import React, { useRef } from "react";
import IdleTimer from "react-idle-timer";
import cogoToast from "cogo-toast";
import { useHistory } from "react-router";
import { ConfirmWrapper } from "./AlertUtility";
import { confirmAlert } from "react-confirm-alert";
import { useAuth } from "../contexts/AuthContext";

const IdleTimerContainer = () => {
  const idleTimerRef = useRef(null);
  const sessionTimeoutRef = useRef(null);
  const { logout } = useAuth();

  const history = useHistory();

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  const logMeOut = () => {
    setTimeout(() => {
      logout();
      localStorage.clear();
      sessionStorage.clear();
      cogoToast.success("User logged out successfully", toastoptions);
      history.push("/home/login");
      window.location.reload();
    }, 4000);
  };

  const onIdle = () => {
    console.log("The user is idle");
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWrapper className="sc-fzoLag BNtsP">
            <a className="sidebar-brand brand-logo" href="/laparrila/restaurant-manager/dashboard">
              <img src={require("../assets/images/la-parrila-logo.png")} alt="logo" className="" />
            </a>
            <header>
              <h4>
                Hey there, you have been idle for a while. You will be logged out of the system now.
              </h4>
            </header>
          </ConfirmWrapper>
        );
      },
    });

    sessionTimeoutRef.current = logMeOut();
  };
  return (
    <div>
      <IdleTimer ref={idleTimerRef} timeout={1800000} onIdle={onIdle}></IdleTimer>
    </div>
  );
};

export default IdleTimerContainer;
