import React from "react";

const CriteriaMark = (condition) => {
  if (condition === true) {
    return (
      <span
        className="mdi mdi-check-circle"
        style={{ color: "green", fontSize: "20px" }}
      ></span>
    );
  } else {
    return (
      <span
        className="mdi mdi-check-circle"
        style={{ color: "red", fontSize: "20px" }}
      ></span>
    );
  }
};

export default CriteriaMark;
