import moment from "moment";
import React from "react";
import { ConvertDateFormatForHuman } from "../../../components/ConvertDateFormat";

const TaskHovering = ({ task }) => {
  return (
    <div>
      <div
        style={{
          opacity: "0.5",
          border: "2px solid #f5f",
          borderRadius: "10px",
        }}
      >
        <ul id="portlet-card-list-1" className="portlet-card-list">
          <li className="portlet-card" style={{}}>
            {/* <ProgressBar variant={`${task.progressVariant}`} now={75} /> */}
            <div className="d-flex justify-content-between w-100">
              <div className="task-date">
                <span
                  style={{
                    display: "inline-block",
                    padding: "3px",
                    verticalAlign: "middle",
                    lineHeight: "normal",
                    marginRight: "4px",
                    backgroundColor: "transparent",
                    textTransform: "uppercase",
                    color: "#0091ea",
                  }}
                >
                  <span
                    style={{
                      border: "1px solid #0091ea",
                      borderRadius: "5px",
                      padding: "2px",
                      fontSize: "12px",
                    }}
                  >
                    {" "}
                    {task.short}
                  </span>
                </span>
                &nbsp;&nbsp;
                <span>
                  {task.completeDate ? (
                    <strike>{task.subject}</strike>
                  ) : (
                    <b>{task.subject}</b>
                  )}
                </span>
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div>
                {/* {task.priority} */}
                {task.tags.map((i, index) => (
                  <div
                    className={"badge badge-inverse-primary"}
                    style={{ display: "inline-block", margin: "0px 2px" }}
                    key={index}
                  >
                    {i}
                  </div>
                ))}
              </div>
            </div>

            <div
              style={{
                margin: "10px 0px",
              }}
            >
              Created on: {moment(task.createDate).format("LLLL")} by{" "}
              {task.createdBy}
            </div>

            <div className="text-center" style={{ marginBottom: "10px" }}>
              <div
                style={{
                  padding: "8px",
                  marginBottom: "10px",
                  border: "1px solid black",
                  borderRadius: "5px",
                }}
              >
                Due On: {moment(task.dueDate).format("LLLL")}
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div className="card-bottom">
                <div>
                  <span className="mdi mdi-comment-text-outline"></span>
                  &nbsp;&nbsp;
                  <span>{task.commentsCount}</span>
                </div>
                <div>
                  <span className="mdi mdi-bell"></span>
                </div>

                <div>
                  {/* <span>`${calculateDueDate(task.dueDate)}`</span> */}
                  {moment(task.dueDate).isSame(moment(), "day") && (
                    <span style={{ color: "red" }}>
                      <span className="mdi mdi-clock-outline"></span>
                      &nbsp;&nbsp;
                      <span>{ConvertDateFormatForHuman(task.dueDate)}</span>
                    </span>
                  )}
                  {moment(task.dueDate).isBefore(moment(), "day") && (
                    <span style={{ color: "red" }}>
                      <span className="mdi mdi-clock-outline"></span>
                      &nbsp;&nbsp;
                      <span>{ConvertDateFormatForHuman(task.dueDate)}</span>
                    </span>
                  )}
                  {moment(task.dueDate).isAfter(moment(), "day") && (
                    <span style={{ color: "green" }}>
                      <span className="mdi mdi-clock-outline"></span>
                      &nbsp;&nbsp;
                      <span>{ConvertDateFormatForHuman(task.dueDate)}</span>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TaskHovering;
