import React, { useState, useContext, useEffect } from "react";
import { Formik } from "formik";
import { Spinner, Form } from "react-bootstrap";
import { BulkBonusUpload } from "../../../components/BulkBonusUpload";
import { UserService } from "../../../service/userService";
import Swal from "sweetalert2";
import BonusContext from "../../../contexts/bonus/BonusContext";
import { Link, useHistory } from "react-router-dom";

const BulkBonusUploadFile = () => {
  const [reportReady, setReportReady] = useState(false);

  const history = useHistory();

  const { dispatch, bulkUpload } = useContext(BonusContext);

  return (
    <section className="content">
      <div>
        {!reportReady && (
          <Formik
            initialValues={{
              bonus_report_attachment_1: [],
              year_1: "",
              quarter_1: "",
              bonus_report_attachment_2: [],
              year_2: "",
              quarter_2: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);

              const data = new FormData();

              if (
                values.bonus_report_attachment_1 &&
                values.bonus_report_attachment_1.length > 0 &&
                values.bonus_report_attachment_2 &&
                values.bonus_report_attachment_2.length > 0 &&
                values.year_1 &&
                values.year_2 &&
                values.quarter_2 &&
                values.quarter_1
              ) {
                values.bonus_report_attachment_1.map((file) => {
                  return data.append("bonus_report_attachment_1[]", file, file.name);
                });

                values.bonus_report_attachment_2.map((file) => {
                  return data.append("bonus_report_attachment_2[]", file, file.name);
                });

                data.append("year_1", values.year_1);
                data.append("year_2", values.year_2);
                data.append("quarter_1", values.quarter_1);
                data.append("quarter_2", values.quarter_2);

                UserService.uploadBulkBonusDetails(data)
                  .then((response) => {
                    setSubmitting(false);
                    // setBulkReport(response.data.data.reportInfo);
                    // setReportReady(true);
                    history.push("/bonus/view-bulk-upload-breakdown", {
                      year_1: values.year_1,
                      year_2: values.year_2,
                      quarter: values.quarter_1,
                      storeId: 1,
                    });
                  })
                  .catch((error) => {
                    console.log(error);
                    setSubmitting(false);
                    Swal.fire({
                      title: "Error",
                      text: error,
                      icon: "error",
                      confirmButtonColor: "#000000",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Ok",
                      allowOutsideClick: false,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        console.log("Payment works");
                      }
                    });
                    // cogoToast.error(error.response.data.message, toastoptions);
                  });
              } else {
                setSubmitting(false);
                Swal.fire({
                  title: "Check all inputs",
                  icon: "error",
                  html: "Check inputs and ensure to attach the required file</b>",
                  // title: "Error",
                  text: "",
                  // icon: "error",
                  confirmButtonColor: "#000000",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    console.log("Labor info works");
                  }
                });
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form
                className="closing_report_form mt-4"
                id="closing_report_form"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-md-6">
                    <h3>First Year </h3>

                    <Form.Group>
                      <label htmlFor="year_1"> Year</label>
                      <Form.Control
                        type="text"
                        as="select"
                        className="form-control"
                        id="year_1"
                        name="year_1"
                        value={values.year_1 ? values.year_1 : "DEFAULT"}
                        onChange={handleChange}
                        disabled={isSubmitting}
                      >
                        <option value="DEFAULT" disabled>
                          Select One
                        </option>

                        <option value={"2021"}> 2021 </option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <label htmlFor="quarter_1">Quarter</label>
                      <Form.Control
                        type="text"
                        as="select"
                        className="form-control"
                        id="quarter_1"
                        name="quarter_1"
                        value={values.quarter_1 ? values.quarter_1 : "DEFAULT"}
                        onChange={handleChange}
                        disabled={isSubmitting}
                      >
                        <option value="DEFAULT" disabled>
                          Select One
                        </option>

                        <option value={"1"}>Q1</option>
                        <option value={"2"}>Q2</option>
                        <option value={"3"}>Q3</option>
                        <option value={"4"}>Q4</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <p> Upload Report </p>
                      <BulkBonusUpload
                        setFieldValue={setFieldValue}
                        name={"bonus_report_attachment_1"}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-md-6">
                    <h3>Second Year </h3>
                    <Form.Group>
                      <label htmlFor="year_2"> Year</label>
                      <Form.Control
                        type="text"
                        as="select"
                        className="form-control"
                        id="year_2"
                        name="year_2"
                        value={values.year_2 ? values.year_2 : "DEFAULT"}
                        onChange={handleChange}
                        disabled={isSubmitting}
                      >
                        <option value="DEFAULT" disabled>
                          Select One
                        </option>

                        <option value={"2022"}> 2022 </option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <label htmlFor="quarter_2">Quarter</label>
                      <Form.Control
                        type="text"
                        as="select"
                        className="form-control"
                        id="quarter_2"
                        name="quarter_2"
                        value={values.quarter_2 ? values.quarter_2 : "DEFAULT"}
                        onChange={handleChange}
                        disabled={isSubmitting}
                      >
                        <option value="DEFAULT" disabled>
                          Select One
                        </option>

                        <option value={"1"}>Q1</option>
                        <option value={"2"}>Q2</option>
                        <option value={"3"}>Q3</option>
                        <option value={"4"}>Q4</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <p> Upload Report </p>
                      <BulkBonusUpload
                        setFieldValue={setFieldValue}
                        name={"bonus_report_attachment_2"}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div>
                  <button type="submit" disabled={isSubmitting} className="btn btn-primary mr-2">
                    Submit &nbsp;&nbsp;
                    {isSubmitting ? <Spinner animation="border" size="sm" /> : ""}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>

      <div>
        {reportReady && (
          <div>
            <h3>Report uploaded successfully.</h3>
            <Link to={"/bonus/view-bulk-upload-breakdown"}>
              <button className="btn btn-info">Click here to view breakdown</button>
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default BulkBonusUploadFile;
