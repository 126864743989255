import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useHistory, useParams, withRouter } from "react-router";
import { Link } from "react-router-dom";
import cogoToast from "cogo-toast";
import { FeedbackService } from "../../service/FeedbackService";

const { SearchBar } = Search;

const ViewFeedback = () => {
  const [feedbackList, setFeedbackList] = useState(null);

  useEffect(() => {
    FeedbackService.getFeedbacksList()
      .then((response) => {
        console.log(response.data.data);
        let feedbackArray = [];

        response.data.data.map((res, index) => {
          return feedbackArray.push({
            id: res.id,
            experience: res.experience,
            feedback: res.feedback,
            feedbackBy: res.feedback_by,
          });
        });

        setFeedbackList(feedbackArray);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  const columns = [
    {
      dataField: "experience",
      text: "Experience",
      sort: true,
    },
    {
      dataField: "feedbackBy",
      text: "Feedback By",
      sort: true,
    },
    {
      dataField: "feedback",
      text: "Feedback",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <div>
      <div className="page-header">
        <div className="mb-5">
          <Link to={"/feedback"}>
            <button className="btn btn-primary"> Back </button>
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            {feedbackList && (
              <div className="card-body">
                <h4 className="card-title"> Portal Feedback </h4>
                <div className="row">
                  <div className="col-12">
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={feedbackList}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div className="d-flex align-items-center">
                            <p className="mb-2 mr-2">Search in table:</p>
                            <SearchBar {...props.searchProps} />
                          </div>
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ViewFeedback);
