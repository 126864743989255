import React, { useState, useEffect } from "react";
import { withRouter, useHistory, useParams, Link } from "react-router-dom";
import cogoToast from "cogo-toast";
import { Formik } from "formik";
import { Button, Form, Spinner } from "react-bootstrap";
import { EmployeeConfigService } from "../../../../service/employeeConfigService";

const ViewMaritalStatus = () => {
  const [editMode, setEditMode] = useState(false);
  const [maritalStatus, setMaritalStatus] = useState(null);

  const history = useHistory();
  let { id } = useParams();

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  useEffect(() => {
    EmployeeConfigService.getMaritalStatus(id)
      .then((response) => {
        console.log(response.data.data);
        setMaritalStatus(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, [id]);

  return (
    <div>
      <div className="page-header">
        {maritalStatus && (
          <h3 className="page-title config-header-title">
            {" "}
            <span>/ {maritalStatus.name} </span>
          </h3>
        )}
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/system-configuration/employee"}>
                Employee configuration
              </Link>
            </li>
            {!editMode && (
              <li className="breadcrumb-item active" aria-current="page">
                <Button
                  onClick={() => {
                    setEditMode(true);
                  }}
                >
                  {" "}
                  Edit{" "}
                </Button>
              </li>
            )}
          </ol>
        </nav>
      </div>

      {maritalStatus && (
        <Formik
          initialValues={{
            id: maritalStatus.id,
            name: maritalStatus.name,
            description: maritalStatus.description,
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            EmployeeConfigService.updateMaritalStatus(values)
              .then((response) => {
                console.log(response);
                setSubmitting(false);
                cogoToast.success(
                  "Marital Status updated successfully",
                  toastoptions
                );
                setEditMode(false);
                history.push("/employee-configuration/view-marital-statuses");
              })
              .catch((error) => {
                console.log(error.response);
                cogoToast.error(error.response.data.message, toastoptions);
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <div className="mt-3 mb-3">
              <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card" style={{ border: "none" }}>
                    <div className="card-body">
                      <form className="pageForms" onSubmit={handleSubmit}>
                        <div className="row border-bottom mb-5">
                          <div className="col-md-6">
                            <Form.Group>
                              <label htmlFor="name"> Marital Status </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                placeholder="Marital Status"
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                onChange={handleChange}
                                defaultValue={values.name}
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="description">Description</label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="description"
                                name="description"
                                placeholder="Marital status"
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                onChange={handleChange}
                                defaultValue={values.description}
                              />
                            </Form.Group>
                          </div>
                        </div>
                        {editMode && (
                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary btn-icon-text"
                            >
                              <i className="mdi mdi-file-check btn-icon-prepend"></i>{" "}
                              Update&nbsp;&nbsp;
                              {isSubmitting ? (
                                <Spinner animation="border" size="sm" />
                              ) : (
                                ""
                              )}
                            </button>

                            <button
                              className="btn btn-light"
                              onClick={() => {
                                setEditMode(false);
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
};

export default withRouter(ViewMaritalStatus);
