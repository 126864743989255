import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { UserService } from "../../../service/userService";

const ReportNote = ({ note }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    UserService.getEmployeeUsingId(note.created_by)
      .then((response) => {
        setUser(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, [note.created_by]);
  return (
    <div className="card-body no-gutter">
      <div className="list-item">
        <div className="content">
          <div className="d-flex align-items-center">
            {user && (
              <h6 className="product-name text-dark">
                {" "}
                {user.first_name} {user.last_name} :
              </h6>
            )}
            <small className="time ml-3 d-none d-sm-block">
              {format(new Date(note.created_at), "MM-dd-yyyy kk:mm:ss")}
              {/* console.log(format(new Date(), 'yyyy/MM/dd kk:mm:ss')) */}
            </small>
          </div>
          <div className="d-flex align-items-center">
            <p className="user-name text-dark">Comment :</p>

            {/* <p className="review-text d-block">{note.note}</p> */}
            <span>{note.comment}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportNote;
