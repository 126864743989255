import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { ProgressBar } from "react-bootstrap";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { BonusPayoutService } from "../../../service/BonusPayoutService";
import getStoreName from "../../../components/StoreNameUsingId";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";

const ViewBonusPayout = () => {
  const history = useHistory();

  const [bonus, setBonus] = useState(null);
  const [m1, setM1] = useState();
  const [m2, setM2] = useState();
  const [m3, setM3] = useState();
  const [currentQuarter, setCurrentQuarter] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [nextYear, setNextYear] = useState(null);

  const [foodSalesPercentage, setFoodSalesPercentage] = useState(0);
  const [foodCostPercentage, setFoodCostPercentage] = useState(0);
  const [liquorCostPercentage, setLiquorCostPercentage] = useState(0);
  const [suppliesCostPercentage, setSuppliesCostPercentage] = useState(0);
  const [payrollCostBOHPercentage, setPayrollCostBOHPercentage] = useState(0);
  const [payrollCostFOHPercentage, setPayrollCostFOHPercentage] = useState(0);
  const [payrollAdminPercentage, setPayrollAdminPercentage] = useState(0);
  const [utiltiesCostPercentage, setUtiltiesCostPercentage] = useState(0);
  const [admonFeePercentage, setAdmonFeePercentage] = useState(0);
  const [netProfitPercentage, setNetProfitPercentage] = useState(0);
  const [bonusCostSettings, setBonusCostSettings] = useState(0);

  const storeId = history.location.state.store;
  const year = history.location.state.year;
  const quarter = history.location.state.quarter;

  const roundNumberUp = (num) => {
    return (Math.round(num * 100) / 100).toFixed(0);
  };

  const roundNumberUp2DP = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  useEffect(() => {
    BonusPayoutService.getSpecificBonusPayout(storeId, quarter, year)
      .then((response) => {
        console.log(response.data.data);
        setBonus(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    setCurrentQuarter(quarter);

    if (quarter === "1") {
      setM1("Jan");
      setM2("Feb");
      setM3("Mar");
    } else if (quarter === "2") {
      setM1("April");
      setM2("May");
      setM3("June");
    } else if (quarter === "3") {
      setM1("Jul");
      setM2("Aug");
      setM3("Sept");
    } else if (quarter === "4") {
      setM1("Oct");
      setM2("Nov");
      setM3("Dec");
    }

    const yearArr = year.split(" - ");
    setCurrentYear(yearArr[0]);
    setNextYear(yearArr[1]);

    BonusPayoutService.getBonusSettingsForAStore(storeId)
      .then((response) => {
        console.log(response.data.data, "This is the configuration");
        const result = response.data.data;
        setFoodSalesPercentage(result.sales);
        setFoodCostPercentage(result.food_cost);
        setLiquorCostPercentage(result.liquor_cost);
        setSuppliesCostPercentage(result.supplies_cost);
        setPayrollCostBOHPercentage(result.payroll_boh);
        setPayrollCostFOHPercentage(result.payroll_foh);
        setPayrollAdminPercentage(0);
        setUtiltiesCostPercentage(result.utilities_cost);
        setAdmonFeePercentage(result.admon_fee);
        setNetProfitPercentage(result.net_profit);
        setBonusCostSettings(result.bonuses_cost);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div className="float-left">
        <div>
          <img
            src={require("../../../assets/images/la-parrila-logo.png")}
            alt="logo"
            className=""
          />
        </div>

        <div>
          <h4
            style={{
              color: "red",
            }}
          >
            {" "}
            BONUS PAYOUT - {quarter} {year}{" "}
          </h4>
          <p> Location: {getStoreName(storeId)}</p>
        </div>
      </div>

      <div className="row float-right">
        <div className="tab-custom-pills-horizontal col-md-12">
          <div className="">
            <Link to={"/view-select-bonus-payout"}>
              <button type="button" className="btn btn-primary btn-rounded">
                Back
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="clearfix"></div>

      {/* CYQM1 = Current Year, Active Quarter, Month 1
    CYQM2 = Current Year, Active Quarter, Month 2
    CYQM2 = Current Year, Active Quarter, Month 3

    NYQM1 = Next Year, Active Quarter, Month 1
    NYQM2 = Next Year, Active Quarter, Month 2
    NYQM2 = Next Year, Active Quarter, Month 3
    NYQP = Next Year Quarter Percentage
    CYQP = Current Year Quarter Percentage
    foodSalesID = Food Sales Increase Decrease
     */}
      {/* Fetch and display records based on available Quarters (Q1, Q2, Q3) */}

      <div className="col-lg-12">
        {/* <div className="col-lg-12 grid-margin stretch-card"> */}
        <Formik
          initialValues={{
            store_id: storeId,
            quarter: quarter,
            year: year,
            // fieldStatus: "",
            foodSalesCYQM1: bonus ? bonus.foodSalesCYQM1 : "",
            liquorSalesCYQM1: bonus ? bonus.liquorSalesCYQM1 : "",
            totalSalesCYQM1: bonus ? bonus.totalSalesCYQM1 : "",

            foodSalesNYQM1: bonus ? bonus.foodSalesNYQM1 : 0,
            liquorSalesNYQM1: bonus ? bonus.liquorSalesNYQM1 : "",
            totalSalesNYQM1: bonus ? bonus.totalSalesNYQM1 : "",

            foodSalesCYQM2: bonus ? bonus.foodSalesCYQM2 : "",
            liquorSalesCYQM2: bonus ? bonus.liquorSalesCYQM2 : "",
            totalSalesCYQM2: bonus ? bonus.totalSalesCYQM2 : "",

            foodSalesNYQM2: bonus ? bonus.foodSalesNYQM2 : "",
            liquorSalesNYQM2: bonus ? bonus.liquorSalesNYQM2 : "",
            totalSalesNYQM2: bonus ? bonus.totalSalesNYQM2 : "",

            foodSalesCYQM3: bonus ? bonus.foodSalesCYQM3 : "",
            liquorSalesCYQM3: bonus ? bonus.liquorSalesCYQM3 : "",
            totalSalesCYQM3: bonus ? bonus.totalSalesCYQM3 : "",

            foodSalesNYQM3: bonus ? bonus.foodSalesNYQM3 : "",
            liquorSalesNYQM3: bonus ? bonus.liquorSalesNYQM3 : "",
            totalSalesNYQM3: bonus ? bonus.totalSalesNYQM3 : "",

            foodSalesTotalCYQ: bonus ? bonus.foodSalesTotalCYQ : "",
            liquorSalesTotalCYQ: bonus ? bonus.liquorSalesTotalCYQ : "",
            totalSalesTotalCYQ: bonus ? bonus.totalSalesTotalCYQ : "",

            foodSalesCYQP: bonus ? bonus.foodSalesCYQP : "",
            liquorSalesCYQP: bonus ? bonus.liquorSalesCYQP : "",
            totalSalesCYQP: bonus ? bonus.totalSalesCYQP : "",

            foodSalesTotalNYQ: bonus ? bonus.foodSalesTotalNYQ : "",
            liquorSalesTotalNYQ: bonus ? bonus.liquorSalesTotalNYQ : "",
            totalSalesTotalNYQ: bonus ? bonus.totalSalesTotalNYQ : "",

            foodSalesNYQP: bonus ? bonus.foodSalesNYQP : "",
            liquorSalesNYQP: bonus ? bonus.liquorSalesNYQP : "",
            totalSalesNYQP: bonus ? bonus.totalSalesNYQP : "",

            foodSalesID: bonus ? bonus.foodSalesID : "",
            liquorSalesID: bonus ? bonus.liquorSalesID : "",
            totalSalesID: bonus ? bonus.totalSalesID : "",

            foodSalesP: bonus ? bonus.foodSalesP : "",
            liquorSalesP: bonus ? bonus.liquorSalesP : "",
            totalSalesP: bonus ? bonus.totalSalesP : "",

            // COGS 1 starts here
            foodCostCYQM1: bonus ? bonus.foodCostCYQM1 : "",
            liquorCostCYQM1: bonus ? bonus.liquorCostCYQM1 : "",
            suppliesCostCYQM1: bonus ? bonus.suppliesCostCYQM1 : "",
            totalCostCYQM1: bonus ? bonus.totalCostCYQM1 : "",

            foodCostNYQM1: bonus ? bonus.foodCostNYQM1 : "",
            liquorCostNYQM1: bonus ? bonus.liquorCostNYQM1 : "",
            suppliesCostNYQM1: bonus ? bonus.suppliesCostNYQM1 : "",
            totalCostNYQM1: bonus ? bonus.totalCostNYQM1 : "",

            foodCostCYQM2: bonus ? bonus.foodCostCYQM2 : "",
            liquorCostCYQM2: bonus ? bonus.liquorCostCYQM2 : "",
            suppliesCostCYQM2: bonus ? bonus.suppliesCostCYQM2 : "",
            totalCostCYQM2: bonus ? bonus.totalCostCYQM2 : "",

            foodCostNYQM2: bonus ? bonus.foodCostNYQM2 : "",
            liquorCostNYQM2: bonus ? bonus.liquorCostNYQM2 : "",
            suppliesCostNYQM2: bonus ? bonus.suppliesCostNYQM2 : "",
            totalCostNYQM2: bonus ? bonus.totalCostNYQM2 : "",

            foodCostCYQM3: bonus ? bonus.foodCostCYQM3 : "",
            liquorCostCYQM3: bonus ? bonus.liquorCostCYQM3 : "",
            suppliesCostCYQM3: bonus ? bonus.suppliesCostCYQM3 : "",
            totalCostCYQM3: bonus ? bonus.totalCostCYQM3 : "",

            foodCostNYQM3: bonus ? bonus.foodCostNYQM3 : "",
            liquorCostNYQM3: bonus ? bonus.liquorCostNYQM3 : "",
            suppliesCostNYQM3: bonus ? bonus.suppliesCostNYQM3 : "",
            totalCostNYQM3: bonus ? bonus.totalCostNYQM3 : "",

            foodCostTotalCYQ: bonus ? bonus.foodCostTotalCYQ : "",
            liquorCostTotalCYQ: bonus ? bonus.liquorCostTotalCYQ : "",
            suppliesCostTotalCYQ: bonus ? bonus.suppliesCostTotalCYQ : "",
            totalCostTotalCYQ: bonus ? bonus.totalCostTotalCYQ : "",

            foodCostCYQP: bonus ? bonus.foodCostCYQP : "",
            liquorCostCYQP: bonus ? bonus.liquorCostCYQP : "",
            suppliesCostCYQP: bonus ? bonus.suppliesCostCYQP : "",
            totalCostCYQP: bonus ? bonus.totalCostCYQP : "",

            foodCostTotalNYQ: bonus ? bonus.foodCostTotalNYQ : "",
            liquorCostTotalNYQ: bonus ? bonus.liquorCostTotalNYQ : "",
            suppliesCostTotalNYQ: bonus ? bonus.suppliesCostTotalNYQ : "",
            totalCostTotalNYQ: bonus ? bonus.totalCostTotalNYQ : "",

            foodCostNYQP: bonus ? bonus.foodCostNYQP : "",
            liquorCostNYQP: bonus ? bonus.liquorCostNYQP : "",
            suppliesCostNYQP: bonus ? bonus.suppliesCostNYQP : "",
            totalCostNYQP: bonus ? bonus.totalCostNYQP : "",

            foodCostID: bonus ? bonus.foodCostID : "",
            liquorCostID: bonus ? bonus.liquorCostID : "",
            suppliesCostID: bonus ? bonus.suppliesCostID : "",
            totalCostID: bonus ? bonus.totalCostID : "",

            foodCostP: bonus ? bonus.foodCostP : "",
            liquorCostP: bonus ? bonus.liquorCostP : "",
            suppliesCostP: bonus ? bonus.suppliesCostP : "",
            totalCostP: bonus ? bonus.totalCostP : "",

            // Gross Profit
            grossProfitCYQM1: bonus ? bonus.grossProfitCYQM1 : "",
            grossProfitNYQM1: bonus ? bonus.grossProfitNYQM1 : "",
            grossProfitCYQM2: bonus ? bonus.grossProfitCYQM2 : "",
            grossProfitNYQM2: bonus ? bonus.grossProfitNYQM2 : "",
            grossProfitCYQM3: bonus ? bonus.grossProfitCYQM3 : "",
            grossProfitNYQM3: bonus ? bonus.grossProfitNYQM3 : "",

            grossProfitTotalCYQ: bonus ? bonus.grossProfitTotalCYQ : "",
            grossProfitTotalCYQP: bonus ? bonus.grossProfitTotalCYQP : "",
            grossProfitTotalNYQ: bonus ? bonus.grossProfitTotalNYQ : "",
            grossProfitTotalNYQP: bonus ? bonus.grossProfitTotalNYQP : "",
            grossProfitID: bonus ? bonus.grossProfitID : "",
            grossProfitP: bonus ? bonus.grossProfitP : "",

            // COGS 2
            payrollBohCYQM1: bonus ? bonus.payrollBohCYQM1 : "",
            payrollFohCYQM1: bonus ? bonus.payrollFohCYQM1 : "",
            payrollAdminCYQM1: bonus ? bonus.payrollAdminCYQM1 : "",
            bonusesCYQM1: bonus ? bonus.bonusesCYQM1 : "",
            creditCardCYQM1: bonus ? bonus.creditCardCYQM1 : "",
            foodDeliveryCYQM1: bonus ? bonus.foodDeliveryCYQM1 : "",
            rentCYQM1: bonus ? bonus.rentCYQM1 : "",
            utilitiesCYQM1: bonus ? bonus.utilitiesCYQM1 : "",
            depreciationCYQM1: bonus ? bonus.depreciationCYQM1 : "",
            overheadsCYQM1: bonus ? bonus.overheadsCYQM1 : "",
            totalExpensesCYQM1: bonus ? bonus.totalExpensesCYQM1 : "",

            payrollBohNYQM1: bonus ? bonus.payrollBohNYQM1 : "",
            payrollFohNYQM1: bonus ? bonus.payrollFohNYQM1 : "",
            payrollAdminNYQM1: bonus ? bonus.payrollAdminNYQM1 : "",
            bonusesNYQM1: bonus ? bonus.bonusesNYQM1 : "",
            creditCardNYQM1: bonus ? bonus.creditCardNYQM1 : "",
            foodDeliveryNYQM1: bonus ? bonus.foodDeliveryNYQM1 : "",
            rentNYQM1: bonus ? bonus.rentNYQM1 : "",
            utilitiesNYQM1: bonus ? bonus.utilitiesNYQM1 : "",
            depreciationNYQM1: bonus ? bonus.depreciationNYQM1 : "",
            overheadsNYQM1: bonus ? bonus.overheadsNYQM1 : "",
            totalExpensesNYQM1: bonus ? bonus.totalExpensesNYQM1 : "",

            payrollBohCYQM2: bonus ? bonus.payrollBohCYQM2 : "",
            payrollFohCYQM2: bonus ? bonus.payrollFohCYQM2 : "",
            payrollAdminCYQM2: bonus ? bonus.payrollAdminCYQM2 : "",
            bonusesCYQM2: bonus ? bonus.bonusesCYQM2 : "",
            creditCardCYQM2: bonus ? bonus.creditCardCYQM2 : "",
            foodDeliveryCYQM2: bonus ? bonus.foodDeliveryCYQM2 : "",
            rentCYQM2: bonus ? bonus.rentCYQM2 : "",
            utilitiesCYQM2: bonus ? bonus.utilitiesCYQM2 : "",
            depreciationCYQM2: bonus ? bonus.depreciationCYQM2 : "",
            overheadsCYQM2: bonus ? bonus.overheadsCYQM2 : "",
            totalExpensesCYQM2: bonus ? bonus.totalExpensesCYQM2 : "",

            payrollBohNYQM2: bonus ? bonus.payrollBohNYQM2 : "",
            payrollFohNYQM2: bonus ? bonus.payrollFohNYQM2 : "",
            payrollAdminNYQM2: bonus ? bonus.payrollAdminNYQM2 : "",
            bonusesNYQM2: bonus ? bonus.bonusesNYQM2 : "",
            creditCardNYQM2: bonus ? bonus.creditCardNYQM2 : "",
            foodDeliveryNYQM2: bonus ? bonus.foodDeliveryNYQM2 : "",
            rentNYQM2: bonus ? bonus.rentNYQM2 : "",
            utilitiesNYQM2: bonus ? bonus.utilitiesNYQM2 : "",
            depreciationNYQM2: bonus ? bonus.depreciationNYQM2 : "",
            overheadsNYQM2: bonus ? bonus.overheadsNYQM2 : "",
            totalExpensesNYQM2: bonus ? bonus.totalExpensesNYQM2 : "",

            payrollBohCYQM3: bonus ? bonus.payrollBohCYQM3 : "",
            payrollFohCYQM3: bonus ? bonus.payrollFohCYQM3 : "",
            payrollAdminCYQM3: bonus ? bonus.payrollAdminCYQM3 : "",
            bonusesCYQM3: bonus ? bonus.bonusesCYQM3 : "",
            creditCardCYQM3: bonus ? bonus.creditCardCYQM3 : "",
            foodDeliveryCYQM3: bonus ? bonus.foodDeliveryCYQM3 : "",
            rentCYQM3: bonus ? bonus.rentCYQM3 : "",
            utilitiesCYQM3: bonus ? bonus.utilitiesCYQM3 : "",
            depreciationCYQM3: bonus ? bonus.depreciationCYQM3 : "",
            overheadsCYQM3: bonus ? bonus.overheadsCYQM3 : "",
            totalExpensesCYQM3: bonus ? bonus.totalExpensesCYQM3 : "",

            payrollBohNYQM3: bonus ? bonus.payrollBohNYQM3 : "",
            payrollFohNYQM3: bonus ? bonus.payrollFohNYQM3 : "",
            payrollAdminNYQM3: bonus ? bonus.payrollAdminNYQM3 : "",
            bonusesNYQM3: bonus ? bonus.bonusesNYQM3 : "",
            creditCardNYQM3: bonus ? bonus.creditCardNYQM3 : "",
            foodDeliveryNYQM3: bonus ? bonus.foodDeliveryNYQM3 : "",
            rentNYQM3: bonus ? bonus.rentNYQM3 : "",
            utilitiesNYQM3: bonus ? bonus.utilitiesNYQM3 : "",
            depreciationNYQM3: bonus ? bonus.depreciationNYQM3 : "",
            overheadsNYQM3: bonus ? bonus.overheadsNYQM3 : "",
            totalExpensesNYQM3: bonus ? bonus.totalExpensesNYQM3 : "",

            payrollBohTotalCYQ: bonus ? bonus.payrollBohTotalCYQ : "",
            payrollFohTotalCYQ: bonus ? bonus.payrollFohTotalCYQ : "",
            payrollAdminTotalCYQ: bonus ? bonus.payrollAdminTotalCYQ : "",
            bonusesTotalCYQ: bonus ? bonus.bonusesTotalCYQ : "",
            creditCardTotalCYQ: bonus ? bonus.creditCardTotalCYQ : "",
            foodDeliveryTotalCYQ: bonus ? bonus.foodDeliveryTotalCYQ : "",
            rentTotalCYQ: bonus ? bonus.rentTotalCYQ : "",
            utilitiesTotalCYQ: bonus ? bonus.utilitiesTotalCYQ : "",
            depreciationTotalCYQ: bonus ? bonus.depreciationTotalCYQ : "",
            overheadsTotalCYQ: bonus ? bonus.overheadsTotalCYQ : "",
            totalExpensesTotalCYQ: bonus ? bonus.totalExpensesTotalCYQ : "",

            payrollBohCYQP: bonus ? bonus.payrollBohCYQP : "",
            payrollFohCYQP: bonus ? bonus.payrollFohCYQP : "",
            payrollAdminCYQP: bonus ? bonus.payrollAdminCYQP : "",
            bonusesCYQP: bonus ? bonus.bonusesCYQP : "",
            creditCardCYQP: bonus ? bonus.creditCardCYQP : "",
            foodDeliveryCYQP: bonus ? bonus.foodDeliveryCYQP : "",
            rentCYQP: bonus ? bonus.rentCYQP : "",
            utilitiesCYQP: bonus ? bonus.utilitiesCYQP : "",
            depreciationCYQP: bonus ? bonus.depreciationCYQP : "",
            overheadsCYQP: bonus ? bonus.overheadsCYQP : "",
            totalExpensesCYQP: bonus ? bonus.totalExpensesCYQP : "",

            payrollBohTotalNYQ: bonus ? bonus.payrollBohTotalNYQ : "",
            payrollFohTotalNYQ: bonus ? bonus.payrollFohTotalNYQ : "",
            payrollAdminTotalNYQ: bonus ? bonus.payrollAdminTotalNYQ : "",
            bonusesTotalNYQ: bonus ? bonus.bonusesTotalNYQ : "",
            creditCardTotalNYQ: bonus ? bonus.creditCardTotalNYQ : "",
            foodDeliveryTotalNYQ: bonus ? bonus.foodDeliveryTotalNYQ : "",
            rentTotalNYQ: bonus ? bonus.rentTotalNYQ : "",
            utilitiesTotalNYQ: bonus ? bonus.utilitiesTotalNYQ : "",
            depreciationTotalNYQ: bonus ? bonus.depreciationTotalNYQ : "",
            overheadsTotalNYQ: bonus ? bonus.overheadsTotalNYQ : "",
            totalExpensesTotalNYQ: bonus ? bonus.totalExpensesTotalNYQ : "",

            payrollBohNYQP: bonus ? bonus.payrollBohNYQP : "",
            payrollFohNYQP: bonus ? bonus.payrollFohNYQP : "",
            payrollAdminNYQP: bonus ? bonus.payrollAdminNYQP : "",
            bonusesNYQP: bonus ? bonus.bonusesNYQP : "",
            creditCardNYQP: bonus ? bonus.creditCardNYQP : "",
            foodDeliveryNYQP: bonus ? bonus.foodDeliveryNYQP : "",
            rentNYQP: bonus ? bonus.rentNYQP : "",
            utilitiesNYQP: bonus ? bonus.utilitiesNYQP : "",
            depreciationNYQP: bonus ? bonus.depreciationNYQP : "",
            overheadsNYQP: bonus ? bonus.overheadsNYQP : "",
            totalExpensesNYQP: bonus ? bonus.totalExpensesNYQP : "",

            payrollBohID: bonus ? bonus.payrollBohID : "",
            payrollFohID: bonus ? bonus.payrollFohID : "",
            payrollAdminID: bonus ? bonus.payrollAdminID : "",
            bonusesID: bonus ? bonus.bonusesID : "",
            creditCardID: bonus ? bonus.creditCardID : "",
            foodDeliveryID: bonus ? bonus.foodDeliveryID : "",
            rentID: bonus ? bonus.rentID : "",
            utilitiesID: bonus ? bonus.utilitiesID : "",
            depreciationID: bonus ? bonus.depreciationID : "",
            overheadsID: bonus ? bonus.overheadsID : "",
            totalExpensesID: bonus ? bonus.totalExpensesID : "",

            payrollBohP: bonus ? bonus.payrollBohP : "",
            payrollFohP: bonus ? bonus.payrollFohP : "",
            payrollAdminP: bonus ? bonus.payrollAdminP : "",
            bonusesP: bonus ? bonus.bonusesP : "",
            creditCardP: bonus ? bonus.creditCardP : "",
            foodDeliveryP: bonus ? bonus.foodDeliveryP : "",
            rentP: bonus ? bonus.rentP : "",
            utilitiesP: bonus ? bonus.utilitiesP : "",
            depreciationP: bonus ? bonus.depreciationP : "",
            overheadsP: bonus ? bonus.overheadsP : "",
            totalExpensesP: bonus ? bonus.totalExpensesP : "",

            // Operating Income
            operatingCYQM1: bonus ? bonus.operatingCYQM1 : "",
            operatingNYQM1: bonus ? bonus.operatingNYQM1 : "",
            operatingCYQM2: bonus ? bonus.operatingCYQM2 : "",
            operatingNYQM2: bonus ? bonus.operatingNYQM2 : "",
            operatingCYQM3: bonus ? bonus.operatingCYQM3 : "",
            operatingNYQM3: bonus ? bonus.operatingNYQM3 : "",

            operatingTotalCYQ: bonus ? bonus.operatingTotalCYQ : "",
            operatingTotalCYQP: bonus ? bonus.operatingTotalCYQP : "",
            operatingTotalNYQ: bonus ? bonus.operatingTotalNYQ : "",
            operatingTotalNYQP: bonus ? bonus.operatingTotalNYQP : "",
            operatingID: bonus ? bonus.operatingID : "",
            operatingP: bonus ? bonus.operatingP : "",

            // Interest and Admon Fee starts here
            interestCYQM1: bonus ? bonus.interestCYQM1 : "",
            admonFeeCYQM1: bonus ? bonus.admonFeeCYQM1 : "",

            interestNYQM1: bonus ? bonus.interestNYQM1 : "",
            admonFeeNYQM1: bonus ? bonus.admonFeeNYQM1 : "",

            interestCYQM2: bonus ? bonus.interestCYQM2 : "",
            admonFeeCYQM2: bonus ? bonus.admonFeeCYQM2 : "",

            interestNYQM2: bonus ? bonus.interestNYQM2 : "",
            admonFeeNYQM2: bonus ? bonus.admonFeeNYQM2 : "",

            interestCYQM3: bonus ? bonus.interestCYQM3 : "",
            admonFeeCYQM3: bonus ? bonus.admonFeeCYQM3 : "",

            interestNYQM3: bonus ? bonus.interestNYQM3 : "",
            admonFeeNYQM3: bonus ? bonus.admonFeeNYQM3 : "",

            interestTotalCYQ: bonus ? bonus.interestTotalCYQ : "",
            admonFeeTotalCYQ: bonus ? bonus.admonFeeTotalCYQ : "",

            interestCYQP: bonus ? bonus.interestCYQP : "",
            admonFeeCYQP: bonus ? bonus.admonFeeCYQP : "",

            interestTotalNYQ: bonus ? bonus.interestTotalNYQ : "",
            admonFeeTotalNYQ: bonus ? bonus.admonFeeTotalNYQ : "",

            interestNYQP: bonus ? bonus.interestNYQP : "",
            admonFeeNYQP: bonus ? bonus.admonFeeNYQP : "",

            interestID: bonus ? bonus.interestID : "",
            admonFeeID: bonus ? bonus.admonFeeID : "",

            interestP: bonus ? bonus.interestP : "",
            admonFeeP: bonus ? bonus.admonFeeP : "",

            // Sub Total
            subTotalCYQM1: bonus ? bonus.subTotalCYQM1 : "",
            subTotalNYQM1: bonus ? bonus.subTotalNYQM1 : "",
            subTotalCYQM2: bonus ? bonus.subTotalCYQM2 : "",
            subTotalNYQM2: bonus ? bonus.subTotalNYQM2 : "",
            subTotalCYQM3: bonus ? bonus.subTotalCYQM3 : "",
            subTotalNYQM3: bonus ? bonus.subTotalNYQM3 : "",

            subTotalTotalCYQ: bonus ? bonus.subTotalTotalCYQ : "",
            subTotalTotalCYQP: bonus ? bonus.subTotalTotalCYQP : "",
            subTotalTotalNYQ: bonus ? bonus.subTotalTotalNYQ : "",
            subTotalTotalNYQP: bonus ? bonus.subTotalTotalNYQP : "",
            subTotalID: bonus ? bonus.subTotalID : "",
            subTotalP: bonus ? bonus.subTotalP : "",

            // Other Income starts here
            otherIncomeCYQM1: bonus ? bonus.otherIncomeCYQM1 : "",
            otherIncomeNYQM1: bonus ? bonus.otherIncomeNYQM1 : "",
            otherIncomeCYQM2: bonus ? bonus.otherIncomeCYQM2 : "",
            otherIncomeNYQM2: bonus ? bonus.otherIncomeNYQM2 : "",
            otherIncomeCYQM3: bonus ? bonus.otherIncomeCYQM3 : "",
            otherIncomeNYQM3: bonus ? bonus.otherIncomeNYQM3 : "",

            otherIncomeTotalCYQ: bonus ? bonus.otherIncomeTotalCYQ : "",
            otherIncomeCYQP: bonus ? bonus.otherIncomeCYQP : "",

            otherIncomeTotalNYQ: bonus ? bonus.otherIncomeTotalNYQ : "",
            otherIncomeNYQP: bonus ? bonus.otherIncomeNYQP : "",

            otherIncomeID: bonus ? bonus.otherIncomeID : "",
            otherIncomeP: bonus ? bonus.otherIncomeP : "",

            // Net Profit
            netProfitCYQM1: bonus ? bonus.netProfitCYQM1 : "",
            netProfitNYQM1: bonus ? bonus.netProfitNYQM1 : "",
            netProfitCYQM2: bonus ? bonus.netProfitCYQM2 : "",
            netProfitNYQM2: bonus ? bonus.netProfitNYQM2 : "",
            netProfitCYQM3: bonus ? bonus.netProfitCYQM3 : "",
            netProfitNYQM3: bonus ? bonus.netProfitNYQM3 : "",

            netProfitTotalCYQ: bonus ? bonus.netProfitTotalCYQ : "",
            netProfitTotalCYQP: bonus ? bonus.netProfitTotalCYQP : "",
            netProfitTotalNYQ: bonus ? bonus.netProfitTotalNYQ : "",
            netProfitTotalNYQP: bonus ? bonus.netProfitTotalNYQP : "",
            netProfitID: bonus ? bonus.netProfitID : "",
            netProfitP: bonus ? bonus.netProfitP : "",
          }}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            Swal.fire({
              title: "Are you sure?",
              text: "You are about to mark this as complete",
              icon: "question",
              confirmButtonText: "Yes",
              allowEscapeKey: false,
              allowOutsideClick: false,
              showCancelButton: true,
              confirmButtonColor: "#000000",
              cancelButtonColor: "#d33",
            }).then((result) => {
              if (result.isConfirmed) {
                setSubmitting(true);
                let data = {
                  store_id: storeId,
                  quarter,
                  year,
                };
                BonusPayoutService.markBonusAsComplete(data)
                  .then((response) => {
                    console.log(response.data.data);
                    alert("Save Successfully");
                    setSubmitting(false);
                    window.location.reload();
                  })
                  .catch((error) => {
                    console.log(error);
                    setSubmitting(false);
                  });
              } else {
                setSubmitting(false);
              }
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form name="bonus-forms" onSubmit={handleSubmit}>
              <div className="table-responsive">
                <div>
                  <table className="table table-bordered">
                    <colgroup span="2"></colgroup>
                    <colgroup span="2"></colgroup>
                    <tbody>
                      {/* <col> */}

                      <tr>
                        <td
                          rowSpan="2"
                          style={{
                            fontWeight: "bold",
                            paddingRight: "5px",
                            paddingLeft: "5px",
                            textAlign: "center",
                          }}
                        >
                          Profit and Loss
                        </td>
                        <th
                          colSpan="2"
                          scope="colgroup"
                          className="text-center"
                        >
                          {m1}
                        </th>
                        <th
                          colSpan="2"
                          scope="colgroup"
                          className="text-center"
                        >
                          {m2}
                        </th>
                        <th
                          colSpan="2"
                          scope="colgroup"
                          className="text-center"
                        >
                          {m3}
                        </th>
                        <th
                          colSpan="4"
                          scope="colgroup"
                          className="text-center"
                        >
                          Total {currentQuarter}
                        </th>
                        <th colSpan="2" scope="colgroup">
                          increased
                        </th>
                      </tr>
                      <tr>
                        <th scope="col">{currentYear}</th>
                        <th scope="col">{nextYear}</th>
                        <th scope="col">{currentYear}</th>
                        <th scope="col">{nextYear}</th>
                        <th scope="col">{currentYear}</th>
                        <th scope="col">{nextYear}</th>
                        <th scope="col">{currentYear}</th>
                        <th scope="col">%</th>
                        <th scope="col">{nextYear}</th>
                        <th scope="col">%</th>
                        <th scope="col">Decreased</th>
                        <th scope="col">%</th>
                      </tr>

                      <tr
                        style={{
                          background: "grey",
                          color: "white",
                          paddingLeft: "2px",
                        }}
                      >
                        <th
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          Sales
                        </th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <th scope="row">Food Sales</th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodSalesCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodSalesNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodSalesCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodSalesNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodSalesCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodSalesNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodSalesTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp2DP(values.foodSalesCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodSalesTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp2DP(values.foodSalesNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodSalesID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td
                          className="bonus-input-wrapper"
                          style={
                            foodSalesPercentage &&
                            roundNumberUp2DP(foodSalesPercentage) <=
                              values.foodSalesP
                              ? {
                                  border: "2px solid green",
                                }
                              : {
                                  border: "2px solid red",
                                }
                          }
                        >
                          <NumberFormat
                            value={roundNumberUp2DP(values.foodSalesP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Liquor Sales</th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.liquorSalesCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.liquorSalesNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.liquorSalesCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.liquorSalesNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.liquorSalesCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.liquorSalesNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.liquorSalesTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.liquorSalesCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.liquorSalesTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.liquorSalesNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.liquorSalesID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>

                        <td
                          className="bonus-input-wrapper"
                          style={
                            foodSalesPercentage &&
                            roundNumberUp2DP(foodSalesPercentage) <=
                              values.liquorSalesP
                              ? {
                                  border: "2px solid green",
                                }
                              : {
                                  border: "2px solid red",
                                }
                          }
                        >
                          <NumberFormat
                            value={roundNumberUp(values.liquorSalesP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Total Sales</th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalSalesCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalSalesNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalSalesCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalSalesNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalSalesCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalSalesNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalSalesTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp2DP(values.totalSalesCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalSalesTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp2DP(values.totalSalesNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalSalesID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td
                          className="bonus-input-wrapper"
                          style={
                            foodSalesPercentage &&
                            roundNumberUp2DP(foodSalesPercentage) <=
                              values.totalSalesP
                              ? {
                                  border: "2px solid green",
                                }
                              : {
                                  border: "2px solid red",
                                }
                          }
                        >
                          <NumberFormat
                            value={roundNumberUp2DP(values.totalSalesP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* COGS 1 */}
                <div style={{ marginTop: "20px" }}>
                  <table className="table table-bordered">
                    <tbody>
                      <tr
                        style={{
                          background: "grey",
                          color: "white",
                          paddingLeft: "2px",
                        }}
                      >
                        <th
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          COGS
                        </th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <th scope="row">Food</th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodCostCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodCostNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodCostCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodCostNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodCostCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodCostNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodCostTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodCostCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodCostTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodCostNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodCostID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>

                        <td
                          className="bonus-input-wrapper"
                          style={
                            foodCostPercentage &&
                            roundNumberUp2DP(foodCostPercentage) <=
                              values.foodCostP
                              ? {
                                  border: "2px solid green",
                                }
                              : {
                                  border: "2px solid red",
                                }
                          }
                        >
                          <NumberFormat
                            value={roundNumberUp2DP(values.foodCostP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Liquor</th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.liquorCostCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.liquorCostNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.liquorCostCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.liquorCostNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.liquorCostCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.liquorCostNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.liquorCostTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.liquorCostCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.liquorCostTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.liquorCostNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.liquorCostID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td
                          className="bonus-input-wrapper"
                          style={
                            liquorCostPercentage &&
                            roundNumberUp2DP(liquorCostPercentage) <=
                              values.liquorCostP
                              ? {
                                  border: "2px solid green",
                                }
                              : {
                                  border: "2px solid red",
                                }
                          }
                        >
                          <NumberFormat
                            value={roundNumberUp2DP(values.liquorCostP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Supplies</th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.suppliesCostCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.suppliesCostNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.suppliesCostCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.suppliesCostNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.suppliesCostCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.suppliesCostNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.suppliesCostTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.suppliesCostCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.suppliesCostTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.suppliesCostNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.suppliesCostID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td
                          className="bonus-input-wrapper"
                          style={
                            suppliesCostPercentage &&
                            roundNumberUp2DP(suppliesCostPercentage) <=
                              values.suppliesCostP
                              ? {
                                  border: "2px solid green",
                                }
                              : {
                                  border: "2px solid red",
                                }
                          }
                        >
                          <NumberFormat
                            value={roundNumberUp(values.suppliesCostP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">Total COGS</th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalCostCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalCostNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalCostCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalCostNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalCostCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalCostNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalCostTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalCostCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalCostTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalCostNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalCostID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalCostP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* Gross Profit */}
                <div style={{ marginTop: "20px" }}>
                  <table className="table table-bordered">
                    <tbody>
                      <tr
                        style={{
                          background: "black",
                          color: "white",
                          paddingLeft: "2px",
                        }}
                        className="dark-background"
                      >
                        <th
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            textAlign: "center",
                          }}
                        >
                          Gross Profit
                        </th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.grossProfitCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.grossProfitNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.grossProfitCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.grossProfitNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.grossProfitCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.grossProfitNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.grossProfitTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.grossProfitTotalCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.grossProfitTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.grossProfitTotalNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.grossProfitID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.grossProfitP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* COGS 2 */}
                <div style={{ marginTop: "20px" }}>
                  <table className="table table-bordered">
                    <colgroup span="2"></colgroup>
                    <colgroup span="2"></colgroup>
                    <tbody>
                      <tr>
                        <td
                          rowSpan="2"
                          style={{
                            fontWeight: "bold",
                            paddingRight: "5px",
                            paddingLeft: "5px",
                            textAlign: "center",
                          }}
                        ></td>
                        <th
                          colSpan="2"
                          scope="colgroup"
                          className="text-center"
                        >
                          {m1}
                        </th>
                        <th
                          colSpan="2"
                          scope="colgroup"
                          className="text-center"
                        >
                          {m2}
                        </th>
                        <th
                          colSpan="2"
                          scope="colgroup"
                          className="text-center"
                        >
                          {m3}
                        </th>
                        <th
                          colSpan="4"
                          scope="colgroup"
                          className="text-center"
                        >
                          Total {currentQuarter}
                        </th>
                        <th colSpan="2" scope="colgroup">
                          increased
                        </th>
                      </tr>
                      <tr>
                        <th scope="col">{currentYear}</th>
                        <th scope="col">{nextYear}</th>
                        <th scope="col">{currentYear}</th>
                        <th scope="col">{nextYear}</th>
                        <th scope="col">{currentYear}</th>
                        <th scope="col">{nextYear}</th>
                        <th scope="col">{currentYear}</th>
                        <th scope="col">%</th>
                        <th scope="col">{nextYear}</th>
                        <th scope="col">%</th>
                        <th scope="col">Decreased</th>
                        <th scope="col">%</th>
                      </tr>
                      <tr
                        style={{
                          background: "grey",
                          color: "white",
                          paddingLeft: "2px",
                        }}
                      >
                        <th
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          COGS
                        </th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <th scope="row">Payroll BOH</th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollBohCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollBohNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollBohCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollBohNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollBohCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollBohNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollBohTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollBohCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollBohTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollBohNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollBohID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td
                          className="bonus-input-wrapper"
                          style={
                            payrollCostBOHPercentage &&
                            roundNumberUp2DP(payrollCostBOHPercentage) <=
                              values.payrollBohP
                              ? {
                                  border: "2px solid green",
                                }
                              : {
                                  border: "2px solid red",
                                }
                          }
                        >
                          <NumberFormat
                            value={roundNumberUp2DP(values.payrollBohP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Payroll FOH</th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollFohCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollFohNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollFohCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollFohNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollFohCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollFohNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollFohTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollFohCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollFohTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollFohNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollFohID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td
                          className="bonus-input-wrapper"
                          style={
                            payrollCostFOHPercentage &&
                            roundNumberUp2DP(payrollCostFOHPercentage) <=
                              values.payrollFohP
                              ? {
                                  border: "2px solid green",
                                }
                              : {
                                  border: "2px solid red",
                                }
                          }
                        >
                          <NumberFormat
                            value={roundNumberUp(values.payrollFohP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">Payroll Admin</th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollAdminCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollAdminNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollAdminCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollAdminNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollAdminCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollAdminNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollAdminTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollAdminCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollAdminTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollAdminNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollAdminID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.payrollAdminP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">Bonuses</th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.bonusesCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.bonusesNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.bonusesCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.bonusesNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.bonusesCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.bonusesNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.bonusesTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.bonusesCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.bonusesTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.bonusesNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.bonusesID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.bonusesP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> Credit Card Svcs</th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.creditCardCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.creditCardNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.creditCardCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.creditCardNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.creditCardCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.creditCardNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.creditCardTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.creditCardCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.creditCardTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.creditCardNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.creditCardID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.creditCardP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> Food Delivery Providers</th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodDeliveryCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodDeliveryNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodDeliveryCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodDeliveryNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodDeliveryCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodDeliveryNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodDeliveryTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodDeliveryCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodDeliveryTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodDeliveryNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodDeliveryID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.foodDeliveryP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> Rent </th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.rentCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.rentNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.rentCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.rentNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.rentCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.rentNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.rentTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.rentCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.rentTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.rentNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.rentID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.rentP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> Utilities</th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.utilitiesCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.utilitiesNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.utilitiesCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.utilitiesNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.utilitiesCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.utilitiesNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.utilitiesTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.utilitiesCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.utilitiesTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.utilitiesNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.utilitiesID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td
                          className="bonus-input-wrapper"
                          style={
                            utiltiesCostPercentage &&
                            roundNumberUp2DP(utiltiesCostPercentage) <=
                              values.utilitiesP
                              ? {
                                  border: "2px solid green",
                                }
                              : {
                                  border: "2px solid red",
                                }
                          }
                        >
                          <NumberFormat
                            value={roundNumberUp(values.utilitiesP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> Depreciation </th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.depreciationCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.depreciationNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.depreciationCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.depreciationNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.depreciationCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.depreciationNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.depreciationTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.depreciationCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.depreciationTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.depreciationNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.depreciationID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.depreciationP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> Other Overheads </th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.overheadsCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.overheadsNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.overheadsCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.overheadsNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.overheadsCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.overheadsNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.overheadsTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.overheadsCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.overheadsTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.overheadsNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.overheadsID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.overheadsP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> Total Expenses</th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalExpensesCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalExpensesNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalExpensesCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalExpensesNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalExpensesCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalExpensesNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalExpensesTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalExpensesCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalExpensesTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalExpensesNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalExpensesID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.totalExpensesP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* Operating Income */}
                <div style={{ marginTop: "20px" }}>
                  <table className="table table-bordered">
                    <tbody>
                      <tr
                        style={{
                          background: "black",
                          color: "white",
                          paddingLeft: "2px",
                        }}
                        className="dark-background"
                      >
                        <th
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            textAlign: "center",
                          }}
                        >
                          Operating Income
                        </th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.operatingCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.operatingNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.operatingCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.operatingNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.operatingCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.operatingNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.operatingTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.operatingTotalCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.operatingTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.operatingTotalNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.operatingID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.operatingP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* Interest */}
                <div style={{ marginTop: "20px" }}>
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th scope="row"> Interest </th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.interestCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.interestNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.interestCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.interestNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.interestCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.interestNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.interestTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.interestCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.interestTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.interestNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.interestID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.interestP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> Admon Fee</th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.admonFeeCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.admonFeeNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.admonFeeCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.admonFeeNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.admonFeeCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.admonFeeNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.admonFeeTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.admonFeeCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.admonFeeTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.admonFeeNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.admonFeeID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td
                          className="bonus-input-wrapper"
                          style={
                            admonFeePercentage &&
                            roundNumberUp2DP(admonFeePercentage) <=
                              values.admonFeeP
                              ? {
                                  border: "2px solid green",
                                }
                              : {
                                  border: "2px solid red",
                                }
                          }
                        >
                          <NumberFormat
                            value={roundNumberUp2DP(values.admonFeeP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* Sub Total */}
                <div style={{ marginTop: "20px" }}>
                  <table className="table table-bordered">
                    <tbody>
                      <tr
                        style={{
                          background: "black",
                          color: "white",
                          paddingLeft: "2px",
                        }}
                        className="dark-background"
                      >
                        <th
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            textAlign: "center",
                          }}
                        >
                          Sub Total
                        </th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.subTotalCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.subTotalNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.subTotalCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.subTotalNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.subTotalCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.subTotalNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.subTotalTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.subTotalTotalCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.subTotalTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.subTotalTotalNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.subTotalID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.subTotalP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* Other Income */}
                <div style={{ marginTop: "20px" }}>
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            textAlign: "center",
                          }}
                        >
                          Other Income
                        </th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.otherIncomeCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.otherIncomeNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.otherIncomeCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.otherIncomeNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.otherIncomeCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.otherIncomeNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.otherIncomeTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.otherIncomeCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.otherIncomeTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.otherIncomeNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.otherIncomeID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.otherIncomeP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* Net Profit */}
                <div style={{ marginTop: "20px" }}>
                  <table className="table table-bordered">
                    <tbody>
                      <tr
                        style={{
                          background: "black",
                          color: "white",
                          paddingLeft: "2px",
                        }}
                        className="dark-background"
                      >
                        <th
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            textAlign: "center",
                          }}
                        >
                          Net Profit
                        </th>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.netProfitCYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.netProfitNYQM1)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.netProfitCYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.netProfitNYQM2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.netProfitCYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.netProfitNYQM3)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.netProfitTotalCYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.netProfitTotalCYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.netProfitTotalNYQ)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.netProfitTotalNYQP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <NumberFormat
                            value={roundNumberUp(values.netProfitID)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        </td>
                        <td
                          className="bonus-input-wrapper"
                          style={
                            netProfitPercentage &&
                            roundNumberUp2DP(netProfitPercentage) <=
                              values.netProfitP
                              ? {
                                  border: "2px solid green",
                                }
                              : {
                                  border: "2px solid red",
                                }
                          }
                        >
                          <NumberFormat
                            value={roundNumberUp2DP(values.netProfitP)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"% "}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                style={{
                  marginTop: "30px",
                }}
              >
                {bonus && bonus.isCompleted !== true ? (
                  <div className="row">
                    <div className="col-md-3">
                      <Button
                        type="submit"
                        className="btn btn-block btn-primary font-weight-medium auth-form-btn"
                      >
                        Save and Complete &nbsp;&nbsp;
                        {isSubmitting ? (
                          // <Spinner animation="border" size="sm" />
                          <span className="fa fa-spin fa-spinner"></span>
                        ) : (
                          ""
                        )}
                      </Button>
                    </div>

                    <div className="col-md-3">
                      <Button
                        type="button"
                        className="btn btn-block btn-primary font-weight-medium auth-form-btn"
                        onClick={() => {
                          history.push("/create-bonus-payout", {
                            store: values.store_id,
                            year: values.year,
                            quarter: values.quarter,
                          });
                        }}
                      >
                        Edit Details &nbsp;&nbsp;
                      </Button>
                    </div>

                    {/* <div className="col-md-3">
                      <Button
                        variant="light m-2"
                        onClick={() => history.push("/bonus-payout")}
                      >
                        Cancel
                      </Button>
                    </div> */}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ViewBonusPayout;
