import Login from "../user-pages/Login";
import AdminLogin from "../user-pages/AdminLogin";
import ForgotPassword from "../user-pages/ForgotPassword";
import ResetPassword from "../user-pages/ResetPassword";
import FirstTimeLogin from "../user-pages/FirstTimeLogin";

const userPagesRoutes = [
  { path: "/home/login", component: Login },
  { path: "/home/admin/login", component: AdminLogin },
  { path: "/password/forgot-password", component: ForgotPassword },
  { path: "/password/reset-password/:token", component: ResetPassword },
  { path: "/auth/first-login", component: FirstTimeLogin },
];

export default userPagesRoutes;
