import React from "react";
import { Link } from "react-router-dom";
import { BigCircle } from "../../../components/AlertUtility";

const EmployeeList = ({ data }) => {
  return (
    <div className="col-md-4 grid-margin stretch-card mb-5" key={data.id}>
      <Link to={`/employees/view/${data.slug}`}>
        <div className="card">
          <div className="card-body">
            <div className="d-xl-flex flex-row">
              <BigCircle
                id=""
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_AZURE_STORAGE}/pictures/${data?.slug}),
                                      url(/svg/portrait.svg)`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              />
              <div className="ml-0 ml-xl-3 mt-2 mt-xl-0">
                <h6 className="text-dark">{`${data.first_name} ${data.last_name}`}</h6>
                <p className="text-muted">{`${data.email}`}</p>
                {/* <p className="mt-2 text-success font-weight-bold">
                  {`${data.company_details.job_status}`}
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default EmployeeList;
