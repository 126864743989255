import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import MultiStep from "react-multistep";
import Question1 from "./questions/Question1";
import Question2 from "./questions/Question2";
import Question3 from "./questions/Question3";
import Question4 from "./questions/Question4";
import Question5 from "./questions/Question5";
import Question6 from "./questions/Question6";
import Question7 from "./questions/Question7";
import Question8 from "./questions/Question8";
import Question9 from "./questions/Question9";
import Question10 from "./questions/Question10";
import Question11 from "./questions/Question11";
import Question12 from "./questions/Question12";
import Question13 from "./questions/Question13";
import Question14 from "./questions/Question14";

const CreateRateMyBoss = () => {
  const [managerName, setManagerName] = useState(null);
  const [langSurvey, setLangSurvey] = useState(null);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("rateMyBossDetails"))) {
      setManagerName(
        JSON.parse(localStorage.getItem("rateMyBossDetails")).manager_name
      );
    }

    if (JSON.parse(localStorage.getItem("surveyLang"))) {
      setLangSurvey(JSON.parse(localStorage.getItem("surveyLang")));
    }
  }, []);
  return (
    <div className="col-md-6 mx-auto" style={{ marginTop: "50px" }}>
      <div className="container form-manager-wrapper" style={{ width: "1140px" }}>
        <div>
          <p className="pull-right" style={{ textDecoration: "underline" }}>
            <a
              href="https://veltron.document360.io/v1/en"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn How &nbsp;<span className="fa fa-question"></span>
            </a>
          </p>

          <div className="clearfix"></div>
        </div>

        <div>
          <p>
            {" "}
            {langSurvey && langSurvey === "spanish" ? (
              <Link to="/employees/rate-your-leaders/begin/spanish">
                <span className="fa fa-caret-left"></span> &nbsp; &nbsp;Haz clic
                aquí para regresar
              </Link>
            ) : (
              <Link to="/employees/rate-your-leaders/begin/english">
                <span className="fa fa-caret-left"></span> &nbsp; &nbsp;Click here
                to go back
              </Link>
            )}
          </p>
        </div>

        <div className="row">
          <div className="col-md-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <div className="form-wizard" id="new-form-wizard">
                  <Question1 name={managerName} lang={langSurvey} />
                  <Question2 name={managerName} lang={langSurvey} />
                  <Question3 name={managerName} lang={langSurvey} />
                  <Question4 name={managerName} lang={langSurvey} />
                  <Question5 name={managerName} lang={langSurvey} />
                  <Question6 name={managerName} lang={langSurvey} />
                  <Question7 name={managerName} lang={langSurvey} />
                  <Question8 name={managerName} lang={langSurvey} />
                  <Question9 name={managerName} lang={langSurvey} />
                  <Question10 name={managerName} lang={langSurvey} />
                  <Question11 name={managerName} lang={langSurvey} />
                  <Question12 name={managerName} lang={langSurvey} />
                  <Question13 name={managerName} lang={langSurvey} />
                  <Question14 name={managerName} lang={langSurvey} />
                  {/* <steps /> */}
                  {/* <MultiStep
                  activeStep={1}
                  showNavigation={true}
                  steps={steps(managerName, langSurvey ? langSurvey : null)}
                /> */}
                  {/* <MultiStep
                  showNavigation={true}
                  className="my-class"
                  steps={steps(managerName, langSurvey ? langSurvey : null)}
                /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

const steps = (managerName, langSurvey) => [
  {
    name: ". Start",
    component: <Question1 name={managerName} lang={langSurvey} />,
  },
  { component: <Question2 name={managerName} lang={langSurvey} /> },
  { component: <Question3 name={managerName} lang={langSurvey} /> },
  { component: <Question4 name={managerName} lang={langSurvey} /> },
  { component: <Question5 name={managerName} lang={langSurvey} /> },
  { component: <Question6 name={managerName} lang={langSurvey} /> },
  { component: <Question7 name={managerName} lang={langSurvey} /> },
  { component: <Question8 name={managerName} lang={langSurvey} /> },
  { component: <Question9 name={managerName} lang={langSurvey} /> },
  { component: <Question10 name={managerName} lang={langSurvey} /> },
  { component: <Question11 name={managerName} lang={langSurvey} /> },
  { component: <Question12 name={managerName} lang={langSurvey} /> },
  { component: <Question13 name={managerName} lang={langSurvey} /> },
  { component: <Question14 name={managerName} lang={langSurvey} /> },
];

export default withRouter(CreateRateMyBoss);
