import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const Circle = styled(Link)`
  width: 40px;
  height: 40px;
  background: #d2d7d6;
  border-radius: 100%;
`;

export const Div = styled.div`
  width: 280px;
  padding: 50px 50px 50px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > header {
    display: flex;
    color: #141212;
    font-weight: bold;
  }

  > header ${Circle} {
    flex-shrink: 0;
  }

  > header > section {
    margin-left: 20px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 5px;
    overflow: hidden;
    white-space: nowrap;

    * {
      text-overflow: ellipsis;
    }
  }

  > header > section > a {
    color: #141212;
    text-decoration: none;
  }

  > nav {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 40px;
  }

  > nav > a,
  > section > button {
    appearance: none;
    color: #717a87;
    display: flex;
    align-items: center;
    font-size: 100%;
    background: transparent;
    outline: none;
    border: none;
    font-weight: bold;
    margin: 0;
    padding: 0;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }

    &.active {
      color: white;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 80px;
    padding: 80px 30px;

    > nav > a svg,
    > section > button svg {
      margin: 0;
    }

    ${Circle} {
      width: 20px;
      height: 20px;
    }

    > header > section,
    > nav > a span,
    > section > button span {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    width: 60px;
    padding: 20px;
  }
`;

const SidebarHeader = ({ user }) => {
  if (!user) return null;
  return (
    <li className="nav-item nav-profile">
      <Div>
        <header>
          <Circle
            to={`/employees/view/${user?.slug}`}
            style={{
              backgroundImage: user?.slug
                ? `url(${process.env.REACT_APP_AZURE_STORAGE}/pictures/${user?.slug})`
                : `url(/svg/portrait.svg)`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          />
          <section>
            <span>{user.first_name + " " + user.last_name}</span>
            <Link to={`/employee/view/my-profile/${user.slug}`}>
              <small>View Profile</small>
            </Link>
          </section>
        </header>
      </Div>
    </li>
  );
};

export default SidebarHeader;
