import React, { useEffect, useState } from "react";
import { SystemConfigService } from "../../service/configService";
import { withRouter, Link } from "react-router-dom";
import AppSpinner from "../../components/Spinner";

const ScheduledPayments = () => {
  const [stores, setStores] = useState(null);

  useEffect(() => {
    SystemConfigService.getStoresForManagerAdmin()
      .then((response) => {
        console.log(response.data);
        setStores(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);

  return (
    <div>
      <div className="row">
        <div className="tab-custom-pills-horizontal col-md-12">
          <div className="float-left">
            <span className="card-description"> Scheduled Payments </span>{" "}
            &nbsp;&nbsp;&nbsp;
            <small className="card-description">
              <i>
                ( Click on the box to view scheduled payments for each store )
              </i>
            </small>
          </div>
          <div className="float-right"></div>
        </div>
        <div className="clearfix"></div>
      </div>

      <div className="mt-3">
        <div className="row">
          {stores ? (
            stores.map((value, index) => (
              <div className="col-md-3 mb-5" key={index}>
                <div className="domainBox">
                  <Link
                    to={`/financials/view-scheduled-payments-store/${value.location_id}`}
                  >
                    <div className="react-aspect-ratio-placeholder">
                      <div className="sc-fzoiQi ozSmQ  ">
                        <main>
                          <h4 style={{ textTransform: "capitalize" }}>
                            {" "}
                            {value.name}
                          </h4>
                          <small>{value.address}</small>
                          <br />
                          <br />
                          <small>
                            <b>({value.location_id})</b>
                          </small>
                        </main>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))
          ) : (
            <AppSpinner />
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(ScheduledPayments);
