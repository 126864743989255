import { Formik } from "formik";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { PaymentService } from "../../../../../service/PaymentService";


const ShowIssuesModal = ({ salesReport, showIssuesModal, setShowIssuesModal }) => {

    let history = useHistory()
    return (
        <>
            <div>
                <Formik
                    initialValues={{
                        store_id: salesReport ? salesReport.owner : "",
                        message: "",
                        reportDate: salesReport ? salesReport.report_date : "",
                        type: "payment",
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        console.log(values);

                        PaymentService.postIssues(values)
                            .then((response) => {
                                console.log(response.data.data);

                                Swal.fire({
                                    title: "Successful!",
                                    text: "Thanks",
                                    icon: "success",
                                    allowOutsideClick: false,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        localStorage.removeItem("salesReport");
                                        localStorage.removeItem("tipDetailsReport");
                                        localStorage.removeItem("tipDetailsBartender");
                                        localStorage.removeItem("tipIncomeReport");
                                        localStorage.removeItem("barTenderPayment");
                                        localStorage.removeItem("cateringPayments");
                                        localStorage.removeItem("laborInfoDetails");
                                        localStorage.removeItem("mergedEmpDetails");
                                        localStorage.removeItem("togoPayments");

                                        localStorage.removeItem("doorDashPaymentDetails");
                                        localStorage.removeItem("togoCalculatedPayments");
                                        localStorage.removeItem("otherEmpdetails");
                                        localStorage.removeItem("laborBartenders");
                                        localStorage.removeItem("atoPaymentDetails");
                                        localStorage.removeItem("storeDetails");
                                        localStorage.removeItem("atoDoorDashTips");
                                        localStorage.removeItem("atoOption");
                                        localStorage.removeItem("expenses");
                                        history.push("/dashboard");
                                    }
                                });
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }}
                    enableReinitialize={true}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <Modal
                            show={showIssuesModal}
                            onHide={() => setShowIssuesModal(false)}
                            aria-labelledby="example-modal-sizes-title-md"
                            style={{ top: "200px" }}
                            backdrop="static"
                        >
                            <form name="form" onSubmit={handleSubmit}>
                                <Modal.Header closeButton>
                                    <Modal.Title> Send Message to Site Admin </Modal.Title>
                                </Modal.Header>

                                <Modal.Body style={{ paddingBottom: "0px" }}>
                                    <Form.Group>
                                        <label htmlFor="archived_message">Details</label>

                                        <Form.Control
                                            type="text"
                                            as="textarea"
                                            rows="6"
                                            cols="50"
                                            className="form-control"
                                            id="message"
                                            name="message"
                                            value={values.message}
                                            onChange={handleChange}
                                            disabled={isSubmitting}
                                        ></Form.Control>
                                    </Form.Group>
                                </Modal.Body>

                                <Modal.Footer
                                    className="flex-wrap"
                                    style={{ paddingTop: "0px" }}
                                >
                                    {" "}
                                    <Button
                                        type="submit"
                                        className="btn btn-block btn-primary font-weight-medium auth-form-btn"
                                        disabled={isSubmitting}
                                    >
                                        Proceed &nbsp;&nbsp;
                                        {isSubmitting ? (
                                            // <Spinner animation="border" size="sm" />
                                            <span className="fa fa-spin fa-spinner"></span>
                                        ) : (
                                            ""
                                        )}
                                    </Button>
                                    <Button
                                        variant="light m-2"
                                        onClick={() => setShowIssuesModal(false)}
                                    >
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </form>
                        </Modal>
                    )}
                </Formik>
            </div>
        </>
    )
}

export default ShowIssuesModal;