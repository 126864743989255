import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import { SystemConfigService } from "../../service/configService";
import { Formik } from "formik";
import toastMessages from "../../Helpers/notifications";

const SelectViewBonus = () => {
  const history = useHistory();

  const [storeOptions, setStoreOptions] = useState(null);

  useEffect(() => {
    SystemConfigService.getStores()
      .then((response) => {
        setStoreOptions(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> </h3>
        <nav aria-label="breadcrumb">
          {/* <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/system-configuration/all"}>Portal configuration</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Store
            </li>
          </ol> */}
        </nav>
      </div>
      <Formik
        initialValues={{
          store: "",
          year: "",
          quarter: "",
          store2: "",
          year2: "",
          quarter2: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          if (values.year_1 && values.year_2 && values.quarter_1) {
            history.push("/bonus/view-bulk-upload-breakdown", {
              year_1: values.year_1,
              year_2: values.year_2,
              quarter: values.quarter_1,
              storeId: 1,
            });
          } else {
            toastMessages.error("Please fill all the fields");
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <h4
                  className="card-title"
                  style={{
                    marginBottom: "20px",
                    marginTop: "20px",
                    textAlign: "center",
                  }}
                >
                  Compare and Compute
                </h4>
                <div className="card-body">
                  <form
                    className="forms-sample"
                    name="form"
                    onSubmit={handleSubmit}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group>
                          <label htmlFor="store"> Store Name </label>
                          <Form.Control
                            type="text"
                            as="select"
                            className="form-control"
                            id="store"
                            name="store"
                            value={values.store ? values.store : "DEFAULT"}
                            onChange={handleChange}
                            disabled={isSubmitting}
                          >
                            <option value="DEFAULT" disabled>
                              Select One
                            </option>
                            <option value="1">
                              LA PARRILLA (MARIETTA SQUARE)
                            </option>
                          </Form.Control>
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="year"> Year</label>
                          <Form.Control
                            type="text"
                            as="select"
                            className="form-control"
                            id="year_1"
                            name="year_1"
                            value={values.year_1 ? values.year_1 : "DEFAULT"}
                            onChange={handleChange}
                            disabled={isSubmitting}
                          >
                            <option value="DEFAULT" disabled>
                              Select One
                            </option>

                            <option value={"2021"}> 2021 </option>
                          </Form.Control>
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="quarter">Quarter</label>
                          <Form.Control
                            type="text"
                            as="select"
                            className="form-control"
                            id="quarter_1"
                            name="quarter_1"
                            value={
                              values.quarter_1 ? values.quarter_1 : "DEFAULT"
                            }
                            onChange={handleChange}
                            disabled={isSubmitting}
                          >
                            <option value="DEFAULT" disabled>
                              Select One
                            </option>

                            <option value={"1"}>Q1</option>
                            <option value={"2"}>Q2</option>
                            <option value={"3"}>Q3</option>
                            <option value={"4"}>Q4</option>
                          </Form.Control>
                        </Form.Group>
                      </div>

                      <div className="col-md-6">
                        <Form.Group>
                          <label htmlFor="store2"> Store Name </label>
                          <Form.Control
                            type="text"
                            as="select"
                            className="form-control"
                            id="store2"
                            name="store2"
                            value={values.store2 ? values.store2 : "DEFAULT"}
                            onChange={handleChange}
                            disabled={isSubmitting}
                          >
                            <option value="DEFAULT" disabled>
                              Select One
                            </option>
                            <option value="1">
                              LA PARRILLA (MARIETTA SQUARE)
                            </option>
                          </Form.Control>
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="year"> Year</label>
                          <Form.Control
                            type="text"
                            as="select"
                            className="form-control"
                            id="year_2"
                            name="year_2"
                            value={values.year_2 ? values.year_2 : "DEFAULT"}
                            onChange={handleChange}
                            disabled={isSubmitting}
                          >
                            <option value="DEFAULT" disabled>
                              Select One
                            </option>

                            <option value={"2022"}> 2022 </option>
                          </Form.Control>
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="quarter">Quarter</label>
                          <Form.Control
                            type="text"
                            as="select"
                            className="form-control"
                            id="quarter_2"
                            name="quarter_2"
                            value={
                              values.quarter_2 ? values.quarter_2 : "DEFAULT"
                            }
                            onChange={handleChange}
                            disabled={isSubmitting}
                          >
                            <option value="DEFAULT" disabled>
                              Select One
                            </option>

                            <option value={"1"}>Q1</option>
                            <option value={"2"}>Q2</option>
                            <option value={"3"}>Q3</option>
                            <option value={"4"}>Q4</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                    </div>

                    <div>
                      <button type="submit" className="btn btn-primary mr-2">
                        {isSubmitting ? "Loading..." : " Compare Results"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default withRouter(SelectViewBonus);
