import React from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import { SystemConfigService } from "../../../service/configService";
import cogoToast from "cogo-toast";
import { Formik } from "formik";

const Corporation = () => {
  const history = useHistory();

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Corporation </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/system-configuration/all"}>Portal configuration</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Store
            </li>
          </ol>
        </nav>
      </div>
      <Formik
        initialValues={{
          name: "",
          headquarters_address: "",
          state: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          if (values.name && values.headquarters_address && values.state) {
            SystemConfigService.createCorporation(values)
              .then((response) => {
                console.log(response.data);
                if (response.data.success) {
                  setSubmitting(false);
                  cogoToast.success(
                    "Corporation created successfully",
                    toastoptions
                  );
                  history.push("/system-configuration/view-corporations");
                }
              })
              .catch((error) => {
                if (error.response.data.errors) {
                  cogoToast.error(
                    error.response.data.errors.name[0],
                    toastoptions
                  );
                }
                cogoToast.error(error.response.data.message, toastoptions);
                setSubmitting(false);
              });
          } else {
            cogoToast.error("Please fill all the fields", toastoptions);
            setSubmitting(false);
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
          <div className="row">
            <div className="col-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">
                    Fill the details below to create a Corporation
                  </h4>
                  <form
                    className="forms-sample"
                    name="form"
                    onSubmit={handleSubmit}
                  >
                    <Form.Group>
                      <label htmlFor="name">Name</label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        disabled={isSubmitting}
                        placeholder="Corporation Name"
                      />
                    </Form.Group>
                    <Form.Group>
                      <label htmlFor="headquarters_address">
                        Headquarter Address
                      </label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="headquarters_address"
                        placeholder="Headquarters Address"
                        name="headquarters_address"
                        value={values.headquarters_address}
                        onChange={handleChange}
                        disabled={isSubmitting}
                      />
                    </Form.Group>
                    <Form.Group>
                      <label htmlFor="state">State</label>
                      <Form.Control
                        type="text"
                        as="select"
                        className="form-control"
                        id="state"
                        placeholder="State"
                        name="state"
                        value={values.state ? values.state : "DEFAULT"}
                        onChange={handleChange}
                        disabled={isSubmitting}
                      >
                        <option value="DEFAULT" disabled>
                          {" "}
                          Select One{" "}
                        </option>
                        <option value="AK"> Alaska - AK </option>
                        <option value="AZ"> Arizona - AZ </option>
                        <option value="AR"> Arkansas - AR </option>
                        <option value="CA"> California - CA </option>
                        <option value="CO"> Colorado - CO </option>
                        <option value="CT"> Connecticut - CT </option>
                        <option value="DE"> Delaware - DE </option>
                        <option value="FL"> Florida - FL </option>
                        <option value="GA"> Georgia - GA </option>
                        <option value="HI"> Hawaii - HI </option>
                        <option value="ID"> Idaho - ID </option>
                        <option value="IL"> Illinois - IL </option>
                        <option value="IN"> Indiana - IN </option>
                        <option value="IA"> Iowa - IA </option>
                        <option value="KS"> Kansas - KS </option>
                        <option value="KY"> Kentucky - KY </option>
                        <option value="LA"> Louisiana - LA </option>
                        <option value="ME"> Maine - ME </option>
                        <option value="MD"> Maryland - MD </option>
                        <option value="MA"> Massachusetts - MA </option>
                        <option value="MI"> Michigan - MI </option>
                        <option value="MN"> Minnesota - MN </option>
                        <option value="MS"> Mississippi - MS </option>
                        <option value="MO"> Missouri - MO </option>
                        <option value="MT"> Montana - MT </option>
                        <option value="NE"> Nebraska - NE </option>
                        <option value="NV"> Nevada - NV </option>
                        <option value="NH"> New Hampshire - NH </option>
                        <option value="NJ"> New Jersey - NJ </option>
                        <option value="NM"> New Mexico - NM </option>
                        <option value="NY"> New York - NY </option>
                        <option value="NC"> North Carolina - NC </option>
                        <option value="ND"> North Dakota - ND </option>
                        <option value="OH"> Ohio - OH </option>
                        <option value="OK"> Oklahoma - OK </option>
                        <option value="OR"> Oregon - OR </option>
                        <option value="PA"> Pennsylvania - PA </option>
                        <option value="RI"> Rhode Island - RI </option>
                        <option value="SC"> South Carolina - SC </option>
                        <option value="SD"> South Dakota - SD </option>
                        <option value="TN"> Tennessee - TN </option>
                        <option value="TX"> Texas - TX </option>
                        <option value="UT"> Utah - UT </option>
                        <option value="VT"> Vermont - VT </option>
                        <option value="VA"> Virginia - VA </option>
                        <option value="WA"> Washington - WA </option>
                        <option value="WV"> West Virginia - WV </option>
                        <option value="AZ"> Wisconsin - WI </option>
                        <option value="MY"> Wyoming - WY </option>
                      </Form.Control>
                    </Form.Group>

                    <button type="submit" className="btn btn-primary mr-2">
                      Submit &nbsp;&nbsp;
                      {isSubmitting ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        ""
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default withRouter(Corporation);
