import React, { useEffect, useState } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { PaymentService } from "../../service/PaymentService";
import { SystemConfigService } from "../../service/configService";
import { ConvertDateFormat } from "../../components/ConvertDateFormat";
const { SearchBar } = Search;

const StorePaymentHistories = () => {
  let { store_id } = useParams();
  const [store, setStore] = useState(null);
  const [paymentHistory, setPaymentHistory] = useState(null);

  useEffect(() => {
    PaymentService.getPaymentHistories(store_id)
      .then((response) => {
        console.log(response.data.data);
        let PaymentResArray = [];
        response.data.data.map((res, index) => {
          return PaymentResArray.push({
            id: res.id,
            payrollEmployeeId: res.payrollEmployeeId,
            firstName: res.firstName,
            lastName: res.lastName,
            amount: res.amount,
            reportDate: ConvertDateFormat(res.reportDate),
            allocationDate: ConvertDateFormat(res.allocationDate),
            message: res.message,
            status: res.status,
          });
        });
        setPaymentHistory(PaymentResArray);
      })
      .catch((error) => {
        console.log(error.response.data);
      });

    SystemConfigService.getStore(store_id)
      .then((response) => {
        console.log(response.data.data);
        setStore(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        // cogoToast.error(error.response.data.message, toastoptions);
      });
  }, []);

  const columns = [
    {
      dataField: "payrollEmployeeId",
      text: "Employee Id",
      sort: true,
    },
    {
      dataField: "firstName",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "lastName",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
    },
    {
      dataField: "reportDate",
      text: "Report Date",
      sort: true,
    },
    {
      dataField: "allocationDate",
      text: "Allocation Date",
      sort: true,
    },
    {
      dataField: "message",
      text: "Message",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: () => {
        return <label className="badge badge-success">Completed</label>;
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">
          <span style={{ textTransform: "capitalize" }}>Payments</span>
        </h3>

        <div>
          <Link to={"/financials/payment-history"}>
            <button className="btn btn-primary">
              View All Payments Histories
            </button>
          </Link>
        </div>

        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/financials/payment-history">
                <span style={{ textTransform: "capitalize" }}>
                  {" "}
                  {store ? store.name : ""}
                </span>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <span style={{ textTransform: "capitalize" }}>
                Payments Histories
              </span>
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            {paymentHistory && (
              <div className="card-body">
                <h4 className="card-title"> Payments History</h4>
                <div className="row">
                  <div className="col-12">
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={paymentHistory}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div className="d-flex align-items-center">
                            <p className="mb-2 mr-2">Search in table:</p>
                            <SearchBar {...props.searchProps} />
                          </div>
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(StorePaymentHistories);
