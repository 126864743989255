import React, { useEffect, useState } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Swal from "sweetalert2";
import cogoToast from "cogo-toast";
import { BonusPayoutService } from "../../../service/BonusPayoutService";
import { Modal } from "react-bootstrap";

const { SearchBar } = Search;

const SurveyForLeaders = () => {
  const [surveys, setSurveys] = useState(null);
  const [mdShow, setMdShow] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);

  useEffect(() => {
    BonusPayoutService.getSurveyData()
      .then((response) => {
        console.log(response.data.data);
        let SurveyArray = [];
        response.data.data.map((res, index) => {
          return SurveyArray.push({
            id: res.id,
            managerName: res.manager_name,
            reviewerName: res.reviewer_name,
            year: res.year,
          });
        });
        setSurveys(SurveyArray);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);

  const defaultSorted = [
    {
      dataField: "payrollEmployeeId",
      order: "desc",
    },
  ];

  const surveyColumn = [
    {
      dataField: "managerName",
      text: "Manager's Name",
      sort: true,
    },
    {
      dataField: "reviewerName",
      text: "Reviewer's Name",
      sort: true,
    },
    {
      dataField: "year",
      text: "Year",
      sort: true,
    },
  ];

  const downloadExcelFile = () => {
    Swal.fire({
      title: "In progress",
      text: "Please wait...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });

    BonusPayoutService.downloadSurveyExcel()
      .then((response) => {
        Swal.close();
        setMdShow(true);
        setFileUrl(response.data.data.url);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div>
        <p className="pull-right" style={{ textDecoration: "underline" }}>
          <a
            href="https://veltron.document360.io/v1/en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn How &nbsp;<span className="fa fa-question"></span>
          </a>
        </p>

        <div className="clearfix"></div>
      </div>

      <div className="page-header">
        <h3 className="page-title">
          <span style={{ textTransform: "capitalize" }}>
            List of Survey For Leaders
          </span>
        </h3>
        <div>
          <button className="btn btn-primary" onClick={downloadExcelFile}>
            {" "}
            Export to Excel
          </button>
        </div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/bonus/survey-for-leaders">
                <span style={{ textTransform: "capitalize" }}> Parrilla </span>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <span style={{ textTransform: "capitalize" }}>Surveys</span>
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-12">
          {surveys && (
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"> Survey For Leaders </h4>
                <div className="row">
                  <div className="col-12">
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={surveys}
                      columns={surveyColumn}
                      search
                    >
                      {(props) => (
                        <div>
                          <div className="d-flex align-items-center">
                            <div className="float-left">
                              <p className="mb-2 mr-2">Search in table:</p>
                              <SearchBar {...props.searchProps} />
                            </div>
                          </div>
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            loading={true}
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                            id="pending-table"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="row">
        <Modal
          show={mdShow}
          onHide={() => setMdShow(false)}
          aria-labelledby="example-modal-sizes-title-md"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {fileUrl && (
              <div className="row">
                <div className="col-md-12">
                  <a download href={fileUrl}>
                    <span> Click here to download the Excel File </span>
                  </a>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default SurveyForLeaders;
