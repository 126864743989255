export const CateringCode = [
  "999100",
  "999101",
  "999102",
  "999103",
  "999104",
  "999105",
  "999106",
  "999107",
  "999108",
  "999109",
  "999110",
  "999111",
  "999112",
  "999113",
  "999114",
  "999115",
  "999116",
  "999117",
  "999118",
  "999119",
  "999120",
];
