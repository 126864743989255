import React from "react";
import { Col, Row, Nav, Tab } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import ViewKitchenManager from "./manager/Kitchen";
import ViewFohManager from "./manager/Foh";
import ViewBeverageManager from "./manager/Beverage";
import ViewGeneralManager from "./manager/General";

const KPIDefaultPage = () => {
  return (
    <div>
      <div>
        <p className="pull-right" style={{ textDecoration: "underline" }}>
          <a
            href="https://veltron.document360.io/v1/en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn How &nbsp;<span className="fa fa-question"></span>
          </a>
        </p>

        <div className="clearfix"></div>
      </div>

      <div className="page-header">
        <h3 className="page-title">Bonus Payout Configuration</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="!#" onClick={(event) => event.preventDefault()}>
                {" "}
                KPI Configuration
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              all
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12 stretch-card">
          <div className="card">
            <div className="card-body">
              <p className="card-description">
                The list of settings for Bonus Payout KPI for managers
              </p>
              <div className="tab-custom-pills-horizontal">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="general"
                >
                  <Row>
                    <Col xs={12}>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="general"
                            className="d-flex align-items-center"
                          >
                            General Manager
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="foh"
                            className="d-flex align-items-center"
                          >
                            FOH Manager
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="beverage"
                            className="d-flex align-items-center"
                          >
                            Beverage Manager
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="kitchen"
                            className="d-flex align-items-center"
                          >
                            Kitchen Manager
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col xs={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="general">
                          <ViewGeneralManager />
                        </Tab.Pane>
                        <Tab.Pane eventKey="foh">
                          <ViewFohManager />
                        </Tab.Pane>
                        <Tab.Pane eventKey="beverage">
                          <ViewBeverageManager />
                        </Tab.Pane>
                        <Tab.Pane eventKey="kitchen">
                          <ViewKitchenManager />
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(KPIDefaultPage);
