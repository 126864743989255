import React from "react";
import { withRouter, Link } from "react-router-dom";

const DefaultPage = () => {
  return (
    <div>
      <div className="row">
        <div className="col-md-3 mb-5">
          <Link to={`/system-configuration/bonus/view`}>
            <div className="domainBox">
              <div className="react-aspect-ratio-placeholder">
                <div className="sc-fzoiQi ozSmQ  ">
                  <main style={{ position: "relative" }}>
                    <p>View Configuration</p>
                  </main>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-md-3 mb-5">
          <Link to={`/system-configuration/bonus/create-config`}>
            <div className="domainBox">
              <div className="react-aspect-ratio-placeholder">
                <div className="sc-fzoiQi ozSmQ  ">
                  <main style={{ position: "relative" }}>
                    <p>Create Configuration</p>
                  </main>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withRouter(DefaultPage);
