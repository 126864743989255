import React, { useEffect } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { useState } from "react";
import { Formik } from "formik";
import { UserService } from "../../../../../service/userService";
import { SystemConfigService } from "../../../../../service/configService";
import cogoToast from "cogo-toast";
import { Utility } from "../../../../../Helpers/utils";
import MoveModal from "./components/MoveModal";

const Company = ({ employee, user }) => {
  const [editMode, setEditMode] = useState(false);
  const [employees, setEmployees] = useState(null);
  const [store, setStore] = useState(null);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [viewSalary, setViewSalary] = useState(false);
  // const [showStorePermissionModal, setShowStorePermissionModal] = useState(
  //   false
  // );
  const [jobCode, setJobCode] = useState(null);
  const [storeOptions, setStoreOptions] = useState(null);
  const [defaultStores, setDefaultStores] = useState(null);

  useEffect(() => {
    UserService.getEmployees()
      .then((response) => {
        setEmployees(response.data.data.activeEmployees);
      })
      .catch((error) => {
        console.log(error.response.data);
      });

    let storeOptionsArray = [];
    SystemConfigService.getStores()
      .then((response) => {
        let val = response.data.data;
        val.map((i, index) => {
          return storeOptionsArray.push({
            value: i.id,
            label: i.name.replace(/\w\S*/g, (w) =>
              w.replace(/^\w/, (c) => c.toUpperCase())
            ),
          });
        });
        setStoreOptions(storeOptionsArray);
        setStore(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });

    // SystemConfigService.getJobCodes()
    //   .then((response) => {
    //     setJobCode(response.data.data);
    //   })
    //   .catch((error) => {
    //     console.log(error.response.data);
    //   });

    let defaultOptionsArray = [];
    SystemConfigService.getPermittedStoreForManagerAdmin({ id: employee.id })
      .then((response) => {
        console.log(response.data.data);
        response.data.data.map((i, index) => {
          return defaultOptionsArray.push({
            value: i.id,
            label: i.name.replace(/\w\S*/g, (w) =>
              w.replace(/^\w/, (c) => c.toUpperCase())
            ),
          });
        });
        setDefaultStores(defaultOptionsArray);
      })
      .catch((error) => {
        console.log(error);
      });

    // setUser(Utility.getUser());
  }, []);

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  return (
    <div className="profile-feed">
      <div className="mt-3 mb-3">
        <div className="pull-left">
          <h2> Position Details</h2>
        </div>

        <div className="pull-right">
          {!editMode && (
            <span>
              {user && (user.role === "super admin" || user.role === "hr") ? (
                <span>
                  {/* <button
                    type="button"
                    className="btn btn-dark btn-icon-text"
                    onClick={() => {
                      setShowStorePermissionModal(true);
                    }}
                  >
                    Store Permissions <i className="mdi mdi-block-helper"></i>
                  </button> */}
                  {/* <button
                    type="button"
                    className="btn btn-dark btn-icon-text"
                    onClick={() => {
                      setShowMoveModal(true);
                    }}
                  >
                    Move Employee{" "}
                    <i className="mdi mdi-send btn-icon-append"></i>
                  </button> */}
                </span>
              ) : (
                ""
              )}
            </span>
          )}

          {!editMode && (
            <span>
              {user && user.role === "super admin" ? (
                <button
                  type="button"
                  className="btn btn-dark btn-icon-text"
                  onClick={() => {
                    setEditMode(true);
                  }}
                >
                  {" "}
                  Edit <i className="mdi mdi-file-check btn-icon-append"></i>
                </button>
              ) : (
                ""
              )}
            </span>
          )}
        </div>
      </div>

      <div className="clearfix"></div>

      {showMoveModal && (
        <>
          <MoveModal
            employee={employee}
            user={user}
            showMoveModal={showMoveModal}
            setShowMoveModal={setShowMoveModal}
          />
        </>
      )}

      <Formik
        initialValues={{
          user_id: employee.personal_details
            ? employee.personal_details.user_id
            : "",
          user_slug: employee.personal_details
            ? employee.personal_details.user_slug
            : "",
          role: employee.company_details ? employee.company_details.role : "",
          employment_status: employee.company_details
            ? employee.company_details.employment_status
            : "",
          job_status: employee.company_details
            ? employee.company_details.job_status
            : "",
          date_hired: employee.company_details
            ? employee.company_details.date_hired
            : "",
          payroll_id_number: employee.company_details
            ? employee.company_details.payroll_id_number
            : "",
          primary_location_code: employee.company_details
            ? employee.company_details.primary_location_code
            : "",
          uniform_deduction_amount: employee.company_details
            ? employee.company_details.uniform_deduction_amount
            : "",
          meal_program_deduction: employee.company_details
            ? employee.company_details.meal_program_deduction
            : "",
          pay_type: employee.company_details
            ? employee.company_details.pay_type
            : "",
          manager_type: employee.company_details
            ? employee.company_details.manager_type
            : "",
          salary: employee.company_details
            ? employee.company_details.salary
            : "",
          pay_schedule: employee.company_details
            ? employee.company_details.pay_schedule
            : "",
          skills_earned: employee.company_details
            ? employee.company_details.skills_earned
            : "",
          thanks_badges: employee.company_details
            ? employee.company_details.thanks_badges
            : "",
          reason_for_termination: employee.company_details
            ? employee.company_details.reason_for_termination
            : "",
          eligible_for_rehire: employee.company_details
            ? employee.company_details.eligible_for_rehire
            : "",
          manager_id: employee.company_details
            ? employee.company_details.manager_id
            : "",
          union_code: employee.company_details
            ? employee.company_details.union_code
            : "",
          minor_labour_program_code: employee.company_details
            ? employee.company_details.minor_labour_program_code
            : "",
          salary_amount: employee.company_details
            ? employee.company_details.salary_amount
            : "",
          sick_leave_hours: employee.company_details
            ? employee.company_details.sick_leave_hours
            : "",
          part_time_flag: employee.company_details
            ? employee.company_details.part_time_flag
            : "",
          break_waiver: employee.company_details
            ? employee.company_details.break_waiver
            : "",
          next_review_date: employee.company_details
            ? employee.company_details.next_review_date
            : "",
          broadcast_schedule_flag: employee.company_details
            ? employee.company_details.broadcast_schedule_flag
            : "",
          store_id: employee.company_details
            ? employee.company_details.store_id
            : "DEFAULT",
          employee_number: employee.company_details
            ? employee.company_details.employee_number
            : "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          UserService.updateCompanyDetails(values)
            .then((response) => {
              console.log(response);
              setSubmitting(false);
              cogoToast.success(
                "Company details updated successfully",
                toastoptions
              );
              setEditMode(false);
              window.location.reload();
            })
            .catch((error) => {
              console.log(error.response);
              // cogoToast.error(error.response.data.message, toastoptions);
              setSubmitting(false);
            });
        }}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
          <div className="mt-3 mb-3">
            <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div
                  className="card"
                  style={{
                    border: "none",
                  }}
                >
                  <div className="card-body">
                    <form className="pageForms" onSubmit={handleSubmit}>
                      <div className="row border-bottom mb-5">
                        <div className="col-md-6">
                          <Form.Group>
                            <label htmlFor="store">
                              Current Active Store
                              <span className="text-danger"> * </span>
                            </label>
                            <select
                              className="form-control"
                              id="store_id"
                              name="store_id"
                              value={values.store_id ? values.store_id : ""}
                              onChange={handleChange}
                              disabled={!editMode}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              style={{ textTransform: "capitalize" }}
                            >
                              <option disabled value={"DEFAULT"}>
                                Select Store
                              </option>
                              {store &&
                                store.map((value) => (
                                  <option
                                    value={value.location_id}
                                    key={value.location_id}
                                  >
                                    {value.name}
                                  </option>
                                ))}
                            </select>
                          </Form.Group>
                          <Form.Group>
                            <label htmlFor="status">Employment Status</label>
                            <Form.Control
                              as="select"
                              type="text"
                              className="form-control"
                              id="status"
                              disabled={"disable"}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              name="employment_status"
                              onChange={handleChange}
                              defaultValue={
                                values.employment_status
                                  ? values.employment_status
                                  : "DEFAULT"
                              }
                            >
                              <option value="DEFAULT" disabled>
                                {" "}
                                Select One{" "}
                              </option>
                              <option value="active"> Active </option>
                              <option value="leave"> On Leave </option>
                              <option value="terminated"> Terminated </option>
                              <option value="archived"> Archived </option>
                            </Form.Control>
                          </Form.Group>

                          <Form.Group>
                            <label htmlFor="status">Job Status</label>
                            <Form.Control
                              as="select"
                              type="text"
                              className="form-control"
                              id="status"
                              disabled={!editMode}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              name="job_status"
                              value={
                                values.job_status
                                  ? values.job_status
                                  : "DEFAULT"
                              }
                              onChange={handleChange}
                            >
                              <option value="DEFAULT" disabled>
                                {" "}
                                Select One{" "}
                              </option>
                              <option value="part time"> Part Time </option>
                              <option value="full time"> Full Time </option>
                              <option value="hourly"> Hourly </option>
                              <option value="salary exempt">
                                {" "}
                                Salary Exempt{" "}
                              </option>
                              <option value="salary non exempt">
                                {" "}
                                Salary Non Exempt{" "}
                              </option>
                            </Form.Control>
                          </Form.Group>
                          <Form.Group>
                            <div>
                              <label htmlFor="dateHired">Date Hired</label>
                            </div>
                            <Form.Control
                              type="date"
                              id="dateHired"
                              className="form-control w-100"
                              name="date_hired"
                              disabled={!editMode}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              defaultValue={values.date_hired}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <label htmlFor="employeeNumber">
                              Employee Number
                            </label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="employeeNumber"
                              placeholder="Employee number"
                              disabled={"disabled"}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              name="employee_number"
                              defaultValue={values.employee_number}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <label htmlFor="payrollIdNumber">
                              Payroll ID Number
                            </label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="payrollIdNumber"
                              placeholder="Payrol ID Number"
                              disabled={!editMode}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              defaultValue={values.payroll_id_number}
                              onChange={handleChange}
                              name="payroll_id_number"
                            />
                          </Form.Group>
                          <Form.Group>
                            <label htmlFor="primaryLocationCode">
                              Primary Location Code
                            </label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="primaryLocationCode"
                              placeholder="Primary Location Code"
                              disabled={!editMode}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              onChange={handleChange}
                              name="primary_location_code"
                              defaultValue={values.primary_location_code}
                            />
                          </Form.Group>
                        </div>

                        <div className="col-md-6">
                          <Form.Group>
                            <label htmlFor="role"> Role </label>
                            <select
                              className="form-control"
                              id="role"
                              name="role"
                              value={values.role ? values.role : ""}
                              onChange={handleChange}
                              disabled={
                                editMode && user && user.role === "super admin"
                                  ? ""
                                  : "disable"
                              }
                            >
                              <option disabled value={""}>
                                Select Role
                              </option>
                              <option
                                value={"super admin"}
                                disabled={"disable"}
                              >
                                Super Admin
                              </option>
                              <option value={"accounting"}>Accounting</option>
                              <option value={"operations"}>Operations</option>
                              <option value={"restaurant manager"}>
                                Restaurant Manager
                              </option>
                              <option value={"district manager"}>
                                District Manager
                              </option>
                              <option value={"service manager"}>
                                Service Manager
                              </option>
                              <option value={"hr"}>HR</option>
                              <option value={"employee"}>Employee</option>
                              <option value={"catering"}>Catering</option>
                            </select>
                          </Form.Group>

                          <Form.Group>
                            <label htmlFor="pay_type"> Pay Type</label>
                            <Form.Control
                              as="select"
                              type="text"
                              className="form-control"
                              id="pay_type"
                              placeholder="Pay Type"
                              name="pay_type"
                              disabled={!editMode}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              onChange={handleChange}
                              defaultValue={
                                values.pay_type ? values.pay_type : "DEFAULT"
                              }
                            >
                              <option value="DEFAULT" disabled>
                                {" "}
                                Select One{" "}
                              </option>
                              <option value="salary"> Salary </option>
                              <option value="hourly"> Hourly </option>
                              <option value="commission"> Commission </option>
                              <option value="contract"> Contract </option>
                            </Form.Control>
                          </Form.Group>

                          <Form.Group>
                            <label htmlFor="manager_type"> Manager Type</label>
                            <Form.Control
                              as="select"
                              type="text"
                              className="form-control"
                              id="manager_type"
                              placeholder="Manager Type"
                              name="manager_type"
                              disabled={!editMode}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              onChange={handleChange}
                              defaultValue={
                                values.manager_type
                                  ? values.manager_type
                                  : "DEFAULT"
                              }
                            >
                              <option value="DEFAULT" disabled>
                                {" "}
                                Select One{" "}
                              </option>
                              <option value="general"> General </option>
                              <option value="foh"> FOH </option>
                              <option value="beverage"> Beverage </option>
                              <option value="kitchen"> Kitchen </option>
                            </Form.Control>
                          </Form.Group>

                          {(user && user.role === "super admin") ||
                          (user && user.role === "district manager") ? (
                            <div>
                              {/* {values.salary && viewSalary ? ( */}
                              <Form.Group>
                                <label htmlFor="salary">Salary</label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  id="salary"
                                  placeholder="Salary"
                                  disabled={!editMode}
                                  autoComplete="off"
                                  autoCorrect="off"
                                  autoCapitalize="off"
                                  spellCheck="false"
                                  name="salary"
                                  onChange={handleChange}
                                  defaultValue={values.salary}
                                />
                              </Form.Group>
                              {/* ) : ( */}
                              {/* <Form.Group>
                                <label htmlFor="salary">Salary</label> */}
                              {/* <Form.Control
                                  type="text"
                                  className="form-control"
                                  id="salary"
                                  placeholder="Salary"
                                  disabled={!editMode}
                                  autoComplete="off"
                                  autoCorrect="off"
                                  autoCapitalize="off"
                                  spellCheck="false"
                                  name="salary"
                                  onChange={handleChange}
                                  value={"Click the icon to view salary"}
                                /> */}

                              {/* <span
                                    className="mdi mdi-eye-off"
                                    // style={{
                                    //   position: "absolute",
                                    //   bottom: "115px",
                                    //   left: "200px",
                                    //   cursor: "pointer",
                                    //   padding: "10px",
                                    // }}
                                    onClick={() => {
                                      setViewSalary(true);
                                      console.log("Here");
                                    }}
                                  ></span> */}
                              {/* </Form.Group> */}
                              {/* )} */}
                            </div>
                          ) : (
                            ""
                          )}

                          <Form.Group>
                            <label htmlFor="pay_schedule"> Pay Schedule</label>
                            <Form.Control
                              as="select"
                              type="text"
                              className="form-control"
                              id="pay_schedule"
                              placeholder="Pay Schedule"
                              name="pay_schedule"
                              disabled={!editMode}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              onChange={handleChange}
                              defaultValue={
                                values.pay_schedule
                                  ? values.pay_schedule
                                  : "DEFAULT"
                              }
                            >
                              <option value="DEFAULT" disabled>
                                {" "}
                                Select One{" "}
                              </option>
                              <option value="weekly"> Weekly </option>
                              <option value="bi-weekly"> Bi-Weekly </option>
                              <option valu="monthly"> Monthly </option>
                              <option value="contract"> Contract </option>
                            </Form.Control>
                          </Form.Group>

                          <Form.Group>
                            <label htmlFor="terminationReasonCode">
                              Termination Reason Code
                            </label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="terminationReasonCode"
                              placeholder="Termination Reason Code"
                              disabled={"disabled"}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              name="reason_for_termination"
                              onChange={handleChange}
                              defaultValue={values.reason_for_termination}
                            />
                          </Form.Group>
                          <Form.Group>
                            <label htmlFor="eligibleForRehire">
                              Eligible For Rehire {values.eligible_for_rehire}
                            </label>
                            <Form.Control
                              as="select"
                              className="form-control"
                              id="eligibleForRehire"
                              disabled={"disabled"}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              name="eligible_for_rehire"
                              defaultValue={
                                values.eligible_for_rehire === 0 ? false : true
                              }
                              onChange={handleChange}
                            >
                              <option value="DEFAULT" disabled>
                                {" "}
                                Eligible For Rehire ?
                              </option>
                              <option value="true"> Yes </option>
                              <option value="false"> No </option>
                            </Form.Control>
                          </Form.Group>
                          {/* <Form.Group>
                            <label htmlFor="manager">Manager</label>
                            <Form.Control
                              as="select"
                              className="form-control"
                              id="manager"
                              name="manager_id"
                              placeholder="Manager"
                              disabled={"disabled"}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              onChange={handleChange}
                              defaultValue={values.manager_id}
                            >
                              <option disabled value={"DEFAULT"}>
                                Select Manager
                              </option>
                              {employees &&
                                employees.map((value, index) => (
                                  <option value={value.id} key={index}>
                                    {value.first_name} {value.last_name}
                                  </option>
                                ))}
                            </Form.Control>
                          </Form.Group> */}
                          {/* <Form.Group>
                            <label htmlFor="unionCode">Union Code</label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="unionCode"
                              placeholder="Union Code"
                              disabled={!editMode}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              defaultValue={values.union_code}
                              name="union_code"
                              onChange={handleChange}
                            />
                          </Form.Group> */}
                          {/* <Form.Group>
                            <label htmlFor="laboutProgramCode">
                              Minor Labour Program Code
                            </label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="labourProgramCode"
                              placeholder="Minor Labour Program Code"
                              disabled={!editMode}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              onChange={handleChange}
                              defaultValue={values.minor_labour_program_code}
                              name="minor_labour_program_code"
                            />
                          </Form.Group> */}
                        </div>

                        <div className="col-md-6">
                          {/* <Form.Group>
                            <label htmlFor="uniformDeductionAmount">
                              Uniform Deduction Amount
                            </label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="uniformDeductionAmount"
                              placeholder="Uniform Deduction Amount"
                              disabled={!editMode}
                              name="uniform_deduction_amount"
                              defaultValue={values.uniform_deduction_amount}
                              onChange={handleChange}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                            />
                          </Form.Group> */}
                          {/* <Form.Group>
                            <label htmlFor="salaryAmount">Salary Amount</label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="salaryAmount"
                              placeholder="Salary Amount"
                              disabled={!editMode}
                              defaultValue={values.salary_amount}
                              name="salary_amount"
                              onChange={handleChange}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                            />
                          </Form.Group> */}
                          {/* <Form.Group>
                            <label htmlFor="mealDeductionProgram">
                              Meal Program Deduction
                            </label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="mealDeductionProgram"
                              placeholder="Meal Program Deduction"
                              disabled={!editMode}
                              defaultValue={values.meal_program_deduction}
                              name="meal_program_deduction"
                              onChange={handleChange}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                            />
                          </Form.Group> */}

                          {/* <Form.Group>
                            <label htmlFor="skillsEarned">Skills Earned</label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="skillsEarned"
                              placeholder="Skills Earned"
                              disabled={!editMode}
                              defaultValue={values.skills_earned}
                              name="skills_earned"
                              onChange={handleChange}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                            />
                          </Form.Group> */}

                          {/* <Form.Group>
                            <label htmlFor="badges">Thanks Badges</label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="badges"
                              placeholder="Badges"
                              disabled={!editMode}
                              defaultValue={values.thanks_badges}
                              name="thanks_badges"
                              onChange={handleChange}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                            />
                          </Form.Group> */}
                        </div>

                        <div className="col-md-6">
                          {/* <Form.Group>
                            <label htmlFor="sickLeaveHoursAvailable">
                              Sick Leave Hours Available
                            </label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="sickLeaveHoursAvailable"
                              placeholder="Sick Leave Hours Available"
                              disabled={!editMode}
                              onChange={handleChange}
                              defaultValue={values.sick_leave_hours}
                              name="sick_leave_hours"
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                            />
                          </Form.Group> */}
                          {/* <Form.Group>
                            <label htmlFor="partTimeFlag">Part Time Flag</label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="partTimeFlag"
                              placeholder="Part Time Flag"
                              disabled={!editMode}
                              defaultValue={values.part_time_flag}
                              name="part_time_flag"
                              onChange={handleChange}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                            />
                          </Form.Group> */}

                          {/* <Form.Group>
                            <label htmlFor="breakWaiver">Break Waiver</label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="breakWaiver"
                              placeholder="Break Waiver"
                              disabled={!editMode}
                              defaultValue={values.break_waiver}
                              name="break_waiver"
                              onChange={handleChange}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                            />
                          </Form.Group> */}

                          {/* <Form.Group>
                            <div>
                              <label htmlFor="nextReviewDate">
                                Next Review Date
                              </label>
                            </div>
                            <Form.Control
                              type="date"
                              id="nextReviewDate"
                              className="form-control w-100"
                              disabled={!editMode}
                              defaultValue={values.next_review_date}
                              name="next_review_date"
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              onChange={handleChange}
                            />
                          </Form.Group> */}
                        </div>
                      </div>

                      {editMode ? (
                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary btn-icon-text"
                          >
                            <i className="mdi mdi-file-check btn-icon-prepend"></i>{" "}
                            Submit{" "}
                          </button>

                          <button
                            className="btn btn-light"
                            onClick={() => {
                              setEditMode(false);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default Company;
