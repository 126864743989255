import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import {
  ConfirmWrapper,
  PrimarySlickButton,
  SlickButton,
  Circle,
} from "../../components/AlertUtility";
import { confirmAlert } from "react-confirm-alert";
import cogoToast from "cogo-toast";

const Navbar = () => {
  const { user, logout } = useAuth();
  const history = useHistory();

  const handleLogout = () => {
    const toastoptions = {
      hideAfter: 5,
      position: "top-right",
      heading: "Attention",
    };

    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmWrapper className="sc-fzoLag BNtsP">
          <a className="sidebar-brand brand-logo" href="/laparrila/restaurant-manager/dashboard">
            <img src={require("../../assets/images/la-parrila-logo.png")} alt="logo" className="" />
          </a>
          <header>
            <h6>Are you sure you want to log out of the portal?</h6>
          </header>
          <div className="alert-footer">
            <PrimarySlickButton
              onClick={() => {
                logout();
                history.push("/home/login");
                onClose();
                cogoToast.success("User logged out successfully", toastoptions);
              }}
              className="sc-fznZeY sc-fzqBZW eNQuho"
            >
              <span>Yes, log out!</span>
            </PrimarySlickButton>
            <SlickButton onClick={onClose} type="reset" className="sc-fznZeY gJlwEu cancel">
              <span>Stay signed in</span>
            </SlickButton>
          </div>
        </ConfirmWrapper>
      ),
    });
  };

  const toggleOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  };

  return (
    <nav className="navbar fixed-top d-flex flex-row navbar-dark bg-dark" style={{ padding: "0" }}>
      <div
        className="navbar-menu-wrapper d-flex justify-content-between align-items-center"
        style={{ width: "100%" }}
      >
        <button
          className="navbar-toggler align-self-center"
          type="button"
          onClick={() => document.body.classList.toggle("sidebar-icon-only")}
        >
          <i className="mdi mdi-menu"></i>
        </button>

        <ul className="navbar-nav navbar-nav-right d-flex align-items-center">
          <li className="nav-item nav-profile">
            <Dropdown>
              <Dropdown.Toggle
                className="nav-link bg-transparent d-flex align-items-center"
                id="dropdown-profile"
              >
                <Circle
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_AZURE_STORAGE}/pictures/${user?.slug}),
                                     url(/svg/portrait.svg)`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                  }}
                />
                <span className="profile-name ml-2 text-light">{`${user?.first_name} ${user?.last_name}`}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {user?.role === "super admin" && (
                  <Dropdown.Item as={Link} to="/activity-log">
                    <i className="mdi mdi-cached text-success"></i> Activity Log
                  </Dropdown.Item>
                )}
                <Dropdown.Item as={Link} to={`/employees/view/${user?.slug}`}>
                  <i className="mdi mdi-account text-primary"></i> Profile
                </Dropdown.Item>
                <Dropdown.Item onClick={handleLogout}>
                  <i className="mdi mdi-logout text-danger"></i> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>

        <button
          className="navbar-toggler d-lg-none align-self-center"
          type="button"
          onClick={toggleOffcanvas}
        >
          <i className="mdi mdi-menu"></i>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
