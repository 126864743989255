import React, { useState, useEffect } from "react";
import { Tab } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import AppSpinner from "../../../components/Spinner";
import { SystemConfigService } from "../../../service/configService";

const ViewDistricts = () => {
  const [districts, setDistricts] = useState(null);

  useEffect(() => {
    SystemConfigService.getDistricts()
      .then((response) => {
        console.log(response.data);
        setDistricts(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);

  return (
    <div>
      <div className="row">
        <div className="tab-custom-pills-horizontal col-md-12">
          <div className="float-left">
            <span className="card-description"> Districts</span>{" "}
            &nbsp;&nbsp;&nbsp;
            <small className="card-description">
              <i>( Click on the box for details )</i>
            </small>
          </div>
          <div className="float-right">
            <span>
              <Link to={"/system-configuration/all"}>Portal configuration</Link>
            </span>
            &nbsp;&nbsp;&nbsp;
            <Link to={"/system-configuration/districts"}>
              <button type="button" className="btn btn-primary btn-rounded">
                Create District
              </button>
            </Link>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>

      <div className="mt-3">
        <Tab.Pane eventKey="districts">
          <div className="row">
            {districts ? (
              districts.map((value, index) => (
                <div className="col-md-3 mb-5" key={index}>
                  <div className="domainBox">
                    <Link
                      to={`/system-configuration/view-district/${value.id}`}
                    >
                      <div className="react-aspect-ratio-placeholder">
                        <div className="sc-fzoiQi ozSmQ  ">
                          <main style={{ position: "relative" }}>
                            <h4> {value.name}</h4>
                            <p>
                              {" "}
                              {value.manager} <br />
                              {/* <small>{value.state}</small> */}
                            </p>
                          </main>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              <div>
                <div>
                  <p> Districts not found</p>
                </div>
                <AppSpinner />
              </div>
            )}
          </div>
        </Tab.Pane>
      </div>
    </div>
  );
};

export default withRouter(ViewDistricts);
