import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Form, Spinner } from "react-bootstrap";
import { useHistory, withRouter } from "react-router";
import { Utility } from "../../../Helpers/utils";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { UserService } from "../../../service/userService";
import Alert from "react-bootstrap/Alert";
import AppSpinner from "../../../components/Spinner";
import moment from "moment";
import Swal from "sweetalert2";
import StoreName from "../../../components/StoreName";
import JobCodeName from "../../../components/JobCodeName";
import Select from "react-select";

const EditStoreApplicationDetails = () => {
  let history = useHistory();

  const [user, setUser] = useState(null);
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [positionOptions, setPositionOptions] = useState(null);
  const [defaultPositionOptionsArray, setDefaultPositionOptionsArray] =
    useState(null);

  const entry_id = history.location.state.entry.id;
  const entry = history.location.state.entry;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let positionArr = [];
    let defaultOptionsArray = [];
    const authUser = Utility.getUser();
    console.log(authUser, "Logged in user");
    setUser(authUser);
    console.log(entry, "This ENTRY");

    history.location.state.entry.position_hired.map((pos, index) => {
      return defaultOptionsArray.push({
        value: pos.number,
        label: pos.name,
      });
    });

    JobCodeName.map((job, index) => {
      return positionArr.push({
        value: job.id,
        label: job.name,
      });
    });
    setPositionOptions(positionArr);
    setDefaultPositionOptionsArray(defaultOptionsArray);
  }, []);

  return (
    <section className="content">
      <div
        style={{
          marginBottom: "20px",
        }}
      >
        <Link to={"/employees/application"}>
          <button className="btn btn-primary">Back</button>
        </Link>
      </div>

      <div>
        <h3> Application Entry </h3>
      </div>

      <div>
        <div>
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card mt-5">
              <div className="card-body">
                <h4 className="card-title">Applicant's details </h4>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <th> State </th>
                        <td> {entry.state} </td>
                      </tr>
                      <tr>
                        <th> City </th>
                        <td> {entry.city} </td>
                      </tr>
                      <tr>
                        <th> Which position are you applying for? </th>
                        <td> {entry.position} </td>
                      </tr>
                      <tr>
                        <th> Are you willing to relocate? </th>
                        <td> {entry.willing} </td>
                      </tr>

                      <tr>
                        <th> Are you a citizen of the United States? </th>
                        <td> {entry.are_you_us_citizen}</td>
                      </tr>

                      <tr>
                        <th>
                          {" "}
                          Are you authorised to work in the United States?{" "}
                        </th>
                        <td> {entry.authorised_to_work_us}</td>
                      </tr>

                      <tr>
                        <th> Have you ever worked for this company? </th>
                        <td> {entry.worked_for_this_company}</td>
                      </tr>

                      <tr>
                        <th> When did you worked? </th>
                        <td> {entry.when_did_you_worked_here}</td>
                      </tr>

                      <tr>
                        <th> When can you start? </th>
                        <td> {entry.when_start} </td>
                      </tr>

                      <tr>
                        <th>Name</th>
                        <td> {entry.name} </td>
                      </tr>

                      <tr>
                        <th>Email Address </th>
                        <td> {entry.email} </td>
                      </tr>

                      <tr>
                        <th> Address </th>
                        <td> {entry.address} </td>
                      </tr>

                      <tr>
                        <th> Phone Number </th>
                        <td> {entry.phone_number} </td>
                      </tr>

                      <tr>
                        <th> Attach a copy of your resume </th>
                        <td> {entry.copy_resume} </td>
                      </tr>

                      <tr>
                        <th> How do you hear about this job? </th>
                        <td> {entry.how_heard} </td>
                      </tr>

                      <tr>
                        <th width={"40%"}> Signature </th>
                        <td className="py-1" width={"60%"}>
                          <img
                            src={entry.signature}
                            style={{
                              height: "75px",
                              width: "100%",
                            }}
                            alt="signature"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div>
                <Formik
                  initialValues={{
                    entry_id,
                    manager_first_name: user ? user.first_name : "",
                    manager_last_name: user ? user.last_name : "",
                    date: entry.date ? moment(entry.date).toDate() : date,
                    startDate: entry.start_date
                      ? moment(entry.start_date).toDate()
                      : startDate,
                    manager_interviewed: entry.manager_interviewed
                      ? entry.manager_interviewed
                      : "",
                    applicant_interviewed_info: entry.applicant_interviewed_info
                      ? entry.applicant_interviewed_info
                      : "",
                    ready_for_training: entry.ready_training
                      ? entry.ready_training
                      : "",
                    ready_for_training_info: entry.ready_for_training_info
                      ? entry.ready_for_training_info
                      : "",
                    hired: entry.hired ? entry.hired : "",
                    rate: entry.rate ? entry.rate : "",
                    employee_classification: entry.employee_classification
                      ? entry.employee_classification
                      : "",
                    employee_type: entry.employee_type
                      ? entry.employee_type
                      : "",
                    rehired_from: entry.rehired_from ? entry.rehired_from : "",
                    transferred_from: entry.transferred_from
                      ? entry.transferred_from
                      : "",
                    position_hired: defaultPositionOptionsArray,
                  }}
                  enableReinitialize={true}
                  onSubmit={(values, { setSubmitting }) => {
                    // setSubmitting(true);
                    console.log(values, "All the values");
                    Swal.fire({
                      title: "Are you sure all information is accurate?",
                      icon: "question",
                      showCancelButton: true,
                      confirmButtonColor: "#000000",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes",
                      allowEscapeKey: false,
                      allowOutsideClick: false,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        console.log(values, "All the values");

                        UserService.processApplication(values)
                          .then((response) => {
                            setSubmitting(false);
                            console.log(response.data.data);
                            history.push("/employees/application");
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      } else {
                        setSubmitting(false);
                      }
                    });
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <div>
                      <div className="row">
                        <div className="col-12 grid-margin">
                          <div className="card">
                            <div className="card-body">
                              <Form
                                className="maintenance-issue-form"
                                onSubmit={handleSubmit}
                              >
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <Form.Group>
                                          <label htmlFor="manager_first_name">
                                            Manager First Name{" "}
                                            <span className="text-danger">
                                              {" "}
                                              *{" "}
                                            </span>{" "}
                                          </label>
                                          <Form.Control
                                            type="text"
                                            className="form-control"
                                            id="manager_first_name"
                                            placeholder="Manager's First Name"
                                            name="manager_first_name"
                                            value={values.manager_first_name}
                                            onChange={handleChange}
                                            disabled={isSubmitting}
                                            autoComplete="off"
                                            autoCorrect="off"
                                            autoCapitalize="off"
                                            spellCheck="false"
                                          />
                                        </Form.Group>
                                      </div>

                                      <div className="col-md-6">
                                        <Form.Group>
                                          <label htmlFor="manager_last_name">
                                            Manager Last Name
                                          </label>
                                          <Form.Control
                                            type="text"
                                            className="form-control"
                                            id="manager_last_name"
                                            placeholder="Manager Last Name"
                                            name="manager_last_name"
                                            value={values.manager_last_name}
                                            onChange={handleChange}
                                            disabled={isSubmitting}
                                            autoComplete="off"
                                            autoCorrect="off"
                                            autoCapitalize="off"
                                            spellCheck="false"
                                          />
                                        </Form.Group>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <Form.Group>
                                    <label
                                      htmlFor="interviewed_date"
                                      className="col-sm-3 col-form-label"
                                    >
                                      {" "}
                                      <b> Date Interviewed</b>{" "}
                                    </label>

                                    <div className="row">
                                      <div className="col-md-12">
                                        <DatePicker
                                          className="form-control w-100"
                                          dateFormat="MM/dd/yyyy"
                                          selected={values.date}
                                          isClearable
                                          placeholderText="Select a new date"
                                          onChange={(d1) => {
                                            console.log(d1);
                                            setDate(d1);
                                            // handleChangeDate
                                            setFieldValue(`date`, d1 ? d1 : "");
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </Form.Group>
                                </div>

                                <div>
                                  <div>
                                    <p>Manager Interviewed Applicant</p>
                                  </div>

                                  <div
                                    className="row"
                                    style={{
                                      padding: "10px",
                                      border: "5px dotted #f5f5f5",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <div className="col-md-6">
                                      <Form.Group>
                                        <div className="form-check">
                                          <label className="form-check-label">
                                            <input
                                              type="radio"
                                              className="form-check-input"
                                              name="manager_interviewed"
                                              id="manager_interviewed1"
                                              value={"Yes"}
                                              onChange={handleChange}
                                              checked={
                                                values.manager_interviewed ===
                                                "Yes"
                                              }
                                            />
                                            <i className="input-helper"></i>
                                            Yes
                                          </label>
                                        </div>
                                        <div className="form-check">
                                          <label className="form-check-label">
                                            <input
                                              type="radio"
                                              className="form-check-input"
                                              name="manager_interviewed"
                                              id="manager_interviewed2"
                                              value={`Yes, but applicant is not qualified for position`}
                                              onChange={handleChange}
                                              checked={
                                                values.manager_interviewed ===
                                                "Yes, but applicant is not qualified for position"
                                              }
                                            />
                                            <i className="input-helper"></i>
                                            Yes, but applicant is not qualified
                                            for position
                                          </label>
                                        </div>
                                        <div className="form-check">
                                          <label className="form-check-label">
                                            <input
                                              type="radio"
                                              className="form-check-input"
                                              name="manager_interviewed"
                                              id="manager_interviewed3"
                                              value={"No"}
                                              onChange={handleChange}
                                              checked={
                                                values.manager_interviewed ===
                                                "No"
                                              }
                                            />
                                            <i className="input-helper"></i>
                                            No
                                          </label>
                                        </div>
                                      </Form.Group>
                                    </div>
                                  </div>
                                </div>

                                {values.manager_interviewed &&
                                  values.manager_interviewed === "No" && (
                                    <div className="">
                                      <div className="col-md-12">
                                        <Form.Group>
                                          <label htmlFor="applicant_interviewed_info">
                                            <b> Why Not </b>
                                          </label>
                                          <Form.Control
                                            type="text"
                                            as="textarea"
                                            rows="6"
                                            cols="50"
                                            className="form-control"
                                            id="applicant_interviewed_info"
                                            name="applicant_interviewed_info"
                                            value={
                                              values.applicant_interviewed_info
                                            }
                                            onChange={handleChange}
                                            disabled={isSubmitting}
                                          ></Form.Control>
                                        </Form.Group>
                                      </div>
                                    </div>
                                  )}

                                <div>
                                  <div>
                                    <p> Ready for training</p>
                                  </div>

                                  <div
                                    className="row"
                                    style={{
                                      padding: "10px",
                                      border: "5px dotted #f5f5f5",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <div className="col-md-6">
                                      <Form.Group>
                                        <div className="form-check">
                                          <label className="form-check-label">
                                            <input
                                              type="radio"
                                              className="form-check-input"
                                              name="ready_for_training"
                                              id="ready_for_training1"
                                              value={"Yes"}
                                              onChange={handleChange}
                                              checked={
                                                values.ready_for_training ===
                                                "Yes"
                                              }
                                            />
                                            <i className="input-helper"></i>
                                            Yes
                                          </label>
                                        </div>
                                        <div className="form-check">
                                          <label className="form-check-label">
                                            <input
                                              type="radio"
                                              className="form-check-input"
                                              name="ready_for_training"
                                              id="ready_for_training1"
                                              value={"No"}
                                              onChange={handleChange}
                                              checked={
                                                values.ready_for_training ===
                                                "No"
                                              }
                                            />
                                            <i className="input-helper"></i>
                                            No
                                          </label>
                                        </div>
                                      </Form.Group>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  {values.ready_for_training &&
                                    values.ready_for_training === "No" && (
                                      <Form.Group>
                                        <label htmlFor="ready_for_training_info">
                                          <b> Why No </b>
                                        </label>
                                        <Form.Control
                                          type="text"
                                          as="textarea"
                                          rows="6"
                                          cols="50"
                                          className="form-control"
                                          id="ready_for_training_info"
                                          name="ready_for_training_info"
                                          value={values.ready_for_training_info}
                                          onChange={handleChange}
                                          disabled={isSubmitting}
                                        ></Form.Control>
                                      </Form.Group>
                                    )}
                                </div>

                                <div>
                                  <div>
                                    <p> Hired ? </p>
                                  </div>

                                  <div
                                    className="row"
                                    style={{
                                      padding: "10px",
                                      border: "5px dotted #f5f5f5",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <div className="col-md-6">
                                      <Form.Group>
                                        <div className="form-check">
                                          <label className="form-check-label">
                                            <input
                                              type="radio"
                                              className="form-check-input"
                                              name="hired"
                                              id="hired"
                                              value={"Yes"}
                                              onChange={handleChange}
                                              checked={values.hired === "Yes"}
                                            />
                                            <i className="input-helper"></i>
                                            Yes
                                          </label>
                                        </div>
                                        <div className="form-check">
                                          <label className="form-check-label">
                                            <input
                                              type="radio"
                                              className="form-check-input"
                                              name="hired"
                                              id="hired"
                                              value={"No"}
                                              onChange={handleChange}
                                              checked={values.hired === "No"}
                                            />
                                            <i className="input-helper"></i>
                                            No
                                          </label>
                                        </div>
                                      </Form.Group>
                                    </div>
                                  </div>

                                  {values.hired === "Yes" && (
                                    <div>
                                      <div>
                                        <div className="col-md-6">
                                          <Form.Group>
                                            <label htmlFor="position_hired">
                                              Position
                                            </label>

                                            <Select
                                              isMulti={true}
                                              onChange={(opt, e) => {
                                                console.log(opt);
                                                setFieldValue(
                                                  "position_hired",
                                                  opt
                                                );
                                              }}
                                              name="position_hired"
                                              options={positionOptions}
                                              defaultValue={
                                                values.position_hired
                                              }
                                            />

                                            {/* <Form.Control
                                              as="select"
                                              type="text"
                                              className="form-control"
                                              id="position_hired"
                                              name="position_hired"
                                              value={
                                                values.position_hired
                                                  ? values.position_hired
                                                  : "DEFAULT"
                                              }
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "position_hired",
                                                  e.target.value
                                                );
                                              }}
                                              disabled={isSubmitting}
                                              autoComplete="off"
                                              autoCorrect="off"
                                              autoCapitalize="off"
                                              spellCheck="false"
                                            >
                                              <option value="DEFAULT" disabled>
                                                Select One
                                              </option>
                                              {JobCodeName.map((job, index) => (
                                                <option
                                                  value={job.id}
                                                  key={index}
                                                >
                                                  {job.name}
                                                </option>
                                              ))}
                                            </Form.Control> */}
                                          </Form.Group>
                                          {defaultPositionOptionsArray &&
                                            defaultPositionOptionsArray.map(
                                              (store, index) => (
                                                <div key={index}>
                                                  <span> {store.label} </span>
                                                </div>
                                              )
                                            )}
                                        </div>
                                      </div>
                                      <div>
                                        <Form.Group>
                                          <label
                                            htmlFor="start_date"
                                            className="col-sm-3 col-form-label"
                                          >
                                            <b> Start Date </b>
                                          </label>

                                          <div className="row">
                                            <div className="col-md-12">
                                              <DatePicker
                                                className="form-control w-100"
                                                dateFormat="MM/dd/yyyy"
                                                selected={values.startDate}
                                                isClearable
                                                placeholderText="Select a start date"
                                                onChange={(d1) => {
                                                  console.log(d1);
                                                  setStartDate(d1);
                                                  // handleChangeDate
                                                  setFieldValue(
                                                    `startDate`,
                                                    d1 ? d1 : ""
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </Form.Group>
                                      </div>

                                      <div className="col-md-3">
                                        <Form.Group>
                                          <label
                                            htmlFor="start_date"
                                            className="col-sm-12 col-form-label"
                                          >
                                            <b> Rate </b>
                                          </label>
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                $
                                              </span>
                                            </div>

                                            <Form.Control
                                              type="text"
                                              className="form-control"
                                              id="rate"
                                              name="rate"
                                              value={values.rate}
                                              onChange={handleChange}
                                              disabled={isSubmitting}
                                            ></Form.Control>
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                /hr
                                              </span>
                                            </div>
                                          </div>
                                        </Form.Group>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div>
                                  <div>
                                    <p> Employee Classification </p>
                                  </div>

                                  <div
                                    className="row"
                                    style={{
                                      padding: "10px",
                                      border: "5px dotted #f5f5f5",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <div className="col-md-6">
                                      <Form.Group>
                                        <div className="form-check">
                                          <label className="form-check-label">
                                            <input
                                              type="radio"
                                              className="form-check-input"
                                              name="employee_classification"
                                              id="employee_classification"
                                              value={"full time"}
                                              onChange={handleChange}
                                              checked={
                                                values.employee_classification ===
                                                "full time"
                                              }
                                            />
                                            <i className="input-helper"></i>
                                            Full Time
                                          </label>
                                        </div>
                                        <div className="form-check">
                                          <label className="form-check-label">
                                            <input
                                              type="radio"
                                              className="form-check-input"
                                              name="employee_classification"
                                              id="employee_classification"
                                              value={"part time"}
                                              onChange={handleChange}
                                              checked={
                                                values.employee_classification ===
                                                "part time"
                                              }
                                            />
                                            <i className="input-helper"></i>
                                            Part Time
                                          </label>
                                        </div>
                                      </Form.Group>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <div>
                                    <p> Employee Type </p>
                                  </div>

                                  <div
                                    className="row"
                                    style={{
                                      padding: "10px",
                                      border: "5px dotted #f5f5f5",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <div className="col-md-6">
                                      <Form.Group>
                                        <div className="form-check">
                                          <label className="form-check-label">
                                            <input
                                              type="radio"
                                              className="form-check-input"
                                              name="employee_type"
                                              id="new employee"
                                              value={"new employee"}
                                              onChange={handleChange}
                                              checked={
                                                values.employee_type ===
                                                "new employee"
                                              }
                                            />
                                            <i className="input-helper"></i>
                                            New Employee
                                          </label>
                                        </div>
                                        <div className="form-check">
                                          <label className="form-check-label">
                                            <input
                                              type="radio"
                                              className="form-check-input"
                                              name="employee_type"
                                              id="re-hire"
                                              value={"re-hire"}
                                              onChange={handleChange}
                                              checked={
                                                values.employee_type ===
                                                "re-hire"
                                              }
                                            />
                                            <i className="input-helper"></i>
                                            Re - Hire Employee
                                          </label>
                                        </div>

                                        <div className="form-check">
                                          <label className="form-check-label">
                                            <input
                                              type="radio"
                                              className="form-check-input"
                                              name="employee_type"
                                              id="transfers"
                                              value={"transfers"}
                                              onChange={handleChange}
                                              checked={
                                                values.employee_type ===
                                                "transfers"
                                              }
                                            />
                                            <i className="input-helper"></i>
                                            Transfers
                                          </label>
                                        </div>
                                      </Form.Group>
                                    </div>
                                  </div>
                                </div>

                                {values.employee_type === "re-hire" && (
                                  <div className="col-md-6">
                                    <Form.Group>
                                      <label htmlFor="rehired_from">
                                        Employee Re-Hired From
                                        <span className="text-danger">*</span>
                                      </label>

                                      <Form.Control
                                        as="select"
                                        type="text"
                                        className="form-control"
                                        id="rehired_from"
                                        name="rehired_from"
                                        value={
                                          values.rehired_from
                                            ? values.rehired_from
                                            : "DEFAULT"
                                        }
                                        onChange={(e) => {
                                          setFieldValue(
                                            "rehired_from",
                                            e.target.value
                                          );
                                        }}
                                        disabled={isSubmitting}
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                      >
                                        <option value="DEFAULT" disabled>
                                          Select One
                                        </option>
                                        {StoreName.map((st, index) => (
                                          <option value={st.id} key={index}>
                                            {st.name}
                                          </option>
                                        ))}
                                      </Form.Control>
                                    </Form.Group>
                                  </div>
                                )}

                                {values.employee_type === "transfers" && (
                                  <div className="col-md-6">
                                    <Form.Group>
                                      <label htmlFor="transferred_from">
                                        Employee Transferred From
                                        <span className="text-danger"> * </span>
                                      </label>

                                      <Form.Control
                                        as="select"
                                        type="text"
                                        className="form-control"
                                        id="transferred_from"
                                        name="transferred_from"
                                        value={
                                          values.transferred_from
                                            ? values.transferred_from
                                            : "DEFAULT"
                                        }
                                        onChange={(e) => {
                                          setFieldValue(
                                            "transferred_from",
                                            e.target.value
                                          );
                                        }}
                                        disabled={isSubmitting}
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                      >
                                        <option value="DEFAULT" disabled>
                                          Select One
                                        </option>
                                        {StoreName.map((st, index) => (
                                          <option value={st.id} key={index}>
                                            {st.name}
                                          </option>
                                        ))}
                                      </Form.Control>
                                    </Form.Group>
                                  </div>
                                )}

                                <button
                                  type="submit"
                                  disabled={isSubmitting}
                                  className="btn btn-primary mr-2"
                                >
                                  Save Changes &nbsp;&nbsp;
                                  {isSubmitting ? (
                                    <Spinner animation="border" size="sm" />
                                  ) : (
                                    ""
                                  )}
                                </button>
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(EditStoreApplicationDetails);
