import BonusSelector from "../bonus/BonusSelector";
import BonusBulkUpload from "../bonus/upload/Bulk";
import SelectViewBonus from "../bonus/SelectViewBonus";
import ViewBonusBreakdown from "../bonus/breakdown/ViewBonusBreakdown";
import ViewBulkUploadBreakdown from "../bonus/breakdown/ViewBulkUploadBreakdown";
import BonusBreakdown from "../bonus/breakdown/BonusBreakdown";
import CreateBonusPayoutData from "../bonus/payout/CreateBonusPayoutData";
import ViewBonusPayout from "../bonus/payout/ViewBonusPayout";
import ReviewsBonusPayout from "../bonus/reviews/ReviewsBonusPayout";
import EvaluationBonusPayout from "../bonus/evaluation/EvaluationBonusPayout";
import SurveyForLeaders from "../bonus/survey/SurveyForLeaders";
import ViewBonusReports from "../bonus/reports/ViewBonusReports";
import ViewBonusStoreList from "../bonus/reports/ViewBonusStoreList";
import ViewBonusReportHistories from "../bonus/reports/ViewBonusReportHistories";

const bonusRoutes = [
  { path: "/bonus-payout", component: BonusSelector },
  { path: "/bonus/bulk-upload", component: BonusBulkUpload },
  { path: "/bonus/view-select-bonus-payout", component: SelectViewBonus },
  { path: "/bonus/view-bonus-payout-breakdown", component: ViewBonusBreakdown },
  { path: "/bonus/view-bulk-upload-breakdown", component: ViewBulkUploadBreakdown },
  { path: "/bonus-payout-breakdown", component: BonusBreakdown },
  { path: "/bonus/create-bonus-payout", component: CreateBonusPayoutData },
  { path: "/view-bonus-payout", component: ViewBonusPayout },
  { path: "/bonus/view-reviews-bonus-payout", component: ReviewsBonusPayout },
  { path: "/bonus/view-evaluation-bonus-payout", component: EvaluationBonusPayout },
  { path: "/bonus/survey-for-leaders", component: SurveyForLeaders },
  { path: "/bonus/view-bonus-report", component: ViewBonusReports },
  { path: "/bonus/store-list", component: ViewBonusStoreList },
  { path: "/bonus/view-bonus-histories-store", component: ViewBonusReportHistories },
];

export default bonusRoutes;
