import React, { useEffect, useState } from "react";
import { useHistory, useParams, withRouter } from "react-router";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { CateringCode } from "../../Helpers/constant";
import { CateringService } from "../../service/CateringService";
import { ConvertDateFormat } from "../../components/ConvertDateFormat";
const { SearchBar } = Search;

const CateringPending = () => {
  let { store_id } = useParams();
  let history = useHistory();
  const [pendingCateringPayments, setPendingCateringPayments] = useState(null);

  const handleScheduleCateringReport = (row) => {
    console.log(row.storeId, row.id, row.cateringCode);
    // history.push("/catering/edit-catering-payment", {
    //   store_id: row.storeId,
    //   id: row.id,
    //   code: row.cateringCode,
    // });
  };

  useEffect(() => {
    console.log(CateringCode);
    CateringService.getPendingCatering(store_id)
      .then((response) => {
        console.log(response.data.data);
        let pendingCateringArray = [];
        response.data.data.map((res, index) => {
          return pendingCateringArray.push({
            id: res.id,
            storeId: res.store_id,
            location: `#Laparrilla ${res.store_id}`,
            cateringCode: res.catering_code,
            cateringDate: ConvertDateFormat(res.catering_date),
            dropOffAmount: res.drop_off_amount,
            employeeForDropOff: res.drop_off_radio_option,
            numbersOfEmployees: res.number_of_employees,
            employees: res.employee_number,
          });
        });
        setPendingCateringPayments(pendingCateringArray);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);

  const columns = [
    {
      dataField: "cateringCode",
      text: "Catering Code",
      sort: true,
    },
    {
      dataField: "cateringDate",
      text: "Catering Date",
      sort: true,
    },
    {
      dataField: "dropOffAmount",
      text: "Drop Off Amount",
      sort: true,
    },
    {
      dataField: "employeeForDropOff",
      text: "Employee Drop Off",
      sort: true,
    },
    {
      dataField: "numbersOfEmployees",
      text: "Number of Employees",
      sort: true,
    },
    {
      dataField: "employees",
      text: "Employees",
      sort: true,
    },
    {
      dataField: "Action",
      text: "Action",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <div>
            <button
              className="btn btn-outline-primary"
              onClick={(e) => handleScheduleCateringReport(row)}
            >
              Schedule Payment
            </button>

            {/* Use the backend logic here to structure how to schedule catering */}
            {/* <button
              className="btn btn-outline-primary"
              onClick={(e) => handleFileFetching(row)}
            >
              <i className="mdi mdi-eye-outline text-primary"></i> Fetch
            </button> */}
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <div>
      <div className="mb-5">
        <div className="pull-left">
          <Link to={"/catering/pending-list"}>
            <button className="btn btn-primary"> Back </button>
          </Link>
        </div>
        <div className="clearfix"></div>
      </div>

      <div className="row">
        <div className="col-12">
          {pendingCateringPayments ? (
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"> Pending Catering </h4>
                <div className="row">
                  <div className="col-12">
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={pendingCateringPayments}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div className="d-flex align-items-center">
                            <p className="mb-2 mr-2">Search in table:</p>
                            <SearchBar {...props.searchProps} />
                          </div>
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(CateringPending);
