import React, { Component, useState } from "react";
import { useParams, withRouter, Link, useHistory } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import ReactTags from "react-tag-autocomplete";
import { CateringService } from "../../service/CateringService";
import cogoToast from "cogo-toast";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";

const CreateCatering = () => {
  const currentDate = () => {
    // const dateTimeWithZone = new Date().toLocaleString("en-US", {
    //   timeZone: "America/New_York",
    // });

    // let agreedDate = new Date(dateTimeWithZone);
    // return agreedDate;
    return new Date(Date.now());
  };

  const [tags, setTags] = useState([]);
  // const [date, setDate] = useState(new Date(Date.now()));
  const [date, setDate] = useState(
    new Date(Date.now()).toISOString().slice(0, 10)
  );
  let { store_id } = useParams();
  let history = useHistory();

  const minDate = new Date(Date.now());

  // const minDate = new Date(
  //   new Date(Date.now()).toLocaleString("en-US", {
  //     timeZone: "America/New_York",
  //   })
  // );

  // console.log(minDate, "Minimum date");

  const handleTagChange = (t) => {
    setTags(t);
  };

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  return (
    <div>
      <div className="mb-5">
        <Link to={"/catering/catering-payment"}>
          <button className="btn btn-primary"> Back </button>
        </Link>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="page-header">
            <h3 className="page-title"> Configure Payment </h3>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb"></ol>
            </nav>
          </div>
          <Formik
            initialValues={{
              catering_code: "",
              catering_date: date,
              drop_off_option: "",
              drop_off_amount: "",
              number_of_employees: "",
              drop_off_radio_option: "",
              store_id: store_id ? store_id : "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              let data = {
                values,
                tags,
              };

              console.log(data, "Data to submit");

              if (
                data.tags.length > 0 &&
                values.number_of_employees == data.tags.length
              ) {
                console.log(data);

                CateringService.createCateringPayment(data)
                  .then((response) => {
                    setSubmitting(false);
                    console.log(response.data.data);
                    cogoToast.success(
                      "Catering payment created successfully",
                      toastoptions
                    );
                    setTimeout(() => {
                      history.push("/catering/catering-payment");
                    }, 200);
                  })
                  .catch((error) => {
                    console.log(error.response.data.message);
                    Swal.fire({
                      title: "Error",
                      text: error.response.data.message,
                      icon: "error",
                      confirmButtonText: "Ok",
                      allowEscapeKey: false,
                      allowOutsideClick: false,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        Swal.close();
                      }
                    });
                    // cogoToast.error(error.response.data.message, toastoptions);
                    setSubmitting(false);
                  });
              } else {
                setSubmitting(false);
                Swal.fire({
                  title: "Error",
                  text: "Please check the employee numbers.",
                  icon: "error",
                  confirmButtonText: "Ok",
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    Swal.close();
                  }
                });
              }
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <div className="row">
                <div className="col-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      {/* <h4 className="card-title">
                      Fill the details below to complete
                    </h4> */}
                      <form
                        className="forms-sample"
                        name="form"
                        onSubmit={handleSubmit}
                      >
                        <Form.Group>
                          <label htmlFor="catering_code">
                            <b>Catering Code</b>{" "}
                          </label>
                          <Form.Control
                            type="text"
                            as="select"
                            className="form-control"
                            id="catering_code"
                            name="catering_code"
                            value={
                              values.catering_code
                                ? values.catering_code
                                : "DEFAULT"
                            }
                            onChange={handleChange}
                            disabled={isSubmitting}
                            placeholder="Catering Code"
                          >
                            <option value="DEFAULT" disabled>
                              {" "}
                              Select One{" "}
                            </option>
                            <option value="999100"> 999100 </option>
                            <option value="999101"> 999101 </option>
                            <option value="999102"> 999102 </option>
                            <option value="999103"> 999103 </option>
                            <option value="999104"> 999104 </option>
                            <option value="999105"> 999105 </option>
                            <option value="999106"> 999106 </option>
                            <option value="999107"> 999107 </option>
                            <option value="999108"> 999108 </option>
                            <option value="999109"> 999109 </option>
                            <option value="999110"> 999110 </option>
                            <option value="999111"> 999111 </option>
                            <option value="999112"> 999112 </option>
                            <option value="999113"> 999113 </option>
                            <option value="999114"> 999114 </option>
                            <option value="999115"> 999115 </option>
                            <option value="999116"> 999116 </option>
                            <option value="999117"> 999117 </option>
                            <option value="999118"> 999118 </option>
                            <option value="999119"> 999119 </option>
                            <option value="999120"> 999120 </option>
                          </Form.Control>
                        </Form.Group>

                        <Form.Group>
                          <label
                            htmlFor="catering_date"
                            className="col-sm-3 col-form-label"
                          >
                            {" "}
                            <b>Catering Date</b>{" "}
                          </label>

                          <div className="row">
                            <div className="col-md-12">
                              <Form.Control
                                type="date"
                                className="form-control w-100"
                                name="catering_date"
                                id="catering_date"
                                defaultValue={values.catering_date}
                                placeholder="Select a new date"
                                min={minDate}
                                // format="MM/dd/yyyy"
                                onChange={(e) => {
                                  console.log(e.target);
                                  const { name, value } = e.target;
                                  console.log([name, value]);

                                  setDate(value);
                                  // handleChangeDate
                                  setFieldValue(
                                    `catering_date`,
                                    value ? value : ""
                                  );
                                }}
                              />
                              {/* <DatePicker
                                className="form-control w-100"
                                dateFormat="MM/dd/yyyy"
                                selected={date}
                                isClearable
                                placeholderText="Select a new date"
                                minDate={minDate}
                                onChange={(d1) => {
                                  console.log(d1);

                                  setDate(d1);
                                  // handleChangeDate
                                  // setFieldValue(`catering_date`, d1 ? d1 : "");
                                }}
                              /> */}
                            </div>
                          </div>
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="drop_off_option">
                            {" "}
                            <b>DropOff ?</b>{" "}
                          </label>
                          <Form.Control
                            type="text"
                            as="select"
                            className="form-control"
                            id="drop_off_option"
                            name="drop_off_option"
                            defaultValue={
                              values.drop_off_option
                                ? values.drop_off_option
                                : "DEFAULT"
                            }
                            onChange={handleChange}
                            disabled={isSubmitting}
                          >
                            <option value="DEFAULT" disabled>
                              {" "}
                              Select One{" "}
                            </option>
                            <option value="yes"> Yes </option>
                            <option value="no"> No </option>
                          </Form.Control>
                        </Form.Group>

                        {values.drop_off_option &&
                        values.drop_off_option === "yes" ? (
                          <Form.Group>
                            <label htmlFor="drop_off_amount">
                              {" "}
                              <b> DropOff Amount Option</b>{" "}
                            </label>
                            <Form.Control
                              type="text"
                              as="select"
                              className="form-control"
                              id="drop_off_amount"
                              name="drop_off_amount"
                              value={
                                values.drop_off_amount
                                  ? values.drop_off_amount
                                  : "DEFAULT"
                              }
                              onChange={handleChange}
                              disabled={isSubmitting}
                            >
                              <option value="DEFAULT" disabled>
                                {" "}
                                Select One{" "}
                              </option>
                              <option value="25"> $25 </option>
                              <option value="35"> $35 </option>
                            </Form.Control>
                          </Form.Group>
                        ) : (
                          ""
                        )}

                        <Form.Group>
                          <label htmlFor="number_of_employees">
                            {" "}
                            <b> Number of Employee</b>
                          </label>
                          <Form.Control
                            type="text"
                            as="select"
                            className="form-control"
                            id="number_of_employees"
                            name="number_of_employees"
                            value={
                              values.number_of_employees
                                ? values.number_of_employees
                                : "DEFAULT"
                            }
                            onChange={handleChange}
                            disabled={isSubmitting}
                          >
                            <option value="DEFAULT" disabled>
                              {" "}
                              Select One{" "}
                            </option>
                            <option value="1"> One </option>
                            <option value="2"> Two </option>
                            <option value="3"> Three </option>
                            <option value="4"> Four </option>
                            <option value="5"> Five </option>
                            <option value="6"> Six </option>
                            <option value="7"> Seven </option>
                            <option value="8"> Eight </option>
                            <option value="9"> Nine </option>
                            <option value="10"> Ten </option>
                          </Form.Control>
                        </Form.Group>

                        {values.number_of_employees &&
                        values.number_of_employees !== "DEFAULT" ? (
                          <Form.Group>
                            <label htmlFor="tags">
                              <b>
                                {" "}
                                Employee Number (s)
                                <br />
                              </b>

                              <i> Type employee's number and press enter</i>
                            </label>

                            <TagsInput
                              limit={
                                values.number_of_employees
                                  ? values.number_of_employees
                                  : 0
                              }
                              handler={handleTagChange}
                            />
                          </Form.Group>
                        ) : (
                          ""
                        )}

                        {values.drop_off_option &&
                        values.drop_off_option === "yes" ? (
                          <Form.Group>
                            <label>Select Employee for DropOff </label>
                            {tags &&
                              tags.map((t, index) => (
                                <div className="form-check" key={index}>
                                  <label className="form-check-label">
                                    <input
                                      type="radio"
                                      className="form-check-input"
                                      name="drop_off_radio_option"
                                      id={`drop_off_radio_option_${index}`}
                                      value={t.name}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "drop_off_radio_option",
                                          e.currentTarget.value
                                        );
                                      }}
                                    />
                                    <i className="input-helper"></i>
                                    {t.name}
                                  </label>
                                </div>
                              ))}
                          </Form.Group>
                        ) : (
                          ""
                        )}

                        <button type="submit" className="btn btn-primary mr-2">
                          Submit &nbsp;&nbsp;
                          {isSubmitting ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            ""
                          )}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

class TagsInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: [],
    };
  }

  handleDelete(i) {
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags });
  }

  handleAddition(tag) {
    const tags = [].concat(this.state.tags, tag);
    if (tags.length <= this.props.limit) {
      this.setState({ tags });
    }
  }

  componentDidMount() {
    console.log(this.props.limit);
  }

  render() {
    return (
      <ReactTags
        tags={this.state.tags}
        placeholder={`Add employee's number`}
        allowNew={true}
        handleDelete={this.handleDelete.bind(this)}
        handleAddition={this.handleAddition.bind(this)}
        handleBlur={this.props.handler(this.state.tags)}
      />
    );
  }
}

export default withRouter(CreateCatering);
