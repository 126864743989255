import React from "react";
import { Form } from "react-bootstrap";
import { RoundNumberUp } from "../../Utils/RoundNumberUp";

const BartenderPageBox = ({
  tipshareAllTotal,
  amountLeft,
  amountToBeDistributed,
  instantPayTotal,
}) => {
  return (
    <div className="col-md-12">
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td style={{ width: "15%" }}></td>
            <td style={{ width: "20%" }}></td>
            <td className="amountRow" style={{ width: "25%" }}>
              <span> Total Tip share </span>
              <Form.Group>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <Form.Control
                    type="text"
                    className="form-control"
                    aria-label="Tips Amount"
                    aria-describedby="basic-addon1"
                    id="total_tipshare_owed"
                    disabled={"disabled"}
                    placeholder="Tips Amount"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    name={"total_tipshare"}
                    value={
                      tipshareAllTotal ? RoundNumberUp(tipshareAllTotal) : ""
                    }
                  />
                </div>
              </Form.Group>
            </td>

            <td className="amountRow" style={{ width: "20%" }}>
              <div>
                <span> Amount left to be distributed</span>
                <Form.Group>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <Form.Control
                      type="text"
                      className="form-control"
                      aria-label="Tips Amount"
                      aria-describedby="basic-addon1"
                      id="amount_left"
                      disabled={"disabled"}
                      placeholder="Tips Amount"
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      name={"amount_left"}
                      value={amountLeft ? amountLeft : ""}
                    />
                  </div>
                </Form.Group>
              </div>

              <div>
                <span> Amount to be distributed</span>
                <Form.Group>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <Form.Control
                      type="text"
                      className="form-control"
                      aria-label="Tips Amount"
                      aria-describedby="basic-addon1"
                      id="amount_to_be_distributed"
                      disabled={"disabled"}
                      placeholder="Tips Amount"
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      name={"total_bar_owed"}
                      value={
                        amountToBeDistributed
                          ? RoundNumberUp(amountToBeDistributed)
                          : ""
                      }
                    />
                  </div>
                </Form.Group>
              </div>
            </td>

            <td className="amountRow" style={{ width: "20%" }}>
              <span> Instant Pay Total</span>
              <Form.Group>
                <div className="input-group" id="instant_pay_total_group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <Form.Control
                    type="text"
                    className="form-control"
                    aria-label="Tips Amount"
                    aria-describedby="basic-addon1"
                    id="instant_pay_total"
                    disabled={"disabled"}
                    placeholder="Tips Amount"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    name={"total_bar_owed"}
                    value={
                      instantPayTotal ? RoundNumberUp(instantPayTotal) : ""
                    }
                  />
                </div>
              </Form.Group>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BartenderPageBox;
