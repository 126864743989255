import React, { useContext, useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { LPSubmitButton } from "../../../styled/styles";
import BonusContext from "../../../contexts/bonus/BonusContext";

const GrossProfit = ({ months }) => {
  const { dispatch, grossProfit } = useContext(BonusContext);

  const initialValues = {
    grossProfitM1: grossProfit ? grossProfit.grossProfitM1 : "",
    grossProfitM1P: grossProfit ? grossProfit.grossProfitM1P : "",
    grossProfitM2: grossProfit ? grossProfit.grossProfitM2 : "",
    grossProfitM2P: grossProfit ? grossProfit.grossProfitM2P : "",
    grossProfitM3: grossProfit ? grossProfit.grossProfitM3 : "",
    grossProfitM3P: grossProfit ? grossProfit.grossProfitM3P : "",
    grossProfitTotal: grossProfit ? grossProfit.grossProfitTotal : "",
    grossProfitTotalP: grossProfit ? grossProfit.grossProfitTotalP : "",
  };

  const handleDispatch = (payload) => {
    dispatch({
      type: "SET_COGS_DATA",
      payload,
    });
  };

  const roundNumberUp = (num) => {
    return (Math.round(num * 100) / 100).toFixed(0);
  };

  const roundNumberUp2DP = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  const makeValZeroIfEmpty = (input) => {
    return input == "" || isNaN(input) ? 0 : input;
  };

  return (
    <div>
      <div className="col-lg-12 grid-margin stretch-card">
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);

            // Submit form here;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form name="sales-data" onSubmit={handleSubmit}>
              <div className="table-responsive">
                <div>
                  <table className="table table-bordered">
                    <tbody>
                      <tr
                        style={{
                          fontWeight: "bold",
                          // paddingLeft: "0px",
                          textAlign: "center",
                        }}
                      >
                        <th scope="row" width="10%"></th>
                        <td width="10%"></td>
                        <td width="10%"> {months.firstMonth}</td>
                        <td width="10%">%</td>
                        <td width="10%">{months.secondMonth}</td>
                        <td width="10%">%</td>
                        <td width="10%">{months.thirdMonth}</td>
                        <td width="10%">%</td>
                        <td width="10%">Total </td>
                        <td width="10%">%</td>
                      </tr>

                      <tr>
                        <th
                          width="10%"
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          Gross Profit
                        </th>
                        <td
                          width="10%"
                          style={{
                            padding: "0px",
                            textAlign: "center",
                          }}
                        ></td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="grossProfitM1"
                            name="grossProfitM1"
                            defaultValue={values.grossProfitM1}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="grossProfitM1P"
                            name="grossProfitM1P"
                            defaultValue={values.grossProfitM1P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="grossProfitM2"
                            name="grossProfitM2"
                            defaultValue={values.grossProfitM2}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="grossProfitM2P"
                            name="grossProfitM2P"
                            defaultValue={values.grossProfitM2P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="grossProfitM3"
                            name="grossProfitM3"
                            defaultValue={values.grossProfitM3}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="grossProfitM3P"
                            name="grossProfitM3P"
                            defaultValue={values.grossProfitM3P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="grossProfitTotal"
                            name="grossProfitTotal"
                            defaultValue={values.grossProfitTotal}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="grossProfitTotalP"
                            name="grossProfitTotalP"
                            defaultValue={values.grossProfitTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div>
                {/* <LPSubmitButton className="btn btn-primary" type="submit">
                  Update COGS
                </LPSubmitButton> */}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default GrossProfit;
