import { Formik } from "formik";
import moment from "moment";
import React, { Component, useEffect } from "react";
import { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Dropdown, ProgressBar, Modal, Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { ConvertDateFormatForHuman } from "../../components/ConvertDateFormat";
import AppSpinner from "../../components/Spinner";
import { MaintenanceReportService } from "../../service/MaintenanceReportService";
import TaskHovering from "./components/TaskHovering";

export class Column extends Component {
  constructor(props) {
    super(props);
    this.taskClickHandler = this.taskClickHandler.bind(this);
    this.handleItemRemoval = this.handleItemRemoval.bind(this);
    this.state = {
      tasks: this.props.tasks,
    };
  }

  taskClickHandler() {
    alert("I was clicked");
  }

  handleItemRemoval(id, columnId) {
    console.log(id, "Item to be removed");
    let tasks = this.state.tasks.filter((task) => task.taskId !== id);
    this.setState({ tasks });

    this.props.handleTaskRemoval(id, columnId);
  }

  render() {
    return (
      <div className="col-md-4">
        <div className="board-wrapper" style={{ padding: "9px" }}>
          <div
            className="board-portlet"
            style={{
              minHeight: "32px",
              padding: "3px 0",
              alignItems: "center",
              position: "relative",
              marginBottom: "-10px",
            }}
          >
            <span
              className="portlet-heading pull-left"
              style={{
                color: `${this.props.column.color}`,
                fontWeight: "bolder",
              }}
            >
              {this.props.column.title}{" "}
            </span>

            <span
              className="mr-0 pull-right"
              style={{
                backgroundColor: "transparent",
                color: "#616161",
                border: "1px solid #616161",
                padding: "2px 8px",
                borderRadius: "5px",
                marginLeft: "6px",
                fontWeight: "400",
                fontSize: ".8rem",
                whiteSpace: "nowrap",
                boxSizing: "border-box",
              }}
            >
              {this.state.tasks.length}{" "}
              {this.state.tasks.length > 1 ? "issues" : "issue"}
            </span>
          </div>
          <span className="clearfix"></span>

          <Droppable droppableId={this.props.column.id}>
            {(provided) => (
              <div
                className="kanbanHeight"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {this.state.tasks.map((task, index) => (
                  <Task
                    key={task.id}
                    task={task}
                    index={index}
                    handleItemClick={this.handleItemRemoval}
                    onClick={() => {
                      alert("Short cut");
                      this.taskClickHandler();
                    }}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    );
  }
}

const Task = ({ task, index, handleItemClick }) => {
  const [previewMode, setPreviewMode] = useState(false);
  const [mdShow, setMdShow] = useState(false);
  const [taskClicked, setTaskClicked] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [editTaskMode, setEditTaskMode] = useState(false);
  const [editCommentMode, setEditCommentMode] = useState(false);
  const [taskComments, setTaskComments] = useState(null);

  const handleEdit = (e, task) => {
    console.log(task, "this is the task to edit");
    setMdShow(true);
    setTaskClicked(task);
  };

  const handleComplete = (e, task) => {
    console.log(task, "this is the task to complete");
    setIsLoading(true);
    setMdShow(false);
    MaintenanceReportService.markTaskAsComplete(task.taskId)
      .then((response) => {
        console.log(response);
        // handleTaskStrike(e, task.taskId);
        handleItemClick(task.taskId, task.columnId);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleArchive = (e, task) => {
    console.log(task, "this is the task to Archive");
    setIsLoading(true);
    setMdShow(false);
    MaintenanceReportService.archiveTasksFromWorkroom(task.taskId)
      .then((response) => {
        console.log(response);
        handleItemClick(task.taskId, task.columnId);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleDelete = (e, task) => {
    setIsLoading(true);
    console.log(task, "this is the task to Delete");
    MaintenanceReportService.deleteTasksFromWorkroom(task.taskId)
      .then((response) => {
        console.log(response);
        handleItemClick(task.taskId, task.columnId);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided) => (
        <div
          className="mt-2 board-portlet"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <ul id="portlet-card-list-1" className="portlet-card-list">
            <li
              className="portlet-card"
              style={
                task.tags.includes("high")
                  ? {
                      position: "relative",
                      padding: "4px",
                      wordWrap: "break-word",
                      borderRadius: "5px",
                      borderBottom: "2px solid #bdbdbd",
                      backgroundColor: "#fff",
                      color: "#000",
                      marginBottom: "2px",
                      opacity: "1",
                      transition: "opacity .3s ease",
                      background: "antiquewhite",
                      border: "2px dotted red",
                    }
                  : {
                      position: "relative",
                      padding: "4px",
                      wordWrap: "break-word",
                      borderRadius: "5px",
                      borderBottom: "2px solid #bdbdbd",
                      backgroundColor: "#fff",
                      color: "#000",
                      marginBottom: "2px",
                      opacity: "1",
                      transition: "opacity .3s ease",
                    }
              }
            >
              {/* <ProgressBar variant={`${task.progressVariant}`} now={75} /> */}
              <div className="d-flex justify-content-between w-100">
                <div
                  className="task-date"
                  // style={{
                  //   border: "1px solid #f5f5f5",
                  //   padding: "20px",
                  //   width: "200px",
                  // }}
                  onClick={() => {
                    console.log(task, "This is the clicked tasks");
                    setMdShow(true);
                    setTaskClicked(task);
                  }}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                >
                  <span
                    style={{
                      display: "inline-block",
                      padding: "3px",
                      verticalAlign: "middle",
                      lineHeight: "normal",
                      marginRight: "4px",
                      backgroundColor: "transparent",
                      textTransform: "uppercase",
                      color: "#0091ea",
                    }}
                  >
                    <span
                      style={{
                        border: "1px solid #0091ea",
                        borderRadius: "5px",
                        padding: "2px",
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      {task.short}
                    </span>
                  </span>
                  &nbsp;&nbsp;
                  <span>
                    {task.completeDate ? (
                      <strike>{task.subject}</strike>
                    ) : (
                      <b>{task.subject}</b>
                    )}

                    {isLoading && <AppSpinner size={25} />}
                  </span>
                </div>
                <Dropdown variant="p-0" alignRight>
                  <Dropdown.Toggle variant="dropdown-toggle p-0">
                    <i className="mdi mdi-dots-vertical"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={(e) => handleEdit(e, task)}>
                      Edit
                    </Dropdown.Item>

                    {!task.completeDate ? (
                      <Dropdown.Item onClick={(e) => handleComplete(e, task)}>
                        Complete
                      </Dropdown.Item>
                    ) : (
                      ""
                    )}
                    <Dropdown.Item onClick={(e) => handleArchive(e, task)}>
                      Archive
                    </Dropdown.Item>
                    <Dropdown.Item onClick={(e) => handleDelete(e, task)}>
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="d-flex justify-content-between">
                <div>
                  {/* {task.priority} */}
                  {task.tags.map((i, index) => (
                    <div
                      className={"badge badge-inverse-primary"}
                      style={{ display: "inline-block", margin: "0px 2px" }}
                      key={index}
                    >
                      {i}
                    </div>
                  ))}
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div className="card-bottom">
                  <div>
                    <span className="mdi mdi-comment-text-outline"></span>
                    &nbsp;&nbsp;
                    <span>{task.commentsCount}</span>
                  </div>
                  <div>
                    <span className="mdi mdi-bell"></span>
                  </div>

                  <div>
                    {/* <span>`${calculateDueDate(task.dueDate)}`</span> */}
                    {moment(task.dueDate).isSame(moment(), "day") && (
                      <span style={{ color: "red" }}>
                        <span className="mdi mdi-clock-outline"></span>
                        &nbsp;&nbsp;
                        <span>{ConvertDateFormatForHuman(task.dueDate)}</span>
                      </span>
                    )}
                    {moment(task.dueDate).isBefore(moment(), "day") && (
                      <span style={{ color: "red" }}>
                        <span className="mdi mdi-clock-outline"></span>
                        &nbsp;&nbsp;
                        <span>{ConvertDateFormatForHuman(task.dueDate)}</span>
                      </span>
                    )}
                    {moment(task.dueDate).isAfter(moment(), "day") && (
                      <span style={{ color: "green" }}>
                        <span className="mdi mdi-clock-outline"></span>
                        &nbsp;&nbsp;
                        <span>{ConvertDateFormatForHuman(task.dueDate)}</span>
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div>
                {isHovering && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50px",
                      background: "#ffffff",
                      zIndex: "2000",
                      width: "inherit",
                      height: "inherit",
                      left: "130px",
                    }}
                  >
                    <TaskHovering task={task} />
                  </div>
                )}
              </div>
            </li>
          </ul>

          <div>
            <Modal
              size="lg"
              show={mdShow}
              onHide={() => setMdShow(false)}
              aria-labelledby="example-modal-sizes-title-md"
              // style={{ margin: "auto", width: "50%", padding: "10px" }}

              id="reportNoteModalWrapper"
              onEntering={() => {
                MaintenanceReportService.getCommentsForATask(task.taskId)
                  .then((response) => {
                    console.log(response.data.data);
                    setTaskComments(response.data.data);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            >
              <Modal.Header closeButton>
                {/* <Modal.Title>Modal title</Modal.Title> */}
              </Modal.Header>
              <Modal.Body
                style={{
                  color: "rgba(0, 0, 0, 0.87)",
                  backgroundColor: "rgb(255, 255, 255)",
                  transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
                  boxSizing: "border-box",
                  fontFamily: '"Open Sans", sans-serif',
                  boxShadow:
                    "rgb(0 0 0 / 25%) 0px 14px 45px, rgb(0 0 0 / 22%) 0px 10px 18p",
                  borderRadius: "2px",
                }}
              >
                {taskClicked && (
                  <div className="row">
                    <div className="col-md-8" style={{ minHeight: "600px" }}>
                      <div>
                        {taskClicked.completeDate ? (
                          <p>
                            {" "}
                            <strike>{taskClicked.subject}</strike>{" "}
                          </p>
                        ) : (
                          <p> {taskClicked.subject} </p>
                        )}
                      </div>

                      <div
                        className="text-center"
                        style={{ marginBottom: "10px" }}
                      >
                        <div
                          style={{
                            padding: "8px",
                            marginBottom: "10px",
                            border: "1px solid black",
                            borderRadius: "5px",
                          }}
                        >
                          Due On: {moment(task.dueDate).format("LLLL")}
                        </div>
                      </div>

                      <div
                        style={{
                          marginBottom: "10px",
                          padding: "10px 0px",
                          borderBottom: "1px solid #f5f5f5",
                        }}
                      >
                        {" "}
                        {task.tags.map((i, index) => (
                          <div
                            className={"badge badge-inverse-primary"}
                            style={{
                              display: "inline-block",
                              margin: "0px 2px",
                            }}
                            key={index}
                          >
                            {i}
                          </div>
                        ))}{" "}
                      </div>

                      <hr />
                      <Formik
                        initialValues={{
                          comment: "",
                          taskId: task.taskId,
                          body: task.body,
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          setSubmitting(true);
                          console.log(values);
                          // MaintenanceReportService.createCommentForATask(values)
                          //   .then((response) => {
                          //     console.log(response.data.data);
                          //   })
                          //   .catch((error) => {
                          //     console.log(error);
                          //   });
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                        }) => (
                          <div
                            style={{
                              lineHeight: "1.7rem",
                              fontSize: "1rem",
                              wordWrap: "break-word",
                              hyphens: "auto",
                            }}
                          >
                            <form name="form" onSubmit={handleSubmit}>
                              {/* <p> {task.body} </p> */}

                              <Form.Group className="d-flex search-field">
                                <Form.Control
                                  type="text"
                                  as="textarea"
                                  rows="6"
                                  cols="50"
                                  className="form-control"
                                  id="body"
                                  name="body"
                                  value={values.body}
                                  disabled={!editTaskMode}
                                  onChange={handleChange}
                                />
                              </Form.Group>

                              {editTaskMode && (
                                <div>
                                  <Button
                                    type="submit"
                                    className="btn btn-primary font-weight-medium"
                                  >
                                    Save &nbsp;&nbsp;
                                    {isSubmitting ? (
                                      // <Spinner animation="border" size="sm" />
                                      <span className="fa fa-spin fa-spinner"></span>
                                    ) : (
                                      ""
                                    )}
                                  </Button>
                                  <Button
                                    variant="light m-2"
                                    onClick={() => setEditTaskMode(false)}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              )}
                            </form>
                          </div>
                        )}
                      </Formik>

                      <hr />

                      <div
                        style={{
                          margin: "10px 0px",
                          fontSize: "12px",
                        }}
                      >
                        <span>
                          Created on: {moment(task.createDate).format("LLLL")}{" "}
                          by {task.createdBy}
                        </span>
                      </div>

                      <hr />

                      <div>
                        {taskComments && (
                          <div>
                            {taskComments.map((comm, index) => (
                              <div
                                style={{
                                  position: "relative",
                                  padding: "6px 42px 6px 0px",
                                  borderBottom: "1px solid #eee",
                                  minHeight: "52px",
                                  marginBottom: "10px",
                                }}
                                key={index}
                              >
                                <div className="task-comment_head">
                                  <span
                                    style={{
                                      fontWeight: "700",
                                      color: "#0091ea",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    {comm.createUser.__descriptor}
                                  </span>
                                  <span
                                    className="task-comment_date"
                                    style={{
                                      paddingLeft: "10px",
                                      color: "#616161",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {moment(comm.createDate).format("LLLL")}
                                  </span>
                                </div>
                                <div>
                                  <p> {comm.comment} </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}

                        <div>
                          <Formik
                            initialValues={{
                              comment: "",
                              taskId: task.taskId,
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                              setSubmitting(true);
                              console.log(values);
                              MaintenanceReportService.createCommentForATask(
                                values
                              )
                                .then((response) => {
                                  console.log(response.data.data);
                                  setSubmitting(false);
                                  setMdShow(false);
                                })
                                .catch((error) => {
                                  console.log(error);
                                });
                            }}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                            }) => (
                              <form name="form" onSubmit={handleSubmit}>
                                <Form.Group>
                                  <label htmlFor="maintanance_issues">
                                    <b> Comment ({task.commentsCount}) </b>{" "}
                                    <br />
                                    <br />
                                    Write any comment related to this issue. Be
                                    as detailed as possible.
                                  </label>
                                  <Form.Control
                                    type="text"
                                    as="textarea"
                                    rows="6"
                                    cols="50"
                                    className="form-control"
                                    id="comment"
                                    name="comment"
                                    disabled={!editCommentMode}
                                    onChange={handleChange}
                                  ></Form.Control>
                                </Form.Group>

                                {editCommentMode && (
                                  <div>
                                    <Button
                                      type="submit"
                                      className="btn btn-primary font-weight-medium"
                                    >
                                      Save &nbsp;&nbsp;
                                      {isSubmitting ? (
                                        // <Spinner animation="border" size="sm" />
                                        <span className="fa fa-spin fa-spinner"></span>
                                      ) : (
                                        ""
                                      )}
                                    </Button>
                                    <Button
                                      variant="light m-2"
                                      onClick={() => setEditCommentMode(false)}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                )}

                                {!editCommentMode && (
                                  <div>
                                    <Button
                                      variant="light m-2"
                                      type={"button"}
                                      onClick={() => setEditCommentMode(true)}
                                    >
                                      Add Comment
                                    </Button>
                                  </div>
                                )}
                              </form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4" style={{ minHeight: "600px" }}>
                      <div className="issues-btn-wrapper">
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={(e) => {
                            setEditTaskMode(true);
                          }}
                        >
                          Edit Issue
                        </button>
                      </div>

                      <div className="issues-btn-wrapper">
                        {!taskClicked.completeDate ? (
                          <button
                            type="button"
                            className="btn btn-outline-dark"
                            onClick={(e) => handleComplete(e, taskClicked)}
                          >
                            MARK COMPLETE
                          </button>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="issues-btn-wrapper">
                        <button type="button" className="btn btn-outline-dark">
                          DUE DATE
                        </button>
                      </div>

                      {/* <div className="issues-btn-wrapper">
                      <button type="button" className="btn btn-outline-dark">
                        ASSIGNEES
                      </button>
                    </div> */}

                      <div className="issues-btn-wrapper">
                        <button type="button" className="btn btn-outline-dark">
                          TAGS
                        </button>
                      </div>

                      <div className="issues-btn-wrapper">
                        <button
                          type="button"
                          className="btn btn-outline-dark"
                          onClick={(e) => handleArchive(e, taskClicked)}
                        >
                          ARCHIVE
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer className="fleex-wrap">
                <Button
                  variant="success"
                  className="text-center"
                  onClick={() => {
                    setMdShow(false);
                    setPreviewMode(false);
                  }}
                >
                  Close Preview
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export class MESReport extends Component {
  constructor(props) {
    super(props);
    this.removeTask = this.removeTask.bind(this);
    this.state = {
      taskDataDetails: null,
      isLoading: false,
    };
  }

  removeTask(id, columnId) {
    console.log(this.state.taskDataDetails);
    let filteredTaskData = this.state.taskDataDetails.columns[
      columnId
    ].taskIds.filter((task) => {
      return task.taskId !== id;
    });

    console.log(filteredTaskData, "Filtered tasks");
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    // this.setState({ isLoading: false });

    // let sample = [
    //   {
    //     body: "Maintenance Issues Or Repair Request: Be as detailed as possible on your requestMaintenance Issues Or Repair Request: Be as detailed as possible on your request",
    //     columnId: "column-1",
    //     comments: [],
    //     commentsCount: 0,
    //     completeDate: "2021-08-05T15:10:06+0000",
    //     createDate: "2021-08-02T01:08:58+0000",
    //     createdBy: "Oluyemi",
    //     dueDate: "2021-08-09T01:08:47+0000",
    //     id: "task-0",
    //     short: "MES-100",
    //     subject: "LP1 / MAINTENANCE / MI / 3561",
    //     tags: ["MI", "Alert", "Bob", "Urgent", "high"],
    //     taskId: 3785298,
    //   },
    // ];

    // let sample2 = [
    //   {
    //     body: "Maintenance Issues Or Repair Request: Be as detailed as possible on your requestMaintenance Issues Or Repair Request: Be as detailed as possible on your request",
    //     columnId: "column-2",
    //     comments: [],
    //     commentsCount: 0,
    //     completeDate: "2021-08-05T15:10:06+0000",
    //     createDate: "2021-08-02T01:08:58+0000",
    //     createdBy: "Oluyemi",
    //     dueDate: "2021-08-09T01:08:47+0000",
    //     id: "task-0",
    //     short: "MES-100",
    //     subject: "LP1 / MAINTENANCE / MI / 3561",
    //     tags: ["MI", "Alert", "Bob", "Urgent"],
    //     taskId: 3785298,
    //   },
    // ];

    // let sample3 = [
    //   {
    //     body: "Maintenance Issues Or Repair Request: Be as detailed as possible on your requestMaintenance Issues Or Repair Request: Be as detailed as possible on your request",
    //     columnId: "column-3",
    //     comments: [],
    //     commentsCount: 0,
    //     completeDate: "2021-08-05T15:10:06+0000",
    //     createDate: "2021-08-02T01:08:58+0000",
    //     createdBy: "Oluyemi",
    //     dueDate: "2021-08-09T01:08:47+0000",
    //     id: "task-0",
    //     short: "MES-100",
    //     subject: "LP1 / MAINTENANCE / MI / 3561",
    //     tags: ["MI", "Alert", "Bob", "Urgent"],
    //     taskId: 3785298,
    //   },
    // ];

    // const taskData = {
    //   columns: {
    //     "column-1": {
    //       id: "column-1",
    //       title: "Maintenance Issues",
    //       taskIds: sample,
    //       // taskIds: maintenanceTasks,
    //       color: "#00cccd",
    //     },
    //     "column-2": {
    //       id: "column-2",
    //       title: "Equipment Repairs",
    //       taskIds: sample2,
    //       // taskIds: equipmentTasks,
    //       color: "#3f50f6",
    //     },
    //     "column-3": {
    //       id: "column-3",
    //       title: "System Issues",
    //       taskIds: sample3,
    //       // taskIds: systemIssuesTasks,
    //       color: "#ff3ca6",
    //     },
    //   },
    //   columnOrder: ["column-1", "column-2", "column-3"],
    // };

    // this.setState({
    //   taskDataDetails: taskData,
    // });

    MaintenanceReportService.getAllTasksForAWorkroom(this.props.match.params.id)
      .then((response) => {
        console.log(response.data.data, "results");
        this.setState({ isLoading: false });
        let tasks = response.data.data;

        if (tasks.length > 0) {
          let maintenanceTasks = tasks[0];
          let equipmentTasks = tasks[1];
          let systemIssuesTasks = tasks[2];

          const taskData = {
            columns: {
              "column-1": {
                id: "column-1",
                title: "Maintenance Issues",
                taskIds: maintenanceTasks,
                color: "#00cccd",
              },
              "column-2": {
                id: "column-2",
                title: "Equipment Repairs",
                taskIds: equipmentTasks,
                color: "#3f50f6",
              },
              "column-3": {
                id: "column-3",
                title: "System Issues",
                taskIds: systemIssuesTasks,
                color: "#ff3ca6",
              },
            },
            columnOrder: ["column-1", "column-2", "column-3"],
          };

          this.setState(taskData);
          this.setState({
            taskDataDetails: taskData,
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  }
  // state = initialData;
  /** Work on the draggable component later */

  render() {
    let { taskDataDetails, isLoading } = this.state;
    return (
      <div>
        <div>
          <p className="pull-right" style={{ textDecoration: "underline" }}>
            <a
              href="https://veltron.document360.io/v1/en"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn How &nbsp;<span className="fa fa-question"></span>
            </a>
          </p>

          <div className="clearfix"></div>
        </div>
        <div>
          <div className="d-flex flex-column flex-md-row align-items-center flex-wrap">
            <h4 className="mb-md-0 mb-4 text-dark">
              Maintenance Request Board
            </h4>
            <div className="wrapper d-flex align-items-center">
              <Link to={"/report/list-stores"}>
                <button
                  type="button"
                  className="btn btn-outline-primary px-5 ml-4"
                >
                  <i className="mdi mdi-arrow-left-bold mr-2"></i>Back
                </button>
              </Link>
            </div>
            <div className="wrapper ml-md-auto  d-none d-lg-flex flex-column flex-md-row kanban-toolbar ml-md-0 my-2">
              <div className="d-flex">
                <button type="button" className="btn btn-danger">
                  Urgents
                </button>
                <button type="button" className="btn btn-info">
                  Medium
                </button>

                <button type="button" className="btn btn-primary">
                  Normal
                </button>

                <div>
                  <DatePicker
                    className="form-control w-100"
                    dateFormat="MM/dd/yyyy"
                    // selected={values.date}
                    isClearable
                    placeholderText="Select a new date"
                    onChange={(d1) => {
                      console.log(d1);
                      // setDate(d1);
                      // handleChangeDate
                      // setFieldValue(`date`, d1 ? d1 : "");
                    }}
                  />
                </div>

                <button type="button" className="btn btn-primary">
                  Filter
                </button>
              </div>
            </div>
          </div>
          {isLoading ? (
            <AppSpinner />
          ) : (
            <div className="row">
              {!taskDataDetails && (
                <div>
                  <p> No Data Found </p>
                </div>
              )}

              <div className="col-lg-12">
                <DragDropContext
                  onDragEnd={this.onDragEnd}
                  onDragStart={this.onDragStart}
                >
                  {taskDataDetails ? (
                    <div className="row">
                      {taskDataDetails.columnOrder.map((columnId) => {
                        const column =
                          this.state.taskDataDetails.columns[columnId];
                        const tasks = column.taskIds.map((t) => {
                          return t;
                        });
                        //return column.title;
                        return (
                          <Column
                            key={column.id}
                            column={column}
                            tasks={tasks}
                            handleTaskRemoval={this.removeTask}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </DragDropContext>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MESReport;
/**  <Column key={column.id} column={column} tasks={tasks} />  
 * 
 * const tasks = column.taskIds.map(
                    (taskId) => this.state.tasks[taskId]
                  );
*/
