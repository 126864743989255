import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { UserService } from "../../../service/userService";
import moment from "moment";
import ViewAllStoresApplication from "./components/ViewAllStoresApplication";

const ViewStoreApplications = () => {
  let { store_id } = useParams();
  const [store, setStore] = useState(null);
  const [applicationEntries, setApplicationEntries] = useState(null);

  useEffect(() => {
    UserService.getApplicantsForStore(store_id)
      .then((response) => {
        console.log(response.data.data);

        let applicantsArr = [];

        response.data.data.map((res, index) => {
          applicantsArr.push({
            id: res.id,
            approval_status: res.approval_status,
            date_created: moment(res.date_created).fromNow(),
            state: res.state,
            city: res.city,
            address: res.address,
            copy_resume: res.copy_resume,
            date: res.date,
            email: res.email,
            entry_number: res.entry_number,
            form_number: res.form_number,
            how_heard: res.how_heard,
            ip_address: res.ip_address,
            name: res.name,
            phone_number: res.phone_number,
            position: res.position,
            signature: res.signature,
            when_start: res.when_start,
            willing: res.willing,
            isAcknowledged: res.isAcknowledged,
            hired: res.hired,
            are_you_us_citizen: res.are_you_us_citizen,
            authorised_to_work_us: res.authorised_to_work_us,
            worked_for_this_company: res.worked_for_this_company,
            when_did_you_worked_here: res.when_did_you_worked_here,
            rate: res.rate,
            start_date: res.start_date,
            employee_classification: res.employee_classification,
            employee_type: res.employee_type,
            rehired_from: res.rehired_from,
            transferred_from: res.transferred_from,
            manager_interviewed: res.manager_interviewed,
            ready_training: res.ready_training,
            position_hired: res.position_hired,
          });
        });
        setApplicationEntries(applicantsArr);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">
          <span style={{ textTransform: "capitalize" }}>
            {store ? store.name : ""}
          </span>
        </h3>

        <div>
          <Link to={"/employees/application"}>
            <button className="btn btn-primary"> View All Entries </button>
          </Link>
        </div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/financials/view-store-report"> La Parrilla </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <span style={{ textTransform: "capitalize" }}>
                {store ? store.name : ""}
              </span>
            </li>
          </ol>
        </nav>
      </div>

      <div className="row">
        <div className="col-12">
          <ViewAllStoresApplication applicationEntries={applicationEntries} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(ViewStoreApplications);
