import React from "react";
import PaymentListContainer from "./PaymentListContainer";

const AllPaymentsList = ({
  tipIncomeReport,
  atoPaymentDetails,
  otherPayments,
  barTenderPayment,
  cateringPayments,
  togoPayments,
  scheduledPayment,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <h4> Server </h4>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th style={{ width: "10%" }}> Number </th>
                <th style={{ width: "50%" }}> Server </th>
                <th style={{ width: "40%" }}> InstantPay Tips </th>
              </tr>
            </thead>
            <tbody>
              {tipIncomeReport &&
                tipIncomeReport.server.map((serve, index) => (
                  <tr key={index}>
                    <td> {serve.employee_number} </td>
                    <td> {serve.employee_name} </td>

                    <PaymentListContainer index={index} tips={serve.tip_owed} />
                  </tr>
                ))}
            </tbody>
          </table>

          <div className="mt-5">
            {atoPaymentDetails ? (
              <div>
                <h4>Online Tips ATO</h4>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}> Number </th>
                      <th className="table-wider" style={{ width: "50%" }}>
                        Employee
                      </th>
                      <th style={{ width: "40%" }}> Tips Owed </th>
                      {/* <th></th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {atoPaymentDetails.map((ato, index) => (
                      <tr key={index}>
                        <td> {ato.employeeId} </td>
                        <td> {ato.name} </td>
                        <PaymentListContainer index={index} tips={ato.tipShareReceived} />
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="mt-5">
            {otherPayments && otherPayments.length > 0 ? (
              <div>
                <h4>Other Employees ( Coach Server, others )</h4>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}> Number </th>
                      <th className="table-wider" style={{ width: "50%" }}>
                        Employee
                      </th>
                      <th style={{ width: "40%" }}> Tips Owed </th>
                      {/* <th></th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {otherPayments.map((emp, index) => (
                      <tr key={index}>
                        <td> {emp.employee_number} </td>
                        <td> {emp.employee_name} </td>
                        <PaymentListContainer index={index} tips={emp.tip_owed} />
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="col-md-6">
          <h4> Bartender</h4>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th style={{ width: "20%" }}> Number </th>
                <th style={{ width: "40%" }}> Bartender </th>
                <th style={{ width: "40%" }}> Tips </th>
              </tr>
            </thead>
            <tbody>
              {barTenderPayment &&
                barTenderPayment.tipSummary.map((tip, index) => (
                  <tr key={tip.employee_number}>
                    <td>{tip.employee_number}</td>
                    <td>{tip.name}</td>
                    <PaymentListContainer index={index} tips={tip.instant_pay_total} />
                  </tr>
                ))}
            </tbody>
          </table>

          <div className="mt-5">
            {cateringPayments ? (
              <div>
                <h4>Catering</h4>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}> Number </th>
                      <th className="table-wider" style={{ width: "50%" }}>
                        Employee
                      </th>
                      <th style={{ width: "40%" }}> Tips Owed </th>
                      {/* <th></th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {cateringPayments.map((cat, index) => (
                      <tr key={index}>
                        <td> {cat.employee_number} </td>
                        <td> {cat.employee_name} </td>
                        <PaymentListContainer index={index} tips={cat.tip_owed} />
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="mt-5">
            {togoPayments ? (
              <div>
                <h4>TOGO</h4>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}> Number </th>
                      <th className="table-wider" style={{ width: "50%" }}>
                        Employee
                      </th>
                      <th style={{ width: "40%" }}> Tips Owed </th>
                      {/* <th></th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {togoPayments.togoPaymentDetails.map((togo, index) => (
                      <tr key={index}>
                        <td> {togo.employee_number} </td>
                        <td> {togo.employee_name} </td>

                        <PaymentListContainer index={index} tips={togo.tipshare_received} />
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <div className="row mt-5">
        {scheduledPayment && scheduledPayment.length > 0 && (
          <div className="col-md-6">
            <h4> Scheduled Payments</h4>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{ width: "20%" }}> Number </th>
                  <th style={{ width: "40%" }}> Bartender </th>
                  <th style={{ width: "40%" }}> Tips </th>
                </tr>
              </thead>
              <tbody>
                {scheduledPayment &&
                  scheduledPayment.map((tip, index) => (
                    <tr key={tip.payrollEmployeeId}>
                      <td>{tip.payrollEmployeeId}</td>
                      <td>
                        {tip.firstName} {tip.lastName}{" "}
                      </td>

                      <PaymentListContainer index={index} tips={parseFloat(tip.amount)} />
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default AllPaymentsList;
