import React from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import { SystemConfigService } from "../../../service/configService";
import cogoToast from "cogo-toast";
import { Formik } from "formik";

const Domain = () => {
  const history = useHistory();

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Domains </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/system-configuration/all"}>Portal Configuration</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={"/system-configuration/view-domains"}>Domains</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              view all
            </li>
          </ol>
        </nav>
      </div>
      <Formik
        initialValues={{
          domain_name: "",
          url: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          if (values.domain_name && values.url) {
            SystemConfigService.createDomain(values.domain_name, values.url)
              .then((response) => {
                console.log(response.data);
                if (response.data.success) {
                  setSubmitting(false);
                  cogoToast.success(
                    "Domain created successfully",
                    toastoptions
                  );
                  history.push("/system-configuration/view-domains");
                }
              })
              .catch((error) => {
                if (error.response.data.errors) {
                  cogoToast.error(
                    error.response.data.errors.url[0],
                    toastoptions
                  );
                }
                cogoToast.error(error.response.data.message, toastoptions);
                setSubmitting(false);
              });
          } else {
            cogoToast.error("Please fill all the fields", toastoptions);
            setSubmitting(false);
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
          <div className="row">
            <div className="col-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">
                    Fill the details below to create a domain
                  </h4>
                  <form
                    className="forms-sample"
                    name="form"
                    onSubmit={handleSubmit}
                  >
                    <Form.Group>
                      <label htmlFor="domain_name">Name</label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="domain_name"
                        name="domain_name"
                        value={values.domain_name}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        placeholder="Domain Name"
                      />
                    </Form.Group>
                    <Form.Group>
                      <label htmlFor="url">URL</label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="url"
                        placeholder="url"
                        name="url"
                        value={values.url}
                        disabled={isSubmitting}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <button type="submit" className="btn btn-primary mr-2">
                      Submit &nbsp;&nbsp;
                      {isSubmitting ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        ""
                      )}
                    </button>
                    {/* <button className="btn btn-light">Cancel</button> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default withRouter(Domain);
