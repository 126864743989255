import React, { useEffect, useState } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import { SystemConfigService } from "../../../service/configService";
import cogoToast from "cogo-toast";
import { Formik } from "formik";

const CreateBonusConfig = () => {
  const history = useHistory();

  const [storeOptions, setStoreOptions] = useState(null);

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  useEffect(() => {
    SystemConfigService.getStores()
      .then((response) => {
        setStoreOptions(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);

  return (
    <div>
      <div className="row">
        <div className="tab-custom-pills-horizontal col-md-12">
          <div className="">
            <Link to={"/system-configuration/bonus/default-page"}>
              <button type="button" className="btn btn-primary btn-rounded">
                Back
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="page-header">
        <h3 className="page-title"> Bonus Payout </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/system-configuration/all"}>System configuration</Link>
            </li>
          </ol>
        </nav>
      </div>
      <Formik
        initialValues={{
          store_id: "",
          sales: "",
          food_cost: "",
          liquor_cost: "",
          supplies_cost: "",
          payroll_boh: "",
          payroll_foh: "",
          bonuses_cost: "",
          utilities_cost: "",
          admon_fee: "",
          net_profit: "",
          review_score: "",
          evaluation_result: "",
          evaluation_percentage: "",
          general_manager: "",
          kitchen_manager: "",
          foh_manager: "",
          beverage_manager: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          SystemConfigService.createBonusConfig(values)
            .then((response) => {
              console.log(response.data);
              if (response.data.success) {
                setSubmitting(false);
                cogoToast.success("Created successfully", toastoptions);
                history.push("/system-configuration/bonus/default-page");
              }
            })
            .catch((error) => {
              cogoToast.error(error.response.data.message, toastoptions);
              setSubmitting(false);
            });
        }}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
          <div className="row">
            <div className="col-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">
                    Percentage Configuration for Bonus Payout
                  </h4>
                  <form
                    className="forms-sample"
                    name="form"
                    onSubmit={handleSubmit}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group>
                          <label htmlFor="store_id">Store</label>
                          <Form.Control
                            type="text"
                            as="select"
                            className="form-control"
                            id="store_id"
                            name="store_id"
                            value={
                              values.store_id ? values.store_id : "DEFAULT"
                            }
                            onChange={handleChange}
                            disabled={isSubmitting}
                          >
                            <option value="DEFAULT" disabled>
                              Select One
                            </option>

                            {storeOptions &&
                              storeOptions.map((s, index) => (
                                <option value={s.location_id} key={index}>
                                  {s.name.toUpperCase()}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <label htmlFor="sales">Sales % </label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="sales"
                            name="sales"
                            value={values.sales}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                          />
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="food_cost"> Food Cost % </label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="food_cost"
                            name="food_cost"
                            value={values.food_cost}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                          />
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="liquor_cost"> Liquor Cost % </label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="liquor_cost"
                            name="liquor_cost"
                            value={values.liquor_cost}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                          />
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="supplies_cost">
                            {" "}
                            Supplies Cost %{" "}
                          </label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="supplies_cost"
                            name="supplies_cost"
                            value={values.supplies_cost}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                          />
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="payroll_boh">Payroll BOH % </label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="payroll_boh"
                            name="payroll_boh"
                            value={values.payroll_boh}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                          />
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="payroll_foh">Payroll FOH % </label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="payroll_foh"
                            name="payroll_foh"
                            value={values.payroll_foh}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                          />
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="bonuses_cost">
                            {" "}
                            Bonuses Cost Amount{" "}
                          </label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="bonuses_cost"
                            name="bonuses_cost"
                            placeholder="Enter Amount"
                            value={values.bonuses_cost}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                          />
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="utilities_cost">
                            Utilities Cost %{" "}
                          </label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="utilities_cost"
                            name="utilities_cost"
                            value={values.utilities_cost}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                          />
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="admon_fee">Admon Fee % </label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="admon_fee"
                            name="admon_fee"
                            value={values.admon_fee}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                          />
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="net_profit">Net Profit % </label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="net_profit"
                            placeholder="Percentage Net Profit"
                            name="net_profit"
                            value={values.net_profit}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group>
                          <label htmlFor="general_manager">
                            General Manager %{" "}
                          </label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="general_manager"
                            name="general_manager"
                            value={values.general_manager}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                          />
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="kitchen_manager">
                            {" "}
                            Kitchen Manager %{" "}
                          </label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="kitchen_manager"
                            name="kitchen_manager"
                            placeholder="Kitchen Manager"
                            value={values.kitchen_manager}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                          />
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="foh_manager">FOH Manager % </label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="foh_manager"
                            name="foh_manager"
                            value={values.foh_manager}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                          />
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="beverage_manager">
                            {" "}
                            Beverage Manager %{" "}
                          </label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="beverage_manager"
                            name="beverage_manager"
                            value={values.beverage_manager}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                          />
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="review_score"> Review Score </label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="review_score"
                            placeholder="Review Score"
                            name="review_score"
                            value={values.review_score}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                          />
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="evaluation_result">
                            {" "}
                            Evaluation Result{" "}
                          </label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="evaluation_result"
                            placeholder="Evaluation Result"
                            name="evaluation_result"
                            value={values.evaluation_result}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                          />
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="evaluation_percentage">
                            Evaluation Percentage %
                          </label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="evaluation_percentage"
                            placeholder="Evaluation Percentage"
                            name="evaluation_percentage"
                            value={values.evaluation_percentage}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                          />
                        </Form.Group>
                      </div>
                    </div>

                    <button type="submit" className="btn btn-primary mr-2">
                      Submit &nbsp;&nbsp;
                      {isSubmitting ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        ""
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default withRouter(CreateBonusConfig);
