export const RoundNumberUp = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2);
};

export const RoundNumberUpAccurately = (num, decimalPlaces) => {
  return (Math.round(num * 100) / 100).toFixed(2);
};

export const RoundNumberUp4 = (num, decimalPlaces) => {
  return num.toFixed(decimalPlaces);
};

export const sample = (num) => {
  return num.toFixed(num);
};
