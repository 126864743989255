import React, { useState } from "react";
import { pdfjs, Document, Page } from "react-pdf/dist/entry.webpack";
import { Modal, Button } from "react-bootstrap";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DepositPDFReader = ({ pdf }) => {
  const [numPages, setNumPages] = useState(0);
  const [previewMode, setPreviewMode] = useState(false);
  const [mdShow, setMdShow] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const printPDFhandler = () => {
    window.open(pdf.deposit_slip_url, "PRINT", "height=400,width=600");
  };

  return (
    <div>
      {/* <div> */}
      {/* <div className="float-left">{pdf.file_name}</div> */}

      <span>
        {/* <div className="float-right"></div> */}
        {!previewMode && (
          <div>
            <input
              type="button"
              onClick={() => {
                setPreviewMode(true);
                setMdShow(true);
              }}
              value={" View Deposit Slip"}
            />
          </div>
        )}

        {previewMode && (
          <button
            type="button"
            className="btn btn-outline-info btn-icon-text"
            onClick={() => {
              setPreviewMode(false);
              setMdShow(false);
            }}
          >
            {" "}
            Close Preview{" "}
          </button>
        )}
      </span>

      <div className="clearfix"></div>
      {/* </div> */}

      {previewMode && (
        <div>
          <Modal
            size="lg"
            show={mdShow}
            onHide={() => {
              setMdShow(false);
              setPreviewMode(false);
            }}
            aria-labelledby="example-modal-sizes-title-md"
          >
            <Modal.Header closeButton>
              {/* <Modal.Title>Modal title</Modal.Title> */}
            </Modal.Header>

            <Modal.Body>
              <Document
                file={pdf.deposit_slip_url}
                options={{ workerSrc: "/pdf.worker.js" }}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </Modal.Body>

            <Modal.Footer className="fleex-wrap">
              <Button
                variant="success"
                className="text-center"
                onClick={() => {
                  printPDFhandler();
                }}
              >
                Print
              </Button>
              <Button
                variant="success"
                className="text-center"
                onClick={() => {
                  setMdShow(false);
                  setPreviewMode(false);
                }}
              >
                Close Preview
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default DepositPDFReader;
