import React from "react";
import { Button, Modal } from "react-bootstrap";
import FailedPaymentsList from "./FailedPaymentsModalList";
import SuccessfulPaymentsList from "./SuccessfulPaymentsModalList";


const PaymentMadeModal = ({
    handleModalClose,
    madePaymentModal,
    successfulPayments,
    failedPayments,
    isSubmitting
}) => {
    return (
        <>
            <Modal
                size="lg"
                show={madePaymentModal}
                onHide={() => {
                    handleModalClose();
                }}
                aria-labelledby="example-modal-sizes-title-md"
            >
                <div className="">
                    <Modal.Header closeButton>
                        <Modal.Title>Payment History </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                {successfulPayments && (
                                    <SuccessfulPaymentsList successfulPayments={successfulPayments} />
                                )}
                            </div>

                            <div className="col-md-12">
                                {failedPayments && (
                                    <FailedPaymentsList failedPayments={failedPayments} />
                                )}
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer
                        className="flexx-wrap"
                        style={{ paddingTop: "0px" }}
                    >
                        {" "}
                        <Button
                            type="submit"
                            className="btn btn-block btn-primary font-weight-medium auth-form-btn"
                            onClick={() => handleModalClose()}
                        >
                            Proceed &nbsp;&nbsp;
                            {isSubmitting ? (
                                <span className="fa fa-spin fa-spinner"></span>
                            ) : (
                                ""
                            )}
                        </Button>
                        <Button variant="light m-2" onClick={() => handleModalClose()}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    )
}

export default PaymentMadeModal;