import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

const FailedPaymentsList = ({ failedPayments, }) => {

    const failedColumn = [
        {
            dataField: "payrollEmployeeId",
            text: "Employee Id",
            sort: true,
        },
        {
            dataField: "amount",
            text: "Amount",
            sort: true,
        },
        {
            dataField: "allocationDate",
            text: "Allocation Date",
            sort: true,
        },
        {
            dataField: "firstName",
            text: "First Name",
            sort: true,
        },
        {
            dataField: "lastName",
            text: "Last Name",
            sort: true,
        },
        {
            dataField: "errorMessage",
            text: "Error Message",
            sort: true,
        },
        {
            dataField: "status",
            text: "Status",
            sort: true,
            formatter: () => {
                return <label className="badge badge-danger">Pending</label>;
            },
        },
    ];

    const defaultSorted = [
        {
            dataField: "payrollEmployeeId",
            order: "desc",
        },
    ];



    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">
                                {" "}
                                Failed Payment
                                {failedPayments && failedPayments.length > 1
                                    ? "s"
                                    : ""}{" "}
                                ( Queued as pending)
                            </h4>
                            <div className="row">
                                <p>
                                    {" "}
                                    Someone from accounting will reach out to you
                                </p>
                                <div className="col-12">
                                    <ToolkitProvider
                                        keyField="payrollEmployeeId"
                                        bootstrap4
                                        data={failedPayments}
                                        columns={failedColumn}
                                        search
                                    >
                                        {(props) => (
                                            <div>
                                                <BootstrapTable
                                                    defaultSorted={defaultSorted}
                                                    pagination={paginationFactory()}
                                                    {...props.baseProps}
                                                    wrapperClasses="table-responsive"
                                                />
                                            </div>
                                        )}
                                    </ToolkitProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FailedPaymentsList;