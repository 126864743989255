const ShowCriteria = (condition) => {
  let message;

  switch (condition) {
    case true:
      message = "Meet Criteria";
      break;
    case false:
      message = "Did Not Meet Criteria";
      break;
    default:
      message = "Criteria not specified";
  }

  return message;
};

export default ShowCriteria;
