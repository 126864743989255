import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import cogoToast from "cogo-toast";
import { AuthService } from "../../service/authService";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const toastOptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const sendPasswordResetLink = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (!email) {
      cogoToast.error("Enter your email address", toastOptions);
      setSubmitted(false);
      return;
    }

    try {
      const data = { email };
      const response = await AuthService.sendResetEmail(data);
      cogoToast.success(response.data.message, toastOptions);
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred. Please try again.";
      cogoToast.error(errorMessage, toastOptions);
    } finally {
      setSubmitted(false);
    }
  };

  return (
    <div className="d-flex align-items-center auth px-0">
      <div className="row w-100 mx-0">
        <div className="col-lg-4 mx-auto">
          <div className="auth-form-light text-left py-5 px-4 px-sm-5">
            <div className="text-center">
              <div className="brand-logo">
                <Link to={"/home/login"}>
                  <img src={require("../../assets/images/la-parrila-logo.png")} alt="logo" />
                </Link>
              </div>
              <h4>Forgot password?</h4>
              <h6 className="font-weight-light">Don't worry. It is easy to reset</h6>
            </div>
            <form className="pt-3" name="forgot-password-form" onSubmit={sendPasswordResetLink}>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  className="form-control form-control-lg"
                  id="email"
                  onChange={handleChange}
                  placeholder="Email"
                />
                {submitted && !email && (
                  <div className="help-block text-danger">Email is required</div>
                )}
              </div>

              <div className="mt-3">
                <button
                  type="submit"
                  className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                  disabled={submitted}
                >
                  SEND RESET EMAIL &nbsp;&nbsp;
                  {submitted ? <Spinner animation="border" size="sm" /> : ""}
                </button>
              </div>
              <div className="text-center mt-4 font-weight-light">
                Don't want to reset?{" "}
                <Link to="/home/login" className="text-primary">
                  Login
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
