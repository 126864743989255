import React from "react";
import { Form } from "react-bootstrap";

const roundNumberUp = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2);
};

const BartenderPageBoxAll = ({
  totalTipShare,
  amountToBeDistributed,
  instantAllTotal,
}) => {
  return (
    <div className="col-md-12" style={{ marginTop: "70px" }}>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td style={{ width: "15%" }}></td>
            <td style={{ width: "20%" }}></td>
            <td className="amountRow" style={{ width: "25%" }}>
              <span> Total Tip share </span>
              <Form.Group>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <Form.Control
                    type="text"
                    className="form-control"
                    aria-label="Tips Amount"
                    aria-describedby="basic-addon1"
                    id="total_bar_owed"
                    disabled={"disabled"}
                    placeholder="Tips Amount"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    name={"total_tipshare"}
                    value={totalTipShare ? totalTipShare : ""}
                  />
                </div>
              </Form.Group>
            </td>

            <td className="amountRow" style={{ width: "20%" }}>
              <div>
                <span> Amount to be distributed</span>
                <Form.Group>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <Form.Control
                      type="text"
                      className="form-control"
                      aria-label="Tips Amount"
                      aria-describedby="basic-addon1"
                      id="total_bar_owed"
                      disabled={"disabled"}
                      placeholder="Tips Amount"
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      name={"total_bar_owed"}
                      value={amountToBeDistributed ? amountToBeDistributed : ""}
                    />
                  </div>
                </Form.Group>
              </div>
            </td>

            <td className="amountRow" style={{ width: "20%" }}>
              <span> Instant Pay Total</span>
              <Form.Group>
                <div className="input-group" id="instant_pay_total_group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <Form.Control
                    type="text"
                    className="form-control"
                    aria-label="Tips Amount"
                    aria-describedby="basic-addon1"
                    id="instantAllTotal"
                    disabled={"disabled"}
                    placeholder="Tips Amount"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    name={"total_bar_owed"}
                    value={
                      instantAllTotal ? roundNumberUp(instantAllTotal) : ""
                    }
                  />
                </div>
              </Form.Group>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BartenderPageBoxAll;
