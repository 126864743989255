import React from "react";
import { Formik } from "formik";
import Swal from "sweetalert2";
import { Form, Spinner } from "react-bootstrap";
import { UserService } from "../../../../service/userService";
import { RoundNumberUp } from "../../../../components/Utils/RoundNumberUp";

const TipDistributionForm = ({
  salesReport,
  atoTips,
  doorDashTips,
  atoConfig,
  doorDashConfig,
  onSubmitSuccess,
}) => {
  const handleSubmit = (values, { setSubmitting }) => {
    console.log(parseFloat(values.atoTips), "ATOOOOOOO");
    if (values.doorDashTips && !values.doorDashConfig) {
      Swal.fire({
        title: "Error",
        text: "Please select an option for Door Dash",
        icon: "error",
        confirmButtonText: "Ok",
      });
      setSubmitting(false);
      return;
    }

    if (values.atoTips && !values.atoConfig) {
      Swal.fire({
        title: "Error",
        text: "Please select an option for ATO",
        icon: "error",
        confirmButtonText: "Ok",
      });
      setSubmitting(false);
      return;
    }

    const payload = {
      ...values,
      atoTips: parseFloat(RoundNumberUp(values.atoTips, 2)),
      doorDashTips: parseFloat(values.doorDashTips),
    };

    UserService.uploadAtoDoorDashTips(payload)
      .then((response) => {
        console.log("Submission Response:", response.data.data);
        onSubmitSuccess(response.data.data);
        setSubmitting(false);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response?.data?.message || "An error occurred",
          icon: "error",
          confirmButtonText: "Ok",
        });
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{
        atoConfig: atoConfig ? atoConfig : "bartender",
        doorDashConfig: doorDashConfig ? doorDashConfig : "bartender",
        atoTips: atoTips || "",
        doorDashTips: doorDashTips || "",
        report_date: salesReport?.report_date || "",
        owner: salesReport?.owner || "",
        laborBartenders: JSON.parse(localStorage.getItem("laborBartenders")) || [],
        togoEmployees:
          JSON.parse(localStorage.getItem("togoPayments"))?.togoEmployees_details || [],
      }}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, handleChange, values, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Form.Group>
            {atoTips && atoTips > 0 ? (
              <label htmlFor="atoConfig">
                How do you want to distribute Online / DoorDash Tips{" "}
                <b>| {`$ ${RoundNumberUp(atoTips, 2)} `}</b>
              </label>
            ) : (
              <label htmlFor="atoConfig">
                There are no Online / DoorDash Tips <b>| {`$ ${RoundNumberUp(atoTips, 2)} `}</b>
              </label>
            )}
            <Form.Control
              as="select"
              name="atoConfig"
              value={values.atoConfig}
              onChange={handleChange}
            >
              <option value="bartender">Bartender</option>
              <option value="togo">ToGo</option>
              <option value="bar_togo">Bartender & ToGo</option>
            </Form.Control>
          </Form.Group>
          <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
            Submit {isSubmitting && <Spinner animation="border" size="sm" />}
          </button>
        </form>
      )}
    </Formik>
  );
};

export default TipDistributionForm;
