import React from "react";
import { Link } from "react-router-dom";
import { DisplaySpanishAnswer } from "./DisplaySpanishAnswer";

const SpanishSummary = ({ surveyDetails, handleSurveySubmit }) => {
  return (
    <div className="card-body all-card-body">
      <div>
        <h2> Summary (Q1 - Q14) </h2>
      </div>

      <div>
        <div className="pull-left">
          <p>
            Vuelva a consultar las preguntas haciendo clic en el botón Volver a
            las preguntas
          </p>
        </div>

        <div className="pull-right">
          <Link to={"/employees/rate-your-leaders/create"}>
            <button className="btn btn-primary"> Volver a Preguntas </button>
          </Link>
        </div>
      </div>

      <div className="clearfix"></div>

      <div
        className="row"
        style={{
          marginTop: "70px",
        }}
      >
        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q1</b>&nbsp;&nbsp;&nbsp;{" "}
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            &nbsp; trabaja conmigo para construir y mantener una relación de
            confianza y justa que sea productiva
          </p>
          <span> {DisplaySpanishAnswer(surveyDetails.question1)} </span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q2</b>&nbsp;&nbsp;&nbsp;{" "}
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            &nbsp; demuestra responsabilidad por mi trabajo de una manera
            productiva, y se preocupa por mi éxito como yo.
          </p>
          <span>{DisplaySpanishAnswer(surveyDetails.question2)}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q3</b>&nbsp;&nbsp;&nbsp;{" "}
            <b>
              <i>{surveyDetails.manager_name} </i>
            </b>{" "}
            &nbsp; me mantiene informado del propósito, la dirección y los
            objetivos inmediatos de La Parrilla, y de manera importante para
            nuestra división o sección; y del papel que desempeña nuestro equipo
            en la consecución de los mismos.
          </p>
          <span>{DisplaySpanishAnswer(surveyDetails.question3)}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q4</b>&nbsp;&nbsp;&nbsp;{" "}
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            &nbsp; me facilita saber qué trabajo debo hacer, ya que se me
            proporciona un contexto claro, el propósito, las responsabilidades y
            la autoridad de las tareas que expuestas de forma clara y que son
            revisadas y actualizadas.
          </p>
          <span>{DisplaySpanishAnswer(surveyDetails.question4)}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q5</b>&nbsp;&nbsp;&nbsp;{" "}
            <b>
              <i> {surveyDetails.manager_name} </i>
            </b>{" "}
            &nbsp; celebra reuniones periódicas de equipo en las que se discuten
            las tareas de trabajo y se avanza en los planes o se resuelven los
            problemas.
          </p>
          <span>{DisplaySpanishAnswer(surveyDetails.question5)}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q6</b>&nbsp;&nbsp;&nbsp; &nbsp; Cuando surgen tareas o proyectos
            especiales,
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            utiliza un método estructurado para informarme sobre el trabajo que
            debo realizar. Este método incluye el contexto, la cantidad, la
            calidad, los recursos y el tiempo.
          </p>
          <span>{DisplaySpanishAnswer(surveyDetails.question6)}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q7</b>&nbsp;&nbsp;&nbsp;{" "}
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            &nbsp; supervisa adecuadamente el progreso de mi trabajo de forma
            productiva.
          </p>
          <span>{DisplaySpanishAnswer(surveyDetails.question7)}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q8</b>&nbsp;&nbsp;&nbsp;{" "}
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            &nbsp; proporciona una retroalimentación periódica sobre la eficacia
            de mi trabajo que es objetiva y justa.
          </p>
          <span>{DisplaySpanishAnswer(surveyDetails.question8)}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q9</b>&nbsp;&nbsp;&nbsp;{" "}
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            &nbsp; está atento a los malos resultados de los miembros del equipo
            y los gestiona bien para mantener a todos en el buen camino.
          </p>
          <span>{DisplaySpanishAnswer(surveyDetails.question9)}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q10</b>&nbsp;&nbsp;&nbsp;{" "}
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            &nbsp; se asegura de que tengo la autoridad necesaria para llevar a
            cabo mis responsabilidades laborales conocidas.
          </p>
          <span>{DisplaySpanishAnswer(surveyDetails.question10)}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q11</b>&nbsp;&nbsp;&nbsp; &nbsp; Para que me desarrolle en mi
            función, &nbsp;
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            &nbsp; me proporciona regularmente entrenamiento para mi mejora de
            forma estructurada.
          </p>
          <span>{DisplaySpanishAnswer(surveyDetails.question11)}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q12</b>&nbsp;&nbsp;&nbsp;{" "}
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            &nbsp; añade valor a los retos laborales que tengo al ver los
            problemas de una manera diferente, y aportando claridad.
          </p>
          <span>{DisplaySpanishAnswer(surveyDetails.question12)}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q13</b>&nbsp;&nbsp;&nbsp;{" "}
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            &nbsp; gestiona adecuadamente los conflictos interpersonales dentro
            de nuestro equipo.
          </p>
          <span>{DisplaySpanishAnswer(surveyDetails.question13)}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q14</b>&nbsp;&nbsp;&nbsp; &nbsp; Para garantizar que nuestro
            equipo trabaje con una eficiencia óptima&nbsp;
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            &nbsp; ha creado un equipo de personas que se adaptan perfectamente
            a la complejidad de su función.
          </p>
          <span>{DisplaySpanishAnswer(surveyDetails.question14)}</span>
        </div>
      </div>

      <div
        style={{
          marginTop: "20px",
        }}
      >
        <button
          className="btn btn-primary"
          onClick={() => {
            handleSurveySubmit();
          }}
        >
          Proceder y enviar
        </button>
      </div>
    </div>
  );
};

export default SpanishSummary;
