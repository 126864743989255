import React, { useMemo } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import MultiStep from "react-multistep";
import SalesReportStage from "./closingReport/SalesReportStage";
import PayBarStage from "./closingReport/PayBarStage";
import ExpensesStage from "./closingReport/ExpensesStage";
import TipIncomeReportStage from "./closingReport/TipIncomeReportStage";
import InstantPayPayment from "./closingReport/InstantPayPayment";
import PayToGo from "./closingReport/PayToGo";
import PayATODoorDash from "./closingReport/PayATODoorDash";

const ClosingReport = () => {
  const steps = useMemo(
    () => [
      { name: ". Sales Report", component: <SalesReportStage /> },
      { name: ". Server / Bar / ToGo", component: <TipIncomeReportStage /> },
      { name: ". Online Tips / Door Dash", component: <PayATODoorDash /> },
      { name: ". Pay Bartenders", component: <PayBarStage /> },
      { name: ". Pay ToGo", component: <PayToGo /> },
      { name: ". Expenses / Deposit", component: <ExpensesStage /> },
      { name: ". Instant Pay Payment", component: <InstantPayPayment /> },
    ],
    []
  );

  return (
    <div className="container form-manager-wrapper" style={{ width: "1140px" }}>
      <div>
        <p className="pull-right" style={{ textDecoration: "underline" }}>
          <a href="https://veltron.document360.io/v1/en" target="_blank" rel="noopener noreferrer">
            Learn How &nbsp;<span className="fa fa-question"></span>
          </a>
        </p>

        <div className="clearfix"></div>
      </div>

      <div>
        <p>
          {" "}
          <Link to="/form-manager/restaurant-manager">
            <span className="fa fa-caret-left"></span> &nbsp; &nbsp;Click here to go back
          </Link>
        </p>
      </div>

      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <div className="form-wizard">
                <MultiStep showNavigation={true} className="my-class" steps={steps} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ClosingReport);
