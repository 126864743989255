import React from "react";
import { useDropzone } from "react-dropzone";
import cogoToast from "cogo-toast";

export const FileUpload = ({ setFieldValue, fieldName, acceptedTypes, label }) => {
  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noClick: false,
    noKeyboard: true,
    accept: acceptedTypes,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        cogoToast.error(`File format not supported. Use only: ${acceptedTypes}`, toastoptions);
        return;
      }
      if (acceptedFiles.length === 0) {
        cogoToast.error("No files uploaded", toastoptions);
      } else if (acceptedFiles.length > 1) {
        cogoToast.error("You cannot upload more than 1 file at a time", toastoptions);
      } else {
        setFieldValue(fieldName, acceptedFiles);
      }
    },
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div>
      <div
        {...getRootProps({ className: "dropzone" })}
        style={{
          border: "1px solid #ebedf2",
          padding: "15px",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#c8dadf",
        }}
      >
        <div style={{ border: "2px dashed #ebedf2" }}>
          <input {...getInputProps()} />
          <div className="text-center">
            <span className="mdi mdi-cloud-download mdi-48px"></span>
          </div>
          <p className="text-center">Drag & drop a file here or Click to upload</p>
        </div>
      </div>

      {files.length > 0 && (
        <aside>
          <h5 className="my-2">{label}:</h5>
          <ul className="list-ticked">{files}</ul>
        </aside>
      )}
    </div>
  );
};
