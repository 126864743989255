import React, { useEffect, useState } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { PaymentService } from "../../service/PaymentService";
import { SystemConfigService } from "../../service/configService";
import Swal from "sweetalert2";
import { ConvertDateFormat } from "../../components/ConvertDateFormat";
import { Utility } from "../../Helpers/utils";

const { SearchBar } = Search;
const user = Utility.getUser();

const StoreScheduledPayments = () => {
  let { store_id } = useParams();
  const [store, setStore] = useState(null);
  const [scheduled, setScheduled] = useState(null);
  // const [isSubmitting, setIsSubmitting] = useState(false);
  // const [scheduled, setScheduled] = useState(false);

  useEffect(() => {
    PaymentService.getScheduledPayment(store_id)
      .then((response) => {
        console.log(response.data.data);
        let ScheduledResArray = [];
        response.data.data.map((res, index) => {
          return ScheduledResArray.push({
            id: res.id,
            payrollEmployeeId: res.payrollEmployeeId,
            firstName: res.firstName,
            lastName: res.lastName,
            amount: res.amount,
            reportDate: ConvertDateFormat(res.reportDate),
            allocationDate: ConvertDateFormat(res.allocationDate),
            message: res.message,
            code: res.code,
          });
        });
        setScheduled(ScheduledResArray);
      })
      .catch((error) => {
        console.log(error.response.data);
      });

    SystemConfigService.getStore(store_id)
      .then((response) => {
        console.log(response.data.data);
        setStore(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        // cogoToast.error(error.response.data.message, toastoptions);
      });
  }, []);

  const unSchedulePayment = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to Unschedule a payment!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#000000",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(data);
        PaymentService.unScheduleAPayment(data)
          .then((response) => {
            console.log(response.data.data);
            Swal.fire(
              "Successful!",
              "Payment has been unscheduled.",
              "success"
            );
            window.location.reload();
          })
          .catch((error) => {
            console.log(error.response.data);
            Swal.fire("Error!", error.response.data.message, "error");
          });
      }
    });
  };

  const columns = [
    {
      dataField: "payrollEmployeeId",
      text: "Employee Id",
      sort: true,
    },
    {
      dataField: "firstName",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "lastName",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
    },
    {
      dataField: "reportDate",
      text: "Report Date",
      sort: true,
    },
    {
      dataField: "allocationDate",
      text: "Allocation Date",
      sort: true,
    },
    {
      dataField: "code",
      text: "Error Code",
      sort: true,
    },
    {
      dataField: "message",
      text: "Message",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: () => {
        return <label className="badge badge-info">Scheduled</label>;
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div>
            {user && (user.role === "super admin" || user.role === "hr") ? (
              <button
                className="btn btn-outline-primary"
                onClick={(e) => {
                  unSchedulePayment(row);
                }}
              >
                UnSchedule Payment
              </button>
            ) : (
              "None"
            )}
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <div>
      <div>
        <p className="pull-right" style={{ textDecoration: "underline" }}>
          <a
            href="https://veltron.document360.io/v1/en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn How &nbsp;<span className="fa fa-question"></span>
          </a>
        </p>

        <div className="clearfix"></div>
      </div>
      <div className="page-header">
        <h3 className="page-title">
          <span style={{ textTransform: "capitalize" }}>
            Scheduled Payments
          </span>
        </h3>
        <div>
          <Link to={"/financials/scheduled-payments"}>
            <button className="btn btn-primary">
              {" "}
              View All Scheduled Payments{" "}
            </button>
          </Link>
        </div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/financials/pending-payments">
                <span style={{ textTransform: "capitalize" }}>
                  {" "}
                  {store ? store.name : ""}
                </span>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <span style={{ textTransform: "capitalize" }}>
                Scheduled Payments
              </span>
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-12">
          {scheduled && (
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"> Scheduled Payment</h4>
                <div className="row">
                  <div className="col-12">
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={scheduled}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div className="d-flex align-items-center">
                            <div>
                              <p className="mb-2 mr-2">Search in table:</p>
                              <SearchBar {...props.searchProps} />
                            </div>
                          </div>
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            loading={true}
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(StoreScheduledPayments);
