import React, { useState, useEffect } from "react";
import { BonusPayoutService } from "../../../../service/BonusPayoutService";

const BonusEvaluation = ({ quarter, year, storeId }) => {
  const [evaluationStat, setEvaluationStat] = useState(null);

  let data = {
    year,
    quarter,
    store_id: storeId,
  };

  useEffect(() => {
    BonusPayoutService.getEvaluationStatsFromDB(data)
      .then((response) => {
        setEvaluationStat(response.data.data.evaluation);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div style={{ marginTop: "30px" }}>
      <p> Evaluation from Staff </p>
      <table className="table table-bordered">
        <thead>
          <tr style={{ background: "grey", color: "white" }}>
            <th style={{ width: "40%" }}> Manager's Name </th>
            <th style={{ width: "20%" }}>Result</th>
            <th style={{ width: "15%" }}>*TE</th>
            <th style={{ width: "15%" }}>*TS</th>
            <th style={{ width: "10%" }}>%</th>
          </tr>
        </thead>
        <tbody>
          {evaluationStat &&
            evaluationStat.map((evaluation, index) => (
              <tr key={index}>
                <td> {evaluation.managerName} </td>
                <td>{evaluation.result}</td>
                <td> {evaluation.totalEmployee}</td>
                <td>{evaluation.totalSurveyForAManager}</td>
                <td> {evaluation.percentageEvaluation}</td>
              </tr>
            ))}
        </tbody>
      </table>

      <div style={{ marginTop: "40px" }}>
        <h5 style={{ color: "red", fontWeight: "bold" }}>
          {" "}
          *TE:{" "}
          <span style={{ color: "black", fontWeight: "400" }}>
            {" "}
            Total Employees
          </span>
        </h5>{" "}
        <h5 style={{ color: "red", fontWeight: "bold" }}>
          {" "}
          *TS:{" "}
          <span style={{ color: "black", fontWeight: "400" }}>
            {" "}
            Total Surveys
          </span>
        </h5>{" "}
      </div>
    </div>
  );
};

export default BonusEvaluation;
