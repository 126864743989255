import { Formik } from "formik";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { withRouter } from "react-router";
import DatePicker from "react-datepicker";
import { InstantTransactionService } from "../../service/InstantTransactionService";
import cogoToast from "cogo-toast";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { ConvertDateFormat } from "../../components/ConvertDateFormat";
const { SearchBar } = Search;

const InstantTransaction = () => {
  const [history, setHistory] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  const columns = [
    {
      dataField: "id",
      text: "Index",
      sort: true,
    },
    {
      dataField: "allocated_date",
      text: "Allocated Date",
      sort: true,
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "employeeId",
      text: "Employee ID",
      sort: true,
    },
    {
      dataField: "locationName",
      text: "Location Name",
      sort: true,
    },
    {
      dataField: "manager",
      text: "Manager",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <div>
      <div>
        <p className="pull-right" style={{ textDecoration: "underline" }}>
          <a
            href="https://veltron.document360.io/v1/en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn How &nbsp;<span className="fa fa-question"></span>
          </a>
        </p>

        <div className="clearfix"></div>
      </div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title"> Retrieving Transaction History </h4>
              <p className="card-description">
                Please select your preferred start and end date using the form
                below and hit the <b>Submit</b> button to proceed.
              </p>
              <Formik
                initialValues={{
                  start_date: "",
                  end_date: "",
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  console.log(values);
                  InstantTransactionService.getInstantTransaction(values)
                    .then((response) => {
                      cogoToast.success("Retrieved successfully", toastoptions);
                      console.log(response.data.data.content);
                      let responseArr = [];
                      response.data.data.content.map((res, index) => {
                        return responseArr.push({
                          id: index,
                          allocated_date: ConvertDateFormat(res.allocatedAt),
                          amount: res.amount,
                          name: `${res.firstName} ${
                            res.lastName ? res.lastName : ""
                          }`,
                          email: res.email,
                          employeeId: res.employeeId,
                          locationName: res.locationName,
                          manager: res.manager,
                          status: res.status,
                        });
                      });
                      setHistory(responseArr);
                      setSubmitting(false);
                    })
                    .catch((error) => {
                      console.log(error.response);
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form className="form-inline" onSubmit={handleSubmit}>
                    <label htmlFor="start_date">Start Date</label>
                    <DatePicker
                      className="form-control mb-2 mr-sm-2"
                      dateFormat="MM/dd/yyyy"
                      selected={startDate}
                      isClearable
                      placeholderText="Select a start date"
                      onChange={(start_date) => {
                        console.log(start_date);
                        setStartDate(start_date);
                        setFieldValue(
                          `start_date`,
                          start_date ? start_date : ""
                        );
                      }}
                    />

                    <label className="" htmlFor="end_date">
                      End Date
                    </label>
                    <div className="input-group mb-2 mr-sm-2">
                      <DatePicker
                        className="form-control mb-2 mr-sm-2"
                        dateFormat="MM/dd/yyyy"
                        selected={endDate}
                        isClearable
                        placeholderText="Select an end date"
                        onChange={(end_date) => {
                          console.log(end_date);
                          setEndDate(end_date);
                          // handleChangeDate
                          setFieldValue(`end_date`, end_date ? end_date : "");
                        }}
                      />
                    </div>
                    <button type="submit" className="btn btn-primary mb-2">
                      Submit &nbsp;&nbsp;
                      {isSubmitting ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        ""
                      )}
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="card">
          {history && (
            <div className="card-body">
              <h4 className="card-title"> Transaction History </h4>
              <div className="row">
                <div className="col-12">
                  <ToolkitProvider
                    keyField="id"
                    bootstrap4
                    data={history}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <div className="d-flex align-items-center">
                          <p className="mb-2 mr-2">Search in table:</p>
                          <SearchBar {...props.searchProps} />
                        </div>
                        <BootstrapTable
                          defaultSorted={defaultSorted}
                          pagination={paginationFactory()}
                          {...props.baseProps}
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(InstantTransaction);
