import AccountingReport from "../accounting/ClosingReportDetails";
import ViewAccountingReportDetails from "../accounting/ViewClosingReportDetails";
import ViewAndDownloadReportDetails from "../accounting/ViewAndDownloadReportDetails";
import SalesPercentageReportStore from "../accounting/SalesReportStore";
import SalesPercentageReportList from "../accounting/SalesReportList";
import SalesPercentageReportDetails from "../accounting/SalesReportDetails";

const accountingRoutes = [
  { path: "/accounting/report", component: AccountingReport },
  { path: "/accounting/sales-report/:store_id", component: ViewAccountingReportDetails },
  { path: "/accounting/view-sales-report/:store_id/:id", component: ViewAndDownloadReportDetails },
  { path: "/percentage/report", component: SalesPercentageReportStore },
  { path: "/percentage/view-report/:store_id", component: SalesPercentageReportList },
  { path: "/percentage/view-details/:store_id/:id", component: SalesPercentageReportDetails },
];

export default accountingRoutes;
