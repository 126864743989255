export const DisplaySpanishAnswer = (ans) => {
  let response = "";

  switch (ans) {
    case "never":
      response = "nunca";
      break;
    case "sometimes":
      response = "A veces";
      break;
    case "often":
      response = "A menudo";
      break;
    case "always":
      response = "Siempre";
      break;
    default:
      response = "";
  }

  return response;
};
