import React, { useEffect } from "react";
import NumberFormat from "react-number-format";
import { RoundNumberUp } from "../../../../components/Utils/RoundNumberUp";
import CriteriaMark from "../CriteriaMark";
import ShowCriteria from "../ShowCriteria";

const BonusPayout = ({ quarter, breakdownData }) => {
  return (
    <div>
      <div className="row">
        {breakdownData &&
          breakdownData.map((data, index) => (
            <div
              className="col-md-9 col-md-offset-1"
              key={index}
              style={{
                marginTop: "30px",
                padding: "20px",
                border: "2px dotted",
              }}
            >
              <div>
                <table className="table">
                  <tbody>
                    <tr>
                      <th scope="col">Manager's Name</th>
                      <td>{data.managerName}</td>
                    </tr>
                    <tr>
                      <th scope="col">Salary</th>
                      <td>
                        <NumberFormat
                          value={RoundNumberUp(data.salary)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$ "}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">Annual Bonus: </th>
                      <td>
                        <NumberFormat
                          value={RoundNumberUp(data.annualBonus)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$ "}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">Quarter | Base: </th>
                      <td>
                        <NumberFormat
                          value={RoundNumberUp(data.quarterBonus)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$ "}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">Manager Type : </th>
                      <td>{data.manager_type.toUpperCase()}</td>
                    </tr>

                    <tr
                      style={{
                        background: "black",
                        color: "white",
                      }}
                    >
                      <th scope="row">
                        {`Q${quarter}`} - Total Earned Bonus :{" "}
                      </th>
                      <td>
                        <NumberFormat
                          value={RoundNumberUp(data.totalEarnedForTheQuarter)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$ "}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                style={{
                  marginTop: "20px",
                }}
              >
                <table className="table table-bordered">
                  <thead>
                    <tr
                      style={{
                        background: "#7e2828",
                        color: "white",
                      }}
                    >
                      <th scope="col">KPIs</th>
                      <th scope="col">%</th>
                      <th scope="col">{`Q${quarter}`}</th>
                      <th scope="col">$</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.KPI &&
                      data.KPI.map((kpi, index) => (
                        <tr key={index}>
                          <td>{kpi.name}</td>
                          <td>{kpi.percentage}</td>
                          <td>{ShowCriteria(kpi.condition)}</td>
                          <td>
                            {" "}
                            <NumberFormat
                              value={RoundNumberUp(kpi.amountEarned)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$ "}
                            />
                          </td>
                          <td>{CriteriaMark(kpi.condition)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <table className="table table-bordered mt-3">
                  <tbody>
                    <tr
                      style={{
                        background: "black",
                        color: "white",
                      }}
                    >
                      <td> Total Bonus</td>
                      <td className="amountRow">
                        <NumberFormat
                          value={RoundNumberUp(data.totalEarnedForTheQuarter)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$ "}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ))}
      </div>

      <div
        style={{
          marginTop: "40px",
        }}
      >
        <p> Reviewed By : ____________</p>
        <p> Authorized By : ____________</p>
      </div>
    </div>
  );
};

export default BonusPayout;
