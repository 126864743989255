import React, { useEffect, useState } from "react";
import { UserService } from "../../service/userService";
import { Utility } from "../../Helpers/utils";
import { Link, useHistory, withRouter } from "react-router-dom";
import ApplicationEntries from "./sections/ApplicationEntries";
import ListEmployees from "./sections/ListEmployees";
import BonusPayoutKPI from "./sections/BonusKpi";
import EmployeeDashboard from "./EmployeeDashboard";

const Dashboard = () => {
  const [employees, setEmployees] = useState(null);
  const [user, setUser] = useState({});
  const history = useHistory();

  useEffect(() => {
    const user = Utility.getUser();
    if (user && !user.first_login) {
      setUser(user);
      UserService.getEmployees()
        .then((response) => {
          setEmployees(response.data.data.activeEmployees);
        })
        .catch((error) => {
          console.log(error.response);
        });
    } else {
      history.push("/home/login");
    }
  }, []);

  return (
    <div
      style={{
        background: "#f2f2f2",
        padding: "30px",
      }}
    >
      <div>
        {user && user.role !== "employee" ? (
          <div>
            <div className="page-header flex-wrap">
              <div
                id="mf_placeholder"
                data-formurl="//forms.laparrilla.com/embed.php?id=76983"
                data-formheight="8110"
                data-formtitle="Recipe Book Daily Click List"
                data-paddingbottom="10"
              ></div>
              <h3 className="mb-0">Hi, {user.first_name}. welcome back!</h3>
              <div className="d-flex">
                {user.role === "super admin" ? (
                  <Link to={"/employees/add"}>
                    <button type="button" className="btn btn-sm ml-3 btn-success mr-0">
                      {" "}
                      <span className="fa fa-plus"></span> Add Employee
                    </button>
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div>{user.role === "restaurant manager" ? <BonusPayoutKPI user={user} /> : ""}</div>

            {user.role === "super admin" ? (
              <div className="row" style={{ marginBottom: "30px" }}>
                <ApplicationEntries user={user} />
              </div>
            ) : (
              ""
            )}

            <div>
              <ListEmployees employees={employees} />
            </div>
          </div>
        ) : (
          <div>
            <EmployeeDashboard user={user} />
          </div>
        )}
      </div>
    </div>
  );
};
export default withRouter(Dashboard);
