import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

const SuccessfulPaymentsList = ({ successfulPayments }) => {
    const successfulColumn = [
        {
            dataField: "payrollEmployeeId",
            text: "Employee Id",
            sort: false,
        },
        {
            dataField: "amount",
            text: "amount",
            sort: true,
        },
        {
            dataField: "allocationDate",
            text: "Allocation Date",
            sort: true,
        },
        {
            dataField: "firstName",
            text: "First Name",
            sort: true,
        },
        {
            dataField: "lastName",
            text: "Last Name",
            sort: true,
        },
        {
            dataField: "paymentMessage",
            text: "Payment Message",
            sort: true,
        },
        {
            dataField: "status",
            text: "Status",
            sort: true,
            formatter: () => {
                return <label className="badge badge-success">Completed</label>;
            },
        },
    ];

    const defaultSorted = [
        {
            dataField: "payrollEmployeeId",
            order: "desc",
        },
    ];

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">
                                {" "}
                                Successful Payment
                                {successfulPayments &&
                                    successfulPayments.length > 1
                                    ? "s"
                                    : ""}
                            </h4>
                            <div className="row">
                                <p>
                                    {" "}
                                    The following list has been scheduled for
                                    payment
                                </p>
                                <div className="col-12">
                                    <ToolkitProvider
                                        keyField="payrollEmployeeId"
                                        bootstrap4
                                        data={successfulPayments}
                                        columns={successfulColumn}
                                        search
                                    >
                                        {(props) => (
                                            <div>
                                                <BootstrapTable
                                                    defaultSorted={defaultSorted}
                                                    pagination={paginationFactory()}
                                                    {...props.baseProps}
                                                    wrapperClasses="table-responsive"
                                                />
                                            </div>
                                        )}
                                    </ToolkitProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SuccessfulPaymentsList;