import React from "react";
import { Formik, FieldArray, Field } from "formik";
import { Form } from "react-bootstrap";

const PaymentDetailsTable = ({ title, tips, editMode, onEdit, onCancel, onSubmit }) => {
  return (
    <div>
      <Formik initialValues={{ tips }} onSubmit={(values) => onSubmit(values.tips)}>
        {({ values, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="togoPaymentDetails">
            <div className="row">
              <div className="table-responsive col-md-12" style={{ marginTop: "30px" }}>
                <FieldArray name="tips">
                  {() => (
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th style={{ width: "15%" }}> Number </th>
                          <th style={{ width: "25%" }}> Name</th>
                          <th style={{ width: "20%" }}> Job Code </th>
                          <th style={{ width: "25%" }}> Tips </th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.tips.map((tip, index) => (
                          <tr key={index}>
                            <td>
                              <Form.Group>
                                <Field
                                  name={`tips[${index}].employeeId`}
                                  disabled
                                  className="form-control"
                                  style={{
                                    border: "none",
                                    fontWeight: "bold",
                                  }}
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <Field
                                  name={`tips[${index}].name`}
                                  disabled
                                  className="form-control"
                                  style={{
                                    border: "none",
                                    fontWeight: "bold",
                                  }}
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <Field
                                  name={`tips[${index}].jobCode`}
                                  disabled
                                  className="form-control"
                                  style={{
                                    border: "none",
                                    fontWeight: "bold",
                                  }}
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                  </div>
                                  <Field
                                    name={`tips[${index}].tipShareReceived`}
                                    disabled={!editMode}
                                    className="form-control"
                                    style={{ background: !editMode ? "#e9ecef" : "transparent" }}
                                  />
                                </div>
                              </Form.Group>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </FieldArray>
              </div>
            </div>

            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              {!editMode && (
                <button type="button" onClick={onEdit} className="btn btn-primary">
                  Edit
                </button>
              )}

              {editMode && (
                <button type="submit" className="btn btn-success">
                  Save
                </button>
              )}

              <button type="button" onClick={onCancel} className="btn btn-light">
                Cancel &nbsp;&nbsp;<i className="mdi mdi-close-circle-outline"></i>
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PaymentDetailsTable;
