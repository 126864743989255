import React, { useState, useEffect, Suspense } from "react";
import { withRouter, useLocation } from "react-router-dom";
import "./App.scss";
import "../assets/styles/custom-styles.scss";
import { appRoutes } from "./Routes";
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
import Footer from "./shared/Footer";
import { Utility } from "../Helpers/utils";
import "react-confirm-alert/src/react-confirm-alert.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import "react-aspect-ratio/aspect-ratio.css";
import IdleTimerContainer from "../components/idleTimerContainer";
import { BonusProvider } from "../contexts/bonus/BonusContext";
import { Switch, Route, Redirect } from "react-router-dom";
import Spinner from "../app/shared/Spinner";
import { PayBarProvider } from "./form-manager/closingReport/context/PayBarContext";

const App = () => {
  const [isFullPageLayout, setIsFullPageLayout] = useState(false);
  const location = useLocation();

  // Define routes that require a full-page layout
  const fullPageLayoutRoutes = [
    "/home/login",
    "/home/admin/login",
    "/password/forgot-password",
    "/password/reset-password/:token",
    "/auth/first-login",
    "/error-pages/error-404",
    "/error-pages/error-500",
    "/general-pages/landing-page",
    "/employees/rate-your-leaders/create",
    "/employees/rate-your-leaders/begin/spanish",
    "/employees/rate-your-leaders/begin/english",
    "/employees/rate-your-leaders",
    "/employees/survey/response-summary",
  ];

  useEffect(() => {
    console.log("ROUTE CHANGED");
    const body = document.querySelector("body");

    // Handle RTL layout
    if (location.pathname === "/layout/RtlLayout") {
      body.classList.add("rtl");
    } else {
      body.classList.remove("rtl");
    }

    // Scroll to top on route change
    window.scrollTo(0, 0);

    // Determine if the current route needs a full-page layout
    const isFullPage = fullPageLayoutRoutes.some((route) => location.pathname.includes(route));

    setIsFullPageLayout(isFullPage);

    const pageBodyWrapper = document.querySelector(".page-body-wrapper");
    if (isFullPage) {
      // eslint-disable-next-line no-unused-expressions
      pageBodyWrapper?.classList.add("full-page-wrapper");
    } else {
      // eslint-disable-next-line no-unused-expressions
      pageBodyWrapper?.classList.remove("full-page-wrapper");
    }
  }, [location.pathname]);

  // Conditional rendering of components based on layout type
  const user = Utility.getUser();
  const navbarComponent = !isFullPageLayout && <Navbar />;
  const sidebarComponent = !isFullPageLayout && <Sidebar user={user} />;
  const footerComponent = !isFullPageLayout && <Footer />;

  return (
    <PayBarProvider>
      <BonusProvider>
        <div className="container-scroller">
          {user && <IdleTimerContainer />}

          {navbarComponent}
          <div className="container-fluid page-body-wrapper">
            {sidebarComponent}
            <div className="main-panel">
              <div className="content-wrapper">
                <Suspense fallback={<Spinner />}>
                  <Switch>
                    {appRoutes.map(({ path, component }, index) => (
                      <Route key={index} path={path} component={component} exact />
                    ))}
                    <Redirect to="/dashboard" />
                  </Switch>
                </Suspense>
              </div>
              {footerComponent}
            </div>
          </div>
        </div>
      </BonusProvider>
    </PayBarProvider>
  );
};

export default withRouter(App);
