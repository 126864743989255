import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { BonusPayoutService } from "../../../service/BonusPayoutService";
import { Form, Spinner } from "react-bootstrap";
import { SystemConfigService } from "../../../service/configService";
import moment from "moment";

const EvaluationBonusPayout = () => {
  const [evaluationStat, setEvaluationStat] = useState(null);
  const [storeConfig, setStoreConfig] = useState(null);
  const [quarter, setQuarter] = useState(null);
  const [storeOptions, setStoreOptions] = useState(null);

  const year = moment().year();
  const activeQuarter = moment().quarter();

  useEffect(() => {
    let data = {
      year: year,
      quarter: activeQuarter,
      store_id: "1",
    };

    setQuarter(data.quarter);

    BonusPayoutService.getEvaluationStatsFromDB(data)
      .then((response) => {
        console.log(response.data.data);
        setEvaluationStat(response.data.data.evaluation);
        setStoreConfig(response.data.data.config);
      })
      .catch((error) => {
        console.log(error);
      });

    // get stores
    SystemConfigService.getStores()
      .then((response) => {
        setStoreOptions(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });

    // use the default value to retrieve stats
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <p className="card-description">
                Please select your preferred search term and hit the{" "}
                <b>Submit</b> button to proceed.
              </p>
              <Formik
                initialValues={{
                  year: year,
                  quarter: activeQuarter,
                  store_id: "1",
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  setQuarter(values.quarter);
                  BonusPayoutService.getEvaluationStatsFromDB(values)
                    .then((response) => {
                      console.log(response.data.data, "From selection");
                      setEvaluationStat(response.data.data.evaluation);
                      setStoreConfig(response.data.data.config);
                      setSubmitting(false);
                    })
                    .catch((error) => {
                      console.log(error);
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form className="form-inline" onSubmit={handleSubmit}>
                    <div>
                      <Form.Control
                        type="text"
                        as="select"
                        className="form-control"
                        id="year"
                        name="year"
                        defaultValue={values.year ? values.year : "2021"}
                        onChange={handleChange}
                        disabled={isSubmitting}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        style={{ width: "250px", margin: "0px 10px" }}
                      >
                        <option value="DEFAULT" disabled>
                          Select Year
                        </option>
                        <option value="2021"> 2021 </option>
                        <option value="2022"> 2022 </option>
                        <option value="2023"> 2023 </option>
                        <option value="2024"> 2024 </option>
                      </Form.Control>
                    </div>

                    <div>
                      <Form.Control
                        type="text"
                        as="select"
                        className="form-control"
                        id="quarter"
                        name="quarter"
                        defaultValue={values.quarter ? values.quarter : "Q1"}
                        onChange={handleChange}
                        disabled={isSubmitting}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        style={{ width: "250px", margin: "0px 10px" }}
                      >
                        <option value="DEFAULT" disabled>
                          Select Quarter
                        </option>
                        <option value="1"> Q1 </option>
                        <option value="2"> Q2 </option>
                        <option value="3"> Q3 </option>
                        <option value="4"> Q4 </option>
                      </Form.Control>
                    </div>

                    <div>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          as="select"
                          className="form-control"
                          id="store_id"
                          name="store_id"
                          value={values.store_id ? values.store_id : "DEFAULT"}
                          onChange={handleChange}
                          disabled={isSubmitting}
                        >
                          <option value="DEFAULT" disabled>
                            Select Location
                          </option>

                          {storeOptions &&
                            storeOptions.map((s, index) => (
                              <option value={s.location_id} key={index}>
                                {s.name.toUpperCase()}
                              </option>
                            ))}
                        </Form.Control>
                      </Form.Group>
                    </div>

                    <div>
                      <button type="submit" className="btn btn-primary mb-2">
                        Submit &nbsp;&nbsp;
                        {isSubmitting ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <div>
        <p> TE* == Total Employees</p>
        <p> TS* == Total Survey</p>
      </div>

      <div>
        {evaluationStat && (
          <div className="row">
            <div
              className="col-md-6"
              style={{
                border: "1px dotted grey",
                padding: "20px",
                overflowX: "auto",
              }}
            >
              
              {/* <h4 className="text-center"> {evaluationStat[0].store_name}</h4> */}

              <table className="table table-bordered">
                <thead>
                  <tr
                    style={{
                      background: "grey",
                      color: "white",
                      paddingLeft: "2px",
                    }}
                  >
                    <th scope="col">Manager's Name</th>
                    <th scope="col"> Result</th>
                    <th scope="col">TE</th>
                    <th scope="col">TS</th>
                    <th scope="col">%</th>
                  </tr>
                </thead>
                <tbody>
                  {evaluationStat.map((stat, index) => (
                    <tr key={index}>
                      <td>{stat.managerName}</td>

                      <td
                        style={
                          stat.result &&
                          storeConfig &&
                          parseInt(stat.result) >=
                            parseInt(storeConfig.evaluation_result)
                            ? {
                                border: "2px solid green",
                              }
                            : {
                                border: "2px solid red",
                              }
                        }
                      >
                        {stat.result}
                      </td>
                      <td>{stat.totalEmployee}</td>
                      <td>{stat.totalSurveyForAManager}</td>
                      <td
                        style={
                          stat.percentageEvaluation &&
                          storeConfig &&
                          parseInt(stat.percentageEvaluation) >=
                            parseInt(storeConfig.evaluation_percentage)
                            ? {
                                border: "2px solid green",
                              }
                            : {
                                border: "2px solid red",
                              }
                        }
                      >
                        {stat.percentageEvaluation} %{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EvaluationBonusPayout;
