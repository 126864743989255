import Employees from "../employee/ViewEmployees";
import AddEmployee from "../employee/AddEmployee";
import ViewEmployee from "../employee/ViewEmployee";
import ViewMyProfile from "../employee/ViewMyProfile";
import CreateBulkUpload from "../employee/bulk/CreateBulkUpload";
import EditEmployee from "../employee/EditEmployee";
import ViewApplicationEntries from "../employee/application/ShowApplicationEntries";
import ViewStoreApplications from "../employee/application/ViewStoreApplications";
import ViewStoreApplicationDetails from "../employee/application/ViewStoreApplicationDetails";
import EditStoreApplicationDetails from "../employee/application/EditStoreApplicationDetails";
import CreateRateMyBoss from "../employee/survey/CreateRateMyBoss";
import RateYourLeaders from "../employee/survey/RateYourLeaders";
import RateYourLeadersSpanish from "../employee/survey/languageSurvey/spanish/RateYourLeadersSpanish";
import RateYourLeadersEnglish from "../employee/survey/languageSurvey/english/RateYourLeadersEnglish";
import ResponseSummary from "../employee/survey/answer/ResponseSummary";

const employeesRoutes = [
  { path: "/employees/list", component: Employees },
  { path: "/employees/add", component: AddEmployee },
  { path: "/employees/bulk/upload", component: CreateBulkUpload },
  { path: "/employees/view/:slug", component: ViewEmployee },
  { path: "/employee/view/my-profile/:slug", component: ViewMyProfile },
  { path: "/employees/edit/:slug", component: EditEmployee },
  { path: "/employees/application", component: ViewApplicationEntries },
  { path: "/employees/entries/:store_id", component: ViewStoreApplications },
  { path: "/employees/details/:store_id", component: ViewStoreApplicationDetails },
  { path: "/employees/edit-details/:store_id", component: EditStoreApplicationDetails },
  { path: "/employees/rate-your-leaders/create", component: CreateRateMyBoss },
  { path: "/employees/rate-your-leaders", component: RateYourLeaders },
  { path: "/employees/rate-your-leaders/begin/spanish", component: RateYourLeadersSpanish },
  { path: "/employees/rate-your-leaders/begin/english", component: RateYourLeadersEnglish },
  { path: "/employees/survey/response-summary", component: ResponseSummary },
];

export default employeesRoutes;
