import { Formik } from "formik";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { UserService } from "../../../service/userService";
import { Form, Tabs, Tab, Spinner, Modal, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { BulkEmployeesUpload } from "./BulkEmployeesUpload";

const CreateBulkUpload = () => {
  const [showBulkEmployeeModal, setShowBulkEmployeeModal] = useState(false);
  const [employeesUploaded, setEmployeesUploaded] = useState(null);

  const history = useHistory();

  const handleModalClose = () => {
    setShowBulkEmployeeModal(false);
    history.push("/employee/add");
  };

  const defaultSorted = [
    {
      dataField: "employeeNumber",
      order: "desc",
    },
  ];

  const uploadedEmployeesColumn = [
    {
      dataField: "employeeNumber",
      text: "Employee Id",
      sort: false,
    },
    {
      dataField: "email",
      text: "Email Address",
      sort: true,
    },
    {
      dataField: "firstName",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "lastName",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
  ];

  return (
    <div>
      <div className="page-header">
        <div>
          <h3 className="page-title"> Bulk Employee Upload </h3>
        </div>

        <div>
          <Link to={"/employees/add"}>
            <button className="btn btn-primary">
              {" "}
              Create Single Employee{" "}
            </button>
          </Link>
        </div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/employees/list"}> Employees</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Create Employee
            </li>
          </ol>
        </nav>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={{
                  bulk_upload_attachement: [],
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);

                  const data = new FormData();

                  if (
                    values.bulk_upload_attachement &&
                    values.bulk_upload_attachement.length > 0
                  ) {
                    values.bulk_upload_attachement.map((file) => {
                      return data.append(
                        "bulk_upload_attachement[]",
                        file,
                        file.name
                      );
                    });

                    UserService.bulkEmployeeUpload(data)
                      .then((response) => {
                        setSubmitting(false);
                        console.log(response.data.data);
                        setEmployeesUploaded(response.data.data);
                        setShowBulkEmployeeModal(true);
                      })
                      .catch((error) => {
                        console.log(error);
                        setSubmitting(false);
                        Swal.fire({
                          title: "Error",
                          text: error.response.data.message,
                          icon: "error",
                          confirmButtonColor: "#000000",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Ok",
                          allowOutsideClick: false,
                        }).then((result) => {
                          if (result.isConfirmed) {
                            console.log("Error");
                          }
                        });
                      });
                  } else {
                    setSubmitting(false);
                    Swal.fire({
                      title: "Check all inputs",
                      icon: "error",
                      html: "Kindly upload all the required file",
                      text: "",
                      confirmButtonColor: "#000000",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Ok",
                      allowOutsideClick: false,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        console.log("Labor info works");
                      }
                    });
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                }) => (
                  <form
                    className="closing_report_form mt-4"
                    id="closing_report_form"
                    onSubmit={handleSubmit}
                  >
                    <Form.Group>
                      <p> Upload Employees </p>
                      <BulkEmployeesUpload setFieldValue={setFieldValue} />
                    </Form.Group>

                    <div>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-primary mr-2"
                      >
                        Submit &nbsp;&nbsp;
                        {isSubmitting ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {showBulkEmployeeModal && (
          <Modal
            size="lg"
            show={showBulkEmployeeModal}
            onHide={() => {
              handleModalClose();
            }}
            aria-labelledby="example-modal-sizes-title-md"
          >
            <div className="">
              <Modal.Header closeButton>
                <Modal.Title>Employees Uploaded </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">
                    {employeesUploaded && (
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title">
                                {" "}
                                Employee
                                {employeesUploaded &&
                                employeesUploaded.length > 1
                                  ? "s"
                                  : ""}
                                Uploaded
                              </h4>
                              <div className="row">
                                <p>
                                  {" "}
                                  The following employees has been uploaded to
                                  the portal. Please note that a mail containing
                                  valid credentials has also been sent to their
                                  respective inboxes.
                                </p>
                                <div className="col-12">
                                  <ToolkitProvider
                                    keyField="employeeNumber"
                                    bootstrap4
                                    data={employeesUploaded}
                                    columns={uploadedEmployeesColumn}
                                    search
                                  >
                                    {(props) => (
                                      <div>
                                        <BootstrapTable
                                          defaultSorted={defaultSorted}
                                          pagination={paginationFactory()}
                                          {...props.baseProps}
                                          wrapperClasses="table-responsive"
                                        />
                                      </div>
                                    )}
                                  </ToolkitProvider>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer
                className="flexx-wrap"
                style={{ paddingTop: "0px" }}
              >
                {" "}
                <Button
                  type="submit"
                  className="btn btn-block btn-primary font-weight-medium auth-form-btn"
                  onClick={() => handleModalClose()}
                >
                  Proceed &nbsp;&nbsp;
                  {/* {isSubmitting ? (
                    <span className="fa fa-spin fa-spinner"></span>
                  ) : (
                    ""
                  )} */}
                </Button>
                <Button variant="light m-2" onClick={() => handleModalClose()}>
                  Cancel
                </Button>
              </Modal.Footer>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default CreateBulkUpload;
