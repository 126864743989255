import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import AppSpinner from "../../../components/Spinner";
import { UserService } from "../../../service/userService";

const DashboardEntries = () => {
  const [submittedList, setSubmittedList] = useState(null);
  const [acknowledged, setAcknowledged] = useState(null);
  const [pending, setPending] = useState(null);
  const [hired, setHired] = useState(null);
  const [notHired, setNotHired] = useState(null);

  useEffect(() => {
    UserService.getApplicants()
      .then((response) => {
        setSubmittedList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    UserService.getAcknowledgedApplicants()
      .then((response) => {
        setAcknowledged(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    UserService.getPendingApplicants()
      .then((response) => {
        setPending(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    UserService.getNotHiredApplicants()
      .then((response) => {
        setNotHired(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    UserService.getHiredApplicants()
      .then((response) => {
        setHired(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div className="card card-statistics">
            <div className="card-body">
              <div className="clearfix">
                <div className="float-left">
                  <i className="mdi mdi-account-multiple-plus text-danger icon-lg"></i>
                </div>
                <div className="float-right">
                  <p className="mb-0 text-right text-dark">Total Submitted</p>
                  <div className="fluid-container">
                    <h3 className="font-weight-medium text-right mb-0 text-dark">
                      {submittedList ? (
                        <NumberFormat
                          value={submittedList.length}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      ) : (
                        <AppSpinner size={20} />
                      )}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div
            className="card card-statistics"
            style={{ boxShadow: "rgb(0 0 0 / 5%) 0px 5px 15px" }}
          >
            <div className="card-body">
              <div className="clearfix">
                <div className="float-left">
                  <i className="mdi mdi-account-search text-warning icon-lg"></i>
                </div>
                <div className="float-right">
                  <p className="mb-0 text-right text-dark">
                    Total Acknowledged
                  </p>
                  <div className="fluid-container">
                    <h3 className="font-weight-medium text-right mb-0 text-dark">
                      {acknowledged ? (
                        <NumberFormat
                          value={acknowledged.length}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      ) : (
                        <AppSpinner size={20} />
                      )}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div className="card card-statistics">
            <div className="card-body">
              <div className="clearfix">
                <div className="float-left">
                  <i className="mdi mdi-account-circle text-success icon-lg"></i>
                </div>
                <div className="float-right">
                  <p className="mb-0 text-right text-dark">
                    {" "}
                    Pending Applicants{" "}
                  </p>
                  <div className="fluid-container">
                    <h3 className="font-weight-medium text-right mb-0 text-dark">
                      {pending ? (
                        <NumberFormat
                          value={pending.length}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      ) : (
                        <AppSpinner size={20} />
                      )}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div className="card card-statistics">
            <div className="card-body">
              <div className="clearfix">
                <div className="float-left">
                  <i className="mdi mdi-account-check text-info icon-lg"></i>
                </div>
                <div className="float-right">
                  <p className="mb-0 text-right text-dark"> Total Hired </p>
                  <div className="fluid-container">
                    <h3 className="font-weight-medium text-right mb-0 text-dark">
                      {hired ? (
                        <NumberFormat
                          value={hired.length}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      ) : (
                        <AppSpinner size={20} />
                      )}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div className="card card-statistics">
            <div className="card-body">
              <div className="clearfix">
                <div className="float-left">
                  <i className="mdi mdi-account-minus text-danger icon-lg"></i>
                </div>
                <div className="float-right">
                  <p className="mb-0 text-right text-dark"> Total Not Hired </p>
                  <div className="fluid-container">
                    <h3 className="font-weight-medium text-right mb-0 text-dark">
                      {notHired ? (
                        <NumberFormat
                          value={notHired.length}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      ) : (
                        <AppSpinner size={20} />
                      )}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div className="card card-statistics">
            <div className="card-body">
              <div className="clearfix">
                <div className="float-left">
                  <i className="mdi mdi-receipt text-warning icon-lg"></i>
                </div>
                <div className="float-right">
                  <p className="mb-0 text-right text-dark"> Promos Amount</p>
                  <div className="fluid-container">
                    <h3 className="font-weight-medium text-right mb-0 text-dark">
                      <NumberFormat
                        value={RoundNumberUpAccurately(salesReport.promos, 2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </h3>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default DashboardEntries;
