import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import { BonusPayoutService } from "../../../service/BonusPayoutService";
import moment from "moment";

const ReviewsBonusPayout = () => {
  const [reviewsStat, setReviewsStat] = useState(null);
  const [quarter, setQuarter] = useState(null);

  const year = moment().year();
  const activeQuarter = moment().quarter();

  useEffect(() => {
    let data = {
      year: year,
      quarter: activeQuarter,
    };

    setQuarter("Q" + data.quarter);

    BonusPayoutService.getReviewsStatsFromDB(data)
      .then((response) => {
        console.log(response.data.data);
        setReviewsStat(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    // use the default value to retrieve stats
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <p className="card-description">
                Please select your preferred search term and hit the{" "}
                <b>Submit</b> button to proceed.
              </p>
              <Formik
                initialValues={{
                  year: year,
                  quarter: activeQuarter,
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  setQuarter("Q" + values.quarter);
                  BonusPayoutService.getReviewsStatsFromDB(values)
                    .then((response) => {
                      console.log(response.data.data, "From selection");
                      setReviewsStat(response.data.data);
                      setSubmitting(false);
                    })
                    .catch((error) => {
                      console.log(error);
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form className="form-inline" onSubmit={handleSubmit}>
                    <div>
                      <Form.Control
                        type="text"
                        as="select"
                        className="form-control"
                        id="year"
                        name="year"
                        defaultValue={values.year ? values.year : "2021"}
                        onChange={handleChange}
                        disabled={isSubmitting}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        style={{ width: "250px", margin: "0px 10px" }}
                      >
                        <option value="DEFAULT" disabled>
                          Select Year
                        </option>
                        <option value="2021"> 2021 </option>
                        <option value="2022"> 2022 </option>
                        <option value="2023"> 2023 </option>
                        <option value="2024"> 2024 </option>
                      </Form.Control>
                    </div>

                    <div>
                      <Form.Control
                        type="text"
                        as="select"
                        className="form-control"
                        id="quarter"
                        name="quarter"
                        defaultValue={values.quarter ? values.quarter : "Q1"}
                        onChange={handleChange}
                        disabled={isSubmitting}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        style={{ width: "250px", margin: "0px 10px" }}
                      >
                        <option value="DEFAULT" disabled>
                          Select Quarter
                        </option>
                        <option value="1"> Q1 </option>
                        <option value="2"> Q2 </option>
                        <option value="3"> Q3 </option>
                        <option value="4"> Q4 </option>
                      </Form.Control>
                    </div>

                    <button type="submit" className="btn btn-primary mb-2">
                      Submit &nbsp;&nbsp;
                      {isSubmitting ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        ""
                      )}
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <div>
        {reviewsStat && (
          <div className="row">
            {reviewsStat.map((stat, index) => (
              <div
                className="col-md-3"
                key={stat.location_id}
                style={{
                  border: "1px dotted grey",
                  padding: "20px",
                }}
              >
                <h4 className="text-center"> {stat.store_name}</h4>
                <table className="table table-bordered">
                  <thead>
                    <tr
                      style={{
                        background: "grey",
                        color: "white",
                        paddingLeft: "2px",
                      }}
                    >
                      <th scope="col">Sales</th>
                      <th scope="col">{quarter && quarter}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Score</th>
                      <td
                        style={
                          stat.score &&
                          stat.bonus_config &&
                          parseInt(stat.score) >=
                            parseInt(stat.bonus_config.review_score)
                            ? {
                                border: "2px solid green",
                              }
                            : {
                                border: "2px solid red",
                              }
                        }
                      >
                        {stat.score}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Average Rating</th>
                      <td>{stat.average_rating}</td>
                    </tr>
                    <tr>
                      <th scope="row">Total Reviews</th>
                      <td>{stat.total_reviews}</td>
                    </tr>
                    <tr>
                      <th scope="row"> Response Rate </th>
                      <td>{stat.response_rate} %</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewsBonusPayout;
