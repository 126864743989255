import React from "react";
import { Form } from "react-bootstrap";
import { RoundNumberUp } from "../../Utils/RoundNumberUp";

const TogoPreview = ({ paymentDetails }) => {
  return (
    <div className="table-responsive col-md-12" style={{ marginTop: "30px" }}>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th style={{ width: "5%" }}> Emp Number </th>
            <th style={{ width: "25%" }}>Employee Name</th>
            <th style={{ width: "25%" }}> Declared Tips </th>
            <th style={{ width: "25%" }}> Tips </th>
          </tr>
        </thead>
        <tbody>
          {paymentDetails &&
            paymentDetails.togoPaymentDetails.map((tip, index) => (
              <tr key={tip.employee_number}>
                <td>
                  <Form.Group>
                    <Form.Control
                      name={`employee_number`}
                      className="form-control"
                      placeholder="Employee Number"
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      disabled={"disabled"}
                      defaultValue={tip.employee_number}
                      style={{
                        border: "none",
                        fontWeight: "bold",
                      }}
                    />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group>
                    <Form.Control
                      name={`employee_name`}
                      className="form-control"
                      placeholder="Employee Name"
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      disabled={"disabled"}
                      defaultValue={tip.employee_name}
                      style={{
                        border: "none",
                        fontWeight: "bold",
                      }}
                    />
                  </Form.Group>
                </td>

                <td>
                  <Form.Group>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">$</span>
                      </div>

                      <Form.Control
                        name={`declared_tips`}
                        className="form-control"
                        type={"text"}
                        placeholder="Tips"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        disabled={"disabled"}
                        defaultValue={RoundNumberUp(tip.declared_tips)}
                      />
                    </div>
                  </Form.Group>
                </td>

                <td>
                  <Form.Group>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">$</span>
                      </div>

                      <Form.Control
                        name={`tipshare_received`}
                        className="form-control"
                        type={"text"}
                        placeholder="Tips"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        disabled={"disabled"}
                        defaultValue={RoundNumberUp(tip.tipshare_received)}
                      />
                    </div>
                  </Form.Group>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TogoPreview;
