import React from "react";
import NumberFormat from "react-number-format";

const roundNumberUp = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2);
};

const CompsTable = ({ comps }) => {
  if (!Array.isArray(comps) || comps.length === 0) {
    return <p>No comps data available</p>;
  }

  return (
    <div className="table-responsive" style={{ marginTop: "60px" }}>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="table-wider">Comps</th>
            <th>Quantity</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {comps.map((comp, index) => (
            <tr key={index}>
              <td>{comp.comps_name || "N/A"}</td>
              <td className="amountRow">
                <NumberFormat
                  value={comp.quantity || 0}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </td>
              <td className="amountRow">
                <NumberFormat
                  value={roundNumberUp(comp.amount || 0)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CompsTable;
