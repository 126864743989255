import cogoToast from "cogo-toast";
import { Formik } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { SystemConfigService } from "../../../../../../service/configService";
import Select from "react-select";

const PermanentAccess = ({
  employee,
  handleStorePermissionClose,
  storeOptions,
  defaultPermanentStores,
}) => {
  return (
    <div>
      <Formik
        initialValues={{
          user_id: employee ? employee.id : "",
          user_slug: employee ? employee.slug : "",
          access_type: "permanent",
          store_selected: defaultPermanentStores,
          start_date: "",
          end_date: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          console.log(values);

          if (values.store_selected.length > 0) {
            SystemConfigService.selectStoreForManager(values)
              .then((response) => {
                setSubmitting(false);
                cogoToast.success("Store saved successfully");
                window.location.reload();
                console.log(response.data.data);
              })
              .catch((error) => {
                console.log(error);
                setSubmitting(false);
              });
          } else {
            setSubmitting(false);
            Swal.fire({
              title: "Error!",
              text: `Please select a store`,
              icon: "error",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                Swal.close();
              }
            });
          }
        }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form name="form" onSubmit={handleSubmit}>
            <Form.Group>
              <label htmlFor="store">
                Select Stores for &nbsp;
                <b>
                  {employee.first_name} {employee.last_name}
                </b>
              </label>

              <Select
                isMulti={true}
                onChange={(opt, e) => {
                  console.log(opt);
                  setFieldValue("store_selected", opt);
                }}
                name="store_selected"
                options={storeOptions}
                defaultValue={defaultPermanentStores}
              />
            </Form.Group>
            <button
              type="submit"
              className="btn btn-primary font-weight-medium auth-form-btn"
            >
              Proceed &nbsp;&nbsp;
              {isSubmitting ? (
                // <Spinner animation="border" size="sm" />
                <span className="fa fa-spin fa-spinner"></span>
              ) : (
                ""
              )}
            </button>
            <button
              className="btn"
              type="button"
              variant="light m-2"
              onClick={handleStorePermissionClose}
            >
              Cancel
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PermanentAccess;
