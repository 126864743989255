import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import NumberFormat from "react-number-format";
import "react-datepicker/dist/react-datepicker.css";
import cogoToast from "cogo-toast";
import { useHistory, withRouter } from "react-router";
import { Link } from "react-router-dom";
import ShowCriteria from "../components/ShowCriteria";
import { RoundNumberUp } from "../../../components/Utils/RoundNumberUp";
import CriteriaMark from "../components/CriteriaMark";
import getStoreName from "../../../components/StoreNameUsingId";
import { Utility } from "../../../Helpers/utils";

class BonusPayoutHistoryPDFDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      store: null,
      year: null,
      quarter: null,
      breakdownData: null,
    };
  }

  componentDidMount() {
    if (
      this.props.store !== "undefined" &&
      this.props.year !== "undefined" &&
      this.props.quarter !== "undefined" &&
      this.props.breakdown !== "undefined"
    ) {
      this.setState({
        store: this.props.store,
        year: this.props.year,
        quarter: this.props.quarter,
        breakdownData: this.props.breakdown,
      });
    } else {
      cogoToast.error("Unable to preview data");
      setTimeout(() => {
        window.location.href =
          "/laparrila/restaurant-manager/bonus/view-bonus-report";
      }, [2000]);
    }
  }
  roundNumberUp = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  render() {
    const { store, year, quarter, breakdownData } = this.state;
    return (
      <section id="downloadBonusPreview">
        <div>
          <div className="row">
            <div
              className="col-sm-12 grid-margin"
              style={{ marginBottom: "0px" }}
            >
              <h4 className="card-title">Bonus Payout Report</h4>
              <h4>
                {store ? getStoreName(store) : ""} &nbsp; Bonus Payout -{" "}
                {quarter ? `Q${quarter}` : ""} &nbsp; {year ? `${year}` : ""}
              </h4>
              <div>
                {/* <Form.Group className="row">
                  <label htmlFor="store" className="col-sm-4 col-form-label">
                    Parrilla #
                  </label>
                  <div className="col-sm-8">
                    <Form.Control
                      type="text"
                      className="form-control"
                      id="store"
                      name="store"
                      value={store ? store : ""}
                      placeholder="Location"
                      disabled={"disabled"}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                    />
                  </div>
                </Form.Group>

                <Form.Group className="row">
                  <label htmlFor="store" className="col-sm-4 col-form-label">
                    Year
                  </label>
                  <div className="col-sm-8">
                    <Form.Control
                      type="text"
                      className="form-control"
                      id="store"
                      name="store"
                      value={year ? year : ""}
                      placeholder="Location"
                      disabled={"disabled"}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                    />
                  </div>
                </Form.Group>
                <Form.Group className="row">
                  <label htmlFor="store" className="col-sm-4 col-form-label">
                    Quarter
                  </label>
                  <div className="col-sm-8">
                    <Form.Control
                      type="text"
                      className="form-control"
                      id="store"
                      name="store"
                      value={quarter ? quarter : ""}
                      placeholder="Location"
                      disabled={"disabled"}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                    />
                  </div>
                </Form.Group> */}
              </div>
            </div>

            {/* <div className="col-sm-6">
              <div style={{ paddingTop: "60px" }}>
                <img
                  src={require("../../../../assets/images/closing_report_logo.png")}
                  alt="logo"
                  style={{
                    display: "block",
                    // marginRight: "auto",
                    margin: "0 auto",
                  }}
                />
              </div>
            </div> */}
          </div>

          <div className="row">
            {breakdownData &&
              breakdownData.map((data, index) => (
                <div
                  className="col-md-9 col-md-offset-1"
                  key={index}
                  style={{
                    marginTop: "30px",
                    padding: "20px",
                    border: "2px dotted",
                  }}
                >
                  <div>
                    <table className="table">
                      <tbody>
                        <tr>
                          <th scope="col">Manager's Name</th>
                          <td>{data.managerName}</td>
                        </tr>
                        <tr>
                          <th scope="col">Salary</th>
                          <td>
                            <NumberFormat
                              value={RoundNumberUp(data.salary)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$ "}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Annual Bonus: </th>
                          <td>
                            <NumberFormat
                              value={RoundNumberUp(data.annualBonus)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$ "}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Quarter | Base: </th>
                          <td>
                            <NumberFormat
                              value={RoundNumberUp(data.quarterBonus)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$ "}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Manager Type : </th>
                          <td>{data.manager_type.toUpperCase()}</td>
                        </tr>

                        <tr
                          style={{
                            background: "black",
                            color: "white",
                          }}
                        >
                          <th scope="row">
                            {`Q${quarter}`} - Total Earned Bonus :{" "}
                          </th>
                          <td>
                            <NumberFormat
                              value={RoundNumberUp(
                                data.totalEarnedForTheQuarter
                              )}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$ "}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    <table className="table table-bordered">
                      <thead>
                        <tr
                          style={{
                            background: "#7e2828",
                            color: "white",
                          }}
                        >
                          <th scope="col">KPIs</th>
                          <th scope="col">%</th>
                          <th scope="col">{`Q${quarter}`}</th>
                          <th scope="col">$</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.KPI &&
                          data.KPI.map((kpi, index) => (
                            <tr key={index}>
                              <td>{kpi.name}</td>
                              <td>{kpi.percentage}</td>
                              <td>{ShowCriteria(kpi.condition)}</td>
                              <td>
                                {" "}
                                <NumberFormat
                                  value={RoundNumberUp(kpi.amountEarned)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$ "}
                                />
                              </td>
                              <td>{CriteriaMark(kpi.condition)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>

                    <table className="table table-bordered mt-3">
                      <tbody>
                        <tr
                          style={{
                            background: "black",
                            color: "white",
                          }}
                        >
                          <td> Total Bonus</td>
                          <td className="amountRow">
                            <NumberFormat
                              value={RoundNumberUp(
                                data.totalEarnedForTheQuarter
                              )}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$ "}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
          </div>

          <div
            style={{
              marginTop: "40px",
            }}
          >
            <p> Reviewed By : ____________</p>
            <p> Authorized By : ____________</p>
          </div>
        </div>
      </section>
    );
  }
}

const ViewBonusReportHistories = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // onAfterPrint: () => {
    //   alert("This has been printed");
    // },
  });

  const history = useHistory();
  const user = Utility.getUser();

  return (
    <div>
      <section className="content">
        <div>
          <div className="text-center">
            <Link to={"/bonus/view-bonus-report"}>
              <button className="btn btn-primary"> View All Reports</button>
            </Link>
          </div>
        </div>
        <div className="page">
          <BonusPayoutHistoryPDFDownload
            ref={componentRef}
            store={
              history.location.state
                ? history.location.state.store
                : "undefined"
            }
            year={
              history.location.state ? history.location.state.year : "undefined"
            }
            quarter={
              history.location.state
                ? history.location.state.quarter
                : "undefined"
            }
            breakdown={
              history.location.state
                ? history.location.state.breakdown_data
                : "undefined"
            }
          />

          {(user && user.role === "super admin") ||
          user.role === "district manager" ? (
            <button onClick={handlePrint} className="btn btn-primary mt-5">
              Print Bonus Report&nbsp;&nbsp;{" "}
              <span className="mdi mdi-printer"></span>
            </button>
          ) : (
            ""
          )}
        </div>
      </section>
    </div>
  );
};

export default withRouter(ViewBonusReportHistories);
