import React from "react";

const Reviews = () => {
  return (
    <div className="profile-feed">
      <div className="mt-3 mb-3">
        <h4> Reviews </h4>
      </div>

      <div className="mt-3 mb-3">
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div
              className="card"
              style={{
                border: "none",
              }}
            >
              <div className="card-body">Feature to be implemented soon</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
