import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import cogoToast from "cogo-toast";
import { confirmAlert } from "react-confirm-alert";
import { ConfirmWrapper, PrimarySlickButton, SlickButton } from "../../components/AlertUtility";
import { getRoleBasedItems } from "./RoleBasedItems";
import styled from "styled-components";
import logo from "../../assets/images/la-parrila-logo.png";
import miniLogo from "../../assets/images/logo-mini.svg";
import SidebarHeader from "./SidebarHeader";
import { useAuth } from "../../contexts/AuthContext";

const SidebarWrapper = styled.nav`
  .nav-item {
    margin: 0;
  }
`;

const Sidebar = ({ user }) => {
  const [menuState, setMenuState] = useState({});
  const location = useLocation();
  const { logout } = useAuth();

  const history = useHistory();

  const roleBasedItems = getRoleBasedItems(user);

  const toggleMenuState = (menu) => {
    setMenuState((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
  };

  useEffect(() => {
    const activeMenu = roleBasedItems.find((item) => {
      if (item.subItems) {
        return item.subItems.some((subItem) => subItem.path === location.pathname);
      }
      return item.path === location.pathname;
    });

    // Set the active menu state to true and close all others
    if (activeMenu) {
      setMenuState({
        [activeMenu.state]: true,
      });
    } else {
      setMenuState({});
    }
  }, [location]);

  const handleLogout = () => {
    const toastoptions = {
      hideAfter: 5,
      position: "top-right",
      heading: "Attention",
    };

    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmWrapper className="sc-fzoLag BNtsP">
          <a className="sidebar-brand brand-logo" href="/laparrila/restaurant-manager/dashboard">
            <img src={require("../../assets/images/la-parrila-logo.png")} alt="logo" className="" />
          </a>
          <header>
            <h6>Are you sure you want to log out of the portal?</h6>
          </header>
          <div className="alert-footer">
            <PrimarySlickButton
              onClick={() => {
                logout();
                history.push("/home/login");
                onClose();
                cogoToast.success("User logged out successfully", toastoptions);
              }}
              className="sc-fznZeY sc-fzqBZW eNQuho"
            >
              <span>Yes, log out!</span>
            </PrimarySlickButton>
            <SlickButton onClick={onClose} type="reset" className="sc-fznZeY gJlwEu cancel">
              <span>Stay signed in</span>
            </SlickButton>
          </div>
        </ConfirmWrapper>
      ),
    });
  };

  const renderSubMenu = (menuStateKey, menuTitle, icon, subItems, index) => (
    <li className="nav-item" key={index}>
      <div
        className={menuState[menuStateKey] ? "nav-link menu-expanded" : "nav-link"}
        onClick={() => toggleMenuState(menuStateKey)}
        data-toggle="collapse"
      >
        <i className={`mdi ${icon} menu-icon`}></i>
        <span className="menu-title">{menuTitle}</span>
        <i className="menu-arrow"></i>
      </div>
      <Collapse in={menuState[menuStateKey]}>
        <ul className="nav flex-column sub-menu">
          {subItems.map((item, idx) => (
            <li key={idx} className="nav-item">
              <Link
                className={location.pathname === item.path ? "nav-link active" : "nav-link"}
                to={item.path}
              >
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      </Collapse>
    </li>
  );

  return (
    <SidebarWrapper className="sidebar sidebar-offcanvas" id="sidebar">
      <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
        <Link className="sidebar-brand brand-logo" to="/dashboard">
          <img src={logo} alt="logo" />
        </Link>
        <Link className="sidebar-brand brand-logo-mini pl-4 pt-3" to="/dashboard">
          <img src={miniLogo} alt="logo-mini" />
        </Link>
      </div>

      <ul className="nav">
        <SidebarHeader user={user} />

        {roleBasedItems.map((item, index) =>
          item.subItems ? (
            renderSubMenu(item.state, item.title, item.icon, item.subItems, index)
          ) : (
            <li key={index} className="nav-item">
              <Link
                className={location.pathname === item.path ? "nav-link active" : "nav-link"}
                to={item.path}
              >
                <i className={`mdi ${item.icon} menu-icon`}></i>
                <span className="menu-title">{item.title}</span>
              </Link>
            </li>
          )
        )}
        <li className="nav-item" onClick={handleLogout}>
          <div className="nav-link">
            <i className="mdi mdi-logout-variant menu-icon"></i>
            <span className="menu-title">Sign Out</span>
          </div>
        </li>
      </ul>
    </SidebarWrapper>
  );
};

export default Sidebar;
