import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Form } from "react-bootstrap";
import NumberFormat from "react-number-format";
import "react-datepicker/dist/react-datepicker.css";
import cogoToast from "cogo-toast";
import { useHistory, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { ConvertDateFormat } from "../../components/ConvertDateFormat";
import { SalesService } from "../../service/SalesService";
import { displayPaymentMethod } from "../../components/Utils/DisplayPaymentMethod";
import { Modal, Button } from "react-bootstrap";
import ReportNotes from "./ReportsComments/ReportNotes";
import DepositPDFReader from "./components/DepositPDFReader";
import { PaymentService } from "../../service/PaymentService";

class SalesReportHistoryPDFDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salesReport: null,
      barTenderPayment: null,
      serverPayment: null,
      pendingPayments: null,
      scheduled: null,
      expenses: null,
      cashDepositData: null,
      atoDetails: null,
      doorDashDetails: null,
      expenseTotal: "",
      isLoading: true,
      totalBar: "",
      totalTipShare: "",
      totalBarTip: "",
      totalServerTip: "",
      totalInstantPay: "",
      emergencyExpenses: "",
    };
  }

  componentDidMount() {
    if (this.props.id !== "undefined" && this.props.date !== "undefined") {
      SalesService.fetchSalesReportHistory(this.props.id, this.props.date)
        .then((response) => {
          console.log(response.data.data);
          this.setState({ isLoading: false });
          let serverRes = response.data.data.expensesAmount;
          let expensesTotal = "";
          if (serverRes) {
            expensesTotal =
              parseFloat(serverRes.auto_truck != null ? serverRes.auto_truck : 0) +
              parseFloat(serverRes.cleaning != null ? serverRes.cleaning : 0) +
              parseFloat(serverRes.food_purchases != null ? serverRes.food_purchases : 0) +
              parseFloat(serverRes.office_supplies != null ? serverRes.office_supplies : 0) +
              parseFloat(serverRes.repair != null ? serverRes.repair : 0) +
              parseFloat(serverRes.rest_supplies != null ? serverRes.rest_supplies : 0) +
              parseFloat(serverRes.gift_promotion != null ? serverRes.gift_promotion : 0) +
              parseFloat(serverRes.other != null ? serverRes.other : 0) +
              parseFloat(
                serverRes.emergency_code_expenses != null ? serverRes.emergency_code_expenses : 0
              );
          }

          // total bar
          let barAmount = 0;
          let barTip = 0;
          let scheduledAmount = 0;
          let barTipshare = 0;
          let serverAmount = 0;
          let cateringAmount = 0;
          let atoAmount = 0;
          let doorDashAmount = 0;
          let togoAmount = 0;
          let othersAmount = 0;
          response.data.data.paymentResponse.bartenders.map((tip, index) => {
            barAmount += parseFloat(this.roundNumberUp(tip.amount));
            barTip += parseFloat(this.roundNumberUp(tip.tip));
            barTipshare += parseFloat(this.roundNumberUp(tip.tipshare));
            return tip;
          });

          response.data.data.paymentResponse.servers.map((serve, index) => {
            return (serverAmount += parseFloat(this.roundNumberUp(serve.amount)));
          });

          response.data.data.paymentResponse.scheduled.map((sch, index) => {
            return (scheduledAmount += parseFloat(this.roundNumberUp(sch.amount)));
          });

          response.data.data.paymentResponse.catering.map((cat, index) => {
            return (cateringAmount += parseFloat(this.roundNumberUp(cat.amount)));
          });

          response.data.data.paymentResponse.ato.map((ato, index) => {
            return (atoAmount += parseFloat(this.roundNumberUp(ato.amount)));
          });

          response.data.data.paymentResponse.doorDash.map((door, index) => {
            return (doorDashAmount += parseFloat(this.roundNumberUp(door.amount)));
          });

          response.data.data.paymentResponse.togo.map((togo, index) => {
            return (togoAmount += parseFloat(this.roundNumberUp(togo.amount)));
          });

          response.data.data.paymentResponse.others.map((other, index) => {
            return (othersAmount += parseFloat(this.roundNumberUp(other.amount)));
          });

          let totalInstant =
            parseFloat(this.roundNumberUp(barAmount)) +
            parseFloat(this.roundNumberUp(serverAmount)) +
            parseFloat(this.roundNumberUp(scheduledAmount)) +
            parseFloat(this.roundNumberUp(cateringAmount)) +
            parseFloat(this.roundNumberUp(atoAmount)) +
            parseFloat(this.roundNumberUp(doorDashAmount)) +
            parseFloat(this.roundNumberUp(togoAmount)) +
            parseFloat(this.roundNumberUp(othersAmount));

          this.setState({
            salesReport: response.data.data.salesReport,
            serverPayment: response.data.data.paymentResponse.servers,
            barTenderPayment: response.data.data.paymentResponse.bartenders,
            scheduled: response.data.data.paymentResponse.scheduled,
            cateringPayment: response.data.data.paymentResponse.catering,
            atoPayment: response.data.data.paymentResponse.ato,
            doorDashPayment: response.data.data.paymentResponse.doorDash,
            togoPayment: response.data.data.paymentResponse.togo,
            othersPayment: response.data.data.paymentResponse.others,
            pendingPayments: response.data.data.paymentResponse.pendingPayments,
            cashDepositData: response.data.data.deposits,
            expenseTotal: serverRes ? this.roundNumberUp(expensesTotal) : 0,
            expenses: response.data.data.expensesAmount,
            atoDetails: response.data.data.atoDetails,
            doorDashDetails: response.data.data.doorDashDetails,
            emergencyExpenses: response.data.data.emergencyExpenses,
            totalBar: barAmount,
            totalTipShare: barTipshare,
            totalBarTip: barTip,
            totalServerTip: serverAmount,
            totalAtoTip: atoAmount,
            totalDoorDashTip: doorDashAmount,
            totalTogoTip: togoAmount,
            totalCateringTip: cateringAmount,
            totalOthersTip: othersAmount,
            totalInstantPay: totalInstant,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    } else {
      cogoToast.error("No data to preview here");
      setTimeout(() => {
        window.location.href = "/laparrila/restaurant-manager/financials/view-store-report";
      }, [2000]);
    }
  }
  roundNumberUp = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  render() {
    const {
      salesReport,
      barTenderPayment,
      serverPayment,
      cateringPayment,
      atoPayment,
      doorDashPayment,
      togoPayment,
      othersPayment,
      expenses,
      expenseTotal,
      atoDetails,
      doorDashDetails,
      pendingPayments,
      scheduled,
      cashDepositData,
      isLoading,
      totalBar,
      totalTipShare,
      totalBarTip,
      totalAtoTip,
      totalDoorDashTip,
      totalCateringTip,
      totalTogoTip,
      totalOthersTip,
      totalServerTip,
      totalInstantPay,
      emergencyExpenses,
    } = this.state;
    return (
      <section id="downloadPreview">
        {!isLoading ? (
          <div>
            <div className="row">
              <div className="col-sm-6 grid-margin" style={{ marginBottom: "0px" }}>
                <h4 className="card-title">Closing Report</h4>
                <div>
                  <Form.Group className="row">
                    <label htmlFor="store" className="col-sm-4 col-form-label">
                      Parrilla #
                    </label>
                    <div className="col-sm-8">
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="store"
                        name="store"
                        value={salesReport ? salesReport.location : ""}
                        placeholder="Location"
                        disabled={"disabled"}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="row">
                    <label htmlFor="exampleInputMobile" className="col-sm-4 col-form-label">
                      Date
                    </label>
                    <div className="col-sm-8">
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="report_date"
                        name="report_date"
                        value={salesReport ? ConvertDateFormat(salesReport.report_date) : ""}
                        placeholder="Report Date"
                        disabled={"disabled"}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="row">
                    <label htmlFor="store" className="col-sm-4 col-form-label">
                      Manager Name
                    </label>
                    <div className="col-sm-8">
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="manager_name"
                        name="manager_name"
                        value={salesReport ? salesReport.printed_by : ""}
                        disabled={"disabled"}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>

              <div className="col-sm-6">
                <div style={{ paddingTop: "60px" }}>
                  <img
                    src={require("../../assets/images/closing_report_logo.png")}
                    alt="logo"
                    style={{
                      display: "block",
                      // marginRight: "auto",
                      margin: "0 auto",
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-5">
                <div>
                  <p> Revenue</p>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th style={{ width: "40%" }}> Revenue </th>
                        <th style={{ width: "60%" }}> Amount </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td> Net Sales </td>

                        <td className="amountRow">
                          <NumberFormat
                            value={salesReport ? this.roundNumberUp(salesReport.net_sales) : 0}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td> Taxes ( Sales & Liquor ) </td>

                        <td className="amountRow">
                          <NumberFormat
                            value={salesReport ? this.roundNumberUp(salesReport.taxes) : 0}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td> Tips - Credit Cards </td>

                        <td className="amountRow">
                          <NumberFormat
                            value={
                              salesReport ? this.roundNumberUp(salesReport.tips_credit_card) : 0
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td> Autogratuity </td>

                        <td className="amountRow">
                          <NumberFormat
                            value={salesReport ? this.roundNumberUp(salesReport.auto_gratuity) : 0}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td> Tip Share Collected </td>

                        <td className="amountRow">
                          <NumberFormat
                            value={
                              salesReport ? this.roundNumberUp(salesReport.tip_share_collected) : 0
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td> Gift Card Sold </td>

                        <td className="amountRow">
                          <NumberFormat
                            value={salesReport ? this.roundNumberUp(salesReport.gift_card_sold) : 0}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td> Charities </td>

                        <td className="amountRow">
                          <NumberFormat
                            value={salesReport ? this.roundNumberUp(salesReport.charities) : 0}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td className="totalColumns"> Total Revenues </td>

                        <td className="amountRow totalColumns">
                          <NumberFormat
                            value={salesReport ? this.roundNumberUp(salesReport.total) : 0}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="mt-2">
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td className="totalColumns"> Total Credit and Gift Cards </td>

                        <td className="amountRow">
                          <NumberFormat
                            value={salesReport ? salesReport.total_credit_and_gift_cards : 0}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="mt-5">
                  <p>
                    {" "}
                    Online {doorDashDetails && doorDashDetails.amount > 0 ? "/ Doordash" : ""}{" "}
                    Payments{" "}
                  </p>
                  {atoDetails ? (
                    <div>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th style={{ width: "50%" }}> Payment Method </th>
                            <th style={{ width: "50%" }}> Amount </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{displayPaymentMethod(atoDetails.payment_method)}</td>

                            <td>
                              <NumberFormat
                                value={atoDetails ? this.roundNumberUp(atoDetails.amount) : 0}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div style={{ fontWeight: "bolder" }}>
                      <p>No Payments</p>
                    </div>
                  )}
                </div>

                <div className="mt-5">
                  {doorDashDetails && doorDashDetails.amount > 0 ? (
                    <>
                      <p> Door Dash Payments Details </p>

                      <div>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th style={{ width: "50%" }}> Payment Method </th>
                              <th style={{ width: "50%" }}> Amount </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{displayPaymentMethod(doorDashDetails.payment_method)}</td>

                              <td>
                                <NumberFormat
                                  value={
                                    doorDashDetails
                                      ? this.roundNumberUp(doorDashDetails.amount)
                                      : ""
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>

                <div className="mt-5">
                  <p> Expenses </p>
                  {expenseTotal ? (
                    <div>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th style={{ width: "50%" }}> Expenses </th>
                            <th style={{ width: "50%" }}> Amount </th>
                          </tr>
                        </thead>
                        <tbody>
                          {expenses && expenses.auto_truck && (
                            <tr>
                              <td> Auto and Truck </td>

                              <td>
                                <NumberFormat
                                  value={expenses ? this.roundNumberUp(expenses.auto_truck) : 0}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </td>
                            </tr>
                          )}

                          {expenses && expenses.cleaning && (
                            <tr>
                              <td> Cleaning & Laundry </td>

                              <td>
                                <NumberFormat
                                  value={expenses ? this.roundNumberUp(expenses.cleaning) : 0}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </td>
                            </tr>
                          )}

                          {expenses && expenses.food_purchases && (
                            <tr>
                              <td> Food Purchase </td>

                              <td>
                                <NumberFormat
                                  value={expenses ? this.roundNumberUp(expenses.food_purchases) : 0}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </td>
                            </tr>
                          )}

                          {expenses && expenses.office_supplies && (
                            <tr>
                              <td> Office Supplies </td>

                              <td>
                                <NumberFormat
                                  value={
                                    expenses ? this.roundNumberUp(expenses.office_supplies) : 0
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </td>
                            </tr>
                          )}

                          {expenses && expenses.repair && (
                            <tr>
                              <td> Repair & Maintenance </td>

                              <td>
                                <NumberFormat
                                  value={expenses ? this.roundNumberUp(expenses.repair) : 0}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </td>
                            </tr>
                          )}

                          {expenses && expenses.rest_supplies && (
                            <tr>
                              <td> Rest. Supplies </td>

                              <td>
                                <NumberFormat
                                  value={expenses ? this.roundNumberUp(expenses.rest_supplies) : 0}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </td>
                            </tr>
                          )}

                          {expenses && expenses.gift_promotion && (
                            <tr>
                              <td> Gift Promotion </td>

                              <td>
                                <NumberFormat
                                  value={expenses ? this.roundNumberUp(expenses.gift_promotion) : 0}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </td>
                            </tr>
                          )}

                          {expenses && expenses.other && (
                            <tr>
                              <td> Other </td>

                              <td>
                                <NumberFormat
                                  value={expenses ? this.roundNumberUp(expenses.other) : 0}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </td>
                            </tr>
                          )}

                          {expenses && expenses.emergency_code_expenses && (
                            <tr>
                              <td> Emergency Code Expenses </td>

                              <td>
                                <NumberFormat
                                  value={
                                    expenses
                                      ? this.roundNumberUp(expenses.emergency_code_expenses)
                                      : 0
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </td>
                            </tr>
                          )}

                          {expenses && expenses.total_expenses && (
                            <tr>
                              <td className="totalColumns"> Total Expenses </td>

                              <td className="amountRow totalColumns">
                                <NumberFormat
                                  value={
                                    expenses
                                      ? this.roundNumberUp(
                                          cashDepositData ? cashDepositData.total_expenses : ""
                                        )
                                      : 0
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div style={{ fontWeight: "bolder" }}>
                      <p>No Expenses</p>
                    </div>
                  )}
                </div>

                <div className="mt-5">
                  <p> Emergency Expenses </p>
                  {emergencyExpenses ? (
                    <div>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th style={{ width: "50%", color: "black" }}> Emergency Code </th>
                            {/* <th style={{ width: "50%" }}> Amount </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {emergencyExpenses.map((exp, index) => (
                            <tr key={index}>
                              <td className="totalColumns" style={{ width: "50%", color: "black" }}>
                                {" "}
                                {exp.emergency_code}{" "}
                              </td>

                              {/* <td className="amountRow totalColumns">
                                <NumberFormat
                                  value={
                                    expenses
                                      ? this.roundNumberUp(
                                          cashDepositData
                                            ? cashDepositData.total_expenses
                                            : ""
                                        )
                                      : 0
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div style={{ fontWeight: "bolder" }}>
                      <p>No Emergency Expense(s)</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-sm-5">
                <div>
                  <p> Tips paid to bartenders </p>
                  <table className="table table-bordered" style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th style={{ width: "10%" }}> Number </th>
                        <th style={{ width: "20%" }}> Bartender </th>
                        <th style={{ width: "10%" }}> Declared Tips </th>
                        <th style={{ width: "10%" }}> Tipshare </th>
                        <th style={{ width: "10%" }}> Tips </th>
                        <th style={{ width: "10%" }}> Total </th>
                      </tr>
                    </thead>
                    <tbody>
                      {barTenderPayment &&
                        barTenderPayment.map((tip, index) => (
                          <tr key={tip.payrollEmployeeId}>
                            <td> {tip.payrollEmployeeId} </td>
                            <td>
                              {tip.firstName}
                              {/* {tip.lastName} */}
                            </td>
                            <td>
                              <NumberFormat
                                value={this.roundNumberUp(tip.declaredTips)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={this.roundNumberUp(tip.tipshare)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={this.roundNumberUp(tip.tip)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={this.roundNumberUp(tip.amount)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>

                  <table className="table table-bordered mt-3" style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th style={{ width: "30%" }}> Total Tipshare </th>
                        <th style={{ width: "30%" }}> Total Tips </th>
                        <th style={{ width: "30%" }}> Total For Bartender </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <NumberFormat
                            value={this.roundNumberUp(totalTipShare)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={this.roundNumberUp(totalBarTip)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />{" "}
                        </td>

                        <td>
                          <NumberFormat
                            value={this.roundNumberUp(totalBar)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="mt-5">
                  <p> Tips paid to servers </p>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th style={{ width: "10%" }}> Number </th>
                        <th style={{ width: "50%" }}> Server </th>
                        <th style={{ width: "40%" }}> Tips </th>
                      </tr>
                    </thead>
                    <tbody>
                      {serverPayment &&
                        serverPayment.map((serve, index) => (
                          <tr key={serve.payrollEmployeeId}>
                            <td> {serve.payrollEmployeeId} </td>
                            <td>
                              {serve.firstName} {serve.lastName}
                            </td>

                            <td className="amountRow">
                              <NumberFormat
                                value={this.roundNumberUp(serve.amount)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>

                  <table className="table table-bordered mt-3">
                    <tbody>
                      <tr>
                        <td className="totalColumns"> Total Tips For Server</td>

                        <td className="amountRow">
                          <NumberFormat
                            value={this.roundNumberUp(totalServerTip)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="mt-5">
                  {atoPayment && atoPayment.length > 0 ? (
                    <div>
                      <p> Tips paid For ATO (Online) </p>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}> Number </th>
                            <th style={{ width: "50%" }}> Employee </th>
                            <th style={{ width: "40%" }}> Tips </th>
                          </tr>
                        </thead>
                        <tbody>
                          {atoPayment &&
                            atoPayment.map((ato, index) => (
                              <tr key={ato.payrollEmployeeId}>
                                <td> {ato.payrollEmployeeId} </td>
                                <td>
                                  {ato.firstName} {ato.lastName}
                                </td>

                                <td className="amountRow">
                                  <NumberFormat
                                    value={this.roundNumberUp(ato.amount)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>

                      <table className="table table-bordered mt-3">
                        <tbody>
                          <tr>
                            <td className="totalColumns"> Total Tips For ATO Interface</td>

                            <td className="amountRow">
                              <NumberFormat
                                value={this.roundNumberUp(totalAtoTip)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="mt-5">
                  {doorDashPayment && doorDashPayment.length > 0 ? (
                    <div>
                      <p> Tips paid For Door Dash </p>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}> Number </th>
                            <th style={{ width: "50%" }}> Employee </th>
                            <th style={{ width: "40%" }}> Tips </th>
                          </tr>
                        </thead>
                        <tbody>
                          {doorDashPayment &&
                            doorDashPayment.map((door, index) => (
                              <tr key={door.payrollEmployeeId}>
                                <td> {door.payrollEmployeeId} </td>
                                <td>
                                  {door.firstName} {door.lastName}
                                </td>

                                <td className="amountRow">
                                  <NumberFormat
                                    value={this.roundNumberUp(door.amount)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>

                      <table className="table table-bordered mt-3">
                        <tbody>
                          <tr>
                            <td className="totalColumns"> Total Tips For Door Dash</td>

                            <td className="amountRow">
                              <NumberFormat
                                value={this.roundNumberUp(totalDoorDashTip)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="mt-5">
                  {togoPayment && togoPayment.length > 0 ? (
                    <div>
                      <p> Tips paid For ToGo </p>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}> Number </th>
                            <th style={{ width: "50%" }}> Employee </th>
                            <th style={{ width: "10%" }}> Declared Tips </th>
                            <th style={{ width: "40%" }}> Tips </th>
                          </tr>
                        </thead>
                        <tbody>
                          {togoPayment &&
                            togoPayment.map((togo, index) => (
                              <tr key={togo.payrollEmployeeId}>
                                <td> {togo.payrollEmployeeId} </td>
                                <td>
                                  {togo.firstName} {togo.lastName}
                                </td>

                                <td>
                                  <NumberFormat
                                    value={this.roundNumberUp(togo.declaredTips)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                </td>

                                <td className="amountRow">
                                  <NumberFormat
                                    value={this.roundNumberUp(togo.amount)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>

                      <table className="table table-bordered mt-3">
                        <tbody>
                          <tr>
                            <td className="totalColumns"> Total Tips For Togo</td>

                            <td className="amountRow">
                              <NumberFormat
                                value={this.roundNumberUp(totalTogoTip)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="mt-5">
                  {cateringPayment && cateringPayment.length > 0 ? (
                    <div>
                      <p> Tips paid For Catering </p>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}> Number </th>
                            <th style={{ width: "50%" }}> Employee </th>
                            <th style={{ width: "40%" }}> Tips </th>
                          </tr>
                        </thead>
                        <tbody>
                          {cateringPayment &&
                            cateringPayment.map((cat, index) => (
                              <tr key={cat.payrollEmployeeId}>
                                <td> {cat.payrollEmployeeId} </td>
                                <td>
                                  {cat.firstName} {cat.lastName}
                                </td>

                                <td className="amountRow">
                                  <NumberFormat
                                    value={this.roundNumberUp(cat.amount)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>

                      <table className="table table-bordered mt-3">
                        <tbody>
                          <tr>
                            <td className="totalColumns"> Total Tips For Catering</td>

                            <td className="amountRow">
                              <NumberFormat
                                value={this.roundNumberUp(totalCateringTip)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="mt-5">
                  {othersPayment && othersPayment.length > 0 ? (
                    <div>
                      <p> Tips paid For others (Coach Server) </p>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}> Number </th>
                            <th style={{ width: "50%" }}> Employee </th>
                            <th style={{ width: "40%" }}> Tips </th>
                          </tr>
                        </thead>
                        <tbody>
                          {othersPayment &&
                            othersPayment.map((others, index) => (
                              <tr key={others.payrollEmployeeId}>
                                <td> {others.payrollEmployeeId} </td>
                                <td>
                                  {others.firstName} {others.lastName}
                                </td>

                                <td className="amountRow">
                                  <NumberFormat
                                    value={this.roundNumberUp(others.amount)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>

                      <table className="table table-bordered mt-3">
                        <tbody>
                          <tr>
                            <td className="totalColumns"> Total Tips For Others ( Coach Server)</td>

                            <td className="amountRow">
                              <NumberFormat
                                value={this.roundNumberUp(totalOthersTip)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="mt-5">
                  {scheduled && scheduled.length > 0 ? (
                    <div>
                      <p> Paid scheduled details </p>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}> Number </th>
                            <th style={{ width: "50%" }}> Name </th>
                            <th style={{ width: "40%" }}> Tips </th>
                          </tr>
                        </thead>
                        <tbody>
                          {scheduled &&
                            scheduled.map((pay, index) => (
                              <tr key={pay.payrollEmployeeId}>
                                <td> {pay.payrollEmployeeId} </td>
                                <td>
                                  {" "}
                                  {pay.firstName} {pay.lastName}{" "}
                                </td>

                                <td className="amountRow">
                                  <NumberFormat
                                    value={this.roundNumberUp(pay.amount)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="mt-5">
                  <p> Total Instant Pay </p>
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td className="totalColumns"> Total Instant Pay </td>

                        <td className="amountRow">
                          <NumberFormat
                            value={this.roundNumberUp(totalInstantPay)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="mt-5">
                  {pendingPayments && pendingPayments.length > 0 ? (
                    <div>
                      <p> Failed Instant Pay Transactions </p>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}> Number </th>
                            <th style={{ width: "50%" }}> Name </th>
                            <th style={{ width: "40%" }}> Tips </th>
                          </tr>
                        </thead>
                        <tbody>
                          {pendingPayments &&
                            pendingPayments.map((pay, index) => (
                              <tr key={pay.payrollEmployeeId}>
                                <td> {pay.payrollEmployeeId} </td>
                                <td>
                                  {" "}
                                  {pay.firstName} {pay.lastName}{" "}
                                </td>

                                <td className="amountRow">
                                  <NumberFormat
                                    value={this.roundNumberUp(pay.amount)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                {/* {cashDepositData && ( */}
                <div>
                  <div className="mt-5">
                    <div>
                      <div className="depositForm">
                        <div>
                          <div>
                            <p className="mb-4">Deposit</p>
                          </div>

                          <div>
                            <div className="form-inline" id="form-inline">
                              <div className="col-sm-4">
                                <label htmlFor="aloha_deposit">Aloha Deposit</label>
                                <Form.Group>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">$</span>
                                    </div>
                                    <Form.Control
                                      type="number"
                                      className="form-control"
                                      id="aloha_deposit"
                                      name="aloha_deposit"
                                      value={cashDepositData ? cashDepositData.aloha_deposit : 0}
                                      placeholder="Enter amount"
                                      autoComplete="off"
                                      autoCorrect="off"
                                      autoCapitalize="off"
                                      spellCheck="false"
                                      disabled={"disabled"}
                                    />
                                  </div>
                                </Form.Group>
                              </div>
                              -
                              <div className="col-sm-3">
                                <label className="" htmlFor="total_expenses">
                                  Total Expenses
                                </label>
                                <Form.Group>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">$</span>
                                    </div>
                                    <Form.Control
                                      type="number"
                                      className="form-control"
                                      id="total_expenses"
                                      name="total_expenses"
                                      value={cashDepositData ? cashDepositData.total_expenses : 0}
                                      placeholder="Enter amount"
                                      autoComplete="off"
                                      autoCorrect="off"
                                      autoCapitalize="off"
                                      spellCheck="false"
                                      disabled={"disabled"}
                                      style={{ padding: "0px" }}
                                    />
                                  </div>
                                </Form.Group>
                              </div>
                              =
                              <div className="col-sm-4">
                                <label className="" htmlFor="sales_deposit_calculated">
                                  Total Deposit (Calculated)
                                </label>
                                <Form.Group>
                                  <div className="input-group" id="total_deposit_cal">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">$</span>
                                    </div>
                                    <Form.Control
                                      type="number"
                                      className="form-control"
                                      id="sales_deposit_calculated"
                                      name="sales_deposit_calculated"
                                      value={
                                        cashDepositData
                                          ? cashDepositData.total_deposit_calculated
                                          : 0
                                      }
                                      placeholder="Enter amount"
                                      autoComplete="off"
                                      autoCorrect="off"
                                      autoCapitalize="off"
                                      spellCheck="false"
                                      disabled={"disabled"}
                                    />
                                  </div>
                                </Form.Group>
                              </div>
                            </div>
                          </div>

                          <table className="table table-bordered mt-5">
                            <tbody>
                              <tr>
                                <td style={{ width: "50%" }}>
                                  <div>
                                    <label htmlFor="cash_to_deposit">Cash to be Deposit</label>
                                    <Form.Group>
                                      <div className="input-group">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">$</span>
                                        </div>
                                        <Form.Control
                                          type="number"
                                          className="form-control"
                                          id="cash_to_deposit"
                                          name="cash_to_deposit"
                                          value={
                                            cashDepositData ? cashDepositData.cash_to_deposit : 0
                                          }
                                          placeholder="Enter amount"
                                          step="any"
                                          autoComplete="off"
                                          autoCorrect="off"
                                          autoCapitalize="off"
                                          spellCheck="false"
                                          disabled={"disabled"}
                                        />
                                      </div>
                                    </Form.Group>
                                  </div>
                                </td>

                                <td style={{ width: "50%" }}>
                                  {cashDepositData &&
                                  cashDepositData.overage &&
                                  cashDepositData.shortage ? (
                                    <div>
                                      <label htmlFor="over_or_short">Short or Over</label>
                                      <Form.Group>
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">$</span>
                                          </div>
                                          <Form.Control
                                            type="number"
                                            className="form-control"
                                            id="over_or_short"
                                            name="over_or_short"
                                            value={cashDepositData ? cashDepositData.overage : 0}
                                            placeholder="Enter amount"
                                            autoComplete="off"
                                            autoCorrect="off"
                                            autoCapitalize="off"
                                            spellCheck="false"
                                            disabled={"disabled"}
                                          />
                                        </div>
                                      </Form.Group>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {cashDepositData &&
                                  !cashDepositData.overage &&
                                  cashDepositData.shortage ? (
                                    <div>
                                      <label htmlFor="short">Short</label>
                                      <Form.Group>
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">$</span>
                                          </div>
                                          <Form.Control
                                            type="number"
                                            className="form-control"
                                            id="shprt"
                                            name="short"
                                            value={cashDepositData ? cashDepositData.shortage : 0}
                                            placeholder="Enter amount"
                                            autoComplete="off"
                                            autoCorrect="off"
                                            autoCapitalize="off"
                                            spellCheck="false"
                                            disabled={"disabled"}
                                          />
                                        </div>
                                      </Form.Group>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {cashDepositData &&
                                  !cashDepositData.shortage &&
                                  cashDepositData.overage ? (
                                    <div>
                                      <label htmlFor="over_or_short">Over</label>
                                      <Form.Group>
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">$</span>
                                          </div>
                                          <Form.Control
                                            type="number"
                                            className="form-control"
                                            id="over"
                                            name="over"
                                            value={cashDepositData ? cashDepositData.overage : 0}
                                            placeholder="Enter amount"
                                            autoComplete="off"
                                            autoCorrect="off"
                                            autoCapitalize="off"
                                            spellCheck="false"
                                            disabled={"disabled"}
                                          />
                                        </div>
                                      </Form.Group>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        {/* {cashDepositData.over_or_short && ( */}
                        <div className="mt-3">
                          {cashDepositData && cashDepositData.explanations ? (
                            <div>
                              <p>Reason for difference in deposit amount</p>
                              <Form.Group>
                                <Form.Control
                                  as="textarea"
                                  rows="3"
                                  type="text"
                                  className="form-control"
                                  id="reason_for_short_or_over"
                                  name="reason_for_short_or_over"
                                  value={cashDepositData ? cashDepositData.explanations : ""}
                                  placeholder="Reason"
                                  autoComplete="off"
                                  autoCorrect="off"
                                  autoCapitalize="off"
                                  spellCheck="false"
                                  disabled={"disabled"}
                                />
                              </Form.Group>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                  {/* <table className="table table-bordered mt-5">
                  <tbody>
                    <tr>
                      <td className="totalColumns"> Cash to be deposit </td>

                      <td className="amountRow">
                        <NumberFormat
                          defaultValue={cashDepositData.cash_to_deposit}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table> */}
                </div>
                {/* )} */}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <span className="fa fa-spin fa-spinner fa-4x"></span>
          </div>
        )}
      </section>
    );
  }
}

const ViewReportHistory = () => {
  const [previewMode, setPreviewMode] = useState(false);
  const [mdShow, setMdShow] = useState(false);
  const [depositPDF, setDepositPDF] = useState(null);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // onAfterPrint: () => {
    //   alert("This has been printed");
    // },
  });

  const history = useHistory();

  useEffect(() => {
    if (history.location.state) {
      PaymentService.getDepositSlipForStore(
        history.location.state.store_id,
        history.location.state.report_date
      )
        .then((response) => {
          setDepositPDF(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <div>
      <section className="content">
        <div>
          <div className="text-center">
            <Link to={`/financials/view-report-histories-store/${history.location.state.store_id}`}>
              <button className="btn btn-primary"> Back </button>
            </Link>
            <Link to={"/financials/view-store-report"}>
              <button className="btn btn-primary"> View All Reports</button>
            </Link>

            <button
              className="btn btn-primary"
              onClick={() => {
                setPreviewMode(true);
                setMdShow(true);
              }}
            >
              Add or View Comments
            </button>

            {depositPDF && (
              <button className="btn btn-primary">
                <DepositPDFReader pdf={depositPDF} />
              </button>
            )}
          </div>
        </div>
        <div className="page">
          <SalesReportHistoryPDFDownload
            ref={componentRef}
            id={history.location.state ? history.location.state.store_id : "undefined"}
            date={history.location.state ? history.location.state.report_date : "undefined"}
          />
          <button onClick={handlePrint} className="btn btn-primary mt-5">
            Print &nbsp;&nbsp; <span className="mdi mdi-printer"></span>
          </button>
        </div>
      </section>

      <div>
        <Modal
          size="lg"
          show={mdShow}
          onHide={() => setMdShow(false)}
          aria-labelledby="example-modal-sizes-title-md"
          id="reportNoteModalWrapper"
        >
          <Modal.Header closeButton></Modal.Header>

          <Modal.Body>
            <div>
              <ReportNotes
                store={history.location.state ? history.location.state.store_id : null}
                date={history.location.state ? history.location.state.report_date : null}
              />
            </div>
          </Modal.Body>

          <Modal.Footer className="fleex-wrap">
            {/* <Button
              variant="success"
              className="text-center"
            >
              Print
            </Button> */}
            <Button
              variant="success"
              className="text-center"
              onClick={() => {
                setMdShow(false);
                setPreviewMode(false);
              }}
            >
              Close Preview
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default withRouter(ViewReportHistory);
