import { Formik } from "formik";
import React from "react";
import NumberFormat from "react-number-format";
import { Form } from "react-bootstrap";
import { RoundNumberUp } from "../../Utils/RoundNumberUp";

const BarPaymentCreated = () => {
  return (
    <div>
      <Formik
        initialValues={{
          payment: JSON.parse(localStorage.getItem("barTenderPayment"))
            ? JSON.parse(localStorage.getItem("barTenderPayment"))
            : [],
        }}
        enableReinitialize={true}
      >
        {({ values, error, touched, handleChange, handleSubmit, isSubmitting }) => (
          <form className="tipShareSummary" onSubmit={handleSubmit}>
            <div className="row">
              <div className="table-responsive col-md-12" style={{ marginTop: "80px" }}>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th style={{ width: "5%" }}> Emp Number </th>
                      <th style={{ width: "25%" }}>Employee Name</th>
                      <th style={{ width: "25%" }}> Declared Tips</th>
                      <th style={{ width: "25%" }}> TipShare</th>

                      <th style={{ width: "25%" }}> Tips Owed</th>

                      <th style={{ width: "25%" }}>Instant Pay Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {" "}
                    {values.payment &&
                      values.payment.tipSummary &&
                      values.payment.tipSummary.map((pay, index) => (
                        <tr key={pay.employee_number}>
                          <td>
                            <NumberFormat
                              defaultValue={pay.employee_number}
                              displayType={"text"}
                              thousandSeparator={false}
                            />
                          </td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                name={`employee_name`}
                                className="form-control"
                                placeholder="Employee Name"
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                disabled={"disabled"}
                                value={`${pay.name}`}
                                style={{
                                  border: "none",
                                  fontWeight: "bold",
                                }}
                              />
                            </Form.Group>
                          </td>

                          <td>
                            <NumberFormat
                              defaultValue={RoundNumberUp(pay.declared_tips)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </td>

                          <td>
                            <NumberFormat
                              defaultValue={RoundNumberUp(pay.total_tipshare_received)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </td>

                          <td>
                            <NumberFormat
                              defaultValue={RoundNumberUp(pay.tip_owed)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </td>

                          <td>
                            <NumberFormat
                              defaultValue={RoundNumberUp(pay.instant_pay_total)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row" style={{ position: "relative", top: "108px" }}></div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default BarPaymentCreated;
