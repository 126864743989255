import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import { AuthProvider } from "./contexts/AuthContext";

ReactDOM.render(
  <BrowserRouter basename="/laparrila/restaurant-manager">
    <AuthProvider>
      <App />
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
