import React, { useState } from "react";
import { pdfjs, Document, Page } from "react-pdf/dist/entry.webpack";
import { Modal, Button, Form, Spinner } from "react-bootstrap";

import { Formik } from "formik";
import cogoToast from "cogo-toast";
import { UserService } from "../service/userService";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const BankDocumentReader = ({ url, bank }) => {
  const [numPages, setNumPages] = useState(0);
  const [previewMode, setPreviewMode] = useState(false);
  const [mdShow, setMdShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  const printPDFhandler = () => {
    window.open(url, "PRINT", "height=400,width=600");
  };

  return (
    <div style={{ marginBottom: "10px", marginTop: "10px" }}>
      <div>
        <div className="float-left">
          {/* <a
            href={pdf.url}
            download={pdf.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {pdf.file_name}
          </a> */}

          {bank.file_name}
        </div>

        <div className="float-right">
          {!previewMode && (
            <div>
              <button
                type="button"
                className="btn btn-outline-info btn-icon-text"
                onClick={() => {
                  setPreviewMode(true);
                  setMdShow(true);
                }}
              >
                {" "}
                Preview File{" "}
              </button>
              {/* <button
                type="button"
                className="btn btn-outline-info btn-icon-text"
                onClick={() => {
                  setShowDeleteModal(true);
                }}
              >
                {" "}
                Delete File{" "}
              </button> */}
            </div>
          )}

          {previewMode && (
            <button
              type="button"
              className="btn btn-outline-info btn-icon-text"
              onClick={() => {
                setPreviewMode(false);
                setMdShow(false);
              }}
            >
              {" "}
              Close Preview{" "}
            </button>
          )}
        </div>

        <div className="clearfix"></div>
      </div>

      {showDeleteModal && (
        <div>
          <Formik
            initialValues={{
              password: "",
              user_slug: bank ? bank.user_slug : "",
              user_id: bank ? bank.user_id : "",
              url,
            }}
            onSubmit={(values, { setSubmitting }) => {
              if (values.password) {
                UserService.deleteUploadedDocument(values)
                  .then((response) => {
                    console.log(response);
                    cogoToast.success(
                      "Document deleted successfully",
                      toastoptions
                    );
                    setSubmitting(false);
                  })
                  .catch((error) => {
                    console.log(error);
                    cogoToast.error(error.response.data.message, toastoptions);
                    setSubmitting(false);
                  });
              } else {
                console.log("Here");
                cogoToast.error(
                  "Please enter your password to proceed",
                  toastoptions
                );
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <Modal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                aria-labelledby="example-modal-sizes-title-md"
                style={{ top: "200px" }}
              >
                <form name="form" onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title>Delete Document</Modal.Title>
                  </Modal.Header>

                  <Modal.Body style={{ paddingBottom: "0px" }}>
                    <p>
                      Please enter your password to proceed with this action{" "}
                    </p>

                    <Form.Group className="d-flex search-field">
                      <Form.Control
                        type="password"
                        placeholder="Enter your password"
                        id="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        size="lg"
                        className="h-auto"
                      />
                      {errors.password && touched.password && errors.password}
                    </Form.Group>
                  </Modal.Body>

                  <Modal.Footer
                    className="fleex-wrap"
                    style={{ paddingTop: "0px" }}
                  >
                    {" "}
                    <Button
                      type="submit"
                      className="btn btn-block btn-primary font-weight-medium auth-form-btn"
                    >
                      Proceed &nbsp;&nbsp;
                      {isSubmitting ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        ""
                      )}
                    </Button>
                    <Button
                      variant="light m-2"
                      onClick={() => setShowDeleteModal(false)}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </form>
              </Modal>
            )}
          </Formik>
        </div>
      )}

      {previewMode && (
        <div>
          <Modal
            size="lg"
            show={mdShow}
            onHide={() => setMdShow(false)}
            aria-labelledby="example-modal-sizes-title-md"
          >
            <Modal.Header closeButton>
              {/* <Modal.Title>Modal title</Modal.Title> */}
            </Modal.Header>

            <Modal.Body>
              <Document
                file={url}
                options={{ workerSrc: "/pdf.worker.js" }}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </Modal.Body>

            <Modal.Footer className="fleex-wrap">
              <Button
                variant="success"
                className="text-center"
                onClick={() => {
                  printPDFhandler();
                }}
              >
                Print
              </Button>

              <Button
                variant="success"
                className="text-center"
                onClick={() => {
                  setMdShow(false);
                  setPreviewMode(false);
                }}
              >
                Close Preview
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default BankDocumentReader;
