import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { PaymentService } from "../../service/PaymentService";
import { Link } from "react-router-dom";
import { SystemConfigService } from "../../service/configService";
const { SearchBar } = Search;

const ViewAllInstantAuditsStore = () => {
  let { store_id } = useParams();
  const [store, setStore] = useState(null);
  const [auditedPayment, setAuditedPayment] = useState(null);

  useEffect(() => {
    PaymentService.getAuditedInstantPaymentsStore(store_id)
      .then((response) => {
        // console.log(response.data.data);
        let AuditedPaymentResArray = [];
        // response.data.data.map((res, index) => {
        //   return AuditedPaymentResArray.push({});
        // });
        setAuditedPayment(AuditedPaymentResArray);
      })
      .catch((error) => {
        console.log(error.response.data);
      });

    SystemConfigService.getStore(store_id)
      .then((response) => {
        console.log(response.data.data);
        setStore(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        // cogoToast.error(error.response.data.message, toastoptions);
      });
  }, []);

  const columns = [
    {
      dataField: "payrollEmployeeId",
      text: "Employee Id",
      sort: true,
    },
    {
      dataField: "firstName",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "lastName",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
    },
    {
      dataField: "reportDate",
      text: "Report Date",
      sort: true,
    },
    {
      dataField: "allocationDate",
      text: "Allocation Date",
      sort: true,
    },
    {
      dataField: "message",
      text: "Message",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: () => {
        return <label className="badge badge-success">Completed</label>;
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">
          <span style={{ textTransform: "capitalize" }}> Audited Payments</span>
        </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/financials/instant-audits">
                <button className="btn btn-primary"> Back </button>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <Link to={`/financials/add-new-instant-audits/${store_id}`}>
                <button className="btn btn-primary"> Audit New Payment</button>
              </Link>
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            {auditedPayment && (
              <div className="card-body">
                <h4 className="card-title"> Audited Payments </h4>
                <div className="row">
                  <div className="col-12">
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={auditedPayment}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div className="d-flex align-items-center">
                            <p className="mb-2 mr-2">Search in table:</p>
                            <SearchBar {...props.searchProps} />
                          </div>
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ViewAllInstantAuditsStore);
