import React from "react";
import { Form, Button } from "react-bootstrap";

const AdmonFee = ({ isSubmitting, values, handleChange }) => {
  return (
    <>
      <tr>
        <th></th>
        <td></td>
      </tr>

      <tr>
        <th
          scope="row"
          style={{
            padding: "0px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        ></th>
        <td
          width="10%"
          style={{
            padding: "0px",
            textAlign: "center",
          }}
        >
          {" "}
          Admon Fee
        </td>
        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="admonFeeM1"
            name="admonFeeM1"
            defaultValue={values.admonFeeM1}
            disabled={isSubmitting}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>
        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="admonFeeM1P"
            name="admonFeeM1P"
            defaultValue={values.admonFeeM1P}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>
        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="admonFeeM2"
            name="admonFeeM2"
            defaultValue={values.admonFeeM2}
            disabled={isSubmitting}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="admonFeeM2P"
            name="admonFeeM2P"
            defaultValue={values.admonFeeM2P}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="admonFeeM3"
            name="admonFeeM3"
            defaultValue={values.admonFeeM3}
            disabled={isSubmitting}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="admonFeeM3P"
            name="admonFeeM3P"
            defaultValue={values.admonFeeM3P}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="admonFeeTotal"
            name="admonFeeTotal"
            defaultValue={values.admonFeeTotal}
            disabled={isSubmitting}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={handleChange}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="admonFeeTotalP"
            name="admonFeeTotalP"
            defaultValue={values.admonFeeTotalP}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={handleChange}
          />
        </td>
      </tr>

      <tr>
        <th
          scope="row"
          style={{
            padding: "0px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        ></th>
        <td
          width="10%"
          style={{
            padding: "0px",
            textAlign: "center",
          }}
        >
          {" "}
          Interest
        </td>
        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="interestM1"
            name="interestM1"
            defaultValue={values.interestM1}
            disabled={isSubmitting}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>
        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="interestM1P"
            name="interestM1P"
            defaultValue={values.interestM1P}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>
        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="interestM2"
            name="interestM2"
            defaultValue={values.interestM2}
            disabled={isSubmitting}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="interestM2P"
            name="interestM2P"
            defaultValue={values.interestM2P}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="interestM3"
            name="interestM3"
            defaultValue={values.interestM3}
            disabled={isSubmitting}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="interestM3P"
            name="interestM3P"
            defaultValue={values.interestM3P}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="interestTotal"
            name="interestTotal"
            defaultValue={values.interestTotal}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={handleChange}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="interestTotalP"
            name="interestTotalP"
            defaultValue={values.interestTotalP}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={handleChange}
          />
        </td>
      </tr>
    </>
  );
};

export default AdmonFee;
