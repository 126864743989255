import cogoToast from "cogo-toast";

const toastOptions = {
  hideAfter: 5,
  position: "top-right",
  heading: "Attention",
};

const success = (msg) => cogoToast.success(msg, toastOptions);
const error = (msg) => cogoToast.error(msg, toastOptions);
const warning = (msg) => cogoToast.loading(msg, toastOptions);
const info = (msg) => cogoToast.info(msg, toastOptions);
const loading = (msg) => cogoToast.loading(msg, toastOptions);

const toastMessages = {
  success,
  error,
  warning,
  info,
  loading,
};

export default toastMessages;
