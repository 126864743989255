import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { RoundNumberUp } from "../../../components/Utils/RoundNumberUp";
import TipDistributionForm from "./components/TipDistributionForm";
import PaymentDetailsTable from "./components/PaymentDetailsTable";

const PayATODoorDash = () => {
  const [salesReport, setSalesReport] = useState(null);
  const [atoPaymentReady, setAtoPaymentReady] = useState(false);
  const [doorDashPaymentReady, setDoorDashPaymentReady] = useState(false);
  const [atoPaymentDetails, setAtoPaymentDetails] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [atoTips, setAtoTips] = useState(0);
  const [doorDashTips, setDoorDashTips] = useState(0);
  const [atoConfig, setAtoConfig] = useState(null);
  const [doorDashConfig, setDoorDashConfig] = useState(null);

  useEffect(() => {
    const storedSalesReport = JSON.parse(localStorage.getItem("salesReport"));
    const storedTips = JSON.parse(localStorage.getItem("atoDoorDashTips"));
    const storedAtoDetails = JSON.parse(localStorage.getItem("atoPaymentDetails"));
    const storedDoorDashDetails = JSON.parse(localStorage.getItem("doorDashPaymentDetails"));

    if (storedSalesReport) setSalesReport(storedSalesReport);
    if (storedTips) {
      setAtoTips(storedTips.atoTips);
      setDoorDashTips(storedTips.doorDashTips);
    }
    if (storedAtoDetails?.length > 0) {
      setAtoPaymentDetails(storedAtoDetails);
      setAtoPaymentReady(true);
      setAtoConfig(storedAtoDetails[0].paymentOption);
    }
    if (storedDoorDashDetails?.length > 0) {
      setDoorDashPaymentReady(true);
      setDoorDashConfig(storedDoorDashDetails[0].paymentOption);
    }
  }, []);

  const handleSubmitTips = (updatedDetails) => {
    const totalTips = updatedDetails.reduce(
      (sum, item) => sum + parseFloat(item.tipShareReceived),
      0
    );

    if (parseFloat(RoundNumberUp(totalTips, 2)) === parseFloat(RoundNumberUp(atoTips, 2))) {
      setAtoPaymentDetails(updatedDetails);
      localStorage.setItem("atoPaymentDetails", JSON.stringify(updatedDetails));
      setEditMode(false);
    } else {
      Swal.fire({
        title: "Error",
        text: `Total tips must equal ${RoundNumberUp(atoTips)}`,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  return (
    <div className="content">
      <section>
        <h3>Online Tips / Door Dash</h3>
        <small>Cumulation of tips for both Online Orders and DoorDash</small>
      </section>

      {!atoPaymentReady && (
        <TipDistributionForm
          salesReport={salesReport}
          atoTips={atoTips}
          doorDashTips={doorDashTips}
          atoConfig={atoConfig}
          doorDashConfig={doorDashConfig}
          onSubmitSuccess={(data) => {
            setAtoPaymentReady(true);
            setDoorDashPaymentReady(true);
            setAtoPaymentDetails(data.atoPaymentDetails);
            localStorage.setItem("atoPaymentDetails", JSON.stringify(data.atoPaymentDetails));
            localStorage.setItem(
              "doorDashPaymentDetails",
              JSON.stringify(data.doorDashPaymentDetails)
            );
          }}
        />
      )}

      {atoPaymentReady && atoPaymentDetails && (
        <PaymentDetailsTable
          title="ATO Payment Details"
          tips={atoPaymentDetails}
          editMode={editMode}
          atoTips={atoTips}
          onEdit={() => setEditMode(true)}
          onCancel={() => {
            setEditMode(false);
            setAtoPaymentReady(false);
            setAtoPaymentDetails(null);
            setDoorDashPaymentReady(false);
            localStorage.removeItem("atoPaymentDetails");
          }}
          onSubmit={handleSubmitTips}
        />
      )}
    </div>
  );
};

export default PayATODoorDash;
