import Api from "./Api";

export class InstantTransactionService {
  static async getInstantTransaction(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`transaction/fetch`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
