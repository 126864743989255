import React, { useState, useEffect } from "react";
import TipSummaryTable from "./components/TipSummaryTable";
import OtherEmployeesTable from "./components/OtherEmployeesTable";
import getStoreName from "../../../components/StoreNameUsingId";
import UploadRequiredFiles from "./components/UploadRequiredFiles";

const TipIncomeReportStage = () => {
  const [tipIncomeReady, setTipIncomeReady] = useState(false);
  const [tipIncomeReport, setTipIncomeReport] = useState(null);
  const [salesReport, setSalesReport] = useState(null);
  const [totalShare, setTotalShare] = useState(0);
  const [totalTipsOwed, setTotalTipsOwed] = useState(0);
  const [totalTogoTips, setTotalTogoTips] = useState(0);
  const [otherEmpDetails, setOtherEmpDetails] = useState(null);

  const RemoveItem = (tipRemove, i) => {
    let totalTipOwed = 0;
    let filteredReport = otherEmpDetails.filter(function (tip) {
      return tip !== tipRemove;
    });

    filteredReport.map((empDetails, i) => {
      if (empDetails.tip_owed !== undefined && !isNaN(empDetails.tip_owed)) {
        totalTipOwed += parseFloat(empDetails.tip_owed);
      }
      return empDetails;
    });

    let newTotalShare = totalTipOwed + totalShare;

    setTotalShare(newTotalShare);
    setOtherEmpDetails(filteredReport);
    localStorage.setItem("otherEmpdetails", JSON.stringify(filteredReport));
  };

  const calculateNewTotalTip = (otherEmpDetails) => {
    let totalTipOwed = 0;
    otherEmpDetails.map((empDetails, i) => {
      if (empDetails.tip_owed !== undefined && !isNaN(empDetails.tip_owed)) {
        totalTipOwed += parseFloat(empDetails.tip_owed);
      }
      return empDetails;
    });

    let newTotalShare = totalTipOwed + totalShare;

    setTotalShare(newTotalShare);
    setOtherEmpDetails(otherEmpDetails);
    localStorage.setItem("otherEmpdetails", JSON.stringify(otherEmpDetails));
  };

  useEffect(() => {
    const storedTipIncomeReport = JSON.parse(localStorage.getItem("tipIncomeReport"));
    const storedSalesReport = JSON.parse(localStorage.getItem("salesReport"));
    const storedOtherEmpDetails = JSON.parse(localStorage.getItem("otherEmpdetails"));

    if (storedTipIncomeReport) {
      const incomeTotal = storedTipIncomeReport.bar.reduce((sum, bar) => sum + bar.tip_owed, 0);
      const totalServerShare = storedTipIncomeReport.server.reduce(
        (sum, server) => sum + server.tip_owed,
        0
      );
      const totalTogoTips = storedTipIncomeReport.togoPayments.togo_tips.reduce(
        (sum, togo) => sum + togo.tips,
        0
      );

      setTipIncomeReport(storedTipIncomeReport);
      setTotalTipsOwed(incomeTotal);
      setTotalShare(
        totalServerShare + (storedOtherEmpDetails?.reduce((sum, emp) => sum + emp.tip_owed, 0) || 0)
      );
      setTotalTogoTips(totalTogoTips);
      setTipIncomeReady(true);
    }

    if (storedSalesReport) {
      setSalesReport(storedSalesReport);
    }

    if (storedOtherEmpDetails) {
      setOtherEmpDetails(storedOtherEmpDetails);
    }
  }, []);

  const handleCancel = () => {
    setTipIncomeReady(false);
    setTipIncomeReport(null);
    setOtherEmpDetails(null);

    // Clear localStorage data
    ["tipIncomeReport", "otherEmpdetails", "mergedBreakdown"].forEach((key) =>
      localStorage.removeItem(key)
    );
  };

  return (
    <section className="content">
      <h3>Server / Bar / ToGo / Coach Server</h3>
      <span>
        <small>{salesReport ? `Date: ${salesReport.report_date}` : ""}</small>
      </span>
      <br />
      <span>
        <small>
          {salesReport ? `Store: ${getStoreName(salesReport.owner)} (LP ${salesReport.owner})` : ""}
        </small>
      </span>

      <small className="form-text text-muted">
        To cancel, go back to the previous step and click Cancel
      </small>

      {!tipIncomeReady && (
        <UploadRequiredFiles
          salesReport={salesReport}
          onSuccess={(data) => {
            setTipIncomeReport(data.tipIncomeReport);
            setOtherEmpDetails(data.otherEmpDetails);
            setTipIncomeReady(true);
          }}
        />
      )}

      {tipIncomeReady && (
        <div className="row mt-5">
          <div className="col-md-6">
            <TipSummaryTable title="Server Tips" data={tipIncomeReport.server} total={totalShare} />
            {otherEmpDetails && otherEmpDetails.length > 0 && (
              <OtherEmployeesTable
                title="Other Employees (Coach Server, ToGo, Catering)"
                data={otherEmpDetails ? otherEmpDetails : []}
                removeItem={RemoveItem}
                calculateNewTotalTip={calculateNewTotalTip}
              />
            )}
          </div>
          <div className="col-md-6">
            <TipSummaryTable title="Bar Tips" data={tipIncomeReport.bar} total={totalTipsOwed} />
            <TipSummaryTable
              title="ToGo Tips"
              data={tipIncomeReport.togoPayments.togo_tips}
              total={totalTogoTips}
            />
          </div>

          <div className="mt-4">
            <button onClick={handleCancel} className="btn btn-light">
              Cancel
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default TipIncomeReportStage;
