import React, { useState, useEffect } from "react";
import { UserService } from "../../service/userService";
import { Link, useParams } from "react-router-dom";
import { Tab, Col, Row, Nav, Button, Form, Spinner } from "react-bootstrap";
import { ProfilePicturePond } from "./ProfilePitcurePond";
import Personal from "./components/profile/Personal";
import Company from "./components/profile/company/Company";
// import Contact from "./components/profile/Contact";
import Documents from "./components/profile/Documents/Documents";
import EmergencyContacts from "./components/profile/Contact/EmergencyContacts";
import Notes from "./components/profile/Notes/Notes";
// import Others from "./components/profile/Others";
import Bank from "./components/profile/Bank/Bank";
import Instant from "./components/profile/Instant/Instant";
import EmployeeCases from "./components/profile/Case/Cases";
import History from "./components/profile/History";
import { Formik } from "formik";
import cogoToast from "cogo-toast";
import { confirmAlert } from "react-confirm-alert";
import { Utility } from "../../Helpers/utils";
import Reviews from "./components/profile/Reviews";
import Locations from "./components/profile/Location/Locations";
import AccessLevel from "./components/profile/Access/AccessLevel";

const ViewMyProfile = () => {
  const [employee, setEmployee] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [user, setUser] = useState(null);
  const [selectedTab, setSelectedTab] = useState("personal");

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  const archiveEmployee = (slug) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            UserService.archiveEmployee(slug)
              .then((response) => {
                console.log(response.data);
                // window.location.reload();
              })
              .catch((error) => {
                console.log(error.response.data);
                cogoToast.error(error.response.data.message, toastoptions);
              });
          },
        },
        {
          label: "No",
          // onClick: () => setSubmitted(false),
        },
      ],
      // onClickOutside: setSubmitted(false),
    });
  };

  let { slug } = useParams();

  useEffect(() => {
    const authUser = Utility.getUser();
    setUser(authUser);
    console.log("O WA WA");

    UserService.getEmployeeUsingSlug(slug)
      .then((response) => {
        console.log(response.data.data);
        setEmployee(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data, "HERE");
      });

    const tab = localStorage.getItem("employeeActiveTab");

    console.log(tab);

    if (tab) {
      setSelectedTab(tab);
    }
  }, [slug]);

  return (
    <div>
      {employee && (
        <div>
          <div className="page-header">
            <h3 className="page-title">
              Profile{" "}
              <span>
                <small>
                  <i>Click on the image to upload a new one</i>
                </small>
              </span>
            </h3>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">Employee Management</li>
                <li className="breadcrumb-item active" aria-current="page">
                  Profile
                </li>
              </ol>
            </nav>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 col-lg-12">
                      <div className="border-bottom text-center pb-4">
                        <div style={{ marginTop: "20px" }}>
                          <ProfilePicturePond slug={employee.slug} />
                        </div>
                        <div className="mb-3">
                          <h3>{`${employee.first_name} ${employee.last_name}`}</h3>
                          <div className="d-flex align-items-center justify-content-center">
                            <h5
                              className="mb-0 mr-2 text-muted"
                              style={{ textTransform: "capitalize" }}
                            >
                              {employee.job_status}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-12">
                      <Tab.Container defaultActiveKey={selectedTab}>
                        <Row>
                          <Col xs={12}>
                            <Nav variant="tabs">
                              <div className="mt-4 py-2 border-bottom">
                                <ul className="nav profile-navbar">
                                  <li className="nav-item">
                                    <Nav.Link
                                      eventKey="personal"
                                      className="d-flex align-items-center"
                                    >
                                      <i className="mdi mdi-account-outline mr-1"></i>
                                      Personal
                                    </Nav.Link>
                                  </li>

                                  {user && user.role !== "employee" ? (
                                    <li className="nav-item">
                                      <Nav.Link
                                        eventKey="company"
                                        className="d-flex align-items-center"
                                      >
                                        <i className="mdi mdi-briefcase mr-1"></i>
                                        Position
                                      </Nav.Link>
                                    </li>
                                  ) : (
                                    ""
                                  )}

                                  {user && user.role !== "employee" ? (
                                    <li className="nav-item">
                                      <Nav.Link
                                        eventKey="locations"
                                        className="d-flex align-items-center"
                                      >
                                        <i className="mdi mdi-map-marker-circle mr-1"></i>
                                        Location History
                                      </Nav.Link>
                                    </li>
                                  ) : (
                                    ""
                                  )}

                                  {user && user.role !== "employee" ? (
                                    <li className="nav-item">
                                      <Nav.Link
                                        eventKey="accessLevel"
                                        className="d-flex align-items-center"
                                      >
                                        <i className="mdi mdi-account-key mr-1"></i>
                                        Access Level
                                      </Nav.Link>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                </ul>
                              </div>
                            </Nav>
                          </Col>

                          <Col xs={12} md={12}>
                            <Tab.Content>
                              <Tab.Pane eventKey="personal">
                                <Personal employee={employee} user={user} />
                              </Tab.Pane>

                              <Tab.Pane eventKey="company">
                                <Company employee={employee} user={user} />
                              </Tab.Pane>

                              <Tab.Pane eventKey="locations">
                                <Locations employee={employee} user={user} />
                              </Tab.Pane>

                              <Tab.Pane eventKey="accessLevel">
                                <AccessLevel employee={employee} user={user} />
                              </Tab.Pane>

                              <Tab.Pane eventKey="reviews">
                                <Reviews />
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewMyProfile;
