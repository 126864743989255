import Api from "./Api";

export class EmployeeConfigService {
  static async getGenders() {
    return new Promise((resolve, reject) => {
      return Api.get("settings/gender/view")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getGender(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`settings/gender/view/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async createGender(data) {
    return new Promise((resolve, reject) => {
      return Api.post("settings/gender/create", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async updateGender(data) {
    return new Promise((resolve, reject) => {
      return Api.put(`settings/gender/update/${data.id}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getEthnics() {
    return new Promise((resolve, reject) => {
      return Api.get("settings/ethnics/view")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getEthnic(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`settings/ethnics/view/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async createEthnic(data) {
    return new Promise((resolve, reject) => {
      return Api.post("settings/ethnics/create", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async updateEthnic(data) {
    return new Promise((resolve, reject) => {
      return Api.put(`settings/ethnics/update/${data.id}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getStates() {
    return new Promise((resolve, reject) => {
      return Api.get("settings/state/view")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getState(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`settings/state/view/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async createState(data) {
    return new Promise((resolve, reject) => {
      return Api.post("settings/state/create", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async updateState(data) {
    return new Promise((resolve, reject) => {
      return Api.put(`settings/state/update/${data.id}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getMaritalStatuses() {
    return new Promise((resolve, reject) => {
      return Api.get("settings/marital-status/view")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getMaritalStatus(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`settings/marital-status/view/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async createMaritalStatus(data) {
    return new Promise((resolve, reject) => {
      return Api.post("settings/marital-status/create", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async updateMaritalStatus(data) {
    return new Promise((resolve, reject) => {
      return Api.put(`settings/marital-status/update/${data.id}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
