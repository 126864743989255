import CateringPayment from "../catering/CateringPayment";
import CateringPaymentCode from "../catering/CateringPaymentCode";
import CreateCateringPayment from "../catering/CreateCatering";
import CateringPendingList from "../catering/PendingList";
import CateringPending from "../catering/Pending";
import CateringScheduledList from "../catering/ScheduledFailedList";
import CateringScheduled from "../catering/ScheduledFailedPending";
import ScheduledEvents from "../catering/ScheduledEvents";
import EditCatering from "../catering/EditCatering";
import ConfigureCateringPayment from "../catering/ConfigureCatering";

const cateringRoutes = [
  { path: "/catering/catering-payment", component: CateringPayment },
  { path: "/catering/catering-payment-code/:store_id", component: CateringPaymentCode },
  { path: "/catering/create-catering-payment/:store_id", component: CreateCateringPayment },
  { path: "/catering/pending-list", component: CateringPendingList },
  { path: "/catering/pending/:store_id", component: CateringPending },
  { path: "/catering/scheduled-list", component: CateringScheduledList },
  { path: "/catering/scheduled/:store_id", component: CateringScheduled },
  { path: "/catering/scheduled-events", component: ScheduledEvents },
  { path: "/catering/edit-catering-payment", component: EditCatering },
  { path: "/catering/configure-catering-payment-store", component: ConfigureCateringPayment },
];

export default cateringRoutes;
