import React from "react";
import { Form } from "react-bootstrap";

const BartenderEmergencyList = ({ barEmergencyCodeDetails }) => {
  return (
    <div>
      <div
        className="row"
        style={{
          marginBottom: "20px",
          marginTop: "10px",
        }}
      >
        <div className="col-md-12">
          <div>
            <h4>Emergency Code(s) </h4>
            <span>
              <small id="emailHelp" className="form-text text-muted">
                Emergency Code Details
              </small>
            </span>
          </div>
          <div className="table-responsive" style={{ marginTop: "30px" }}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{ width: "10%" }}> Code </th>
                  {/* <th className="table-wider"> Hours </th> */}
                  <th style={{ width: "5%" }}> Hours </th>
                  <th style={{ width: "10%" }}> Tipshare </th>
                  <th style={{ width: "10%" }}> Tips Owed </th>
                  <th style={{ width: "25%" }}> Employee Name </th>
                  <th style={{ width: "25%" }}> Base Location </th>
                </tr>
              </thead>
              <tbody>
                {barEmergencyCodeDetails.map((code, index) => (
                  <tr
                    key={code.emergency_code}
                    id={"emergency_codes_row_" + index}
                  >
                    <td style={{ paddingLeft: "0px" }}>
                      <Form.Control
                        name={`emergency_code`}
                        className="form-control"
                        placeholder="Emergency Code"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        disabled={"disabled"}
                        value={code.emergency_code}
                        style={{
                          border: "none",
                          paddingLeft: "10px",
                          paddingRight: "0px",
                        }}
                      />
                    </td>

                    <td style={{ paddingLeft: "0px" }}>
                      <Form.Control
                        name={`totalHours`}
                        className="form-control"
                        placeholder="Total Hours"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        disabled={"disabled"}
                        value={code.total_hours}
                        style={{
                          border: "none",
                          paddingLeft: "10px",
                          paddingRight: "0px",
                        }}
                      />
                    </td>

                    <td className="amountRow" style={{ paddingLeft: "0px" }}>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Form.Control
                          name={`tipshare`}
                          className="form-control"
                          placeholder="Tips Owed"
                          autoComplete="off"
                          autoCorrect="off"
                          autoCapitalize="off"
                          spellCheck="false"
                          disabled={"disabled"}
                          value={code.tip_share}
                          style={{
                            border: "none",
                            paddingLeft: "10px",
                            paddingRight: "0px",
                          }}
                        />
                      </div>
                    </td>

                    <td className="amountRow" style={{ paddingLeft: "0px" }}>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Form.Control
                          name={`tipsOwed`}
                          className="form-control"
                          placeholder="Tips Owed"
                          autoComplete="off"
                          autoCorrect="off"
                          autoCapitalize="off"
                          spellCheck="false"
                          disabled={"disabled"}
                          value={code.tips_owed}
                          style={{
                            border: "none",
                            paddingLeft: "10px",
                            paddingRight: "0px",
                          }}
                        />
                      </div>
                    </td>

                    <td style={{ paddingLeft: "5px" }}>
                      <Form.Control
                        name={`emergencyCodes[${index}].employee_name`}
                        className="form-control"
                        placeholder="Employee Name"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        disabled={"disabled"}
                        defaultValue={
                          code.employee_name ? code.employee_name : ""
                        }
                      />
                    </td>

                    <td style={{ paddingLeft: "5px" }}>
                      <Form.Control
                        as="select"
                        name={`emergencyCodes[${index}].base_location`}
                        className="form-control"
                        placeholder="Base location"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        disabled={"disabled"}
                        defaultValue={
                          code.base_location ? code.base_location : "DEFAULT"
                        }
                      >
                        <option value="DEFAULT" disabled>
                          Select One
                        </option>
                        <option value="1">
                          {" "}
                          La Parrilla (Marietta Square){" "}
                        </option>
                        <option value="2"> La Parrilla (Dallas Hwy) </option>
                        <option value="3">
                          {" "}
                          La Parrilla (Flowery Branch){" "}
                        </option>
                        <option value="4"> La Parrilla (Powder Spring) </option>
                        <option value="5"> La Parrilla (Fayette Ville) </option>
                        <option value="6"> La Parrilla (Alpharetta) </option>
                        <option value="7"> La Parrilla (Woodstock) </option>
                        <option value="9"> La Parrilla (Acworth) </option>
                        <option value="10"> La Parrilla (Newman) </option>
                        <option value="11"> La Parrilla (Mcdonough) </option>
                        <option value="12"> La Parrilla (Howel Mill) </option>
                        <option value="13"> La Parrilla (Dothan) </option>
                        <option value="14"> La Parrilla (Norcross) </option>
                        <option value="15"> La Parrilla (Canton) </option>
                        <option value="16"> La Parrilla (Macon) </option>
                        <option value="17"> La Parrilla (Greenville) </option>
                        <option value="18"> La Parrilla (Toco Hill) </option>
                        <option value="19"> La Parrilla (Savannah) </option>
                        <option value="20"> La Parrilla (Griffin) </option>
                        <option value="21"> La Parrilla (Rome) </option>
                      </Form.Control>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BartenderEmergencyList;
