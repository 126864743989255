import dashboardRoutes from "./dashboard";
import employeesRoutes from "./employees";
import financialsRoutes from "./financials";
import hrRoutes from "./hr";
import formManagerRoutes from "./formManager";
import systemConfigurationRoutes from "./systemConfiguration";
import userPagesRoutes from "./userPage";
import cateringRoutes from "./catering";
import accountingRoutes from "./accounting";
import reportRoutes from "./report";
import feedbackRoutes from "./feedback";
import bonusRoutes from "./bonus";

export const appRoutes = [
  ...dashboardRoutes,
  ...employeesRoutes,
  ...financialsRoutes,
  ...hrRoutes,
  ...cateringRoutes,
  ...formManagerRoutes,
  ...systemConfigurationRoutes,
  ...userPagesRoutes,
  ...accountingRoutes,
  ...reportRoutes,
  ...feedbackRoutes,
  ...bonusRoutes,
];
