import React, { useState, useEffect } from "react";
import { UserService } from "../../service/userService";
import { Link, useHistory, useParams } from "react-router-dom";
import { Tab, Col, Row, Nav } from "react-bootstrap";
import Personal from "./components/profile/Personal";
import Company from "./components/profile/company/Company";
import Documents from "./components/profile/Documents/Documents";
import EmergencyContacts from "./components/profile/Contact/EmergencyContacts";
import Notes from "./components/profile/Notes/Notes";
import Bank from "./components/profile/Bank/Bank";
import EmployeeCases from "./components/profile/Case/Cases";
import History from "./components/profile/History";
import cogoToast from "cogo-toast";
import { Utility } from "../../Helpers/utils";
import Reviews from "./components/profile/Reviews";
import Locations from "./components/profile/Location/Locations";
import AccessLevel from "./components/profile/Access/AccessLevel";
import TerminateEmployee from "./components/TerminateEmployee";

const ViewEmployee = () => {
  const [employee, setEmployee] = useState(null);
  const [user, setUser] = useState(null);
  const [selectedTab, setSelectedTab] = useState("personal");
  const history = useHistory();

  let { slug } = useParams();

  useEffect(() => {
    const authUser = Utility.getUser();
    setUser(authUser);

    UserService.getEmployeeUsingSlug(slug)
      .then((response) => {
        setEmployee(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        history.push("/employees/list");
        cogoToast.error("Employee not found");
        // console.log(error.response.data);
      });

    const tab = localStorage.getItem("employeeActiveTab");

    console.log(tab);

    if (tab) {
      setSelectedTab(tab);
    }
  }, [slug]);

  return (
    <div>
      {employee && (
        <div>
          <div className="page-header">
            <h3 className="page-title">
              Profile{" "}
              <span>
                <small>
                  <i>Click on the image to upload a new one</i>
                </small>
              </span>
            </h3>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/employees/list">Employee Management</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Profile
                </li>
              </ol>
            </nav>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <TerminateEmployee employee={employee} slug={slug} />

                    <div className="col-md-12 col-lg-12">
                      <Tab.Container defaultActiveKey={selectedTab}>
                        <Row>
                          <Col xs={12}>
                            <Nav variant="tabs">
                              <div className="mt-4 py-2 border-bottom">
                                <ul className="nav profile-navbar">
                                  <li className="nav-item">
                                    <Nav.Link
                                      eventKey="personal"
                                      className="d-flex align-items-center"
                                    >
                                      <i className="mdi mdi-account-outline mr-1"></i>
                                      Personal
                                    </Nav.Link>
                                  </li>
                                  {/* <li className="nav-item">
                                    <Nav.Link
                                      eventKey="bank"
                                      className="d-flex align-items-center"
                                    >
                                      <i className="mdi mdi-bank mr-1"></i>
                                      Bank
                                    </Nav.Link>
                                  </li>
                                  <li className="nav-item">
                                    <Nav.Link
                                      eventKey="emergency"
                                      className="d-flex align-items-center"
                                    >
                                      <i className="mdi mdi-phone-settings mr-1"></i>
                                      Emergency
                                    </Nav.Link>
                                  </li> */}
                                  <li className="nav-item">
                                    <Nav.Link
                                      eventKey="company"
                                      className="d-flex align-items-center"
                                    >
                                      <i className="mdi mdi-briefcase mr-1"></i>
                                      Position
                                    </Nav.Link>
                                  </li>

                                  <li className="nav-item">
                                    <Nav.Link
                                      eventKey="locations"
                                      className="d-flex align-items-center"
                                    >
                                      <i className="mdi mdi-map-marker-circle mr-1"></i>
                                      Location History
                                    </Nav.Link>
                                  </li>

                                  <li className="nav-item">
                                    <Nav.Link
                                      eventKey="accessLevel"
                                      className="d-flex align-items-center"
                                    >
                                      <i className="mdi mdi-account-key mr-1"></i>
                                      Access Level
                                    </Nav.Link>
                                  </li>

                                  {/* {user && user.role !== "employee" ? (
                                    <li className="nav-item">
                                      <Nav.Link
                                        eventKey="documents"
                                        className="d-flex align-items-center"
                                      >
                                        <i className="mdi mdi-file-document mr-1"></i>
                                        Documents
                                      </Nav.Link>
                                    </li>
                                  ) : (
                                    ""
                                  )} */}

                                  {/* <li className="nav-item">
                                    <Nav.Link
                                      eventKey="notes"
                                      className="d-flex align-items-center"
                                    >
                                      <i className="mdi mdi-note mr-1"></i>
                                      Notes
                                    </Nav.Link>
                                  </li> */}

                                  {/* <li className="nav-item">
                                    <Nav.Link
                                      eventKey="case"
                                      className="d-flex align-items-center"
                                    >
                                      <i className="mdi mdi-anchor mr-1"></i>
                                      Case
                                    </Nav.Link>
                                  </li> */}

                                  {/* <li className="nav-item">
                                    <Nav.Link
                                      eventKey="reviews"
                                      className="d-flex align-items-center"
                                    >
                                      <i className="mdi mdi-account-star menu-icon"></i>
                                      &nbsp;Reviews
                                    </Nav.Link>
                                  </li> */}

                                  {/* <li className="nav-item">
                                    <Nav.Link
                                      eventKey="history"
                                      className="d-flex align-items-center"
                                    >
                                      <i className="mdi mdi-history menu-icon"></i>
                                      &nbsp;History
                                    </Nav.Link>
                                  </li> */}
                                </ul>
                              </div>
                            </Nav>
                          </Col>

                          <Col xs={12} md={12}>
                            <Tab.Content>
                              <Tab.Pane eventKey="personal">
                                <Personal employee={employee} user={user} />
                              </Tab.Pane>

                              {/* <Tab.Pane eventKey="bank">
                                <Bank employee={employee} />
                              </Tab.Pane> */}

                              {/* <Tab.Pane eventKey="emergency">
                                <EmergencyContacts employee={employee} />
                              </Tab.Pane> */}

                              <Tab.Pane eventKey="company">
                                <Company employee={employee} user={user} />
                              </Tab.Pane>

                              <Tab.Pane eventKey="locations">
                                <Locations employee={employee} user={user} />
                              </Tab.Pane>

                              <Tab.Pane eventKey="accessLevel">
                                <AccessLevel employee={employee} user={user} />
                              </Tab.Pane>

                              {/* <Tab.Pane eventKey="documents">
                                <Documents employee={employee} user={user} />
                              </Tab.Pane> */}

                              {/* <Tab.Pane eventKey="notes">
                                <Notes employee={employee} user={user} />
                              </Tab.Pane> */}

                              {/* <Tab.Pane eventKey="case">
                                <EmployeeCases
                                  employee={employee}
                                  user={user}
                                />
                              </Tab.Pane> */}

                              <Tab.Pane eventKey="reviews">
                                <Reviews />
                              </Tab.Pane>
                              <Tab.Pane eventKey="history">
                                <History />
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewEmployee;
