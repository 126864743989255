import Api from "./Api";

export class PaymentService {
  static async payTip(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`payment/pay_tips`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async sendPartPayment(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`payment/part-payment`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async payPendingTip(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`payment/pay-pending-tips`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async proccessInstantPayResponse(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`payment/instant-response`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async proccessPendingTipsResponse(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`payment/pending-instant-response`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getPendingPayment(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`payment/pending-payment/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getDeletedPayment(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`payment/deleted-payment/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getScheduledPayment(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`payment/scheduled-payment/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getPaymentHistories(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`payment/payment-histories/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getPaymentsStoreDateHistories(id, date) {
    return new Promise((resolve, reject) => {
      return Api.get(`payment/payment-store-histories/${id}/${date}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async scheduleAPayment(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`payment/schedule-payment`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async unScheduleAPayment(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`payment/unschedule-payment`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async deleteAPendingPayment(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`payment/delete-pending`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async scheduleAllPayment(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`payment/schedule-all-payment/`, { data })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getSalesReportForStore(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`payment/sales-report/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getCompletedSalesReportForStore(id, date) {
    return new Promise((resolve, reject) => {
      return Api.get(`payment/completed-report/${id}/${date}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async uploadAuditReport(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`payment/instant/upload-audit-report`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getAuditedInstantPaymentsStore(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`payment/audited-instant/store/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async postIssues(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`payment/issues`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async retrieveOpenedIssues(data) {
    return new Promise((resolve, reject) => {
      return Api.get(`payment/get-issues`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async sendPaymentIssuesToAdmin(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`payment/issues-with-payments`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async updateClosingReportIssues(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`payment/update-closing-issues`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async uploadDepositSlip(data) {
    return new Promise((resolve, reject) => {
      return Api.post("payment/upload-deposit-slip", data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  static async getDepositSlipForStore(id, date) {
    return new Promise((resolve, reject) => {
      return Api.get(`payment/deposit-slip/${id}/${date}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
