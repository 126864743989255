import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router";
import { SalesService } from "../../service/SalesService";
import { Link } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import Swal from "sweetalert2";
import { ConvertDateFormat } from "../../components/ConvertDateFormat";

const ViewAndDownloadReportDetails = () => {
  let { store_id, id } = useParams();
  const [fileUrl, setFileUrl] = useState(null);
  const [extractedData, setExtractedData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [mdShow, setMdShow] = useState(false);

  const roundNumberUp = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  useEffect(() => {
    const data = { store_id, id };
    SalesService.downloadSalesExtract(data)
      .then((response) => {
        setExtractedData(response.data.data.extract);
        setIsLoading(false);
        console.log(response.data.data.extract, "the date is here");
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      {isLoading ? (
        <div>
          <span className="fa fa-spin fa-spinner fa-4x"></span>
        </div>
      ) : (
        <div>
          <div className="page-header">
            <h3 className="page-title">Sales Report Extract</h3>

            <Link to={"/accounting/report"}>
              <button className="btn btn-primary"> Back </button>
            </Link>
          </div>

          <div>
            <p>
              {" "}
              Report Details For{" "}
              <span>
                <i>
                  <b>{ConvertDateFormat(extractedData.report_date)}</b>{" "}
                </i>
              </span>
            </p>
          </div>

          <Formik
            initialValues={{
              store_id: store_id,
              extract_id: id,
              food_sales: extractedData.food_sales
                ? roundNumberUp(extractedData.food_sales)
                : "",
              liquor: extractedData.liquor
                ? roundNumberUp(extractedData.liquor)
                : "",
              beer_wine: extractedData.beer_wine
                ? roundNumberUp(extractedData.beer_wine)
                : "",
              sale_tax: extractedData.sale_tax
                ? roundNumberUp(extractedData.sale_tax)
                : "",

              gift_card_sold: extractedData.gift_card_sold
                ? roundNumberUp(extractedData.gift_card_sold)
                : "",
              tips_credit_card: extractedData.tips_credit_card
                ? roundNumberUp(extractedData.tips_credit_card)
                : "",
              gift_cards_redeem: extractedData.gift_cards_redeem
                ? roundNumberUp(extractedData.gift_cards_redeem)
                : "",
              door_dash_pos: extractedData.door_dash
                ? roundNumberUp(extractedData.door_dash)
                : "",
              door_dash_amount: extractedData.door_dash_amount
                ? roundNumberUp(extractedData.door_dash_amount)
                : "",
              door_dash_diff_amount: extractedData.door_dash_diff_amount
                ? roundNumberUp(extractedData.door_dash_diff_amount)
                : "",
              compos_promos_voids: extractedData.compos_promos_voids
                ? roundNumberUp(extractedData.compos_promos_voids)
                : "",
              credit_card_amex: extractedData.credit_card_amex
                ? roundNumberUp(extractedData.credit_card_amex)
                : "",
              credit_card_other: extractedData.credit_card_other
                ? roundNumberUp(extractedData.credit_card_other)
                : "",
              deposit: extractedData.deposit
                ? roundNumberUp(extractedData.deposit)
                : "",
              tips_paid_instant: extractedData.tips_paid_instant
                ? roundNumberUp(extractedData.tips_paid_instant)
                : "",
              bank_deposit: extractedData.bank_deposit
                ? roundNumberUp(extractedData.bank_deposit)
                : "",
              bank_deposit_diff_amount: extractedData.bank_deposit_diff_amount
                ? roundNumberUp(extractedData.bank_deposit_diff_amount)
                : "",
              cp_cc_amount: extractedData.cp_cc_amount
                ? roundNumberUp(extractedData.cp_cc_amount)
                : "",
              cc_diff_amount: extractedData.cc_diff_amount
                ? roundNumberUp(extractedData.cc_diff_amount)
                : "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);

              if (
                values.bank_deposit &&
                values.cp_cc_amount &&
                values.door_dash_amount
              ) {
                console.log(values);
                Swal.fire({
                  title: "In progress",
                  text: "Please wait...",
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  onOpen: () => {
                    Swal.showLoading();
                  },
                });
                SalesService.updateSalesExtract(values)
                  .then((response) => {
                    setSubmitting(false);
                    Swal.close();
                    setMdShow(true);
                    setFileUrl(response.data.data.url);
                    console.log(response);
                  })
                  .catch((error) => {
                    setSubmitting(false);
                    console.log(error);
                    console.log(error.response.data);
                    Swal.fire({
                      title: "Error!",
                      text: error.response.data.message,
                      icon: "error",
                      confirmButtonText: "Ok",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        Swal.close();
                      }
                    });
                  });
              } else {
                setSubmitting(false);
                Swal.fire({
                  title: "Error!",
                  text: `Please fill the required fields`,
                  icon: "error",
                  confirmButtonText: "Ok",
                }).then((result) => {
                  if (result.isConfirmed) {
                    Swal.close();
                  }
                });
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Item</th>
                                <th>Amount</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>
                                <td>
                                  Bank Deposit
                                  <span className="text-danger">*</span>
                                </td>
                                <td>
                                  <Form.Control
                                    type="text"
                                    className="form-control"
                                    id="bank_deposit"
                                    name="bank_deposit"
                                    defaultValue={values.bank_deposit}
                                    placeholder="Enter an amount "
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                    onBlur={(e) => {
                                      if (e.target.value) {
                                        setFieldValue(
                                          "bank_deposit",
                                          parseFloat(e.target.value)
                                        );

                                        const deposit_diff =
                                          parseFloat(e.target.value) -
                                          (values.deposit
                                            ? parseFloat(values.deposit)
                                            : 0);
                                        console.log(deposit_diff);
                                        setFieldValue(
                                          "bank_deposit_diff_amount",
                                          roundNumberUp(
                                            parseFloat(deposit_diff)
                                          )
                                        );
                                      } else {
                                        setFieldValue(
                                          "bank_deposit_diff_amount",
                                          roundNumberUp(0)
                                        );
                                      }
                                    }}
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td> Bank Deposit Diff Amount </td>
                                <td>
                                  <Form.Control
                                    type="text"
                                    className="form-control"
                                    id="bank_deposit_diff_amount"
                                    name="bank_deposit_diff_amount"
                                    value={values.bank_deposit_diff_amount}
                                    disabled={"disabled"}
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  Door Dash Amount
                                  <span className="text-danger">*</span>
                                </td>
                                <td>
                                  <Form.Control
                                    type="text"
                                    className="form-control"
                                    id="door_dash_amount"
                                    name="door_dash_amount"
                                    defaultValue={values.door_dash_amount}
                                    placeholder="Enter an amount "
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                    onBlur={(e) => {
                                      if (e.target.value) {
                                        setFieldValue(
                                          "door_dash_amount",
                                          parseFloat(e.target.value)
                                        );

                                        const door_diff =
                                          parseFloat(
                                            values.door_dash_pos
                                              ? values.door_dash_pos
                                              : 0
                                          ) - parseFloat(e.target.value);
                                        setFieldValue(
                                          "door_dash_diff_amount",
                                          roundNumberUp(parseFloat(door_diff))
                                        );
                                      } else {
                                        setFieldValue(
                                          "door_dash_diff_amount",
                                          roundNumberUp(0)
                                        );
                                      }
                                    }}
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td> Door Dash Diff Amount </td>
                                <td>
                                  <Form.Control
                                    type="text"
                                    className="form-control"
                                    id="door_dash_diff_amount"
                                    name="door_dash_diff_amount"
                                    value={values.door_dash_diff_amount}
                                    disabled={"disabled"}
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  CP CC Amount
                                  <span className="text-danger">*</span>
                                </td>
                                <td>
                                  <Form.Control
                                    type="number"
                                    className="form-control"
                                    id="tips_instant"
                                    name="cp_cc_amount"
                                    defaultValue={values.cp_cc_amount}
                                    placeholder="Enter an amount "
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                    onBlur={(e) => {
                                      if (e.target.value) {
                                        setFieldValue(
                                          "cp_cc_amount",
                                          parseFloat(e.target.value)
                                        );

                                        const cc_diff =
                                          parseFloat(
                                            values.credit_card_amex
                                              ? values.credit_card_amex
                                              : 0
                                          ) +
                                          parseFloat(
                                            values.credit_card_other
                                              ? values.credit_card_other
                                              : 0
                                          ) -
                                          parseFloat(e.target.value);
                                        setFieldValue(
                                          "cc_diff_amount",
                                          roundNumberUp(parseFloat(cc_diff))
                                        );
                                      } else {
                                        setFieldValue(
                                          "cc_diff_amount",
                                          roundNumberUp(0)
                                        );
                                      }
                                    }}
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td> CC Diff Amount </td>
                                <td>
                                  <Form.Control
                                    type="text"
                                    className="form-control"
                                    id="cc_diff_amount"
                                    name="cc_diff_amount"
                                    value={values.cc_diff_amount}
                                    disabled={"disabled"}
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <button className="btn btn-success" type="submit">
                          {" "}
                          Save and Generate Excel &nbsp;&nbsp;
                          {isSubmitting ? (
                            <span className="fa fa-spin fa-spinner"></span>
                          ) : (
                            ""
                          )}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <p> Other Items </p>
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Item</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Food Sales</td>
                                <td>
                                  <Form.Group>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          $
                                        </span>
                                      </div>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        aria-label="Tips Amount"
                                        aria-describedby="basic-addon1"
                                        id="food_sales"
                                        disabled={"disabled"}
                                        placeholder="Tips Amount"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        name={"food_sales"}
                                        value={values.food_sales}
                                      />
                                    </div>
                                  </Form.Group>
                                </td>
                              </tr>
                              <tr>
                                <td>Liquor</td>
                                <td>
                                  <Form.Group>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          $
                                        </span>
                                      </div>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        aria-label="Tips Amount"
                                        aria-describedby="basic-addon1"
                                        id="liquor"
                                        disabled={"disabled"}
                                        placeholder="Tips Amount"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        name={"liquor"}
                                        value={values.liquor}
                                      />
                                    </div>
                                  </Form.Group>
                                </td>
                              </tr>
                              <tr>
                                <td>Beer / Wine </td>
                                <td>
                                  <Form.Group>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          $
                                        </span>
                                      </div>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        aria-label="Tips Amount"
                                        aria-describedby="basic-addon1"
                                        id="beer_wine"
                                        disabled={"disabled"}
                                        placeholder="Tips Amount"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        name={"beer_wine"}
                                        value={values.beer_wine}
                                      />
                                    </div>
                                  </Form.Group>
                                </td>
                              </tr>
                              <tr>
                                <td> Sale Tax </td>
                                <td>
                                  <Form.Group>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          $
                                        </span>
                                      </div>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        aria-label="Tips Amount"
                                        aria-describedby="basic-addon1"
                                        id="sale_tax"
                                        disabled={"disabled"}
                                        placeholder="Tips Amount"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        name={"sale_tax"}
                                        value={values.sale_tax}
                                      />
                                    </div>
                                  </Form.Group>
                                </td>
                              </tr>
                              <tr>
                                <td>Gift Card Sold </td>
                                <td>
                                  <Form.Group>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          $
                                        </span>
                                      </div>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        aria-label="Tips Amount"
                                        aria-describedby="basic-addon1"
                                        id="gift_card_sold"
                                        disabled={"disabled"}
                                        placeholder="Tips Amount"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        name={"gift_card_sold"}
                                        value={values.gift_card_sold}
                                      />
                                    </div>
                                  </Form.Group>
                                </td>
                              </tr>
                              <tr>
                                <td>Tips / Credit Cards </td>
                                <td>
                                  <Form.Group>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          $
                                        </span>
                                      </div>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        aria-label="Tips Amount"
                                        aria-describedby="basic-addon1"
                                        id="tips_credit_card"
                                        disabled={"disabled"}
                                        placeholder="Tips Amount"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        name={"tips_credit_card"}
                                        value={values.tips_credit_card}
                                      />
                                    </div>
                                  </Form.Group>
                                </td>
                              </tr>
                              <tr>
                                <td>Gift Cards Redeem </td>
                                <td>
                                  <Form.Group>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          $
                                        </span>
                                      </div>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        aria-label="Tips Amount"
                                        aria-describedby="basic-addon1"
                                        id="gift_cards_redeem"
                                        disabled={"disabled"}
                                        placeholder="Tips Amount"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        name={"gift_cards_redeem"}
                                        value={
                                          values.gift_cards_redeem
                                            ? values.gift_cards_redeem
                                            : roundNumberUp(0)
                                        }
                                      />
                                    </div>
                                  </Form.Group>
                                </td>
                              </tr>
                              <tr>
                                <td> Door Dash </td>
                                <td>
                                  <Form.Group>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          $
                                        </span>
                                      </div>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        aria-label="Tips Amount"
                                        aria-describedby="basic-addon1"
                                        id="total_bar_owed"
                                        disabled={"disabled"}
                                        placeholder="Tips Amount"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        name={"food_sales"}
                                        value={
                                          values.door_dash_pos
                                            ? values.door_dash_pos
                                            : roundNumberUp(0)
                                        }
                                      />
                                    </div>
                                  </Form.Group>
                                </td>
                              </tr>
                              <tr>
                                <td>Comps / Promos / Voids</td>
                                <td>
                                  <Form.Group>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          $
                                        </span>
                                      </div>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        aria-label="Tips Amount"
                                        aria-describedby="basic-addon1"
                                        id="compos_promos_voids"
                                        disabled={"disabled"}
                                        placeholder="Tips Amount"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        name={"compos_promos_voids"}
                                        value={
                                          values.compos_promos_voids
                                            ? values.compos_promos_voids
                                            : roundNumberUp(0)
                                        }
                                      />
                                    </div>
                                  </Form.Group>
                                </td>
                              </tr>
                              <tr>
                                <td>Credit Card - Amex </td>
                                <td>
                                  <Form.Group>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          $
                                        </span>
                                      </div>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        aria-label="Credit Card Amex"
                                        aria-describedby="basic-addon1"
                                        id="credit_card_amex"
                                        disabled={"disabled"}
                                        placeholder="Credit Card Amex"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        name={"credit_card_amex"}
                                        value={
                                          values.credit_card_amex
                                            ? values.credit_card_amex
                                            : roundNumberUp(0)
                                        }
                                      />
                                    </div>
                                  </Form.Group>
                                </td>
                              </tr>
                              <tr>
                                <td>Credit Card - Other </td>
                                <td>
                                  <Form.Group>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          $
                                        </span>
                                      </div>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        aria-label="Credit Card Other"
                                        aria-describedby="basic-addon1"
                                        id="credit_card_other"
                                        disabled={"disabled"}
                                        placeholder="Credit Card Other"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        name={"credit_card_other"}
                                        value={
                                          values.credit_card_other
                                            ? values.credit_card_other
                                            : roundNumberUp(0)
                                        }
                                      />
                                    </div>
                                  </Form.Group>
                                </td>
                              </tr>

                              <tr>
                                <td> Deposit </td>
                                <td>
                                  <Form.Group>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          $
                                        </span>
                                      </div>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        aria-label="Deposit"
                                        aria-describedby="basic-addon1"
                                        id="deposit"
                                        disabled={"disabled"}
                                        placeholder="Deposit"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        name={"deposit"}
                                        value={
                                          values.deposit
                                            ? values.deposit
                                            : roundNumberUp(0)
                                        }
                                      />
                                    </div>
                                  </Form.Group>
                                </td>
                              </tr>

                              <tr>
                                <td> Tips paid through Instant </td>
                                <td>
                                  <Form.Group>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          $
                                        </span>
                                      </div>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        aria-label="Tips Paid Instant"
                                        aria-describedby="basic-addon1"
                                        id="deposit"
                                        disabled={"disabled"}
                                        placeholder="Tips Instant"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        name={"tips_paid_instant"}
                                        value={
                                          values.tips_paid_instant
                                            ? values.tips_paid_instant
                                            : roundNumberUp(0)
                                        }
                                      />
                                    </div>
                                  </Form.Group>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>

          <div className="row">
            <Modal
              show={mdShow}
              onHide={() => setMdShow(false)}
              aria-labelledby="example-modal-sizes-title-md"
            >
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>

              <Modal.Body>
                {fileUrl && (
                  <div className="row">
                    <div className="col-md-12">
                      <a download href={fileUrl}>
                        <span> Click here to download the Excel </span>
                      </a>
                    </div>
                  </div>
                )}
              </Modal.Body>
            </Modal>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(ViewAndDownloadReportDetails);
