import cogoToast from "cogo-toast";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useHistory, useParams, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { ConvertDateFormat } from "../../components/ConvertDateFormat";
import { SystemConfigService } from "../../service/configService";
import { ExpensesService } from "../../service/ExpenseService";
const { SearchBar } = Search;

const StoreExpenses = () => {
  let { store_id } = useParams();
  const [store, setStore] = useState(null);
  const [expensesAmount, setExpensesAmount] = useState(null);
  let history = useHistory();

  const handleViewDocRedirect = (id) => {
    history.push(`/financials/store/doc/${store_id}/${id}`);
  };

  const columns = [
    {
      dataField: "id",
      text: "Expense Id",
      sort: true,
    },
    {
      dataField: "date",
      text: "Report Date",
      sort: true,
    },
    {
      dataField: "manager_name",
      text: "Manager Name",
      sort: true,
    },
    {
      dataField: "auto_truck",
      text: "Auto Truck",
      sort: true,
    },
    {
      dataField: "cleaning",
      text: "Cleaning",
      sort: true,
    },
    {
      dataField: "food_purchases",
      text: "Food Purchases",
      sort: true,
    },
    {
      dataField: "gift_promotion",
      text: "Gift Promotion",
      sort: true,
    },
    {
      dataField: "office_supplies",
      text: "Office Supplies",
      sort: true,
    },
    {
      dataField: "repair",
      text: "Repairs",
      sort: true,
    },
    {
      dataField: "other",
      text: "Others",
      sort: true,
    },
    {
      dataField: "rest_supplies",
      text: "Office Supplies",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <div>
            <button
              className="btn btn-outline-primary"
              onClick={() => handleViewDocRedirect(row.id)}
            >
              <i className="mdi mdi-eye-outline text-primary"></i>View Receipts
            </button>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  const roundNumberUp = (num) => {
    // if (num) {
    return "$" + (Math.round(num * 100) / 100).toFixed(2);
    // } else {
    //   return "$" + (Math.round(0 * 100) / 100).toFixed(2);
    // }
  };

  useEffect(() => {
    const data = { store_id };
    ExpensesService.getExpensesAmount(data)
      .then((response) => {
        let dataResArray = [];
        console.log(response.data.data);
        response.data.data.map((res, index) => {
          return dataResArray.push({
            date: ConvertDateFormat(res.date),
            manager_name: res.manager_name,
            auto_truck: res.auto_truck
              ? roundNumberUp(res.auto_truck)
              : roundNumberUp(0),
            cleaning: res.cleaning
              ? roundNumberUp(res.cleaning)
              : roundNumberUp(0),
            food_purchases: res.food_purchases
              ? roundNumberUp(res.food_purchases)
              : roundNumberUp(0),
            gift_promotion: res.gift_promotion
              ? roundNumberUp(res.gift_promotion)
              : roundNumberUp(0),
            id: res.id,
            office_supplies: res.office_supplies
              ? roundNumberUp(res.office_supplies)
              : roundNumberUp(0),
            other: res.other ? roundNumberUp(res.other) : roundNumberUp(0),
            repair: res.repair ? roundNumberUp(res.repair) : roundNumberUp(0),
            rest_supplies: res.rest_supplies
              ? roundNumberUp(res.rest_supplies)
              : roundNumberUp(0),
          });
        });
        setExpensesAmount(dataResArray);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });

    SystemConfigService.getStore(store_id)
      .then((response) => {
        console.log(response.data.data);
        setStore(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        cogoToast.error(error.response.data.message, toastoptions);
      });
  }, []);

  return (
    <div>
      <div>
        <p className="pull-right" style={{ textDecoration: "underline" }}>
          <a
            href="https://veltron.document360.io/v1/en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn How &nbsp;<span className="fa fa-question"></span>
          </a>
        </p>

        <div className="clearfix"></div>
      </div>

      <div className="page-header">
        <h3 className="page-title">
          <span style={{ textTransform: "capitalize" }}>
            {store ? store.name : ""}
          </span>
        </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/financials/expenses-viewers">La Parrilla</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <span style={{ textTransform: "capitalize" }}>
                {store ? store.name : ""}
              </span>
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        {expensesAmount && (
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"> Expenses</h4>
                <div className="row">
                  <div className="col-12">
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={expensesAmount}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div className="d-flex align-items-center">
                            <p className="mb-2 mr-2">Search in table:</p>
                            <SearchBar {...props.searchProps} />
                          </div>
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(StoreExpenses);
