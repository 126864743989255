import React from "react";
import { Form, Button } from "react-bootstrap";

const SubTotal = ({ isSubmitting, values, handleChange }) => {
  return (
    <>
      <tr>
        <th></th>
        <td></td>
      </tr>

      <tr>
        <th
          scope="row"
          style={{
            padding: "0px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Sub Total
        </th>
        <td
          width="10%"
          style={{
            padding: "0px",
            textAlign: "center",
          }}
        ></td>
        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="subTotalM1"
            name="subTotalM1"
            defaultValue={values.subTotalM1}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>
        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="subTotalM1P"
            name="subTotalM1P"
            defaultValue={values.subTotalM1P}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>
        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="subTotalM2"
            name="subTotalM2"
            defaultValue={values.subTotalM2}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="subTotalM2P"
            name="subTotalM2P"
            defaultValue={values.subTotalM2P}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="subTotalM3"
            name="subTotalM3"
            defaultValue={values.subTotalM3}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="subTotalM3P"
            name="subTotalM3P"
            defaultValue={values.subTotalM3P}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="subTotalTotal"
            name="subTotalTotal"
            defaultValue={values.subTotalTotal}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={handleChange}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="subTotalTotalP"
            name="subTotalTotalP"
            defaultValue={values.subTotalTotalP}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={handleChange}
          />
        </td>
      </tr>

      <tr>
        <th
          scope="row"
          style={{
            padding: "0px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        ></th>
        <td
          width="10%"
          style={{
            padding: "0px",
            textAlign: "center",
          }}
        >
          {" "}
          Other Income
        </td>
        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="otherIncomeM1"
            name="otherIncomeM1"
            defaultValue={values.otherIncomeM1}
            disabled={isSubmitting}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>
        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="otherIncomeM1P"
            name="otherIncomeM1P"
            defaultValue={values.otherIncomeM1P}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>
        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="otherIncomeM2"
            name="otherIncomeM2"
            defaultValue={values.otherIncomeM2}
            disabled={isSubmitting}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="otherIncomeM2P"
            name="otherIncomeM2P"
            defaultValue={values.otherIncomeM2P}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="otherIncomeM3"
            name="otherIncomeM3"
            defaultValue={values.otherIncomeM3}
            disabled={isSubmitting}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="otherIncomeM3P"
            name="otherIncomeM3P"
            defaultValue={values.otherIncomeM3P}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="otherIncomeTotal"
            name="otherIncomeTotal"
            defaultValue={values.otherIncomeTotal}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={handleChange}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="otherIncomeTotalP"
            name="otherIncomeTotalP"
            defaultValue={values.otherIncomeTotalP}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={handleChange}
          />
        </td>
      </tr>

      <tr>
        <th
          scope="row"
          style={{
            padding: "0px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Net Profit
        </th>
        <td
          width="10%"
          style={{
            padding: "0px",
            textAlign: "center",
          }}
        ></td>
        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="netProfitM1"
            name="netProfitM1"
            defaultValue={values.netProfitM1}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>
        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="netProfitM1P"
            name="netProfitM1P"
            defaultValue={values.netProfitM1P}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>
        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="netProfitM2"
            name="netProfitM2"
            defaultValue={values.netProfitM2}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="netProfitM2P"
            name="netProfitM2P"
            defaultValue={values.netProfitM2P}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="netProfitM3"
            name="netProfitM3"
            defaultValue={values.netProfitM3}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="netProfitM3P"
            name="netProfitM3P"
            defaultValue={values.netProfitM3P}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => {
              console.log(e.target);
            }}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="netProfitTotal"
            name="netProfitTotal"
            defaultValue={values.netProfitTotal}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={handleChange}
          />
        </td>

        <td className="bonus-input-wrapper">
          <Form.Control
            type="number"
            className="form-control"
            id="netProfitTotalP"
            name="netProfitTotalP"
            defaultValue={values.netProfitTotalP}
            disabled={"disabled"}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={handleChange}
          />
        </td>
      </tr>
    </>
  );
};

export default SubTotal;
