import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import AppSpinner from "../../components/Spinner";
import { SystemConfigService } from "../../service/configService";
import { Col, Row, Nav, Tab } from "react-bootstrap";
import AllReports from "./components/AllReports";
import MiReports from "./components/MiReports";
import ErReports from "./components/ErReports";
import SiReports from "./components/SiReports";

const ListStoresForReport = () => {
  const [stores, setStores] = useState(null);

  useEffect(() => {
    SystemConfigService.getStoresForManagerAdmin()
      .then((response) => {
        console.log(response.data.data);
        setStores(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);
  return (
    <div>
      <div className="tab-custom-pills-horizontal">
        <Tab.Container id="left-tabs-example" defaultActiveKey="all">
          <Row>
            <Col xs={12}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link
                    eventKey="stores"
                    className="d-flex align-items-center"
                  >
                    Stores
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="all"
                    className="d-flex align-items-center"
                  >
                    All
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="mi" className="d-flex align-items-center">
                    MI
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="er" className="d-flex align-items-center">
                    ER
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="si" className="d-flex align-items-center">
                    SI
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="stores">
                  <div className="mt-3">
                    <div className="row">
                      {stores ? (
                        stores.map((value, index) => (
                          <div className="col-md-3 mb-5" key={index}>
                            <div className="domainBox">
                              <Link to={`/report/mes/${value.location_id}`}>
                                <div className="react-aspect-ratio-placeholder">
                                  <div className="sc-fzoiQi ozSmQ  ">
                                    <main>
                                      <h4
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {" "}
                                        {value.name}
                                      </h4>
                                      <small>{value.address}</small>
                                      <br />
                                      <br />
                                      <small>
                                        <b>({value.location_id})</b>
                                      </small>
                                    </main>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        ))
                      ) : (
                        <AppSpinner />
                      )}
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="all">
                  <AllReports />
                </Tab.Pane>
                <Tab.Pane eventKey="mi">
                  <MiReports />
                </Tab.Pane>
                <Tab.Pane eventKey="er">
                  <ErReports />
                </Tab.Pane>
                <Tab.Pane eventKey="si">
                  <SiReports />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};

export default ListStoresForReport;
