import React from "react";
import SweetAlert from "sweetalert2-react";

const DepositAlert = ({ showDepositAlert, depositAlertMessage, setShowDepositAlert }) => (
  <SweetAlert
    show={showDepositAlert}
    title="Alert!"
    text={depositAlertMessage}
    onConfirm={() => setShowDepositAlert(false)}
  />
);

export default DepositAlert;
