import React, { useEffect, useState } from "react";
import { Formik, FieldArray, Field } from "formik";
import { ExpensesService } from "../../../../service/ExpenseService";
import { Spinner } from "react-bootstrap";
import { Form } from "react-bootstrap";

const EmergencyCodeDetails = ({ salesReport, laborInfoDetails }) => {
  const [emergencyCodes, setEmergencyCodes] = useState(null);
  const [emergencyCodesDetails, setEmergencyCodesDetails] = useState(null);
  const [emergencyCodeCreated, setEmergencyCodeCreated] = useState(false);

  const handleCancelEmergencyCode = (date, owner) => {
    console.log(date, owner);
    const data = {
      date,
      store_id: owner,
    };
    ExpensesService.cancelEmergencyExpenses(data)
      .then((response) => {
        console.log(response.data);
        setEmergencyCodes(
          JSON.parse(localStorage.getItem("laborInfoDetails")).emergencyCodes.otherCodes
        );
        setEmergencyCodeCreated(false);
        setEmergencyCodesDetails(null);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  useEffect(() => {
    if (salesReport) {
      let data = {
        store_id: salesReport.owner,
        report_date: salesReport.report_date,
      };

      ExpensesService.getEmergencyExpensesForAStore(data)
        .then((response) => {
          let nonBarEmergency = response.data.data.filter((item) => {
            return item.job_code !== "Bartender";
          });

          setEmergencyCodesDetails(nonBarEmergency);
          if (nonBarEmergency.length > 0) {
            setEmergencyCodeCreated(true);
          }
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    }

    if (laborInfoDetails) {
      const emergency = laborInfoDetails.emergencyCodes.otherCodes;
      setEmergencyCodes(emergency);
    }
  }, [salesReport, laborInfoDetails]);

  return (
    <div>
      <div>
        {emergencyCodes && emergencyCodes.length > 0 && !emergencyCodeCreated && (
          <div className="row" style={{ marginTop: "60px" }}>
            <div className="col-md-12">
              <div>
                <h4>Emergency Code(s) </h4>
                <span>
                  <small id="emailHelp" className="form-text text-muted">
                    Please enter the details for the Emergency code(s) listed below ( Click on
                    submit to save your changes)
                  </small>
                </span>
              </div>
              <div className="table-responsive" style={{ marginTop: "60px" }}>
                <Formik
                  initialValues={{
                    emergencyCodes: emergencyCodes ? emergencyCodes : [],
                    report_date: salesReport ? salesReport.report_date : "",
                    owner: salesReport ? salesReport.owner : "",
                  }}
                  enableReinitialize={true}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);

                    ExpensesService.createEmergencyExpenses(values)
                      .then((response) => {
                        console.log(response.data.data);
                        setSubmitting(false);
                        if (response.data.data.length > 0) {
                          setEmergencyCodeCreated(true);
                        }
                        setEmergencyCodesDetails(response.data.data);
                      })
                      .catch((error) => {
                        setSubmitting(false);
                        console.log(error);
                      });
                  }}
                >
                  {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                      <FieldArray name="emergencyCodeForm">
                        {(fieldArrayProps) => {
                          const { form } = fieldArrayProps;
                          const { values } = form;
                          const { emergencyCodes } = values;

                          return (
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th style={{ width: "10%" }}> Code </th>
                                  <th style={{ width: "10%" }}> Name </th>
                                  <th style={{ width: "5%" }}> Hours </th>
                                  <th style={{ width: "10%" }}> Tips Owed </th>
                                  <th style={{ width: "25%" }}> Employee Name </th>
                                  <th style={{ width: "25%" }}> Base Location </th>
                                </tr>
                              </thead>
                              <tbody>
                                {emergencyCodes.map((code, index) => (
                                  <tr key={code.emergency_code} id={"emergency_codes_row_" + index}>
                                    <td style={{ paddingLeft: "0px" }}>
                                      <Field
                                        name={`emergencyCodes[${index}].emergency_code`}
                                        className="form-control"
                                        placeholder="Emergency Code"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        disabled={"disabled"}
                                        value={code.emergency_code}
                                        style={{
                                          border: "none",
                                          paddingLeft: "10px",
                                          paddingRight: "0px",
                                        }}
                                      />
                                    </td>

                                    <td style={{ paddingLeft: "0px" }}>
                                      <Field
                                        name={`emergencyCodes[${index}].jobCode`}
                                        className="form-control"
                                        placeholder="Job Code"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        disabled={"disabled"}
                                        value={code.jobCode}
                                        style={{
                                          border: "none",
                                          paddingLeft: "10px",
                                          paddingRight: "0px",
                                        }}
                                      />
                                    </td>

                                    <td style={{ paddingLeft: "0px" }}>
                                      <Field
                                        name={`emergencyCodes[${index}].totalHours`}
                                        className="form-control"
                                        placeholder="Total Hours"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        disabled={"disabled"}
                                        value={code.totalHours}
                                        style={{
                                          border: "none",
                                          paddingLeft: "10px",
                                          paddingRight: "0px",
                                        }}
                                      />
                                    </td>

                                    <td className="amountRow" style={{ paddingLeft: "0px" }}>
                                      <div className="input-group">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">$</span>
                                        </div>
                                        <Field
                                          name={`emergencyCodes[${index}].tipsOwed`}
                                          className="form-control"
                                          placeholder="Tips Owed"
                                          autoComplete="off"
                                          autoCorrect="off"
                                          autoCapitalize="off"
                                          spellCheck="false"
                                          disabled={"disabled"}
                                          value={code.tipsOwed}
                                          style={{
                                            border: "none",
                                            paddingLeft: "10px",
                                            paddingRight: "0px",
                                          }}
                                        />
                                      </div>
                                    </td>

                                    <td style={{ paddingLeft: "5px" }}>
                                      <Field
                                        name={`emergencyCodes[${index}].employee_name`}
                                        className="form-control"
                                        placeholder="Employee Name"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        disabled={isSubmitting}
                                        value={code.employee_name ? code.employee_name : ""}
                                      />
                                    </td>

                                    <td style={{ paddingLeft: "5px" }}>
                                      <Field
                                        as="select"
                                        name={`emergencyCodes[${index}].base_location`}
                                        className="form-control"
                                        placeholder="Base location"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        disabled={isSubmitting}
                                        value={code.base_location ? code.base_location : "DEFAULT"}
                                      >
                                        <option value="DEFAULT" disabled>
                                          Select One
                                        </option>
                                        <option value="1"> La Parrilla (Marietta Square) </option>
                                        <option value="2"> La Parrilla (Dallas Hwy) </option>
                                        <option value="3"> La Parrilla (Flowery Branch) </option>
                                        <option value="4"> La Parrilla (Powder Spring) </option>
                                        <option value="5"> La Parrilla (Fayette Ville) </option>
                                        <option value="6"> La Parrilla (Alpharetta) </option>
                                        <option value="7"> La Parrilla (Woodstock) </option>
                                        <option value="9"> La Parrilla (Acworth) </option>
                                        <option value="10"> La Parrilla (Newman) </option>
                                        <option value="11"> La Parrilla (Mcdonough) </option>
                                        <option value="12"> La Parrilla (Howel Mill) </option>
                                        <option value="13"> La Parrilla (Dothan) </option>
                                        <option value="14"> La Parrilla (Norcross) </option>
                                        <option value="15"> La Parrilla (Canton) </option>
                                        <option value="16"> La Parrilla (Macon) </option>
                                        <option value="17"> La Parrilla (Greenville) </option>
                                        <option value="18"> La Parrilla (Toco Hill) </option>
                                        <option value="19"> La Parrilla (Savannah) </option>
                                        <option value="20"> La Parrilla (Griffin) </option>
                                        <option value="21">La Parrilla (Rome) </option>
                                        <option value="23">La Parrilla (Sandy Springs)</option>
                                      </Field>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          );
                        }}
                      </FieldArray>

                      {!emergencyCodeCreated && (
                        <div style={{ marginTop: "60px" }}>
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-primary mr-2"
                          >
                            Submit &nbsp;&nbsp;
                            {isSubmitting ? <Spinner animation="border" size="sm" /> : ""}
                          </button>
                        </div>
                      )}
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        {emergencyCodesDetails && emergencyCodesDetails.length > 0 && (
          <div
            className="row"
            style={{
              marginTop: "60px",
              borderTop: "3px dotted #f5f5f5",
            }}
          >
            <div className="col-md-12">
              <div>
                <h4>Emergency Code(s) </h4>
                <span>
                  <small id="emailHelp" className="form-text text-muted">
                    Emergency Code Details
                  </small>
                </span>
              </div>
              <div className="table-responsive" style={{ marginTop: "60px" }}>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}> Code </th>
                      {/* <th className="table-wider"> Hours </th> */}
                      <th style={{ width: "10%" }}> Name </th>
                      <th style={{ width: "5%" }}> Hours </th>
                      <th style={{ width: "10%" }}> Tips Owed </th>
                      <th style={{ width: "25%" }}> Employee Name </th>
                      <th style={{ width: "25%" }}> Base Location </th>
                    </tr>
                  </thead>
                  <tbody>
                    {emergencyCodesDetails.map((code, index) => (
                      <tr key={code.emergency_code} id={"emergency_codes_row_" + index}>
                        <td style={{ paddingLeft: "0px" }}>
                          <Form.Control
                            name={`emergency_code`}
                            className="form-control"
                            placeholder="Emergency Code"
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            disabled={"disabled"}
                            value={code.emergency_code}
                            style={{
                              border: "none",
                              paddingLeft: "10px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>

                        <td style={{ paddingLeft: "0px" }}>
                          <Form.Control
                            name={`jobCode`}
                            className="form-control"
                            placeholder="Job Code"
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            disabled={"disabled"}
                            value={code.job_code}
                            style={{
                              border: "none",
                              paddingLeft: "10px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>

                        <td style={{ paddingLeft: "0px" }}>
                          <Form.Control
                            name={`totalHours`}
                            className="form-control"
                            placeholder="Total Hours"
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            disabled={"disabled"}
                            value={code.total_hours}
                            style={{
                              border: "none",
                              paddingLeft: "10px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>

                        <td className="amountRow" style={{ paddingLeft: "0px" }}>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">$</span>
                            </div>
                            <Form.Control
                              name={`tipsOwed`}
                              className="form-control"
                              placeholder="Tips Owed"
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              disabled={"disabled"}
                              value={code.tips_owed}
                              style={{
                                border: "none",
                                paddingLeft: "10px",
                                paddingRight: "0px",
                              }}
                            />
                          </div>
                        </td>

                        <td style={{ paddingLeft: "5px" }}>
                          <Form.Control
                            name={`emergencyCodes[${index}].employee_name`}
                            className="form-control"
                            placeholder="Employee Name"
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            disabled={"disabled"}
                            value={code.employee_name ? code.employee_name : ""}
                          />
                        </td>

                        <td style={{ paddingLeft: "5px" }}>
                          <Form.Control
                            as="select"
                            name={`emergencyCodes[${index}].base_location`}
                            className="form-control"
                            placeholder="Base location"
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            disabled={"disabled"}
                            value={code.base_location ? code.base_location : "DEFAULT"}
                          >
                            <option value="DEFAULT" disabled>
                              Select One
                            </option>
                            <option value="1"> La Parrilla (Marietta Square) </option>
                            <option value="2"> La Parrilla (Dallas Hwy) </option>
                            <option value="3"> La Parrilla (Flowery Branch) </option>
                            <option value="4"> La Parrilla (Powder Spring) </option>
                            <option value="5"> La Parrilla (Fayette Ville) </option>
                            <option value="6"> La Parrilla (Alpharetta) </option>
                            <option value="7"> La Parrilla (Woodstock) </option>
                            <option value="9"> La Parrilla (Acworth) </option>
                            <option value="10"> La Parrilla (Newman) </option>
                            <option value="11"> La Parrilla (Mcdonough) </option>
                            <option value="12"> La Parrilla (Howel Mill) </option>
                            <option value="13"> La Parrilla (Dothan) </option>
                            <option value="14"> La Parrilla (Norcross) </option>
                            <option value="15"> La Parrilla (Canton) </option>
                            <option value="16"> La Parrilla (Macon) </option>
                            <option value="17"> La Parrilla (Greenville) </option>
                            <option value="18"> La Parrilla (Toco Hill) </option>
                            <option value="19"> La Parrilla (Savannah) </option>
                            <option value="20"> La Parrilla (Griffin) </option>
                            <option value="21"> La Parrilla (Rome) </option>
                            <option value="23">La Parrilla (Sandy Springs)</option>
                          </Form.Control>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div style={{ marginTop: "60px" }}>
                  <button
                    type="submit"
                    className="btn btn-light mr-2"
                    onClick={() => {
                      handleCancelEmergencyCode(salesReport.report_date, salesReport.owner);
                    }}
                  >
                    Cancel Emergency Code &nbsp;&nbsp;
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmergencyCodeDetails;
