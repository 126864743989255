import React, { useEffect, useState } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { PaymentService } from "../../service/PaymentService";
import { SystemConfigService } from "../../service/configService";
import Swal from "sweetalert2";
import cogoToast from "cogo-toast";
import { ConvertDateFormat } from "../../components/ConvertDateFormat";
import { Utility } from "../../Helpers/utils";
import "moment-timezone";
import moment from "moment";
import { Modal, Button, Form } from "react-bootstrap";
import { Formik } from "formik";

const { SearchBar } = Search;
const user = Utility.getUser();

const StorePendingPayments = () => {
  let { store_id } = useParams();
  const [store, setStore] = useState(null);
  const [pendingPayments, setPendingPayments] = useState(null);
  const [pendingPaymentData, setPendingPaymentData] = useState(null);
  const [paymentTotal, setPaymentTotal] = useState(0);
  const [successfulPayments, setSuccessfulPayments] = useState(null);
  const [failedPayments, setFailedPayments] = useState(null);
  const [madePaymentModal, setMadePaymentModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPartModal, setShowPartModal] = useState(false);
  const [currentPaymentId, setCurrentPaymentId] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  // const [isSubmitting, setIsSubmitting] = useState(false);
  // const [scheduled, setScheduled] = useState(false);

  const roundNumberUp = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  useEffect(() => {
    PaymentService.getPendingPayment(store_id)
      .then((response) => {
        console.log(response.data.data);
        let PendingResArray = [];
        response.data.data.map((res, index) => {
          return PendingResArray.push({
            id: res.id,
            payrollEmployeeId: res.payrollEmployeeId,
            firstName: res.firstName,
            lastName: res.lastName,
            amount: res.amount,
            reportDate: ConvertDateFormat(res.reportDate),
            allocationDate: ConvertDateFormat(res.allocationDate),
            message: res.message,
            code: res.code,
          });
        });
        setPendingPayments(PendingResArray);
      })
      .catch((error) => {
        console.log(error.response.data);
      });

    SystemConfigService.getStore(store_id)
      .then((response) => {
        console.log(response.data.data);
        setStore(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        // cogoToast.error(error.response.data.message, toastoptions);
      });
  }, []);

  const defaultSorted = [
    {
      dataField: "payrollEmployeeId",
      order: "desc",
    },
  ];

  const failedColumn = [
    {
      dataField: "payrollEmployeeId",
      text: "Employee Id",
      sort: true,
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
    },
    {
      dataField: "allocationDate",
      text: "Allocation Date",
      sort: true,
    },
    {
      dataField: "firstName",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "lastName",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "errorMessage",
      text: "Error Message",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: () => {
        return <label className="badge badge-danger">Pending</label>;
      },
    },
  ];

  const successfulColumn = [
    {
      dataField: "payrollEmployeeId",
      text: "Employee Id",
      sort: false,
    },
    {
      dataField: "amount",
      text: "amount",
      sort: true,
    },
    {
      dataField: "allocationDate",
      text: "Allocation Date",
      sort: true,
    },
    {
      dataField: "firstName",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "lastName",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "paymentMessage",
      text: "Payment Message",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: () => {
        return <label className="badge badge-success">Completed</label>;
      },
    },
  ];

  const schedulePayment = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to schedule a payment!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#000000",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        PaymentService.scheduleAPayment(data)
          .then((response) => {
            console.log(response.data.data);
            Swal.fire("Successful!", "Payment has been scheduled.", "success");
            window.location.reload();
          })
          .catch((error) => {
            console.log(error.response.data);
          });
      }
    });
  };

  const sendPartPayment = (row, e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to send a part payment!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#000000",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        // e.target.innerHTML = `<i class="fa fa-spin fa-spinner"></i>Loading...`;
        console.log(row, "This is the row");
        setShowPartModal(true);
        setCurrentData(row);
      }
    });
  };

  const deletePayment = (row, e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete a payment!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#000000",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        // e.target.innerHTML = `<i class="fa fa-spin fa-spinner"></i>Loading...`;

        setShowDeleteModal(true);
        setCurrentPaymentId(row.id);
      }
    });
  };

  const scheduleAllPayment = () => {
    if (pendingPayments.length > 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "You are about to schedule all payments!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#000000",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          PaymentService.scheduleAllPayment(pendingPayments)
            .then((response) => {
              console.log(response.data.data);
              Swal.fire(
                "Successful!",
                "Payment has been scheduled.",
                "success"
              );
              window.location.reload();
            })
            .catch((error) => {
              console.log(error.response.data);
            });
        }
      });
    } else {
      cogoToast.error("No pending payment for this store.");
    }
  };

  const selectItemsForPayment = (e) => {
    let today = moment.tz(new Date(), "America/New_York");
    let paymentData = [];
    let pendingTable = document.getElementById("pending-table");
    let sels = pendingTable.getElementsByTagName("input");
    let payTotal = 0;

    for (let i = 0; i < sels.length; i++) {
      if (sels[i].checked) {
        let row = JSON.parse(sels[i].getAttribute("data-details"));
        payTotal += parseFloat(roundNumberUp(row.amount));
        paymentData.push({
          id: row.id,
          payrollEmployeeId: `${row.payrollEmployeeId}`,
          externalLocationId: store_id ? store_id : "",
          employeeType: "scheduled",
          reportDate: today.format(),
          employeeInfo: {
            firstName: row.firstName,
            lastName: row.lastName,
          },
          paymentType: "Tips",
          payDisbursementDateTime: today.format(),
          allocationDate: today.format("yyyy-MM-DD"),
          amount: parseFloat(roundNumberUp(row.amount)),
        });
      }
      setPendingPaymentData(paymentData);
    }

    setPaymentTotal(payTotal);
  };

  const processAllSelectedPayments = () => {
    console.log(pendingPaymentData);
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to process all selected payment(s). Please note that no further changes can be made to this report. Click yes to proceed!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#000000",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "In progress",
          text: "Please wait...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });

        PaymentService.payPendingTip(pendingPaymentData)
          .then((response) => {
            console.log(response.data.data);

            // setMadePayment(true);
            const instantSuccessRes = response.data.data.content.successes;
            const instantErrorRes = response.data.data.content.errors;

            console.log(instantSuccessRes, instantErrorRes);
            let errorAmount = 0;
            // let totalSuccessAmount = 0;
            let errorPaymentArray = [];
            let successPaymentArray = [];

            if (instantSuccessRes.length > 0) {
              instantSuccessRes.map((val, index) => {
                // totalSuccessAmount += val.amount;
                successPaymentArray.push({
                  _uuid: val._uuid,
                  allocationDate: val.allocatedAt,
                  amount: val.amount,
                  balance: val.balance,
                  firstName: val.raw.firstName,
                  lastName: val.raw.lastName,
                  clientId: val.clientId,
                  locationId: val.locationId,
                  referenceId: val.referenceId,
                  paymentMessage: val.processingResult.message,
                  employeeInfo: {
                    firstName: val.raw.firstName,
                    lastName: val.raw.lastName,
                  },
                  externalLocationId: val.raw.externalLocationId,
                  payDisbursementDateTime: val.processAt,
                  paymentType: "Tips",
                  payrollEmployeeId: val.raw.employeeId,
                  processingResult: {
                    code: val.processingResult.code,
                    message: val.processingResult.message,
                  },
                });
                return successPaymentArray;
              });
            } else {
              console.log("Success is less than 1");
            }
            if (instantErrorRes.length > 0) {
              instantErrorRes.map((val, index) => {
                errorAmount += parseFloat(val.amount);
                errorPaymentArray.push({
                  allocationDate: val.allocatedAt,
                  amount: val.amount,
                  firstName: val.raw.firstName,
                  lastName: val.raw.lastName,
                  employeeInfo: {
                    firstName: val.raw.firstName,
                    lastName: val.raw.lastName,
                  },
                  externalLocationId: val.raw.externalLocationId,
                  payDisbursementDateTime: val.processAt,
                  paymentType: "Tips",
                  payrollEmployeeId: val.raw.employeeId,
                  errorMessage: val.failureReason.message,
                  failureReason: {
                    code: val.failureReason.code,
                    message: val.failureReason.message,
                  },
                });
                return errorPaymentArray;
              });
            } else {
              console.log("Error is less than 1");
            }

            const totalFailedAmount = parseFloat(errorAmount);

            const differencesInTransaction =
              parseFloat(paymentTotal) - parseFloat(totalFailedAmount);

            const paymentHistoryData = {
              successful: successPaymentArray,
              failedPending: errorPaymentArray,
              paymentTotal,
              totalFailedAmount,
              differencesInTransaction,
              storeId: store_id ? store_id : "",
              paid_by_slug: user ? user.slug : null,
            };

            console.log(paymentHistoryData, "This is payment history");

            PaymentService.proccessPendingTipsResponse(paymentHistoryData)
              .then((response) => {
                console.log(response.data.data, "This is the final report");

                let successRes = response.data.data.success;
                let failedRes = response.data.data.failed;

                setFailedPayments(failedRes);
                setSuccessfulPayments(successRes);

                if (successRes.length > 0 && failedRes.length < 1) {
                  setMadePaymentModal(false);
                  Swal.fire({
                    title: "Good Job!",
                    text: "All payments has been scheduled successfully",
                    icon: "success",
                    confirmButtonText: "Ok",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      // window.location.reload();
                    }
                  });
                } else {
                  Swal.close();
                  setMadePaymentModal(true);
                  // window.location.reload();
                }

                // let payBtn = document.getElementById("processPaymentButton");
                // payBtn.disabled = true;
                // payBtn.style.visibility = "hidden";
              })
              .catch((error) => {
                console.log(error.response.data);
                console.log("Processing payment responses failed");
              });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              title: "Error",
              text: "Could not process payment at this time. Please contact the administrator",
              icon: "info",
              confirmButtonText: "Ok",
              allowEscapeKey: false,
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                console.log("redirection should work");
                Swal.close();
              }
            });
          });
      }
    });
  };

  const columns = [
    {
      dataField: "checkbox",
      text: "Option",
      formatter: (cellContent, row) => {
        return (
          <div>
            {user && (user.role === "super admin" || user.role === "hr" || user.role === "accounting") ? (
              <div>
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="stove_option"
                  data-details={JSON.stringify(row)}
                  onChange={(e) => {
                    e.persist();
                    selectItemsForPayment(e);
                  }}
                />

                {/* <button
                  className="btn btn-outline-primary"
                  onClick={(e) => {
                    e.persist();
                    selectItemsForPayment(row, e);
                  }}
                >
                  Delete
                </button> */}
              </div>
            ) : (
              "None"
            )}
          </div>
        );
      },
    },
    {
      dataField: "payrollEmployeeId",
      text: "Employee Id",
      sort: true,
    },
    {
      dataField: "firstName",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "lastName",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
    },
    {
      dataField: "reportDate",
      text: "Report Date",
      sort: true,
    },
    {
      dataField: "allocationDate",
      text: "Allocation Date",
      sort: true,
    },
    {
      dataField: "code",
      text: "Error Code",
      sort: true,
    },
    {
      dataField: "message",
      text: "Message",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <div>
            {user && (user.role === "super admin" || user.role === "hr" || user.role === "accounting") ? (
              <div>
                <button
                  className="btn btn-outline-primary"
                  onClick={(e) => {
                    e.persist();
                    deletePayment(row, e);
                  }}
                >
                  Delete
                </button>
                <button
                  className="btn btn-outline-primary"
                  onClick={(e) => {
                    sendPartPayment(row, e);
                    console.log(row, "This is the row");
                  }}
                >
                  Send Part
                </button>
                <button
                  className="btn btn-outline-primary"
                  onClick={(e) => {
                    schedulePayment(row);
                  }}
                >
                  Schedule
                </button>
              </div>
            ) : (
              "None"
            )}
          </div>
        );
      },
    },
  ];

  const handleModalClose = () => {
    setMadePaymentModal(false);
    window.location.reload();
  };

  return (
    <div>
      <div>
        <p className="pull-right" style={{ textDecoration: "underline" }}>
          <a
            href="https://veltron.document360.io/v1/en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn How &nbsp;<span className="fa fa-question"></span>
          </a>
        </p>

        <div className="clearfix"></div>
      </div>
      <div className="page-header">
        <h3 className="page-title">
          <span style={{ textTransform: "capitalize" }}>Pending Payments</span>
        </h3>
        <div>
          <Link to={"/financials/pending-payments"}>
            <button className="btn btn-primary">
              {" "}
              View All Pending Payments{" "}
            </button>
          </Link>
        </div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/financials/pending-payments">
                <span style={{ textTransform: "capitalize" }}>
                  {" "}
                  {store ? store.name : ""}
                </span>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <span style={{ textTransform: "capitalize" }}>
                Pending Payments
              </span>
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-12">
          {pendingPayments && (
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"> Pending Payment</h4>
                <div className="row">
                  <div className="col-12">
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={pendingPayments}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div className="d-flex align-items-center">
                            <div className="float-left">
                              <p className="mb-2 mr-2">Search in table:</p>
                              <SearchBar {...props.searchProps} />
                            </div>
                            <div className="float-right ml-4">
                              <button
                                className="btn btn-primary"
                                style={{ height: "46px", marginTop: "20px" }}
                                onClick={() => {
                                  scheduleAllPayment();
                                }}
                              >
                                Schedule All
                              </button>

                              {pendingPaymentData &&
                                pendingPaymentData.length > 0 && (
                                  <button
                                    className="btn btn-primary"
                                    style={{
                                      height: "46px",
                                      marginTop: "20px",
                                    }}
                                    onClick={() => {
                                      processAllSelectedPayments();
                                    }}
                                  >
                                    Process Selected Payment(s)
                                  </button>
                                )}
                            </div>

                            <div className="clearfix"></div>
                          </div>
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            loading={true}
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                            id="pending-table"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="row">
        {madePaymentModal && (
          <Modal
            size="lg"
            show={madePaymentModal}
            onHide={() => {
              handleModalClose();
            }}
            aria-labelledby="example-modal-sizes-title-md"
          >
            <div className="">
              <Modal.Header closeButton>
                <Modal.Title>Payment History </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">
                    {successfulPayments && (
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title">
                                {" "}
                                Successful Payment
                                {successfulPayments &&
                                  successfulPayments.length > 1
                                  ? "s"
                                  : ""}
                              </h4>
                              <div className="row">
                                <p>
                                  {" "}
                                  The following list has been scheduled for
                                  payment
                                </p>
                                <div className="col-12">
                                  <ToolkitProvider
                                    keyField="payrollEmployeeId"
                                    bootstrap4
                                    data={successfulPayments}
                                    columns={successfulColumn}
                                    search
                                  >
                                    {(props) => (
                                      <div>
                                        <BootstrapTable
                                          defaultSorted={defaultSorted}
                                          pagination={paginationFactory()}
                                          {...props.baseProps}
                                          wrapperClasses="table-responsive"
                                        />
                                      </div>
                                    )}
                                  </ToolkitProvider>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-md-12">
                    {failedPayments && (
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title">
                                {" "}
                                Failed Payment
                                {failedPayments && failedPayments.length > 1
                                  ? "s"
                                  : ""}{" "}
                                ( Queued as pending)
                              </h4>
                              <div className="row">
                                <p>
                                  {" "}
                                  Someone from accounting will reach out to you
                                </p>
                                <div className="col-12">
                                  <ToolkitProvider
                                    keyField="payrollEmployeeId"
                                    bootstrap4
                                    data={failedPayments}
                                    columns={failedColumn}
                                    search
                                  >
                                    {(props) => (
                                      <div>
                                        <BootstrapTable
                                          defaultSorted={defaultSorted}
                                          pagination={paginationFactory()}
                                          {...props.baseProps}
                                          wrapperClasses="table-responsive"
                                        />
                                      </div>
                                    )}
                                  </ToolkitProvider>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer
                className="flexx-wrap"
                style={{ paddingTop: "0px" }}
              >
                {" "}
                <Button
                  type="submit"
                  className="btn btn-block btn-primary font-weight-medium auth-form-btn"
                  onClick={() => handleModalClose()}
                >
                  Proceed &nbsp;&nbsp;
                  {/* {isSubmitting ? (
                    <span className="fa fa-spin fa-spinner"></span>
                  ) : (
                    ""
                  )} */}
                </Button>
                <Button variant="light m-2" onClick={() => handleModalClose()}>
                  Cancel
                </Button>
              </Modal.Footer>
            </div>
          </Modal>
        )}
      </div>

      <div className="row">
        {showDeleteModal && (
          <div>
            <Formik
              initialValues={{
                id: currentPaymentId ? currentPaymentId : "",
                archived_message: "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                console.log(values);

                PaymentService.deleteAPendingPayment(values)
                  .then((response) => {
                    console.log(response.data);
                    Swal.fire({
                      title: "Successful!",
                      text: response.data.message,
                      icon: "success",
                      allowOutsideClick: false,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        window.location.reload();
                      }
                    });
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <Modal
                  show={showDeleteModal}
                  onHide={() => setShowDeleteModal(false)}
                  aria-labelledby="example-modal-sizes-title-md"
                  style={{ top: "200px" }}
                  backdrop="static"
                >
                  <form name="form" onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                      <Modal.Title> Delete Pending Payment </Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ paddingBottom: "0px" }}>
                      <Form.Group>
                        <label htmlFor="archived_message">Reason</label>

                        <Form.Control
                          type="text"
                          as="textarea"
                          rows="6"
                          cols="50"
                          className="form-control"
                          id="archived_message"
                          name="archived_message"
                          value={values.archived_message}
                          onChange={handleChange}
                          disabled={isSubmitting}
                        ></Form.Control>
                      </Form.Group>
                    </Modal.Body>

                    <Modal.Footer
                      className="flex-wrap"
                      style={{ paddingTop: "0px" }}
                    >
                      {" "}
                      <Button
                        type="submit"
                        className="btn btn-block btn-primary font-weight-medium auth-form-btn"
                        disabled={isSubmitting}
                      >
                        Proceed &nbsp;&nbsp;
                        {isSubmitting ? (
                          // <Spinner animation="border" size="sm" />
                          <span className="fa fa-spin fa-spinner"></span>
                        ) : (
                          ""
                        )}
                      </Button>
                      <Button
                        variant="light m-2"
                        onClick={() => setShowDeleteModal(false)}
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </form>
                </Modal>
              )}
            </Formik>
          </div>
        )}
      </div>

      <div className="row">
        {showPartModal && (
          <div>
            <Formik
              initialValues={{
                original_details: currentData ? currentData : "",
                new_amount: "",
                original_amount: currentData ? currentData.amount : "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                console.log(values);

                if (values.new_amount) {
                  let today = moment.tz(new Date(), "America/New_York");
                  let paymentData = {
                    payrollEmployeeId: `${values.original_details.payrollEmployeeId}`,
                    externalLocationId: store_id,
                    employeeType: "scheduled",
                    reportDate: `${values.original_details.reportDate}`,
                    employeeInfo: {
                      firstName: `${values.original_details.firstName}`,
                      lastName: `${values.original_details.lastName}`,
                    },
                    paymentType: "Tips",
                    payDisbursementDateTime: today.format(),
                    allocationDate: today.format("yyyy-MM-DD"),
                    amount: parseFloat(roundNumberUp(`${values.new_amount}`)),
                  };

                  let data = {
                    paymentData,
                    values,
                  };

                  PaymentService.sendPartPayment(data)
                    .then((response) => {
                      console.log(response.data);
                      setSubmitting(false);
                      Swal.fire({
                        title: "Successful!",
                        text: response.data.message,
                        icon: "success",
                        allowOutsideClick: false,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          window.location.reload();
                        }
                      });
                    })
                    .catch((error) => {
                      console.log(error.response.data.message);
                      setSubmitting(false);
                      Swal.fire({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        allowOutsideClick: false,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          Swal.close();
                        }
                      });
                    });
                } else {
                  setSubmitting(false);
                  Swal.fire({
                    title: "Error!",
                    text: "Please add the new amount",
                    icon: "error",
                    allowOutsideClick: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.close();
                    }
                  });
                }
              }}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <Modal
                  show={showPartModal}
                  onHide={() => setShowPartModal(false)}
                  aria-labelledby="example-modal-sizes-title-md"
                  style={{ top: "200px" }}
                  backdrop="static"
                >
                  <form name="form" onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                      <Modal.Title> Send Part </Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ paddingBottom: "0px" }}>
                      <Form.Group>
                        <label htmlFor="original_amount">Original Amount</label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="original_amount"
                          placeholder="Original Amount"
                          name="original_amount"
                          value={values.original_amount}
                          onChange={handleChange}
                          disabled={"disabled"}
                          autoComplete="off"
                          autoCorrect="off"
                          autoCapitalize="off"
                          spellCheck="false"
                        />
                      </Form.Group>

                      <Form.Group>
                        <label htmlFor="new_amount">
                          Part Payment <span className="text-danger"> * </span>
                        </label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="new_amount"
                          placeholder="Part Payment"
                          name="new_amount"
                          value={values.new_amount}
                          onChange={handleChange}
                          disabled={isSubmitting}
                          autoComplete="off"
                          autoCorrect="off"
                          autoCapitalize="off"
                          spellCheck="false"
                        />
                      </Form.Group>
                    </Modal.Body>

                    <Modal.Footer
                      className="flex-wrap"
                      style={{ paddingTop: "0px" }}
                    >
                      {" "}
                      <Button
                        type="submit"
                        className="btn btn-block btn-primary font-weight-medium auth-form-btn"
                        disabled={isSubmitting}
                      >
                        Proceed &nbsp;&nbsp;
                        {isSubmitting ? (
                          // <Spinner animation="border" size="sm" />
                          <span className="fa fa-spin fa-spinner"></span>
                        ) : (
                          ""
                        )}
                      </Button>
                      <Button
                        variant="light m-2"
                        onClick={() => setShowPartModal(false)}
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </form>
                </Modal>
              )}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(StorePendingPayments);
