export const calculateExpensesTotal = (expenses) => {
  const expenseKeys = [
    "auto_truck",
    "cleaning",
    "food_purchases",
    "office_supplies",
    "repair",
    "rest_supplies",
    "gift_promotion",
    "emergency_code_expenses",
    "other",
  ];

  return expenseKeys.reduce((total, key) => total + parseFloat(expenses[key] || 0), 0);
};
