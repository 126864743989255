const StoreName = [
  {
    id: 1,
    name: "La Parrilla (Marietta Square)",
  },
  {
    id: 2,
    name: "La Parrilla (Dallas Hwy)",
  },
  {
    id: 3,
    name: "La Parrilla (Flowery Branch)",
  },
  {
    id: 4,
    name: "La Parrilla (Powder Spring)",
  },
  {
    id: 5,
    name: "La Parrilla (Fayette Ville)",
  },
  {
    id: 6,
    name: "La Parrilla (Alpharetta)",
  },
  {
    id: 7,
    name: "La Parrilla (Woodstock)",
  },
  {
    id: 9,
    name: "La Parrilla (Acworth)",
  },
  {
    id: 10,
    name: "La Parrilla (Newman)",
  },
  {
    id: 11,
    name: "La Parrilla (Mcdonough)",
  },
  {
    id: 12,
    name: "La Parrilla (Howel Mill)",
  },
  {
    id: 13,
    name: "La Parrilla (Dothan)",
  },
  {
    id: 14,
    name: "La Parrilla (Norcross)",
  },
  {
    id: 15,
    name: "La Parrilla (Canton)",
  },
  {
    id: 16,
    name: "La Parrilla (Macon)",
  },
  {
    id: 17,
    name: "La Parrilla (Greenville)",
  },
  {
    id: 18,
    name: "La Parrilla (Toco Hill)",
  },
  {
    id: 19,
    name: "La Parrilla (Savannah)",
  },
  {
    id: 20,
    name: "La Parrilla (Griffin)",
  },
  {
    id: 21,
    name: "La Parrilla (Rome)",
  },
  {
    id: 23,
    name: "La Parrilla (Sandy Springs)",
  },
];

export default StoreName;
