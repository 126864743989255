import React from "react";
// import DepositForm from "./DepositForm";
import { DepositForm } from "./DepositForm";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

const roundNumberUp = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2);
};

const DepositFormSection = ({
  salesReport,
  cashDepositAvailable,
  cashDepositData,
  onSubmit,
  initialValues,
  handleCancelDepositData,
  alohaDeposit,
  totalExpenses,
  depositCalculated,
  editDepositMode,
  enterOverageOrShort,
  overOrShort,
  setEnterOverageOrShort,
  setOverOrShort,
  setEditDepositMode,
  setCashDepositAvailable,
}) => (
  <div>
    {salesReport ? (
      <div className="mt-5">
        {!cashDepositAvailable ? (
          <DepositForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            alohaDeposit={alohaDeposit}
            totalExpenses={totalExpenses}
            depositCalculated={depositCalculated}
            editDepositMode={editDepositMode}
            enterOverageOrShort={enterOverageOrShort}
            overOrShort={overOrShort}
            setEnterOverageOrShort={setEnterOverageOrShort}
            setOverOrShort={setOverOrShort}
            setEditDepositMode={setEditDepositMode}
          />
        ) : (
          <Formik
            initialValues={{}}
            onSubmit={(values, { setSubmitting }) => {}}
            enableReinitialize={true}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <div>
                <div className="depositForm">
                  <div>
                    <div>
                      <h3 className="mb-4">Deposit</h3>
                    </div>

                    <div>
                      <div className="form-inline" id="form-inline">
                        <div className="col-md-3">
                          <label htmlFor="aloha_deposit">Aloha Deposit</label>
                          <Form.Group>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                              </div>
                              <Form.Control
                                type="number"
                                className="form-control"
                                id="aloha_deposit"
                                name="aloha_deposit"
                                value={cashDepositData ? cashDepositData.aloha_deposit : ""}
                                placeholder="Enter amount"
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                disabled={"disabled"}
                              />
                            </div>
                          </Form.Group>
                        </div>
                        -
                        <div className="col-md-3">
                          <label className="" htmlFor="total_expenses">
                            Total Expenses
                          </label>
                          <Form.Group>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                              </div>
                              <Form.Control
                                type="number"
                                className="form-control"
                                id="total_expenses"
                                name="total_expenses"
                                value={cashDepositData ? cashDepositData.total_expenses : ""}
                                placeholder="Enter amount"
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                disabled={"disabled"}
                              />
                            </div>
                          </Form.Group>
                        </div>
                        =
                        <div className="col-md-4">
                          <label className="" htmlFor="sales_deposit_calculated">
                            Total Deposit (Calculated)
                          </label>
                          <Form.Group>
                            <div className="input-group" id="total_deposit_cal">
                              <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                              </div>
                              <Form.Control
                                type="number"
                                className="form-control"
                                id="sales_deposit_calculated"
                                name="sales_deposit_calculated"
                                value={
                                  cashDepositData ? cashDepositData.total_deposit_calculated : ""
                                }
                                placeholder="Enter amount"
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                disabled={"disabled"}
                              />
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </div>

                    <table className="table table-bordered mt-5">
                      <tbody>
                        <tr>
                          <td style={{ width: "50%" }}>
                            <div>
                              <label htmlFor="cash_to_deposit">Cash to be Deposit</label>
                              <Form.Group>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                  </div>
                                  <Form.Control
                                    type="number"
                                    className="form-control"
                                    id="cash_to_deposit"
                                    name="cash_to_deposit"
                                    value={cashDepositData ? cashDepositData.cash_to_deposit : ""}
                                    placeholder="Enter amount"
                                    step="any"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                    disabled={"disabled"}
                                  />
                                </div>
                              </Form.Group>
                            </div>
                          </td>

                          <td style={{ width: "50%" }}>
                            {cashDepositData && cashDepositData.overage && (
                              <div>
                                <label htmlFor="overage">Over</label>
                                <Form.Group>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">$</span>
                                    </div>
                                    <Form.Control
                                      type="number"
                                      className="form-control"
                                      id="overage"
                                      name="overage"
                                      value={roundNumberUp(cashDepositData.overage)}
                                      placeholder="Enter amount"
                                      autoComplete="off"
                                      autoCorrect="off"
                                      autoCapitalize="off"
                                      spellCheck="false"
                                      disabled={"disabled"}
                                    />
                                  </div>
                                </Form.Group>
                              </div>
                            )}

                            {cashDepositData && cashDepositData.shortage && (
                              <div>
                                <label htmlFor="shortage">Shortage</label>
                                <Form.Group>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">$</span>
                                    </div>
                                    <Form.Control
                                      type="number"
                                      className="form-control"
                                      id="shortage"
                                      name="shortage"
                                      value={roundNumberUp(cashDepositData.shortage)}
                                      placeholder="Enter amount"
                                      autoComplete="off"
                                      autoCorrect="off"
                                      autoCapitalize="off"
                                      spellCheck="false"
                                      disabled={"disabled"}
                                    />
                                  </div>
                                </Form.Group>
                              </div>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {cashDepositData && cashDepositData.overage && (
                    <div className="mt-3">
                      <p>Please explain the over of the deposit amount</p>
                      <Form.Group>
                        <Form.Control
                          as="textarea"
                          rows="3"
                          type="text"
                          className="form-control"
                          id="reason_for_overage"
                          name="reason_for_overage"
                          value={cashDepositData.explanations}
                          placeholder="Please Explain"
                          autoComplete="off"
                          autoCorrect="off"
                          autoCapitalize="off"
                          spellCheck="false"
                          disabled={"disabled"}
                        />
                      </Form.Group>
                    </div>
                  )}

                  {cashDepositData && cashDepositData.shortage && (
                    <div className="mt-3">
                      <p>Please explain the short of the deposit amount</p>
                      <Form.Group>
                        <Form.Control
                          as="textarea"
                          rows="3"
                          type="text"
                          className="form-control"
                          id="reason_for_shortage"
                          name="reason_for_shortage"
                          value={cashDepositData.explanations}
                          placeholder="Please Explain"
                          autoComplete="off"
                          autoCorrect="off"
                          autoCapitalize="off"
                          spellCheck="false"
                          disabled={"disabled"}
                        />
                      </Form.Group>
                    </div>
                  )}
                </div>

                {cashDepositData && (
                  <div className="mt-3">
                    <button
                      type="submit"
                      className="btn btn-light"
                      onClick={() => {
                        handleCancelDepositData(
                          cashDepositData.report_date,
                          cashDepositData.store_id
                        );
                        setEditDepositMode(false);
                        setCashDepositAvailable(false);
                      }}
                    >
                      <i className="mdi mdi-file-check btn-icon-prepend"></i>
                      Cancel Deposit Details
                    </button>
                  </div>
                )}
              </div>
            )}
          </Formik>
        )}
      </div>
    ) : (
      <div>You are yet to upload a Sales Report</div>
    )}
  </div>
);

export default DepositFormSection;
