import React, { useEffect, useState } from "react";
import cogoToast from "cogo-toast";
import { Formik } from "formik";
import { ExpensesService } from "../../../service/ExpenseService";
import DepositAlert from "./components/DepositAlert";
import ExpensesHeader from "./components/ExpensesHeader";
import { calculateExpensesTotal } from "./utils/calculateExpensesTotal";
import CreateExpensesModal from "./components/modals/CreateExpensesModal";
import ShowExpensesForm from "./components/ShowExpensesForm";
import DepositFormSection from "./components/DepositFormSection";

const ExpensesStage = () => {
  const [salesReport, setSalesReport] = useState(null);
  const [expenses, setExpenses] = useState(null);
  const [createExpensesModal, setCreateExpensesModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [depositCalculated, setDepositCalculated] = useState("");
  const [alohaDeposit, setAlohaDeposit] = useState("");
  const [totalExpenses, setTotalExpenses] = useState("");
  const [overOrShort, setOverOrShort] = useState("");
  const [enterOverageOrShort, setEnterOverageOrShort] = useState(false);
  const [editDepositMode, setEditDepositMode] = useState(false);
  const [showDepositAlert, setShowDepositAlert] = useState(false);
  const [depositAlertMessage, setDepositAlertMessage] = useState("");
  const [cashDepositData, setCashDepositData] = useState(null);
  const [cashDepositAvailable, setCashDepositAvailable] = useState(false);
  const [addExpensesDetails, setAddExpensesDetails] = useState(false);

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  const roundNumberUp = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  const enableExpensesDetailsField = () => {
    setAddExpensesDetails(true);
  };

  const handleCancelDepositData = async (report_date, store_id) => {
    const data = { report_date, store_id };

    try {
      const cancelResponse = await ExpensesService.cancelDepositData(data);
      console.log(cancelResponse.data.data);

      resetState();

      const salesReport = getSalesReportFromLocalStorage();
      if (salesReport) {
        await handleSalesReportData(salesReport);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Resets the state values to their initial state
  const resetState = () => {
    setCashDepositData(null);
    setOverOrShort("");
    setEditDepositMode(false);
    setCashDepositAvailable(false);
  };

  // Retrieves the sales report from localStorage
  const getSalesReportFromLocalStorage = () => {
    const salesReport = localStorage.getItem("salesReport");
    return salesReport ? JSON.parse(salesReport) : null;
  };

  // Handles updating state and fetching new data based on the sales report
  const handleSalesReportData = async (salesReport) => {
    try {
      setSalesReport(salesReport);
      setAlohaDeposit(roundNumberUp(parseFloat(salesReport.deposit_calculated)));

      // Fetch and update deposit data
      const depositData = await fetchDepositData(salesReport);
      if (depositData) {
        setCashDepositData(depositData);
        setCashDepositAvailable(true);
        setEditDepositMode(true);
      }

      // Fetch and update expenses
      const expenses = await fetchExpensesForDate(salesReport);
      updateExpensesAndCalculatedDeposit(expenses, salesReport);
    } catch (error) {
      console.error(error);
    }
  };

  // Fetches deposit data
  const fetchDepositData = async (salesReport) => {
    try {
      const response = await ExpensesService.getDepositData(
        salesReport.owner,
        salesReport.report_date
      );
      console.log(response.data.data);
      return response.data.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  // Fetches expenses for a given date
  const fetchExpensesForDate = async (salesReport) => {
    try {
      const response = await ExpensesService.getExpensesForADate(
        salesReport.report_date,
        salesReport.owner
      );
      return response.data.data || {};
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  // Updates state based on fetched expenses and sales report data
  const updateExpensesAndCalculatedDeposit = (expenses, salesReport) => {
    const totalExpenses = calculateTotalExpenses(expenses);
    setExpenses(expenses);
    setTotalExpenses(roundNumberUp(totalExpenses));
    setDepositCalculated(
      roundNumberUp(parseFloat(salesReport.deposit_calculated) - parseFloat(totalExpenses))
    );

    updateUIForTotalDeposit();
    localStorage.setItem("expenses", JSON.stringify(expenses));
  };

  // Calculates the total expenses from the server response
  const calculateTotalExpenses = (serverRes) => {
    const expenseKeys = [
      "auto_truck",
      "cleaning",
      "food_purchases",
      "office_supplies",
      "repair",
      "rest_supplies",
      "gift_promotion",
      "emergency_code_expenses",
      "other",
    ];

    return expenseKeys.reduce((total, key) => {
      return total + parseFloat(serverRes[key] || 0);
    }, 0);
  };

  // Updates the UI for the total deposit calculation
  const updateUIForTotalDeposit = () => {
    const totalDepositElement = document.getElementById("total_deposit_cal");
    if (totalDepositElement) {
      totalDepositElement.classList.add("instant_pay_total_group");

      setTimeout(() => {
        totalDepositElement.classList.remove("instant_pay_total_group");
      }, 2000);
    }
  };

  const handleCreateExpenses = (values, { setSubmitting }) => {
    setSubmitting(true);

    if (values.store_id) {
      const data = new FormData();
      Object.entries(values).forEach(([key, value]) => data.append(key, value));

      ExpensesService.createExpenses(data)
        .then((response) => {
          const serverResponse = response.data.data;
          setSubmitting(false);
          setCreateExpensesModal(false);
          setExpenses(serverResponse);

          const expensesTotal = calculateExpensesTotal(serverResponse);

          setDepositCalculated(
            roundNumberUp(parseFloat(salesReport.deposit_calculated) - parseFloat(expensesTotal))
          );

          setEditDepositMode(false);
          setCashDepositAvailable(false);
          localStorage.removeItem("cashDepositData");
        })
        .catch((error) => {
          console.log(error);
          cogoToast.error(error.response.data.message, toastoptions);
        })
        .finally(() => setSubmitting(false));
    }
  };

  const handleEditExpenses = async (values, { setSubmitting }) => {
    try {
      const response = await ExpensesService.editExpenses(values);
      const serverRes = response.data.data;
      setEditMode(false);
      setExpenses(serverRes);
      const expensesTotal = calculateExpensesTotal(serverRes);

      if (cashDepositData) {
        handleCancelDepositData(cashDepositData.report_date, cashDepositData.store_id);
        setEditDepositMode(false);
        setCashDepositAvailable(false);
      }
      setEditDepositMode(false);
      setCashDepositAvailable(false);

      setAlohaDeposit(roundNumberUp(salesReport.deposit_calculated));
      setTotalExpenses(roundNumberUp(expensesTotal));

      setDepositCalculated(
        roundNumberUp(parseFloat(salesReport.deposit_calculated) - parseFloat(expensesTotal))
      );

      if (
        typeof document.getElementById("total_deposit_cal") != "undefined" &&
        document.getElementById("total_deposit_cal") != null
      ) {
        document.getElementById("total_deposit_cal").classList.add("instant_pay_total_group");

        setTimeout(() => {
          document.getElementById("total_deposit_cal").classList.remove("instant_pay_total_group");
        }, [2000]);
      }

      cogoToast.success("Updated successfully", toastoptions);
    } catch (error) {
      console.log(error);
    }
  };

  const depositDataInitialValues = (salesReport) => ({
    store_id: salesReport ? salesReport.owner : "",
    date: salesReport ? salesReport.report_date : "",
    deposit_cal: depositCalculated ? depositCalculated : 0,
    cash_to_deposit: "",
    reason_for_short_or_over: "",
    aloha_deposit: alohaDeposit,
    total_expenses: totalExpenses,
    sales_deposit_calculated: depositCalculated,
  });

  const handleDepositDataForm = (values, { setSubmitting }) => {
    setSubmitting(true);

    if (values.cash_to_deposit) {
      if (
        parseFloat(values.cash_to_deposit) != roundNumberUp(parseFloat(depositCalculated)) &&
        !values.reason_for_short_or_over
      ) {
        setSubmitting(false);
        setShowDepositAlert(true);

        setDepositAlertMessage(
          `Please explain the ${
            overOrShort && overOrShort < 0 ? " shortage " : " overage "
          } of the deposit amount`
        );
      } else {
        // console.log(values);
        const cashDepositData = {
          deposit_calculated: depositCalculated ? roundNumberUp(parseFloat(depositCalculated)) : 0,
          cash_to_deposit: parseFloat(roundNumberUp(values.cash_to_deposit)),
          reason_for_short_or_over: values.reason_for_short_or_over,
          aloha_deposit: alohaDeposit ? parseFloat(roundNumberUp(alohaDeposit)) : 0,
          total_expenses: totalExpenses ? parseFloat(roundNumberUp(totalExpenses)) : 0,
          sales_deposit_calculated: depositCalculated
            ? parseFloat(roundNumberUp(depositCalculated))
            : 0,
          over_or_short:
            parseFloat(roundNumberUp(values.cash_to_deposit)) !==
            parseFloat(roundNumberUp(depositCalculated))
              ? overOrShort
              : "",
          store_id: salesReport ? salesReport.owner : "",
          date: salesReport ? salesReport.report_date : "",
        };

        ExpensesService.createDepositData(cashDepositData)
          .then((response) => {
            console.log(response.data.data);
            setCashDepositData(response.data.data);
            setCashDepositAvailable(true);
            setEditDepositMode(true);
            setSubmitting(false);
          })
          .catch((error) => {
            console.log(error);
            setSubmitting(false);
          });
      }
    } else {
      setShowDepositAlert(true);
      setDepositAlertMessage("Please enter cash to deposit");
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const enableButton = () => {
      const btn = document.querySelector(".progress_btn");
      if (btn && btn.disabled) {
        btn.disabled = false;
      }
    };

    const fetchCashDepositData = () => {
      const cashDepositData = localStorage.getItem("cashDepositData");
      if (cashDepositData) {
        const parsedData = JSON.parse(cashDepositData);
        console.log(parsedData);
        setCashDepositData(parsedData);
        setCashDepositAvailable(true);
      }
    };

    const fetchSalesReportData = async () => {
      const salesReport = localStorage.getItem("salesReport");
      if (salesReport) {
        const parsedReport = JSON.parse(salesReport);
        setSalesReport(parsedReport);
        setAlohaDeposit(roundNumberUp(parseFloat(parsedReport.deposit_calculated)));

        try {
          const depositDataResponse = await ExpensesService.getDepositData(
            parsedReport.owner,
            parsedReport.report_date
          );
          if (depositDataResponse.data.data) {
            setCashDepositData(depositDataResponse.data.data);
            setCashDepositAvailable(true);
            setEditDepositMode(true);
          }
        } catch (error) {
          console.error("Error fetching deposit data:", error);
        }

        try {
          const expensesResponse = await ExpensesService.getExpensesForADate(
            parsedReport.report_date,
            parsedReport.owner
          );
          if (expensesResponse.data.data) {
            updateExpensesAndDeposit(expensesResponse.data.data, parsedReport);
          } else {
            setTotalExpenses(roundNumberUp(0));
            setDepositCalculated(
              roundNumberUp(parseFloat(parsedReport.deposit_calculated) - parseFloat(0))
            );
          }
        } catch (error) {
          console.error("Error fetching expenses:", error);
        }
      }
    };

    const updateExpensesAndDeposit = (serverRes, salesReport) => {
      const totalExpenses = calculateTotalExpenses(serverRes);
      setExpenses(serverRes);
      setTotalExpenses(roundNumberUp(totalExpenses));
      setDepositCalculated(
        roundNumberUp(parseFloat(salesReport.deposit_calculated) - parseFloat(totalExpenses))
      );
      updateUIForTotalDeposit();
      localStorage.setItem("expenses", JSON.stringify(serverRes));
    };

    const calculateTotalExpenses = (serverRes) => {
      const expenseKeys = [
        "auto_truck",
        "cleaning",
        "food_purchases",
        "office_supplies",
        "repair",
        "rest_supplies",
        "gift_promotion",
        "emergency_code_expenses",
        "other",
      ];

      return expenseKeys.reduce((total, key) => {
        return total + parseFloat(serverRes[key] || 0);
      }, 0);
    };

    const updateUIForTotalDeposit = () => {
      const totalDepositElement = document.getElementById("total_deposit_cal");
      if (totalDepositElement) {
        totalDepositElement.classList.add("instant_pay_total_group");
        setTimeout(() => {
          totalDepositElement.classList.remove("instant_pay_total_group");
        }, 2000);
      }
    };

    const preventNumberScroll = () => {
      document.addEventListener("wheel", (event) => {
        const activeElement = document.activeElement;
        if (activeElement.type === "number" && activeElement.classList.contains("noscroll")) {
          activeElement.blur();
        }
      });
    };

    enableButton();
    fetchCashDepositData();
    fetchSalesReportData();
    preventNumberScroll();
  }, [depositCalculated]);

  return (
    <section className="content">
      <div>
        <ExpensesHeader
          totalExpenses={totalExpenses}
          setCreateExpensesModal={setCreateExpensesModal}
          salesReport={salesReport}
        />
        <DepositAlert
          showDepositAlert={showDepositAlert}
          depositAlertMessage={depositAlertMessage}
          setShowDepositAlert={setShowDepositAlert}
        />
        <div className="clearfix"></div>
        {createExpensesModal && (
          <div style={{ width: "630px" }}>
            <Formik
              initialValues={{
                store_id: salesReport ? parseInt(salesReport.owner) : "",
                store: salesReport?.location || "",
                date: salesReport?.report_date || "",
                manager_name: salesReport?.printed_by || "",
                auto_truck: "",
                cleaning: "",
                food_purchases: "",
                office_supplies: "",
                repair: "",
                rest_supplies: "",
                gift_promotion: "",
                other: "",
                expenses_details: "",
              }}
              enableReinitialize={true}
              onSubmit={handleCreateExpenses}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <CreateExpensesModal
                  initialValues={values}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  createExpensesModal={createExpensesModal}
                  setCreateExpensesModal={setCreateExpensesModal}
                  enableExpensesDetailsField={enableExpensesDetailsField}
                  addExpensesDetails={addExpensesDetails}
                />
              )}
            </Formik>
          </div>
        )}

        <ShowExpensesForm
          totalExpenses={totalExpenses}
          expenses={expenses}
          editMode={editMode}
          setEditMode={setEditMode}
          handleEditExpenses={handleEditExpenses}
        />
      </div>

      <div>
        <DepositFormSection
          salesReport={salesReport}
          cashDepositAvailable={cashDepositAvailable}
          cashDepositData={cashDepositData}
          onSubmit={handleDepositDataForm}
          initialValues={depositDataInitialValues(salesReport)}
          handleCancelDepositData={handleCancelDepositData}
          alohaDeposit={alohaDeposit}
          totalExpenses={totalExpenses}
          depositCalculated={depositCalculated}
          editDepositMode={editDepositMode}
          enterOverageOrShort={enterOverageOrShort}
          overOrShort={overOrShort}
          setEnterOverageOrShort={setEnterOverageOrShort}
          setOverOrShort={setOverOrShort}
          setEditDepositMode={setEditDepositMode}
          setCashDepositAvailable={setCashDepositAvailable}
        />
      </div>
    </section>
  );
};

export default ExpensesStage;
