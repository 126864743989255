import React from "react";
import { Formik } from "formik";
import ExpensesTableRow from "./ExpensesTableRow";

const ShowExpensesForm = ({
  totalExpenses,
  expenses,
  editMode,
  setEditMode,
  handleEditExpenses,
}) => {
  if (!totalExpenses || totalExpenses <= 0) {
    return (
      <div
        style={{
          height: "180px",
          border: "2px dotted #f5f5f5",
          textAlign: "center",
        }}
      >
        <h4 style={{ lineHeight: "180px" }}>
          You are either yet to provide the details of expenses or uploaded the Sales Report for
          today
        </h4>
      </div>
    );
  }

  // Utility to prepare initial values for Formik
  const getInitialValues = () => {
    const defaultValues = {
      id: "",
      store_id: "",
      store: "",
      date: "",
      manager_name: "",
      auto_truck: "",
      auto_truck_receipt_url: [],
      cleaning: "",
      cleaning_receipt_url: [],
      food_purchases: "",
      food_purchases_receipt_url: [],
      office_supplies: "",
      office_supplies_receipt_url: [],
      repair: "",
      repair_receipt_url: [],
      rest_supplies: "",
      rest_supplies_receipt_url: [],
      gift_promotion: "",
      gift_promotion_receipt_url: [],
      emergency_code_expenses: "",
      other: "",
      expenses_details: "",
      other_receipt_url: [],
    };
    return expenses
      ? {
          ...defaultValues,
          ...expenses,
          store_id: parseInt(expenses.store_id) || "",
        }
      : defaultValues;
  };

  return (
    <div>
      <Formik
        initialValues={getInitialValues()}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          console.log("Clicked");
          setSubmitting(true);
          handleEditExpenses(values, setSubmitting);
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <div className="table-responsive">
            <form className="expensesForm" onSubmit={handleSubmit}>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th style={{ width: "40%" }}>Expenses</th>
                    <th style={{ width: "40%" }}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {[
                    "auto_truck",
                    "cleaning",
                    "food_purchases",
                    "office_supplies",
                    "repair",
                    "rest_supplies",
                    "gift_promotion",
                    "emergency_code_expenses",
                    "other",
                  ].map((key) => (
                    <ExpensesTableRow
                      key={key}
                      name={key}
                      label={key.replace(/_/g, " ")}
                      value={values[key]}
                      handleChange={handleChange}
                      editMode={editMode}
                    />
                  ))}
                  {values.other !== "" && (
                    <tr>
                      <td>Expenses Details</td>
                      <td>
                        <textarea
                          rows="6"
                          className="form-control"
                          id="expenses_details"
                          name="expenses_details"
                          onChange={handleChange}
                          value={values.expenses_details || ""}
                          disabled={!editMode}
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <div className="mt-5">
                {editMode && (
                  <>
                    <button
                      type="submit"
                      className="btn btn-primary btn-icon-text"
                      disabled={isSubmitting}
                    >
                      Submit Expenses
                    </button>
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => setEditMode(false)}
                    >
                      Cancel
                    </button>
                  </>
                )}
              </div>
            </form>
            <div className="mt-3">
              {expenses && !editMode && (
                <div className="">
                  <button
                    type="button"
                    className="btn btn-dark btn-icon-text"
                    onClick={() => {
                      setEditMode(true);
                    }}
                  >
                    {" "}
                    Edit Expenses <i className="mdi mdi-file-check btn-icon-append"></i>
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default ShowExpensesForm;
