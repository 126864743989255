import React from "react";

const BonusReviews = ({ quarter }) => {
  return (
    <div style={{ marginTop: "40px" }}>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th style={{ width: "40%" }}> Reviews </th>
            <th style={{ width: "60%" }}>
              {" "}
              <b className="totalColumns">Q{quarter}</b>{" "}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ background: "grey", color: "white" }}>
            <td> Sales </td>

            <td></td>
          </tr>
          <tr>
            <td> Score </td>

            <td className="amountRow">33</td>
          </tr>
          <tr>
            <td> Average Rating </td>

            <td className="amountRow">62</td>
          </tr>

          <tr>
            <td> Total Reviews </td>

            <td className="amountRow">96</td>
          </tr>

          <tr>
            <td> Response Rate </td>

            <td className="amountRow">36</td>
          </tr>

          <tr>
            <td> Response Time </td>

            <td className="amountRow">85</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BonusReviews;
