import cogoToast from "cogo-toast";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { MaintenanceReportService } from "../../../service/MaintenanceReportService";

const AllReports = () => {
  const [reports, setReports] = useState(null);

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  useEffect(() => {
    let data = {
      duration: "today",
      status: "opened",
    };
    MaintenanceReportService.getAllOpenedReport(data)
      .then((response) => {
        setReports(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <p className="card-description">
                Please select your preferred search term and hit the{" "}
                <b>Submit</b> button to proceed.
              </p>
              <Formik
                initialValues={{
                  duration: "today",
                  status: "opened",
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  MaintenanceReportService.getAllOpenedReport(values)
                    .then((response) => {
                      setReports(response.data.data);
                      setSubmitting(false);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form className="form-inline" onSubmit={handleSubmit}>
                    <div>
                      <Form.Control
                        type="text"
                        as="select"
                        className="form-control"
                        id="duration"
                        name="duration"
                        defaultValue={
                          values.duration ? values.duration : "today"
                        }
                        onChange={handleChange}
                        disabled={isSubmitting}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        style={{ width: "250px", margin: "0px 10px" }}
                      >
                        <option value="DEFAULT" disabled>
                          Select Duration
                        </option>
                        <option value="all"> ALL </option>
                        <option value="today"> TODAY </option>
                        <option value="yesterday"> YESTERDAY </option>
                        <option value="this week"> THIS WEEK </option>
                        <option value="this month"> THIS MONTH </option>
                      </Form.Control>
                    </div>

                    <div>
                      <Form.Control
                        type="text"
                        as="select"
                        className="form-control"
                        id="status"
                        name="status"
                        defaultValue={values.status ? values.status : "opened"}
                        onChange={handleChange}
                        disabled={isSubmitting}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        style={{ width: "250px", margin: "0px 10px" }}
                      >
                        <option value="DEFAULT" disabled>
                          Select Status
                        </option>
                        <option value="opened"> OPENED </option>
                        <option value="closed"> CLOSED </option>
                      </Form.Control>
                    </div>

                    <button type="submit" className="btn btn-primary mb-2">
                      Submit &nbsp;&nbsp;
                      {isSubmitting ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        ""
                      )}
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <div>
        {reports && (
          <div>
            {Object.entries(reports).map(([key, value]) => (
              <div key={key}>
                <h4> {key}</h4>
                <ul className="bullet-line-list maintenance-report-list">
                  {value.map((rep, index) => (
                    <li key={index}>
                      <div
                        style={
                          rep.priority === "high"
                            ? {
                                padding: "10px",
                                border: "2px dotted",
                                margin: "10px 0px",
                                borderColor: "red",
                                backgroundColor: "antiquewhite",
                              }
                            : {
                                padding: "10px",
                                border: "2px dotted",
                                margin: "10px 0px",
                              }
                        }
                      >
                        <h6 className="text-dark">
                          {rep.issue_type === "ei" && (
                            <span
                              className="text-primary"
                              style={{ fontWeight: "900" }}
                            >
                              ( ER )
                            </span>
                          )}

                          {rep.issue_type === "mi" && (
                            <span
                              className="text-success"
                              style={{ fontWeight: "900" }}
                            >
                              ( MI )
                            </span>
                          )}

                          {rep.issue_type === "si" && (
                            <span
                              className="text-danger"
                              style={{ fontWeight: "900" }}
                            >
                              ( SI )
                            </span>
                          )}
                        </h6>
                        <p>{rep.subject}</p>
                        <p>{rep.body}</p>
                        <p className="text-muted">
                          <span>
                            <b>Submitted by:</b> {rep.submitted_by}
                          </span>{" "}
                          &nbsp;&nbsp;|| &nbsp;&nbsp;
                          <span>
                            <b>Priority:</b>
                            {rep.priority === "normal" && (
                              <span className="badge badge-info badge-pill">
                                {rep.priority.toUpperCase()}
                              </span>
                            )}

                            {rep.priority === "medium" && (
                              <span className="badge badge-warning badge-pill">
                                {rep.priority.toUpperCase()}
                              </span>
                            )}

                            {rep.priority === "high" && (
                              <span className="badge badge-danger badge-pill">
                                {rep.priority.toUpperCase()}
                              </span>
                            )}
                          </span>
                          &nbsp;&nbsp;|| &nbsp;&nbsp;
                          <span>
                            {" "}
                            <b>Status :</b>{" "}
                            <span className="badge badge-danger badge-pill">
                              {rep.status.toUpperCase()}
                            </span>
                          </span>{" "}
                          &nbsp;&nbsp;|| &nbsp;&nbsp;
                          <span>
                            {" "}
                            <b>Ticket Number:</b> {rep.ticket_number}
                          </span>{" "}
                          &nbsp;&nbsp;|| &nbsp;&nbsp;
                          <i className="mdi mdi-clock"></i>{" "}
                          {moment(rep.created_on).format("LL")}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AllReports;
