import React, { useState } from "react";
import { Formik, FieldArray, Field } from "formik";
import { RoundNumberUp } from "../../../../components/Utils/RoundNumberUp";
import { Form } from "react-bootstrap";

const OtherEmployeesTable = ({ title, data, removeItem, calculateNewTotalTip }) => {
  const [editMode, setEditMode] = useState(true);

  return (
    <div className="table-responsive" style={{ marginTop: "60px" }}>
      <div>
        <Formik
          initialValues={{ otherEmpDetails: data }}
          onSubmit={(values) => {
            setEditMode(true);
          }}
          enableReinitialize
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <FieldArray name="otherEmpDetails">
                {(fieldArrayProps) => {
                  const { form } = fieldArrayProps;
                  const { values } = form;
                  const { otherEmpDetails } = values;

                  return (
                    <div>
                      <h4>{title}</h4>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th> Number</th>
                            <th className="table-wider">Employee</th>
                            <th>Tips Owed</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {otherEmpDetails.map((emp, index) => (
                            <tr key={emp.employee_number} id={"summary_row_" + index}>
                              <td>{emp.employee_number}</td>
                              <td>{emp.employee_name}</td>
                              <td className="amountRow">
                                <Form.Group>
                                  <Field
                                    name={`otherEmpDetails[${index}].tip_owed`}
                                    type="number"
                                    className="form-control"
                                    style={{
                                      padding: "0px",
                                    }}
                                    placeholder="Employee Number"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                    disabled={editMode}
                                    defaultValue={emp.tip_owed ? RoundNumberUp(emp.tip_owed) : ""}
                                    onBlur={(e) => {
                                      calculateNewTotalTip(otherEmpDetails);
                                    }}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `otherEmpDetails[${index}].tip_owed`,
                                        parseFloat(e.target.value)
                                      );
                                    }}
                                  />
                                </Form.Group>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() => removeItem(emp, index)}
                                  className="btn btn-danger"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  );
                }}
              </FieldArray>

              <div className="mt-5">
                {editMode ? (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setEditMode(false);
                    }}
                    className="btn btn-dark btn-icon-text"
                  >
                    Edit
                  </button>
                ) : (
                  <button type="submit" className="btn btn-dark">
                    Save
                  </button>
                )}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default OtherEmployeesTable;
