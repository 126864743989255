import Api from "./Api";

export class ExpensesService {
  static async createExpenses(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`expenses/create-expense`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async uploadExpensesReceipt(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`expenses/upload-receipt-expense`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async editExpenses(data) {
    return new Promise((resolve, reject) => {
      return Api.put(`expenses/edit-expense/${data.id}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getExpensesForADate(date, store_id) {
    let data = { date, store_id };
    return new Promise((resolve, reject) => {
      return Api.post(`expenses/get-expense-date`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async fetchExpensesForAStore(data) {
    return new Promise((resolve, reject) => {
      return Api.get(`expenses/fetch-expenses-store/${data.store_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getExpensesAmount(data) {
    return new Promise((resolve, reject) => {
      return Api.get(`expenses/expenses-amount/${data.store_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getAParticularExpensesAmountForStore(data) {
    return new Promise((resolve, reject) => {
      return Api.get(
        `expenses/expenses-amount-store/${data.store_id}/${data.expense_id}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getExpensesAmountStoreDate(data) {
    return new Promise((resolve, reject) => {
      return Api.get(
        `expenses/expense-amount-date/${data.store_id}/${data.date}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getExpensesDocs(data) {
    return new Promise((resolve, reject) => {
      return Api.get(
        `expenses/expenses-docs/${data.store_id}/${data.expense_id}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async createDepositData(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`expenses/create-deposit`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getDepositData(id, date) {
    return new Promise((resolve, reject) => {
      return Api.get(`expenses/get-deposit/${id}/${date}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async cancelDepositData(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`expenses/cancel-deposit`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async createEmergencyExpenses(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`expenses/create-emergency-expenses`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async cancelEmergencyExpenses(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`expenses/cancel-emergency-expenses`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getEmergencyExpensesForAStore(data) {
    return new Promise((resolve, reject) => {
      return Api.get(
        `expenses/emergency-expenses/${data.store_id}/${data.report_date}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getEmergencyExpensesForAStorePDF(data) {
    return new Promise((resolve, reject) => {
      return Api.get(
        `expenses/emergency-expenses-pdf/${data.store_id}/${data.report_date}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getAllEmergencyExpenses() {
    return new Promise((resolve, reject) => {
      return Api.get(`expenses/all-emergency-expenses`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async deleteEmergencyCodeForAStore(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`expenses/delete-emergency-expenses`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
