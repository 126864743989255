import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import { PaymentService } from "../../../service/PaymentService";
import { Modal, Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import Swal from "sweetalert2";

const { SearchBar } = Search;

const ClosingReportIssues = () => {
  const [issuesList, setIssuesList] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [storeId, setStoreId] = useState(null);

  useEffect(() => {
    PaymentService.retrieveOpenedIssues()
      .then((response) => {
        console.log(response.data.data);
        let issuesArray = [];

        response.data.data.map((res, index) => {
          return issuesArray.push({
            id: res.id,
            store_id: res.store_id,
            submitted_by_slug: res.submitted_by_slug,
            status: res.status,
            priority: res.priority,
            info: res.info,
            report_date: res.report_date,
            type: res.type,
          });
        });

        setIssuesList(issuesArray);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  const handleClosingIssues = (row, e) => {
    console.log(row);
    setShowUpdateModal(true);
    setStoreId(row.id);
  };

  const columns = [
    {
      dataField: "store_id",
      text: "Store Id",
      sort: true,
    },
    {
      dataField: "submitted_by_slug",
      text: "Submitted By",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },

    {
      dataField: "priority",
      text: "Priority",
      sort: true,
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
    },
    {
      dataField: "info",
      text: "Info",
      sort: true,
      style: {
        whiteSpace: "normal",
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <div>
            <button
              className="btn btn-outline-primary"
              onClick={(e) => handleClosingIssues(row, e)}
            >
              <i className="mdi mdi-cancel text-primary"></i>Close
            </button>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <div>
      <div className="page-header">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/"> La Parrilla </Link>
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            {issuesList && (
              <div className="card-body">
                <h4 className="card-title"> Closing Report Issues </h4>
                <div className="row">
                  <div className="col-12">
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={issuesList}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div className="d-flex align-items-center">
                            <p className="mb-2 mr-2">Search in table:</p>
                            <SearchBar {...props.searchProps} />
                          </div>
                          <BootstrapTable
                            className={"table-responsive"}
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        {showUpdateModal && (
          <div>
            <Formik
              initialValues={{
                id: storeId ? storeId : "",
                feedback_note: "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                console.log(values);

                PaymentService.updateClosingReportIssues(values)
                  .then((response) => {
                    console.log(response.data.data);

                    Swal.fire({
                      title: "Successful!",
                      text: response.data.message,
                      icon: "success",
                      allowOutsideClick: false,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        window.location.reload();
                      }
                    });
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <Modal
                  show={showUpdateModal}
                  onHide={() => setShowUpdateModal(false)}
                  aria-labelledby="example-modal-sizes-title-md"
                  style={{ top: "200px" }}
                  backdrop="static"
                >
                  <form name="form" onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                      <Modal.Title> Close Report Issue </Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ paddingBottom: "0px" }}>
                      <Form.Group>
                        <label htmlFor="archived_message">Feedback</label>

                        <Form.Control
                          type="text"
                          as="textarea"
                          rows="6"
                          cols="50"
                          className="form-control"
                          id="feedback_note"
                          name="feedback_note"
                          value={values.feedback_note}
                          onChange={handleChange}
                          disabled={isSubmitting}
                        ></Form.Control>
                      </Form.Group>
                    </Modal.Body>

                    <Modal.Footer className="flex-wrap" style={{ paddingTop: "0px" }}>
                      {" "}
                      <Button
                        type="submit"
                        className="btn btn-block btn-primary font-weight-medium auth-form-btn"
                        disabled={isSubmitting}
                      >
                        Proceed &nbsp;&nbsp;
                        {isSubmitting ? <span className="fa fa-spin fa-spinner"></span> : ""}
                      </Button>
                      <Button variant="light m-2" onClick={() => setShowUpdateModal(false)}>
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </form>
                </Modal>
              )}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClosingReportIssues;
