import React from "react";
import { Form } from "react-bootstrap";
import { RoundNumberUp } from "../../../../components/Utils/RoundNumberUp";


const PaymentListContainer = ({ index, tips }) => {
    return (
        <>
            <td className="amountRow">
                <Form.Group>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                $
                            </span>
                        </div>

                        <Form.Control
                            type="text"
                            className="form-control"
                            id={`tip_owed_${index}`}
                            name="tip_owed"
                            defaultValue={RoundNumberUp(
                                tips
                            )}
                            placeholder="Enter amount"
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            disabled={"disabled"}
                        />
                    </div>
                </Form.Group>
            </td>
        </>
    )
}

export default PaymentListContainer;