import React, { Component } from "react";
import { withRouter } from "react-router-dom";

export class HREmployees extends Component {
  render() {
    return (
      <div className="container">
        <div className="hr-image-wrapper row">
          <div className="col-md-3">
            <a
              className="hr-image-links"
              href="https://www.heartlandcheckview.com/UserLogin.aspx"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("../../assets/images/heartland-logo.png")}
                alt="logo"
                className="center-image"
              />{" "}
              <br></br>
              <div className="text-center">
                <h4> Payroll</h4>
              </div>
            </a>
          </div>
          <div className="col-md-3">
            <a
              className="hr-image-links"
              href="https://www.talltreehealth.com/member/member_login"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("../../assets/images/TTA_logo.gif")}
                alt="logo"
              />
            </a>
          </div>

          <div className="col-md-3">
            <a
              className="hr-image-links"
              href="https://member.aetna.com/appConfig/login/login.fcc"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("../../assets/images/aetna-logo.png")}
                alt="logo"
              />
            </a>
          </div>

          <div className="col-md-3">
            <a
              className="hr-image-links"
              href="https://www.employeenavigator.com/Benefits/Login.aspx?ReturnUrl=%252fBenefits%252fLogout.aspx"
              target="_blank"
              rel="noopener noreferrer"
              style={{ width: "246px", height: "75.77px" }}
            >
              <img
                src={require("../../assets/images/employee-navigator.png")}
                alt="logo"
                style={{ width: "100%", height: "100%" }}
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(HREmployees);
