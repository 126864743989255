import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useHistory, useParams, withRouter } from "react-router";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { CateringCode } from "../../Helpers/constant";
import { CateringService } from "../../service/CateringService";
import { ConvertDateFormat } from "../../components/ConvertDateFormat";
import Swal from "sweetalert2";
import getStoreName from "../../components/StoreNameUsingId";
import AppSpinner from "../../components/Spinner";

const { SearchBar } = Search;

const CateringPaymentCode = () => {
  let { store_id } = useParams();
  let history = useHistory();
  const [cateringPayments, setCateringPayments] = useState(null);
  const [createNewCatering, setCreateNewCatering] = useState(false);

  const handleAction = (code, i, id) => {
    console.log(code, i, id);
    // history.push("/financials/")

    history.push("/catering/configure-catering-payment-store", {
      store_id: id,
      code: code,
    });
  };

  const handleEditCateringReport = (row) => {
    console.log(row.storeId, row.id, row.cateringCode);
    history.push("/catering/edit-catering-payment", {
      store_id: row.storeId,
      id: row.id,
      code: row.cateringCode,
    });
  };

  const cancelCatering = (row, e) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to delete a catering event`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.isConfirmed) {
        e.target.innerHTML = `<i class="fa fa-spin fa-spinner"></i>Loading...`;
        // console.log(row.storeId, row.id, row.cateringCode);
        let data = {
          store_id: row.storeId,
          id: row.id,
          catering_code: row.cateringCode,
        };

        CateringService.cancelCatering(data)
          .then((response) => {
            console.log(response.data.data);
            Swal.fire({
              title: "Successful!",
              text: response.data.message,
              icon: "success",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          })
          .catch((error) => {
            console.log(error.response.data);
          });
        // setShowBalanceAlert(false);
      }
    });
  };

  const handleFileFetching = (row) => {
    let data = {
      date: row.cateringDate,
      store_id: row.storeId,
    };
    CateringService.getFile(data)
      .then((response) => {
        console.log(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  useEffect(() => {
    console.log(CateringCode);
    CateringService.getAllCateringPayment(store_id)
      .then((response) => {
        console.log(response.data.data);
        let cateringArray = [];
        response.data.data.map((res, index) => {
          return cateringArray.push({
            id: res.id,
            storeId: res.store_id,
            location: `#Laparrilla ${res.store_id}`,
            cateringCode: res.catering_code,
            cateringDate: ConvertDateFormat(res.catering_date),
            dropOffAmount: res.drop_off_amount,
            employeeForDropOff: res.drop_off_radio_option,
            numbersOfEmployees: res.number_of_employees,
            employees: res.employee_number,
          });
        });
        setCateringPayments(cateringArray);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);

  const columns = [
    {
      dataField: "cateringCode",
      text: "Catering Code",
      sort: true,
    },
    {
      dataField: "cateringDate",
      text: "Catering Date",
      sort: true,
    },
    {
      dataField: "dropOffAmount",
      text: "Drop Off Amount",
      sort: true,
    },
    {
      dataField: "employeeForDropOff",
      text: "Employee Drop Off",
      sort: true,
    },
    {
      dataField: "numbersOfEmployees",
      text: "Number of Employees",
      sort: true,
    },
    {
      dataField: "employees",
      text: "Employees",
      sort: true,
    },
    {
      dataField: "Action",
      text: "Action",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <div>
            <button
              className="btn btn-outline-primary"
              onClick={(e) => handleEditCateringReport(row)}
            >
              <i className="mdi mdi-eye-outline text-primary"></i> Edit
            </button>

            <button
              className="btn btn-outline-primary"
              onClick={(e) => {
                e.persist();
                cancelCatering(row, e);
              }}
            >
              <i className="mdi mdi-close text-primary"></i> Cancel
            </button>

            {/* Use the backend logic here to structure how to schedule catering */}
            {/* <button
              className="btn btn-outline-primary"
              onClick={(e) => handleFileFetching(row)}
            >
              <i className="mdi mdi-eye-outline text-primary"></i> Fetch
            </button> */}
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <div>
      <div className="mb-5">
        <div className="pull-left">
          <Link to={"/catering/catering-payment"}>
            <button className="btn btn-primary"> Back </button>
          </Link>
        </div>

        <div className="pull-right">
          <Link to={`/catering/create-catering-payment/${store_id}`}>
            <button className="btn btn-primary"> Create </button>
          </Link>
        </div>
        <div className="clearfix"></div>
      </div>

      <div>
        <p className="pull-right" style={{ textDecoration: "underline" }}>
          <a
            href="https://veltron.document360.io/v1/en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn How &nbsp;<span className="fa fa-question"></span>
          </a>
        </p>

        <div className="clearfix"></div>
      </div>

      <div className="row">
        {!createNewCatering && (
          <div className="col-12">
            {cateringPayments ? (
              cateringPayments.length > 0 ? (
                <div className="card">
                  <div className="card-body">
                    <p className="card-title">
                      Catering Event Details for <b>{getStoreName(store_id)}</b>
                    </p>
                    <div className="row">
                      <div className="col-12">
                        <ToolkitProvider
                          keyField="id"
                          bootstrap4
                          data={cateringPayments}
                          columns={columns}
                          search
                        >
                          {(props) => (
                            <div>
                              <div className="d-flex align-items-center">
                                <p className="mb-2 mr-2">Search in table:</p>
                                <SearchBar {...props.searchProps} />
                              </div>
                              <BootstrapTable
                                defaultSorted={defaultSorted}
                                pagination={paginationFactory()}
                                {...props.baseProps}
                                wrapperClasses="table-responsive"
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <p>
                    {" "}
                    Catering has not been scheduled for this particular store{" "}
                  </p>
                </div>
              )
            ) : (
              <AppSpinner />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(CateringPaymentCode);
