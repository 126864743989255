import React, { useState, useEffect } from "react";
import Note from "./ReportNote";
import { Formik } from "formik";
import cogoToast from "cogo-toast";
import { Form } from "react-bootstrap";
import { UserService } from "../../../service/userService";
import { SalesService } from "../../../service/SalesService";
import ReportNote from "./ReportNote";
// import CreateNote from "./CreateNotes";

const ReportNotes = ({ store, date }) => {
  const [addNewNote, setAddNewNote] = useState(false);
  const [notes, setNotes] = useState(null);

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  useEffect(() => {
    SalesService.getSalesReportComment({ store_id: store, report_date: date })
      .then((response) => {
        console.log(response.data.data, "Report comment");
        setNotes(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      {/* {!addNewNote && ( */}
      <div className="profile-feed">
        <div className="card review-card">
          <div className="card-header header-sm d-flex justify-content-between align-items-center">
            <h4 className="card-title mb-0">Comments</h4>
            <div className="wrapper d-flex align-items-center">
              {notes && notes.length > 0 ? (
                <p className="mb-0">
                  {!addNewNote && (
                    <button onClick={() => setAddNewNote(true)}>
                      <span className="mdi mdi-plus"></span>
                      <span> Add new </span>
                    </button>
                  )}

                  {addNewNote && (
                    <button onClick={() => setAddNewNote(false)}>
                      <span className="mdi mdi-minus"></span>
                      <span> Cancel </span>
                    </button>
                  )}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          {!addNewNote && (
            <div>
              {notes && notes.length > 0 ? (
                notes.map((note) => (
                  <div key={note.id}>
                    <ReportNote note={note} />
                  </div>
                ))
              ) : (
                <div>
                  {/* <CreateNote employee={employee} user={user}></CreateNote> */}
                  <div className="profile-feed">
                    <Formik
                      initialValues={{
                        comment: "",
                        store_id: store,
                        report_date: date,
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        SalesService.createSalesReportComment(values)
                          .then((response) => {
                            console.log(response.data);
                            // setNotes(response.data.data);
                            setNotes([...notes, response.data.data]);
                            setAddNewNote(false);
                            cogoToast.success(
                              "Comment created successfully",
                              toastoptions
                            );
                            setSubmitting(false);
                          })
                          .catch((error) => {
                            console.log(error);
                            setSubmitting(false);
                          });
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <div className="mt-3 mb-3">
                          <div className="row">
                            <div className="col-md-12 grid-margin stretch-card">
                              <div
                                className="card"
                                style={{
                                  border: "none",
                                }}
                              >
                                <div className="card-body">
                                  <form
                                    className="forms-sample"
                                    onSubmit={handleSubmit}
                                  >
                                    <div className="row border-bottom mb-5">
                                      <div className="col-md-6">
                                        <Form.Group>
                                          <label
                                            htmlFor="note"
                                            className="col-form-label"
                                          >
                                            Please enter a comment
                                          </label>
                                          <Form.Control
                                            as="textarea"
                                            rows="10"
                                            columns="10"
                                            className="form-control"
                                            id="comment"
                                            name="comment"
                                            onChange={handleChange}
                                            value={values.comment ?? ""}
                                          />
                                        </Form.Group>
                                      </div>
                                    </div>

                                    <button
                                      type="submit"
                                      className="btn btn-primary mr-2"
                                    >
                                      Submit
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Formik>
                  </div>
                </div>
              )}
            </div>
          )}

          <div>
            {addNewNote && (
              <div>
                {/* <CreateNote employee={employee} user={user}></CreateNote> */}
                <div className="profile-feed">
                  <Formik
                    initialValues={{
                      comment: "",
                      store_id: store,
                      report_date: date,
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setSubmitting(false);
                      SalesService.createSalesReportComment(values)
                        .then((response) => {
                          console.log(response.data);
                          setNotes([...notes, response.data.data]);
                          setAddNewNote(false);
                          cogoToast.success(
                            "Notes created successfully",
                            toastoptions
                          );
                          setSubmitting(false);
                        })
                        .catch((error) => {
                          console.log(error);
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <div className="mt-3 mb-3">
                        <div className="row">
                          <div className="col-md-12 grid-margin stretch-card">
                            <div
                              className="card"
                              style={{
                                border: "none",
                              }}
                            >
                              <div className="card-body">
                                <form
                                  className="forms-sample"
                                  onSubmit={handleSubmit}
                                >
                                  <div className="row border-bottom mb-5">
                                    <div className="col-md-6">
                                      <Form.Group>
                                        <label
                                          htmlFor="note"
                                          className="col-form-label"
                                        >
                                          Please enter a comment
                                        </label>
                                        <Form.Control
                                          as="textarea"
                                          rows="10"
                                          columns="10"
                                          className="form-control"
                                          id="comment"
                                          name="comment"
                                          onChange={handleChange}
                                          value={values.comment ?? ""}
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>

                                  <button
                                    type="submit"
                                    className="btn btn-primary mr-2"
                                  >
                                    Submit
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Formik>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default ReportNotes;
