import Api from "./Api";

export class BonusPayoutService {
  static async createBonus(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`bonus-payout/create`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getSpecificBonusPayout(store_id, quarter, year) {
    return new Promise((resolve, reject) => {
      return Api.get(`bonus-payout/get-bonus/${store_id}/${quarter}/${year}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getBulkPayoutAndIncrease(year_1, year_2, quarter, storeId) {
    return new Promise((resolve, reject) => {
      return Api.get(
        `bonus-payout/get-bulk-payout/${year_1}/${year_2}/${quarter}/${storeId}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async markBonusAsComplete(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`bonus-payout/mark-complete`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getBonusSettingsForAStore(store_id) {
    return new Promise((resolve, reject) => {
      return Api.get(`bonus-payout/get-settings/${store_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getReviewsStatsFromDB(data) {
    return new Promise((resolve, reject) => {
      return Api.get(
        `bonus-payout/get-stats-from-db/${data.year}/${data.quarter}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getEvaluationStatsFromDB(data) {
    return new Promise((resolve, reject) => {
      return Api.get(
        `bonus-payout/get-evaluation-stats-from-db/${data.year}/${data.quarter}/${data.store_id}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getSurveyData() {
    return new Promise((resolve, reject) => {
      return Api.get(`bonus-payout/get-survey-data`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async downloadSurveyExcel() {
    return new Promise((resolve, reject) => {
      return Api.get(`bonus-payout/download-survey-excel`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getEvaluationStatsForManager(data) {
    return new Promise((resolve, reject) => {
      return Api.get(
        `bonus-payout/get-evaluation-stats-manager/${data.year}/${data.quarter}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async viewBonusBreakdown() {
    return new Promise((resolve, reject) => {
      return Api.get(`bonus-payout/view-bonus-breakdown`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getBreakdownForAQuarter(data) {
    return new Promise((resolve, reject) => {
      return Api.get(
        `bonus-payout/get-breakdown-quarter/${data.year_1}/${data.year_2}/${data.quarter}/${data.store}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async submitSurveyResponses(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`bonus-payout/submit-survey-responses`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async checkManagerSurvey(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`bonus-payout/check-manager-survey`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async bonusCompleteAndPaid(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`bonus-payout/complete-and-paid`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async viewBonusReportHistories(store_id) {
    return new Promise((resolve, reject) => {
      return Api.get(`bonus-payout/get-bonus-report-histories/${store_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
