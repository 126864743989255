import React, { useContext, useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { LPSubmitButton } from "../../../styled/styles";
import BonusContext from "../../../contexts/bonus/BonusContext";

const COGS = ({ months }) => {
  const { dispatch, cogs, sales } = useContext(BonusContext);

  const initialValues = {
    foodCogsM1: cogs ? cogs.foodCogsM1 : "",
    foodCogsM1P: cogs ? cogs.foodCogsM1P : "",
    foodCogsM2: cogs ? cogs.foodCogsM2 : "",
    foodCogsM2P: cogs ? cogs.foodCogsM2P : "",
    foodCogsM3: cogs ? cogs.foodCogsM3 : "",
    foodCogsM3P: cogs ? cogs.foodCogsM3P : "",
    foodCogsTotal: cogs ? cogs.foodCogsTotal : "",
    foodCogsTotalP: cogs ? cogs.foodCogsTotalP : "",

    liquorCogsM1: cogs ? cogs.liquorCogsM1 : "",
    liquorCogsM1P: cogs ? cogs.liquorCogsM1P : "",
    liquorCogsM2: cogs ? cogs.liquorCogsM2 : "",
    liquorCogsM2P: cogs ? cogs.liquorCogsM2P : "",
    liquorCogsM3: cogs ? cogs.liquorCogsM3 : "",
    liquorCogsM3P: cogs ? cogs.liquorCogsM3P : "",
    liquorCogsTotal: cogs ? cogs.liquorCogsTotal : "",
    liquorCogsTotalP: cogs ? cogs.liquorCogsTotalP : "",

    suppliesCogsM1: cogs ? cogs.suppliesCogsM1 : "",
    suppliesCogsM1P: cogs ? cogs.suppliesCogsM1P : "",
    suppliesCogsM2: cogs ? cogs.suppliesCogsM2 : "",
    suppliesCogsM2P: cogs ? cogs.suppliesCogsM2P : "",
    suppliesCogsM3: cogs ? cogs.suppliesCogsM3 : "",
    suppliesCogsM3P: cogs ? cogs.suppliesCogsM3P : "",
    suppliesCogsTotal: cogs ? cogs.suppliesCogsTotal : "",
    suppliesCogsTotalP: cogs ? cogs.suppliesCogsTotalP : "",

    totalCogsM1: cogs ? cogs.totalCogsM1 : "",
    totalCogsM1P: cogs ? cogs.totalCogsM1P : "",
    totalCogsM2: cogs ? cogs.totalCogsM2 : "",
    totalCogsM2P: cogs ? cogs.totalCogsM2P : "",
    totalCogsM3: cogs ? cogs.totalCogsM3 : "",
    totalCogsM3P: cogs ? cogs.totalCogsM3P : "",
    totalCogsTotal: cogs ? cogs.totalCogsTotal : "",
    totalCogsTotalP: cogs ? cogs.totalCogsTotalP : "",
  };

  const handleDispatch = (payload) => {
    dispatch({
      type: "SET_COGS_DATA",
      payload,
    });
  };

  const roundNumberUp = (num) => {
    return (Math.round(num * 100) / 100).toFixed(0);
  };

  const roundNumberUp2DP = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  const makeValZeroIfEmpty = (input) => {
    return input == "" || isNaN(input) ? 0 : input;
  };

  return (
    <div>
      <div className="col-lg-12 grid-margin stretch-card">
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            console.log(values);

            // Submit form here;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form name="sales-data" onSubmit={handleSubmit}>
              <div className="table-responsive">
                <div>
                  <table className="table table-bordered">
                    <tbody>
                      <tr
                        style={{
                          fontWeight: "bold",
                          // paddingLeft: "0px",
                          textAlign: "center",
                        }}
                      >
                        <th
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            padding: "0px",
                            textAlign: "center",
                          }}
                          width="10%"
                        >
                          COGS
                        </th>
                        <td width="10%"></td>
                        <td width="10%"> {months.firstMonth}</td>
                        <td width="10%">%</td>
                        <td width="10%">{months.secondMonth}</td>
                        <td width="10%">%</td>
                        <td width="10%">{months.thirdMonth}</td>
                        <td width="10%">%</td>
                        <td width="10%">Total </td>
                        <td width="10%">%</td>
                      </tr>

                      <tr>
                        <th width="10%" scope="row">
                          {" "}
                        </th>
                        <td
                          width="10%"
                          style={{
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          Food
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodCogsM1"
                            name="foodCogsM1"
                            defaultValue={values.foodCogsM1}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              setFieldValue(
                                "foodCogsM1",
                                parseFloat(makeValZeroIfEmpty(e.target.value))
                              );

                              handleDispatch({
                                key: e.target.name,
                                value: parseFloat(makeValZeroIfEmpty(e.target.value)),
                              });

                              const foodCogsTotal =
                                parseFloat(makeValZeroIfEmpty(e.target.value)) +
                                parseFloat(makeValZeroIfEmpty(values.foodCogsM2)) +
                                parseFloat(makeValZeroIfEmpty(values.foodCogsM3));

                              handleDispatch({
                                key: "foodCogsTotal",
                                value: parseFloat(roundNumberUp2DP(foodCogsTotal)),
                              });

                              setFieldValue(
                                "foodCogsTotal",
                                parseFloat(roundNumberUp2DP(foodCogsTotal))
                              );

                              const foodCogsM1P =
                                (parseFloat(makeValZeroIfEmpty(e.target.value)) /
                                  sales.foodSalesM1) *
                                100;

                              handleDispatch({
                                key: "foodCogsM1P",
                                value: parseFloat(roundNumberUp2DP(foodCogsM1P)),
                              });

                              setFieldValue(
                                "foodCogsM1P",
                                parseFloat(roundNumberUp2DP(foodCogsM1P))
                              );

                              const totalCogsM1 =
                                parseFloat(makeValZeroIfEmpty(e.target.value)) +
                                parseFloat(makeValZeroIfEmpty(values.liquorCogsM1)) +
                                parseFloat(makeValZeroIfEmpty(values.suppliesCogsM1));

                              handleDispatch({
                                key: "totalCogsM1",
                                value: parseFloat(roundNumberUp2DP(totalCogsM1)),
                              });

                              setFieldValue(
                                "totalCogsM1",
                                parseFloat(roundNumberUp2DP(totalCogsM1))
                              );

                              const totalCogsTotal =
                                parseFloat(roundNumberUp2DP(totalCogsM1)) +
                                parseFloat(makeValZeroIfEmpty(values.totalCogsM2)) +
                                parseFloat(makeValZeroIfEmpty(values.totalCogsM3));

                              handleDispatch({
                                key: "totalCogsTotal",
                                value: parseFloat(roundNumberUp2DP(totalCogsTotal)),
                              });
                            }}
                          />
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodCogsM1P"
                            name="foodCogsM1P"
                            defaultValue={values.foodCogsM1P ? values.foodCogsM1P : ""}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodCogsM2"
                            name="foodCogsM2"
                            defaultValue={values.foodCogsM2}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodCogsM2P"
                            name="foodCogsM2P"
                            defaultValue={values.foodCogsM2P ? values.foodCogsM2P : ""}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodCogsM3"
                            name="foodCogsM3"
                            defaultValue={values.foodCogsM3}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodCogsM3P"
                            name="foodCogsM3P"
                            defaultValue={values.foodCogsM3P ? values.foodCogsM3P : ""}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodCogsTotal"
                            name="foodCogsTotal"
                            defaultValue={values.foodCogsTotal}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodCogsTotalP"
                            name="foodCogsTotalP"
                            defaultValue={values.foodCogsTotalP ? values.foodCogsTotalP : ""}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row"> </th>
                        <td
                          width="10%"
                          style={{
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          Liquor
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorCogsM1"
                            name="liquorCogsM1"
                            defaultValue={values.liquorCogsM1}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              setFieldValue(
                                "liquorCogsM1",
                                parseFloat(makeValZeroIfEmpty(e.target.value))
                              );

                              handleDispatch({
                                key: e.target.name,
                                value: parseFloat(makeValZeroIfEmpty(e.target.value)),
                              });

                              const liquorCogsTotal =
                                parseFloat(makeValZeroIfEmpty(e.target.value)) +
                                parseFloat(makeValZeroIfEmpty(values.liquorCogsM2)) +
                                parseFloat(makeValZeroIfEmpty(values.liquorCogsM3));

                              handleDispatch({
                                key: "liquorCogsTotal",
                                value: parseFloat(roundNumberUp2DP(liquorCogsTotal)),
                              });

                              setFieldValue(
                                "liquorCogsTotal",
                                parseFloat(roundNumberUp2DP(liquorCogsTotal))
                              );

                              const liquorCogsM1P =
                                (parseFloat(makeValZeroIfEmpty(e.target.value)) /
                                  sales.liquorSalesM1) *
                                100;

                              handleDispatch({
                                key: "liquorCogsM1P",
                                value: parseFloat(roundNumberUp2DP(liquorCogsM1P)),
                              });

                              setFieldValue(
                                "liquorCogsM1P",
                                parseFloat(roundNumberUp2DP(liquorCogsM1P))
                              );

                              const totalCogsM1 =
                                parseFloat(makeValZeroIfEmpty(e.target.value)) +
                                parseFloat(makeValZeroIfEmpty(values.foodCogsM1)) +
                                parseFloat(makeValZeroIfEmpty(values.suppliesCogsM1));

                              handleDispatch({
                                key: "totalCogsM1",
                                value: parseFloat(roundNumberUp2DP(totalCogsM1)),
                              });

                              setFieldValue(
                                "totalCogsM1",
                                parseFloat(roundNumberUp2DP(totalCogsM1))
                              );

                              const totalCogsTotal =
                                parseFloat(roundNumberUp2DP(totalCogsM1)) +
                                parseFloat(makeValZeroIfEmpty(values.totalCogsM2)) +
                                parseFloat(makeValZeroIfEmpty(values.totalCogsM3));

                              handleDispatch({
                                key: "totalCogsTotal",
                                value: parseFloat(roundNumberUp2DP(totalCogsTotal)),
                              });
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorCogsM1P"
                            name="liquorCogsM1P"
                            defaultValue={values.liquorCogsM1P ? values.liquorCogsM1P : ""}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorCogsM2"
                            name="liquorCogsM2"
                            defaultValue={values.liquorCogsM2}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorCogsM2P"
                            name="liquorCogsM2P"
                            defaultValue={values.liquorCogsM2P ? values.liquorCogsM2P : ""}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorCogsM3"
                            name="liquorCogsM3"
                            defaultValue={values.liquorCogsM3}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorCogsM3P"
                            name="liquorCogsM3P"
                            defaultValue={values.liquorCogsM3P ? values.liquorCogsM3P : ""}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorCogsTotal"
                            name="liquorCogsTotal"
                            defaultValue={values.liquorCogsTotal}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorCogsTotalP"
                            name="liquorCogsTotalP"
                            defaultValue={values.liquorCogsTotalP ? values.liquorCogsTotalP : ""}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> </th>
                        <td
                          width="10%"
                          style={{
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          Supplies
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="suppliesCogsM1"
                            name="suppliesCogsM1"
                            defaultValue={values.suppliesCogsM1}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              setFieldValue(
                                "suppliesCogsM1",
                                parseFloat(makeValZeroIfEmpty(e.target.value))
                              );

                              handleDispatch({
                                key: e.target.name,
                                value: parseFloat(makeValZeroIfEmpty(e.target.value)),
                              });

                              const suppliesCogsTotal =
                                parseFloat(makeValZeroIfEmpty(e.target.value)) +
                                parseFloat(makeValZeroIfEmpty(values.suppliesCogsM2)) +
                                parseFloat(makeValZeroIfEmpty(values.suppliesCogsM3));

                              handleDispatch({
                                key: "suppliesCogsTotal",
                                value: parseFloat(roundNumberUp2DP(suppliesCogsTotal)),
                              });

                              setFieldValue(
                                "suppliesCogsTotal",
                                parseFloat(roundNumberUp2DP(suppliesCogsTotal))
                              );

                              const suppliesCogsM1P =
                                (parseFloat(makeValZeroIfEmpty(e.target.value)) /
                                  sales.totalSalesM1) *
                                100;

                              handleDispatch({
                                key: "suppliesCogsM1P",
                                value: parseFloat(roundNumberUp2DP(suppliesCogsM1P)),
                              });

                              setFieldValue(
                                "suppliesCogsM1P",
                                parseFloat(roundNumberUp2DP(suppliesCogsM1P))
                              );

                              const foodCogsM1P =
                                (parseFloat(makeValZeroIfEmpty(values.foodCogsM1)) /
                                  sales.foodSalesM1) *
                                100;

                              handleDispatch({
                                key: "foodCogsM1P",
                                value: parseFloat(roundNumberUp2DP(foodCogsM1P)),
                              });

                              setFieldValue(
                                "foodCogsM1P",
                                parseFloat(roundNumberUp2DP(foodCogsM1P))
                              );

                              const liquorCogsM1P =
                                (parseFloat(makeValZeroIfEmpty(values.liquorCogsM1)) /
                                  sales.liquorSalesM1) *
                                100;

                              handleDispatch({
                                key: "liquorCogsM1P",
                                value: parseFloat(roundNumberUp2DP(liquorCogsM1P)),
                              });

                              setFieldValue(
                                "liquorCogsM1P",
                                parseFloat(roundNumberUp2DP(liquorCogsM1P))
                              );

                              const totalCogsM1 =
                                parseFloat(makeValZeroIfEmpty(e.target.value)) +
                                parseFloat(makeValZeroIfEmpty(values.liquorCogsM1)) +
                                parseFloat(makeValZeroIfEmpty(values.foodCogsM1));

                              handleDispatch({
                                key: "totalCogsM1",
                                value: parseFloat(roundNumberUp2DP(totalCogsM1)),
                              });

                              setFieldValue(
                                "totalCogsM1",
                                parseFloat(roundNumberUp2DP(totalCogsM1))
                              );

                              const totalCogsTotal =
                                parseFloat(roundNumberUp2DP(totalCogsM1)) +
                                parseFloat(makeValZeroIfEmpty(values.totalCogsM2)) +
                                parseFloat(makeValZeroIfEmpty(values.totalCogsM3));

                              handleDispatch({
                                key: "totalCogsTotal",
                                value: parseFloat(roundNumberUp2DP(totalCogsTotal)),
                              });

                              setFieldValue(
                                "totalCogsTotal",
                                parseFloat(roundNumberUp2DP(totalCogsTotal))
                              );

                              const totalCogsM1P =
                                (parseFloat(totalCogsM1) / parseFloat(sales.totalSalesM1)) * 100;

                              handleDispatch({
                                key: "totalCogsM1P",
                                value: parseFloat(roundNumberUp2DP(totalCogsM1P)),
                              });

                              setFieldValue(
                                "totalCogsM1P",
                                parseFloat(roundNumberUp2DP(totalCogsM1P))
                              );
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="suppliesCogsM1P"
                            name="suppliesCogsM1P"
                            defaultValue={values.suppliesCogsM1P ? values.suppliesCogsM1P : ""}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="suppliesCogsM2"
                            name="suppliesCogsM2"
                            defaultValue={values.suppliesCogsM2}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="suppliesCogsM2P"
                            name="suppliesCogsM2P"
                            defaultValue={values.suppliesCogsM2P ? values.suppliesCogsM2P : ""}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="suppliesCogsM3"
                            name="suppliesCogsM3"
                            defaultValue={values.suppliesCogsM3}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="suppliesCogsM3P"
                            name="suppliesCogsM3P"
                            defaultValue={values.suppliesCogsM3P ? values.suppliesCogsM3P : ""}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="suppliesCogsTotal"
                            name="suppliesCogsTotal"
                            defaultValue={values.suppliesCogsTotal}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="suppliesCogsTotalP"
                            name="suppliesCogsTotalP"
                            defaultValue={
                              values.suppliesCogsTotalP ? values.suppliesCogsTotalP : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row"> </th>
                        <td
                          width="10%"
                          style={{
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          Total
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalCogsM1"
                            name="totalCogsM1"
                            defaultValue={values.totalCogsM1 ? values.totalCogsM1 : ""}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalCogsM1P"
                            name="totalCogsM1P"
                            defaultValue={values.totalCogsM1P ? values.totalCogsM1P : ""}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalCogsM2"
                            name="totalCogsM2"
                            defaultValue={values.totalCogsM2 ? values.totalCogsM2 : ""}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalCogsM2P"
                            name="totalCogsM2P"
                            defaultValue={values.totalCogsM2P ? values.totalCogsM2P : ""}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalCogsM3"
                            name="totalCogsM3"
                            defaultValue={values.totalCogsM3 ? values.totalCogsM3 : ""}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalCogsM3P"
                            name="totalCogsM3P"
                            defaultValue={values.totalCogsM3P ? values.totalCogsM3P : ""}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalCogsTotal"
                            name="totalCogsTotal"
                            defaultValue={values.totalCogsTotal ? values.totalCogsTotal : ""}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalCogsTotalP"
                            name="totalCogsTotalP"
                            defaultValue={values.totalCogsTotalP ? values.totalCogsTotalP : ""}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div>
                {/* <LPSubmitButton className="btn btn-primary" type="submit">
                  Update COGS
                </LPSubmitButton> */}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default COGS;
