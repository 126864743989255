import React from "react";
import { Col, Row, Nav, Tab } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import ViewDomains from "./domain/ViewDomains";
import JobCodes from "./job-code/ViewJobCodes";
import Corporations from "./corporation/ViewCorporations";
import Stores from "./store/ViewStores";
import Districts from "./district/ViewDistricts";

const SystemConfig = () => {
  return (
    <div>
      <div>
        <p className="pull-right" style={{ textDecoration: "underline" }}>
          <a
            href="https://veltron.document360.io/v1/en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn How &nbsp;<span className="fa fa-question"></span>
          </a>
        </p>

        <div className="clearfix"></div>
      </div>

      <div className="page-header">
        <h3 className="page-title">Portal Configuration</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="!#" onClick={(event) => event.preventDefault()}>
                {" "}
                Portal configuration
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              all
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12 stretch-card">
          <div className="card">
            <div className="card-body">
              {/* <h4 className="card-title">System Configuration</h4> */}
              {/* <p className="card-description">The list of settings items</p> */}
              <div className="tab-custom-pills-horizontal">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="domains"
                >
                  <Row>
                    <Col xs={12}>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="domains"
                            className="d-flex align-items-center"
                          >
                            Domains
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="jobCodes"
                            className="d-flex align-items-center"
                          >
                            Job Codes
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="stores"
                            className="d-flex align-items-center"
                          >
                            Store
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="corporations"
                            className="d-flex align-items-center"
                          >
                            Corporation
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                          <Nav.Link
                            eventKey="districts"
                            className="d-flex align-items-center"
                          >
                            District
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col xs={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="domains">
                          <ViewDomains />
                        </Tab.Pane>
                        <Tab.Pane eventKey="jobCodes">
                          <JobCodes />
                        </Tab.Pane>
                        <Tab.Pane eventKey="stores">
                          <Stores />
                        </Tab.Pane>
                        <Tab.Pane eventKey="corporations">
                          <Corporations />
                        </Tab.Pane>
                        <Tab.Pane eventKey="districts">
                          <Districts />
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SystemConfig);
