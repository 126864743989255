import Domains from "../system-configuration/domain/Domains";
import ViewDomains from "../system-configuration/domain/ViewDomains";
import ViewDomain from "../system-configuration/domain/ViewDomain";
import JobCodes from "../system-configuration/job-code/JobCodes";
import ViewJobCodes from "../system-configuration/job-code/ViewJobCodes";
import ViewJobCode from "../system-configuration/job-code/ViewJobCode";
import Store from "../system-configuration/store/Store";
import ViewStores from "../system-configuration/store/ViewStores";
import ViewStore from "../system-configuration/store/ViewStore";
import Corporations from "../system-configuration/corporation/Corporations";
import ViewCorporations from "../system-configuration/corporation/ViewCorporations";
import ViewCorporation from "../system-configuration/corporation/ViewCorporation";
import Districts from "../system-configuration/district/Districts";
import ViewDistricts from "../system-configuration/district/ViewDistricts";
import ViewDistrict from "../system-configuration/district/ViewDistrict";
import SystemConfig from "../system-configuration/SystemConfig";
import CreateBonusPayoutConfig from "../system-configuration/bonus/CreateBonusConfig";
import BonusPayoutConfigDefault from "../system-configuration/bonus/Default";
import BonusKPIConfigDefault from "../system-configuration/bonus/kpi/Default";
import BonusPayoutStoreList from "../system-configuration/bonus/StoreList";
import BonusPayoutConfig from "../system-configuration/bonus/ViewBonusConfig";
import EmployeeConfiguration from "../system-configuration/employee-configuration/EmployeesConfig";
import States from "../system-configuration/employee-configuration/state/States";
import ViewStates from "../system-configuration/employee-configuration/state/ViewStates";
import ViewState from "../system-configuration/employee-configuration/state/ViewState";
import MaritalStatuses from "../system-configuration/employee-configuration/marital-status/MaritalStatuses";
import ViewMaritalStatuses from "../system-configuration/employee-configuration/marital-status/ViewMaritalStatuses";
import ViewMaritalStatus from "../system-configuration/employee-configuration/marital-status/ViewMaritalStatus";
import Genders from "../system-configuration/employee-configuration/gender/Genders";
import ViewGenders from "../system-configuration/employee-configuration/gender/ViewGenders";
import ViewGender from "../system-configuration/employee-configuration/gender/ViewGender";
import Ethnics from "../system-configuration/employee-configuration/ethnic/Ethnics";
import ViewEthnics from "../system-configuration/employee-configuration/ethnic/ViewEthnics";
import ViewEthnic from "../system-configuration/employee-configuration/ethnic/ViewEthnic";

const systemConfigurationRoutes = [
  { path: "/system-configuration/domains", component: Domains },
  { path: "/system-configuration/view-domains", component: ViewDomains },
  { path: "/system-configuration/view-domain/:id", component: ViewDomain },
  { path: "/system-configuration/job-codes", component: JobCodes },
  { path: "/system-configuration/view-job-codes", component: ViewJobCodes },
  { path: "/system-configuration/view-job-code/:id", component: ViewJobCode },
  { path: "/system-configuration/store", component: Store },
  { path: "/system-configuration/view-stores", component: ViewStores },
  { path: "/system-configuration/view-store/:id", component: ViewStore },
  { path: "/system-configuration/corporations", component: Corporations },
  { path: "/system-configuration/view-corporations", component: ViewCorporations },
  { path: "/system-configuration/view-corporation/:id", component: ViewCorporation },
  { path: "/system-configuration/districts", component: Districts },
  { path: "/system-configuration/view-districts", component: ViewDistricts },
  { path: "/system-configuration/view-district/:id", component: ViewDistrict },
  { path: "/system-configuration/all", component: SystemConfig },
  { path: "/system-configuration/bonus/create-config", component: CreateBonusPayoutConfig },
  { path: "/system-configuration/bonus/default-page", component: BonusPayoutConfigDefault },
  { path: "/system-configuration/kpis/default", component: BonusKPIConfigDefault },
  { path: "/system-configuration/bonus/view", component: BonusPayoutStoreList },
  { path: "/system-configuration/bonus/view-config/:id", component: BonusPayoutConfig },
  { path: "/system-configuration/employee", component: EmployeeConfiguration },
  { path: "/employee-configuration/states", component: States },
  { path: "/employee-configuration/view-states", component: ViewStates },
  { path: "/employee-configuration/view-state/:id", component: ViewState },
  { path: "/employee-configuration/marital-statuses", component: MaritalStatuses },
  { path: "/employee-configuration/view-marital-statuses", component: ViewMaritalStatuses },
  { path: "/employee-configuration/view-marital-status/:id", component: ViewMaritalStatus },
  { path: "/employee-configuration/genders", component: Genders },
  { path: "/employee-configuration/view-genders", component: ViewGenders },
  { path: "/employee-configuration/view-gender/:id", component: ViewGender },
  { path: "/employee-configuration/ethnics", component: Ethnics },
  { path: "/employee-configuration/view-ethnics", component: ViewEthnics },
  { path: "/employee-configuration/view-ethnic/:id", component: ViewEthnic },
];

export default systemConfigurationRoutes;
