import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { SystemConfigService } from "../../../../../service/configService";
import Swal from "sweetalert2";

const KpiBeverage = () => {
  const [editMode, setEditMode] = useState(false);
  const [config, setConfig] = useState(null);

  useEffect(() => {
    SystemConfigService.getBonusKpi("beverage")
      .then((response) => {
        console.log(response);
        setConfig(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      {config ? (
        <Formik
          initialValues={{
            id: "",
            manager_type: "beverage",
            alcohol_cost: config.alcohol_cost,
            alcohol_sales: config.alcohol_sales,
            reviews: config.reviews,
            net_profit: config.net_profit,
            evaluation_from_staff: config.evaluation_from_staff,
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            let totalPercentage = 100;
            let currentTotal =
              parseFloat(values.alcohol_cost) +
              parseFloat(values.alcohol_sales) +
              parseFloat(values.reviews) +
              parseFloat(values.net_profit) +
              parseFloat(values.evaluation_from_staff);

            if (currentTotal === totalPercentage) {
              SystemConfigService.updateOrCreateBonusKpi(values)
                .then((response) => {
                  console.log(response);
                  setSubmitting(false);
                  Swal.fire({
                    title: "Good Job!",
                    text: "Updated successfully",
                    icon: "success",
                    confirmButtonText: "Ok",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      setEditMode(false);
                    }
                  });
                })
                .catch((error) => {
                  console.log(error.response);
                  setSubmitting(false);
                });
            } else {
              Swal.fire({
                title: "Error",
                text: "Total percentage cannot be more or less than 100%",
                icon: "error",
                confirmButtonText: "Ok",
                allowEscapeKey: false,
                allowOutsideClick: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  Swal.close();
                  setSubmitting(false);
                }
              });
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <div className="mt-3 mb-3">
              <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card" style={{ border: "none" }}>
                    <div className="card-body">
                      <form className="pageForms" onSubmit={handleSubmit}>
                        <div className="row border-bottom mb-5">
                          <div className="col-md-6">
                            <Form.Group>
                              <label htmlFor="alcohol_cost">
                                Alcohol Cost %
                              </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="alcohol_cost"
                                name="alcohol_cost"
                                value={values.alcohol_cost}
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="alcohol_sales">
                                {" "}
                                Alcohol Sales %{" "}
                              </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="alcohol_sales"
                                name="alcohol_sales"
                                value={values.alcohol_sales}
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="reviews">Reviews % </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="reviews"
                                name="reviews"
                                value={values.reviews}
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>
                          </div>

                          <div className="col-md-6">
                            <Form.Group>
                              <label htmlFor="net_profit">Net Profit % </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="net_profit"
                                name="net_profit"
                                value={values.net_profit}
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="evaluation_from_staff">
                                Evaluation From Staff %
                              </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="evaluation_from_staff"
                                name="evaluation_from_staff"
                                value={values.evaluation_from_staff}
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>
                          </div>
                        </div>
                        {editMode && (
                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary btn-icon-text"
                            >
                              <i className="mdi mdi-file-check btn-icon-prepend"></i>{" "}
                              Update&nbsp;&nbsp;
                              {isSubmitting ? (
                                <Spinner animation="border" size="sm" />
                              ) : (
                                ""
                              )}
                            </button>

                            <button
                              className="btn btn-light"
                              onClick={() => {
                                setEditMode(false);
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </form>
                      {!editMode && (
                        <div className="">
                          <button
                            type="button"
                            onClick={() => {
                              setEditMode(true);
                            }}
                            className="btn btn-primary btn-rounded"
                          >
                            Edit
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <div>
          <p> Record Not Found </p>
        </div>
      )}
    </div>
  );
};

export default KpiBeverage;
