import React, { useState, useEffect } from "react";
import { SystemConfigService } from "../../../../../service/configService";
import StorePermissionModal from "./components/StorePermissionModal";

const AccessLevel = ({ employee, user }) => {
  const [showStorePermissionModal, setShowStorePermissionModal] =
    useState(false);
  const [storeOptions, setStoreOptions] = useState(null);
  const [defaultPermanentStores, setDefaultPermanentStores] = useState(null);
  const [defaultTemporaryStores, setDefaultTemporaryStores] = useState(null);

  const handleStorePermissionClose = () => {
    setShowStorePermissionModal(false);
  };

  useEffect(() => {
    let storeOptionsArray = [];
    SystemConfigService.getStores()
      .then((response) => {
        let val = response.data.data;
        val.map((i, index) => {
          return storeOptionsArray.push({
            value: i.location_id,
            label: i.name.replace(/\w\S*/g, (w) =>
              w.replace(/^\w/, (c) => c.toUpperCase())
            ),
          });
        });
        setStoreOptions(storeOptionsArray);
      })
      .catch((error) => {
        console.log(error.response.data);
      });

    let defaultOptionsArray = {
      temporary: [],
      permanent: [],
    };

    SystemConfigService.getPermittedStoreForTempPerm({ id: employee.id })
      .then((response) => {
        console.log(response.data.data, "Selected stores");
        const tempRes = response.data.data.temporary;
        const permRes = response.data.data.permanent;

        if (tempRes.length > 0) {
          tempRes.map((i, index) => {
            defaultOptionsArray.temporary.push({
              value: i.location_id,
              label: i.name.replace(/\w\S*/g, (w) =>
                w.replace(/^\w/, (c) => c.toUpperCase())
              ),
            });
          });
        }

        if (permRes.length > 0) {
          permRes.map((i, index) => {
            defaultOptionsArray.permanent.push({
              value: i.location_id,
              label: i.name.replace(/\w\S*/g, (w) =>
                w.replace(/^\w/, (c) => c.toUpperCase())
              ),
            });
          });
        }

        setDefaultPermanentStores(defaultOptionsArray.permanent);
        setDefaultTemporaryStores(defaultOptionsArray.temporary);

        return defaultOptionsArray;
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div>
      {user && (user.role === "super admin" || user.role === "hr") ? (
        <button
          type="button"
          className="btn btn-dark btn-icon-text"
          style={{ marginBottom: "20px" }}
          onClick={() => {
            setShowStorePermissionModal(true);
          }}
        >
          Configure Store Permissions <i className="mdi mdi-block-helper"></i>
        </button>
      ) : (
        ""
      )}

      <div>
        <div className="row">
          <div className="col-md-6">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{ width: "50%" }}> Permanent </th>
                </tr>
              </thead>
              <tbody>
                {defaultPermanentStores &&
                  defaultPermanentStores.map((store, index) => (
                    <tr key={index}>
                      <td> {store.label} </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="col-md-6">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{ width: "50%" }}> Temporary </th>
                </tr>
              </thead>
              <tbody>
                {defaultTemporaryStores &&
                  defaultTemporaryStores.map((store, index) => (
                    <tr key={index}>
                      <td> {store.label} </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div>
        {showStorePermissionModal && (
          <StorePermissionModal
            showStorePermissionModal={showStorePermissionModal}
            setShowStorePermissionModal={setShowStorePermissionModal}
            employee={employee}
            handleStorePermissionClose={handleStorePermissionClose}
            storeOptions={storeOptions}
            defaultPermanentStores={defaultPermanentStores}
            defaultTemporaryStores={defaultTemporaryStores}
          />
        )}
      </div>
    </div>
  );
};

export default AccessLevel;
