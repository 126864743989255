import React, { useEffect, useState, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { LPSubmitButton } from "../../../styled/styles";
// import BonusContext from "../../../contexts/bonus/BonusContext";
import { parse } from "date-fns";

const Sales = ({ months }) => {
  // context
  // const { dispatch, sales } = useContext(BonusContext);

  // const initialValues = {
  //   foodSalesM1: sales ? sales.foodSalesM1 : "",
  //   foodSalesM1P: sales ? sales.foodSalesM1P : "",
  //   foodSalesM2: sales ? sales.foodSalesM2 : "",
  //   foodSalesM2P: sales ? sales.foodSalesM2P : "",
  //   foodSalesM3: sales ? sales.foodSalesM3 : "",
  //   foodSalesM3P: sales ? sales.foodSalesM3P : "",
  //   foodSalesTotal: sales ? sales.foodSalesTotal : "",
  //   foodSalesTotalP: sales ? sales.foodSalesTotalP : "",

  //   liquorSalesM1: sales ? sales.liquorSalesM1 : "",
  //   liquorSalesM1P: sales ? sales.liquorSalesM1P : "",
  //   liquorSalesM2: sales ? sales.liquorSalesM2 : "",
  //   liquorSalesM2P: sales ? sales.liquorSalesM2P : "",
  //   liquorSalesM3: sales ? sales.liquorSalesM3 : "",
  //   liquorSalesM3P: sales ? sales.liquorSalesM3P : "",
  //   liquorSalesTotal: sales ? sales.liquorSalesTotal : "",
  //   liquorSalesTotalP: sales ? sales.liquorSalesTotalP : "",

  //   totalSalesM1: sales ? sales.totalSalesM1 : "",
  //   totalSalesM1P: sales ? sales.totalSalesM1P : "",
  //   totalSalesM2: sales ? sales.totalSalesM2 : "",
  //   totalSalesM2P: sales ? sales.totalSalesM2P : "",
  //   totalSalesM3: sales ? sales.totalSalesM3 : "",
  //   totalSalesM3P: sales ? sales.totalSalesM3P : "",
  //   totalSalesTotal: sales ? sales.totalSalesTotal : "",
  //   totalSalesTotalP: sales ? sales.totalSalesTotalP : "",
  // };

  // useEffect(() => {
  //   console.log(initialValues, "this is the initial values");
  //   console.log([sales]);
  // }, []);

  // const handleDispatch = (payload) => {
  //   dispatch({
  //     type: "SET_SALES_DATA",
  //     payload,
  //   });
  // };

  const roundNumberUp = (num) => {
    return (Math.round(num * 100) / 100).toFixed(0);
  };

  const roundNumberUp2DP = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  const makeValZeroIfEmpty = (input) => {
    return input == "" || isNaN(input) ? 0 : input;
  };

  return (
    <div> Here</div>
    // <div>
    //   <div className="col-lg-12 grid-margin stretch-card">
    //     <Formik
    //       initialValues={initialValues}
    //       enableReinitialize={true}
    //       onSubmit={(values, { setSubmitting }) => {
    //         setSubmitting(true);
    //         // Create action and send the data to the database
    //         console.log(values, "Submitted values");
    //         console.log(sales, "Data from the Context");

    //         setTimeout(() => {
    //           setSubmitting(false);
    //         }, 300);
    //       }}
    //     >
    //       {({
    //         values,
    //         errors,
    //         touched,
    //         handleBlur,
    //         handleChange,
    //         handleSubmit,
    //         isSubmitting,
    //         setFieldValue,
    //       }) => (
    //         <form name="sales-data" onSubmit={handleSubmit}>
    //           <div className="table-responsive">
    //             <div>
    //               <table className="table table-bordered">
    //                 <tbody>
    //                   <tr
    //                     style={{
    //                       fontWeight: "bold",
    //                       // paddingLeft: "0px",
    //                       textAlign: "center",
    //                     }}
    //                   >
    //                     <th
    //                       scope="row"
    //                       style={{
    //                         fontWeight: "bold",
    //                         padding: "0px",
    //                         textAlign: "center",
    //                       }}
    //                       width="10%"
    //                     >
    //                       Sales
    //                     </th>
    //                     <td width="10%"></td>
    //                     <td width="10%"> {months.firstMonth}</td>
    //                     <td width="10%">%</td>
    //                     <td width="10%">{months.secondMonth}</td>
    //                     <td width="10%">%</td>
    //                     <td width="10%">{months.thirdMonth}</td>
    //                     <td width="10%">%</td>
    //                     <td width="10%">Total </td>
    //                     <td width="10%">%</td>
    //                   </tr>

    //                   <tr>
    //                     <th width="10%" scope="row">
    //                       {" "}
    //                     </th>
    //                     <td
    //                       width="10%"
    //                       style={{
    //                         padding: "0px",
    //                         textAlign: "center",
    //                       }}
    //                     >
    //                       Food
    //                     </td>
    //                     <td width="10%" className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="foodSalesM1"
    //                         name="foodSalesM1"
    //                         defaultValue={values.foodSalesM1}
    //                         disabled={isSubmitting}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={(e) => {
    //                           setFieldValue(
    //                             "foodSalesM1",
    //                             parseFloat(makeValZeroIfEmpty(e.target.value))
    //                           );

    //                           handleDispatch({
    //                             key: e.target.name,
    //                             value: parseFloat(makeValZeroIfEmpty(e.target.value)),
    //                           });

    //                           const foodSalesTotal =
    //                             parseFloat(makeValZeroIfEmpty(e.target.value)) +
    //                             parseFloat(makeValZeroIfEmpty(values.foodSalesM2)) +
    //                             parseFloat(makeValZeroIfEmpty(values.foodSalesM3));

    //                           handleDispatch({
    //                             key: "foodSalesTotal",
    //                             value: parseFloat(roundNumberUp2DP(foodSalesTotal)),
    //                           });

    //                           const totalSalesM1 =
    //                             parseFloat(makeValZeroIfEmpty(e.target.value)) +
    //                             parseFloat(makeValZeroIfEmpty(values.liquorSalesM1));

    //                           const totalSalesTotal =
    //                             parseFloat(roundNumberUp2DP(totalSalesM1)) +
    //                             parseFloat(makeValZeroIfEmpty(values.totalSalesM2)) +
    //                             parseFloat(makeValZeroIfEmpty(values.totalSalesM3));

    //                           handleDispatch({
    //                             key: "totalSalesTotal",
    //                             value: parseFloat(roundNumberUp2DP(totalSalesTotal)),
    //                           });

    //                           handleDispatch({
    //                             key: "totalSalesM1",
    //                             value: parseFloat(roundNumberUp2DP(totalSalesM1)),
    //                           });

    //                           const foodSalesM1P = (sales.foodSalesM1 / sales.totalSalesM1) * 100;

    //                           handleDispatch({
    //                             key: "foodSalesM1P",
    //                             value: parseFloat(roundNumberUp2DP(foodSalesM1P)),
    //                           });

    //                           setFieldValue(
    //                             "totalSalesTotal",
    //                             parseFloat(roundNumberUp2DP(totalSalesTotal))
    //                           );

    //                           setFieldValue(
    //                             "totalSalesM1",
    //                             parseFloat(roundNumberUp2DP(totalSalesM1))
    //                           );

    //                           setFieldValue(
    //                             "foodSalesM1P",
    //                             parseFloat(roundNumberUp2DP(foodSalesM1P))
    //                           );

    //                           setFieldValue(
    //                             "foodSalesTotal",
    //                             parseFloat(roundNumberUp2DP(foodSalesTotal))
    //                           );

    //                           const foodSalesTotalP =
    //                             (parseFloat(roundNumberUp2DP(foodSalesTotal)) /
    //                               parseFloat(roundNumberUp2DP(totalSalesTotal))) *
    //                             100;

    //                           handleDispatch({
    //                             key: "foodSalesTotalP",
    //                             value: parseFloat(roundNumberUp2DP(foodSalesTotalP)),
    //                           });

    //                           setFieldValue(
    //                             "foodSalesTotalP",
    //                             parseFloat(roundNumberUp2DP(foodSalesTotalP))
    //                           );

    //                           const totalSalesTotalP =
    //                             parseFloat(roundNumberUp2DP(foodSalesTotalP)) +
    //                             parseFloat(makeValZeroIfEmpty(values.liquorSalesTotalP));

    //                           handleDispatch({
    //                             key: "totalSalesTotalP",
    //                             value: parseFloat(roundNumberUp2DP(totalSalesTotalP)),
    //                           });

    //                           setFieldValue(
    //                             "totalSalesTotalP",
    //                             parseFloat(roundNumberUp2DP(totalSalesTotalP))
    //                           );
    //                         }}
    //                       />
    //                     </td>
    //                     <td width="10%" className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="foodSalesM1P"
    //                         name="foodSalesM1P"
    //                         defaultValue={values.foodSalesM1P ? values.foodSalesM1P : ""}
    //                         disabled={"disabled"}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={handleChange}
    //                       />
    //                     </td>
    //                     <td width="10%" className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="foodSalesM2"
    //                         name="foodSalesM2"
    //                         defaultValue={values.foodSalesM2}
    //                         disabled={isSubmitting}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={(e) => {
    //                           setFieldValue(
    //                             "foodSalesM2",
    //                             parseFloat(makeValZeroIfEmpty(e.target.value))
    //                           );

    //                           handleDispatch({
    //                             key: e.target.name,
    //                             value: parseFloat(makeValZeroIfEmpty(e.target.value)),
    //                           });

    //                           const foodSalesTotal =
    //                             parseFloat(makeValZeroIfEmpty(e.target.value)) +
    //                             parseFloat(makeValZeroIfEmpty(sales.foodSalesM1)) +
    //                             parseFloat(makeValZeroIfEmpty(values.foodSalesM3));

    //                           handleDispatch({
    //                             key: "foodSalesTotal",
    //                             value: parseFloat(roundNumberUp2DP(foodSalesTotal)),
    //                           });

    //                           const totalSalesM2 =
    //                             parseFloat(makeValZeroIfEmpty(e.target.value)) +
    //                             parseFloat(makeValZeroIfEmpty(values.liquorSalesM2));

    //                           const totalSalesTotal =
    //                             parseFloat(roundNumberUp2DP(totalSalesM2)) +
    //                             parseFloat(makeValZeroIfEmpty(sales.totalSalesM1)) +
    //                             parseFloat(makeValZeroIfEmpty(values.totalSalesM3));

    //                           handleDispatch({
    //                             key: "totalSalesTotal",
    //                             value: parseFloat(roundNumberUp2DP(totalSalesTotal)),
    //                           });

    //                           handleDispatch({
    //                             key: "totalSalesM2",
    //                             value: parseFloat(roundNumberUp2DP(totalSalesM2)),
    //                           });

    //                           const foodSalesM2P = (sales.foodSalesM2 / sales.totalSalesM2) * 100;

    //                           handleDispatch({
    //                             key: "foodSalesM2P",
    //                             value: parseFloat(roundNumberUp2DP(foodSalesM2P)),
    //                           });

    //                           setFieldValue(
    //                             "totalSalesTotal",
    //                             parseFloat(roundNumberUp2DP(totalSalesTotal))
    //                           );

    //                           setFieldValue(
    //                             "totalSalesM2",
    //                             parseFloat(roundNumberUp2DP(totalSalesM2))
    //                           );

    //                           setFieldValue(
    //                             "foodSalesM2P",
    //                             parseFloat(roundNumberUp2DP(foodSalesM2P))
    //                           );

    //                           setFieldValue(
    //                             "foodSalesTotal",
    //                             parseFloat(roundNumberUp2DP(foodSalesTotal))
    //                           );

    //                           const foodSalesTotalP =
    //                             (parseFloat(roundNumberUp2DP(foodSalesTotal)) /
    //                               parseFloat(roundNumberUp2DP(totalSalesTotal))) *
    //                             100;

    //                           handleDispatch({
    //                             key: "foodSalesTotalP",
    //                             value: parseFloat(roundNumberUp2DP(foodSalesTotalP)),
    //                           });

    //                           setFieldValue(
    //                             "foodSalesTotalP",
    //                             parseFloat(roundNumberUp2DP(foodSalesTotalP))
    //                           );

    //                           const totalSalesTotalP =
    //                             parseFloat(roundNumberUp2DP(foodSalesTotalP)) +
    //                             parseFloat(makeValZeroIfEmpty(values.liquorSalesTotalP));

    //                           handleDispatch({
    //                             key: "totalSalesTotalP",
    //                             value: parseFloat(roundNumberUp2DP(totalSalesTotalP)),
    //                           });

    //                           setFieldValue(
    //                             "totalSalesTotalP",
    //                             parseFloat(roundNumberUp2DP(totalSalesTotalP))
    //                           );
    //                         }}
    //                       />
    //                     </td>
    //                     <td className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="foodSalesM2P"
    //                         name="foodSalesM2P"
    //                         defaultValue={values.foodSalesM2P ? values.foodSalesM2P : ""}
    //                         disabled={"disabled"}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={handleChange}
    //                       />
    //                     </td>
    //                     <td className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="foodSalesM3"
    //                         name="foodSalesM3"
    //                         defaultValue={values.foodSalesM3}
    //                         disabled={isSubmitting}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={(e) => {
    //                           setFieldValue(
    //                             "foodSalesM3",
    //                             parseFloat(makeValZeroIfEmpty(e.target.value))
    //                           );

    //                           handleDispatch({
    //                             key: e.target.name,
    //                             value: parseFloat(makeValZeroIfEmpty(e.target.value)),
    //                           });

    //                           const foodSalesTotal =
    //                             parseFloat(makeValZeroIfEmpty(e.target.value)) +
    //                             parseFloat(makeValZeroIfEmpty(sales.foodSalesM1)) +
    //                             parseFloat(makeValZeroIfEmpty(sales.foodSalesM2));

    //                           handleDispatch({
    //                             key: "foodSalesTotal",
    //                             value: parseFloat(roundNumberUp2DP(foodSalesTotal)),
    //                           });

    //                           const totalSalesM3 =
    //                             parseFloat(makeValZeroIfEmpty(e.target.value)) +
    //                             parseFloat(makeValZeroIfEmpty(values.liquorSalesM3));

    //                           const totalSalesTotal =
    //                             parseFloat(roundNumberUp2DP(totalSalesM3)) +
    //                             parseFloat(makeValZeroIfEmpty(sales.totalSalesM2)) +
    //                             parseFloat(makeValZeroIfEmpty(sales.totalSalesM1));

    //                           handleDispatch({
    //                             key: "totalSalesTotal",
    //                             value: parseFloat(roundNumberUp2DP(totalSalesTotal)),
    //                           });

    //                           handleDispatch({
    //                             key: "totalSalesM3",
    //                             value: parseFloat(roundNumberUp2DP(totalSalesM3)),
    //                           });

    //                           const foodSalesM3P = (sales.foodSalesM3 / sales.totalSalesM3) * 100;

    //                           handleDispatch({
    //                             key: "foodSalesM3P",
    //                             value: parseFloat(roundNumberUp2DP(foodSalesM3P)),
    //                           });

    //                           setFieldValue(
    //                             "totalSalesTotal",
    //                             parseFloat(roundNumberUp2DP(totalSalesTotal))
    //                           );

    //                           setFieldValue(
    //                             "totalSalesM3",
    //                             parseFloat(roundNumberUp2DP(totalSalesM3))
    //                           );

    //                           setFieldValue(
    //                             "foodSalesM3P",
    //                             parseFloat(roundNumberUp2DP(foodSalesM3P))
    //                           );

    //                           setFieldValue(
    //                             "foodSalesTotal",
    //                             parseFloat(roundNumberUp2DP(foodSalesTotal))
    //                           );

    //                           const foodSalesTotalP =
    //                             (parseFloat(roundNumberUp2DP(foodSalesTotal)) /
    //                               parseFloat(roundNumberUp2DP(totalSalesTotal))) *
    //                             100;

    //                           handleDispatch({
    //                             key: "foodSalesTotalP",
    //                             value: parseFloat(roundNumberUp2DP(foodSalesTotalP)),
    //                           });

    //                           setFieldValue(
    //                             "foodSalesTotalP",
    //                             parseFloat(roundNumberUp2DP(foodSalesTotalP))
    //                           );

    //                           const totalSalesTotalP =
    //                             parseFloat(roundNumberUp2DP(foodSalesTotalP)) +
    //                             parseFloat(makeValZeroIfEmpty(values.liquorSalesTotalP));

    //                           handleDispatch({
    //                             key: "totalSalesTotalP",
    //                             value: parseFloat(roundNumberUp2DP(totalSalesTotalP)),
    //                           });

    //                           setFieldValue(
    //                             "totalSalesTotalP",
    //                             parseFloat(roundNumberUp2DP(totalSalesTotalP))
    //                           );
    //                         }}
    //                       />
    //                     </td>
    //                     <td className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="foodSalesM3P"
    //                         name="foodSalesM3P"
    //                         defaultValue={values.foodSalesM3P ? values.foodSalesM3P : ""}
    //                         disabled={"disabled"}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={handleChange}
    //                       />
    //                     </td>
    //                     <td className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="foodSalesTotal"
    //                         name="foodSalesTotal"
    //                         defaultValue={values.foodSalesTotal ? values.foodSalesTotal : ""}
    //                         disabled={"disabled"}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={handleChange}
    //                       />
    //                     </td>
    //                     <td className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="foodSalesTotalP"
    //                         name="foodSalesTotalP"
    //                         defaultValue={values.foodSalesTotalP ? values.foodSalesTotalP : ""}
    //                         disabled={"disabled"}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={handleChange}
    //                       />
    //                     </td>
    //                   </tr>

    //                   <tr>
    //                     <th scope="row"> </th>
    //                     <td
    //                       width="10%"
    //                       style={{
    //                         padding: "0px",
    //                         textAlign: "center",
    //                       }}
    //                     >
    //                       Liquor
    //                     </td>
    //                     <td className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="liquorSalesM1"
    //                         name="liquorSalesM1"
    //                         defaultValue={values.liquorSalesM1}
    //                         disabled={isSubmitting}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={(e) => {
    //                           setFieldValue(
    //                             "liquorSalesM1",
    //                             parseFloat(makeValZeroIfEmpty(e.target.value))
    //                           );

    //                           handleDispatch({
    //                             key: e.target.name,
    //                             value: parseFloat(makeValZeroIfEmpty(e.target.value)),
    //                           });

    //                           const liquorSalesTotal =
    //                             parseFloat(makeValZeroIfEmpty(e.target.value)) +
    //                             parseFloat(makeValZeroIfEmpty(values.liquorSalesM2)) +
    //                             parseFloat(makeValZeroIfEmpty(values.liquorSalesM3));

    //                           const totalSalesM1 =
    //                             parseFloat(makeValZeroIfEmpty(e.target.value)) +
    //                             parseFloat(makeValZeroIfEmpty(sales.foodSalesM1));

    //                           const totalSalesTotal =
    //                             parseFloat(roundNumberUp2DP(totalSalesM1)) +
    //                             parseFloat(makeValZeroIfEmpty(values.totalSalesM2)) +
    //                             parseFloat(makeValZeroIfEmpty(values.totalSalesM3));

    //                           handleDispatch({
    //                             key: "totalSalesTotal",
    //                             value: parseFloat(roundNumberUp2DP(totalSalesTotal)),
    //                           });

    //                           const foodSalesM1P =
    //                             (sales.foodSalesM1 / parseFloat(totalSalesM1)) * 100;

    //                           const liquorSalesM1P =
    //                             (parseFloat(makeValZeroIfEmpty(e.target.value)) /
    //                               parseFloat(totalSalesM1)) *
    //                             100;

    //                           const totalSalesM1P =
    //                             (parseFloat(totalSalesM1) / parseFloat(totalSalesM1)) * 100;

    //                           handleDispatch({
    //                             key: "foodSalesM1P",
    //                             value: parseFloat(roundNumberUp2DP(foodSalesM1P)),
    //                           });

    //                           handleDispatch({
    //                             key: "totalSalesM1",
    //                             value: parseFloat(roundNumberUp2DP(totalSalesM1)),
    //                           });

    //                           handleDispatch({
    //                             key: "liquorSalesM1P",
    //                             value: parseFloat(roundNumberUp2DP(liquorSalesM1P)),
    //                           });

    //                           handleDispatch({
    //                             key: "totalSalesM1P",
    //                             value: parseFloat(roundNumberUp2DP(totalSalesM1P)),
    //                           });

    //                           handleDispatch({
    //                             key: "liquorSalesTotal",
    //                             value: parseFloat(roundNumberUp2DP(liquorSalesTotal)),
    //                           });

    //                           setFieldValue(
    //                             "totalSalesTotal",
    //                             parseFloat(roundNumberUp2DP(totalSalesTotal))
    //                           );

    //                           setFieldValue(
    //                             "totalSalesM1",
    //                             parseFloat(roundNumberUp2DP(totalSalesM1))
    //                           );

    //                           setFieldValue(
    //                             "foodSalesM1P",
    //                             parseFloat(roundNumberUp2DP(foodSalesM1P))
    //                           );

    //                           setFieldValue(
    //                             "liquorSalesM1P",
    //                             parseFloat(roundNumberUp2DP(liquorSalesM1P))
    //                           );

    //                           setFieldValue(
    //                             "totalSalesM1P",
    //                             parseFloat(roundNumberUp2DP(totalSalesM1P))
    //                           );

    //                           setFieldValue(
    //                             "liquorSalesTotal",
    //                             parseFloat(roundNumberUp2DP(liquorSalesTotal))
    //                           );

    //                           const liquorSalesTotalP =
    //                             (parseFloat(roundNumberUp2DP(liquorSalesTotal)) /
    //                               parseFloat(roundNumberUp2DP(totalSalesTotal))) *
    //                             100;

    //                           handleDispatch({
    //                             key: "liquorSalesTotalP",
    //                             value: parseFloat(roundNumberUp2DP(liquorSalesTotalP)),
    //                           });

    //                           setFieldValue(
    //                             "liquorSalesTotalP",
    //                             parseFloat(roundNumberUp2DP(liquorSalesTotalP))
    //                           );

    //                           const foodSalesTotalP =
    //                             (parseFloat(roundNumberUp2DP(sales.foodSalesTotal)) /
    //                               parseFloat(roundNumberUp2DP(totalSalesTotal))) *
    //                             100;

    //                           handleDispatch({
    //                             key: "foodSalesTotalP",
    //                             value: parseFloat(roundNumberUp2DP(foodSalesTotalP)),
    //                           });

    //                           setFieldValue(
    //                             "foodSalesTotalP",
    //                             parseFloat(roundNumberUp2DP(foodSalesTotalP))
    //                           );

    //                           const totalSalesTotalP =
    //                             parseFloat(roundNumberUp2DP(foodSalesTotalP)) +
    //                             parseFloat(makeValZeroIfEmpty(liquorSalesTotalP));

    //                           handleDispatch({
    //                             key: "totalSalesTotalP",
    //                             value: parseFloat(roundNumberUp2DP(totalSalesTotalP)),
    //                           });

    //                           setFieldValue(
    //                             "totalSalesTotalP",
    //                             parseFloat(roundNumberUp2DP(totalSalesTotalP))
    //                           );
    //                         }}
    //                       />
    //                     </td>
    //                     <td className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="liquorSalesM1P"
    //                         name="liquorSalesM1P"
    //                         defaultValue={values.liquorSalesM1P ? values.liquorSalesM1P : ""}
    //                         disabled={"disabled"}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={handleChange}
    //                       />
    //                     </td>
    //                     <td className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="liquorSalesM2"
    //                         name="liquorSalesM2"
    //                         defaultValue={values.liquorSalesM2 ? values.liquorSalesM2 : ""}
    //                         disabled={isSubmitting}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={(e) => {
    //                           setFieldValue(
    //                             "liquorSalesM2",
    //                             parseFloat(makeValZeroIfEmpty(e.target.value))
    //                           );

    //                           handleDispatch({
    //                             key: e.target.name,
    //                             value: parseFloat(makeValZeroIfEmpty(e.target.value)),
    //                           });

    //                           const liquorSalesTotal =
    //                             parseFloat(makeValZeroIfEmpty(e.target.value)) +
    //                             parseFloat(makeValZeroIfEmpty(sales.liquorSalesM1)) +
    //                             parseFloat(makeValZeroIfEmpty(values.liquorSalesM3));

    //                           const totalSalesM2 =
    //                             parseFloat(makeValZeroIfEmpty(e.target.value)) +
    //                             parseFloat(makeValZeroIfEmpty(sales.foodSalesM2));

    //                           const totalSalesTotal =
    //                             parseFloat(roundNumberUp2DP(totalSalesM2)) +
    //                             parseFloat(makeValZeroIfEmpty(sales.totalSalesM1)) +
    //                             parseFloat(makeValZeroIfEmpty(values.totalSalesM3));

    //                           handleDispatch({
    //                             key: "totalSalesTotal",
    //                             value: parseFloat(roundNumberUp2DP(totalSalesTotal)),
    //                           });

    //                           const liquorSalesTotalP =
    //                             (parseFloat(roundNumberUp2DP(liquorSalesTotal)) /
    //                               parseFloat(roundNumberUp2DP(totalSalesTotal))) *
    //                             100;

    //                           handleDispatch({
    //                             key: "liquorSalesTotalP",
    //                             value: parseFloat(roundNumberUp2DP(liquorSalesTotalP)),
    //                           });

    //                           setFieldValue(
    //                             "liquorSalesTotalP",
    //                             parseFloat(roundNumberUp2DP(liquorSalesTotalP))
    //                           );

    //                           const foodSalesM2P =
    //                             (sales.foodSalesM2 / parseFloat(totalSalesM2)) * 100;

    //                           const liquorSalesM2P =
    //                             (parseFloat(makeValZeroIfEmpty(e.target.value)) /
    //                               parseFloat(totalSalesM2)) *
    //                             100;

    //                           const totalSalesM2P =
    //                             (parseFloat(totalSalesM2) / parseFloat(totalSalesM2)) * 100;

    //                           handleDispatch({
    //                             key: "foodSalesM2P",
    //                             value: parseFloat(roundNumberUp2DP(foodSalesM2P)),
    //                           });

    //                           handleDispatch({
    //                             key: "totalSalesM2",
    //                             value: parseFloat(roundNumberUp2DP(totalSalesM2)),
    //                           });

    //                           handleDispatch({
    //                             key: "liquorSalesM2P",
    //                             value: parseFloat(roundNumberUp2DP(liquorSalesM2P)),
    //                           });

    //                           handleDispatch({
    //                             key: "totalSalesM2P",
    //                             value: parseFloat(roundNumberUp2DP(totalSalesM2P)),
    //                           });

    //                           handleDispatch({
    //                             key: "liquorSalesTotal",
    //                             value: parseFloat(roundNumberUp2DP(liquorSalesTotal)),
    //                           });

    //                           setFieldValue(
    //                             "totalSalesTotal",
    //                             parseFloat(roundNumberUp2DP(totalSalesTotal))
    //                           );

    //                           setFieldValue(
    //                             "totalSalesM2",
    //                             parseFloat(roundNumberUp2DP(totalSalesM2))
    //                           );

    //                           setFieldValue(
    //                             "foodSalesM2P",
    //                             parseFloat(roundNumberUp2DP(foodSalesM2P))
    //                           );

    //                           setFieldValue(
    //                             "liquorSalesM2P",
    //                             parseFloat(roundNumberUp2DP(liquorSalesM2P))
    //                           );

    //                           setFieldValue(
    //                             "totalSalesM2P",
    //                             parseFloat(roundNumberUp2DP(totalSalesM2P))
    //                           );

    //                           setFieldValue(
    //                             "liquorSalesTotal",
    //                             parseFloat(roundNumberUp2DP(liquorSalesTotal))
    //                           );

    //                           const foodSalesTotalP =
    //                             (parseFloat(roundNumberUp2DP(sales.foodSalesTotal)) /
    //                               parseFloat(roundNumberUp2DP(totalSalesTotal))) *
    //                             100;

    //                           handleDispatch({
    //                             key: "foodSalesTotalP",
    //                             value: parseFloat(roundNumberUp2DP(foodSalesTotalP)),
    //                           });

    //                           setFieldValue(
    //                             "foodSalesTotalP",
    //                             parseFloat(roundNumberUp2DP(foodSalesTotalP))
    //                           );

    //                           const totalSalesTotalP =
    //                             parseFloat(roundNumberUp2DP(foodSalesTotalP)) +
    //                             parseFloat(makeValZeroIfEmpty(liquorSalesTotalP));

    //                           handleDispatch({
    //                             key: "totalSalesTotalP",
    //                             value: parseFloat(roundNumberUp2DP(totalSalesTotalP)),
    //                           });

    //                           setFieldValue(
    //                             "totalSalesTotalP",
    //                             parseFloat(roundNumberUp2DP(totalSalesTotalP))
    //                           );
    //                         }}
    //                       />
    //                     </td>

    //                     <td className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="liquorSalesM2P"
    //                         name="liquorSalesM2P"
    //                         defaultValue={values.liquorSalesM2P ? values.liquorSalesM2P : ""}
    //                         disabled={"disabled"}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={handleChange}
    //                       />
    //                     </td>

    //                     <td className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="liquorSalesM3"
    //                         name="liquorSalesM3"
    //                         defaultValue={values.liquorSalesM3 ? values.liquorSalesM3 : ""}
    //                         disabled={isSubmitting}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={(e) => {
    //                           setFieldValue(
    //                             "liquorSalesM3",
    //                             parseFloat(makeValZeroIfEmpty(e.target.value))
    //                           );

    //                           handleDispatch({
    //                             key: e.target.name,
    //                             value: parseFloat(makeValZeroIfEmpty(e.target.value)),
    //                           });

    //                           const liquorSalesTotal =
    //                             parseFloat(makeValZeroIfEmpty(e.target.value)) +
    //                             parseFloat(makeValZeroIfEmpty(sales.liquorSalesM1)) +
    //                             parseFloat(makeValZeroIfEmpty(sales.liquorSalesM2));

    //                           const totalSalesM3 =
    //                             parseFloat(makeValZeroIfEmpty(e.target.value)) +
    //                             parseFloat(makeValZeroIfEmpty(sales.foodSalesM3));

    //                           const totalSalesTotal =
    //                             parseFloat(roundNumberUp2DP(totalSalesM3)) +
    //                             parseFloat(makeValZeroIfEmpty(sales.totalSalesM2)) +
    //                             parseFloat(makeValZeroIfEmpty(sales.totalSalesM1));

    //                           handleDispatch({
    //                             key: "totalSalesTotal",
    //                             value: parseFloat(roundNumberUp2DP(totalSalesTotal)),
    //                           });

    //                           const liquorSalesTotalP =
    //                             (parseFloat(roundNumberUp2DP(liquorSalesTotal)) /
    //                               parseFloat(roundNumberUp2DP(totalSalesTotal))) *
    //                             100;

    //                           handleDispatch({
    //                             key: "liquorSalesTotalP",
    //                             value: parseFloat(roundNumberUp2DP(liquorSalesTotalP)),
    //                           });

    //                           setFieldValue(
    //                             "liquorSalesTotalP",
    //                             parseFloat(roundNumberUp2DP(liquorSalesTotalP))
    //                           );

    //                           const foodSalesM3P =
    //                             (sales.foodSalesM3 / parseFloat(totalSalesM3)) * 100;

    //                           const liquorSalesM3P =
    //                             (parseFloat(makeValZeroIfEmpty(e.target.value)) /
    //                               parseFloat(totalSalesM3)) *
    //                             100;

    //                           const totalSalesM3P =
    //                             (parseFloat(totalSalesM3) / parseFloat(totalSalesM3)) * 100;

    //                           handleDispatch({
    //                             key: "foodSalesM3P",
    //                             value: parseFloat(roundNumberUp2DP(foodSalesM3P)),
    //                           });

    //                           handleDispatch({
    //                             key: "totalSalesM3",
    //                             value: parseFloat(roundNumberUp2DP(totalSalesM3)),
    //                           });

    //                           handleDispatch({
    //                             key: "liquorSalesM3P",
    //                             value: parseFloat(roundNumberUp2DP(liquorSalesM3P)),
    //                           });

    //                           handleDispatch({
    //                             key: "totalSalesM3P",
    //                             value: parseFloat(roundNumberUp2DP(totalSalesM3P)),
    //                           });

    //                           handleDispatch({
    //                             key: "liquorSalesTotal",
    //                             value: parseFloat(roundNumberUp2DP(liquorSalesTotal)),
    //                           });

    //                           setFieldValue(
    //                             "totalSalesTotal",
    //                             parseFloat(roundNumberUp2DP(totalSalesTotal))
    //                           );

    //                           setFieldValue(
    //                             "totalSalesM3",
    //                             parseFloat(roundNumberUp2DP(totalSalesM3))
    //                           );

    //                           setFieldValue(
    //                             "foodSalesM3P",
    //                             parseFloat(roundNumberUp2DP(foodSalesM3P))
    //                           );

    //                           setFieldValue(
    //                             "liquorSalesM3P",
    //                             parseFloat(roundNumberUp2DP(liquorSalesM3P))
    //                           );

    //                           setFieldValue(
    //                             "totalSalesM3P",
    //                             parseFloat(roundNumberUp2DP(totalSalesM3P))
    //                           );

    //                           setFieldValue(
    //                             "liquorSalesTotal",
    //                             parseFloat(roundNumberUp2DP(liquorSalesTotal))
    //                           );

    //                           const foodSalesTotalP =
    //                             (parseFloat(roundNumberUp2DP(sales.foodSalesTotal)) /
    //                               parseFloat(roundNumberUp2DP(totalSalesTotal))) *
    //                             100;

    //                           handleDispatch({
    //                             key: "foodSalesTotalP",
    //                             value: parseFloat(roundNumberUp2DP(foodSalesTotalP)),
    //                           });

    //                           setFieldValue(
    //                             "foodSalesTotalP",
    //                             parseFloat(roundNumberUp2DP(foodSalesTotalP))
    //                           );

    //                           const totalSalesTotalP =
    //                             parseFloat(roundNumberUp2DP(foodSalesTotalP)) +
    //                             parseFloat(makeValZeroIfEmpty(liquorSalesTotalP));

    //                           handleDispatch({
    //                             key: "totalSalesTotalP",
    //                             value: parseFloat(roundNumberUp2DP(totalSalesTotalP)),
    //                           });

    //                           setFieldValue(
    //                             "totalSalesTotalP",
    //                             parseFloat(roundNumberUp2DP(totalSalesTotalP))
    //                           );
    //                         }}
    //                       />
    //                     </td>

    //                     <td className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="liquorSalesM3P"
    //                         name="liquorSalesM3P"
    //                         defaultValue={values.liquorSalesM3P ? values.liquorSalesM3P : ""}
    //                         disabled={"disabled"}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={handleChange}
    //                       />
    //                     </td>

    //                     <td className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="liquorSalesTotal"
    //                         name="liquorSalesTotal"
    //                         defaultValue={values.liquorSalesTotal ? values.liquorSalesTotal : ""}
    //                         disabled={"disabled"}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={handleChange}
    //                       />
    //                     </td>

    //                     <td className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="liquorSalesTotalP"
    //                         name="liquorSalesTotalP"
    //                         defaultValue={values.liquorSalesTotalP ? values.liquorSalesTotalP : ""}
    //                         disabled={"disabled"}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={handleChange}
    //                       />
    //                     </td>
    //                   </tr>
    //                   <tr>
    //                     <th scope="row"> </th>
    //                     <td
    //                       width="10%"
    //                       style={{
    //                         padding: "0px",
    //                         textAlign: "center",
    //                       }}
    //                     >
    //                       Total Sales
    //                     </td>
    //                     <td className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="totalSalesM1"
    //                         name="totalSalesM1"
    //                         defaultValue={values.totalSalesM1 ? values.totalSalesM1 : ""}
    //                         disabled={"disabled"}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={handleChange}
    //                       />
    //                     </td>
    //                     <td className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="totalSalesM1P"
    //                         name="totalSalesM1P"
    //                         defaultValue={values.totalSalesM1P ? values.totalSalesM1P : ""}
    //                         disabled={"disabled"}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={handleChange}
    //                       />
    //                     </td>
    //                     <td className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="totalSalesM2"
    //                         name="totalSalesM2"
    //                         defaultValue={values.totalSalesM2 ? values.totalSalesM2 : ""}
    //                         disabled={"disabled"}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={handleChange}
    //                       />
    //                     </td>
    //                     <td className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="totalSalesM2P"
    //                         name="totalSalesM2P"
    //                         defaultValue={values.totalSalesM2P ? values.totalSalesM2P : ""}
    //                         disabled={"disabled"}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={handleChange}
    //                       />
    //                     </td>
    //                     <td className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="totalSalesM3"
    //                         name="totalSalesM3"
    //                         defaultValue={values.totalSalesM3 ? values.totalSalesM3 : ""}
    //                         disabled={"disabled"}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={handleChange}
    //                       />
    //                     </td>
    //                     <td className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="totalSalesM3P"
    //                         name="totalSalesM3P"
    //                         defaultValue={values.totalSalesM3P ? values.totalSalesM3P : ""}
    //                         disabled={"disabled"}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={handleChange}
    //                       />
    //                     </td>
    //                     <td className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="totalSalesTotal"
    //                         name="totalSalesTotal"
    //                         defaultValue={values.totalSalesTotal ? values.totalSalesTotal : ""}
    //                         disabled={"disabled"}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={handleChange}
    //                       />
    //                     </td>
    //                     <td className="bonus-input-wrapper">
    //                       <Form.Control
    //                         type="number"
    //                         className="form-control"
    //                         id="totalSalesTotalP"
    //                         name="totalSalesTotalP"
    //                         defaultValue={values.totalSalesTotalP ? values.totalSalesTotalP : ""}
    //                         disabled={"disabled"}
    //                         autoComplete="off"
    //                         autoCorrect="off"
    //                         autoCapitalize="off"
    //                         spellCheck="false"
    //                         onChange={handleChange}
    //                       />
    //                     </td>
    //                   </tr>
    //                 </tbody>
    //               </table>
    //             </div>
    //           </div>

    //           <div>
    //             {/* <LPSubmitButton className="btn btn-primary" type="submit">
    //               {" "}
    //               {isSubmitting ? "Updating..." : "Update Sales"}
    //             </LPSubmitButton> */}
    //           </div>
    //         </form>
    //       )}
    //     </Formik>
    //   </div>
    // </div>
  );
};

export default Sales;
