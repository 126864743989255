import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Form } from "react-bootstrap";
import NumberFormat from "react-number-format";
import "react-datepicker/dist/react-datepicker.css";
import cogoToast from "cogo-toast";
import { ExpensesService } from "../../service/ExpenseService";
import { useHistory, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { ConvertDateFormat } from "../../components/ConvertDateFormat";

class EmergencyReportHistoryPDFDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emergencyReport: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    if (this.props.id !== "undefined" && this.props.date !== "undefined") {
      let data = { store_id: this.props.id, report_date: this.props.date };
      ExpensesService.getEmergencyExpensesForAStorePDF(data)
        .then((response) => {
          this.setState({
            emergencyReport: response.data.data,
            isLoading: false,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    } else {
      cogoToast.error("No data to preview here");
      setTimeout(() => {
        window.location.href =
          "/laparrila/restaurant-manager/financials/view-store-report";
      }, [2000]);
    }
  }
  roundNumberUp = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  render() {
    const { emergencyReport, isLoading } = this.state;
    return (
      <section id="downloadPreview">
        {!isLoading ? (
          <div>
            {emergencyReport && emergencyReport.length > 0 ? (
              <div>
                <div className="row">
                  <div
                    className="col-sm-6 grid-margin"
                    style={{ marginBottom: "0px" }}
                  >
                    <h4 className="card-title">Emergency Report</h4>
                    <div>
                      <Form.Group className="row">
                        <label
                          htmlFor="store"
                          className="col-sm-4 col-form-label"
                        >
                          Parrilla #
                        </label>
                        <div className="col-sm-8">
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="store"
                            name="store"
                            defaultValue={
                              emergencyReport ? emergencyReport.store_id : ""
                            }
                            placeholder="Location"
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                          />
                        </div>
                      </Form.Group>

                      <Form.Group className="row">
                        <label
                          htmlFor="exampleInputMobile"
                          className="col-sm-4 col-form-label"
                        >
                          Date
                        </label>
                        <div className="col-sm-8">
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="report_date"
                            name="report_date"
                            value={
                              emergencyReport
                                ? ConvertDateFormat(emergencyReport.date)
                                : ""
                            }
                            placeholder="Report Date"
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div style={{ paddingTop: "60px" }}>
                      <img
                        src={require("../../assets/images/closing_report_logo.png")}
                        alt="logo"
                        style={{
                          display: "block",
                          // marginRight: "auto",
                          margin: "0 auto",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <div>
                      <p> Emergency Report </p>
                      <table
                        className="table table-bordered"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}> Code </th>
                            <th style={{ width: "5%" }}> Hours </th>
                            <th style={{ width: "10%" }}> Tips Owed </th>
                            <th style={{ width: "25%" }}> Employee Name </th>
                            <th style={{ width: "25%" }}> Base Location </th>
                          </tr>
                        </thead>
                        <tbody>
                          {emergencyReport &&
                            emergencyReport.map((tip, index) => (
                              <tr key={tip.id}>
                                <td> {tip.emergency_code} </td>
                                <td>{tip.total_hours}</td>
                                <td>
                                  <NumberFormat
                                    value={this.roundNumberUp(tip.tips_owed)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                </td>
                                <td>{tip.employee_name}</td>
                                <td>{tip.base_location}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <p> This is an empty report </p>
              </div>
            )}
          </div>
        ) : (
          <div>
            <span className="fa fa-spin fa-spinner fa-4x"></span>
          </div>
        )}
      </section>
    );
  }
}

const ViewEmergencyReportHistory = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // onAfterPrint: () => {
    //   alert("This has been printed");
    // },
  });

  const history = useHistory();

  return (
    <div>
      <section className="content">
        <div>
          <Link to={"/financials/emergency-code-report"}>
            <button className="btn btn-primary"> View All Reports</button>
          </Link>
        </div>
        <div className="page">
          <EmergencyReportHistoryPDFDownload
            ref={componentRef}
            id={
              history.location.state
                ? history.location.state.store_id
                : "undefined"
            }
            date={
              history.location.state
                ? history.location.state.report_date
                : "undefined"
            }
          />
          <button onClick={handlePrint} className="btn btn-primary mt-5">
            Print &nbsp;&nbsp; <span className="mdi mdi-printer"></span>
          </button>
        </div>
      </section>
    </div>
  );
};

export default withRouter(ViewEmergencyReportHistory);
