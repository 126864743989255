import Api from "./Api";

export class UserService {
  static async getEmployees() {
    return new Promise((resolve, reject) => {
      return Api.get("employee/account")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getEmployeeLocationHistories(user_slug) {
    return await Api.get(`employee/account/location-history/${user_slug}`);

    // return new Promise((resolve, reject) => {
    //   return Api.get("employee/account")
    //     .then((response) => {
    //       resolve(response);
    //     })
    //     .catch((error) => {
    //       reject(error);
    //     });
    // });
  }

  static async getOtherEmployees() {
    return new Promise((resolve, reject) => {
      return Api.get("employee/account/other-employees-account")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getAllManagers() {
    return new Promise((resolve, reject) => {
      return Api.get("employee/account/all-managers")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getRestaurantManagers() {
    return new Promise((resolve, reject) => {
      return Api.get("employee/account/restaurant-managers")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getRestaurantManagersUsingStoreId(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`employee/account/restaurant-managers-store-id/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getSelectedRestaurantManagers(data) {
    return new Promise((resolve, reject) => {
      return Api.get(`employee/account/selected-restaurant-managers/${data.id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getDistrictManagers() {
    return new Promise((resolve, reject) => {
      return Api.get("employee/account/district-managers")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async createEmployee(data) {
    const {
      // employee_number,
      first_name,
      last_name,
      middle_name,
      pay_type,
      pay_schedule,
      ethnicity,
      phone_number,
      email,
      date_of_employment,
      employment_status,
      role,
      job_status,
      job_code_id,
      store_id,
      manager_type,
      salary,
    } = data;
    return new Promise((resolve, reject) => {
      return Api.post("employee/account/create", {
        // employee_number,
        first_name,
        last_name,
        middle_name,
        pay_type,
        pay_schedule,
        ethnicity,
        phone_number,
        email,
        date_of_employment,
        employment_status,
        role,
        job_status,
        job_code_id,
        store_id,
        manager_type,
        salary,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async bulkEmployeeUpload(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`employee/account/bulk-employee-upload`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async editEmployee(data) {
    const {
      first_name,
      last_name,
      middle_name,
      email,
      date_of_employment,
      role,
      job_status,
      job_code_id,
      // store_id,
      slug,
    } = data;
    console.log(data);
    return new Promise((resolve, reject) => {
      return Api.put(`employee/account/update/${slug}`, {
        first_name,
        last_name,
        middle_name,
        email,
        date_of_employment,
        role,
        job_status,
        job_code_id,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getEmployeeUsingSlug(slug) {
    return new Promise((resolve, reject) => {
      return Api.get(`employee/account/show/${slug}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getEmployeeUsingId(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`employee/account/show-user/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async activateEmployee(slug) {
    return new Promise((resolve, reject) => {
      return Api.post(`employee/account/activate/${slug}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async terminateEmployee(slug, values) {
    const { reason, lastWorkingDay, eligibleForRehire, explanationNoRehire, expectedReturnDate } =
      values;
    return new Promise((resolve, reject) => {
      return Api.post(`employee/account/terminate/${slug}`, {
        reason,
        lastWorkingDay,
        eligibleForRehire,
        explanationNoRehire,
        expectedReturnDate,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async archiveEmployee(slug) {
    return new Promise((resolve, reject) => {
      return Api.post(`employee/account/archive/${slug}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async deleteEmployee(slug) {
    return new Promise((resolve, reject) => {
      return Api.post(`employee/account/delete/${slug}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async createBankDetails(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`employee/account/create/bank`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async updateBankDetails(data) {
    return new Promise((resolve, reject) => {
      return Api.put(`employee/account/update/bank/${data.bank_id}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async createEmergencyContact(values) {
    let data = {
      user_id: values.user_id,
      contact_name: values.contact_name,
      contact_phone_number: values.contact_phone_number,
      relationship: values.relationship,
      email: values.email,
    };

    return new Promise((resolve, reject) => {
      return Api.post(`employee/account/create/emergency`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async updateEmergencyContact(values) {
    let data = {
      contact_name: values.contact_name,
      contact_phone_number: values.contact_phone_number,
      relationship: values.relationship,
      email: values.email,
    };
    return new Promise((resolve, reject) => {
      return Api.put(`employee/account/update/emergency/${values.id}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async updatePersonalDetails(values) {
    let data = {
      user_id: values.user_id,
      user_slug: values.user_slug,
      first_name: values.first_name,
      middle_name: values.middle_name,
      last_name: values.last_name,
      email: values.email,
      dob: values.dob,
      // tax_jurisdiction: values.tax_jurisdiction,
      state_marital_status: values.state_marital_status,
      // federal_marital_status: values.federal_marital_status,
      gender: values.gender,
      nickname: values.nickname,
      ethnic_group: values.ethnic_group,
      ssn: values.ssn,
      // bio_data: values.bio_data,
      phone_number: values.phone_number,
      address1: values.address1,
      address2: values.address2,
      street_number_name: values.street_number_name,
      apartment: values.apartment,
      state_abbreviation: values.state_abbreviation,
      postal_code: values.postal_code,
      country: values.country,
      city: values.city,
      number_of_dependents: values.number_of_dependents,
    };
    return new Promise((resolve, reject) => {
      return Api.put(`employee/account/personal/${values.user_slug}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async updateCompanyDetails(values) {
    let data = {
      employment_status: values.employment_status,
      job_status: values.job_status,
      date_hired: values.date_hired,
      payroll_id_number: values.payroll_id_number,
      primary_location_code: values.primary_location_code,
      uniform_deduction_amount: values.uniform_deduction_amount,
      meal_program_deduction: values.meal_program_deduction,
      pay_type: values.pay_type,
      pay_schedule: values.pay_schedule,
      skills_earned: values.skills_earned,
      thanks_badges: values.thanks_badges,
      reason_for_termination: values.reason_for_termination,
      eligible_for_rehire: values.eligible_for_rehire,
      manager_id: values.manager_id,
      union_code: values.union_code,
      minor_labour_program_code: values.minor_labour_program_code,
      salary_amount: values.salary_amount,
      sick_leave_hours: values.sick_leave_hours,
      part_time_flag: values.part_time_flag,
      break_waiver: values.break_waiver,
      next_review_date: values.next_review_date,
      broadcast_schedule_flag: values.broadcast_schedule_flag,
      store_id: values.store_id,
      employee_number: values.employee_number,
      role: values.role,
      manager_type: values.manager_type,
      salary: values.salary,
    };
    return new Promise((resolve, reject) => {
      return Api.put(`employee/account/company/${values.user_slug}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async createNote(values) {
    const { user_id, note, creator_id } = values;

    return new Promise((resolve, reject) => {
      return Api.post(`employee/account/create/note`, {
        user_id,
        note,
        creator_id,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async createEmployeeCase(values) {
    let data = {
      owner: values.owner,
      creator: values.creator,
      summary: values.summary,
    };

    return new Promise((resolve, reject) => {
      return Api.post(`employee/account/create/case`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async editEmployeeCase(values) {
    let data = {
      summary: values.summary,
      status: values.status,
    };
    return new Promise((resolve, reject) => {
      return Api.put(`employee/account/edit/case/${values.id}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async createCaseComment(values) {
    const { user_id, note, creator_id } = values;

    return new Promise((resolve, reject) => {
      return Api.post(`employee/account/create/note`, {
        user_id,
        note,
        creator_id,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async uploadDocuments(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`employee/account/upload-documents`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async deleteUploadedDocument(data) {
    console.log(data);
    return new Promise((resolve, reject) => {
      return Api.post(`employee/account/delete-document`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async viewSSN(data) {
    console.log(data);
    return new Promise((resolve, reject) => {
      return Api.post(`employee/account/view-ssn`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async uploadSalesReport(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`employee/account/upload-sales-report`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async uploadNewSalesReport(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`employee/account/upload-new-sales-report`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async uploadBulkBonusDetails(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`bonus-payout/bulk-upload`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async uploadTipIncomeReport(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`employee/account/upload-tip-income-report`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async uploadTipDetails(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`employee/account/upload-tip-details`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async uploadAtoDoorDashTips(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`employee/account/upload-ato-door-dash`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async moveEmployee(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`employee/account/move-employee`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getApplicants() {
    return new Promise((resolve, reject) => {
      return Api.get("employees/application/entries")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getAcknowledgedApplicants() {
    return new Promise((resolve, reject) => {
      return Api.get("employees/application/acknowledged")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getPendingApplicants() {
    return new Promise((resolve, reject) => {
      return Api.get("employees/application/pending")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getHiredApplicants() {
    return new Promise((resolve, reject) => {
      return Api.get("employees/application/hired")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getNotHiredApplicants() {
    return new Promise((resolve, reject) => {
      return Api.get("employees/application/not-hired")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getApplicantsForStore(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`employees/application/store/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async processApplication(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`employees/process/application`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async acknowledgeApplicant(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`employees/acknowledge/applicant`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
