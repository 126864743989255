import Api from "./Api";

export class SystemConfigService {
  static async getDomains() {
    return new Promise((resolve, reject) => {
      return Api.get("settings/domain/view")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getDomain(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`settings/domain/view/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async createDomain(name, url) {
    return new Promise((resolve, reject) => {
      return Api.post("settings/domain/create", { name, url })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async updateDomain(values) {
    let data = {
      name: values.name,
      url: values.url,
      domain_name: values.domain_name,
      active: values.active,
      id: values.id,
    };
    return new Promise((resolve, reject) => {
      return Api.put(`settings/domain/update/${data.id}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getJobCodes() {
    return new Promise((resolve, reject) => {
      return Api.get("settings/job-code/view")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getJobCode(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`settings/job-code/view/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async createJobCode(values) {
    let data = {
      name: values.name,
      pos_code: values.pos_code,
      crunch_time_code: values.crunch_time_code,
      payroll_code: values.payroll_code,
    };
    return new Promise((resolve, reject) => {
      return Api.post("settings/job-code/create", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async updateJobCode(values) {
    let data = {
      id: values.id,
      name: values.name,
      pos_code: values.pos_code,
      crunch_time_code: values.crunch_time_code,
      payroll_code: values.payroll_code,
    };
    return new Promise((resolve, reject) => {
      return Api.put(`settings/job-code/update/${data.id}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getStores() {
    return new Promise((resolve, reject) => {
      return Api.get("settings/store/view")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getStoresForManagerAdmin() {
    return new Promise((resolve, reject) => {
      return Api.get(`settings/store/admin-manager-view`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async selectStoreForManager(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`settings/store/select-store-manager`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getPermittedStoreForManagerAdmin(data) {
    return new Promise((resolve, reject) => {
      return Api.get(`settings/store/permitted-stores/${data.id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getPermittedStoreForTempPerm(data) {
    return new Promise((resolve, reject) => {
      return Api.get(`settings/store/temp-perm-permitted-stores/${data.id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getStoreForManager() {
    return new Promise((resolve, reject) => {
      return Api.get("settings/store/view-manager")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getStore(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`settings/store/view/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async createStore(data) {
    const {
      area,
      location_id,
      name,
      address,
      city,
      state,
      email,
      manager_id,
      states_code,
      telephone_number,
      corporation_id,
      pos_code,
      crunch_time_code,
      payroll_code,
      payments_options,
      emergency_code_payments_options,
      catering_payments_options,
      ato_code_payments_options,
    } = data;
    return new Promise((resolve, reject) => {
      return Api.post("settings/store/create", {
        area,
        location_id,
        name,
        address,
        city,
        state,
        email,
        manager_id,
        states_code,
        telephone_number,
        corporation_id,
        pos_code,
        crunch_time_code,
        payroll_code,
        payments_options,
        emergency_code_payments_options,
        catering_payments_options,
        ato_code_payments_options,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async updateStore(data) {
    const {
      area,
      name,
      address,
      city,
      state,
      email,
      manager_id,
      states_code,
      telephone_number,
      corporation_id,
      pos_code,
      crunch_time_code,
      payroll_code,
      payments_options,
      emergency_code_payments_options,
      catering_payments_options,
      // ato_code_payments_options,
    } = data;
    return new Promise((resolve, reject) => {
      return Api.put(`settings/store/update/${data.id}`, {
        area,
        name,
        address,
        city,
        state,
        email,
        manager_id,
        states_code,
        telephone_number,
        corporation_id,
        pos_code,
        crunch_time_code,
        payroll_code,
        payments_options,
        emergency_code_payments_options,
        catering_payments_options,
        // ato_code_payments_options,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getCorporations() {
    return new Promise((resolve, reject) => {
      return Api.get("settings/corporation/view")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getCorporation(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`settings/corporation/view/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async createCorporation(data) {
    const { name, headquarters_address, state } = data;
    return new Promise((resolve, reject) => {
      return Api.post("settings/corporation/create", {
        name,
        headquarters_address,
        state,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async updateCorporation(data) {
    const { name, headquarters_address, state } = data;
    return new Promise((resolve, reject) => {
      return Api.put(`settings/corporation/update/${data.id}`, {
        name,
        headquarters_address,
        state,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getDistricts() {
    return new Promise((resolve, reject) => {
      return Api.get("settings/district/view")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getDistrict(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`settings/district/view/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async createDistrict(data) {
    return new Promise((resolve, reject) => {
      return Api.post("settings/district/create", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async updateDistrict(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`settings/district/update/`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async createBonusConfig(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`settings/bonus/create/`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async configBonusForStore(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`settings/bonus/config-store/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async updateOrCreateBonusKpi(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`settings/bonus/create-bonus-kpi`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getBonusKpi(type) {
    return new Promise((resolve, reject) => {
      return Api.get(`settings/bonus/get-bonus-kpi/${type}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
