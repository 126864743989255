export const LOCATION_OPTIONS = [
  { value: "1", label: "La Parrilla (Marietta Square)" },
  { value: "2", label: "La Parrilla (Dallas Hwy)" },
  { value: "3", label: "La Parrilla (Flowery Branch)" },
  { value: "4", label: "La Parrilla (Powder Springs)" },
  { value: "5", label: "La Parrilla (Fayetteville)" },
  { value: "6", label: "La Parrilla (Alpharetta)" },
  { value: "7", label: "La Parrilla (Woodstock)" },
  { value: "8", label: "La Parrilla (Lawrenceville)" },
  { value: "9", label: "La Parrilla (Acworth)" },
  { value: "10", label: "La Parrilla (Newnan)" },
  { value: "11", label: "La Parrilla (McDonough)" },
  { value: "12", label: "La Parrilla (Howell Mill)" },
  { value: "13", label: "La Parrilla (Dothan)" },
  { value: "14", label: "La Parrilla (Norcross)" },
  { value: "15", label: "La Parrilla (Canton)" },
  { value: "16", label: "La Parrilla (Macon)" },
  { value: "17", label: "La Parrilla (Greenville)" },
  { value: "18", label: "La Parrilla (Toco Hills)" },
  { value: "19", label: "La Parrilla (Savannah)" },
  { value: "20", label: "La Parrilla (Griffin)" },
  { value: "21", label: "La Parrilla (Rome)" },
];
