import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BonusPayoutService } from "../../../../service/BonusPayoutService";
import Swal from "sweetalert2";
import { useHistory, withRouter } from "react-router";
import EnglishSummary from "./EnglishSummary";
import SpanishSummary from "./SpanishSummary";

const ResponseSummary = () => {
  const [surveyDetails, setSurveyDetails] = useState(null);
  const [lang, setLang] = useState(null);

  const history = useHistory();

  const handleSurveySubmit = () => {
    BonusPayoutService.submitSurveyResponses(surveyDetails)
      .then((response) => {
        console.log(response, "All responses");
        Swal.fire({
          title: "Good Job!",
          text: "Survey completed and submitted successfully",
          icon: "success",
          confirmButtonText: "Ok",
          allowEscapeKey: false,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.close();
            localStorage.removeItem("rateMyBossDetails");
            history.push("/employees/rate-your-leaders");
          }
        });
      })
      .catch((error) => {
        console.log(error.response.data.message);
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "Ok",
          allowEscapeKey: false,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.close();
          }
        });
      });
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("rateMyBossDetails"))) {
      setSurveyDetails(JSON.parse(localStorage.getItem("rateMyBossDetails")));
    }

    if (JSON.parse(localStorage.getItem("surveyLang"))) {
      setLang(JSON.parse(localStorage.getItem("surveyLang")));
    }
  }, []);

  return (
    <div
      style={{
        padding: "30px",
      }}
    >
      <div className="card">
        {surveyDetails ? (
          <div>
            {lang && lang === "spanish" ? (
              <SpanishSummary
                surveyDetails={surveyDetails}
                handleSurveySubmit={handleSurveySubmit}
              />
            ) : (
              <EnglishSummary
                surveyDetails={surveyDetails}
                handleSurveySubmit={handleSurveySubmit}
              />
            )}
          </div>
        ) : (
          <div className="card-body all-card-body">
            <div>
              <p>
                {" "}
                No summary found. Click on the button below to rate your boss{" "}
              </p>

              <Link to={"/employees/rate-your-leaders"}>
                <button className="btn btn-primary"> Rate Your Leaders</button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(ResponseSummary);
