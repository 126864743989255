import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Link, useHistory } from "react-router-dom";
import getStoreName from "../../../components/StoreNameUsingId";
import { RoundNumberUp } from "../../../components/Utils/RoundNumberUp";
import { BonusPayoutService } from "../../../service/BonusPayoutService";
import CriteriaMark from "../components/CriteriaMark";
import ShowCriteria from "../components/ShowCriteria";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";
import cogoToast from "cogo-toast";

const BonusBreakdown = () => {
  let history = useHistory();
  const [breakdownData, setBreakdownData] = useState(null);
  const [store, setStore] = useState(null);
  const [year, setYear] = useState(null);
  const [quarter, setQuarter] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  const handleCompleteAndPaid = () => {
    setIsSubmitting(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to mark this bonus payout as completed",
      icon: "warning",
      confirmButtonText: "Yes",
      cancelButtonText: "Not sure",
      showCancelButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          store: history.location.state.store,
          year: history.location.state.year,
          quarter: history.location.state.quarter,
          breakdown: history.location.state.breakdown_data,
        };
        console.log(data, "kskfs");

        BonusPayoutService.bonusCompleteAndPaid(data)
          .then((response) => {
            console.log(response.data.data, "Response from server");
            setIsSubmitting(false);
            cogoToast.success("Completed successfully", toastoptions);
            history.push("/view-bonus-payout-breakdown");
          })
          .catch((error) => {
            console.log(error.response);
          });
      } else {
        setIsSubmitting(false);
      }
    });
  };

  useEffect(() => {
    if (history.location.state && history.location.state.breakdown_data) {
      console.log(history.location.state.breakdown_data);
      setBreakdownData(history.location.state.breakdown_data);
      setStore(history.location.state.store);
      setYear(history.location.state.year);
      setQuarter(history.location.state.quarter);
    } else {
      history.push("/view-bonus-payout-breakdown");
    }
  }, []);

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div
            style={{
              marginBottom: "20px",
            }}
          >
            <Link to={"/view-bonus-payout-breakdown"}>
              <button className="btn btn-primary"> Back </button>
            </Link>
          </div>
          <h3>
            {store ? getStoreName(store) : ""} &nbsp; Bonus Payout -{" "}
            {quarter ? `Q${quarter}` : ""} &nbsp; {year ? `${year}` : ""}
          </h3>
          <div className="row">
            {breakdownData &&
              breakdownData.map((data, index) => (
                <div
                  className="col-md-6"
                  key={index}
                  style={{
                    marginTop: "30px",
                    border: "2px dotted",
                  }}
                >
                  <div>
                    <table className="table">
                      <tbody>
                        <tr>
                          <th scope="col">Manager's Name</th>
                          <td>{data.managerName}</td>
                        </tr>
                        <tr>
                          <th scope="col">Salary</th>
                          <td>
                            <NumberFormat
                              value={RoundNumberUp(data.salary)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$ "}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Annual Bonus: </th>
                          <td>
                            <NumberFormat
                              value={RoundNumberUp(data.annualBonus)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$ "}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Quarter | Base: </th>
                          <td>
                            <NumberFormat
                              value={RoundNumberUp(data.quarterBonus)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$ "}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Manager Type : </th>
                          <td>{data.manager_type.toUpperCase()}</td>
                        </tr>

                        <tr
                          style={{
                            background: "black",
                            color: "white",
                          }}
                        >
                          <th scope="row">
                            {`Q${quarter}`} - Total Earned Bonus :{" "}
                          </th>
                          <td>
                            <NumberFormat
                              value={RoundNumberUp(
                                data.totalEarnedForTheQuarter
                              )}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$ "}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    <table className="table table-bordered">
                      <thead>
                        <tr
                          style={{
                            background: "#7e2828",
                            color: "white",
                          }}
                        >
                          <th scope="col">KPIs</th>
                          <th scope="col">%</th>
                          <th scope="col">{`Q${quarter}`}</th>
                          <th scope="col">$</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.KPI &&
                          data.KPI.map((kpi, index) => (
                            <tr key={index}>
                              <td>{kpi.name}</td>
                              <td>{kpi.percentage}</td>
                              <td>{ShowCriteria(kpi.condition)}</td>
                              <td>
                                {" "}
                                <NumberFormat
                                  value={RoundNumberUp(kpi.amountEarned)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$ "}
                                />
                              </td>
                              <td>{CriteriaMark(kpi.condition)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>

                    <table className="table table-bordered mt-3">
                      <tbody>
                        <tr
                          style={{
                            background: "black",
                            color: "white",
                          }}
                        >
                          <td> Total Bonus</td>
                          <td className="amountRow">
                            <NumberFormat
                              value={RoundNumberUp(
                                data.totalEarnedForTheQuarter
                              )}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$ "}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="row">
        {/* <div className=""> */}
        <button
          className="btn btn-primary col-md-12"
          onClick={handleCompleteAndPaid}
          disabled={isSubmitting}
        >
          {isSubmitting ? <Spinner animation="border" size="sm" /> : ""} &nbsp;
          Complete and Paid
        </button>
        {/* </div> */}
      </div>
    </div>
  );
};

export default BonusBreakdown;
