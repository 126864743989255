import React, { useEffect } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import { Utility } from "../../../../Helpers/utils";

const RestaurantManagerForm = () => {
  const history = useHistory();

  useEffect(() => {
    const user = Utility.getUser();

    if (!user) {
      history.push("/home/login");
    }
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h3 className="text-center display-1"> Restaurant Manager </h3>
        </div>
      </div>

      <div className="row" style={{ marginTop: "100px", marginBottom: "200px" }}>
        <div className="col-md-6 text-center mx-auto">
          <Link className="" to="/form-manager/closing-report">
            <div className="forms-link-box">
              <b>Closing Report</b>
            </div>
          </Link>
        </div>
      </div>

      <div className="manager-other-links-wrapper">
        <div className="row">
          <div className="col-md-4">
            <a
              className="hr-image-links rounded mx-auto d-block"
              href="https://net3.necs.com/lacosecha/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require("../../../../assets/images/lcfood.jpg")} alt="logo" />
              <div className="text-center">
                <h3> Food & Supplies Catalog</h3>
              </div>
            </a>
          </div>
          <div className="col-md-4">
            <a
              className="hr-image-links"
              href="https://laparrilla.net-chef.com/session.nc"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("../../../../assets/images/crunchtime-netchef.jpg")}
                alt="logo"
                width="100%"
              />
            </a>
          </div>

          <div className="col-md-4">
            <a
              className="hr-image-links"
              href="https://laparrilla.ct-teamworx.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require("../../../../assets/images/crunchtime-team.png")} alt="logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(RestaurantManagerForm);
