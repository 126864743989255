import React, { useState } from "react";
import { Spinner, Form } from "react-bootstrap";
import { useParams, withRouter } from "react-router";
import { Formik } from "formik";
import Swal from "sweetalert2";
import { PaymentService } from "../../service/PaymentService";
import { InstantAuditReportUpload } from "../../components/InstantAuditReportUpload";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { Utility } from "../../Helpers/utils";

const AddNewInstantAuditForStore = () => {
  let { store_id } = useParams();
  const [auditReady, setAuditReady] = useState(false);
  const [auditReport, setAuditReport] = useState(null);

  const roundNumberUp = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  return (
    <div>
      <section className="content">
        <div>
          <div className="pull-left">
            <h3> Add New Record</h3>
          </div>

          <div className="pull-right">
            <Link to={`/financials/view-all-instant-audits-store/${store_id}`}>
              <button className="btn btn-primary">Back to lists</button>
            </Link>
          </div>
          <div className="clearfix"></div>
        </div>
        <div>
          {!auditReady && (
            <Formik
              initialValues={{
                audit_report_attachment: "",
                store_id: store_id,
                audited_by: Utility.getUser().id,
              }}
              onSubmit={(values, { setSubmitting }) => {
                // setSubmitting(true);
                console.log(values.audit_report_attachment);

                const data = new FormData();
                data.append(
                  "audit_report_attachment",
                  values.audit_report_attachment
                );

                PaymentService.uploadAuditReport(data)
                  .then((response) => {
                    setSubmitting(false);
                    setAuditReport(response.data.data);
                    setAuditReady(true);
                    console.log(response.data.data);
                  })
                  .catch((error) => {
                    console.log(error);
                    setSubmitting(false);
                    Swal.fire({
                      title: "Error",
                      text: error.response.data.message,
                      icon: "error",
                      confirmButtonColor: "#000000",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Ok",
                      allowOutsideClick: false,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        console.log("Payment works");
                      }
                    });
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => (
                <form
                  className="closing_report_form mt-4"
                  id="closing_report_form"
                  onSubmit={handleSubmit}
                >
                  <Form.Group>
                    <p> Upload a new record </p>
                    <InstantAuditReportUpload setFieldValue={setFieldValue} />
                  </Form.Group>

                  <div>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary mr-2"
                    >
                      Submit &nbsp;&nbsp;
                      {isSubmitting ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </div>

        {auditReady && (
          <div className="row" style={{ marginTop: "60px" }}>
            <div className="col-md-12">
              <div>
                <small id="emailHelp" className="form-text text-muted">
                  Details of the Audited report
                </small>
              </div>
              <div>
                {auditReport.todayData.length > 0 && (
                  <div
                    className="table-responsive"
                    style={{ marginTop: "60px" }}
                  >
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th> Location </th>
                          <th> Employee </th>
                          <th> Date </th>
                          <th> Date Processed </th>
                          <th> Amount </th>
                          <th> Status </th>
                        </tr>
                      </thead>

                      <tbody>
                        {auditReport.todayData.map((report, index) => (
                          <tr key={index}>
                            <td> {report.location} </td>
                            <td> {report.employee} </td>
                            <td> {report.date} </td>
                            <td> {report.date_processed} </td>

                            <td className="amountRow">
                              <NumberFormat
                                defaultValue={roundNumberUp(report.amount)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </td>

                            <td>
                              {" "}
                              {report.status === "verified"
                                ? "Verified"
                                : "Not Verified"}{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>

              {auditReport.previousData.length > 0 && (
                <div className="table-responsive" style={{ marginTop: "60px" }}>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th> Location </th>
                        <th> Employee </th>
                        <th> Date </th>
                        <th> Date Processed </th>
                        <th> Amount </th>
                        <th> Status </th>
                      </tr>
                    </thead>

                    <tbody>
                      {auditReport.previousData.map((report, index) => (
                        <tr key={index}>
                          <td> {report.location} </td>
                          <td> {report.employee} </td>
                          <td> {report.date} </td>
                          <td> {report.date_processed} </td>

                          <td className="amountRow">
                            <NumberFormat
                              defaultValue={roundNumberUp(report.amount)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </td>
                          <td>
                            {" "}
                            {report.status === "verified"
                              ? "Verified"
                              : "Not Verified"}{" "}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default withRouter(AddNewInstantAuditForStore);
