import React, { useState, useEffect } from "react";
import { SalesReportUpload } from "./components/SalesReportUpload";
import { Formik } from "formik";
import { Spinner, Form } from "react-bootstrap";
import { UserService } from "../../../service/userService";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import { LaborInfoReportUpload } from "./components/LaborInfoReportUpload";
import getStoreName from "../../../components/StoreNameUsingId";
import EmergencyCodeDetails from "./components/EmergencyCodeDetails";
import { TipIncomeReportUpload } from "./components/TipIncomeReportUpload";
import CompsTable from "./components/CompsTable";

const SalesReportStage = () => {
  const [reportReady, setReportReady] = useState(false);
  const [salesReport, setSalesReport] = useState(null);
  const [laborInfoDetails, setLaborInfoDetails] = useState(null);
  const [emergencyCodeReady, setEmergencyCodeReady] = useState(false);

  const roundNumberUp = (num) => (Math.round(num * 100) / 100).toFixed(2);

  useEffect(() => {
    const storedSalesReport = localStorage.getItem("salesReport");
    const storedLaborInfoDetails = localStorage.getItem("laborInfoDetails");

    if (storedSalesReport) {
      setSalesReport(JSON.parse(storedSalesReport));
      setReportReady(true);
      setEmergencyCodeReady(true);
    }

    if (storedLaborInfoDetails) {
      setLaborInfoDetails(JSON.parse(storedLaborInfoDetails));
    }

    localStorage.removeItem("newCompletedSalesReport");
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const data = new FormData();

    if (
      values.sales_report_attachement.length > 0 &&
      values.labor_info_report_attachement.length > 0 &&
      values.tip_income_report.length > 0
    ) {
      // Append files to FormData
      values.sales_report_attachement.forEach((file) =>
        data.append("sales_report_attachement[]", file, file.name)
      );
      values.labor_info_report_attachement.forEach((file) =>
        data.append("labor_info_report_attachement[]", file, file.name)
      );
      values.tip_income_report.forEach((file) =>
        data.append("tip_income_report[]", file, file.name)
      );

      UserService.uploadSalesReport(data)
        .then((response) => {
          const {
            reportInfo,
            laborInfoDetails: laborDetails,
            tipIncomeData,
            storeDetails,
          } = response.data.data;

          // Update state
          setSalesReport(reportInfo);
          setLaborInfoDetails(laborDetails);
          setReportReady(true);
          setEmergencyCodeReady(true);

          // Save data to localStorage
          localStorage.setItem("salesReport", JSON.stringify(reportInfo));
          localStorage.setItem("storeDetails", JSON.stringify(storeDetails));
          localStorage.setItem("laborInfoDetails", JSON.stringify(laborDetails));
          localStorage.setItem("otherEmpdetails", JSON.stringify(tipIncomeData.diff));
          localStorage.setItem("tipIncomeReport", JSON.stringify(tipIncomeData));
          localStorage.setItem("mergedEmpDetails", JSON.stringify(tipIncomeData.mergedEmpDetails));
          localStorage.setItem("cateringPayments", JSON.stringify(tipIncomeData.cateringPayments));
          localStorage.setItem("togoPayments", JSON.stringify(tipIncomeData.togoPayments));
          localStorage.setItem("atoDoorDashTips", JSON.stringify(tipIncomeData.atoDoorDashTips));
          localStorage.setItem("laborBartenders", JSON.stringify(tipIncomeData.laborBartenders));

          // Update emergency tips in labor details
          const updatedLaborDetails = { ...laborDetails };
          tipIncomeData.updatedEmergencyDetails.forEach((detail) => {
            if (detail.emergency_code === 999220) {
              updatedLaborDetails.emergencyCodes.barCodes[0].tipsOwed = detail.tip_owed;
            }
          });
          localStorage.setItem("laborInfoDetails", JSON.stringify(updatedLaborDetails));
          setLaborInfoDetails(updatedLaborDetails);

          setSubmitting(false);
        })
        .catch((error) => {
          setSubmitting(false);
          Swal.fire({
            title: "Error",
            text: error.response?.data?.message || "An error occurred",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    } else {
      setSubmitting(false);
      Swal.fire({
        title: "Check all inputs",
        icon: "error",
        html: "Kindly upload all the required files <b>(Sales Report File, Labor File & Tip income Files)</b>",
        confirmButtonText: "Ok",
      });
    }
  };

  const handleReset = () => {
    setReportReady(false);
    setSalesReport(null);
    setEmergencyCodeReady(false);

    // Clear specific localStorage items
    const keysToRemove = [
      "salesReport",
      "tipDetailsReport",
      "tipDetailsBartender",
      "laborInfoDetails",
      "barTenderPayment",
      "cashDepositData",
      "tipIncomeReport",
      "storeDetails",
      "mergedEmpDetails",
      "otherEmpdetails",
      "togoCalculatedPayments",
      "atoPaymentDetails",
      "cateringPayments",
      "atoOption",
      "togoPayments",
      "atoDoorDashTips",
      "laborBartenders",
      "doorDashPaymentDetails",
    ];
    keysToRemove.forEach((key) => localStorage.removeItem(key));
  };

  return (
    <section className="content">
      <div>
        <h3>Daily Sales Report</h3>
        <small className="mb-4">{salesReport && `Date: ${salesReport.report_date}`}</small>
        <br />
        <small className="mb-4">
          {salesReport && `Store: ${getStoreName(salesReport.owner)} (LP ${salesReport.owner})`}
        </small>
        {!reportReady && (
          <Formik
            initialValues={{
              sales_report_attachement: [],
              labor_info_report_attachement: [],
              tip_income_report: [],
            }}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, handleSubmit, isSubmitting }) => (
              <form className="closing_report_form mt-4" onSubmit={handleSubmit}>
                <Form.Group>
                  <p>Upload Daily Sales Report</p>
                  <SalesReportUpload setFieldValue={setFieldValue} />
                </Form.Group>
                <Form.Group>
                  <p>Upload Labor Info Report</p>
                  <LaborInfoReportUpload setFieldValue={setFieldValue} />
                </Form.Group>
                <Form.Group>
                  <p>Upload Tip Income Report</p>
                  <TipIncomeReportUpload setFieldValue={setFieldValue} />
                </Form.Group>
                <div>
                  <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                    Submit {isSubmitting && <Spinner animation="border" size="sm" />}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>

      {reportReady && (
        <div className="row" style={{ marginTop: "40px" }}>
          <div className="col-md-12">
            <small className="form-text text-muted">
              Summary of Sales report (Click on Cancel to upload a new one or next to proceed)
            </small>
            {salesReport && (
              <div className="table-responsive" style={{ marginTop: "60px" }}>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Revenue</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      { label: "Net Sales", value: salesReport.net_sales },
                      { label: "Taxes (Sales & Liquor)", value: salesReport.taxes },
                      { label: "Tips - Credit Cards", value: salesReport.tips_credit_card },
                      { label: "Autogratuity", value: salesReport.auto_gratuity },
                      { label: "Tip Share Collected", value: salesReport.tip_share_collected },
                      { label: "Gift Card Sold", value: salesReport.gift_card_sold },
                      { label: "Charities", value: salesReport.charities },
                      { label: "Total Revenues", value: salesReport.total },
                    ].map((item, index) => (
                      <tr key={index}>
                        <td>{item.label}</td>
                        <td className="amountRow">
                          <NumberFormat
                            value={roundNumberUp(item.value)}
                            displayType="text"
                            thousandSeparator
                            prefix="$"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <CompsTable comps={salesReport.comps} />

                <div style={{ marginTop: "60px" }}>
                  <button onClick={handleReset} className="btn btn-light">
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {emergencyCodeReady && (
        <EmergencyCodeDetails salesReport={salesReport} laborInfoDetails={laborInfoDetails} />
      )}
    </section>
  );
};

export default SalesReportStage;
