import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { CateringService } from "../../service/CateringService";
import { ConvertDateFormat } from "../../components/ConvertDateFormat";
import AppSpinner from "../../components/Spinner";
import { Formik } from "formik";
import { Form, Spinner } from "react-bootstrap";
import cogoToast from "cogo-toast";
import { RoundNumberUpAccurately } from "../../components/Utils/RoundNumberUp";
const { SearchBar } = Search;

const ScheduledEvents = () => {
  const [scheduledCatering, setScheduledCatering] = useState(null);

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  useEffect(() => {
    CateringService.getAllCateringPayments()
      .then((response) => {
        let ScheduledArr = [];

        response.data.data.map((res, index) => {
          return ScheduledArr.push({
            id: res.id,
            storeId: res.store_id,
            location: `#Laparrilla ${res.store_id}`,
            cateringCode: res.catering_code,
            cateringDate: ConvertDateFormat(res.catering_date),
            status: res.status,
            tips: res.tips,
          });
        });
        setScheduledCatering(ScheduledArr);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);

  const columns = [
    {
      dataField: "location",
      text: "Location",
      sort: true,
    },
    {
      dataField: "cateringCode",
      text: "Catering Code",
      sort: true,
    },
    {
      dataField: "cateringDate",
      text: "Catering Date",
      sort: true,
    },
    {
      dataField: "tips",
      text: "Tips Owed",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <div>
      {/* <div className="mb-5">
        <div className="pull-left">
          <Link to={"/catering/scheduled-list"}>
            <button className="btn btn-primary"> Back </button>
          </Link>
        </div>
        <div className="clearfix"></div>
      </div> */}

      <div>
        <p className="pull-right" style={{ textDecoration: "underline" }}>
          <a
            href="https://veltron.document360.io/v1/en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn How &nbsp;<span className="fa fa-question"></span>
          </a>
        </p>

        <div className="clearfix"></div>
      </div>

      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title"> Search Scheduled Events </h4>
              <p className="card-description">
                Please select your preferred search term and hit the{" "}
                <b>Submit</b> button to proceed.
              </p>
              <Formik
                initialValues={{
                  search_term: "",
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  console.log(values);
                  CateringService.cateringRecords(values)
                    .then((response) => {
                      console.log(response.data.data);
                      let ScheduledArr = [];

                      response.data.data.map((res, index) => {
                        return ScheduledArr.push({
                          id: res.id,
                          storeId: res.store_id,
                          location: `#Laparrilla ${res.store_id}`,
                          cateringCode: res.catering_code,
                          cateringDate: ConvertDateFormat(res.catering_date),
                          status: res.status,
                          tips: `$ ${RoundNumberUpAccurately(res.tips, 2)}`,
                        });
                      });
                      setScheduledCatering(ScheduledArr);
                      setSubmitting(false);
                      cogoToast.success("Retrieved successfully", toastoptions);
                    })
                    .catch((error) => {
                      console.log(error.response);
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form className="form-inline" onSubmit={handleSubmit}>
                    <label htmlFor="search_term"> Search Term </label>
                    <Form.Control
                      type="text"
                      as="select"
                      className="form-control"
                      id="search_term"
                      name="search_term"
                      defaultValue={
                        values.search_term ? values.search_term : "DEFAULT"
                      }
                      onChange={handleChange}
                      disabled={isSubmitting}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      style={{ width: "250px", margin: "0px 10px" }}
                    >
                      <option value="DEFAULT" disabled>
                        Select One
                      </option>
                      <option value="all"> ALL </option>
                      <option value="today"> TODAY </option>
                      <option value="this_week"> THIS WEEK </option>
                      <option value="this_month"> THIS MONTH </option>
                    </Form.Control>

                    <button type="submit" className="btn btn-primary mb-2">
                      Submit &nbsp;&nbsp;
                      {isSubmitting ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        ""
                      )}
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          {scheduledCatering ? (
            <div>
              <div className="card">
                {scheduledCatering.length > 0 ? (
                  <div className="card-body">
                    <h4 className="card-title"> Scheduled Events </h4>
                    <div className="row">
                      <div className="col-12">
                        <ToolkitProvider
                          keyField="id"
                          bootstrap4
                          data={scheduledCatering}
                          columns={columns}
                          search
                        >
                          {(props) => (
                            <div>
                              <div className="d-flex align-items-center">
                                <p className="mb-2 mr-2">Search in table:</p>
                                <SearchBar {...props.searchProps} />
                              </div>
                              <BootstrapTable
                                defaultSorted={defaultSorted}
                                pagination={paginationFactory()}
                                {...props.baseProps}
                                wrapperClasses="table-responsive"
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div style={{ padding: "20px" }}>
                    <p>
                      No events has been scheduled for today. Please use the
                      filter above to view past events
                    </p>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <AppSpinner />
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(ScheduledEvents);
