import React from "react";
import { Col, Row, Nav, Tab } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import ViewStates from "../employee-configuration/state/ViewStates";
import ViewMaritalStatuses from "../employee-configuration/marital-status/ViewMaritalStatuses";
import ViewGenders from "../employee-configuration/gender/ViewGenders";
import ViewEthnics from "./ethnic/ViewEthnics";

const employeeConfig = () => {
  return (
    <div>
      <div>
        <p className="pull-right" style={{ textDecoration: "underline" }}>
          <a
            href="https://veltron.document360.io/v1/en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn How &nbsp;<span className="fa fa-question"></span>
          </a>
        </p>

        <div className="clearfix"></div>
      </div>

      <div className="page-header">
        <h3 className="page-title">Employee Configuration</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="!#" onClick={(event) => event.preventDefault()}>
                {" "}
                Employee configuration
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              all
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12 stretch-card">
          <div className="card">
            <div className="card-body">
              {/* <h4 className="card-title">System Configuration</h4> */}
              {/* <p className="card-description">The list of settings items</p> */}
              <div className="tab-custom-pills-horizontal">
                <Tab.Container id="left-tabs-example" defaultActiveKey="state">
                  <Row>
                    <Col xs={12}>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="state"
                            className="d-flex align-items-center"
                          >
                            State
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="ethnicity"
                            className="d-flex align-items-center"
                          >
                            Ethnicity
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="gender"
                            className="d-flex align-items-center"
                          >
                            Gender
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="maritalStatus"
                            className="d-flex align-items-center"
                          >
                            Marital Status
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col xs={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="state">
                          <ViewStates />
                        </Tab.Pane>
                        <Tab.Pane eventKey="ethnicity">
                          <ViewEthnics />
                        </Tab.Pane>
                        <Tab.Pane eventKey="gender">
                          <ViewGenders />
                        </Tab.Pane>
                        <Tab.Pane eventKey="maritalStatus">
                          <ViewMaritalStatuses />
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(employeeConfig);
