import React from "react";
import NumberFormat from "react-number-format";
import { RoundNumberUp } from "../../../../components/Utils/RoundNumberUp";

const TipSummaryTable = ({ title, data, total }) => {
  const removeNegative = (val) => {
    return val < 0 ? Math.abs(val) : val;
  };
  return (
    <div className="mt-4">
      <h4>{title}</h4>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Employee Number</th>
            <th>Employee Name</th>
            <th>Tips Owed</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.employee_number || item.togo_code || item.number}</td>
              <td>{item.employee_name || item.togo_name || item.bar_name}</td>
              <td className="amountRow">
                <NumberFormat
                  defaultValue={RoundNumberUp(removeNegative(item.tip_owed || item.tips))}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <table className="table table-bordered">
        <tbody>
          <tr>
            <td className="table-wider"> Total Tips Owed </td>
            <td className="amountRow">
              <NumberFormat
                defaultValue={total ? RoundNumberUp(total) : ""}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TipSummaryTable;
