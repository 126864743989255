export const displayPaymentMethod = (name) => {
  let paymentName = "";
  switch (name) {
    case "bartender":
      paymentName = "Bartender";
      break;
    case "togo":
      paymentName = "ToGo";
      break;
    case "bar_togo":
      paymentName = "Bartender and ToGo";
      break;
    default:
      paymentName = "None selected";
  }

  return paymentName;
};
