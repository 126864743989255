import { Field } from "formik";
import React from "react";
import { LOCATION_OPTIONS } from "../utils/locations";

export const EmergencyCodeTable = ({ emergencyCodes }) => (
  <table className="table table-bordered">
    <thead>
      <tr>
        <th style={{ width: "5%" }}>Code</th>
        <th style={{ width: "5%" }}>Hours</th>
        <th style={{ width: "10%" }}>Tipshare</th>
        <th style={{ width: "10%" }}>Tips Owed</th>
        <th style={{ width: "20%" }}>Employee Name</th>
        <th style={{ width: "15%" }}>Base Location</th>
      </tr>
    </thead>
    <tbody>
      {emergencyCodes.map((code, index) => (
        <tr key={code.emergency_code} id={"emergency_codes_row_" + index}>
          <td
            style={{
              paddingLeft: "0px",
            }}
          >
            <Field
              name={`barEmergencyCodes[${index}].emergency_code`}
              className="form-control"
              disabled
              value={code.emergency_code}
              style={{
                border: "none",
                paddingLeft: "10px",
                paddingRight: "0px",
              }}
            />
          </td>
          <td
            style={{
              paddingLeft: "0px",
            }}
          >
            <Field
              name={`barEmergencyCodes[${index}].totalHours`}
              className="form-control"
              disabled
              value={code.totalHours}
              style={{
                border: "none",
                paddingLeft: "10px",
                paddingRight: "0px",
              }}
            />
          </td>
          <td
            style={{
              paddingLeft: "0px",
            }}
          >
            <Field
              name={`barEmergencyCodes[${index}].tipshare`}
              className="form-control"
              disabled
              value={code.tipsOwed}
              style={{
                border: "none",
                paddingLeft: "10px",
                paddingRight: "0px",
              }}
            />
          </td>
          <td
            className="amountRow"
            style={{
              paddingLeft: "0px",
            }}
          >
            <Field
              name={`barEmergencyCodes[${index}].tips_owed`}
              className="form-control"
              disabled
              value={code.tips_owed}
              style={{
                border: "none",
                paddingLeft: "10px",
                paddingRight: "0px",
              }}
            />
          </td>
          <td
            style={{
              paddingLeft: "0px",
            }}
          >
            <Field
              name={`barEmergencyCodes[${index}].employee_name`}
              className="form-control"
              disabled
              value={code.employee_name}
              style={{
                border: "none",
                paddingLeft: "10px",
                paddingRight: "0px",
              }}
            />
          </td>
          <td
            style={{
              paddingLeft: "0px",
            }}
          >
            <Field
              as="select"
              name={`barEmergencyCodes[${index}].base_location`}
              className="form-control"
              disabled
              value={code.base_location}
            >
              <option value="DEFAULT" disabled>
                Select One
              </option>
              {LOCATION_OPTIONS.map((loc) => (
                <option key={loc.value} value={loc.value}>
                  {loc.label}
                </option>
              ))}
            </Field>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);
