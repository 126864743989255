import cogoToast from "cogo-toast";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useParams, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { ConvertDateFormat } from "../../components/ConvertDateFormat";
import { SystemConfigService } from "../../service/configService";
import { SalesService } from "../../service/SalesService";
const { SearchBar } = Search;

const ViewClosingReportDetails = () => {
  let { store_id } = useParams();
  const [store, setStore] = useState(null);
  const [reportData, setReportData] = useState(null);

  // let history = useHistory();

  // const handleViewDocRedirect = (id) => {
  //   history.push(`/financials/store/doc/${store_id}/${id}`);
  // };

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "report_date",
      text: "Report Date",
      sort: true,
    },
    {
      dataField: "food_sales",
      text: "Food Sales",
      sort: true,
    },
    {
      dataField: "liquor",
      text: "Liquor",
      sort: true,
    },
    {
      dataField: "beer_wine",
      text: "Beer/Wine",
      sort: true,
    },
    {
      dataField: "sale_tax",
      text: "Sale Tax",
      sort: true,
    },
    {
      dataField: "gift_card_sold",
      text: "Gift Card Sold",
      sort: true,
    },
    {
      dataField: "tips_credit_card",
      text: "Gift Tips/Credit ards",
      sort: true,
    },
    {
      dataField: "tip_share",
      text: "Tip Share",
      sort: true,
    },
    // {
    //   dataField: "gift_cards_redeem",
    //   text: "Gift Cards Redeem",
    //   sort: true,
    // },
    // {
    //   dataField: "door_dash",
    //   text: "Door Dash",
    //   sort: true,
    // },
    // {
    //   dataField: "compos_promos_voids",
    //   text: "Comps/Promos/Voids",
    //   sort: true,
    // },
    // {
    //   dataField: "aloha_payment",
    //   text: "Aloha Payment",
    //   sort: true,
    // },
    // {
    //   dataField: "bank_deposit",
    //   text: "Bank Deposit",
    //   sort: true,
    // },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <div>
            {/* <button
              className="btn btn-outline-primary"
              onClick={() => setMdShow(true)}
            >
              <i className="mdi mdi-eye-outline text-primary"></i>View More
            </button> */}

            <Link to={`/accounting/view-sales-report/${store_id}/${row.id}`}>
              <button className="btn btn-outline-primary">
                <i className="mdi mdi-eye-outline text-primary"></i>View More
              </button>
            </Link>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  const roundNumberUp = (num) => {
    return "$" + (Math.round(num * 100) / 100).toFixed(2);
  };

  useEffect(() => {
    const data = { store_id };
    SalesService.getSalesReportExtractForStore(data)
      .then((response) => {
        let dataResArray = [];
        console.log(response.data.data, "Data right here");
        // setExtractedData(response.data.data);
        response.data.data.map((res, index) => {
          return dataResArray.push({
            id: res.id,
            report_date: ConvertDateFormat(res.report_date),
            food_sales: res.food_sales,
            liquor: res.liquor ? roundNumberUp(res.liquor) : roundNumberUp(0),
            beer_wine: res.beer_wine
              ? roundNumberUp(res.beer_wine)
              : roundNumberUp(0),
            sale_tax: res.sale_tax
              ? roundNumberUp(res.sale_tax)
              : roundNumberUp(0),
            gift_card_sold: res.gift_card_sold
              ? roundNumberUp(res.gift_card_sold)
              : roundNumberUp(0),
            tips_credit_card: res.tips_credit_card
              ? roundNumberUp(res.tips_credit_card)
              : roundNumberUp(0),
            tip_share: res.tip_share
              ? roundNumberUp(res.tip_share)
              : roundNumberUp(0),
            gift_cards_redeem: res.gift_cards_redeem
              ? roundNumberUp(res.gift_cards_redeem)
              : roundNumberUp(0),
            door_dash: res.door_dash
              ? roundNumberUp(res.door_dash)
              : roundNumberUp(0),
            compos_promos_voids: res.compos_promos_voids
              ? roundNumberUp(res.compos_promos_voids)
              : roundNumberUp(0),
            aloha_payment: res.aloha_payment
              ? roundNumberUp(res.aloha_payment)
              : roundNumberUp(0),
            bank_deposit: res.bank_deposit
              ? roundNumberUp(res.bank_deposit)
              : roundNumberUp(0),
          });
        });
        setReportData(dataResArray);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });

    SystemConfigService.getStore(store_id)
      .then((response) => {
        console.log(response.data.data);
        setStore(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        cogoToast.error(error.response.data.message, toastoptions);
      });
  }, []);

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">
          <span style={{ textTransform: "capitalize" }}>
            {store ? store.name : ""}
          </span>
        </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/financials/expenses-viewers">La Parrilla</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <span style={{ textTransform: "capitalize" }}>
                {store ? store.name : ""}
              </span>
            </li>
          </ol>
        </nav>
      </div>

      <div className="row">
        {reportData && (
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"> Sales Report </h4>
                <div className="row">
                  <div className="col-12">
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={reportData}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div className="d-flex align-items-center">
                            <p className="mb-2 mr-2">Search in table:</p>
                            <SearchBar {...props.searchProps} />
                          </div>
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(ViewClosingReportDetails);
