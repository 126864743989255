import cogoToast from "cogo-toast";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useParams, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { ConvertDateFormat } from "../../components/ConvertDateFormat";
import { SystemConfigService } from "../../service/configService";
import { SalesService } from "../../service/SalesService";
const { SearchBar } = Search;

const SalesReportDetails = () => {
  let { store_id, id } = useParams();
  const [store, setStore] = useState(null);
  const [reportData, setReportData] = useState(null);

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "report_date",
      text: "Report Date",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "employee_number",
      text: "Employee Number",
      sort: true,
    },
    {
      dataField: "position",
      text: "Position",
      sort: true,
    },
    {
      dataField: "total_tips_sent",
      text: "InstantPay Tips",
      sort: true,
    },
    {
      dataField: "tips_as_server",
      text: "Tips as server",
      sort: true,
    },
    {
      dataField: "tips_as_coach_server",
      text: "Tips as Coach",
      sort: true,
    },
    {
      dataField: "sales_server",
      text: "Sales as Server",
      sort: true,
    },
    {
      dataField: "sales_coach_server",
      text: "Sales as Coach",
      sort: true,
    },
    {
      dataField: "total_sales",
      text: "Total Sales",
      sort: true,
    },
    {
      dataField: "percentage",
      text: "Percentage",
      sort: true,
    },
  ];

  const rowStyle = (row, rowIndex) => {
    const style = {
      fontWeight: "bold",
      color: "white",
    };
    console.log(row, "Row re oooooooo");
    if (row.level_percentage === "High") {
      style.backgroundColor = "#e3091e";
    } else if (row.level_percentage === "Low") {
      style.backgroundColor = "#eb9d27";
    } else {
      style.backgroundColor = "#2b9b45";
    }

    return style;
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  const roundNumberUp = (num) => {
    return "$" + (Math.round(num * 100) / 100).toFixed(2);
  };

  const roundNumberUpPercentage = (num) => {
    return num + "%";
  };

  useEffect(() => {
    const data = { store_id, id };
    SalesService.getPercentageSalesDetails(data)
      .then((response) => {
        let dataResArray = [];
        console.log(response.data.data, "Data right here");
        // setExtractedData(response.data.data);
        response.data.data.map((res, index) => {
          return dataResArray.push({
            id: res.id,
            report_date: ConvertDateFormat(res.report_date),
            name: res.name,
            employee_number: res.employee_number,
            position: res.position,
            level_percentage: res.level_percentage,
            total_tips_sent: res.total_tips_sent
              ? roundNumberUp(res.total_tips_sent)
              : roundNumberUp(0),
            tips_as_server: res.tips_as_server
              ? roundNumberUp(res.tips_as_server)
              : roundNumberUp(0),
            tips_as_coach_server: res.tips_as_coach_server
              ? roundNumberUp(res.tips_as_coach_server)
              : roundNumberUp(0),
            sales_server: res.sales_server
              ? roundNumberUp(res.sales_server)
              : roundNumberUp(0),
            sales_coach_server: res.sales_coach_server
              ? roundNumberUp(res.sales_coach_server)
              : roundNumberUp(0),
            total_sales: res.total_sales
              ? roundNumberUp(res.total_sales)
              : roundNumberUp(0),
            percentage: res.percentage
              ? roundNumberUpPercentage(res.percentage)
              : roundNumberUpPercentage(0),
          });
        });
        setReportData(dataResArray);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });

    SystemConfigService.getStore(store_id)
      .then((response) => {
        setStore(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        cogoToast.error(error.response.data.message, toastoptions);
      });
  }, []);

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">
          <span style={{ textTransform: "capitalize" }}>
            {store ? store.name : ""}
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span>
            <Link to={`/percentage/view-report/${store_id}`}>
              <button className="btn btn-primary"> Back </button>
            </Link>
          </span>
        </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/financials/expenses-viewers">La Parrilla</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <span style={{ textTransform: "capitalize" }}>
                {store ? store.name : ""}
              </span>
            </li>
          </ol>
        </nav>
      </div>

      <div className="row">
        {reportData && (
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"> Sales Report </h4>
                <div className="row">
                  <div className="col-12">
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={reportData}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div className="d-flex align-items-center">
                            <p className="mb-2 mr-2">Search in table:</p>
                            <SearchBar {...props.searchProps} />
                          </div>
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            rowStyle={rowStyle}
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(SalesReportDetails);
