import React from "react";
import { Field } from "formik";
import { RoundNumberUp } from "../../../../components/Utils/RoundNumberUp";
import { Form } from "react-bootstrap";

const BartenderTipsTable = ({ tipSummary }) => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th style={{ width: "5%" }}>Emp Number</th>
          <th style={{ width: "25%" }}>Employee Name</th>
          <th style={{ width: "10%" }}>Declared Tips</th>
          <th style={{ width: "20%" }}>Tipshare</th>
          <th style={{ width: "25%" }}>Tips Owed</th>
          <th style={{ width: "25%" }}>Instant Pay Total</th>
        </tr>
      </thead>
      <tbody>
        {tipSummary && tipSummary.length > 0 ? (
          tipSummary.map((tip, index) => (
            <tr key={tip.employee_number}>
              <td>
                <Form.Group>
                  <Field
                    name={`tipSummary[${index}].employee_number`}
                    className="form-control"
                    placeholder="Employee Number"
                    autoComplete="off"
                    disabled
                  />
                </Form.Group>
              </td>
              <td>
                <Form.Group>
                  <Field
                    name={`tipSummary[${index}].name`}
                    className="form-control"
                    placeholder="Employee Name"
                    autoComplete="off"
                    disabled
                    style={{ border: "none", fontWeight: "bold" }}
                  />
                </Form.Group>
              </td>
              <td>
                <Form.Group>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <Field
                      name={`tipSummary[${index}].declared_tips`}
                      className="form-control"
                      placeholder="Declared tips"
                      autoComplete="off"
                      disabled
                      value={RoundNumberUp(tip.declared_tips)}
                    />
                  </div>
                </Form.Group>
              </td>
              <td>
                <Form.Group>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <Field
                      name={`tipSummary[${index}].total_tipshare_received`}
                      className="form-control"
                      placeholder="Tipshare"
                      autoComplete="off"
                      disabled
                      value={RoundNumberUp(tip.total_tipshare_received)}
                    />
                  </div>
                </Form.Group>
              </td>
              <td>
                <Form.Group>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <Field
                      name={`tipSummary[${index}].tip_owed`}
                      className="form-control"
                      placeholder="Tips Owed"
                      type="number"
                      autoComplete="off"
                      disabled
                      value={RoundNumberUp(tip.tip_owed_new)}
                    />
                  </div>
                </Form.Group>
              </td>
              <td>
                <Form.Group>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <Field
                      name={`tipSummary[${index}].instant_pay_total`}
                      className="form-control"
                      placeholder="Instant Pay Total"
                      type="number"
                      autoComplete="off"
                      disabled
                      value={RoundNumberUp(tip.instant_pay_total_new)}
                    />
                  </div>
                </Form.Group>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="6" className="text-center">
              No tips data available
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default BartenderTipsTable;
