import React, { useEffect, useState } from "react";
import { Form, Spinner, Button, Modal } from "react-bootstrap";
import { Formik, FieldArray, Field } from "formik";
import cogoToast from "cogo-toast";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import { ExpensesService } from "../../../service/ExpenseService";
import BartenderPageBox from "../../../components/ClosingReports/Bartender/BartenderPageBox";
import BartenderPageBoxAll from "../../../components/ClosingReports/Bartender/BartenderPageBoxAll";
import ShowHourBreakDown from "../../../components/ClosingReports/Bartender/ShowHourBreakDown";
import BarPaymentCreated from "../../../components/ClosingReports/Bartender/BarPaymentCreated";
import BartenderEmergencyList from "../../../components/ClosingReports/Bartender/BartenderEmergencyList";
import { RoundNumberUp } from "../../../components/Utils/RoundNumberUp";
import { EmergencyCodeTable } from "./components/EmergencyCodeTable";
import BartenderTipsTable from "./components/BartenderTipsTable";

const PayBarStage = () => {
  const [tipDetailsBartender, setTipDetailsBartender] = useState(null);
  const [totalTipShare, setTotalTipshare] = useState(0);
  const [amountToBeDistributed, setAmountLeftToBeDistributed] = useState(0);
  const [amountLeft, setAmountLeft] = useState([]);
  const [instantPayTotal, setInstantPaytotal] = useState("");
  // const [tipsOwedTotal, setTipsOwedTotal] = useState("");
  const [showBalanceAlert, setShowBalanceAlert] = useState(false);
  const [barPaymentCreated, setBarPaymentCreated] = useState(false);
  const [storeDetails, setStoreDetails] = useState(null);
  const [showBreakDetails, setShowBreakDetails] = useState(false);
  const [laborPaymentDetails, setLaborPaymentDetails] = useState(false);
  const [instantAllTotal, setInstantAllTotal] = useState(0);
  const [tipshareAllTotal, setTipshareAllTotal] = useState(0);
  const [totalAmMin, setTotalAmMin] = useState(0);
  const [totalBreakMin, setTotalBreakMin] = useState(0);
  const [totalPmMin, setTotalPmMin] = useState(0);
  const [codeAmAmount, setCodeAmAmount] = useState(0);
  const [codeBreakAmount, setCodeBreakAmount] = useState(0);
  const [codePmAmount, setCodePmAmount] = useState(0);
  const [barEmergencyCodes, setBarEmergencyCodes] = useState(null);
  const [barEmergencyCodeDetails, setBarEmergencyCodeDetails] = useState(null);
  const [barEmergencyCodeCreated, setBarEmergencyCodeCreated] = useState(false);
  const [salesReport, setSalesReport] = useState(null);
  const [barEmergencyAmount, setBarEmergencyAmount] = useState(0);

  const roundNumberUp = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  const roundNumberUpAccurately = (num, decimalPlaces) => {
    return Number(Math.round(num + "e" + decimalPlaces) + "e-" + decimalPlaces);
  };

  let CancelBtnStyle = {
    position: "relative",
    top: "240px",
  };

  if (barEmergencyCodeDetails && barEmergencyCodeDetails.length > 0) {
    CancelBtnStyle = {
      position: "relative",
      top: "467px",
    };
  }

  useEffect(() => {
    const getLocalStorageItem = (key) => JSON.parse(localStorage.getItem(key));

    const initializeButtonState = (btn, state) => {
      if (btn) {
        btn.disabled = !state;
      }
    };

    const fetchStoreDetails = () => {
      const storeDetails = getLocalStorageItem("storeDetails");
      if (storeDetails) {
        setStoreDetails(storeDetails);
        console.log(storeDetails, "This is the store details");
      }
    };

    const fetchBarEmergencyCodes = () => {
      const laborInfoDetails = getLocalStorageItem("laborInfoDetails");
      if (laborInfoDetails) {
        const barEmergency = laborInfoDetails.emergencyCodes.barCodes;
        setBarEmergencyCodes(barEmergency);
        console.log(barEmergency, "Bar Emergency Code");
      }
    };

    const fetchSalesReport = () => {
      const salesReport = getLocalStorageItem("salesReport");
      if (salesReport) {
        console.log(salesReport);

        const data = {
          store_id: salesReport.owner,
          report_date: salesReport.report_date,
        };

        ExpensesService.getEmergencyExpensesForAStore(data)
          .then((response) => {
            const barEmergency = response.data.data.filter((item) => item.job_code === "Bartender");

            setBarEmergencyCodeDetails(barEmergency);
            if (barEmergency.length > 0) {
              setBarEmergencyAmount(barEmergency[0].tips_owed);
              setBarEmergencyCodeCreated(true);
            }
          })
          .catch((error) => {
            console.log(error.response.data);
          });

        setSalesReport(salesReport);
      }
    };

    const fetchTipDetails = () => {
      const tipDetails = getLocalStorageItem("tipDetailsBartender");
      if (tipDetails) {
        const totalTipshare = tipDetails.reduce(
          (total, val) => total + parseFloat(val["total_tipshare_received"]),
          0
        );
        setTotalTipshare(roundNumberUp(totalTipshare));
        setTipDetailsBartender(tipDetails);
      }
    };

    const fetchBarTenderPayment = (btn) => {
      const barTenderPayment = getLocalStorageItem("barTenderPayment");
      if (barTenderPayment) {
        setBarPaymentCreated(true);

        const totalInstantPay = barTenderPayment.tipSummary.reduce(
          (total, val) => total + parseFloat(val["instant_pay_total"]),
          0
        );
        setInstantPaytotal(totalInstantPay);

        initializeButtonState(btn, true);
      } else {
        initializeButtonState(btn, false);
        setBarPaymentCreated(false);
      }
    };

    const fetchTipIncomeReport = () => {
      const tipIncomeReport = getLocalStorageItem("tipIncomeReport");
      if (tipIncomeReport) {
        const tipToDistribute = tipIncomeReport.bar.reduce(
          (total, val) => total + parseFloat(val.tip_owed),
          0
        );
        setAmountLeftToBeDistributed(roundNumberUp(tipToDistribute));
        setAmountLeft(roundNumberUp(tipToDistribute));
      }
    };

    const fetchMergedEmpDetails = () => {
      const mergedEmpDetails = getLocalStorageItem("mergedEmpDetails");
      if (mergedEmpDetails) {
        const laborPaymentData = [];
        let instantAllTotal = 0,
          tipshareAllTotal = 0,
          totalAmMin = 0,
          totalBreakMin = 0,
          totalPmMin = 0,
          codeAmAmount = 0,
          codeBreakAmount = 0,
          codePmAmount = 0;

        Object.values(mergedEmpDetails).forEach((pay) => {
          instantAllTotal += pay.amAmount + pay.breakAmount + pay.pmAmount + pay.tipShareReceived;
          tipshareAllTotal += pay.tipShareReceived;
          totalAmMin += pay.minutesAm;
          totalBreakMin += pay.minutesBreak;
          totalPmMin += pay.minutesPm;
          codeAmAmount = pay.totalAmAmount;
          codeBreakAmount = pay.totalBreakAmount;
          codePmAmount = pay.totalPmAmount;

          laborPaymentData.push({
            employee_number: pay.employeeId,
            name: pay.name,
            am: pay.minutesAm,
            amAmount: pay.minutesAm * pay.amountAmPerMinutes,
            break: pay.minutesBreak,
            breakAmount: pay.minutesBreak * pay.amountBreakPerMinutes,
            pm: pay.minutesPm,
            pmAmount: pay.minutesPm * pay.amountPmPerMinutes,
            total_minutes: pay.minutesAm + pay.minutesBreak + pay.minutesPm,
            amount_per_minutes: `${roundNumberUpAccurately(
              pay.amountAmPerMinutes,
              5
            )} | ${roundNumberUpAccurately(
              pay.amountBreakPerMinutes,
              5
            )} | ${roundNumberUpAccurately(pay.amountPmPerMinutes, 5)}`,
            tip_owed_new: pay.amAmount + pay.breakAmount + pay.pmAmount,
            total_tipshare_received: roundNumberUp(pay.tipShareReceived),
            declared_tips: roundNumberUp(pay.declaredTips),
            instant_pay_total_new: roundNumberUpAccurately(
              pay.amAmount + pay.breakAmount + pay.pmAmount + pay.tipShareReceived,
              2
            ),
          });
        });

        setLaborPaymentDetails(laborPaymentData);
        setInstantAllTotal(instantAllTotal);
        setTipshareAllTotal(tipshareAllTotal);
        setTotalAmMin(totalAmMin);
        setTotalBreakMin(totalBreakMin);
        setTotalPmMin(totalPmMin);
        setCodeAmAmount(codeAmAmount);
        setCodeBreakAmount(codeBreakAmount);
        setCodePmAmount(codePmAmount);

        console.log(laborPaymentData, "This is the breakdown");
        console.log(mergedEmpDetails);
      }
    };

    const btn = document.getElementsByClassName("progress_btn")[0];
    fetchStoreDetails();
    fetchBarEmergencyCodes();
    fetchSalesReport();
    fetchTipDetails();
    fetchBarTenderPayment(btn);
    fetchTipIncomeReport();
    fetchMergedEmpDetails();
  }, [setTipDetailsBartender]);

  const initialValues = {
    tipSummary: laborPaymentDetails ? laborPaymentDetails : [],
  };

  const calculateTipAmountGeneric = ({
    summary,
    tipKey,
    instantPayKey,
    barEmergencyAmount,
    amountToBeDistributed,
    setShowBalanceAlert,
    setAmountLeft,
    setInstantPaytotal,
  }) => {
    console.log(summary, "This summary");

    let totalTipOwed = summary.reduce((total, item) => {
      const tip = parseFloat(item[tipKey]);
      return !isNaN(tip) ? total + tip : total;
    }, 0);

    let totalInstantPay = summary.reduce((total, item) => {
      const instantPay = parseFloat(item[instantPayKey]);
      return !isNaN(instantPay) ? total + instantPay : total;
    }, 0);

    const roundedTotalTipOwed = roundNumberUp(totalTipOwed);
    const roundedBarEmergencyAmount = barEmergencyAmount
      ? parseFloat(roundNumberUp(barEmergencyAmount))
      : 0;
    const totalDistributed = parseFloat(roundNumberUp(amountToBeDistributed));

    if (parseFloat(roundedTotalTipOwed + roundedBarEmergencyAmount) > totalDistributed) {
      Swal.fire({
        title: "Error!",
        text: `Total tips owed cannot be more than the amount to be distributed. Please check the inputted amounts`,
        icon: "error",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          setShowBalanceAlert(false);
        }
      });
    } else {
      const newAmountLeft =
        amountToBeDistributed - (roundedTotalTipOwed + roundedBarEmergencyAmount);
      setAmountLeft(roundNumberUp(newAmountLeft));
      setInstantPaytotal(roundNumberUp(totalInstantPay));
    }
  };

  // Wrapper functions for specific cases
  const calculateTipAmount = (e, index, summary) => {
    calculateTipAmountGeneric({
      summary,
      tipKey: "tip_owed",
      instantPayKey: "instant_pay_total",
      barEmergencyAmount,
      amountToBeDistributed,
      setShowBalanceAlert,
      setAmountLeft,
      setInstantPaytotal,
    });
  };

  const calculateTipAmountHourly = (e, index, summary) => {
    calculateTipAmountGeneric({
      summary,
      tipKey: "tip_owed_new",
      instantPayKey: "instant_pay_total_new",
      barEmergencyAmount,
      amountToBeDistributed,
      setShowBalanceAlert,
      setAmountLeft,
      setInstantPaytotal,
    });
  };

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  const handlePaymentSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);

    const tipSummary = values.tipSummary.map((val) => ({
      employee_number: val.employee_number,
      instant_pay_total: val.instant_pay_total_new,
      name: val.name,
      tip_owed: val.tip_owed_new,
      total_tipshare_received: val.total_tipshare_received,
      declared_tips: val.declared_tips,
    }));

    const payTotal = tipSummary.reduce(
      (total, item) => total + parseFloat(item.instant_pay_total || 0),
      0
    );

    localStorage.setItem("barTenderPayment", JSON.stringify({ tipSummary }));

    const btnNext = document.querySelector("button:nth-of-type(7)");
    if (btnNext) btnNext.disabled = false;

    document.getElementById("instant_pay_total_group").classList.add("instant_pay_total_group");

    Swal.fire({
      title: "Updated!",
      text: `The total amount that will be submitted to instant pay for all bartenders is ${roundNumberUp(
        payTotal
      )}`,
      icon: "success",
      confirmButtonText: "Ok",
    }).finally(() => setSubmitting(false));

    setBarPaymentCreated(true);
  };

  const handleCancelPayments = () => {
    localStorage.removeItem("barTenderPayment");
    setBarPaymentCreated(false);
    setInstantPaytotal(0);
    setAmountLeft(0);

    const btn = document.querySelector("button:nth-of-type(7)");
    if (btn) btn.disabled = true;
  };

  return (
    <div className="content">
      {storeDetails && storeDetails.payments_options !== "manual" && (
        <div>
          <ShowHourBreakDown
            laborPaymentDetails={laborPaymentDetails}
            totalAmMin={totalAmMin}
            codeAmAmount={codeAmAmount}
            totalBreakMin={totalBreakMin}
            codeBreakAmount={codeBreakAmount}
            totalPmMin={totalPmMin}
            codePmAmount={codePmAmount}
          />
        </div>
      )}

      {storeDetails && storeDetails.payments_options === "pay_by_hour" ? (
        <div>
          <section>
            <h3 className="mb-4">
              Bartender
              <small id="emailHelp" className="form-text text-muted">
                Tips calculated based on number of hours worked
              </small>
            </h3>

            <div>
              {!barPaymentCreated && (
                <div>
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={handlePaymentSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      prop,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit} className="tipShareSummary">
                        <div className="row">
                          <div className="table-responsive col-md-12" style={{ marginTop: "60px" }}>
                            <FieldArray name="tipSummary">
                              {(fieldArrayProps) => {
                                const { form } = fieldArrayProps;

                                return <BartenderTipsTable tipSummary={form.values.tipSummary} />;
                              }}
                            </FieldArray>
                          </div>
                        </div>

                        <div className="row" style={{ marginTop: "20px", marginBottom: "20px" }}>
                          <div className="col-md-12">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-primary mr-2"
                            >
                              Submit Bartender Payment(s) &nbsp;&nbsp;
                              {isSubmitting ? <Spinner animation="border" size="sm" /> : ""}
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>

                  <div>
                    {barEmergencyCodes && barEmergencyCodes.length === 1 ? (
                      <div
                        className="row"
                        style={{
                          marginBottom: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <Formik
                          initialValues={{
                            barEmergencyCodes: barEmergencyCodes || [],
                            report_date: salesReport ? salesReport.report_date : "",
                            owner: salesReport ? salesReport.owner : "",
                          }}
                          enableReinitialize={true}
                          onSubmit={(values, { setSubmitting }) => {
                            console.log(values);
                            setSubmitting(true);
                          }}
                        >
                          {({ handleSubmit, isSubmitting }) => (
                            <div className="col-md-12">
                              <form onSubmit={handleSubmit}>
                                <div>
                                  <h4>Emergency Code(s) </h4>
                                  <span>
                                    <small id="emailHelp" className="form-text text-muted">
                                      Emergency Code Details
                                    </small>
                                  </span>
                                </div>
                                <div className="table-responsive" style={{ marginTop: "30px" }}>
                                  <FieldArray name="barEmergencyCodes">
                                    {(fieldArrayProps) => {
                                      const { form } = fieldArrayProps;
                                      const { values } = form;
                                      const { barEmergencyCodes } = values;

                                      return (
                                        <EmergencyCodeTable emergencyCodes={barEmergencyCodes} />
                                      );
                                    }}
                                  </FieldArray>
                                </div>

                                <div className="col-md-12" style={{ marginTop: "20px" }}>
                                  <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="btn btn-primary mr-2"
                                  >
                                    Submit Emergency Code &nbsp;&nbsp;
                                    {isSubmitting ? <Spinner animation="border" size="sm" /> : ""}
                                  </button>
                                </div>
                              </form>
                            </div>
                          )}
                        </Formik>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="row" id="total_tip_row">
                    <BartenderPageBoxAll
                      totalTipShare={tipshareAllTotal}
                      amountToBeDistributed={amountToBeDistributed}
                      instantAllTotal={instantAllTotal}
                    />
                  </div>
                </div>
              )}
            </div>

            <div>
              {barPaymentCreated && (
                <div>
                  <BarPaymentCreated />

                  <div className="row" style={{ position: "relative", top: "190px" }}>
                    <div className="col-md-12">
                      {barPaymentCreated && (
                        <button
                          type="button"
                          className="btn btn-inverse-dark mr-2"
                          onClick={handleCancelPayments}
                        >
                          Cancel &nbsp;&nbsp;
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="row" style={{ position: "relative", top: "-50px" }}>
                    <BartenderPageBoxAll
                      totalTipShare={totalTipShare}
                      amountToBeDistributed={amountToBeDistributed}
                      instantAllTotal={instantAllTotal}
                    />
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      ) : (
        <section>
          <h3 className="mb-2">
            {" "}
            Bartender{" "}
            <small id="emailHelp" className="form-text text-muted">
              Please click on the Submit button before moving to the next step
            </small>
          </h3>
          <SweetAlert
            show={showBalanceAlert}
            title="Alert!"
            text="Total tips owed is more than the amount to be distributed"
            onConfirm={() => setShowBalanceAlert(false)}
          />
          <div>
            {!barPaymentCreated && (
              <div>
                <Formik
                  initialValues={initialValues}
                  enableReinitialize={true}
                  onSubmit={(values, { setSubmitting }) => {
                    console.log(values);
                    setSubmitting(true);
                    console.log(isNaN(values.tipSummary[0].tip_owed));

                    if (!isNaN(values.tipSummary[0].tip_owed)) {
                      let pay_total = 0;
                      let owed_tips = 0;
                      values.tipSummary.map((val, index) => {
                        if (val.tip_owed !== undefined && !isNaN(val.tip_owed)) {
                          console.log(val.tip_owed);
                          return (owed_tips += parseFloat(val.tip_owed));
                        } else {
                          return val;
                        }
                      });

                      values.tipSummary.map((val, index) => {
                        if (val.tip_owed !== undefined && !isNaN(val.tip_owed)) {
                          console.log(val.tip_owed);
                          return (pay_total += parseFloat(roundNumberUp(val.instant_pay_total)));
                        } else {
                          return val;
                        }
                      });

                      if (
                        parseFloat(
                          roundNumberUp(
                            parseFloat(roundNumberUp(owed_tips)) +
                              (barEmergencyAmount
                                ? parseFloat(RoundNumberUp(barEmergencyAmount))
                                : 0)
                          )
                        ) == parseFloat(roundNumberUp(amountToBeDistributed))
                      ) {
                        setInstantPaytotal(parseFloat(roundNumberUp(pay_total)));
                        setSubmitting(false);
                        localStorage.setItem("barTenderPayment", JSON.stringify(values));

                        // Check to disbale appropriate button
                        if (storeDetails && storeDetails.payments_options !== "manual") {
                          let btn_next = document.getElementsByTagName("button")[6];
                          if (btn_next) {
                            btn_next.disabled = false;
                          }
                        } else {
                          let btn_next = document.getElementsByTagName("button")[5];
                          if (btn_next) {
                            btn_next.disabled = false;
                          }
                        }

                        let pay_group = document.getElementById("instant_pay_total_group");
                        pay_group.classList.add("instant_pay_total_group");

                        setBarPaymentCreated(true);

                        Swal.fire({
                          title: "Updated!",
                          text: `The total amount that will be submitted to instant pay for all bartenders is ${parseFloat(
                            roundNumberUp(pay_total)
                          )}`,
                          icon: "success",
                          confirmButtonText: "Ok",
                        }).then((result) => {
                          if (result.isConfirmed) {
                          }
                        });
                      } else {
                        Swal.fire({
                          title: "Error!",
                          text: `Total tips owed cannot be more or less than the amount to be distributed. Please submit emergency code if you have any`,
                          icon: "error",
                          confirmButtonText: "Ok",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            setShowBalanceAlert(false);
                          }
                        });
                        setSubmitting(false);
                      }
                    } else {
                      cogoToast.error("Tips owed cannot be empty", toastoptions);
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    prop,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit} className="tipShareSummary">
                      <div className="row">
                        <div className="table-responsive col-md-12" style={{ marginTop: "30px" }}>
                          <FieldArray name="tipSummary">
                            {(fieldArrayProps) => {
                              const { form } = fieldArrayProps;
                              const { values } = form;
                              const { tipSummary } = values;

                              return (
                                <table className="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th style={{ width: "5%" }}> Emp Number </th>
                                      <th style={{ width: "25%" }}>Employee Name</th>
                                      <th style={{ width: "10%" }}>Declared Tips</th>
                                      <th style={{ width: "20%" }}> Tipshare </th>
                                      <th style={{ width: "25%" }}> Tips Owed</th>
                                      {storeDetails &&
                                        storeDetails.payments_options === "hour_help" && (
                                          <th style={{ width: "25%" }}>Hourly Tips</th>
                                        )}
                                      <th style={{ width: "25%" }}> Instant Pay Total </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {tipSummary &&
                                      tipSummary.map((tip, index) => (
                                        <tr key={tip.employee_number}>
                                          <td>
                                            <Form.Group>
                                              <Field
                                                name={`tipSummary[${index}].employee_number`}
                                                className="form-control"
                                                placeholder="Employee Number"
                                                autoComplete="off"
                                                autoCorrect="off"
                                                autoCapitalize="off"
                                                spellCheck="false"
                                                disabled={"disabled"}
                                              />
                                            </Form.Group>
                                          </td>
                                          <td>
                                            <Form.Group>
                                              <Field
                                                name={`tipSummary[${index}].name`}
                                                className="form-control"
                                                placeholder="Employee Name"
                                                autoComplete="off"
                                                autoCorrect="off"
                                                autoCapitalize="off"
                                                spellCheck="false"
                                                disabled={"disabled"}
                                                style={{
                                                  border: "none",
                                                  fontWeight: "bold",
                                                }}
                                              />
                                            </Form.Group>
                                          </td>
                                          <td>
                                            <Form.Group>
                                              <div className="input-group">
                                                <div className="input-group-prepend">
                                                  <span className="input-group-text">$</span>
                                                </div>

                                                <Field
                                                  name={`tipSummary[${index}].declared_tips`}
                                                  className="form-control"
                                                  placeholder="Declared Tips"
                                                  autoComplete="off"
                                                  autoCorrect="off"
                                                  autoCapitalize="off"
                                                  spellCheck="false"
                                                  disabled={"disabled"}
                                                  value={roundNumberUp(tip.declared_tips)}
                                                />
                                              </div>
                                            </Form.Group>
                                          </td>
                                          <td>
                                            <Form.Group>
                                              <div className="input-group">
                                                <div className="input-group-prepend">
                                                  <span className="input-group-text">$</span>
                                                </div>

                                                <Field
                                                  name={`tipSummary[${index}].total_tipshare_received`}
                                                  className="form-control"
                                                  placeholder="Employee Name"
                                                  autoComplete="off"
                                                  autoCorrect="off"
                                                  autoCapitalize="off"
                                                  spellCheck="false"
                                                  disabled={"disabled"}
                                                  value={roundNumberUp(tip.total_tipshare_received)}
                                                />
                                              </div>
                                            </Form.Group>
                                          </td>
                                          <td>
                                            <Form.Group>
                                              <div className="input-group">
                                                <div className="input-group-prepend">
                                                  <span className="input-group-text">$ </span>
                                                </div>
                                                <Field
                                                  name={`tipSummary[${index}].tip_owed`}
                                                  className="form-control"
                                                  type="number"
                                                  autoComplete="off"
                                                  autoCorrect="off"
                                                  autoCapitalize="off"
                                                  spellCheck="false"
                                                  placeholder="Tips Owed"
                                                  disabled={isSubmitting}
                                                  defaultValue={tip.tip_owed ? tip.tip_owed : ""}
                                                  onBlur={(e) => {
                                                    calculateTipAmount(e, index, tipSummary);
                                                  }}
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      `tipSummary[${index}].tip_owed`,
                                                      parseFloat(e.target.value)
                                                    );
                                                    const instant_total =
                                                      parseFloat(
                                                        roundNumberUp(tip.total_tipshare_received)
                                                      ) + parseFloat(e.target.value);

                                                    setFieldValue(
                                                      `tipSummary[${index}].instant_pay_total`,
                                                      roundNumberUp(parseFloat(instant_total))
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </Form.Group>
                                          </td>

                                          {storeDetails &&
                                            storeDetails.payments_options === "hour_help" && (
                                              <td>
                                                <Form.Group>
                                                  <div className="input-group">
                                                    <div className="input-group-prepend">
                                                      <span
                                                        style={{
                                                          backgroundColor: "#3f50f6",
                                                        }}
                                                        id="copy-icon"
                                                        className="input-group-text"
                                                        onClick={(e) => {
                                                          setFieldValue(
                                                            `tipSummary[${index}].tip_owed`,
                                                            roundNumberUpAccurately(
                                                              tip.tip_owed_new,
                                                              4
                                                            )
                                                          );

                                                          const instant_total_new =
                                                            parseFloat(
                                                              roundNumberUp(
                                                                tip.total_tipshare_received
                                                              )
                                                            ) +
                                                            parseFloat(
                                                              roundNumberUp(tip.tip_owed_new)
                                                            );

                                                          setFieldValue(
                                                            `tipSummary[${index}].instant_pay_total`,
                                                            roundNumberUp(
                                                              parseFloat(instant_total_new)
                                                            )
                                                          );

                                                          calculateTipAmountHourly(
                                                            e,
                                                            index,
                                                            tipSummary
                                                          );
                                                        }}
                                                      >
                                                        <i className="mdi mdi-content-copy"></i>
                                                      </span>
                                                    </div>
                                                    <Field
                                                      name={`tipSummary[${index}].tip_owed_new`}
                                                      className="form-control"
                                                      placeholder="Tips Amount"
                                                      type="text"
                                                      disabled={"disabled"}
                                                      value={`$${roundNumberUpAccurately(
                                                        tip.tip_owed_new,
                                                        4
                                                      )}`}
                                                      onChange={(e) => console.log(e.target.value)}
                                                    />
                                                  </div>
                                                </Form.Group>
                                              </td>
                                            )}

                                          <td>
                                            <Form.Group>
                                              <div className="input-group">
                                                <div className="input-group-prepend">
                                                  <span className="input-group-text">$</span>
                                                </div>
                                                <Field
                                                  name={`tipSummary[${index}].instant_pay_total`}
                                                  className="form-control"
                                                  placeholder="Tips Amount"
                                                  type="number"
                                                  disabled={"disabled"}
                                                  defaultValue={
                                                    tip.instant_pay_total
                                                      ? parseFloat(tip.instant_pay_total)
                                                      : ""
                                                  }
                                                  onChange={(e) => console.log(e.target.value)}
                                                />
                                              </div>
                                            </Form.Group>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              );
                            }}
                          </FieldArray>
                        </div>
                      </div>

                      <div
                        className="row"
                        style={{ marginTop: "20px", marginBottom: "20px" }}
                        // style={{ position: "relative", top: "430px" }}
                      >
                        <div
                          className="col-md-12"
                          // style={{ marginTop: "80px" }}
                        >
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-primary mr-2"
                          >
                            Submit Bartender Payment(s) &nbsp;&nbsp;
                            {isSubmitting ? <Spinner animation="border" size="sm" /> : ""}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>

                <div>
                  {/* // Show only if bartender emergency bartender is not more than 1 */}
                  {barEmergencyCodes &&
                    barEmergencyCodes.length > 0 &&
                    !barEmergencyCodeCreated && (
                      <div
                        className="row"
                        style={{
                          marginBottom: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <Formik
                          initialValues={{
                            emergencyCodes: barEmergencyCodes ? barEmergencyCodes : [],
                            report_date: salesReport ? salesReport.report_date : "",
                            owner: salesReport ? salesReport.owner : "",
                          }}
                          enableReinitialize={true}
                          onSubmit={(values, { setSubmitting }) => {
                            console.log(values);
                            setSubmitting(true);

                            ExpensesService.createEmergencyExpenses(values)
                              .then((response) => {
                                console.log(response.data.data);
                                let barEmergency = response.data.data.filter((item) => {
                                  return item.job_code === "Bartender";
                                });
                                setSubmitting(false);
                                if (response.data.data.length > 0) {
                                  setBarEmergencyAmount(response.data.data[0].tips_owed);
                                  setBarEmergencyCodeCreated(true);
                                }
                                setBarEmergencyCodeDetails(barEmergency);
                              })
                              .catch((error) => {
                                setSubmitting(false);
                                console.log(error);
                              });
                          }}
                        >
                          {({ values, errors, handleSubmit, isSubmitting, setFieldValue }) => (
                            <div className="col-md-12">
                              <form onSubmit={handleSubmit}>
                                <div>
                                  <h4>Emergency Code(s) </h4>
                                  <span>
                                    <small id="emailHelp" className="form-text text-muted">
                                      Emergency Code Details
                                    </small>
                                  </span>
                                </div>
                                <div className="table-responsive" style={{ marginTop: "30px" }}>
                                  <FieldArray name="barEmergencyCodes">
                                    {(fieldArrayProps) => {
                                      const { form } = fieldArrayProps;

                                      return (
                                        <EmergencyCodeTable
                                          emergencyCodes={form.values.emergencyCodes}
                                        />
                                      );
                                    }}
                                  </FieldArray>
                                </div>

                                <div className="col-md-12" style={{ marginTop: "20px" }}>
                                  <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="btn btn-primary mr-2"
                                  >
                                    Submit Emergency Code &nbsp;&nbsp;
                                    {isSubmitting ? <Spinner animation="border" size="sm" /> : ""}
                                  </button>
                                </div>
                              </form>
                            </div>
                          )}
                        </Formik>
                      </div>
                    )}
                </div>

                {barEmergencyCodeDetails && barEmergencyCodeDetails.length > 0 && (
                  <BartenderEmergencyList barEmergencyCodeDetails={barEmergencyCodeDetails} />
                )}

                <div className="row" id="total_tip_row">
                  <BartenderPageBox
                    tipshareAllTotal={tipshareAllTotal}
                    amountLeft={amountLeft}
                    amountToBeDistributed={amountToBeDistributed}
                    instantPayTotal={instantPayTotal}
                  />
                </div>
              </div>
            )}
          </div>

          <div>
            {barPaymentCreated && (
              <div>
                <BarPaymentCreated />

                <div className="row" style={CancelBtnStyle}>
                  <div className="col-md-12">
                    {barPaymentCreated && (
                      <button
                        type="button"
                        className="btn btn-inverse-dark mr-2"
                        onClick={() => {
                          localStorage.removeItem("barTenderPayment");
                          setBarPaymentCreated(false);
                          setInstantPaytotal(0);
                          setAmountLeft(0);
                          let btn = document.getElementsByTagName("button")[6];
                          if (btn) {
                            btn.disabled = true;
                          }

                          ExpensesService.deleteEmergencyCodeForAStore(
                            barEmergencyCodeDetails ? barEmergencyCodeDetails : []
                          )
                            .then((response) => {
                              console.log(response.data.data);
                              setBarEmergencyCodeCreated(false);
                              setBarEmergencyCodeDetails([]);
                            })
                            .catch((error) => {
                              console.log(error.response.data);
                            });

                          // console.log(barEmergencyCodeDetails);
                        }}
                      >
                        Cancel &nbsp;&nbsp;
                      </button>
                    )}
                  </div>
                </div>

                {barEmergencyCodeDetails && barEmergencyCodeDetails.length > 0 && (
                  <BartenderEmergencyList barEmergencyCodeDetails={barEmergencyCodeDetails} />
                )}

                <div className="row">
                  <BartenderPageBox
                    tipshareAllTotal={tipshareAllTotal}
                    amountLeft={amountLeft}
                    amountToBeDistributed={amountToBeDistributed}
                    instantPayTotal={instantPayTotal}
                  />
                </div>
              </div>
            )}
          </div>
        </section>
      )}
    </div>
  );
};

export default PayBarStage;
