import React, { useState, useEffect } from "react";
import { withRouter, useHistory, useParams, Link } from "react-router-dom";
import cogoToast from "cogo-toast";
import { Formik } from "formik";
import { SystemConfigService } from "../../../service/configService";
import { Button, Form, Spinner } from "react-bootstrap";

const ViewCorporation = () => {
  const [editMode, setEditMode] = useState(false);
  const [corporation, setCorporation] = useState(null);

  const history = useHistory();
  let { id } = useParams();

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  useEffect(() => {
    SystemConfigService.getCorporation(id)
      .then((response) => {
        console.log(response.data.data);
        setCorporation(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, [id]);

  return (
    <div>
      <div className="page-header">
        {corporation && (
          <h3 className="page-title config-header-title">
            {" "}
            Corporation <span>/ {corporation.name} </span>
          </h3>
        )}
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/system-configuration/all"}>Portal configuration</Link>
            </li>
            {!editMode && (
              <li className="breadcrumb-item active" aria-current="page">
                <Button
                  onClick={() => {
                    setEditMode(true);
                  }}
                >
                  {" "}
                  Edit{" "}
                </Button>
              </li>
            )}
          </ol>
        </nav>
      </div>

      {corporation && (
        <Formik
          initialValues={{
            id: corporation.id,
            name: corporation.name,
            headquarters_address: corporation.headquarters_address,
            state: corporation.state,
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            SystemConfigService.updateCorporation(values)
              .then((response) => {
                console.log(response);
                setSubmitting(false);
                cogoToast.success(
                  "Corporation updated successfully",
                  toastoptions
                );
                setEditMode(false);
                history.push("/system-configuration/view-corporations");
              })
              .catch((error) => {
                console.log(error.response);
                cogoToast.error(error.response.data.message, toastoptions);
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <div className="mt-3 mb-3">
              <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card" style={{ border: "none" }}>
                    <div className="card-body">
                      <form className="pageForms" onSubmit={handleSubmit}>
                        <div className="row border-bottom mb-5">
                          <div className="col-md-6">
                            <Form.Group>
                              <label htmlFor="name"> Name </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                placeholder="Name of the domain"
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                onChange={handleChange}
                                defaultValue={values.name}
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="headquarters_address">
                                Headquarter Address
                              </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="editHeadquartersAddress"
                                name="headquarters_address"
                                placeholder="Address"
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                onChange={handleChange}
                                defaultValue={values.headquarters_address}
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="editState"> State</label>
                              <Form.Control
                                type="text"
                                as="select"
                                className="form-control"
                                id="editState"
                                name="state"
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                onChange={handleChange}
                                defaultValue={
                                  values.state ? values.state : "DEFAULT"
                                }
                              >
                                <option value="DEFAULT" disabled>
                                  {" "}
                                  Select One{" "}
                                </option>
                                <option value="AK"> Alaska - AK </option>
                                <option value="AZ"> Arizona - AZ </option>
                                <option value="AR"> Arkansas - AR </option>
                                <option value="CA"> California - CA </option>
                                <option value="CO"> Colorado - CO </option>
                                <option value="CT"> Connecticut - CT </option>
                                <option value="DE"> Delaware - DE </option>
                                <option value="FL"> Florida - FL </option>
                                <option value="GA"> Georgia - GA </option>
                                <option value="HI"> Hawaii - HI </option>
                                <option value="ID"> Idaho - ID </option>
                                <option value="IL"> Illinois - IL </option>
                                <option value="IN"> Indiana - IN </option>
                                <option value="IA"> Iowa - IA </option>
                                <option value="KS"> Kansas - KS </option>
                                <option value="KY"> Kentucky - KY </option>
                                <option value="LA"> Louisiana - LA </option>
                                <option value="ME"> Maine - ME </option>
                                <option value="MD"> Maryland - MD </option>
                                <option value="MA"> Massachusetts - MA </option>
                                <option value="MI"> Michigan - MI </option>
                                <option value="MN"> Minnesota - MN </option>
                                <option value="MS"> Mississippi - MS </option>
                                <option value="MO"> Missouri - MO </option>
                                <option value="MT"> Montana - MT </option>
                                <option value="NE"> Nebraska - NE </option>
                                <option value="NV"> Nevada - NV </option>
                                <option value="NH"> New Hampshire - NH </option>
                                <option value="NJ"> New Jersey - NJ </option>
                                <option value="NM"> New Mexico - NM </option>
                                <option value="NY"> New York - NY </option>
                                <option value="NC">
                                  {" "}
                                  North Carolina - NC{" "}
                                </option>
                                <option value="ND"> North Dakota - ND </option>
                                <option value="OH"> Ohio - OH </option>
                                <option value="OK"> Oklahoma - OK </option>
                                <option value="OR"> Oregon - OR </option>
                                <option value="PA"> Pennsylvania - PA </option>
                                <option value="RI"> Rhode Island - RI </option>
                                <option value="SC">
                                  {" "}
                                  South Carolina - SC{" "}
                                </option>
                                <option value="SD"> South Dakota - SD </option>
                                <option value="TN"> Tennessee - TN </option>
                                <option value="TX"> Texas - TX </option>
                                <option value="UT"> Utah - UT </option>
                                <option value="VT"> Vermont - VT </option>
                                <option value="VA"> Virginia - VA </option>
                                <option value="WA"> Washington - WA </option>
                                <option value="WV"> West Virginia - WV </option>
                                <option value="AZ"> Wisconsin - WI </option>
                                <option value="MY"> Wyoming - WY </option>
                              </Form.Control>
                            </Form.Group>
                          </div>
                        </div>
                        {editMode && (
                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary btn-icon-text"
                            >
                              <i className="mdi mdi-file-check btn-icon-prepend"></i>{" "}
                              Update&nbsp;&nbsp;
                              {isSubmitting ? (
                                <Spinner animation="border" size="sm" />
                              ) : (
                                ""
                              )}
                            </button>

                            <button
                              className="btn btn-light"
                              onClick={() => {
                                setEditMode(false);
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
};

export default withRouter(ViewCorporation);
