import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router";
import EvidenceReader from "../../components/EvidenceReader";
import { ExpensesService } from "../../service/ExpenseService";
import { Utility } from "../../Helpers/utils";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { EvidenceOfPayment } from "../../components/EvidenceOfPayment";
import { Formik } from "formik";
import { Button, Form, Modal } from "react-bootstrap";
import Swal from "sweetalert2";

const StoreExpensesDoc = () => {
  let { store_id, expense_id } = useParams();
  const [expenseDoc, setExpenseDoc] = useState(null);
  const [expensesAmount, setExpensesAmount] = useState(null);
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [uploadTitle, setUploadTitle] = useState("");

  const isString = (value) => {
    return typeof value === "string" ? true : false;
  };

  const user = Utility.getUser();

  const roundNumberUp = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  useEffect(() => {
    const data = { store_id, expense_id };
    ExpensesService.getExpensesDocs(data)
      .then((response) => {
        console.log(response.data.data, "DOCS");
        setExpenseDoc(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });

    ExpensesService.getAParticularExpensesAmountForStore(data)
      .then((response) => {
        // let dataResArray = [];
        setExpensesAmount(response.data.data);
        // response.data.data.map((res, index) => {
        //   return dataResArray.push({
        //     auto_truck: res.auto_truck
        //       ? roundNumberUp(res.auto_truck)
        //       : roundNumberUp(0),
        //     cleaning: res.cleaning
        //       ? roundNumberUp(res.cleaning)
        //       : roundNumberUp(0),
        //     food_purchases: res.food_purchases
        //       ? roundNumberUp(res.food_purchases)
        //       : roundNumberUp(0),
        //     gift_promotion: res.gift_promotion
        //       ? roundNumberUp(res.gift_promotion)
        //       : roundNumberUp(0),
        //     id: res.id,
        //     office_supplies: res.office_supplies
        //       ? roundNumberUp(res.office_supplies)
        //       : roundNumberUp(0),
        //     other: res.other ? roundNumberUp(res.other) : roundNumberUp(0),
        //     repair: res.repair ? roundNumberUp(res.repair) : roundNumberUp(0),
        //     rest_supplies: res.rest_supplies
        //       ? roundNumberUp(res.rest_supplies)
        //       : roundNumberUp(0),
        //   });
        // });
        // setExpensesAmount(dataResArray);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

  const addMoreExpenseDoc = (text) => {
    setShowAddNewModal(true);
    setUploadTitle(text);
  };

  return (
    <div>
      {showAddNewModal && (
        <div>
          <Formik
            initialValues={{
              id: expense_id,
              store_id: store_id,
              file_type: "",
              receipt_url: [],
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);

              const data = new FormData();
              data.append("id", values.id);
              data.append("store_id", values.store_id);

              values.auto_truck_receipt_url.map((file) => {
                return data.append("auto_truck_receipt_url[]", file, file.name);
              });

              values.cleaning_receipt_url.map((file) => {
                return data.append("cleaning_receipt_url[]", file, file.name);
              });

              values.food_purchases_receipt_url.map((file) => {
                return data.append("food_purchases_receipt_url[]", file, file.name);
              });

              values.office_supplies_receipt_url.map((file) => {
                return data.append("office_supplies_receipt_url[]", file, file.name);
              });

              values.repair_receipt_url.map((file) => {
                return data.append("repair_receipt_url[]", file, file.name);
              });

              values.rest_supplies_receipt_url.map((file) => {
                return data.append("rest_supplies_receipt_url[]", file, file.name);
              });

              values.gift_promotion_receipt_url.map((file) => {
                return data.append("gift_promotion_receipt_url[]", file, file.name);
              });

              values.other_receipt_url.map((file) => {
                return data.append("other_receipt_url[]", file, file.name);
              });

              ExpensesService.uploadExpensesReceipt(data)
                .then((response) => {
                  console.log(response);
                  Swal.fire({
                    title: "Good Job!",
                    text: "Receipts uploaded successfully",
                    icon: "success",
                    confirmButtonText: "Ok",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      window.location.reload();
                    }
                  });
                  setSubmitting(false);
                })
                .catch((error) => {
                  console.log(error);
                  setSubmitting(false);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <Modal
                show={showAddNewModal}
                onHide={() => setShowAddNewModal(false)}
                aria-labelledby="example-modal-sizes-title-md"
                style={{ top: "200px" }}
                backdrop="static"
              >
                <form name="form" onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title>Add More Receipt </Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ paddingBottom: "0px" }}>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th style={{ width: "20%" }}> {uploadTitle} </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div>
                              <EvidenceOfPayment
                                setFieldValue={setFieldValue}
                                name={"auto_truck_receipt_url"}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Modal.Body>
                  {/* Button temporarily disabled */}
                  <Modal.Footer className="fleex-wrap" style={{ paddingTop: "0px" }}>
                    {" "}
                    <Button
                      type="submit"
                      className="btn btn-block btn-primary font-weight-medium auth-form-btn"
                      disabled={"disabled"}
                    >
                      Proceed &nbsp;&nbsp;
                      {isSubmitting ? (
                        // <Spinner animation="border" size="sm" />
                        <span className="fa fa-spin fa-spinner"></span>
                      ) : (
                        ""
                      )}
                    </Button>
                    <Button variant="light m-2" onClick={() => setShowAddNewModal(false)}>
                      Cancel
                    </Button>
                  </Modal.Footer>
                </form>
              </Modal>
            )}
          </Formik>
        </div>
      )}

      <div className="mb-5">
        {user && user.role === "super admin" ? (
          <Link to="/financials/expenses-viewers">
            <button className="btn btn-primary"> Back </button>
          </Link>
        ) : (
          <Link to={`/financials/view-expenses-store/${store_id}`}>
            <button className="btn btn-primary"> Back </button>
          </Link>
        )}
      </div>
      <p> View Expenses Receipts</p>

      {expenseDoc && expensesAmount && (
        <div>
          <Formik
            initialValues={{
              id: expense_id,
              store_id: store_id,
              auto_truck_receipt_url:
                expenseDoc && expenseDoc.auto_truck_receipt_url
                  ? expenseDoc.auto_truck_receipt_url
                  : [],
              cleaning_receipt_url:
                expenseDoc && expenseDoc.cleaning_receipt_url
                  ? expenseDoc.cleaning_receipt_url
                  : [],
              food_purchases_receipt_url:
                expenseDoc && expenseDoc.food_purchases_receipt_url
                  ? expenseDoc.food_purchases_receipt_url
                  : [],
              office_supplies_receipt_url:
                expenseDoc && expenseDoc.office_supplies_receipt_url
                  ? expenseDoc.office_supplies_receipt_url
                  : [],
              repair_receipt_url:
                expenseDoc && expenseDoc.repair_receipt_url ? expenseDoc.repair_receipt_url : [],
              rest_supplies_receipt_url:
                expenseDoc && expenseDoc.rest_supplies_receipt_url
                  ? expenseDoc.rest_supplies_receipt_url
                  : [],
              gift_promotion_receipt_url:
                expenseDoc && expenseDoc.gift_promotion_receipt_url
                  ? expenseDoc.gift_promotion_receipt_url
                  : [],
              other_receipt_url:
                expenseDoc && expenseDoc.other_receipt_url ? expenseDoc.other_receipt_url : [],
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);

              console.log(isString(values.auto_truck_receipt_url), "Right here");

              const data = new FormData();
              data.append("id", values.id);
              data.append("store_id", values.store_id);

              if (isString(values.auto_truck_receipt_url)) {
                data.append("auto_truck_receipt_url", values.auto_truck_receipt_url);
              } else {
                values.auto_truck_receipt_url.map((file) => {
                  return data.append("auto_truck_receipt_url[]", file, file.name);
                });
              }

              if (isString(values.cleaning_receipt_url)) {
                data.append("cleaning_receipt_url", values.cleaning_receipt_url);
              } else {
                values.cleaning_receipt_url.map((file) => {
                  return data.append("cleaning_receipt_url[]", file, file.name);
                });
              }

              if (isString(values.food_purchases_receipt_url)) {
                data.append("food_purchases_receipt_url", values.food_purchases_receipt_url);
              } else {
                values.food_purchases_receipt_url.map((file) => {
                  return data.append("food_purchases_receipt_url[]", file, file.name);
                });
              }

              if (isString(values.office_supplies_receipt_url)) {
                data.append("office_supplies_receipt_url", values.office_supplies_receipt_url);
              } else {
                values.office_supplies_receipt_url.map((file) => {
                  return data.append("office_supplies_receipt_url[]", file, file.name);
                });
              }

              if (isString(values.repair_receipt_url)) {
                data.append("repair_receipt_url", values.repair_receipt_url);
              } else {
                values.repair_receipt_url.map((file) => {
                  return data.append("repair_receipt_url[]", file, file.name);
                });
              }

              if (isString(values.rest_supplies_receipt_url)) {
                data.append("rest_supplies_receipt_url", values.rest_supplies_receipt_url);
              } else {
                values.rest_supplies_receipt_url.map((file) => {
                  return data.append("rest_supplies_receipt_url[]", file, file.name);
                });
              }

              if (isString(values.gift_promotion_receipt_url)) {
                data.append("gift_promotion_receipt_url", values.gift_promotion_receipt_url);
              } else {
                values.gift_promotion_receipt_url.map((file) => {
                  return data.append("gift_promotion_receipt_url[]", file, file.name);
                });
              }

              if (isString(values.other_receipt_url)) {
                data.append("other_receipt_url", values.other_receipt_url);
              } else {
                values.other_receipt_url.map((file) => {
                  return data.append("other_receipt_url[]", file, file.name);
                });
              }

              ExpensesService.uploadExpensesReceipt(data)
                .then((response) => {
                  console.log(response);
                  Swal.fire({
                    title: "Good Job!",
                    text: "Receipts uploaded successfully",
                    icon: "success",
                    confirmButtonText: "Ok",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      window.location.reload();
                    }
                  });
                  setSubmitting(false);
                })
                .catch((error) => {
                  console.log(error);
                  setSubmitting(false);
                });
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <div className="table-responsive">
                <form onSubmit={handleSubmit}>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th style={{ width: "40%" }}> Expenses </th>
                        <th style={{ width: "20%" }}> Amount </th>

                        <th style={{ width: "20%" }}> Receipt </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td> Auto and Truck </td>

                        <td>
                          <NumberFormat
                            defaultValue={roundNumberUp(expensesAmount.auto_truck)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>

                        <td>
                          {expensesAmount.auto_truck && (
                            <div>
                              {expenseDoc.auto_truck_receipt_url &&
                              isString(expenseDoc.auto_truck_receipt_url) ? (
                                <div>
                                  <EvidenceReader
                                    pdf={expenseDoc.auto_truck_receipt_url}
                                    user={user}
                                  />

                                  <div>
                                    <button>
                                      <span className="mdi mdi-plus"></span>
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <EvidenceOfPayment
                                  setFieldValue={setFieldValue}
                                  name={"auto_truck_receipt_url"}
                                />
                              )}
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td> Cleaning & Laundry </td>

                        <td>
                          <NumberFormat
                            defaultValue={roundNumberUp(expensesAmount.cleaning)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>

                        <td>
                          {expensesAmount.cleaning && (
                            <span>
                              {expenseDoc.cleaning_receipt_url &&
                              isString(expenseDoc.cleaning_receipt_url) ? (
                                <EvidenceReader pdf={expenseDoc.cleaning_receipt_url} user={user} />
                              ) : (
                                <EvidenceOfPayment
                                  setFieldValue={setFieldValue}
                                  name={"cleaning_receipt_url"}
                                />
                              )}
                            </span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td> Food Purchase </td>

                        <td>
                          <NumberFormat
                            defaultValue={roundNumberUp(expensesAmount.food_purchases)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>

                        <td>
                          {expensesAmount.food_purchases && (
                            <span>
                              {expenseDoc.food_purchases_receipt_url &&
                              isString(expenseDoc.food_purchases_receipt_url) ? (
                                <EvidenceReader
                                  pdf={expenseDoc.food_purchases_receipt_url}
                                  user={user}
                                />
                              ) : (
                                <EvidenceOfPayment
                                  setFieldValue={setFieldValue}
                                  name={"food_purchases_receipt_url"}
                                />
                              )}
                            </span>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td> Office Supplies </td>

                        <td>
                          <NumberFormat
                            defaultValue={roundNumberUp(expensesAmount.office_supplies)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>

                        <td>
                          {expensesAmount.office_supplies && (
                            <span>
                              {expenseDoc.office_supplies_receipt_url &&
                              isString(expenseDoc.office_supplies_receipt_url) ? (
                                <EvidenceReader
                                  pdf={expenseDoc.office_supplies_receipt_url}
                                  user={user}
                                />
                              ) : (
                                <EvidenceOfPayment
                                  setFieldValue={setFieldValue}
                                  name={"office_supplies_receipt_url"}
                                />
                              )}
                            </span>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td> Repair & Maintenance </td>

                        <td>
                          <NumberFormat
                            defaultValue={roundNumberUp(expensesAmount.repair)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>

                        <td>
                          {expensesAmount.repair && (
                            <span>
                              {expenseDoc.repair_receipt_url &&
                              isString(expenseDoc.repair_receipt_url) ? (
                                <EvidenceReader pdf={expenseDoc.repair_receipt_url} user={user} />
                              ) : (
                                <EvidenceOfPayment
                                  setFieldValue={setFieldValue}
                                  name={"repair_receipt_url"}
                                />
                              )}
                            </span>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td> Rest. Supplies </td>

                        <td>
                          <NumberFormat
                            defaultValue={roundNumberUp(expensesAmount.rest_supplies)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>

                        <td>
                          {expensesAmount.rest_supplies && (
                            <span>
                              {expenseDoc.rest_supplies_receipt_url &&
                              isString(expenseDoc.rest_supplies_receipt_url) ? (
                                <EvidenceReader
                                  pdf={expenseDoc.rest_supplies_receipt_url}
                                  user={user}
                                />
                              ) : (
                                <EvidenceOfPayment
                                  setFieldValue={setFieldValue}
                                  name={"rest_supplies_receipt_url"}
                                />
                              )}
                            </span>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td> Gift Promotion </td>

                        <td>
                          <NumberFormat
                            defaultValue={roundNumberUp(expensesAmount.gift_promotion)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>

                        <td>
                          {expensesAmount.gift_promotion && (
                            <div>
                              {expenseDoc.gift_promotion_receipt_url &&
                              isString(expenseDoc.gift_promotion_receipt_url) ? (
                                <div>
                                  <EvidenceReader
                                    pdf={expenseDoc.gift_promotion_receipt_url}
                                    user={user}
                                  />

                                  {/* <div>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        addMoreExpenseDoc("Gift Promotion");
                                      }}
                                    >
                                      <span className="mdi mdi-plus"></span>
                                    </button>
                                  </div> */}
                                </div>
                              ) : (
                                <EvidenceOfPayment
                                  setFieldValue={setFieldValue}
                                  name={"gift_promotion_receipt_url"}
                                />
                              )}
                            </div>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td> Other </td>

                        <td>
                          <NumberFormat
                            defaultValue={roundNumberUp(expensesAmount.other)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>

                        <td>
                          {expensesAmount.other && (
                            <span>
                              {expenseDoc.other_receipt_url &&
                              isString(expenseDoc.other_receipt_url) ? (
                                <EvidenceReader pdf={expenseDoc.other_receipt_url} user={user} />
                              ) : (
                                <EvidenceOfPayment
                                  setFieldValue={setFieldValue}
                                  name={"other_receipt_url"}
                                />
                              )}
                            </span>
                          )}
                        </td>
                      </tr>

                      {expensesAmount.other > 0 && (
                        <tr>
                          <td> Expenses Details </td>

                          <td>
                            {" "}
                            <Form.Group>
                              <Form.Control
                                as="textarea"
                                rows="6"
                                columns="10"
                                className="form-control"
                                id="expenses_details"
                                name="expenses_details"
                                value={expenseDoc.expenses_details ?? ""}
                                disabled={"disabled"}
                              />
                            </Form.Group>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="mt-4">
                    <Button
                      type="submit"
                      className="btn btn-block btn-primary font-weight-medium auth-form-btn"
                    >
                      Submit Receipt &nbsp;&nbsp;
                      {isSubmitting ? (
                        // <Spinner animation="border" size="sm" />
                        <span className="fa fa-spin fa-spinner"></span>
                      ) : (
                        ""
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default withRouter(StoreExpensesDoc);
