import React, { Component, useState } from "react";
import { useParams, withRouter, Link, useHistory } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import ReactTags from "react-tag-autocomplete";
import { CateringService } from "../../service/CateringService";
import cogoToast from "cogo-toast";

const ConfigureCateringPayment = () => {
  const [tags, setTags] = useState([]);
  let history = useHistory();
  const cateringCode = history.location.state.code;
  const storeId = history.location.state.store_id;

  const handleDeleteForTags = (i) => {
    // const remainingTags = tags.slice(0);
    // remainingTags.splice(i, 1);
    // setTags({ remainingTags });
  };

  const handleAddingTags = (tag) => {
    // const newTags = [].concat(tags, tag);
    // setTags({ newTags });
  };

  const handleTagChange = (t) => {
    setTags(t);
  };

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  return (
    <div>
      <div className="mb-5">
        <Link to={"/catering/catering-payment"}>
          <button className="btn btn-primary"> Back </button>
        </Link>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="page-header">
            <h3 className="page-title"> Configure Payment </h3>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb"></ol>
            </nav>
          </div>
          <Formik
            initialValues={{
              catering_code: cateringCode ? cateringCode : "",
              catering_date: "",
              drop_off_option: "",
              drop_off_amount: "",
              number_of_employees: "",
              drop_off_radio_option: "",
              store_id: storeId ? storeId : "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              let data = {
                values,
                tags,
              };

              CateringService.createCateringPayment(data)
                .then((response) => {
                  setSubmitting(false);
                  console.log(response.data.data);
                  cogoToast.success("Store created successfully", toastoptions);
                  setTimeout(() => {
                    history.push("/catering/catering-payment");
                  }, 200);
                })
                .catch((error) => {
                  console.log(error);
                  setSubmitting(false);
                });

              console.log(data);
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <div className="row">
                <div className="col-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      {/* <h4 className="card-title">
                    Fill the details below to complete
                  </h4> */}
                      <form
                        className="forms-sample"
                        name="form"
                        onSubmit={handleSubmit}
                      >
                        <Form.Group>
                          <label htmlFor="catering_code">
                            <b>Catering Code</b>{" "}
                          </label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="catering_code"
                            name="catering_code"
                            defaultValue={cateringCode ? cateringCode : ""}
                            onChange={handleChange}
                            disabled={"disabled"}
                            placeholder="Catering Code"
                          />
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="catering_date">
                            {" "}
                            <b>Catering Date</b>{" "}
                          </label>
                          <Form.Control
                            type="date"
                            id="catering_date"
                            className="form-control w-100"
                            name="catering_date"
                            value={values.catering_date}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                          />
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="drop_off_option">
                            {" "}
                            <b>DropOff ?</b>{" "}
                          </label>
                          <Form.Control
                            type="text"
                            as="select"
                            className="form-control"
                            id="drop_off_option"
                            name="drop_off_option"
                            defaultValue={
                              values.drop_off_option
                                ? values.drop_off_option
                                : "DEFAULT"
                            }
                            onChange={handleChange}
                            disabled={isSubmitting}
                          >
                            <option value="DEFAULT" disabled>
                              {" "}
                              Select One{" "}
                            </option>
                            <option value="yes"> Yes </option>
                            <option value="no"> No </option>
                          </Form.Control>
                        </Form.Group>

                        {values.drop_off_option &&
                        values.drop_off_option === "yes" ? (
                          <Form.Group>
                            <label htmlFor="drop_off_amount">
                              {" "}
                              <b> DropOff Amount Option</b>{" "}
                            </label>
                            <Form.Control
                              type="text"
                              as="select"
                              className="form-control"
                              id="drop_off_amount"
                              name="drop_off_amount"
                              value={
                                values.drop_off_amount
                                  ? values.drop_off_amount
                                  : "DEFAULT"
                              }
                              onChange={handleChange}
                              disabled={isSubmitting}
                            >
                              <option value="DEFAULT" disabled>
                                {" "}
                                Select One{" "}
                              </option>
                              <option value="25"> $25 </option>
                              <option value="35"> $35 </option>
                            </Form.Control>
                          </Form.Group>
                        ) : (
                          ""
                        )}

                        <Form.Group>
                          <label htmlFor="number_of_employees">
                            {" "}
                            <b> Number of Employee</b>
                          </label>
                          <Form.Control
                            type="text"
                            as="select"
                            className="form-control"
                            id="number_of_employees"
                            name="number_of_employees"
                            value={
                              values.number_of_employees
                                ? values.number_of_employees
                                : "DEFAULT"
                            }
                            onChange={handleChange}
                            disabled={isSubmitting}
                          >
                            <option value="DEFAULT" disabled>
                              {" "}
                              Select One{" "}
                            </option>
                            <option value="1"> One </option>
                            <option value="2"> Two </option>
                            <option value="3"> Three </option>
                            <option value="4"> Four </option>
                            <option value="5"> Five </option>
                            <option value="6"> Six </option>
                            <option value="7"> Seven </option>
                            <option value="8"> Eight </option>
                            <option value="9"> Nine </option>
                            <option value="10"> Ten </option>
                          </Form.Control>
                        </Form.Group>

                        {values.number_of_employees &&
                        values.number_of_employees !== "DEFAULT" ? (
                          <Form.Group>
                            <label htmlFor="tags">
                              <b>
                                {" "}
                                Employee Number (s)
                                <br />
                              </b>

                              <i> Type employee's number and press enter</i>
                            </label>

                            <TagsInput
                              limit={
                                values.number_of_employees
                                  ? values.number_of_employees
                                  : 0
                              }
                              handler={handleTagChange}
                            />
                          </Form.Group>
                        ) : (
                          ""
                        )}

                        {values.drop_off_option &&
                        values.drop_off_option === "yes" ? (
                          <Form.Group>
                            <label>Select Employee for DropOff </label>
                            {tags &&
                              tags.map((t, index) => (
                                <div className="form-check" key={index}>
                                  <label className="form-check-label">
                                    <input
                                      type="radio"
                                      className="form-check-input"
                                      name="drop_off_radio_option"
                                      id={`drop_off_radio_option_${index}`}
                                      value={t.name}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "drop_off_radio_option",
                                          e.currentTarget.value
                                        );
                                      }}
                                    />
                                    <i className="input-helper"></i>
                                    {t.name}
                                  </label>
                                </div>
                              ))}
                          </Form.Group>
                        ) : (
                          ""
                        )}

                        <button type="submit" className="btn btn-primary mr-2">
                          Submit &nbsp;&nbsp;
                          {isSubmitting ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            ""
                          )}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

class TagsInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: [],
    };
  }

  handleDelete(i) {
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags });
  }

  handleAddition(tag) {
    const tags = [].concat(this.state.tags, tag);
    if (tags.length <= this.props.limit) {
      this.setState({ tags });
    }
  }

  componentDidMount() {
    console.log(this.props.limit);
  }

  render() {
    return (
      <ReactTags
        tags={this.state.tags}
        placeholder={`Add employee's number`}
        allowNew={true}
        handleDelete={this.handleDelete.bind(this)}
        handleAddition={this.handleAddition.bind(this)}
        handleBlur={this.props.handler(this.state.tags)}
      />
    );
  }
}

export default withRouter(ConfigureCateringPayment);
