import React from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import cogoToast from "cogo-toast";
import { Formik } from "formik";
import { EmployeeConfigService } from "../../../../service/employeeConfigService";

const Ethnics = () => {
  const history = useHistory();

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Ethnic </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/system-configuration/employee"}>
                Employee configuration
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Ethnic
            </li>
          </ol>
        </nav>
      </div>
      <Formik
        initialValues={{
          name: "",
          description: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (values.name) {
            EmployeeConfigService.createEthnic(values)
              .then((response) => {
                console.log(response.data);
                if (response.data.success) {
                  setSubmitting(false);
                  cogoToast.success(
                    "Ethnic created successfully",
                    toastoptions
                  );
                  history.push("/employee-configuration/view-ethnics");
                }
              })
              .catch((error) => {
                if (error.response.data.errors) {
                  cogoToast.error(
                    error.response.data.errors.name[0],
                    toastoptions
                  );
                }
                cogoToast.error(error.response.data.message, toastoptions);
                setSubmitting(false);
              });
          } else {
            cogoToast.error("Please fill all the fields", toastoptions);
            setSubmitting(false);
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
          <div className="row">
            <div className="col-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">
                    Fill the details below to create an Ethnic
                  </h4>
                  <form
                    className="forms-sample"
                    name="form"
                    onSubmit={handleSubmit}
                  >
                    <Form.Group>
                      <label htmlFor="name">Ethnic</label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        disabled={isSubmitting}
                        placeholder="State Name"
                      />
                    </Form.Group>
                    <Form.Group>
                      <label htmlFor="headquarters_address">Description</label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="description"
                        placeholder="Description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        disabled={isSubmitting}
                      />
                    </Form.Group>

                    <button type="submit" className="btn btn-primary mr-2">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default withRouter(Ethnics);
