import React from "react";
import { FileUpload } from "./FileUpload";

export const SalesReportUpload = ({ setFieldValue }) => {
  return (
    <FileUpload
      setFieldValue={setFieldValue}
      fieldName="sales_report_attachement"
      acceptedTypes=".csv"
      label="Uploaded Sales Report"
    />
  );
};
