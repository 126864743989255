import React from "react";
import DashboardEntries from "../components/DashboardEntries";

const ApplicationEntries = ({ user }) => {
  return (
    <div className="col-md-12 stretch-card">
      <div className="card">
        <div className="card-body">
          <div>
            <div>
              <div className="float-left">
                <h4 className="card-title"> Application Entries </h4>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
          <div className="">
            <div className="row">
              <DashboardEntries />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationEntries;
