import React, { useEffect, useState } from "react";
import { BonusPayoutService } from "../../../service/BonusPayoutService";
import moment from "moment";
import GaugeChart from "react-gauge-chart";
import AppSpinner from "../../../components/Spinner";

const BonusPayoutKPI = ({ user }) => {
  const [evaluationStat, setEvaluationStat] = useState(null);

  const year = moment().year();
  const activeQuarter = moment().quarter();

  useEffect(() => {
    let data = {
      year,
      quarter: activeQuarter,
    };

    BonusPayoutService.getEvaluationStatsForManager(data)
      .then((response) => {
        console.log(response.data.data);
        setEvaluationStat(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="row" style={{ marginBottom: "30px" }}>
      <div className="col-md-12 stretch-card">
        <div className="card">
          <div className="card-body">
            <div>
              <div>
                <div className="float-left">
                  <h4 className="card-title"> Bonus Payout KPI</h4>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
            <div className="">
              {evaluationStat ? (
                <div className="row">
                  <div className="col-md-4">
                    <div>
                      <p> Evaluation from Staff</p>
                      <div>
                        <GaugeChart
                          id="gauge-chart1"
                          needleColor="#345243"
                          percent={
                            isNaN(evaluationStat.percentageEvaluation / 100)
                              ? 0
                              : evaluationStat.percentageEvaluation / 100
                          }
                          textColor="#000000"
                          needleBaseColor="green"
                          arcsLength={[0.6, 0.2, 0.2]}
                          colors={["#EA4228", "#F5CD19", "#5BE12C"]}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div
                      style={{
                        border: "1px dotted grey",
                        padding: "10px",
                        overflowX: "auto",
                      }}
                    >
                      <table className="table table-bordered">
                        <thead>
                          <tr
                            style={{
                              background: "grey",
                              color: "white",
                              paddingLeft: "2px",
                            }}
                          >
                            <th scope="col"> Result</th>
                            <th scope="col">Total Employees</th>
                            <th scope="col">Total Survey</th>
                            <th scope="col">%</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{evaluationStat.result}</td>
                            <td>{evaluationStat.totalEmployee}</td>
                            <td>{evaluationStat.totalSurveyForAManager}</td>
                            <td>{evaluationStat.percentageEvaluation} % </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <AppSpinner />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BonusPayoutKPI;
