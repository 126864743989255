import MESReport from "../report/MESReport";
import ListStoresForReport from "../report/ListStoresForReport";
import NewAccountingReport from "../report/AccountingReport";

const reportRoutes = [
  { path: "/report/mes/:id", component: MESReport },
  { path: "/report/list-stores", component: ListStoresForReport },
  { path: "/report/accounting-report", component: NewAccountingReport },
];

export default reportRoutes;
