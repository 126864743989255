const getStoreName = (store_id) => {
  let store_name;

  switch (parseInt(store_id)) {
    case 1:
      store_name = "La Parrilla (Marietta Square)";
      break;
    case 2:
      store_name = "La Parrilla (Dallas Hwy)";
      break;
    case 3:
      store_name = "La Parrilla (Flowery Branch)";
      break;
    case 4:
      store_name = "La Parrilla (Powder Springs)";
      break;
    case 5:
      store_name = "La Parrilla (Fayetteville)";
      break;
    case 6:
      store_name = "La Parrilla (Alpharetta)";
      break;
    case 7:
      store_name = "La Parrilla (Woodstock)";
      break;
    case 9:
      store_name = "La Parrilla (Acworth)";
      break;
    case 10:
      store_name = "La Parrilla (Newnan)";
      break;
    case 11:
      store_name = "La Parrilla (Mcdonough)";
      break;
    case 12:
      store_name = "La Parrilla (Howell Mill)";
      break;
    case 13:
      store_name = "La Parrilla (Dothan)";
      break;
    case 14:
      store_name = "La Parrilla (Norcross)";
      break;
    case 15:
      store_name = "La Parrilla (Canton)";
      break;
    case 16:
      store_name = "La Parrilla (Macon)";
      break;
    case 17:
      store_name = "La Parrilla (Greenville)";
      break;
    case 18:
      store_name = "La Parrilla (Toco Hill)";
      break;
    case 19:
      store_name = "La Parrilla (Greenville)";
      break;
    case 20:
      store_name = "La Parrilla (Griffin)";
      break;
    case 21:
      store_name = "La Parrilla (Savannah)";
      break;
    case 23:
      store_name = "La Parrilla (Sandy Springs)";
      break;
    default:
      store_name = "";
  }

  return store_name;
};

export default getStoreName;
