import React, { useEffect, useContext } from "react";
import Sales from "../components/Sales";
import COGS from "../components/COGS";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import getStoreName from "../../../components/StoreNameUsingId";
import laparrillaLogo from "../../../assets/images/la-parrila-logo.png";
import OperatingExpenses from "../components/OperatingExpenses";
import AdministrativeExpenses from "../components/AdministrativeExpenses";
import OperatingIncome from "../components/OperatingIncome";
import { LPSubmitButton } from "../../../styled/styles";
import BonusContext from "../../../contexts/bonus/BonusContext";
import GrossProfit from "../components/GrossProfit";

const CreateBonusPayoutData = () => {
  const {
    storeId,
    year,
    quarter,
    months,
    sales,
    cogs,
    operatingExpenses,
    administrativeExpenses,
    totalExpenses,
    operatingIncome,
    admonFee,
    interest,
    subTotal,
    otherIncome,
    netProfit,
  } = useContext(BonusContext);
  // useEffect(() => {}, []);

  const handleDataSubmit = () => {
    const dataToBeSubmitted = {
      year,
      quarter,
      storeId,
      months,
      sales,
      cogs,
      operatingExpenses,
      administrativeExpenses,
      totalExpenses,
      operatingIncome,
      admonFee,
      interest,
      subTotal,
      otherIncome,
      netProfit,
    };

    console.log(dataToBeSubmitted, "Overall data to be submitted");
  };

  return (
    <div>
      <div className="float-left">
        <div>
          <img src={laparrillaLogo} alt="logo" className="" />
        </div>

        <div>
          <h4
            style={{
              color: "red",
            }}
          >
            {" "}
            BONUS PAYOUT - {year} Quarter {quarter}{" "}
          </h4>
          <p> Location: {getStoreName(storeId)}</p>
        </div>
      </div>

      <div className="row float-right">
        <div className="tab-custom-pills-horizontal col-md-12">
          <div className="">
            {/* <Link to={"/bonus-payout"}>
              <button type="button" className="btn btn-primary btn-rounded">
                Back
              </button>
            </Link> */}
          </div>
        </div>
      </div>

      <div className="clearfix"></div>

      <Sales months={months} />
      <COGS months={months} />
      <GrossProfit months={months} />
      <OperatingExpenses months={months} />
      <AdministrativeExpenses months={months} />
      <OperatingIncome months={months} />

      <div>
        <LPSubmitButton
          className="btn btn-primary"
          style={{ marginBottom: "20px" }}
          onClick={handleDataSubmit}
        >
          Save and Continue
        </LPSubmitButton>
      </div>
    </div>
  );
};

export default CreateBonusPayoutData;
