import React from "react";

const History = () => {
  return (
    <div className="profile-feed">
      <div className="mt-3 mb-3">
        <h4> Activity Log </h4>
      </div>

      <div className="mt-3 mb-3">
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div
              className="card"
              style={{
                border: "none",
              }}
            >
              <div className="card-body">Feature not available for now</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
