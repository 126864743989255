import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import { useHistory, withRouter } from "react-router-dom";
import Swal from "sweetalert2";

const Question14 = ({ name, lang }) => {
  const history = useHistory();

  const [question14, setQuestion14] = useState(null);

  const handleQuestionChange = (e, setFieldValue) => {
    setFieldValue("question14", e.target.dataset.name);
    if (JSON.parse(localStorage.getItem("rateMyBossDetails"))) {
      let existingDetails = JSON.parse(
        localStorage.getItem("rateMyBossDetails")
      );

      Object.assign(existingDetails, {
        question14: e.target.dataset.name,
      });

      localStorage.setItem(
        "rateMyBossDetails",
        JSON.stringify(existingDetails)
      );
    }
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("rateMyBossDetails"))) {
      setQuestion14(
        JSON.parse(localStorage.getItem("rateMyBossDetails")).question14
      );
    }
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Formik
            initialValues={{
              question14: question14 ? question14 : "",
            }}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);

              if (JSON.parse(localStorage.getItem("rateMyBossDetails"))) {
                let surveyDetails = JSON.parse(
                  localStorage.getItem("rateMyBossDetails")
                );

                //TODO: Comeback to this page to save survey details in the DB

                if (Object.keys(surveyDetails).length < 20) {
                  Swal.fire({
                    title: "Warning",
                    text: "You don't seems to have responded to all the questions. Please check again",
                    icon: "warning",
                    confirmButtonText: "Ok",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.close();
                      setSubmitting(false);
                    }
                  });
                } else {
                  history.push("/employees/survey/response-summary");
                }
              }
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <div>
                {lang && lang === "spanish" ? (
                  <form className="rate_my_boss_form" onSubmit={handleSubmit}>
                    <div>
                      <p>
                        {" "}
                        <b>Q14</b>&nbsp;&nbsp;&nbsp; &nbsp; Para garantizar que
                        nuestro equipo trabaje con una eficiencia óptima &nbsp;
                        <b>
                          <i>{name}</i>
                        </b>{" "}
                        &nbsp; ha creado un equipo de personas que se adaptan
                        perfectamente a la complejidad de su función.
                      </p>
                    </div>

                    <div
                      className="row"
                      style={{
                        marginTop: "70px",
                      }}
                    >
                      <div className="col-md-10">
                        <Form.Group className="row">
                          <div className="col-sm-3">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="question14"
                                  id="never"
                                  onChange={(e) =>
                                    handleQuestionChange(e, setFieldValue)
                                  }
                                  data-name={"never"}
                                  value={"never"}
                                  checked={values.question14 === "never"}
                                />{" "}
                                Nunca
                                <i className="input-helper"></i>
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="question14"
                                  id="sometimes"
                                  onChange={(e) =>
                                    handleQuestionChange(e, setFieldValue)
                                  }
                                  data-name={"sometimes"}
                                  value={"sometimes"}
                                  checked={values.question14 === "sometimes"}
                                />{" "}
                                A veces
                                <i className="input-helper"></i>
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="question14"
                                  id="often"
                                  onChange={(e) =>
                                    handleQuestionChange(e, setFieldValue)
                                  }
                                  data-name={"often"}
                                  value={"often"}
                                  checked={values.question14 === "often"}
                                />{" "}
                                A menudo
                                <i className="input-helper"></i>
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="question14"
                                  id="always"
                                  onChange={(e) =>
                                    handleQuestionChange(e, setFieldValue)
                                  }
                                  data-name={"always"}
                                  value={"always"}
                                  checked={values.question14 === "always"}
                                />{" "}
                                Siempre
                                <i className="input-helper"></i>
                              </label>
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      <div className="col-md-12">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btn btn-primary"
                        >
                          {" "}
                          completar y ver resumen{" "}
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <form className="rate_my_boss_form" onSubmit={handleSubmit}>
                    <div>
                      <p>
                        {" "}
                        <b>Q14</b>&nbsp;&nbsp;&nbsp; &nbsp; In order to ensure
                        our team works to optimum efficiency &nbsp;
                        <b>
                          <i>{name}</i>
                        </b>{" "}
                        &nbsp; has built a team of people who are an ideal fit
                        for the complexity of their role.
                      </p>
                    </div>

                    <div
                      className="row"
                      style={{
                        marginTop: "70px",
                      }}
                    >
                      <div className="col-md-10">
                        <Form.Group className="row">
                          <div className="col-sm-3">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="question14"
                                  id="never"
                                  onChange={(e) =>
                                    handleQuestionChange(e, setFieldValue)
                                  }
                                  data-name={"never"}
                                  value={"never"}
                                  checked={values.question14 === "never"}
                                />{" "}
                                Never
                                <i className="input-helper"></i>
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="question14"
                                  id="sometimes"
                                  onChange={(e) =>
                                    handleQuestionChange(e, setFieldValue)
                                  }
                                  data-name={"sometimes"}
                                  value={"sometimes"}
                                  checked={values.question14 === "sometimes"}
                                />{" "}
                                Sometimes
                                <i className="input-helper"></i>
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="question14"
                                  id="often"
                                  onChange={(e) =>
                                    handleQuestionChange(e, setFieldValue)
                                  }
                                  data-name={"often"}
                                  value={"often"}
                                  checked={values.question14 === "often"}
                                />{" "}
                                Often
                                <i className="input-helper"></i>
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="question14"
                                  id="always"
                                  onChange={(e) =>
                                    handleQuestionChange(e, setFieldValue)
                                  }
                                  data-name={"always"}
                                  value={"always"}
                                  checked={values.question14 === "always"}
                                />{" "}
                                Always
                                <i className="input-helper"></i>
                              </label>
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      <div className="col-md-12">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btn btn-primary"
                        >
                          {" "}
                          Complete and View Summary{" "}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Question14);
