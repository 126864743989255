import React, { useEffect, useState } from "react";
import { Formik, FieldArray, Field } from "formik";
import { Form, Spinner } from "react-bootstrap";
import { RoundNumberUp, RoundNumberUp4 } from "../../../components/Utils/RoundNumberUp";
import Swal from "sweetalert2";
import TogoPreview from "../../../components/ClosingReports/togo/TogoPreview";

const PayToGo = () => {
  const [togoTips, setTogoTips] = useState(0);
  const [togoEmployee, setTogoEmployee] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [salesReport, setSalesReport] = useState(null);
  const [tipsCreated, setTipsCreated] = useState(false);
  const [equalPay, setEqualPay] = useState(false);
  const [tipsLeft, setTipsLeft] = useState(0);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("salesReport"))) {
      console.log(JSON.parse(localStorage.getItem("salesReport")));
      setSalesReport(JSON.parse(localStorage.getItem("salesReport")));
    }

    if (JSON.parse(localStorage.getItem("togoCalculatedPayments"))) {
      console.log(JSON.parse(localStorage.getItem("togoCalculatedPayments")), "Calculatedddd");

      setTipsLeft(0);
      setTipsCreated(true);
      setPaymentDetails(JSON.parse(localStorage.getItem("togoCalculatedPayments")));
    }

    if (JSON.parse(localStorage.getItem("togoPayments"))) {
      console.log(JSON.parse(localStorage.getItem("togoPayments")));
      let togoTips = JSON.parse(localStorage.getItem("togoPayments")).togo_tips;
      let totalTogoTips = 0;
      togoTips.map((togo, index) => {
        return (totalTogoTips += togo.tips);
      });
      setTogoTips(totalTogoTips);

      setTogoEmployee(JSON.parse(localStorage.getItem("togoPayments")).togoEmployees);
    }
  }, []);

  const calculateTipAmount = (e, index, togoPaymentDetails) => {
    let tot_tips_cal = 0;
    togoPaymentDetails.map((pay, index) => {
      if (pay.tipshare_received !== undefined && !isNaN(pay.tipshare_received)) {
        tot_tips_cal += parseFloat(pay.tipshare_received);
      }

      return pay;
    });

    let new_cal_tips_left =
      parseFloat(RoundNumberUp(togoTips)) - parseFloat(RoundNumberUp(tot_tips_cal));

    if (!isNaN(new_cal_tips_left)) {
      setTipsLeft(new_cal_tips_left);
    }
  };

  const handleTogoEqualTips = () => {
    let tips = RoundNumberUp(togoTips);
    let totalEmployees = togoEmployee.length;
    let equalAmount = tips / totalEmployees;
    let newEmployeeArr = [];

    togoEmployee.map((emp, index) => {
      return newEmployeeArr.push({
        employee_name: emp.employee_name,
        employee_number: emp.employee_number,
        declared_tips: emp.declared_tips,
        tipshare_received: RoundNumberUp4(equalAmount, 4),
      });
    });

    setTogoEmployee(newEmployeeArr);
    setEqualPay(true);
    console.log([equalAmount, newEmployeeArr], "new emp");
  };

  const cancelTogoEqualTips = () => {
    let newEmployeeArr = [];

    togoEmployee.map((emp, index) => {
      return newEmployeeArr.push({
        employee_name: emp.employee_name,
        employee_number: emp.employee_number,
        declared_tips: emp.declared_tips,
        tipshare_received: 0,
      });
    });

    setTogoEmployee(newEmployeeArr);
    setEqualPay(false);
    setTipsLeft(0);
  };

  return (
    <div className="content">
      <div>
        <section>
          <h3 className="mb-4">
            TOGO
            <small id="emailHelp" className="form-text text-muted">
              Tips calculated for ToGo Employees
            </small>
          </h3>

          <div>
            <div>
              <div className="float-left">
                <div>
                  Total Tips :<b> {togoTips ? RoundNumberUp(togoTips) : ""} </b>
                </div>

                <div>
                  Tips Left : <b> {tipsLeft ? RoundNumberUp(tipsLeft) : ""} </b>
                </div>
              </div>

              {!tipsCreated && togoEmployee && togoEmployee.length > 0 && (
                <div className="float-right">
                  {!equalPay && (
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        handleTogoEqualTips();
                      }}
                    >
                      <span className="mdi mdi-equal"></span> Divide Equally
                    </button>
                  )}

                  {equalPay && (
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        cancelTogoEqualTips();
                      }}
                    >
                      <span className="mdi mdi-cancel"></span> Cancel Equal Amount
                    </button>
                  )}
                </div>
              )}
            </div>
            <div className="clearfix"></div>
            {!tipsCreated && togoEmployee && togoEmployee.length > 0 ? (
              <div>
                <Formik
                  initialValues={{
                    togoPaymentDetails: togoEmployee ? togoEmployee : [],
                  }}
                  enableReinitialize={true}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);

                    let calculated_tip_total = 0;

                    if (values) {
                      values.togoPaymentDetails.map((tip, index) => {
                        return (calculated_tip_total += parseFloat(tip.tipshare_received));
                      });

                      if (
                        parseFloat(RoundNumberUp(togoTips)) ==
                        parseFloat(RoundNumberUp(calculated_tip_total))
                      ) {
                        setSubmitting(false);
                        setPaymentDetails(values);
                        setTipsCreated(true);
                        localStorage.setItem("togoCalculatedPayments", JSON.stringify(values));
                      } else {
                        Swal.fire({
                          title: "Error!",
                          text: `Calculated tips cannot be more or less than the amount to be distributed.`,
                          icon: "error",
                          confirmButtonText: "Ok",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            setSubmitting(false);
                            console.log("Yeah");
                          }
                        });
                      }
                    }

                    console.log(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit} className="togoPaymentDetails">
                      <div className="row">
                        <div className="table-responsive col-md-12" style={{ marginTop: "30px" }}>
                          <FieldArray name="tipSummary">
                            {(fieldArrayProps) => {
                              const { form } = fieldArrayProps;
                              const { values } = form;
                              const { togoPaymentDetails } = values;

                              return (
                                <table className="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th style={{ width: "5%" }}> Emp Number </th>
                                      <th style={{ width: "25%" }}>Employee Name</th>
                                      <th style={{ width: "25%" }}> Declared Tips </th>
                                      <th style={{ width: "25%" }}> Tips </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {togoPaymentDetails &&
                                      togoPaymentDetails.map((tip, index) => (
                                        <tr key={tip.employee_number}>
                                          <td>
                                            <Form.Group>
                                              <Field
                                                name={`togoPaymentDetails[${index}].employee_number`}
                                                className="form-control"
                                                placeholder="Employee Number"
                                                autoComplete="off"
                                                autoCorrect="off"
                                                autoCapitalize="off"
                                                spellCheck="false"
                                                disabled={"disabled"}
                                                style={{
                                                  border: "none",
                                                  fontWeight: "bold",
                                                }}
                                              />
                                            </Form.Group>
                                          </td>
                                          <td>
                                            <Form.Group>
                                              <Field
                                                name={`togoPaymentDetails[${index}].employee_name`}
                                                className="form-control"
                                                placeholder="Employee Name"
                                                autoComplete="off"
                                                autoCorrect="off"
                                                autoCapitalize="off"
                                                spellCheck="false"
                                                disabled={"disabled"}
                                                style={{
                                                  border: "none",
                                                  fontWeight: "bold",
                                                }}
                                              />
                                            </Form.Group>
                                          </td>

                                          <td>
                                            <Form.Group>
                                              <Field
                                                name={`togoPaymentDetails[${index}].declared_tips`}
                                                className="form-control"
                                                placeholder="Declared Tips"
                                                autoComplete="off"
                                                autoCorrect="off"
                                                autoCapitalize="off"
                                                spellCheck="false"
                                                disabled={"disabled"}
                                                style={{
                                                  border: "none",
                                                  fontWeight: "bold",
                                                }}
                                              />
                                            </Form.Group>
                                          </td>

                                          <td>
                                            <Form.Group>
                                              <div className="input-group">
                                                <div className="input-group-prepend">
                                                  <span className="input-group-text">$</span>
                                                </div>

                                                <Field
                                                  name={`togoPaymentDetails[${index}].tipshare_received`}
                                                  className="form-control"
                                                  type={"text"}
                                                  placeholder="Tips"
                                                  autoComplete="off"
                                                  autoCorrect="off"
                                                  autoCapitalize="off"
                                                  spellCheck="false"
                                                  disabled={isSubmitting || equalPay}
                                                  defaultValue={
                                                    tip.tipshare_received
                                                      ? parseFloat(
                                                          RoundNumberUp(tip.tipshare_received)
                                                        )
                                                      : ""
                                                  }
                                                  onBlur={(e) => {
                                                    calculateTipAmount(
                                                      e,
                                                      index,
                                                      togoPaymentDetails
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </Form.Group>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              );
                            }}
                          </FieldArray>
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        <div className="col-md-12">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-primary mr-2"
                          >
                            Submit &nbsp;&nbsp;
                            {isSubmitting ? <Spinner animation="border" size="sm" /> : ""}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            ) : (
              ""
            )}

            {tipsCreated && (
              <div>
                <div className="row">
                  <TogoPreview paymentDetails={paymentDetails} />
                </div>

                <div className="row" style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <div className="col-md-12">
                    <button
                      type="submit"
                      className="btn btn-inverse-dark mr-2"
                      onClick={() => {
                        localStorage.removeItem("togoCalculatedPayments");
                        setTipsCreated(false);
                      }}
                    >
                      Cancel &nbsp;&nbsp;
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default PayToGo;
