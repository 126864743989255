const JobCodeName = [
  {
    id: 9,
    name: "Hostess",
  },
  {
    id: 1,
    name: "Manager",
  },
  {
    id: 2,
    name: "Server",
  },
  {
    id: 3,
    name: "Bartender",
  },
  {
    id: 4,
    name: "Kitchen",
  },
  {
    id: 5,
    name: "Training",
  },
  {
    id: 6,
    name: "Cleaning",
  },
  {
    id: 7,
    name: "Dish Washer",
  },
  {
    id: 8,
    name: "Busser",
  },
  {
    id: 10,
    name: "Prekitchen",
  },
  {
    id: 11,
    name: "Expo",
  },
  {
    id: 17,
    name: "svc-assist",
  },
  {
    id: 15,
    name: "Coach Server",
  },
  {
    id: 23,
    name: "DoorDash",
  },
  {
    id: 12,
    name: "Togo",
  },
];

export default JobCodeName;
