import { Formik } from "formik";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { Form, Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { AccountingReportsUpload } from "../../components/AccountingReportsUpload";
import { SalesService } from "../../service/SalesService";
import { UserService } from "../../service/userService";

const AccountingReport = () => {

    const [reportReady, setReportReady] = useState(false)
    const [mdShow, setMdShow] = useState(false);
    const [fileUrl, setFileUrl] = useState(null);

    const history = useHistory();


    return (
        <section className="content">
            <div>
                {!reportReady && (
                    <Formik
                        initialValues={{
                            accounting_report_attachment: []
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true);

                            const data = new FormData();

                            if (
                                values.accounting_report_attachment &&
                                values.accounting_report_attachment.length > 0
                            ) {
                                values.accounting_report_attachment.map((file) => {
                                    return data.append(
                                        "accounting_report_attachment[]",
                                        file,
                                        file.name
                                    );
                                });

                                SalesService.accountReportUpload(data)
                                    .then((response) => {
                                        setSubmitting(false);
                                        console.log(response.data.data);
                                        setMdShow(true);
                                        setFileUrl(response.data.data.url);
                                        // setBulkReport(response.data.data.reportInfo);
                                        // setReportReady(true);

                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        setSubmitting(false);
                                        Swal.fire({
                                            title: "Error",
                                            text: error,
                                            icon: "error",
                                            confirmButtonColor: "#000000",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: "Ok",
                                            allowOutsideClick: false,
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                console.log("Payment works");
                                            }
                                        });
                                        // cogoToast.error(error.response.data.message, toastoptions);
                                    });
                            } else {
                                setSubmitting(false);
                                Swal.fire({
                                    title: "Check all inputs",
                                    icon: "error",
                                    html: "Check inputs and ensure to attach the required file</b>",
                                    // title: "Error",
                                    text: "",
                                    // icon: "error",
                                    confirmButtonColor: "#000000",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Ok",
                                    allowOutsideClick: false,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        console.log("Labor info works");
                                    }
                                });
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting,
                        }) => (
                            <form
                                className="closing_report_form mt-4"
                                id="closing_report_form"
                                onSubmit={handleSubmit}
                            >
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3> Accounting Report </h3>

                                        <Form.Group>
                                            <p> Upload Report </p>
                                            <AccountingReportsUpload
                                                setFieldValue={setFieldValue}
                                                name={"accounting_report_attachment"}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>

                                <div>
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="btn btn-primary mr-2"
                                    >
                                        Submit &nbsp;&nbsp;
                                        {isSubmitting ? (
                                            <Spinner animation="border" size="sm" />
                                        ) : (
                                            ""
                                        )}
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                )}
            </div>

            <div className="row">
                <Modal
                    show={mdShow}
                    onHide={() => setMdShow(false)}
                    aria-labelledby="example-modal-sizes-title-md"
                >
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {fileUrl && (
                            <div className="row">
                                <div className="col-md-12">
                                    <a download href={fileUrl}>
                                        <span> Click here to download the Excel File </span>
                                    </a>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                </Modal>
            </div>
        </section>
    )
}

export default AccountingReport