import React, { useState, useEffect } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import cogoToast from "cogo-toast";
import { AuthService } from "../../service/authService";
import InputField from "../../components/common/InputField";
import { Utility } from "../../Helpers/utils";

const FirstTimeLogin = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const history = useHistory();

  const toastOptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  // Populate the email from props
  useEffect(() => {
    const emailFromState = props.history.location.state?.email;
    if (emailFromState) setEmail(emailFromState);
  }, [props.history.location.state]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (!password || !passwordConfirmation) {
      cogoToast.error("Please enter all the credentials", toastOptions);
      setSubmitted(false);
      return;
    }

    if (password !== passwordConfirmation) {
      cogoToast.error("Passwords do not match", toastOptions);
      setSubmitted(false);
      return;
    }

    try {
      const response = await AuthService.firstLogin(email, password);
      console.log(response, "RESPONSE");
      if (response.data.success) {
        // const userData = response.data.data;
        // Utility.setUser(userData);
        cogoToast.success("Password changed successfully", toastOptions);
        history.push("/home/login");
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred. Please try again.";
      cogoToast.error(errorMessage, toastOptions);
      setSubmitted(false);
    }
  };

  return (
    <div className="d-flex align-items-center auth px-0">
      <div className="row w-100 mx-0">
        <div className="col-lg-4 mx-auto">
          <div className="auth-form-light text-left py-5 px-4 px-sm-5">
            <div className="text-center">
              <div className="brand-logo">
                <Link to={"/home/login"}>
                  <img src={require("../../assets/images/la-parrila-logo.png")} alt="logo" />
                </Link>
              </div>
              <h4>Please set a new password</h4>
            </div>
            <form className="pt-3" name="form" onSubmit={handleSubmit}>
              <InputField
                id="password"
                name="password"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={!password && submitted}
                touched={submitted}
              />
              <InputField
                id="password_confirmation"
                name="password_confirmation"
                type="password"
                placeholder="Confirm Password"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                error={!passwordConfirmation && submitted}
                touched={submitted}
              />
              <div className="mt-3">
                <button
                  type="submit"
                  className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                  disabled={submitted}
                >
                  RESET PASSWORD &nbsp;&nbsp;
                  {submitted && <Spinner animation="border" size="sm" />}
                </button>
              </div>
              <div className="my-2 d-flex justify-content-between align-items-center">
                <div className="text-center"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(FirstTimeLogin);
