import Api from "./Api";

export class MaintenanceReportService {
  static async submitMaintenanceReport(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`maintenance/maintenance-report-request`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getAllTasksForAWorkroom(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`maintenance/all-tasks-store/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async deleteTasksFromWorkroom(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`maintenance/delete-task-store/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async archiveTasksFromWorkroom(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`maintenance/archive-task-store/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async markTaskAsComplete(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`maintenance/mark-task-complete/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getCommentsForATask(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`maintenance/get-task-comment/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async createCommentForATask(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`maintenance/create-task-comment`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getAllOpenedReport(data) {
    return new Promise((resolve, reject) => {
      return Api.get(
        `maintenance/opened-reports/${data.duration}/${data.status}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getAllClosedReport() {
    return new Promise((resolve, reject) => {
      return Api.get(`maintenance/closed-reports`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getAllReport() {
    return new Promise((resolve, reject) => {
      return Api.get(`maintenance/all-reports`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
