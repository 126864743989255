import Api from "./Api";

export class FeedbackService {
  static async createNewFeedback(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`feedback/create`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getFeedbacksList() {
    return new Promise((resolve, reject) => {
      return Api.get(`feedback/get-feedback-list`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
