import React, { useState, useEffect } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import { SystemConfigService } from "../../../service/configService";
import { Formik } from "formik";
import cogoToast from "cogo-toast";

const ViewBonusConfig = () => {
  const [editMode, setEditMode] = useState(false);
  const [config, setConfig] = useState(null);
  const [storeOptions, setStoreOptions] = useState(null);

  const history = useHistory();
  let { id } = useParams();

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  useEffect(() => {
    SystemConfigService.configBonusForStore(id)
      .then((response) => {
        console.log(response.data);
        setConfig(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });

    SystemConfigService.getStores()
      .then((response) => {
        setStoreOptions(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);

  return (
    <div>
      <div className="row">
        <div className="tab-custom-pills-horizontal col-md-12">
          <div className="">
            <Link to={"/system-configuration/bonus/default-page"}>
              <button type="button" className="btn btn-primary btn-rounded">
                Back
              </button>
            </Link>

            <button
              type="button"
              onClick={() => {
                setEditMode(true);
              }}
              className="btn btn-primary btn-rounded"
            >
              Edit
            </button>
          </div>
        </div>
      </div>

      {config ? (
        <Formik
          initialValues={{
            id: config.id,
            store_id: config.store_id,
            sales: config.sales,
            food_cost: config.food_cost,
            liquor_cost: config.liquor_cost,
            supplies_cost: config.supplies_cost,
            payroll_boh: config.payroll_boh,
            payroll_foh: config.payroll_foh,
            bonuses_cost: config.bonuses_cost,
            utilities_cost: config.utilities_cost,
            admon_fee: config.admon_fee,
            net_profit: config.net_profit,
            review_score: config.review_score,
            evaluation_result: config.evaluation_result,
            evaluation_percentage: config.evaluation_percentage,
            general_manager: config.general_manager,
            kitchen_manager: config.kitchen_manager,
            foh_manager: config.foh_manager,
            beverage_manager: config.beverage_manager,
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            SystemConfigService.createBonusConfig(values)
              .then((response) => {
                console.log(response);
                setSubmitting(false);
                cogoToast.success("Updated successfully", toastoptions);
                setEditMode(false);
                history.push("/system-configuration/bonus/default-page");
              })
              .catch((error) => {
                console.log(error.response);
                cogoToast.error(error.response.data.message, toastoptions);
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <div className="mt-3 mb-3">
              <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card" style={{ border: "none" }}>
                    <div className="card-body">
                      <form className="pageForms" onSubmit={handleSubmit}>
                        <div className="row border-bottom mb-5">
                          <div className="col-md-6">
                            <Form.Group>
                              <label htmlFor="store_id">Store</label>
                              <Form.Control
                                type="text"
                                as="select"
                                className="form-control"
                                id="store_id"
                                name="store_id"
                                value={
                                  values.store_id ? values.store_id : "DEFAULT"
                                }
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              >
                                <option value="DEFAULT" disabled>
                                  Select One
                                </option>

                                {storeOptions &&
                                  storeOptions.map((s, index) => (
                                    <option value={s.location_id} key={index}>
                                      {s.name.toUpperCase()}
                                    </option>
                                  ))}
                              </Form.Control>
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="sales">Sales % </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="sales"
                                name="sales"
                                value={values.sales}
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="food_cost"> Food Cost % </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="food_cost"
                                name="food_cost"
                                value={values.food_cost}
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="liquor_cost">
                                {" "}
                                Liquor Cost %{" "}
                              </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="liquor_cost"
                                name="liquor_cost"
                                value={values.liquor_cost}
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="supplies_cost">
                                {" "}
                                Supplies Cost %{" "}
                              </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="supplies_cost"
                                name="supplies_cost"
                                value={values.supplies_cost}
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="payroll_boh">
                                Payroll BOH %{" "}
                              </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="payroll_boh"
                                name="payroll_boh"
                                value={values.payroll_boh}
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="payroll_foh">
                                Payroll FOH %{" "}
                              </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="payroll_foh"
                                name="payroll_foh"
                                value={values.payroll_foh}
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="bonuses_cost">
                                {" "}
                                Bonuses Cost %{" "}
                              </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="bonuses_cost"
                                name="bonuses_cost"
                                value={values.bonuses_cost}
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="utilities_cost">
                                Utilities Cost %{" "}
                              </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="utilities_cost"
                                name="utilities_cost"
                                value={values.utilities_cost}
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="admon_fee">Admon Fee % </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="admon_fee"
                                name="admon_fee"
                                value={values.admon_fee}
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="net_profit">Net Profit % </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="net_profit"
                                placeholder="Percentage Net Profit"
                                name="net_profit"
                                value={values.net_profit}
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>
                          </div>

                          <div className="col-md-6">
                            <Form.Group>
                              <label htmlFor="general_manager">
                                General Manager %{" "}
                              </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="general_manager"
                                name="general_manager"
                                value={values.general_manager}
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="kitchen_manager">
                                {" "}
                                Kitchen Manager %{" "}
                              </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="kitchen_manager"
                                name="kitchen_manager"
                                value={values.kitchen_manager}
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="foh_manager">
                                FOH Manager %{" "}
                              </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="foh_manager"
                                name="foh_manager"
                                value={values.foh_manager}
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="beverage_manager">
                                Beverage Manager %{" "}
                              </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="beverage_manager"
                                name="beverage_manager"
                                value={values.beverage_manager}
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="review_score">
                                Review Score{" "}
                              </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="review_score"
                                placeholder=" Review Score"
                                name="review_score"
                                value={values.review_score}
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="evaluation_result">
                                Evaluation Result
                              </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="evaluation_result"
                                placeholder="Evaluation Result"
                                name="evaluation_result"
                                value={values.evaluation_result}
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="evaluation_percentage">
                                Evaluation Percentage %
                              </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="review_score"
                                placeholder="Evaluation Percentage"
                                name="evaluation_percentage"
                                value={values.evaluation_percentage}
                                onChange={handleChange}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>
                          </div>
                        </div>
                        {editMode && (
                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary btn-icon-text"
                            >
                              <i className="mdi mdi-file-check btn-icon-prepend"></i>{" "}
                              Update&nbsp;&nbsp;
                              {isSubmitting ? (
                                <Spinner animation="border" size="sm" />
                              ) : (
                                ""
                              )}
                            </button>

                            <button
                              className="btn btn-light"
                              onClick={() => {
                                setEditMode(false);
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <div>
          <p> Record Not Found </p>
        </div>
      )}
    </div>
  );
};

export default withRouter(ViewBonusConfig);
