import React from "react";
import { Form } from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";

const RateMyBoss = () => {
  // const [lang, setLang] = useState(null);

  const history = useHistory();

  const handleRedirect = (e) => {
    e.preventDefault();
    let lang = e.target.value;
    localStorage.setItem("surveyLang", JSON.stringify(lang));
    console.log(lang);
    if (lang === "spanish") {
      history.push("/employees/rate-your-leaders/begin/spanish");
    } else {
      history.push("/employees/rate-your-leaders/begin/english");
    }
  };

  return (
    <div className="row">
      <div className="col-md-6 mx-auto" style={{ marginTop: "50px"}}>
        <div className="card">
          <div className="card-body">
            <form className="rate_my_boss_form">
              <div className="row">
                <div className="col-md-6">
                  <Form.Group>
                    <label htmlFor="store">
                      <b>Select Language / Seleccione el idioma</b>
                    </label>
                    <Form.Control
                      type="text"
                      as="select"
                      className="form-control"
                      id="store"
                      name="store"
                      value={"DEFAULT"}
                      onChange={handleRedirect}
                    >
                      <option value="DEFAULT" disabled>
                        Select One
                      </option>
                      <option value={"spanish"}> Spanish </option>
                      <option value={"english"}> English </option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(RateMyBoss);
