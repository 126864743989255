import React, { useEffect, useState } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import { SystemConfigService } from "../../../service/configService";
import cogoToast from "cogo-toast";
import { Formik } from "formik";
import { UserService } from "../../../service/userService";
import Select from "react-select";

const District = () => {
  const history = useHistory();

  const [districtManagers, setDistrictManagers] = useState(null);
  const [rManagersOptions, setRManagersOptions] = useState(null);
  const [storeOptions, setStoreOptions] = useState(null);
  const [defaultStores, setDefaultStores] = useState(null);

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  useEffect(() => {
    UserService.getDistrictManagers()
      .then((response) => {
        setDistrictManagers(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    let rManagerOptionsArray = [];
    UserService.getRestaurantManagers()
      .then((response) => {
        let val = response.data.data;
        val.map((i, index) => {
          let name = `${i.first_name} ${i.last_name}`;
          return rManagerOptionsArray.push({
            value: i.slug,
            label: name.replace(/\w\S*/g, (w) =>
              w.replace(/^\w/, (c) => c.toUpperCase())
            ),
          });
        });
        setRManagersOptions(rManagerOptionsArray);
      })
      .catch((error) => {
        console.log(error);
      });

    let storeOptionsArray = [];
    SystemConfigService.getStores()
      .then((response) => {
        let val = response.data.data;
        val.map((i, index) => {
          return storeOptionsArray.push({
            value: i.location_id,
            label: i.name.replace(/\w\S*/g, (w) =>
              w.replace(/^\w/, (c) => c.toUpperCase())
            ),
          });
        });
        setStoreOptions(storeOptionsArray);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> District </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/system-configuration/all"}>Portal configuration</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Store
            </li>
          </ol>
        </nav>
      </div>
      <Formik
        initialValues={{
          name: "",
          manager: "",
          restaurant_managers: [],
          store_selected: [],
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          if (
            values.name &&
            values.manager &&
            values.restaurant_managers.length > 0 &&
            values.store_selected.length > 0
          ) {
            SystemConfigService.createDistrict(values)
              .then((response) => {
                console.log(response.data);
                if (response.data.success) {
                  setSubmitting(false);
                  cogoToast.success(
                    "District created successfully",
                    toastoptions
                  );
                  history.push("/system-configuration/view-districts");
                }
              })
              .catch((error) => {
                if (error.response.data.errors) {
                  cogoToast.error(
                    error.response.data.errors.name[0],
                    toastoptions
                  );
                }
                // cogoToast.error(error.response.data.message, toastoptions);
                setSubmitting(false);
              });
          } else {
            cogoToast.error("Please fill all the fields", toastoptions);
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <div className="row">
            <div className="col-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">
                    Fill the details below to create a District
                  </h4>
                  <form
                    className="forms-sample"
                    name="form"
                    onSubmit={handleSubmit}
                  >
                    <Form.Group>
                      <label htmlFor="name">Name</label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        disabled={isSubmitting}
                        placeholder="Corporation Name"
                      />
                    </Form.Group>

                    <Form.Group>
                      <label htmlFor="state">District Manager</label>
                      <Form.Control
                        type="text"
                        as="select"
                        className="form-control"
                        id="manager"
                        name="manager"
                        value={values.manager ? values.manager : "DEFAULT"}
                        onChange={handleChange}
                        disabled={isSubmitting}
                      >
                        <option value="DEFAULT" disabled>
                          Select One
                        </option>

                        {districtManagers &&
                          districtManagers.map((man, index) => (
                            <option value={man.slug} key={index}>
                              {" "}
                              {man.first_name} {man.last_name}{" "}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <label htmlFor="restaurant_managers">
                        Restaurant Managers
                      </label>

                      <Select
                        isMulti={true}
                        onChange={(opt, e) => {
                          console.log(opt);
                          setFieldValue("restaurant_managers", opt);
                        }}
                        name="store_selected"
                        options={rManagersOptions ? rManagersOptions : null}
                        disabled={isSubmitting}
                      />
                    </Form.Group>

                    <Form.Group>
                      <label htmlFor="store_selected"> Select Stores </label>

                      <Select
                        isMulti={true}
                        onChange={(opt, e) => {
                          console.log(opt);
                          setFieldValue("store_selected", opt);
                        }}
                        name="store_selected"
                        options={storeOptions}
                        defaultValue={defaultStores}
                      />
                    </Form.Group>

                    <button type="submit" className="btn btn-primary mr-2">
                      Submit &nbsp;&nbsp;
                      {isSubmitting ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        ""
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default withRouter(District);
