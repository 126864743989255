import Api from "./Api";

export class SalesService {
  static async getSalesReportExtractForStore(data) {
    return new Promise((resolve, reject) => {
      return Api.get(`accounting/extract-sales-report/${data.store_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async downloadSalesExtract(data) {
    return new Promise((resolve, reject) => {
      return Api.get(`accounting/download-extract/${data.store_id}/${data.id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async updateSalesExtract(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`accounting/update-extract`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async accountReportUpload(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`accounting/generate-report`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async fetchSalesReportHistory(id, date) {
    return new Promise((resolve, reject) => {
      return Api.get(`payment/fetch-sales-report-history/${id}/${date}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getCompletedSalesReportForStore(id, date) {
    return new Promise((resolve, reject) => {
      return Api.get(`payment/completed-report/${id}/${date}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getSalesReportStatus(id, date) {
    return new Promise((resolve, reject) => {
      return Api.get(`closing/report-status`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async createSalesReportComment(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`accounting/create-comment`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getSalesReportComment(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`accounting/get-comment`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getPercentageSalesForStore(data) {
    return new Promise((resolve, reject) => {
      return Api.get(`accounting/retrieve-percentage-report/${data.store_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getPercentageSalesDetails(data) {
    return new Promise((resolve, reject) => {
      return Api.get(
        `accounting/get-percentage-sales-details/${data.store_id}/${data.id}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
