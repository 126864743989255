import React from "react";
import { Form, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import { withRouter, Link, useHistory } from "react-router-dom";
import cogoToast from "cogo-toast";
import { FeedbackService } from "../../service/FeedbackService";
import { Utility } from "../../Helpers/utils";

const CreateFeedback = () => {
  let history = useHistory();
  const user = Utility.getUser();

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  return (
    <div>
      <div>
        <p className="pull-right" style={{ textDecoration: "underline" }}>
          <a
            href="https://veltron.document360.io/v1/en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn How &nbsp;<span className="fa fa-question"></span>
          </a>
        </p>

        <div className="clearfix"></div>
      </div>

      <div className="mb-5">
        {user && (user.role === "super admin" || user.role === "hr") ? (
          <Link to={"/feedback-view"}>
            <button className="btn btn-primary"> View Feedbacks </button>
          </Link>
        ) : (
          ""
        )}
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="page-header">
            <h3 className="page-title"> Send Feedback </h3>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb"></ol>
            </nav>
          </div>
          <Formik
            initialValues={{
              experience: "",
              feedback: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);

              FeedbackService.createNewFeedback(values)
                .then((response) => {
                  setSubmitting(false);
                  console.log(response.data.data);
                  cogoToast.success(
                    "Thanks for the feedback. Submitted successfully",
                    toastoptions
                  );
                  values.experience = "";
                  values.feedback = "";
                  //   window.location.reload();
                  history.push("/dashboard");
                })
                .catch((error) => {
                  console.log(error);
                  setSubmitting(false);
                });
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <div className="row">
                <div className="col-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <h3
                        className="card-title"
                        style={{ marginBottom: "20px", textTransform: "none" }}
                      >
                        Tell us what you love about the portal, or what we could
                        do better.
                      </h3>
                      <form
                        className="forms-sample"
                        name="form"
                        onSubmit={handleSubmit}
                      >
                        <Form.Group>
                          <label htmlFor="experience">
                            How was your experience using the portal
                          </label>
                          <Form.Control
                            type="text"
                            as="select"
                            className="form-control"
                            id="experience"
                            name="experience"
                            value={
                              values.experience ? values.experience : "DEFAULT"
                            }
                            onChange={handleChange}
                            disabled={isSubmitting}
                          >
                            <option value="DEFAULT" disabled>
                              Select One
                            </option>
                            <option value="great"> Great </option>
                            <option value="average"> Average </option>
                            <option value="disappointing">
                              {" "}
                              Disappointing{" "}
                            </option>
                          </Form.Control>
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="feedback">
                            Please share more detailed feedback{" "}
                          </label>
                          <Form.Control
                            type="text"
                            as="textarea"
                            rows="6"
                            cols="50"
                            className="form-control"
                            id="feedback"
                            name="feedback"
                            value={values.feedback}
                            onChange={handleChange}
                            disabled={isSubmitting}
                          ></Form.Control>
                        </Form.Group>

                        <button type="submit" className="btn btn-primary mr-2">
                          Submit &nbsp;&nbsp;
                          {isSubmitting ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            ""
                          )}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CreateFeedback);
