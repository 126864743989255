import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Formik } from "formik";

const Question1 = ({ name, lang }) => {
  const [question1, setQuestion1] = useState(null);

  const handleQuestionChange = (e, setFieldValue) => {
    setFieldValue("question1", e.target.dataset.name);
    if (JSON.parse(localStorage.getItem("rateMyBossDetails"))) {
      let existingDetails = JSON.parse(
        localStorage.getItem("rateMyBossDetails")
      );

      Object.assign(existingDetails, {
        question1: e.target.dataset.name,
      });

      localStorage.setItem(
        "rateMyBossDetails",
        JSON.stringify(existingDetails)
      );
    }
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("rateMyBossDetails"))) {
      console.log(
        JSON.parse(localStorage.getItem("rateMyBossDetails")).question1
      );
      setQuestion1(
        JSON.parse(localStorage.getItem("rateMyBossDetails")).question1
      );
    }
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div>
            <Formik
              initialValues={{
                question1: question1 ? question1 : "",
              }}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <div>
                  {lang && lang === "spanish" ? (
                    <form className="rate_my_boss_form" onSubmit={handleSubmit}>
                      <div>
                        <p>
                          {" "}
                          <b>Q1</b>&nbsp;&nbsp;&nbsp;{" "}
                          <b>
                            <i>{name}</i>
                          </b>{" "}
                          &nbsp; trabaja conmigo para construir y mantener una
                          relación de confianza y justa que sea productiva
                        </p>
                      </div>

                      <div
                        className="row"
                        style={{
                          marginTop: "70px",
                        }}
                      >
                        <div className="col-md-10">
                          <Form.Group className="row">
                            <div className="col-sm-3">
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name="question1"
                                    id="never"
                                    onChange={(e) =>
                                      handleQuestionChange(e, setFieldValue)
                                    }
                                    data-name={"never"}
                                    value={"never"}
                                    checked={values.question1 === "never"}
                                  />{" "}
                                  Nunca
                                  <i className="input-helper"></i>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name="question1"
                                    id="sometimes"
                                    onChange={(e) =>
                                      handleQuestionChange(e, setFieldValue)
                                    }
                                    data-name={"sometimes"}
                                    value={"sometimes"}
                                    checked={values.question1 === "sometimes"}
                                  />{" "}
                                  A veces
                                  <i className="input-helper"></i>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name="question1"
                                    id="often"
                                    onChange={(e) =>
                                      handleQuestionChange(e, setFieldValue)
                                    }
                                    data-name={"often"}
                                    value={"often"}
                                    checked={values.question1 === "often"}
                                  />{" "}
                                  A menudo
                                  <i className="input-helper"></i>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name="question1"
                                    id="always"
                                    onChange={(e) =>
                                      handleQuestionChange(e, setFieldValue)
                                    }
                                    data-name={"always"}
                                    value={"always"}
                                    checked={values.question1 === "always"}
                                  />{" "}
                                  Siempre
                                  <i className="input-helper"></i>
                                </label>
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <form className="rate_my_boss_form" onSubmit={handleSubmit}>
                      <div>
                        <p>
                          {" "}
                          <b>Q1</b>&nbsp;&nbsp;&nbsp;{" "}
                          <b>
                            <i>{name}</i>
                          </b>{" "}
                          &nbsp; works with me to build and maintain a trusting
                          and fair relationship that is productive
                        </p>
                      </div>

                      <div
                        className="row"
                        style={{
                          marginTop: "70px",
                        }}
                      >
                        <div className="col-md-10">
                          <Form.Group className="row">
                            <div className="col-sm-3">
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name="question1"
                                    id="never"
                                    onChange={(e) =>
                                      handleQuestionChange(e, setFieldValue)
                                    }
                                    data-name={"never"}
                                    value={"never"}
                                    checked={values.question1 === "never"}
                                  />{" "}
                                  Never
                                  <i className="input-helper"></i>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name="question1"
                                    id="sometimes"
                                    onChange={(e) =>
                                      handleQuestionChange(e, setFieldValue)
                                    }
                                    data-name={"sometimes"}
                                    value={"sometimes"}
                                    checked={values.question1 === "sometimes"}
                                  />{" "}
                                  Sometimes
                                  <i className="input-helper"></i>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name="question1"
                                    id="often"
                                    onChange={(e) =>
                                      handleQuestionChange(e, setFieldValue)
                                    }
                                    data-name={"often"}
                                    value={"often"}
                                    checked={values.question1 === "often"}
                                  />{" "}
                                  Often
                                  <i className="input-helper"></i>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name="question1"
                                    id="always"
                                    onChange={(e) =>
                                      handleQuestionChange(e, setFieldValue)
                                    }
                                    data-name={"always"}
                                    value={"always"}
                                    checked={values.question1 === "always"}
                                  />{" "}
                                  Always
                                  <i className="input-helper"></i>
                                </label>
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question1;
