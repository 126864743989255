import React, { useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { LPSubmitButton } from "../../../styled/styles";
import BonusContext from "../../../contexts/bonus/BonusContext";

const OperatingExpenses = ({ months }) => {
  const { dispatch, operatingExpenses } = useContext(BonusContext);

  const initialValues = {
    payrollBohM1: operatingExpenses ? operatingExpenses.payrollBohM1 : "",
    payrollBohM1P: operatingExpenses ? operatingExpenses.payrollBohM1P : "",
    payrollBohM2: operatingExpenses ? operatingExpenses.payrollBohM2 : "",
    payrollBohM2P: operatingExpenses ? operatingExpenses.payrollBohM2P : "",
    payrollBohM3: operatingExpenses ? operatingExpenses.payrollBohM3 : "",
    payrollBohM3P: operatingExpenses ? operatingExpenses.payrollBohM3P : "",
    payrollBohTotal: operatingExpenses ? operatingExpenses.payrollBohTotal : "",
    payrollBohTotalP: operatingExpenses ? operatingExpenses.payrollBohTotalP : "",

    payrollFohM1: operatingExpenses ? operatingExpenses.payrollFohM1 : "",
    payrollFohM1P: operatingExpenses ? operatingExpenses.payrollFohM1P : "",
    payrollFohM2: operatingExpenses ? operatingExpenses.payrollFohM2 : "",
    payrollFohM2P: operatingExpenses ? operatingExpenses.payrollFohM2P : "",
    payrollFohM3: operatingExpenses ? operatingExpenses.payrollFohM3 : "",
    payrollFohM3P: operatingExpenses ? operatingExpenses.payrollFohM3P : "",
    payrollFohTotal: operatingExpenses ? operatingExpenses.payrollFohTotal : "",
    payrollFohTotalP: operatingExpenses ? operatingExpenses.payrollFohTotalP : "",

    cleaningM1: operatingExpenses ? operatingExpenses.cleaningM1 : "",
    cleaningM1P: operatingExpenses ? operatingExpenses.cleaningM1P : "",
    cleaningM2: operatingExpenses ? operatingExpenses.cleaningM2 : "",
    cleaningM2P: operatingExpenses ? operatingExpenses.cleaningM2P : "",
    cleaningM3: operatingExpenses ? operatingExpenses.cleaningM3 : "",
    cleaningM3P: operatingExpenses ? operatingExpenses.cleaningM3P : "",
    cleaningTotal: operatingExpenses ? operatingExpenses.cleaningTotal : "",
    cleaningTotalP: operatingExpenses ? operatingExpenses.cleaningTotalP : "",

    shortageM1: operatingExpenses ? operatingExpenses.shortageM1 : "",
    shortageM1P: operatingExpenses ? operatingExpenses.shortageM1P : "",
    shortageM2: operatingExpenses ? operatingExpenses.shortageM2 : "",
    shortageM2P: operatingExpenses ? operatingExpenses.shortageM2P : "",
    shortageM3: operatingExpenses ? operatingExpenses.shortageM3 : "",
    shortageM3P: operatingExpenses ? operatingExpenses.shortageM3P : "",
    shortageTotal: operatingExpenses ? operatingExpenses.shortageTotal : "",
    shortageTotalP: operatingExpenses ? operatingExpenses.shortageTotalP : "",

    rentM1: operatingExpenses ? operatingExpenses.rentM1 : "",
    rentM1P: operatingExpenses ? operatingExpenses.rentM1P : "",
    rentM2: operatingExpenses ? operatingExpenses.rentM2 : "",
    rentM2P: operatingExpenses ? operatingExpenses.rentM2P : "",
    rentM3: operatingExpenses ? operatingExpenses.rentM3 : "",
    rentM3P: operatingExpenses ? operatingExpenses.rentM3P : "",
    rentTotal: operatingExpenses ? operatingExpenses.rentTotal : "",
    rentTotalP: operatingExpenses ? operatingExpenses.rentTotalP : "",

    repairM1: operatingExpenses ? operatingExpenses.repairM1 : "",
    repairM1P: operatingExpenses ? operatingExpenses.repairM1P : "",
    repairM2: operatingExpenses ? operatingExpenses.repairM2 : "",
    repairM2P: operatingExpenses ? operatingExpenses.repairM2P : "",
    repairM3: operatingExpenses ? operatingExpenses.repairM3 : "",
    repairM3P: operatingExpenses ? operatingExpenses.repairM3P : "",
    repairTotal: operatingExpenses ? operatingExpenses.repairTotal : "",
    repairTotalP: operatingExpenses ? operatingExpenses.repairTotalP : "",

    uniformsM1: operatingExpenses ? operatingExpenses.uniformsM1 : "",
    uniformsM1P: operatingExpenses ? operatingExpenses.uniformsM1P : "",
    uniformsM2: operatingExpenses ? operatingExpenses.uniformsM2 : "",
    uniformsM2P: operatingExpenses ? operatingExpenses.uniformsM2P : "",
    uniformsM3: operatingExpenses ? operatingExpenses.uniformsM3 : "",
    uniformsM3P: operatingExpenses ? operatingExpenses.uniformsM3P : "",
    uniformsTotal: operatingExpenses ? operatingExpenses.uniformsTotal : "",
    uniformsTotalP: operatingExpenses ? operatingExpenses.uniformsTotalP : "",

    utilitiesM1: operatingExpenses ? operatingExpenses.utilitiesM1 : "",
    utilitiesM1P: operatingExpenses ? operatingExpenses.utilitiesM1P : "",
    utilitiesM2: operatingExpenses ? operatingExpenses.utilitiesM2 : "",
    utilitiesM2P: operatingExpenses ? operatingExpenses.utilitiesM2P : "",
    utilitiesM3: operatingExpenses ? operatingExpenses.utilitiesM3 : "",
    utilitiesM3P: operatingExpenses ? operatingExpenses.utilitiesM3P : "",
    utilitiesTotal: operatingExpenses ? operatingExpenses.utilitiesTotal : "",
    utilitiesTotalP: operatingExpenses ? operatingExpenses.utilitiesTotalP : "",

    totalOperatingM1: operatingExpenses ? operatingExpenses.totalOperatingM1 : "",
    totalOperatingM1P: operatingExpenses ? operatingExpenses.totalOperatingM1P : "",
    totalOperatingM2: operatingExpenses ? operatingExpenses.totalOperatingM2 : "",
    totalOperatingM2P: operatingExpenses ? operatingExpenses.totalOperatingM2P : "",
    totalOperatingM3: operatingExpenses ? operatingExpenses.totalOperatingM3 : "",
    totalOperatingM3P: operatingExpenses ? operatingExpenses.totalOperatingM3P : "",
    totalOperatingTotal: operatingExpenses ? operatingExpenses.totalOperatingTotal : "",
    totalOperatingTotalP: operatingExpenses ? operatingExpenses.totalOperatingTotalP : "",
  };

  return (
    <div>
      <div className="col-lg-12 grid-margin stretch-card">
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);

            // Submit form here;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form name="sales-data" onSubmit={handleSubmit}>
              <div className="table-responsive">
                <div>
                  <table className="table table-bordered">
                    <tbody>
                      <tr
                        style={{
                          fontWeight: "bold",
                          // paddingLeft: "0px",
                          textAlign: "center",
                        }}
                      >
                        <th
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            padding: "0px",
                            textAlign: "center",
                          }}
                          width="10%"
                        >
                          Operating Expenses
                        </th>
                        <td width="10%"></td>
                        <td width="10%"> {months.firstMonth}</td>
                        <td width="10%">%</td>
                        <td width="10%">{months.secondMonth}</td>
                        <td width="10%">%</td>
                        <td width="10%">{months.thirdMonth}</td>
                        <td width="10%">%</td>
                        <td width="10%">Total </td>
                        <td width="10%">%</td>
                      </tr>

                      <tr>
                        <th width="10%" scope="row">
                          {" "}
                        </th>
                        <td
                          width="10%"
                          style={{
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          Payroll - BOH
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollBohM1"
                            name="payrollBohM1"
                            defaultValue={values.payrollBohM1}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollBohM1P"
                            name="payrollBohM1P"
                            defaultValue={values.payrollBohM1P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollBohM2"
                            name="payrollBohM2"
                            defaultValue={values.payrollBohM2}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollBohM2P"
                            name="payrollBohM2P"
                            defaultValue={values.payrollBohM2P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollBohM3"
                            name="payrollBohM3"
                            defaultValue={values.payrollBohM3}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollBohM3P"
                            name="payrollBohM3P"
                            defaultValue={values.payrollBohM3P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollBohTotal"
                            name="payrollBohTotal"
                            defaultValue={values.payrollBohTotal}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollBohTotalP"
                            name="payrollBohTotalP"
                            defaultValue={values.payrollBohTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th width="10%" scope="row">
                          {" "}
                        </th>
                        <td
                          width="10%"
                          style={{
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          Payroll - FOH
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollFohM1"
                            name="payrollFohM1"
                            defaultValue={values.payrollFohM1}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollFohM1P"
                            name="payrollFohM1P"
                            defaultValue={values.payrollFohM1P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollFohM2"
                            name="payrollFohM2"
                            defaultValue={values.payrollFohM2}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollFohM2P"
                            name="payrollFohM2P"
                            defaultValue={values.payrollFohM2P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollFohM3"
                            name="payrollFohM3"
                            defaultValue={values.payrollFohM3}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollFohM3P"
                            name="payrollFohM3P"
                            defaultValue={values.payrollFohM3P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollFohTotal"
                            name="payrollFohTotal"
                            defaultValue={values.payrollFohTotal}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollFohTotalP"
                            name="payrollFohTotalP"
                            defaultValue={values.payrollFohTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th width="10%" scope="row">
                          {" "}
                        </th>
                        <td
                          width="10%"
                          style={{
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          Cleaning & Laundry
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="cleaningM1"
                            name="cleaningM1"
                            defaultValue={values.cleaningM1}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="cleaningM1P"
                            name="cleaningM1P"
                            defaultValue={values.cleaningM1P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="cleaningM2"
                            name="cleaningM2"
                            defaultValue={values.cleaningM2}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="cleaningM2P"
                            name="cleaningM2P"
                            defaultValue={values.cleaningM2P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="cleaningM3"
                            name="cleaningM3"
                            defaultValue={values.cleaningM3}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="cleaningM3P"
                            name="cleaningM3P"
                            defaultValue={values.cleaningM3P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="cleaningTotal"
                            name="cleaningTotal"
                            defaultValue={values.cleaningTotal}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="cleaningTotalP"
                            name="cleaningTotalP"
                            defaultValue={values.cleaningTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th width="10%" scope="row">
                          {" "}
                        </th>
                        <td
                          width="10%"
                          style={{
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          Shortage
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="shortageM1"
                            name="shortageM1"
                            defaultValue={values.shortageM1}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="shortageM1P"
                            name="shortageM1P"
                            defaultValue={values.shortageM1P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="shortageM2"
                            name="shortageM2"
                            defaultValue={values.shortageM2}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="shortageM2P"
                            name="shortageM2P"
                            defaultValue={values.shortageM2P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="shortageM3"
                            name="shortageM3"
                            defaultValue={values.shortageM3}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="shortageM3P"
                            name="shortageM3P"
                            defaultValue={values.shortageM3P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="shortageTotal"
                            name="shortageTotal"
                            defaultValue={values.shortageTotal}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="shortageTotalP"
                            name="shortageTotalP"
                            defaultValue={values.shortageTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th width="10%" scope="row">
                          {" "}
                        </th>
                        <td
                          width="10%"
                          style={{
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          Rent
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="rentM1"
                            name="rentM1"
                            defaultValue={values.rentM1}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="rentM1P"
                            name="rentM1P"
                            defaultValue={values.rentM1P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="rentM2"
                            name="rentM2"
                            defaultValue={values.rentM2}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="rentM2P"
                            name="rentM2P"
                            defaultValue={values.rentM2P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="rentM3"
                            name="rentM3"
                            defaultValue={values.rentM3}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="rentM3P"
                            name="rentM3P"
                            defaultValue={values.rentM3P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="rentTotal"
                            name="rentTotal"
                            defaultValue={values.rentTotal}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="rentTotalP"
                            name="rentTotalP"
                            defaultValue={values.rentTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> </th>
                        <td
                          width="10%"
                          style={{
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          Repair & Maintenance
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="repairM1"
                            name="repairM1"
                            defaultValue={values.repairM1}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="repairM1P"
                            name="repairM1P"
                            defaultValue={values.repairM1P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="repairM2"
                            name="repairM2"
                            defaultValue={values.repairM2}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="repairM2P"
                            name="repairM2P"
                            defaultValue={values.repairM2P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="repairM3"
                            name="repairM3"
                            defaultValue={values.repairM3}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="repairM3P"
                            name="repairM3P"
                            defaultValue={values.repairM3P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="repairTotal"
                            name="repairTotal"
                            defaultValue={values.repairTotal}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="repairTotalP"
                            name="repairTotalP"
                            defaultValue={values.repairTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> </th>
                        <td
                          width="10%"
                          style={{
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          Uniforms
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="uniformsM1"
                            name="uniformsM1"
                            defaultValue={values.uniformsM1}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="uniformsM1P"
                            name="uniformsM1P"
                            defaultValue={values.uniformsM1P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="uniformsM2"
                            name="uniformsM2"
                            defaultValue={values.uniformsM2}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="uniformsM2P"
                            name="uniformsM2P"
                            defaultValue={values.uniformsM2P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="uniformsM3"
                            name="uniformsM3"
                            defaultValue={values.uniformsM3}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="uniformsM3P"
                            name="uniformsM3P"
                            defaultValue={values.uniformsM3P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="uniformsTotal"
                            name="uniformsTotal"
                            defaultValue={values.uniformsTotal}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="uniformsTotalP"
                            name="uniformsTotalP"
                            defaultValue={values.uniformsTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> </th>
                        <td
                          width="10%"
                          style={{
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          Utilities
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="utilitiesM1"
                            name="utilitiesM1"
                            defaultValue={values.utilitiesM1}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="utilitiesM1P"
                            name="utilitiesM1P"
                            defaultValue={values.utilitiesM1P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="utilitiesM2"
                            name="utilitiesM2"
                            defaultValue={values.utilitiesM2}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="utilitiesM2P"
                            name="utilitiesM2P"
                            defaultValue={values.utilitiesM2P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="utilitiesM3"
                            name="utilitiesM3"
                            defaultValue={values.utilitiesM3}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="utilitiesM3P"
                            name="utilitiesM3P"
                            defaultValue={values.utilitiesM3P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="utilitiesTotal"
                            name="utilitiesTotal"
                            defaultValue={values.utilitiesTotal}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="utilitiesTotalP"
                            name="utilitiesTotalP"
                            defaultValue={values.utilitiesTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> </th>
                        <td
                          width="10%"
                          style={{
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          Total
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalOperatingM1"
                            name="totalOperatingM1"
                            defaultValue={values.totalOperatingM1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalOperatingM1P"
                            name="totalOperatingM1P"
                            defaultValue={values.totalOperatingM1P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalOperatingM2"
                            name="totalOperatingM2"
                            defaultValue={values.totalOperatingM2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalOperatingM2P"
                            name="totalOperatingM2P"
                            defaultValue={values.totalOperatingM2P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalOperatingM3"
                            name="totalOperatingM3"
                            defaultValue={values.totalOperatingM3}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalOperatingM3P"
                            name="totalOperatingM3P"
                            defaultValue={values.totalOperatingM3P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalOperatingTotal"
                            name="totalOperatingTotal"
                            defaultValue={values.totalOperatingTotal}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalOperatingTotalP"
                            name="totalOperatingTotalP"
                            defaultValue={values.totalOperatingTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div>
                {/* <LPSubmitButton className="btn btn-primary" type="submit">
                  Update Operating Expenses
                </LPSubmitButton> */}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default OperatingExpenses;
