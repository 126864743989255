import React from "react";
import { Link } from "react-router-dom";

const EnglishSummary = ({ surveyDetails, handleSurveySubmit }) => {
  return (
    <div className="card-body all-card-body">
      <div>
        <h2> Summary (Q1 - Q14) </h2>
      </div>

      <div>
        <div className="pull-left">
          <p>
            Revisit the questions by clicking on the <b> Back to Questions </b>{" "}
            button
          </p>
        </div>

        <div className="pull-right">
          <Link to={"/employees/rate-your-leaders/create"}>
            <button className="btn btn-primary"> Back to Questions </button>
          </Link>
        </div>
      </div>

      <div className="clearfix"></div>

      <div
        className="row"
        style={{
          marginTop: "70px",
        }}
      >
        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q1</b>&nbsp;&nbsp;&nbsp;{" "}
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            &nbsp; works with me to build and maintain a trusting and fair
            relationship that is productive
          </p>
          <span>{surveyDetails.question1}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q2</b>&nbsp;&nbsp;&nbsp;{" "}
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            &nbsp; demonstrates accountability for my work output in a
            productive way, and is as concerned about my success as I am.
          </p>
          <span>{surveyDetails.question2}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q3</b>&nbsp;&nbsp;&nbsp;{" "}
            <b>
              <i>{surveyDetails.manager_name} </i>
            </b>{" "}
            &nbsp; keeps me informed of the purpose, direction and immediate
            objectives of the organisation, and importantly for our division or
            section; and the role our team plays in achieving these.
          </p>
          <span>{surveyDetails.question3}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q4</b>&nbsp;&nbsp;&nbsp;{" "}
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            &nbsp; makes it easy for me to know what work I am to do as I am
            provided with clear context, purpose, accountabilities and
            authorities in a formal role definition which is reviewed and up to
            date.
          </p>
          <span>{surveyDetails.question4}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q5</b>&nbsp;&nbsp;&nbsp;{" "}
            <b>
              <i> {surveyDetails.manager_name} </i>
            </b>{" "}
            &nbsp; holds regular team meetings where we discuss work tasks and
            make progress on plans or solve problems.
          </p>
          <span>{surveyDetails.question5}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q6</b>&nbsp;&nbsp;&nbsp; &nbsp; When special tasks or projects
            come up{" "}
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            uses a structured way of informing me about the work I must
            undertake. This method includes context, quantity, quality,
            resources and time.
          </p>
          <span>{surveyDetails.question6}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q7</b>&nbsp;&nbsp;&nbsp;{" "}
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            &nbsp; appropriately monitors the progress of my work in a
            productive way.
          </p>
          <span>{surveyDetails.question7}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q8</b>&nbsp;&nbsp;&nbsp;{" "}
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            &nbsp; provides periodic feedback on the effectiveness of my work
            that is objective and fair.
          </p>
          <span>{surveyDetails.question8}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q9</b>&nbsp;&nbsp;&nbsp;{" "}
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            &nbsp; looks out for any poor performance of those in the team and
            manages this well to keep everyone on track.
          </p>
          <span>{surveyDetails.question9}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q10</b>&nbsp;&nbsp;&nbsp;{" "}
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            &nbsp; ensures I have the necessary authority to undertake my known
            work accountabilities.
          </p>
          <span>{surveyDetails.question10}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q11</b>&nbsp;&nbsp;&nbsp; &nbsp; In order for me to develop in my
            role &nbsp;
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            &nbsp; provides regular coaching for my improvement in a structured
            way.
          </p>
          <span>{surveyDetails.question11}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q12</b>&nbsp;&nbsp;&nbsp;{" "}
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            &nbsp; adds value to the work challenges I have by seeing problems
            in a different way, and providing clarity.
          </p>
          <span>{surveyDetails.question12}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q13</b>&nbsp;&nbsp;&nbsp;{" "}
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            &nbsp; appropriately manages interpersonal conflict within our team.
          </p>
          <span>{surveyDetails.question13}</span>
        </div>

        <div className="col-md-4 summary-card-wrapper">
          <p>
            {" "}
            <b>Q14</b>&nbsp;&nbsp;&nbsp; &nbsp; In order to ensure our team
            works to optimum efficiency&nbsp;
            <b>
              <i>{surveyDetails.manager_name}</i>
            </b>{" "}
            &nbsp; has built a team of people who are an ideal fit for the
            complexity of their role.
          </p>
          <span>{surveyDetails.question14}</span>
        </div>
      </div>

      <div
        style={{
          marginTop: "20px",
        }}
      >
        <button
          className="btn btn-primary"
          onClick={() => {
            handleSurveySubmit();
          }}
        >
          Proceed and Submit
        </button>
      </div>
    </div>
  );
};

export default EnglishSummary;
