import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import cogoToast from 'cogo-toast';
import { AuthService } from '../../service/authService';
import { Utility } from '../../Helpers/utils';

export class AdminLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      submitted: false
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toastoptions = {
    hideAfter: 5,
    position: 'top-right',
    heading: 'Attention'
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value})
  }


  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { email, password } = this.state;

    if (email && password) {
      AuthService.adminLogin(email, password)
      .then(response => {
        if (response.data.success) {
          if (response.data.data?.user.first_login) {
            cogoToast.warning('You have to change your password before logging in', this.toastoptions)
            this.props.history.push('/first-login', { email })
          } else {
            Utility.setUser(response.data.data?.user)
            Utility.setToken(response.data.data?.access_token);
            cogoToast.success('User logged in successfully', this.toastoptions);
            this.props.history.push('/dashboard')
          }
        }
      })
      .catch(error => {
        cogoToast.error(error.response.data.message, this.toastoptions);
      })
    } else {
      cogoToast.error('Please enter all the credentials', this.toastoptions)
    }
  }

  render() {
    const { email, password, submitted } = this.state;
    return (
      <div>
        <div className="d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="text-center">
                  <div className="brand-logo">
                    <img src={require("../../assets/images/la-parrila-logo.png")} alt="logo" />
                  </div>
                  <h4>Hello! Administrator </h4>
                  <h6 className="font-weight-light">Sign in to continue.</h6>
                </div>
                <Form className="pt-3" name="form" onSubmit={this.handleSubmit}>
                  <Form.Group className="d-flex search-field">
                    <Form.Control type="email" placeholder="Email Address" id="email" name="email" value={email} size="lg" className="h-auto" onChange={this.handleChange} />
                  </Form.Group>
                  {submitted && !password &&
                      <div className="help-block">Email is required</div>
                    }
                  <Form.Group className="d-flex search-field">
                    <Form.Control type="password" placeholder="Password" id="password" name="password" value={password} size="lg" className="h-auto" onChange={this.handleChange}/>
                    
                  </Form.Group>
                  {submitted && !password &&
                      <div className="help-block">Password is required</div>
                    }
                  <div className="mt-3">
                    <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"> SIGN IN </button>
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-center">
                    <div className="text-center">
                      <Link to="/password/forgot-password" className="auth-link text-black">Forgot password?</Link>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>  
      </div>
    )
  }
}

export default withRouter(AdminLogin)
