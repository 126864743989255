import React, { useEffect, useState } from "react";
import { Tabs, Tab, Spinner } from "react-bootstrap";
import "moment-timezone";
import moment from "moment";
import { PaymentService } from "../../../service/PaymentService";
import { Utility } from "../../../Helpers/utils";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { ConvertDateFormat } from "../../../components/ConvertDateFormat";
import ShowIssuesModal from "./components/modals/ShowIssuesModal";
import PaymentMadeModal from "./components/modals/PaymentMadeModal";
import AllPaymentsList from "./components/AllPaymentsList";
// import { withRouter } from "react-router";
// const { SearchBar } = Search;

const InstantPayPayment = () => {
  const [barTenderPayment, setBarTenderPayment] = useState(null);
  const [tipIncomeReport, setTipIncomeReport] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [salesReport, setSalesReport] = useState(null);
  // const [madePayment, setMadePayment] = useState(false);
  const [madePaymentModal, setMadePaymentModal] = useState(false);
  const [successfulPayments, setSuccessfulPayments] = useState(null);
  const [failedPayments, setFailedPayments] = useState(null);
  const [scheduledPayment, setScheduledPayment] = useState(null);
  const [otherPayments, setOtherPayments] = useState(null);
  const [togoPayments, setTogoPayments] = useState(null);
  const [atoPaymentDetails, setAtoPaymentDetails] = useState(null);
  const [doorDashPaymentDetails, setDoorDashPaymentDetails] = useState(null);
  const [cateringPayments, setCateringPayments] = useState(null);
  const [cateringResponses, setCateringResponses] = useState(null);
  const [coachServerEmployees, setCoachServerEmployees] = useState(null);
  const [extractedServers, setExtractedServers] = useState(null);
  const [showIssuesModal, setShowIssuesModal] = useState(false);

  const user = Utility.getUser();
  let history = useHistory();

  useEffect(() => {
    var btn = document.getElementsByTagName("button")[5];
    if (btn) {
      if (btn.disabled) {
        btn.disabled = false;
      }
    }

    if (JSON.parse(localStorage.getItem("tipIncomeReport"))) {
      console.log(JSON.parse(localStorage.getItem("tipIncomeReport")));
      setTipIncomeReport(JSON.parse(localStorage.getItem("tipIncomeReport")));
    }

    if (JSON.parse(localStorage.getItem("otherEmpdetails"))) {
      console.log(JSON.parse(localStorage.getItem("otherEmpdetails")));
      setOtherPayments(JSON.parse(localStorage.getItem("otherEmpdetails")));
    }

    if (JSON.parse(localStorage.getItem("cateringPayments"))) {
      console.log(JSON.parse(localStorage.getItem("cateringPayments")));
      setCateringPayments(JSON.parse(localStorage.getItem("cateringPayments")));
    }

    if (JSON.parse(localStorage.getItem("atoPaymentDetails"))) {
      console.log(JSON.parse(localStorage.getItem("atoPaymentDetails")));
      setAtoPaymentDetails(JSON.parse(localStorage.getItem("atoPaymentDetails")));
    }

    if (JSON.parse(localStorage.getItem("doorDashPaymentDetails"))) {
      console.log(JSON.parse(localStorage.getItem("doorDashPaymentDetails")));
      setDoorDashPaymentDetails(JSON.parse(localStorage.getItem("doorDashPaymentDetails")));
    }

    if (JSON.parse(localStorage.getItem("togoCalculatedPayments"))) {
      console.log(JSON.parse(localStorage.getItem("togoCalculatedPayments")));
      setTogoPayments(JSON.parse(localStorage.getItem("togoCalculatedPayments")));
    }

    if (JSON.parse(localStorage.getItem("togoPayments"))) {
      if (
        JSON.parse(localStorage.getItem("togoPayments")).togoTotalTips &&
        !JSON.parse(localStorage.getItem("togoCalculatedPayments"))
      ) {
        Swal.fire({
          title: "Warning",
          text: "You are yet to compute and submit ToGo payments",
          icon: "warning",
          confirmButtonText: "Ok",
          allowEscapeKey: false,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            console.log("redirection should work");
            window.location.reload();
            // setShowIssuesModal(true);
            // Swal.close();
          }
        });
      }
    }

    if (JSON.parse(localStorage.getItem("atoDoorDashTips"))) {
      if (
        JSON.parse(localStorage.getItem("atoDoorDashTips")).atoTips &&
        !JSON.parse(localStorage.getItem("atoPaymentDetails"))
      ) {
        Swal.fire({
          title: "Warning",
          text: "You are yet to submit Online / Doordash payments",
          icon: "warning",
          confirmButtonText: "Ok",
          allowEscapeKey: false,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            console.log("redirection should work");
            window.location.reload();
            // setShowIssuesModal(true);
            // Swal.close();
          }
        });
      }
    }

    if (JSON.parse(localStorage.getItem("laborInfoDetails"))) {
      console.log(JSON.parse(localStorage.getItem("laborInfoDetails")).cateringResponses);
      setCateringResponses(JSON.parse(localStorage.getItem("laborInfoDetails")).cateringResponses);

      setExtractedServers(JSON.parse(localStorage.getItem("tipIncomeReport")).server);

      setCoachServerEmployees(JSON.parse(localStorage.getItem("tipIncomeReport")).diff);
    }

    if (JSON.parse(localStorage.getItem("barTenderPayment"))) {
      console.log(JSON.parse(localStorage.getItem("barTenderPayment")));
      setBarTenderPayment(JSON.parse(localStorage.getItem("barTenderPayment")));
    }

    if (JSON.parse(localStorage.getItem("salesReport"))) {
      const salesReport = JSON.parse(localStorage.getItem("salesReport"));
      PaymentService.getScheduledPayment(salesReport.owner)
        .then((response) => {
          console.log(response.data.data);
          setScheduledPayment(response.data.data);
        })
        .catch((error) => {
          console.log(error.response.data);
        });

      setSalesReport(JSON.parse(localStorage.getItem("salesReport")));
    }

    if (JSON.parse(localStorage.getItem("expenses"))) {
      console.log(JSON.parse(localStorage.getItem("expenses")));
      const exp_tot = JSON.parse(localStorage.getItem("expenses"));
      console.log(exp_tot);
    }
  }, []);

  const roundNumberUp = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  const handleModalClose = () => {
    setMadePaymentModal(false);
    history.push("/financials/view-store-report");
  };

  const instantPayPayment = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to process all payments. Please note that no further changes can be made to this report. Click yes to proceed!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#000000",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsSubmitting(true);
        if (JSON.parse(localStorage.getItem("newCompletedSalesReport"))) {
          localStorage.removeItem("newCompletedSalesReport");
        }
        Swal.fire({
          title: "In progress",
          text: "Please wait...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });

        let today = moment.tz(new Date(), "America/New_York");
        let barPaymentData = [];
        let serverPaymentData = [];
        let coachServerPaymentData = [];
        let scheduledPaymentData = [];
        let cateringPaymentData = [];
        let togoPaymentData = [];
        let atoPaymentData = [];
        let doorDashPaymentData = [];
        let barPaymentTotal = 0;
        let serverPaymentTotal = 0;
        let coachServerPaymentTotal = 0;
        let scheduledPaymentTotal = 0;
        let cateringPaymentTotal = 0;
        let togoPaymentTotal = 0;
        let atoPaymentTotal = 0;
        let doorDashPaymentTotal = 0;

        // bartenders
        if (barTenderPayment) {
          barTenderPayment.tipSummary.map((bar, index) => {
            let splitName = bar.name.split(" ");
            let firstName, lastName;
            if (splitName.length > 2) {
              firstName = splitName[0];
              lastName = splitName[1] + " " + splitName[2];
            } else {
              firstName = splitName[0];
              lastName = splitName[1];
            }
            barPaymentTotal += parseFloat(roundNumberUp(bar.instant_pay_total));
            barPaymentData.push({
              payrollEmployeeId: `${bar.employee_number}`,
              externalLocationId: salesReport ? salesReport.owner : "",
              employeeType: "bartender",
              reportDate: salesReport ? salesReport.report_date : "",
              employeeInfo: {
                firstName,
                lastName,
              },
              paymentType: "Tips",
              payDisbursementDateTime: today.format(),
              allocationDate: today.format("yyyy-MM-DD"),
              amount: parseFloat(roundNumberUp(bar.instant_pay_total)),
              tip: parseFloat(roundNumberUp(bar.tip_owed)),
              tipshare: parseFloat(roundNumberUp(bar.total_tipshare_received)),
              declaredTips: parseFloat(roundNumberUp(bar.declared_tips)),
            });
            return barPaymentData;
          });
        }

        // servers
        tipIncomeReport.server.map((server, index) => {
          let splitName = server.employee_name.split(" ");
          let firstName, lastName;
          if (splitName.length > 2) {
            firstName = splitName[0];
            lastName = splitName[1] + " " + splitName[2];
          } else {
            firstName = splitName[0];
            lastName = splitName[1];
          }
          serverPaymentTotal += parseFloat(roundNumberUp(server.tip_owed));
          serverPaymentData.push({
            payrollEmployeeId: `${server.employee_number}`,
            externalLocationId: salesReport ? salesReport.owner : "",
            employeeType: "server",
            reportDate: salesReport ? salesReport.report_date : "",
            employeeInfo: {
              firstName,
              lastName,
            },
            paymentType: "Tips",
            payDisbursementDateTime: today.format(),
            allocationDate: today.format("yyyy-MM-DD"),
            amount: parseFloat(roundNumberUp(server.tip_owed)),
          });
          return serverPaymentData;
        });

        // catering
        if (cateringPayments) {
          cateringPayments.map((cat, index) => {
            let splitName = cat.employee_name.split(" ");
            let firstName, lastName;
            if (splitName.length > 2) {
              firstName = splitName[0];
              lastName = splitName[1] + " " + splitName[2];
            } else {
              firstName = splitName[0];
              lastName = splitName[1];
            }
            cateringPaymentTotal += parseFloat(roundNumberUp(cat.tip_owed));
            cateringPaymentData.push({
              payrollEmployeeId: `${cat.employee_number}`,
              externalLocationId: salesReport ? salesReport.owner : "",
              employeeType: "catering",
              reportDate: salesReport ? salesReport.report_date : "",
              employeeInfo: {
                firstName,
                lastName,
              },
              paymentType: "Tips",
              payDisbursementDateTime: today.format(),
              allocationDate: today.format("yyyy-MM-DD"),
              amount: parseFloat(roundNumberUp(cat.tip_owed)),
            });
            return cateringPaymentData;
          });
        }

        // ToGo
        if (togoPayments) {
          togoPayments.togoPaymentDetails.map((togo, index) => {
            let splitName = togo.employee_name.split(" ");
            let firstName, lastName;
            if (splitName.length > 2) {
              firstName = splitName[0];
              lastName = splitName[1] + " " + splitName[2];
            } else {
              firstName = splitName[0];
              lastName = splitName[1];
            }
            togoPaymentTotal += parseFloat(roundNumberUp(togo.tipshare_received));
            togoPaymentData.push({
              payrollEmployeeId: `${togo.employee_number}`,
              externalLocationId: salesReport ? salesReport.owner : "",
              employeeType: "togo",
              reportDate: salesReport ? salesReport.report_date : "",
              employeeInfo: {
                firstName,
                lastName,
              },
              paymentType: "Tips",
              payDisbursementDateTime: today.format(),
              allocationDate: today.format("yyyy-MM-DD"),
              amount: parseFloat(roundNumberUp(togo.tipshare_received)),
              declaredTips: parseFloat(roundNumberUp(togo.declared_tips)),
            });
            return togoPaymentData;
          });
        }

        // ATO Payments
        if (atoPaymentDetails) {
          atoPaymentDetails.map((ato, index) => {
            let splitName = ato.name.split(" ");
            let firstName, lastName;
            if (splitName.length > 2) {
              firstName = splitName[0];
              lastName = splitName[1] + " " + splitName[2];
            } else {
              firstName = splitName[0];
              lastName = splitName[1];
            }
            atoPaymentTotal += parseFloat(roundNumberUp(ato.tipShareReceived));
            atoPaymentData.push({
              payrollEmployeeId: `${ato.employeeId}`,
              externalLocationId: salesReport ? salesReport.owner : "",
              employeeType: "ato",
              reportDate: salesReport ? salesReport.report_date : "",
              employeeInfo: {
                firstName,
                lastName,
              },
              paymentType: "Tips",
              payDisbursementDateTime: today.format(),
              allocationDate: today.format("yyyy-MM-DD"),
              amount: parseFloat(roundNumberUp(ato.tipShareReceived)),
            });
            return atoPaymentData;
          });
        }

        // Door Dash Payments
        if (doorDashPaymentDetails) {
          doorDashPaymentDetails.map((door, index) => {
            let splitName = door.name.split(" ");
            let firstName, lastName;
            if (splitName.length > 2) {
              firstName = splitName[0];
              lastName = splitName[1] + " " + splitName[2];
            } else {
              firstName = splitName[0];
              lastName = splitName[1];
            }
            doorDashPaymentTotal += parseFloat(roundNumberUp(door.tipShareReceived));
            doorDashPaymentData.push({
              payrollEmployeeId: `${door.employeeId}`,
              externalLocationId: salesReport ? salesReport.owner : "",
              employeeType: "doorDash",
              reportDate: salesReport ? salesReport.report_date : "",
              employeeInfo: {
                firstName,
                lastName,
              },
              paymentType: "Tips",
              payDisbursementDateTime: today.format(),
              allocationDate: today.format("yyyy-MM-DD"),
              amount: parseFloat(roundNumberUp(door.tipShareReceived)),
            });
            return doorDashPaymentData;
          });
        }

        // other payments ( TOGO and others)
        if (otherPayments) {
          otherPayments.map((server, index) => {
            let splitName = server.employee_name.split(" ");
            let firstName, lastName;
            if (splitName.length > 2) {
              firstName = splitName[0];
              lastName = splitName[1] + " " + splitName[2];
            } else {
              firstName = splitName[0];
              lastName = splitName[1];
            }
            coachServerPaymentTotal += parseFloat(roundNumberUp(server.tip_owed));
            coachServerPaymentData.push({
              payrollEmployeeId: `${server.employee_number}`,
              externalLocationId: salesReport ? salesReport.owner : "",
              employeeType: "others",
              reportDate: salesReport ? salesReport.report_date : "",
              employeeInfo: {
                firstName,
                lastName,
              },
              paymentType: "Tips",
              payDisbursementDateTime: today.format(),
              allocationDate: today.format("yyyy-MM-DD"),
              amount: parseFloat(roundNumberUp(server.tip_owed)),
            });
            return coachServerPaymentData;
          });
        }

        // scheduled payments
        if (scheduledPayment) {
          scheduledPayment.map((sch, index) => {
            scheduledPaymentTotal += parseFloat(roundNumberUp(sch.amount));
            scheduledPaymentData.push({
              payrollEmployeeId: `${sch.payrollEmployeeId}`,
              externalLocationId: salesReport ? salesReport.owner : "",
              employeeType: "scheduled",
              reportDate: salesReport ? salesReport.report_date : "",
              employeeInfo: {
                firstName: sch.firstName,
                lastName: sch.lastName,
              },
              paymentType: "Tips",
              payDisbursementDateTime: today.format(),
              allocationDate: today.format("yyyy-MM-DD"),
              amount: parseFloat(roundNumberUp(sch.amount)),
            });

            return scheduledPaymentData;
          });
        }

        let payData = {
          paymentDetails: barPaymentData.concat(
            serverPaymentData,
            scheduledPaymentTotal > 0 ? scheduledPaymentData : [],
            coachServerPaymentTotal > 0 ? coachServerPaymentData : [],
            cateringPaymentTotal > 0 ? cateringPaymentData : [],
            togoPaymentTotal > 0 ? togoPaymentData : [],
            atoPaymentTotal > 0 ? atoPaymentData : [],
            doorDashPaymentTotal > 0 ? doorDashPaymentData : []
          ),
          paid_by_slug: user ? user.slug : null,
          manager_name: salesReport ? salesReport.printed_by : "",
          store_id: salesReport ? salesReport.owner : "",
          store_location: salesReport ? salesReport.location : "",
          report_date: salesReport ? salesReport.report_date : "",
        };

        console.log(payData);
        const totalPaymentSent =
          parseFloat(barPaymentTotal) +
          parseFloat(serverPaymentTotal) +
          (scheduledPaymentTotal > 0 ? parseFloat(scheduledPaymentTotal) : 0) +
          (coachServerPaymentTotal > 0 ? parseFloat(coachServerPaymentTotal) : 0) +
          (cateringPaymentTotal > 0 ? parseFloat(cateringPaymentTotal) : 0) +
          (togoPaymentTotal > 0 ? parseFloat(togoPaymentTotal) : 0) +
          (atoPaymentTotal > 0 ? parseFloat(atoPaymentTotal) : 0) +
          (doorDashPaymentTotal > 0 ? parseFloat(doorDashPaymentTotal) : 0);

        PaymentService.payTip(payData)
          .then((response) => {
            console.log(response.data.data);

            // setMadePayment(true);
            const instantSuccessRes = response.data.data.content.successes;
            const instantErrorRes = response.data.data.content.errors;

            console.log(instantSuccessRes, instantErrorRes);
            let errorAmount = 0;
            // let totalSuccessAmount = 0;
            let errorPaymentArray = [];
            let successPaymentArray = [];

            if (instantSuccessRes.length > 0) {
              instantSuccessRes.map((val, index) => {
                // totalSuccessAmount += val.amount;
                successPaymentArray.push({
                  _uuid: val._uuid,
                  allocationDate: val.allocatedAt,
                  amount: val.amount,
                  balance: val.balance,
                  firstName: val.raw.firstName,
                  lastName: val.raw.lastName,
                  clientId: val.clientId,
                  locationId: val.locationId,
                  referenceId: val.referenceId,
                  paymentMessage: val.processingResult.message,
                  employeeInfo: {
                    firstName: val.raw.firstName,
                    lastName: val.raw.lastName,
                  },
                  externalLocationId: val.raw.externalLocationId,
                  payDisbursementDateTime: val.processAt,
                  paymentType: "Tips",
                  payrollEmployeeId: val.raw.employeeId,
                  processingResult: {
                    code: val.processingResult.code,
                    message: val.processingResult.message,
                  },
                });
                return successPaymentArray;
              });
            } else {
              console.log("Success is less than 1");
            }
            if (instantErrorRes.length > 0) {
              instantErrorRes.map((val, index) => {
                errorAmount += parseFloat(val.amount);
                errorPaymentArray.push({
                  allocationDate: val.allocatedAt,
                  amount: val.amount,
                  firstName: val.raw.firstName,
                  lastName: val.raw.lastName,
                  employeeInfo: {
                    firstName: val.raw.firstName,
                    lastName: val.raw.lastName,
                  },
                  externalLocationId: val.raw.externalLocationId,
                  payDisbursementDateTime: val.processAt,
                  paymentType: "Tips",
                  payrollEmployeeId: val.raw.employeeId,
                  errorMessage: val.failureReason.message,
                  failureReason: {
                    code: val.failureReason.code,
                    message: val.failureReason.message,
                  },
                });
                return errorPaymentArray;
              });
            } else {
              console.log("Error is less than 1");
            }

            const totalFailedAmount = parseFloat(errorAmount);

            const differencesInTransaction =
              parseFloat(totalPaymentSent) - parseFloat(totalFailedAmount);

            const paymentHistoryData = {
              successful: successPaymentArray,
              failedPending: errorPaymentArray,
              scheduled: scheduledPayment,
              totalPaymentSent,
              totalFailedAmount,
              differencesInTransaction,
              storeId: salesReport ? salesReport.owner : "",
              reportDate: salesReport ? salesReport.report_date : "",
              storeLocation: salesReport ? salesReport.location : "",
              paid_by_slug: user ? user.slug : null,
              // depositData: depositData ? depositData : null,
              cateringResponses: cateringResponses ? cateringResponses : [],
              coachServerEmployees: coachServerEmployees ? coachServerEmployees : [],
              extractedServers: extractedServers ? extractedServers : [],
              cateringPayments: cateringPayments ? cateringPayments : [],
            };

            console.log(paymentHistoryData, "This is payment history");

            Swal.fire({
              title: "Almost done",
              text: "Processing files and sending emails...",
              allowEscapeKey: false,
              allowOutsideClick: false,
              onOpen: () => {
                Swal.showLoading();
              },
            });

            PaymentService.proccessInstantPayResponse(paymentHistoryData)
              .then((response) => {
                console.log(response.data.data, "This is the final report");

                let successRes = response.data.data.success;
                let failedRes = response.data.data.failed;

                setFailedPayments(failedRes);
                setSuccessfulPayments(successRes);

                if (successRes.length > 0 && failedRes.length < 1) {
                  setMadePaymentModal(false);
                  setIsSubmitting(false);
                  Swal.fire({
                    title: "Good Job!",
                    text: "All payments have been scheduled successfully",
                    icon: "success",
                    confirmButtonText: "Ok",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      console.log("redirection should work");
                      // Clear data from storage
                      localStorage.removeItem("salesReport");
                      localStorage.removeItem("tipDetailsReport");
                      localStorage.removeItem("tipDetailsBartender");
                      localStorage.removeItem("tipIncomeReport");
                      localStorage.removeItem("barTenderPayment");
                      localStorage.removeItem("cateringPayments");
                      localStorage.removeItem("laborInfoDetails");
                      localStorage.removeItem("mergedEmpDetails");
                      localStorage.removeItem("togoPayments");

                      localStorage.removeItem("doorDashPaymentDetails");
                      localStorage.removeItem("togoCalculatedPayments");
                      localStorage.removeItem("otherEmpdetails");
                      localStorage.removeItem("laborBartenders");
                      localStorage.removeItem("atoPaymentDetails");
                      localStorage.removeItem("storeDetails");
                      localStorage.removeItem("atoDoorDashTips");
                      localStorage.removeItem("atoOption");
                      localStorage.removeItem("expenses");

                      // history.push("/form-manager/preview-closing-report");
                      history.push("/financials/view-closing-report", {
                        store_id: response.data.data.finalSalesReport.owner,
                        report_date: ConvertDateFormat(
                          response.data.data.finalSalesReport.report_date
                        ),
                      });
                    }
                  });
                } else {
                  Swal.close();
                  setMadePaymentModal(true);
                  setIsSubmitting(false);
                  localStorage.removeItem("salesReport");
                  localStorage.removeItem("tipDetailsReport");
                  localStorage.removeItem("tipDetailsBartender");
                  localStorage.removeItem("tipIncomeReport");
                  localStorage.removeItem("barTenderPayment");
                  localStorage.removeItem("cateringPayments");
                  localStorage.removeItem("laborInfoDetails");
                  localStorage.removeItem("mergedEmpDetails");
                  localStorage.removeItem("togoPayments");

                  localStorage.removeItem("doorDashPaymentDetails");
                  localStorage.removeItem("togoCalculatedPayments");
                  localStorage.removeItem("otherEmpdetails");
                  localStorage.removeItem("laborBartenders");
                  localStorage.removeItem("atoPaymentDetails");
                  localStorage.removeItem("storeDetails");
                  localStorage.removeItem("atoDoorDashTips");
                  localStorage.removeItem("atoOption");
                  localStorage.removeItem("expenses");
                }
              })
              .catch((error) => {
                console.log(error);
                console.log("Processing payment responses failed");
              });
          })
          .catch((error) => {
            console.log(error);
            setIsSubmitting(false);
            Swal.fire({
              title: "Error",
              text: "Could not process payment at this time. Please click on the button below to send messages to site administrator",
              icon: "info",
              confirmButtonText: "Ok",
              allowEscapeKey: false,
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                console.log("redirection should work");
                setShowIssuesModal(true);
                Swal.close();
              }
            });
          });
      }
    });
  };

  return (
    <section className="content" id="previewStage">
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              {/* <h4 className="card-title">Basic Tab</h4> */}
              <Tabs defaultActiveKey="instantPay" id="uncontrolled-tab-example">
                <Tab eventKey="instantPay" title="All InstantPay Payments">
                  <AllPaymentsList
                    tipIncomeReport={tipIncomeReport}
                    atoPaymentDetails={atoPaymentDetails}
                    doorDashPaymentDetails={doorDashPaymentDetails}
                    otherPayments={otherPayments}
                    barTenderPayment={barTenderPayment}
                    cateringPayments={cateringPayments}
                    togoPayments={togoPayments}
                    scheduledPayment={scheduledPayment}
                  />
                </Tab>
              </Tabs>

              <div className="mt-4">
                <button
                  id="processPaymentButton"
                  className="btn btn-primary"
                  disabled={!barTenderPayment || !tipIncomeReport}
                  onClick={(e) => {
                    instantPayPayment();
                  }}
                >
                  {" "}
                  Process All Payment &nbsp;&nbsp;
                  {isSubmitting ? <Spinner animation="border" size="sm" /> : ""}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {madePaymentModal && (
          <PaymentMadeModal
            handleModalClose={handleModalClose}
            madePaymentModal={madePaymentModal}
            successfulPayments={successfulPayments}
            failedPayments={failedPayments}
            isSubmitting={isSubmitting}
          />
        )}
      </div>

      <div className="row">
        {showIssuesModal && (
          <ShowIssuesModal
            salesReport={salesReport}
            showIssuesModal={showIssuesModal}
            setShowIssuesModal={setShowIssuesModal}
          />
        )}
      </div>
    </section>
  );
};

export default InstantPayPayment;
