import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Formik } from "formik";

const Question5 = ({ name, lang }) => {
  const [question5, setQuestion5] = useState(null);

  const handleQuestionChange = (e, setFieldValue) => {
    setFieldValue("question5", e.target.dataset.name);
    if (JSON.parse(localStorage.getItem("rateMyBossDetails"))) {
      let existingDetails = JSON.parse(
        localStorage.getItem("rateMyBossDetails")
      );

      Object.assign(existingDetails, {
        question5: e.target.dataset.name,
      });

      localStorage.setItem(
        "rateMyBossDetails",
        JSON.stringify(existingDetails)
      );
    }
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("rateMyBossDetails"))) {
      setQuestion5(
        JSON.parse(localStorage.getItem("rateMyBossDetails")).question5
      );
    }
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Formik
            initialValues={{
              question5: question5 ? question5 : "",
            }}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <div>
                {lang && lang === "spanish" ? (
                  <form className="rate_my_boss_form" onSubmit={handleSubmit}>
                    <div>
                      <p>
                        {" "}
                        <b>Q5</b>&nbsp;&nbsp;&nbsp;{" "}
                        <b>
                          <i>{name}</i>
                        </b>{" "}
                        &nbsp; celebra reuniones periódicas de equipo en las que
                        se discuten las tareas de trabajo y se avanza en los
                        planes o se resuelven los problemas.
                      </p>
                    </div>

                    <div
                      className="row"
                      style={{
                        marginTop: "70px",
                      }}
                    >
                      <div className="col-md-10">
                        <Form.Group className="row">
                          <div className="col-sm-3">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="question5"
                                  id="never"
                                  onChange={(e) =>
                                    handleQuestionChange(e, setFieldValue)
                                  }
                                  data-name={"never"}
                                  value={"never"}
                                  checked={values.question5 === "never"}
                                />{" "}
                                Nunca
                                <i className="input-helper"></i>
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="question5"
                                  id="sometimes"
                                  onChange={(e) =>
                                    handleQuestionChange(e, setFieldValue)
                                  }
                                  data-name={"sometimes"}
                                  value={"sometimes"}
                                  checked={values.question5 === "sometimes"}
                                />{" "}
                                A veces
                                <i className="input-helper"></i>
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="question5"
                                  id="often"
                                  onChange={(e) =>
                                    handleQuestionChange(e, setFieldValue)
                                  }
                                  data-name={"often"}
                                  value={"often"}
                                  checked={values.question5 === "often"}
                                />{" "}
                                A menudo
                                <i className="input-helper"></i>
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="question5"
                                  id="always"
                                  onChange={(e) =>
                                    handleQuestionChange(e, setFieldValue)
                                  }
                                  data-name={"always"}
                                  value={"always"}
                                  checked={values.question5 === "always"}
                                />{" "}
                                Siempre
                                <i className="input-helper"></i>
                              </label>
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                  </form>
                ) : (
                  <form className="rate_my_boss_form" onSubmit={handleSubmit}>
                    <div>
                      <p>
                        {" "}
                        <b>Q5</b>&nbsp;&nbsp;&nbsp;{" "}
                        <b>
                          <i>{name}</i>
                        </b>{" "}
                        &nbsp; holds regular team meetings where we discuss work
                        tasks and make progress on plans or solve problems.
                      </p>
                    </div>

                    <div
                      className="row"
                      style={{
                        marginTop: "70px",
                      }}
                    >
                      <div className="col-md-10">
                        <Form.Group className="row">
                          <div className="col-sm-3">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="question5"
                                  id="never"
                                  onChange={(e) =>
                                    handleQuestionChange(e, setFieldValue)
                                  }
                                  data-name={"never"}
                                  value={"never"}
                                  checked={values.question5 === "never"}
                                />{" "}
                                Never
                                <i className="input-helper"></i>
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="question5"
                                  id="sometimes"
                                  onChange={(e) =>
                                    handleQuestionChange(e, setFieldValue)
                                  }
                                  data-name={"sometimes"}
                                  value={"sometimes"}
                                  checked={values.question5 === "sometimes"}
                                />{" "}
                                Sometimes
                                <i className="input-helper"></i>
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="question5"
                                  id="often"
                                  onChange={(e) =>
                                    handleQuestionChange(e, setFieldValue)
                                  }
                                  data-name={"often"}
                                  value={"often"}
                                  checked={values.question5 === "often"}
                                />{" "}
                                Often
                                <i className="input-helper"></i>
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="question5"
                                  id="always"
                                  onChange={(e) =>
                                    handleQuestionChange(e, setFieldValue)
                                  }
                                  data-name={"always"}
                                  value={"always"}
                                  checked={values.question5 === "always"}
                                />{" "}
                                Always
                                <i className="input-helper"></i>
                              </label>
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Question5;
