import React from "react";

const ExpensesHeader = ({ totalExpenses, setCreateExpensesModal, salesReport }) => (
  <>
    <div className="pull-left">
      <h3 className="mb-4">Expenses</h3>
    </div>

    {totalExpenses <= 0 && (
      <div className="pull-right">
        <button
          className="btn btn-primary"
          onClick={() => setCreateExpensesModal(true)}
          disabled={!salesReport}
        >
          {" "}
          Create Expenses
        </button>
      </div>
    )}
  </>
);

export default ExpensesHeader;
