import cogoToast from "cogo-toast";
import { Formik } from "formik";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { SystemConfigService } from "../../../../../../service/configService";
import DatePicker from "react-datepicker";
import Select from "react-select";

const TemporaryAccess = ({
  employee,
  handleStorePermissionClose,
  storeOptions,
  defaultTemporaryStores,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  return (
    <div>
      <Formik
        initialValues={{
          user_id: employee ? employee.id : "",
          user_slug: employee ? employee.slug : "",
          access_type: "temporary",
          store_selected: defaultTemporaryStores,
          start_date: startDate ? startDate : "",
          end_date: endDate ? endDate : "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          console.log(values);

          if (values.store_selected.length > 0) {
            SystemConfigService.selectStoreForManager(values)
              .then((response) => {
                setSubmitting(false);
                cogoToast.success("Store saved successfully");
                window.location.reload();
                console.log(response.data.data);
              })
              .catch((error) => {
                console.log(error);
                setSubmitting(false);
              });
          } else {
            setSubmitting(false);
            Swal.fire({
              title: "Error!",
              text: `Please select a store`,
              icon: "error",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                Swal.close();
              }
            });
          }
        }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form name="form" onSubmit={handleSubmit}>
            <Form.Group>
              <label> Select Date Range </label>

              <div className="row">
                <div className="col-md-12">
                  <div className="daterange-picker">
                    <DatePicker
                      selected={values.start_date}
                      onChange={(startDate) => {
                        setStartDate(startDate);
                        setFieldValue(`start_date`, startDate ? startDate : "");
                      }}
                      isClearable
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      className="form-control w-100"
                      dateFormat="MM/dd/yyyy"
                      placeholderText="Select a start date"
                    />

                    <span className="range-seperator"> to </span>
                    <DatePicker
                      selected={values.end_date}
                      onChange={(endDate) => {
                        setEndDate(endDate);
                        setFieldValue(`end_date`, endDate ? endDate : "");
                      }}
                      isClearable
                      selectsEnd
                      endDate={endDate}
                      minDate={startDate}
                      className="form-control w-100"
                      placeholderText="Select an end date"
                    />
                  </div>
                </div>
              </div>
            </Form.Group>

            <Form.Group>
              <label htmlFor="store">
                Select Stores for &nbsp;
                <b>
                  {employee.first_name} {employee.last_name}
                </b>
              </label>

              <Select
                isMulti={true}
                onChange={(opt, e) => {
                  console.log(opt);
                  setFieldValue("store_selected", opt);
                }}
                name="store_selected"
                options={storeOptions}
                defaultValue={defaultTemporaryStores}
              />
            </Form.Group>
            <button
              type="submit"
              className="btn btn-primary font-weight-medium auth-form-btn"
            >
              Proceed &nbsp;&nbsp;
              {isSubmitting ? (
                // <Spinner animation="border" size="sm" />
                <span className="fa fa-spin fa-spinner"></span>
              ) : (
                ""
              )}
            </button>
            <button
              className="btn"
              type="button"
              variant="light m-2"
              onClick={handleStorePermissionClose}
            >
              Cancel
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default TemporaryAccess;
