import React from "react";
import { Form } from "react-bootstrap";

const InputField = ({ id, name, type, value, onChange, onBlur, placeholder, error, touched }) => (
  <Form.Group className="d-flex search-field">
    <Form.Control
      id={id}
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      size="lg" // Bootstrap size class
      className="h-auto"
    />
    {error && touched && <div className="text-danger">{error}</div>}
  </Form.Group>
);

export default InputField;
