import React, { useState } from "react";
import DatePicker from "react-datepicker";

const EmployeeDashboard = ({ user }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [store, setStore] = useState(user.store);

  return (
    <div>
      <div className="page-header flex-wrap">
        <div
          id="mf_placeholder"
          data-formurl="//forms.laparrilla.com/embed.php?id=76983"
          data-formheight="8110"
          data-formtitle="Recipe Book Daily Click List"
          data-paddingbottom="10"
        ></div>
        <h3 className="mb-0">Hi, {user.first_name}. welcome back!</h3>
      </div>

      <div>
        {store && (
          <h5 style={{ textTransform: "capitalize" }}>
            {" "}
            {store.name}
            <span className="pl-0 h6 pl-sm-2 text-muted d-inline-block">
              Employee Management Portal
            </span>
          </h5>
        )}
      </div>

      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card card-statistics">
            <div className="row">
              <div className="card-col col-xl-3 col-lg-6 col-md-3 col-6">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-center flex-column flex-sm-row">
                    <i className="mdi mdi-store text-primary mr-0 mr-sm-4 icon-lg"></i>
                    <div className="wrapper text-center text-sm-left">
                      <p className="card-text mb-0 text-dark">
                        Active Location
                      </p>
                      <div className="fluid-container">
                        <h3
                          className="mb-0 font-weight-medium text-dark"
                          style={{ textTransform: "capitalize" }}
                        >
                          {store && <span>{store.name}</span>}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-col col-xl-3 col-lg-6 col-md-3 col-6">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-center flex-column flex-sm-row">
                    <i className="mdi mdi-checkbox-marked-circle-outline text-primary mr-0 mr-sm-4 icon-lg"></i>
                    <div className="wrapper text-center text-sm-left">
                      <p className="card-text mb-0 text-dark">Corporation</p>
                      <div className="fluid-container">
                        <h3 className="mb-0 font-weight-medium text-dark">
                          Demo
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-col col-xl-3 col-lg-6 col-md-3 col-6">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-center flex-column flex-sm-row">
                    <i className="mdi mdi-trophy-outline text-primary mr-0 mr-sm-4 icon-lg"></i>
                    <div className="wrapper text-center text-sm-left">
                      <p className="card-text mb-0 text-dark">Employees</p>
                      <div className="fluid-container">
                        <h3 className="mb-0 font-weight-medium text-dark">
                          17,583
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-md-12 grid-margin grid-margin-lg-0 stretch-card">
          <div className="card">
            <div className="card-body">
              <DatePicker
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div className="datepicker-custom-header d-flex justify-content-between align-items-center">
                    <h5 className="month-name">
                      {date.toLocaleString("default", { month: "long" }) +
                        " " +
                        date.toLocaleString("default", { year: "numeric" })}
                    </h5>
                    <div>
                      <button
                        className="month-nav-btn btn btn-inverse-primary btn-rounded btn-icon prev-month"
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                      >
                        <i className="mdi mdi-chevron-left"></i>
                      </button>
                      <button
                        className="month-nav-btn btn btn-inverse-primary btn-rounded btn-icon next-month"
                        onClick={increaseMonth}
                      >
                        <i
                          className="mdi mdi-chevron-right"
                          disabled={nextMonthButtonDisabled}
                        ></i>
                      </button>
                    </div>
                  </div>
                )}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                inline
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmployeeDashboard;
