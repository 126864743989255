import React from "react";
import { FileUpload } from "./FileUpload";

export const TipIncomeReportUpload = ({ setFieldValue }) => {
  return (
    <FileUpload
      setFieldValue={setFieldValue}
      fieldName="tip_income_report"
      acceptedTypes=".csv"
      label="Uploaded Tip Income Report"
    />
  );
};
