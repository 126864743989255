import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { BonusPayoutService } from "../../../service/BonusPayoutService";
import getStoreName from "../../../components/StoreNameUsingId";
import Swal from "sweetalert2";

const ViewBulkUploadBreakdown = () => {
  const history = useHistory();

  const [bonusDataYear1, setBonusDataYear1] = useState(null);
  const [bonusDataYear2, setBonusDataYear2] = useState(null);
  const [bonusIncrease, setBonusIncrease] = useState(null);

  // if (history.location.state == undefined) {
  //   history.push("/bonus/bulk-upload");
  // } else {
  //   console.log(history.location.state);
  // }

  const year_1 = history.location.state.year_1;
  const year_2 = history.location.state.year_2;
  const quarter = history.location.state.quarter;
  const storeId = history.location.state.storeId;

  const m1 = "Jan";
  const m2 = "Feb";
  const m3 = "Mar";
  const currentQuarter = quarter;
  const currentYear = year_1;
  const nextYear = year_2;

  const handleBonusBreakdown = () => {
    history.push("/bonus/view-bonus-payout-breakdown", {
      year_1,
      year_2,
      quarter,
      storeId,
    });
  };

  useEffect(() => {
    BonusPayoutService.getBulkPayoutAndIncrease(
      year_1,
      year_2,
      quarter,
      storeId
    )
      .then((response) => {
        console.log(response.data.data);
        setBonusDataYear1(response.data.data.bonusDataYear1);
        setBonusDataYear2(response.data.data.bonusDataYear2);
        setBonusIncrease(response.data.data.bonusIncrease);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div className="float-left">
        <div>
          <img
            src={require("../../../assets/images/la-parrila-logo.png")}
            alt="logo"
            className=""
          />
        </div>

        <div>
          <h4
            style={{
              color: "red",
            }}
          >
            {" "}
            BONUS PAYOUT - {year_1} - {year_2} &nbsp; &nbsp;Q{quarter}
          </h4>
          <p> Location: {getStoreName(storeId)}</p>
        </div>
      </div>

      <div className="row float-right">
        <div className="tab-custom-pills-horizontal col-md-12">
          <div className="">
            <Link to={"/bonus/view-select-bonus-payout"}>
              <button type="button" className="btn btn-primary btn-rounded">
                Back
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="clearfix"></div>

      <div className="page-header">
        <h3 className="page-title"></h3>
        <div>
          <button className="btn btn-primary" onClick={handleBonusBreakdown}>
            {" "}
            View Bonus Breakdown{" "}
          </button>
        </div>
        <nav aria-label="breadcrumb"></nav>
      </div>

      <div className="col-lg-12 grid-margin stretch-card">
        <Formik
          initialValues={{
            store_id: storeId,
            quarter,
            year_1,
            year_2,
            // fieldStatus: "",
            foodSalesM1_2: bonusDataYear2 ? bonusDataYear2.foodSalesM1 : "",
            foodSalesM1P_2: bonusDataYear2 ? bonusDataYear2.foodSalesM1P : "",
            foodSalesM2_2: bonusDataYear2 ? bonusDataYear2.foodSalesM2 : "",
            foodSalesM2P_2: bonusDataYear2 ? bonusDataYear2.foodSalesM2P : "",
            foodSalesM3_2: bonusDataYear2 ? bonusDataYear2.foodSalesM3 : "",
            foodSalesM3P_2: bonusDataYear2 ? bonusDataYear2.foodSalesM3P : "",
            foodSalesTotal_2: bonusDataYear2
              ? bonusDataYear2.foodSalesTotal
              : "",
            foodSalesTotalP_2: bonusDataYear2
              ? bonusDataYear2.foodSalesTotalP
              : "",

            foodSalesM1_1: bonusDataYear1 ? bonusDataYear1.foodSalesM1 : "",
            foodSalesM1P_1: bonusDataYear1 ? bonusDataYear1.foodSalesM1P : "",
            foodSalesM2_1: bonusDataYear1 ? bonusDataYear1.foodSalesM2 : "",
            foodSalesM2P_1: bonusDataYear1 ? bonusDataYear1.foodSalesM2P : "",
            foodSalesM3_1: bonusDataYear1 ? bonusDataYear1.foodSalesM3 : "",
            foodSalesM3P_1: bonusDataYear1 ? bonusDataYear1.foodSalesM3P : "",
            foodSalesTotal_1: bonusDataYear2
              ? bonusDataYear2.foodSalesTotal
              : "",
            foodSalesTotalP_1: bonusDataYear2
              ? bonusDataYear2.foodSalesTotalP
              : "",

            foodSalesId: bonusIncrease ? bonusIncrease.foodSalesId : "",
            foodSalesP: bonusIncrease ? bonusIncrease.foodSalesP : "",

            liquorSalesM1_2: bonusDataYear2 ? bonusDataYear2.liquorSalesM1 : "",
            liquorSalesM1P_2: bonusDataYear2
              ? bonusDataYear2.liquorSalesM1P
              : "",
            liquorSalesM2_2: bonusDataYear2 ? bonusDataYear2.liquorSalesM2 : "",
            liquorSalesM2P_2: bonusDataYear2
              ? bonusDataYear2.liquorSalesM2P
              : "",
            liquorSalesM3_2: bonusDataYear2 ? bonusDataYear2.liquorSalesM3 : "",
            liquorSalesM3P_2: bonusDataYear2
              ? bonusDataYear2.liquorSalesM3P
              : "",
            liquorSalesTotal_2: bonusDataYear2
              ? bonusDataYear2.liquorSalesTotal
              : "",
            liquorSalesTotalP_2: bonusDataYear2
              ? bonusDataYear2.liquorSalesTotalP
              : "",

            liquorSalesM1_1: bonusDataYear1 ? bonusDataYear1.liquorSalesM1 : "",
            liquorSalesM1P_1: bonusDataYear1
              ? bonusDataYear1.liquorSalesM1P
              : "",
            liquorSalesM2_1: bonusDataYear1 ? bonusDataYear1.liquorSalesM2 : "",
            liquorSalesM2P_1: bonusDataYear1
              ? bonusDataYear1.liquorSalesM2P
              : "",
            liquorSalesM3_1: bonusDataYear1 ? bonusDataYear1.liquorSalesM3 : "",
            liquorSalesM3P_1: bonusDataYear1
              ? bonusDataYear1.liquorSalesM3P
              : "",
            liquorSalesTotal_1: bonusDataYear1
              ? bonusDataYear1.liquorSalesTotal
              : "",
            liquorSalesTotalP_1: bonusDataYear1
              ? bonusDataYear1.liquorSalesTotalP
              : "",

            liquorSalesId: bonusIncrease ? bonusIncrease.liquorSalesId : "",
            liquorSalesP: bonusIncrease ? bonusIncrease.liquorSalesP : "",

            totalSalesM1_2: bonusDataYear2 ? bonusDataYear2.totalSalesM1 : "",
            totalSalesM1P_2: bonusDataYear2 ? bonusDataYear2.totalSalesM1P : "",
            totalSalesM2_2: bonusDataYear2 ? bonusDataYear2.totalSalesM2 : "",
            totalSalesM2P_2: bonusDataYear2 ? bonusDataYear2.totalSalesM2P : "",
            totalSalesM3_2: bonusDataYear2 ? bonusDataYear2.totalSalesM3 : "",
            totalSalesM3P_2: bonusDataYear2 ? bonusDataYear2.totalSalesM3P : "",
            totalSalesTotal_2: bonusDataYear2
              ? bonusDataYear2.totalSalesTotal
              : "",
            totalSalesTotalP_2: bonusDataYear2
              ? bonusDataYear2.totalSalesTotalP
              : "",

            totalSalesM1_1: bonusDataYear1 ? bonusDataYear1.totalSalesM1 : "",
            totalSalesM1P_1: bonusDataYear1 ? bonusDataYear1.totalSalesM1P : "",
            totalSalesM2_1: bonusDataYear1 ? bonusDataYear1.totalSalesM2 : "",
            totalSalesM2P_1: bonusDataYear1 ? bonusDataYear1.totalSalesM2P : "",
            totalSalesM3_1: bonusDataYear1 ? bonusDataYear1.totalSalesM3 : "",
            totalSalesM3P_1: bonusDataYear1 ? bonusDataYear1.totalSalesM3P : "",
            totalSalesTotal_1: bonusDataYear1
              ? bonusDataYear1.totalSalesTotal
              : "",
            totalSalesTotalP_1: bonusDataYear1
              ? bonusDataYear1.totalSalesTotalP
              : "",
            totalSalesId: bonusIncrease ? bonusIncrease.totalSalesId : "",
            totalSalesP: bonusIncrease ? bonusIncrease.totalSalesP : "",

            foodCogsM1_2: bonusDataYear2 ? bonusDataYear2.foodCogsM1 : "",
            foodCogsM1P_2: bonusDataYear2 ? bonusDataYear2.foodCogsM1P : "",
            foodCogsM2_2: bonusDataYear2 ? bonusDataYear2.foodCogsM2 : "",
            foodCogsM2P_2: bonusDataYear2 ? bonusDataYear2.foodCogsM2P : "",
            foodCogsM3_2: bonusDataYear2 ? bonusDataYear2.foodCogsM3 : "",
            foodCogsM3P_2: bonusDataYear2 ? bonusDataYear2.foodCogsM3P : "",
            foodCogsTotal_2: bonusDataYear2 ? bonusDataYear2.foodCogsTotal : "",
            foodCogsTotalP_2: bonusDataYear2
              ? bonusDataYear2.foodCogsTotalP
              : "",
            foodCogsM1_1: bonusDataYear1 ? bonusDataYear1.foodCogsM1 : "",
            foodCogsM1P_1: bonusDataYear1 ? bonusDataYear1.foodCogsM1P : "",
            foodCogsM2_1: bonusDataYear1 ? bonusDataYear1.foodCogsM2 : "",
            foodCogsM2P_1: bonusDataYear1 ? bonusDataYear1.foodCogsM2P : "",
            foodCogsM3_1: bonusDataYear1 ? bonusDataYear1.foodCogsM3 : "",
            foodCogsM3P_1: bonusDataYear1 ? bonusDataYear1.foodCogsM3P : "",
            foodCogsTotal_1: bonusDataYear1 ? bonusDataYear1.foodCogsTotal : "",
            foodCogsTotalP_1: bonusDataYear1
              ? bonusDataYear1.foodCogsTotalP
              : "",
            foodCogsId: bonusIncrease ? bonusIncrease.foodCogsId : "",
            foodCogsP: bonusIncrease ? bonusIncrease.foodCogsP : "",

            liquorCogsM1_2: bonusDataYear2 ? bonusDataYear2.liquorCogsM1 : "",
            liquorCogsM1P_2: bonusDataYear2 ? bonusDataYear2.liquorCogsM1P : "",
            liquorCogsM2_2: bonusDataYear2 ? bonusDataYear2.liquorCogsM2 : "",
            liquorCogsM2P_2: bonusDataYear2 ? bonusDataYear2.liquorCogsM2P : "",
            liquorCogsM3_2: bonusDataYear2 ? bonusDataYear2.liquorCogsM3 : "",
            liquorCogsM3P_2: bonusDataYear2 ? bonusDataYear2.liquorCogsM3P : "",
            liquorCogsTotal_2: bonusDataYear2
              ? bonusDataYear2.liquorCogsTotal
              : "",
            liquorCogsTotalP_2: bonusDataYear2
              ? bonusDataYear2.liquorCogsTotalP
              : "",
            liquorCogsM1_1: bonusDataYear1 ? bonusDataYear1.liquorCogsM1 : "",
            liquorCogsM1P_1: bonusDataYear1 ? bonusDataYear1.liquorCogsM1P : "",
            liquorCogsM2_1: bonusDataYear1 ? bonusDataYear1.liquorCogsM2 : "",
            liquorCogsM2P_1: bonusDataYear1 ? bonusDataYear1.liquorCogsM2P : "",
            liquorCogsM3_1: bonusDataYear1 ? bonusDataYear1.liquorCogsM3 : "",
            liquorCogsM3P_1: bonusDataYear1 ? bonusDataYear1.liquorCogsM3P : "",
            liquorCogsTotal_1: bonusDataYear1
              ? bonusDataYear1.liquorCogsTotal
              : "",
            liquorCogsTotalP_1: bonusDataYear1
              ? bonusDataYear1.liquorCogsTotalP
              : "",
            liquorCogsId: bonusIncrease ? bonusIncrease.liquorCogsId : "",
            liquorCogsP: bonusIncrease ? bonusIncrease.liquorCogsP : "",

            suppliesCogsM1_2: bonusDataYear2
              ? bonusDataYear2.suppliesCogsM1
              : "",
            suppliesCogsM1P_2: bonusDataYear2
              ? bonusDataYear2.suppliesCogsM1P
              : "",
            suppliesCogsM2_2: bonusDataYear2
              ? bonusDataYear2.suppliesCogsM2
              : "",
            suppliesCogsM2P_2: bonusDataYear2
              ? bonusDataYear2.suppliesCogsM2P
              : "",
            suppliesCogsM3_2: bonusDataYear2
              ? bonusDataYear2.suppliesCogsM3
              : "",
            suppliesCogsM3P_2: bonusDataYear2
              ? bonusDataYear2.suppliesCogsM3P
              : "",
            suppliesCogsTotal_2: bonusDataYear2
              ? bonusDataYear2.suppliesCogsTotal
              : "",
            suppliesCogsTotalP_2: bonusDataYear2
              ? bonusDataYear2.suppliesCogsTotalP
              : "",

            suppliesCogsM1_1: bonusDataYear1
              ? bonusDataYear1.suppliesCogsM1
              : "",
            suppliesCogsM1P_1: bonusDataYear1
              ? bonusDataYear1.suppliesCogsM1P
              : "",
            suppliesCogsM2_1: bonusDataYear1
              ? bonusDataYear1.suppliesCogsM2
              : "",
            suppliesCogsM2P_1: bonusDataYear1
              ? bonusDataYear1.suppliesCogsM2P
              : "",
            suppliesCogsM3_1: bonusDataYear1
              ? bonusDataYear1.suppliesCogsM3
              : "",
            suppliesCogsM3P_1: bonusDataYear1
              ? bonusDataYear1.suppliesCogsM3P
              : "",
            suppliesCogsTotal_1: bonusDataYear1
              ? bonusDataYear1.suppliesCogsTotal
              : "",
            suppliesCogsTotalP_1: bonusDataYear1
              ? bonusDataYear1.suppliesCogsTotalP
              : "",

            suppliesCogsId: bonusIncrease ? bonusIncrease.suppliesCogsId : "",
            suppliesCogsP: bonusIncrease ? bonusIncrease.suppliesCogsP : "",

            totalCogsM1_2: bonusDataYear2 ? bonusDataYear2.totalCogsM1 : "",
            totalCogsM1P_2: bonusDataYear2 ? bonusDataYear2.totalCogsM1P : "",
            totalCogsM2_2: bonusDataYear2 ? bonusDataYear2.totalCogsM2 : "",
            totalCogsM2P_2: bonusDataYear2 ? bonusDataYear2.totalCogsM2P : "",
            totalCogsM3_2: bonusDataYear2 ? bonusDataYear2.totalCogsM3 : "",
            totalCogsM3P_2: bonusDataYear2 ? bonusDataYear2.totalCogsM3P : "",
            totalCogsTotal_2: bonusDataYear2
              ? bonusDataYear2.totalCogsTotal
              : "",
            totalCogsTotalP_2: bonusDataYear2
              ? bonusDataYear2.totalCogsTotalP
              : "",

            totalCogsM1_1: bonusDataYear1 ? bonusDataYear1.totalCogsM1 : "",
            totalCogsM1P_1: bonusDataYear1 ? bonusDataYear1.totalCogsM1P : "",
            totalCogsM2_1: bonusDataYear1 ? bonusDataYear1.totalCogsM2 : "",
            totalCogsM2P_1: bonusDataYear1 ? bonusDataYear1.totalCogsM2P : "",
            totalCogsM3_1: bonusDataYear1 ? bonusDataYear1.totalCogsM3 : "",
            totalCogsM3P_1: bonusDataYear1 ? bonusDataYear1.totalCogsM3P : "",
            totalCogsTotal_1: bonusDataYear1
              ? bonusDataYear1.totalCogsTotal
              : "",
            totalCogsTotalP_1: bonusDataYear1
              ? bonusDataYear1.totalCogsTotalP
              : "",

            totalCogsId: bonusIncrease ? bonusIncrease.totalCogsId : "",
            totalCogsP: bonusIncrease ? bonusIncrease.totalCogsP : "",

            grossProfitM1_2: bonusDataYear2 ? bonusDataYear2.grossProfitM1 : "",
            grossProfitM1P_2: bonusDataYear2
              ? bonusDataYear2.grossProfitM1P
              : "",
            grossProfitM2_2: bonusDataYear2 ? bonusDataYear2.grossProfitM2 : "",
            grossProfitM2P_2: bonusDataYear2
              ? bonusDataYear2.grossProfitM2P
              : "",
            grossProfitM3_2: bonusDataYear2 ? bonusDataYear2.grossProfitM3 : "",
            grossProfitM3P_2: bonusDataYear2
              ? bonusDataYear2.grossProfitM3P
              : "",
            grossProfitTotal_2: bonusDataYear2
              ? bonusDataYear2.grossProfitTotal
              : "",
            grossProfitTotalP_2: bonusDataYear2
              ? bonusDataYear2.grossProfitTotalP
              : "",

            grossProfitM1_1: bonusDataYear1 ? bonusDataYear1.grossProfitM1 : "",
            grossProfitM1P_1: bonusDataYear1
              ? bonusDataYear1.grossProfitM1P
              : "",
            grossProfitM2_1: bonusDataYear1 ? bonusDataYear1.grossProfitM2 : "",
            grossProfitM2P_1: bonusDataYear1
              ? bonusDataYear1.grossProfitM2P
              : "",
            grossProfitM3_1: bonusDataYear1 ? bonusDataYear1.grossProfitM3 : "",
            grossProfitM3P_1: bonusDataYear1
              ? bonusDataYear1.grossProfitM3P
              : "",
            grossProfitTotal_1: bonusDataYear1
              ? bonusDataYear1.grossProfitTotal
              : "",
            grossProfitTotalP_1: bonusDataYear1
              ? bonusDataYear1.grossProfitTotalP
              : "",

            payrollBohM1_2: bonusDataYear2 ? bonusDataYear2.payrollBohM1 : "",
            payrollBohM1P_2: bonusDataYear2 ? bonusDataYear2.payrollBohM1P : "",
            payrollBohM2_2: bonusDataYear2 ? bonusDataYear2.payrollBohM2 : "",
            payrollBohM2P_2: bonusDataYear2 ? bonusDataYear2.payrollBohM2P : "",
            payrollBohM3_2: bonusDataYear2 ? bonusDataYear2.payrollBohM3 : "",
            payrollBohM3P_2: bonusDataYear2 ? bonusDataYear2.payrollBohM3P : "",
            payrollBohTotal_2: bonusDataYear2
              ? bonusDataYear2.payrollBohTotal
              : "",
            payrollBohTotalP_2: bonusDataYear2
              ? bonusDataYear2.payrollBohTotalP
              : "",

            payrollBohM1_1: bonusDataYear1 ? bonusDataYear1.payrollBohM1 : "",
            payrollBohM1P_1: bonusDataYear1 ? bonusDataYear1.payrollBohM1P : "",
            payrollBohM2_1: bonusDataYear1 ? bonusDataYear1.payrollBohM2 : "",
            payrollBohM2P_1: bonusDataYear1 ? bonusDataYear1.payrollBohM2P : "",
            payrollBohM3_1: bonusDataYear1 ? bonusDataYear1.payrollBohM3 : "",
            payrollBohM3P_1: bonusDataYear1 ? bonusDataYear1.payrollBohM3P : "",
            payrollBohTotal_1: bonusDataYear1
              ? bonusDataYear1.payrollBohTotal
              : "",
            payrollBohTotalP_1: bonusDataYear1
              ? bonusDataYear1.payrollBohTotalP
              : "",
            labourBohId: bonusIncrease ? bonusIncrease.labourBohId : "",
            labourBohP: bonusIncrease ? bonusIncrease.labourBohP : "",

            payrollFohM1_2: bonusDataYear2 ? bonusDataYear2.payrollFohM1 : "",
            payrollFohM1P_2: bonusDataYear2 ? bonusDataYear2.payrollFohM1P : "",
            payrollFohM2_2: bonusDataYear2 ? bonusDataYear2.payrollFohM2 : "",
            payrollFohM2P_2: bonusDataYear2 ? bonusDataYear2.payrollFohM2P : "",
            payrollFohM3_2: bonusDataYear2 ? bonusDataYear2.payrollFohM3 : "",
            payrollFohM3P_2: bonusDataYear2 ? bonusDataYear2.payrollFohM3P : "",
            payrollFohTotal_2: bonusDataYear2
              ? bonusDataYear2.payrollFohTotal
              : "",
            payrollFohTotalP_2: bonusDataYear2
              ? bonusDataYear2.payrollFohTotalP
              : "",

            payrollFohM1_1: bonusDataYear1 ? bonusDataYear1.payrollFohM1 : "",
            payrollFohM1P_1: bonusDataYear1 ? bonusDataYear1.payrollFohM1P : "",
            payrollFohM2_1: bonusDataYear1 ? bonusDataYear1.payrollFohM2 : "",
            payrollFohM2P_1: bonusDataYear1 ? bonusDataYear1.payrollFohM2P : "",
            payrollFohM3_1: bonusDataYear1 ? bonusDataYear1.payrollFohM3 : "",
            payrollFohM3P_1: bonusDataYear1 ? bonusDataYear1.payrollFohM3P : "",
            payrollFohTotal_1: bonusDataYear1
              ? bonusDataYear1.payrollFohTotal
              : "",
            payrollFohTotalP_1: bonusDataYear1
              ? bonusDataYear1.payrollFohTotalP
              : "",

            payrollM1_1: bonusDataYear1 ? bonusDataYear1.payrollM1 : "",
            payrollM1P_1: bonusDataYear1 ? bonusDataYear1.payrollM1P : "",
            payrollM2_1: bonusDataYear1 ? bonusDataYear1.payrollM2 : "",
            payrollM2P_1: bonusDataYear1 ? bonusDataYear1.payrollM2P : "",
            payrollM3_1: bonusDataYear1 ? bonusDataYear1.payrollM3 : "",
            payrollM3P_1: bonusDataYear1 ? bonusDataYear1.payrollM3P : "",
            payrollTotal_1: bonusDataYear1
              ? bonusDataYear1.payrollFohTotal
              : "",
            payrollTotalP_1: bonusDataYear1 ? bonusDataYear1.payrollTotalP : "",

            labourFohId: bonusIncrease ? bonusIncrease.labourFohId : "",
            labourFohP: bonusIncrease ? bonusIncrease.labourFohP : "",

            cleaningM1_2: bonusDataYear2 ? bonusDataYear2.cleaningM1 : "",
            cleaningM1P_2: bonusDataYear2 ? bonusDataYear2.cleaningM1P : "",
            cleaningM2_2: bonusDataYear2 ? bonusDataYear2.cleaningM2 : "",
            cleaningM2P_2: bonusDataYear2 ? bonusDataYear2.cleaningM2P : "",
            cleaningM3_2: bonusDataYear2 ? bonusDataYear2.cleaningM3 : "",
            cleaningM3P_2: bonusDataYear2 ? bonusDataYear2.cleaningM3P : "",
            cleaningTotal_2: bonusDataYear2 ? bonusDataYear2.cleaningTotal : "",
            cleaningTotalP_2: bonusDataYear2
              ? bonusDataYear2.cleaningTotalP
              : "",

            creditCardM1: bonusDataYear1 ? bonusDataYear1.creditCardM1 : "",
            creditCardM1P: bonusDataYear1 ? bonusDataYear1.creditCardM1P : "",
            creditCardM2: bonusDataYear1 ? bonusDataYear1.creditCardM2 : "",
            creditCardM2P: bonusDataYear1 ? bonusDataYear1.creditCardM2P : "",
            creditCardM3: bonusDataYear1 ? bonusDataYear1.creditCardM3 : "",
            creditCardM3P: bonusDataYear1 ? bonusDataYear1.creditCardM3P : "",
            creditCardTotal: bonusDataYear1
              ? bonusDataYear1.creditCardTotal
              : "",
            creditCardTotalP: bonusDataYear1
              ? bonusDataYear1.creditCardTotalP
              : "",

            depreciationM1: bonusDataYear1 ? bonusDataYear1.depreciationM1 : "",
            depreciationM1P: bonusDataYear1
              ? bonusDataYear1.depreciationM1P
              : "",
            depreciationM2: bonusDataYear1 ? bonusDataYear1.depreciationM2 : "",
            depreciationM2P: bonusDataYear1
              ? bonusDataYear1.depreciationM2P
              : "",
            depreciationM3: bonusDataYear1 ? bonusDataYear1.depreciationM3 : "",
            depreciationM3P: bonusDataYear1
              ? bonusDataYear1.depreciationM3P
              : "",
            depreciationTotal: bonusDataYear1
              ? bonusDataYear1.depreciationTotal
              : "",
            depreciationTotalP: bonusDataYear1
              ? bonusDataYear1.depreciationTotal
              : "",

            foodDeliveryM1: bonusDataYear1 ? bonusDataYear1.foodDeliveryM1 : "",
            foodDeliveryM1P: bonusDataYear1
              ? bonusDataYear1.foodDeliveryM1P
              : "",
            foodDeliveryM2: bonusDataYear1 ? bonusDataYear1.foodDeliveryM2 : "",
            foodDeliveryM2P: bonusDataYear1
              ? bonusDataYear1.foodDeliveryM2P
              : "",
            foodDeliveryM3: bonusDataYear1 ? bonusDataYear1.foodDeliveryM3 : "",
            foodDeliveryM3P: bonusDataYear1
              ? bonusDataYear1.foodDeliveryM3P
              : "",
            foodDeliveryTotal: bonusDataYear1
              ? bonusDataYear1.foodDeliveryTotal
              : "",
            foodDeliveryTotalP: bonusDataYear1
              ? bonusDataYear1.foodDeliveryTotalP
              : "",

            cleaningM1_2: bonusDataYear2 ? bonusDataYear2.cleaningM1 : "",
            cleaningM1P_2: bonusDataYear2 ? bonusDataYear2.cleaningM1P : "",
            cleaningM2_2: bonusDataYear2 ? bonusDataYear2.cleaningM2 : "",
            cleaningM2P_2: bonusDataYear2 ? bonusDataYear2.cleaningM2P : "",
            cleaningM3_2: bonusDataYear2 ? bonusDataYear2.cleaningM3 : "",
            cleaningM3P_2: bonusDataYear2 ? bonusDataYear2.cleaningM3P : "",
            cleaningTotal_2: bonusDataYear2 ? bonusDataYear2.cleaningTotal : "",
            cleaningTotalP_2: bonusDataYear2
              ? bonusDataYear2.cleaningTotalP
              : "",

            shortageM1_2: bonusDataYear2 ? bonusDataYear2.shortageM1 : "",
            shortageM1P_2: bonusDataYear2 ? bonusDataYear2.shortageM1P : "",
            shortageM2_2: bonusDataYear2 ? bonusDataYear2.shortageM2 : "",
            shortageM2P_2: bonusDataYear2 ? bonusDataYear2.shortageM2P : "",
            shortageM3_2: bonusDataYear2 ? bonusDataYear2.shortageM3 : "",
            shortageM3P_2: bonusDataYear2 ? bonusDataYear2.shortageM3P : "",
            shortageTotal_2: bonusDataYear2 ? bonusDataYear2.shortageTotal : "",
            shortageTotalP_2: bonusDataYear2
              ? bonusDataYear2.shortageTotalP
              : "",

            shortageM1_1: bonusDataYear1 ? bonusDataYear1.shortageM1 : "",
            shortageM1P_1: bonusDataYear1 ? bonusDataYear1.shortageM1P : "",
            shortageM2_1: bonusDataYear1 ? bonusDataYear1.shortageM2 : "",
            shortageM2P_1: bonusDataYear1 ? bonusDataYear1.shortageM2P : "",
            shortageM3_1: bonusDataYear1 ? bonusDataYear1.shortageM3 : "",
            shortageM3P_1: bonusDataYear1 ? bonusDataYear1.shortageM3P : "",
            shortageTotal_1: bonusDataYear1 ? bonusDataYear1.shortageTotal : "",
            shortageTotalP_1: bonusDataYear1
              ? bonusDataYear1.shortageTotalP
              : "",

            rentM1_2: bonusDataYear2 ? bonusDataYear2.rentM1 : "",
            rentM1P_2: bonusDataYear2 ? bonusDataYear2.rentM1P : "",
            rentM2_2: bonusDataYear2 ? bonusDataYear2.rentM2 : "",
            rentM2P_2: bonusDataYear2 ? bonusDataYear2.rentM2P : "",
            rentM3_2: bonusDataYear2 ? bonusDataYear2.rentM3 : "",
            rentM3P_2: bonusDataYear2 ? bonusDataYear2.rentM3P : "",
            rentTotal_2: bonusDataYear2 ? bonusDataYear2.rentTotal : "",
            rentTotalP_2: bonusDataYear2 ? bonusDataYear2.rentTotalP : "",

            rentM1_1: bonusDataYear1 ? bonusDataYear1.rentM1 : "",
            rentM1P_1: bonusDataYear1 ? bonusDataYear1.rentM1P : "",
            rentM2_1: bonusDataYear1 ? bonusDataYear1.rentM2 : "",
            rentM2P_1: bonusDataYear1 ? bonusDataYear1.rentM2P : "",
            rentM3_1: bonusDataYear1 ? bonusDataYear1.rentM3 : "",
            rentM3P_1: bonusDataYear1 ? bonusDataYear1.rentM3P : "",
            rentTotal_1: bonusDataYear1 ? bonusDataYear1.rentTotal : "",
            rentTotalP_1: bonusDataYear1 ? bonusDataYear1.rentTotalP : "",

            repairM1_2: bonusDataYear2 ? bonusDataYear2.repairM1 : "",
            repairM1P_2: bonusDataYear2 ? bonusDataYear2.repairM1P : "",
            repairM2_2: bonusDataYear2 ? bonusDataYear2.repairM2 : "",
            repairM2P_2: bonusDataYear2 ? bonusDataYear2.repairM2P : "",
            repairM3_2: bonusDataYear2 ? bonusDataYear2.repairM3 : "",
            repairM3P_2: bonusDataYear2 ? bonusDataYear2.repairM3P : "",
            repairTotal_2: bonusDataYear2 ? bonusDataYear2.repairTotal : "",
            repairTotalP_2: bonusDataYear2 ? bonusDataYear2.repairTotalP : "",

            repairM1_1: bonusDataYear1 ? bonusDataYear1.repairM1 : "",
            repairM1P_1: bonusDataYear1 ? bonusDataYear1.repairM1P : "",
            repairM2_1: bonusDataYear1 ? bonusDataYear1.repairM2 : "",
            repairM2P_1: bonusDataYear1 ? bonusDataYear1.repairM2P : "",
            repairM3_1: bonusDataYear1 ? bonusDataYear1.repairM3 : "",
            repairM3P_1: bonusDataYear1 ? bonusDataYear1.repairM3P : "",
            repairTotal_1: bonusDataYear1 ? bonusDataYear1.repairTotal : "",
            repairTotalP_1: bonusDataYear1 ? bonusDataYear1.repairTotalP : "",

            uniformsM1_2: bonusDataYear2 ? bonusDataYear2.uniformsM1 : "",
            uniformsM1P_2: bonusDataYear2 ? bonusDataYear2.uniformsM1P : "",
            uniformsM2_2: bonusDataYear2 ? bonusDataYear2.uniformsM2 : "",
            uniformsM2P_2: bonusDataYear2 ? bonusDataYear2.uniformsM2P : "",
            uniformsM3_2: bonusDataYear2 ? bonusDataYear2.uniformsM3 : "",
            uniformsM3P_2: bonusDataYear2 ? bonusDataYear2.uniformsM3P : "",
            uniformsTotal_2: bonusDataYear2 ? bonusDataYear2.uniformsTotal : "",
            uniformsTotalP_2: bonusDataYear2
              ? bonusDataYear2.uniformsTotalP
              : "",
            uniformsM1_1: bonusDataYear1 ? bonusDataYear1.uniformsM1 : "",
            uniformsM1P_1: bonusDataYear1 ? bonusDataYear1.uniformsM1P : "",
            uniformsM2_1: bonusDataYear1 ? bonusDataYear1.uniformsM2 : "",
            uniformsM2P_1: bonusDataYear1 ? bonusDataYear1.uniformsM2P : "",
            uniformsM3_1: bonusDataYear1 ? bonusDataYear1.uniformsM3 : "",
            uniformsM3P_1: bonusDataYear1 ? bonusDataYear1.uniformsM3P : "",
            uniformsTotal_1: bonusDataYear1 ? bonusDataYear1.uniformsTotal : "",
            uniformsTotalP_1: bonusDataYear1
              ? bonusDataYear1.uniformsTotalP
              : "",

            utilitiesM1_2: bonusDataYear2 ? bonusDataYear2.utilitiesM1 : "",
            utilitiesM1P_2: bonusDataYear2 ? bonusDataYear2.utilitiesM1P : "",
            utilitiesM2_2: bonusDataYear2 ? bonusDataYear2.utilitiesM2 : "",
            utilitiesM2P_2: bonusDataYear2 ? bonusDataYear2.utilitiesM2P : "",
            utilitiesM3_2: bonusDataYear2 ? bonusDataYear2.utilitiesM3 : "",
            utilitiesM3P_2: bonusDataYear2 ? bonusDataYear2.utilitiesM3P : "",
            utilitiesTotal_2: bonusDataYear2
              ? bonusDataYear2.utilitiesTotal
              : "",
            utilitiesTotalP_2: bonusDataYear2
              ? bonusDataYear2.utilitiesTotalP
              : "",
            utilitiesM1_1: bonusDataYear1 ? bonusDataYear1.utilitiesM1 : "",
            utilitiesM1P_1: bonusDataYear1 ? bonusDataYear1.utilitiesM1P : "",
            utilitiesM2_1: bonusDataYear1 ? bonusDataYear1.utilitiesM2 : "",
            utilitiesM2P_1: bonusDataYear1 ? bonusDataYear1.utilitiesM2P : "",
            utilitiesM3_1: bonusDataYear1 ? bonusDataYear1.utilitiesM3 : "",
            utilitiesM3P_1: bonusDataYear1 ? bonusDataYear1.utilitiesM3P : "",
            utilitiesTotal_1: bonusDataYear1
              ? bonusDataYear1.utilitiesTotal
              : "",
            utilitiesTotalP_1: bonusDataYear1
              ? bonusDataYear1.utilitiesTotalP
              : "",

            totalOperatingM1_2: bonusDataYear2
              ? bonusDataYear2.totalOperatingM1
              : "",
            totalOperatingM1P_2: bonusDataYear2
              ? bonusDataYear2.totalOperatingM1P
              : "",
            totalOperatingM2_2: bonusDataYear2
              ? bonusDataYear2.totalOperatingM2
              : "",
            totalOperatingM2P_2: bonusDataYear2
              ? bonusDataYear2.totalOperatingM2P
              : "",
            totalOperatingM3_2: bonusDataYear2
              ? bonusDataYear2.totalOperatingM3
              : "",
            totalOperatingM3P_2: bonusDataYear2
              ? bonusDataYear2.totalOperatingM3P
              : "",
            totalOperatingTotal_2: bonusDataYear2
              ? bonusDataYear2.totalOperatingTotal
              : "",
            totalOperatingTotalP_2: bonusDataYear2
              ? bonusDataYear2.totalOperatingTotalP
              : "",

            totalOperatingM1_1: bonusDataYear1
              ? bonusDataYear1.totalOperatingM1
              : "",
            totalOperatingM1P_1: bonusDataYear1
              ? bonusDataYear1.totalOperatingM1P
              : "",
            totalOperatingM2_1: bonusDataYear1
              ? bonusDataYear1.totalOperatingM2
              : "",
            totalOperatingM2P_1: bonusDataYear1
              ? bonusDataYear1.totalOperatingM2P
              : "",
            totalOperatingM3_1: bonusDataYear1
              ? bonusDataYear1.totalOperatingM3
              : "",
            totalOperatingM3P_1: bonusDataYear1
              ? bonusDataYear1.totalOperatingM3P
              : "",
            totalOperatingTotal_1: bonusDataYear1
              ? bonusDataYear1.totalOperatingTotal
              : "",
            totalOperatingTotalP_1: bonusDataYear1
              ? bonusDataYear1.totalOperatingTotalP
              : "",

            payrollM1_2: bonusDataYear2 ? bonusDataYear2.payrollM1 : "",
            payrollM1P_2: bonusDataYear2 ? bonusDataYear2.payrollM1P : "",
            payrollM2_2: bonusDataYear2 ? bonusDataYear2.payrollM2 : "",
            payrollM2P_2: bonusDataYear2 ? bonusDataYear2.payrollM2P : "",
            payrollM3_2: bonusDataYear2 ? bonusDataYear2.payrollM3 : "",
            payrollM3P_2: bonusDataYear2 ? bonusDataYear2.payrollM3P : "",
            payrollTotal_2: bonusDataYear2 ? bonusDataYear2.payrollTotal : "",
            payrollTotalP_2: bonusDataYear2 ? bonusDataYear2.payrollTotalP : "",

            payrollM1_1: bonusDataYear1 ? bonusDataYear1.payrollM1 : "",
            payrollM1P_1: bonusDataYear1 ? bonusDataYear1.payrollM1P : "",
            payrollM2_1: bonusDataYear1 ? bonusDataYear1.payrollM2 : "",
            payrollM2P_1: bonusDataYear1 ? bonusDataYear1.payrollM2P : "",
            payrollM3_1: bonusDataYear1 ? bonusDataYear1.payrollM3 : "",
            payrollM3P_1: bonusDataYear1 ? bonusDataYear1.payrollM3P : "",
            payrollTotal_1: bonusDataYear1 ? bonusDataYear1.payrollTotal : "",
            payrollTotalP_1: bonusDataYear1 ? bonusDataYear1.payrollTotalP : "",

            overheadsM1_2: bonusDataYear2 ? bonusDataYear2.overheadsM1 : "",
            overheadsM1P_2: bonusDataYear2 ? bonusDataYear2.overheadsM1P : "",
            overheadsM2_2: bonusDataYear2 ? bonusDataYear2.overheadsM2 : "",
            overheadsM2P_2: bonusDataYear2 ? bonusDataYear2.overheadsM2P : "",
            overheadsM3_2: bonusDataYear2 ? bonusDataYear2.overheadsM3 : "",
            overheadsM3P_2: bonusDataYear2 ? bonusDataYear2.overheadsM3P : "",
            overheadsTotal_2: bonusDataYear2
              ? bonusDataYear2.overheadsTotal
              : "",
            overheadsTotalP_2: bonusDataYear2
              ? bonusDataYear2.overheadsTotalP
              : "",

            overheadsM1_1: bonusDataYear1 ? bonusDataYear1.overheadsM1 : "",
            overheadsM1P_1: bonusDataYear1 ? bonusDataYear1.overheadsM1P : "",
            overheadsM2_1: bonusDataYear1 ? bonusDataYear1.overheadsM2 : "",
            overheadsM2P_1: bonusDataYear1 ? bonusDataYear1.overheadsM2P : "",
            overheadsM3_1: bonusDataYear1 ? bonusDataYear1.overheadsM3 : "",
            overheadsM3P_1: bonusDataYear1 ? bonusDataYear1.overheadsM3P : "",
            overheadsTotal_1: bonusDataYear1
              ? bonusDataYear1.overheadsTotal
              : "",
            overheadsTotalP_1: bonusDataYear1
              ? bonusDataYear1.overheadsTotalP
              : "",

            totalAdminM1_2: bonusDataYear2 ? bonusDataYear2.totalAdminM1 : "",
            totalAdminM1P_2: bonusDataYear2 ? bonusDataYear2.totalAdminM1P : "",
            totalAdminM2_2: bonusDataYear2 ? bonusDataYear2.totalAdminM2 : "",
            totalAdminM2P_2: bonusDataYear2 ? bonusDataYear2.totalAdminM2P : "",
            totalAdminM3_2: bonusDataYear2 ? bonusDataYear2.totalAdminM3 : "",
            totalAdminM3P_2: bonusDataYear2 ? bonusDataYear2.totalAdminM3P : "",
            totalAdminTotal_2: bonusDataYear2
              ? bonusDataYear2.totalAdminTotal
              : "",
            totalAdminTotalP_2: bonusDataYear2
              ? bonusDataYear2.totalAdminTotalP
              : "",

            totalAdminM1_1: bonusDataYear1 ? bonusDataYear1.totalAdminM1 : "",
            totalAdminM1P_1: bonusDataYear1 ? bonusDataYear1.totalAdminM1P : "",
            totalAdminM2_1: bonusDataYear1 ? bonusDataYear1.totalAdminM2 : "",
            totalAdminM2P_1: bonusDataYear1 ? bonusDataYear1.totalAdminM2P : "",
            totalAdminM3_1: bonusDataYear1 ? bonusDataYear1.totalAdminM3 : "",
            totalAdminM3P_1: bonusDataYear1 ? bonusDataYear1.totalAdminM3P : "",
            totalAdminTotal_1: bonusDataYear1
              ? bonusDataYear1.totalAdminTotal
              : "",
            totalAdminTotalP_1: bonusDataYear1
              ? bonusDataYear1.totalAdminTotalP
              : "",

            totalExpensesM1_2: bonusDataYear2
              ? bonusDataYear2.totalExpensesM1
              : "",
            totalExpensesM1P_2: bonusDataYear2
              ? bonusDataYear2.totalExpensesM1P
              : "",
            totalExpensesM2_2: bonusDataYear2
              ? bonusDataYear2.totalExpensesM2
              : "",
            totalExpensesM2P_2: bonusDataYear2
              ? bonusDataYear2.totalExpensesM2P
              : "",
            totalExpensesM3_2: bonusDataYear2
              ? bonusDataYear2.totalExpensesM3
              : "",
            totalExpensesM3P_2: bonusDataYear2
              ? bonusDataYear2.totalExpensesM3P
              : "",
            totalExpensesTotal_2: bonusDataYear2
              ? bonusDataYear2.totalExpensesTotal
              : "",
            totalExpensesTotalP_2: bonusDataYear2
              ? bonusDataYear2.totalExpensesTotalP
              : "",

            totalExpensesM1_1: bonusDataYear1
              ? bonusDataYear1.totalExpensesM1
              : "",
            totalExpensesM1P_1: bonusDataYear1
              ? bonusDataYear1.totalExpensesM1P
              : "",
            totalExpensesM2_1: bonusDataYear1
              ? bonusDataYear1.totalExpensesM2
              : "",
            totalExpensesM2P_1: bonusDataYear1
              ? bonusDataYear1.totalExpensesM2P
              : "",
            totalExpensesM3_1: bonusDataYear1
              ? bonusDataYear1.totalExpensesM3
              : "",
            totalExpensesM3P_1: bonusDataYear1
              ? bonusDataYear1.totalExpensesM3P
              : "",
            totalExpensesTotal_1: bonusDataYear1
              ? bonusDataYear1.totalExpensesTotal
              : "",
            totalExpensesTotalP_1: bonusDataYear1
              ? bonusDataYear1.totalExpensesTotalP
              : "",

            incomeM1_2: bonusDataYear2 ? bonusDataYear2.incomeM1 : "",
            incomeM1P_2: bonusDataYear2 ? bonusDataYear2.incomeM1P : "",
            incomeM2_2: bonusDataYear2 ? bonusDataYear2.incomeM2 : "",
            incomeM2P_2: bonusDataYear2 ? bonusDataYear2.incomeM2P : "",
            incomeM3_2: bonusDataYear2 ? bonusDataYear2.incomeM3 : "",
            incomeM3P_2: bonusDataYear2 ? bonusDataYear2.incomeM3P : "",
            incomeTotal_2: bonusDataYear2 ? bonusDataYear2.incomeTotal : "",
            incomeTotalP_2: bonusDataYear2 ? bonusDataYear2.incomeTotalP : "",

            incomeM1_1: bonusDataYear1 ? bonusDataYear1.incomeM1 : "",
            incomeM1P_1: bonusDataYear1 ? bonusDataYear1.incomeM1P : "",
            incomeM2_1: bonusDataYear1 ? bonusDataYear1.incomeM2 : "",
            incomeM2P_1: bonusDataYear1 ? bonusDataYear1.incomeM2P : "",
            incomeM3_1: bonusDataYear1 ? bonusDataYear1.incomeM3 : "",
            incomeM3P_1: bonusDataYear1 ? bonusDataYear1.incomeM3P : "",
            incomeTotal_1: bonusDataYear1 ? bonusDataYear1.incomeTotal : "",
            incomeTotalP_1: bonusDataYear1 ? bonusDataYear1.incomeTotalP : "",

            admonFeeM1_2: bonusDataYear2 ? bonusDataYear2.admonFeeM1 : "",
            admonFeeM1P_2: bonusDataYear2 ? bonusDataYear2.admonFeeM1P : "",
            admonFeeM2_2: bonusDataYear2 ? bonusDataYear2.admonFeeM2 : "",
            admonFeeM2P_2: bonusDataYear2 ? bonusDataYear2.admonFeeM2P : "",
            admonFeeM3_2: bonusDataYear2 ? bonusDataYear2.admonFeeM3 : "",
            admonFeeM3P_2: bonusDataYear2 ? bonusDataYear2.admonFeeM3P : "",
            admonFeeTotal_2: bonusDataYear2 ? bonusDataYear2.admonFeeTotal : "",
            admonFeeTotalP_2: bonusDataYear2
              ? bonusDataYear2.admonFeeTotalP
              : "",

            admonFeeM1_1: bonusDataYear1 ? bonusDataYear1.admonFeeM1 : "",
            admonFeeM1P_1: bonusDataYear1 ? bonusDataYear1.admonFeeM1P : "",
            admonFeeM2_1: bonusDataYear1 ? bonusDataYear1.admonFeeM2 : "",
            admonFeeM2P_1: bonusDataYear1 ? bonusDataYear1.admonFeeM2P : "",
            admonFeeM3_1: bonusDataYear1 ? bonusDataYear1.admonFeeM3 : "",
            admonFeeM3P_1: bonusDataYear1 ? bonusDataYear1.admonFeeM3P : "",
            admonFeeTotal_1: bonusDataYear1 ? bonusDataYear1.admonFeeTotal : "",
            admonFeeTotalP_1: bonusDataYear1
              ? bonusDataYear1.admonFeeTotalP
              : "",

            interestM1_2: bonusDataYear2 ? bonusDataYear2.interestM1 : "",
            interestM1P_2: bonusDataYear2 ? bonusDataYear2.interestM1P : "",
            interestM2_2: bonusDataYear2 ? bonusDataYear2.interestM2 : "",
            interestM2P_2: bonusDataYear2 ? bonusDataYear2.interestM2P : "",
            interestM3_2: bonusDataYear2 ? bonusDataYear2.interestM3 : "",
            interestM3P_2: bonusDataYear2 ? bonusDataYear2.interestM3P : "",
            interestTotal_2: bonusDataYear2 ? bonusDataYear2.interestTotal : "",
            interestTotalP_2: bonusDataYear2
              ? bonusDataYear2.interestTotalP
              : "",

            interestM1_1: bonusDataYear1 ? bonusDataYear1.interestM1 : "",
            interestM1P_1: bonusDataYear1 ? bonusDataYear1.interestM1P : "",
            interestM2_1: bonusDataYear1 ? bonusDataYear1.interestM2 : "",
            interestM2P_1: bonusDataYear1 ? bonusDataYear1.interestM2P : "",
            interestM3_1: bonusDataYear1 ? bonusDataYear1.interestM3 : "",
            interestM3P_1: bonusDataYear1 ? bonusDataYear1.interestM3P : "",
            interestTotal_1: bonusDataYear1 ? bonusDataYear1.interestTotal : "",
            interestTotalP_1: bonusDataYear1
              ? bonusDataYear1.interestTotalP
              : "",

            subTotalM1_2: bonusDataYear2 ? bonusDataYear2.subTotalM1 : "",
            subTotalM1P_2: bonusDataYear2 ? bonusDataYear2.subTotalM1P : "",
            subTotalM2_2: bonusDataYear2 ? bonusDataYear2.subTotalM2 : "",
            subTotalM2P_2: bonusDataYear2 ? bonusDataYear2.subTotalM2P : "",
            subTotalM3_2: bonusDataYear2 ? bonusDataYear2.subTotalM3 : "",
            subTotalM3P_2: bonusDataYear2 ? bonusDataYear2.subTotalM3P : "",
            subTotalTotal_2: bonusDataYear2 ? bonusDataYear2.subTotalTotal : "",
            subTotalTotalP_2: bonusDataYear2
              ? bonusDataYear2.subTotalTotalP
              : "",

            subTotalM1_1: bonusDataYear1 ? bonusDataYear1.subTotalM1 : "",
            subTotalM1P_1: bonusDataYear1 ? bonusDataYear1.subTotalM1P : "",
            subTotalM2_1: bonusDataYear1 ? bonusDataYear1.subTotalM2 : "",
            subTotalM2P_1: bonusDataYear1 ? bonusDataYear1.subTotalM2P : "",
            subTotalM3_1: bonusDataYear1 ? bonusDataYear1.subTotalM3 : "",
            subTotalM3P_1: bonusDataYear1 ? bonusDataYear1.subTotalM3P : "",
            subTotalTotal_1: bonusDataYear1 ? bonusDataYear1.subTotalTotal : "",
            subTotalTotalP_1: bonusDataYear1
              ? bonusDataYear1.subTotalTotalP
              : "",

            otherIncomeM1_2: bonusDataYear2 ? bonusDataYear2.otherIncomeM1 : "",
            otherIncomeM1P_2: bonusDataYear2
              ? bonusDataYear2.otherIncomeM1P
              : "",
            otherIncomeM2_2: bonusDataYear2 ? bonusDataYear2.otherIncomeM2 : "",
            otherIncomeM2P_2: bonusDataYear2
              ? bonusDataYear2.otherIncomeM2P
              : "",
            otherIncomeM3_2: bonusDataYear2 ? bonusDataYear2.otherIncomeM3 : "",
            otherIncomeM3P_2: bonusDataYear2
              ? bonusDataYear2.otherIncomeM3P
              : "",
            otherIncomeTotal_2: bonusDataYear2
              ? bonusDataYear2.otherIncomeTotal
              : "",
            otherIncomeTotalP_2: bonusDataYear2
              ? bonusDataYear2.otherIncomeTotalP
              : "",

            otherIncomeM1_1: bonusDataYear1 ? bonusDataYear1.otherIncomeM1 : "",
            otherIncomeM1P_1: bonusDataYear1
              ? bonusDataYear1.otherIncomeM1P
              : "",
            otherIncomeM2_1: bonusDataYear1 ? bonusDataYear1.otherIncomeM2 : "",
            otherIncomeM2P_1: bonusDataYear1
              ? bonusDataYear1.otherIncomeM2P
              : "",
            otherIncomeM3_1: bonusDataYear1 ? bonusDataYear1.otherIncomeM3 : "",
            otherIncomeM3P_1: bonusDataYear1
              ? bonusDataYear1.otherIncomeM3P
              : "",
            otherIncomeTotal_1: bonusDataYear1
              ? bonusDataYear1.otherIncomeTotal
              : "",
            otherIncomeTotalP_1: bonusDataYear1
              ? bonusDataYear1.otherIncomeTotalP
              : "",

            netProfitM1_2: bonusDataYear2 ? bonusDataYear2.netProfitM1 : "",
            netProfitM1P_2: bonusDataYear2 ? bonusDataYear2.netProfitM1P : "",
            netProfitM2_2: bonusDataYear2 ? bonusDataYear2.netProfitM2 : "",
            netProfitM2P_2: bonusDataYear2 ? bonusDataYear2.netProfitM2P : "",
            netProfitM3_2: bonusDataYear2 ? bonusDataYear2.netProfitM3 : "",
            netProfitM3P_2: bonusDataYear2 ? bonusDataYear2.netProfitM3P : "",
            netProfitTotal_2: bonusDataYear2
              ? bonusDataYear2.netProfitTotal
              : "",
            netProfitTotalP_2: bonusDataYear2
              ? bonusDataYear2.netProfitTotalP
              : "",

            netProfitM1_1: bonusDataYear1 ? bonusDataYear1.netProfitM1 : "",
            netProfitM1P_1: bonusDataYear1 ? bonusDataYear1.netProfitM1P : "",
            netProfitM2_1: bonusDataYear1 ? bonusDataYear1.netProfitM2 : "",
            netProfitM2P_1: bonusDataYear1 ? bonusDataYear1.netProfitM2P : "",
            netProfitM3_1: bonusDataYear1 ? bonusDataYear1.netProfitM3 : "",
            netProfitM3P_1: bonusDataYear1 ? bonusDataYear1.netProfitM3P : "",
            netProfitTotal_1: bonusDataYear1
              ? bonusDataYear1.netProfitTotal
              : "",
            netProfitTotalP_1: bonusDataYear1
              ? bonusDataYear1.netProfitTotalP
              : "",

            netProfitId: bonusIncrease ? bonusIncrease.netProfitId : "",
            netProfitP: bonusIncrease ? bonusIncrease.netProfitP : "",
          }}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            BonusPayoutService.createBonus(values)
              .then((response) => {
                console.log(response.data.data);
                Swal.fire({
                  title: "Good Job!",
                  text: "All changes have been saved successfully",
                  icon: "success",
                  confirmButtonText: "Ok",
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    history.push("/bonus/view-select-bonus-payout");
                  }
                });
                // alert("Save Successfully");
                setSubmitting(false);
              })
              .catch((error) => {
                console.log(error);
                setSubmitting(false);
              });
            console.log(values, "The values from the form");
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form name="bonus-forms" onSubmit={handleSubmit}>
              <div className="table-responsive">
                <div>
                  <table className="table table-bordered">
                    {/* <col> */}
                    <colgroup span="2"></colgroup>
                    <colgroup span="2"></colgroup>
                    <tbody>
                      <tr>
                        <td
                          rowSpan="2"
                          style={{
                            fontWeight: "bold",
                            paddingRight: "5px",
                            paddingLeft: "5px",
                            textAlign: "center",
                          }}
                        >
                          Profit and Loss
                        </td>
                        <th
                          colSpan="2"
                          scope="colgroup"
                          className="text-center"
                        >
                          {m1}
                        </th>
                        <th
                          colSpan="2"
                          scope="colgroup"
                          className="text-center"
                        >
                          {m2}
                        </th>
                        <th
                          colSpan="2"
                          scope="colgroup"
                          className="text-center"
                        >
                          {m3}
                        </th>
                        <th
                          colSpan="4"
                          scope="colgroup"
                          className="text-center"
                        >
                          Total {"Q1"}
                        </th>
                        <th colSpan="2" scope="colgroup">
                          increased
                        </th>
                      </tr>
                      <tr>
                        <th scope="col">{2021}</th>
                        <th scope="col">{2022}</th>
                        <th scope="col">{2021}</th>
                        <th scope="col">{2022}</th>
                        <th scope="col">{2021}</th>
                        <th scope="col">{2022}</th>
                        <th scope="col">{2021}</th>
                        <th scope="col">%</th>
                        <th scope="col">{2022}</th>
                        <th scope="col">%</th>
                        <th scope="col">Decreased</th>
                        <th scope="col">%</th>
                      </tr>

                      <tr
                        style={{
                          background: "grey",
                          color: "white",
                          paddingLeft: "2px",
                        }}
                      >
                        <th
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          Sales
                        </th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <th scope="row">Food Sales</th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodSalesM1_1"
                            name="foodSalesM1_1"
                            defaultValue={
                              values.foodSalesM1_1 ? values.foodSalesM1_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodSalesM1_2"
                            name="foodSalesM1_2"
                            defaultValue={values.foodSalesM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodSalesM2_1"
                            name="foodSalesM2_1"
                            defaultValue={
                              values.foodSalesM2_1 ? values.foodSalesM2_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodSalesM2_2"
                            name="foodSalesM2_2"
                            defaultValue={values.foodSalesM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodSalesM3_1"
                            name="foodSalesM3_1"
                            defaultValue={
                              values.foodSalesM3_1 ? values.foodSalesM3_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodSalesM3_2"
                            name="foodSalesM3_2"
                            defaultValue={values.foodSalesM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodSalesTotal_1"
                            name="foodSalesTotal_1"
                            defaultValue={values.foodSalesTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodSalesTotalP_1"
                            name="foodSalesTotalP_1"
                            defaultValue={values.foodSalesTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodSalesTotal_2"
                            name="foodSalesTotal_2"
                            defaultValue={values.foodSalesTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodSalesTotalP_2"
                            name="foodSalesTotalP_2"
                            defaultValue={values.foodSalesTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodSalesId"
                            name="foodSalesId"
                            defaultValue={values.foodSalesId}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodSalesP"
                            name="foodSalesP"
                            defaultValue={values.foodSalesP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Liquor Sales</th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorSalesM1_1"
                            name="liquorSalesM1_1"
                            defaultValue={
                              values.liquorSalesM1_1
                                ? values.liquorSalesM1_1
                                : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorSalesM1_2"
                            name="liquorSalesM1_2"
                            defaultValue={values.liquorSalesM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorSalesM2_1"
                            name="liquorSalesM2_1"
                            defaultValue={
                              values.liquorSalesM2_1
                                ? values.liquorSalesM2_1
                                : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorSalesM2_2"
                            name="liquorSalesM2_2"
                            defaultValue={values.liquorSalesM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorSalesM3_1"
                            name="liquorSalesM3_1"
                            defaultValue={
                              values.liquorSalesM3_1
                                ? values.liquorSalesM3_1
                                : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorSalesM3_2"
                            name="liquorSalesM3_2"
                            defaultValue={values.liquorSalesM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorSalesTotal_1"
                            name="liquorSalesTotal_1"
                            defaultValue={values.liquorSalesTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorSalesTotalP_1"
                            name="liquorSalesTotalP_1"
                            defaultValue={values.liquorSalesTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorSalesTotal_2"
                            name="liquorSalesTotal_2"
                            defaultValue={values.liquorSalesTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorSalesTotalP_2"
                            name="liquorSalesTotalP_2"
                            defaultValue={values.liquorSalesTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorSalesId"
                            name="liquorSalesId"
                            defaultValue={values.liquorSalesId}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorSalesP"
                            name="liquorSalesP"
                            defaultValue={values.liquorSalesP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Total Sales</th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalSalesM1_1"
                            name="totalSalesM1_1"
                            defaultValue={values.totalSalesM1_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalSalesM1_2"
                            name="totalSalesM1_2"
                            defaultValue={values.totalSalesM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalSalesM2_1"
                            name="totalSalesM2_1"
                            defaultValue={values.totalSalesM2_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalSalesM2_2"
                            name="totalSalesM2_2"
                            defaultValue={values.totalSalesM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalSalesM3_1"
                            name="totalSalesM3_1"
                            defaultValue={values.totalSalesM3_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalSalesM3_2"
                            name="totalSalesM3_2"
                            defaultValue={values.totalSalesM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalSalesTotal_1"
                            name="totalSalesTotal_1"
                            defaultValue={values.totalSalesTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalSalesTotalP_1"
                            name="totalSalesTotalP_1"
                            defaultValue={values.totalSalesTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalSalesTotal_2"
                            name="totalSalesTotal_2"
                            defaultValue={values.totalSalesTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalSalesTotalP_2"
                            name="totalSalesTotalP_2"
                            defaultValue={values.totalSalesTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalSalesId"
                            name="totalSalesId"
                            defaultValue={values.totalSalesId}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalSalesP"
                            name="totalSalesP"
                            defaultValue={values.totalSalesP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* COGS 1 */}
                <div style={{ marginTop: "20px" }}>
                  <table className="table table-bordered">
                    <tbody>
                      <tr
                        style={{
                          background: "grey",
                          color: "white",
                          paddingLeft: "2px",
                        }}
                      >
                        <th
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          COGS
                        </th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <th scope="row">Food</th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodCogsM1_1"
                            name="foodCogsM1_1"
                            defaultValue={
                              values.foodCogsM1_1 ? values.foodCogsM1_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodCogsM1_2"
                            name="foodCogsM1_2"
                            defaultValue={values.foodCogsM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodCogsM2_1"
                            name="foodCogsM2_1"
                            defaultValue={
                              values.foodCogsM2_1 ? values.foodCogsM2_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodCogsM2_2"
                            name="foodCogsM2_2"
                            defaultValue={values.foodCogsM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodCogsM3_1"
                            name="foodCogsM3_1"
                            defaultValue={
                              values.foodCogsM3_1 ? values.foodCogsM3_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodCogsM3_2"
                            name="foodCogsM3_2"
                            defaultValue={values.foodCogsM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodCogsTotal_1"
                            name="foodCogsTotal_1"
                            defaultValue={values.foodCogsTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodCogsTotalP_1"
                            name="foodCogsTotalP_1"
                            defaultValue={values.foodCogsTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodCogsTotal_2"
                            name="foodCogsTotal_2"
                            defaultValue={values.foodCogsTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodCogsTotalP_2"
                            name="foodCogsTotalP_2"
                            defaultValue={values.foodCogsTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodCogsId"
                            name="foodCogsId"
                            defaultValue={values.foodCogsId}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodCogsP"
                            name="foodCogsP"
                            defaultValue={values.foodCogsP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Liquor</th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorCogsM1_1"
                            name="liquorCogsM1_1"
                            defaultValue={
                              values.liquorCogsM1_1 ? values.liquorCogsM1_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorCogsM1_2"
                            name="liquorCogsM1_2"
                            defaultValue={values.liquorCogsM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorCogsM2_1"
                            name="liquorCogsM2_1"
                            defaultValue={
                              values.liquorCogsM2_1 ? values.liquorCogsM2_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorCogsM2_2"
                            name="liquorCogsM2_2"
                            defaultValue={values.liquorCogsM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorCogsM3_1"
                            name="liquorCogsM3_1"
                            defaultValue={
                              values.liquorCogsM3_1 ? values.liquorCogsM3_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorCogsM3_2"
                            name="liquorCogsM3_2"
                            defaultValue={values.liquorCogsM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorCogsTotal_1"
                            name="liquorCogsTotal_1"
                            defaultValue={values.liquorCogsTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorCogsTotalP_1"
                            name="liquorCogsTotalP_1"
                            defaultValue={values.liquorCogsTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorCogsTotal_2"
                            name="liquorCogsTotal_2"
                            defaultValue={values.liquorCogsTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorCogsTotalP_2"
                            name="liquorCogsTotalP_2"
                            defaultValue={values.liquorCogsTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorCogsId"
                            name="liquorCogsId"
                            defaultValue={values.liquorCogsId}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="liquorCogsP"
                            name="liquorCogsP"
                            defaultValue={values.liquorCogsP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Supplies</th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="suppliesCogsM1_1"
                            name="suppliesCogsM1_1"
                            defaultValue={
                              values.suppliesCogsM1_1
                                ? values.suppliesCogsM1_1
                                : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="suppliesCogsM1_2"
                            name="suppliesCogsM1_2"
                            defaultValue={values.suppliesCogsM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="suppliesCogsM2_1"
                            name="suppliesCogsM2_1"
                            defaultValue={
                              values.suppliesCogsM2_1
                                ? values.suppliesCogsM2_1
                                : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="suppliesCogsM2_2"
                            name="suppliesCogsM2_2"
                            defaultValue={values.suppliesCogsM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="suppliesCogsM3_1"
                            name="suppliesCogsM3_1"
                            defaultValue={
                              values.suppliesCogsM3_1
                                ? values.suppliesCogsM3_1
                                : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="suppliesCogsM3_2"
                            name="suppliesCogsM3_2"
                            defaultValue={values.suppliesCogsM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="suppliesCogsTotal_1"
                            name="suppliesCogsTotal_1"
                            defaultValue={values.suppliesCogsTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="suppliesCogsTotalP_1"
                            name="suppliesCogsTotalP_1"
                            defaultValue={values.suppliesCogsTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="suppliesCogsTotal_2"
                            name="suppliesCogsTotal_2"
                            defaultValue={values.suppliesCogsTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="suppliesCogsTotalP_2"
                            name="suppliesCogsTotalP_2"
                            defaultValue={values.suppliesCogsTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="suppliesCogsId"
                            name="suppliesCogsId"
                            defaultValue={values.suppliesCogsId}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="suppliesCogsP"
                            name="suppliesCogsP"
                            defaultValue={values.suppliesCogsP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">Total COGS</th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalCogsM1_1"
                            name="totalCogsM1_1"
                            defaultValue={values.totalCogsM1_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalCogsM1_2"
                            name="totalCogsM1_2"
                            defaultValue={values.totalCogsM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalCogsM2_1"
                            name="totalCogsM2_1"
                            defaultValue={values.totalCogsM2_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalCogsM2_2"
                            name="totalCogsM2_2"
                            defaultValue={values.totalCogsM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalCogsM3_1"
                            name="totalCogsM3_1"
                            defaultValue={values.totalCogsM3_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalCogsM3_2"
                            name="totalCogsM3_2"
                            defaultValue={values.totalCogsM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalCogsTotal_1"
                            name="totalCogsTotal_1"
                            defaultValue={values.totalCogsTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalCogsTotalP_1"
                            name="totalCogsTotalP_1"
                            defaultValue={values.totalCogsTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalCogsTotal_2"
                            name="totalCogsTotal_2"
                            defaultValue={values.totalCogsTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalCogsTotalP_2"
                            name="totalCogsTotalP_2"
                            defaultValue={values.totalCogsTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalCogsId"
                            name="totalCogsId"
                            defaultValue={values.totalCogsId}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalCogsP"
                            name="totalCogsP"
                            defaultValue={values.totalCogsP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* Gross Profit */}
                <div style={{ marginTop: "20px" }}>
                  <table className="table table-bordered">
                    <tbody>
                      <tr
                        style={{
                          background: "black",
                          color: "white",
                          paddingLeft: "2px",
                        }}
                        className="dark-background"
                      >
                        <th
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            textAlign: "center",
                          }}
                        >
                          Gross Profit
                        </th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="grossProfitM1_1"
                            name="grossProfitM1_1"
                            defaultValue={values.grossProfitM1_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="grossProfitM1_2"
                            name="grossProfitM1_2"
                            defaultValue={values.grossProfitM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="grossProfitM2_1"
                            name="grossProfitM2_1"
                            defaultValue={values.grossProfitM2_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="grossProfitM2_2"
                            name="grossProfitM2_2"
                            defaultValue={values.grossProfitM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="grossProfitM3_1"
                            name="grossProfitM3_1"
                            defaultValue={values.grossProfitM3_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="grossProfitM3_2"
                            name="grossProfitM3_2"
                            defaultValue={values.grossProfitM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="grossProfitTotal_1"
                            name="grossProfitTotal_1"
                            defaultValue={values.grossProfitTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="grossProfitTotalP_1"
                            name="grossProfitTotalP_1"
                            defaultValue={values.grossProfitTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="grossProfitTotal_2"
                            name="grossProfitTotal_2"
                            defaultValue={values.grossProfitTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="grossProfitTotalP_2"
                            name="grossProfitTotalP_2"
                            defaultValue={values.grossProfitTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="grossProfitID"
                            name="grossProfitID"
                            defaultValue={values.grossProfitID}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="grossProfitP"
                            name="grossProfitP"
                            defaultValue={values.grossProfitP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* COGS 2 */}
                <div style={{ marginTop: "20px" }}>
                  <table className="table table-bordered">
                    <colgroup span="2"></colgroup>
                    <colgroup span="2"></colgroup>
                    <tbody>
                      <tr>
                        <td
                          rowSpan="2"
                          style={{
                            fontWeight: "bold",
                            paddingRight: "5px",
                            paddingLeft: "5px",
                            textAlign: "center",
                          }}
                        ></td>
                        <th
                          colSpan="2"
                          scope="colgroup"
                          className="text-center"
                        >
                          {m1}
                        </th>
                        <th
                          colSpan="2"
                          scope="colgroup"
                          className="text-center"
                        >
                          {m2}
                        </th>
                        <th
                          colSpan="2"
                          scope="colgroup"
                          className="text-center"
                        >
                          {m3}
                        </th>
                        <th
                          colSpan="4"
                          scope="colgroup"
                          className="text-center"
                        >
                          Total {currentQuarter}
                        </th>
                        <th colSpan="2" scope="colgroup">
                          increased
                        </th>
                      </tr>
                      <tr>
                        <th scope="col">{currentYear}</th>
                        <th scope="col">{nextYear}</th>
                        <th scope="col">{currentYear}</th>
                        <th scope="col">{nextYear}</th>
                        <th scope="col">{currentYear}</th>
                        <th scope="col">{nextYear}</th>
                        <th scope="col">{currentYear}</th>
                        <th scope="col">%</th>
                        <th scope="col">{nextYear}</th>
                        <th scope="col">%</th>
                        <th scope="col">Decreased</th>
                        <th scope="col">%</th>
                      </tr>
                      <tr
                        style={{
                          background: "grey",
                          color: "white",
                          paddingLeft: "2px",
                        }}
                      >
                        <th
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          EXPENSES
                        </th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <th scope="row">Payroll BOH</th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollBohCYQM1"
                            name="payrollBohCYQM1"
                            defaultValue={
                              values.payrollBohCYQM1
                                ? values.payrollBohCYQM1
                                : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollBohM1_2"
                            name="payrollBohM1_2"
                            defaultValue={values.payrollBohM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollBohCYQM2"
                            name="payrollBohCYQM2"
                            defaultValue={
                              values.payrollBohCYQM2
                                ? values.payrollBohCYQM2
                                : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollBohM2_2"
                            name="payrollBohM2_2"
                            defaultValue={values.payrollBohM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollBohCYQM3"
                            name="payrollBohCYQM3"
                            defaultValue={
                              values.payrollBohCYQM3
                                ? values.payrollBohCYQM3
                                : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollBohM3_2"
                            name="payrollBohM3_2"
                            defaultValue={values.payrollBohM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollBohTotalCYQ"
                            name="payrollBohTotalCYQ"
                            defaultValue={values.payrollBohTotalCYQ}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollBohCYQP"
                            name="payrollBohCYQP"
                            defaultValue={values.payrollBohCYQP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollBohTotal_2"
                            name="payrollBohTotal_2"
                            defaultValue={values.payrollBohTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollBohTotalP_2"
                            name="payrollBohTotalP_2"
                            defaultValue={values.payrollBohTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="labourBohId"
                            name="labourBohId"
                            defaultValue={values.labourBohId}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="labourBohP"
                            name="labourBohP"
                            defaultValue={values.labourBohP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Payroll FOH</th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollFohM1_2"
                            name="payrollFohM1_2"
                            defaultValue={
                              values.payrollFohM1_2 ? values.payrollFohM1_2 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollFohNYQM1"
                            name="payrollFohNYQM1"
                            defaultValue={values.payrollFohNYQM1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollFohM2_2"
                            name="payrollFohM2_2"
                            defaultValue={
                              values.payrollFohM2_2 ? values.payrollFohM2_2 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollFohNYQM2"
                            name="payrollFohNYQM2"
                            defaultValue={values.payrollFohNYQM2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollFohM3_2"
                            name="payrollFohM3_2"
                            defaultValue={
                              values.payrollFohM3_2 ? values.payrollFohM3_2 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollFohNYQM3"
                            name="payrollFohNYQM3"
                            defaultValue={values.payrollFohNYQM3}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollFohTotalCYQ"
                            name="payrollFohTotalCYQ"
                            defaultValue={values.payrollFohTotalCYQ}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollFohCYQP"
                            name="payrollFohCYQP"
                            defaultValue={values.payrollFohCYQP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollFohTotal_2"
                            name="payrollFohTotal_2"
                            defaultValue={values.payrollFohTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollFohTotalP_2"
                            name="payrollFohTotalP_2"
                            defaultValue={values.payrollFohTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="labourFohId"
                            name="labourFohId"
                            defaultValue={values.labourFohId}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="labourFohP"
                            name="labourFohP"
                            defaultValue={values.labourFohP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Payroll </th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollM1_1"
                            name="payrollM1_1"
                            defaultValue={
                              values.payrollM1_1 ? values.payrollM1_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollAdminNYQM1"
                            name="payrollAdminNYQM1"
                            defaultValue={values.payrollAdminNYQM1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollM2_1"
                            name="payrollM2_1"
                            defaultValue={
                              values.payrollM2_1 ? values.payrollM2_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollAdminNYQM2"
                            name="payrollAdminNYQM2"
                            defaultValue={values.payrollAdminNYQM2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollM3_1"
                            name="payrollM3_1"
                            defaultValue={
                              values.payrollM3_1 ? values.payrollM3_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollAdminNYQM3"
                            name="payrollAdminNYQM3"
                            defaultValue={values.payrollAdminNYQM3}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollTotal_1"
                            name="payrollTotal_1"
                            defaultValue={values.payrollTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollTotalP_1"
                            name="payrollTotalP_1"
                            defaultValue={values.payrollTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollAdminTotalNYQ"
                            name="payrollAdminTotalNYQ"
                            defaultValue={values.payrollAdminTotalNYQ}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollAdminNYQP"
                            name="payrollAdminNYQP"
                            defaultValue={values.payrollAdminNYQP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollAdminID"
                            name="payrollAdminID"
                            defaultValue={values.payrollAdminID}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollAdminP"
                            name="payrollAdminP"
                            defaultValue={values.payrollAdminP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> Credit Card Svcs</th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="creditCardM1"
                            name="creditCardM1"
                            defaultValue={
                              values.creditCardM1 ? values.creditCardM1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="creditCardNYQM1"
                            name="creditCardNYQM1"
                            defaultValue={values.creditCardNYQM1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="creditCardM2"
                            name="creditCardM2"
                            defaultValue={
                              values.creditCardM2 ? values.creditCardM2 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="creditCardNYQM2"
                            name="creditCardNYQM2"
                            defaultValue={values.creditCardNYQM2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="creditCardM3"
                            name="creditCardM3"
                            defaultValue={
                              values.creditCardM3 ? values.creditCardM3 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="creditCardNYQM3"
                            name="creditCardNYQM3"
                            defaultValue={values.creditCardNYQM3}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="creditCardTotal"
                            name="creditCardTotal"
                            defaultValue={values.creditCardTotal}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="creditCardTotalP"
                            name="creditCardTotalP"
                            defaultValue={values.creditCardTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="creditCardTotalNYQ"
                            name="creditCardTotalNYQ"
                            defaultValue={values.creditCardTotalNYQ}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="creditCardNYQP"
                            name="creditCardNYQP"
                            defaultValue={values.creditCardNYQP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="creditCardID"
                            name="creditCardID"
                            defaultValue={values.creditCardID}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="creditCardP"
                            name="creditCardP"
                            defaultValue={values.creditCardP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> Cleaning and Laundry</th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="cleaningM1_1"
                            name="cleaningM1_1"
                            defaultValue={
                              values.cleaningM1_1 ? values.cleaningM1_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="cleaningM1_2"
                            name="cleaningM1_2"
                            defaultValue={values.cleaningM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="cleaningM2_1"
                            name="cleaningM2_1"
                            defaultValue={
                              values.cleaningM2_1 ? values.cleaningM2_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="cleaningM2_2"
                            name="cleaningM2_2"
                            defaultValue={values.cleaningM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="cleaningM3_1"
                            name="cleaningM3_1"
                            defaultValue={
                              values.cleaningM3_1 ? values.cleaningM3_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="cleaningM3_2"
                            name="cleaningM3_2"
                            defaultValue={values.cleaningM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="cleaningTotal_1"
                            name="cleaningTotal_1"
                            defaultValue={values.cleaningTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="cleaningTotalP_1"
                            name="cleaningTotalP_1"
                            defaultValue={values.cleaningTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="cleaningTotal_2"
                            name="cleaningTotal_2"
                            defaultValue={values.cleaningTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="cleaningTotalP_2"
                            name="cleaningTotalP_2"
                            defaultValue={values.cleaningTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="cleaningTotalID"
                            name="cleaningTotalID"
                            defaultValue={values.cleaningTotalID}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="cleaningTotalP"
                            name="cleaningTotalP"
                            defaultValue={values.cleaningTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> Repair & Maintenance </th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="repairM1_1"
                            name="repairM1_1"
                            defaultValue={
                              values.repairM1_1 ? values.repairM1_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="repairM1_2"
                            name="repairM1_2"
                            defaultValue={values.repairM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="repairM2_1"
                            name="repairM2_1"
                            defaultValue={
                              values.repairM2_1 ? values.repairM2_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="repairM2_2"
                            name="repairM2_2"
                            defaultValue={values.repairM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="repairM3_1"
                            name="repairM3_1"
                            defaultValue={
                              values.repairM3_1 ? values.repairM3_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="repairM3_2"
                            name="repairM3_2"
                            defaultValue={values.repairM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="repairTotal_1"
                            name="repairTotal_1"
                            defaultValue={values.repairTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="repairTotalP_1"
                            name="repairTotalP_1"
                            defaultValue={values.repairTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="repairTotal_2"
                            name="repairTotal_2"
                            defaultValue={values.repairTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="repairTotalP_2"
                            name="repairTotalP_2"
                            defaultValue={values.repairTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="repairTotalId"
                            name="repairTotalId"
                            defaultValue={values.repairTotalId}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="repairTotalP"
                            name="repairTotalP"
                            defaultValue={values.repairTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> Uniforms</th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="uniformsM1_1"
                            name="uniformsM1_1"
                            defaultValue={
                              values.uniformsM1_1 ? values.uniformsM1_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="uniformsM1_2"
                            name="uniformsM1_2"
                            defaultValue={values.uniformsM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="uniformsM2_1"
                            name="uniformsM2_1"
                            defaultValue={
                              values.uniformsM2_1 ? values.uniformsM2_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="uniformsM2_2"
                            name="uniformsM2_2"
                            defaultValue={values.uniformsM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="uniformsM3_1"
                            name="uniformsM3_1"
                            defaultValue={
                              values.uniformsM3_1 ? values.uniformsM3_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="uniformsM3_2"
                            name="uniformsM3_2"
                            defaultValue={values.uniformsM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="uniformsTotal_1"
                            name="uniformsTotal_1"
                            defaultValue={values.uniformsTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="uniformsTotalP_1"
                            name="uniformsTotalP_1"
                            defaultValue={values.uniformsTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="uniformsTotal_2"
                            name="uniformsTotal_2"
                            defaultValue={values.uniformsTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="uniformsTotalP_2"
                            name="uniformsTotalP_2"
                            defaultValue={values.uniformsTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="uniformsTotalId"
                            name="uniformsTotalId"
                            defaultValue={values.uniformsTotalId}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="uniformsTotalP"
                            name="uniformsTotalP"
                            defaultValue={values.uniformsTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> Shortage </th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="shortageM1_1"
                            name="shortageM1_1"
                            defaultValue={
                              values.shortageM1_1 ? values.shortageM1_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="shortageM1_2"
                            name="shortageM1_2"
                            defaultValue={values.shortageM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="shortageM2_1"
                            name="shortageM2_1"
                            defaultValue={
                              values.shortageM2_1 ? values.shortageM2_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="shortageM2_2"
                            name="shortageM2_2"
                            defaultValue={values.shortageM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="shortageM3_1"
                            name="shortageM3_1"
                            defaultValue={
                              values.shortageM3_1 ? values.shortageM3_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="shortageM3_2"
                            name="shortageM3_2"
                            defaultValue={values.shortageM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="shortageTotal_1"
                            name="shortageTotal_1"
                            defaultValue={values.shortageTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="shortageTotalP_1"
                            name="shortageTotalP_1"
                            defaultValue={values.shortageTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="shortageTotal_2"
                            name="shortageTotal_2"
                            defaultValue={values.shortageTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="shortageTotalP_2"
                            name="shortageTotalP_2"
                            defaultValue={values.shortageTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="shortageTotalId"
                            name="shortageTotalId"
                            defaultValue={values.shortageTotalId}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="shortageTotalP"
                            name="shortageTotalP"
                            defaultValue={values.shortageTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> Food Delivery Providers</th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodDeliveryM1"
                            name="foodDeliveryM1"
                            defaultValue={
                              values.foodDeliveryM1 ? values.foodDeliveryM1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodDeliveryNYQM1"
                            name="foodDeliveryNYQM1"
                            defaultValue={values.foodDeliveryNYQM1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodDeliveryM2"
                            name="foodDeliveryM2"
                            defaultValue={
                              values.foodDeliveryM2 ? values.foodDeliveryM2 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodDeliveryNYQM2"
                            name="foodDeliveryNYQM2"
                            defaultValue={values.foodDeliveryNYQM2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodDeliveryM3"
                            name="foodDeliveryM3"
                            defaultValue={
                              values.foodDeliveryM3 ? values.foodDeliveryM3 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodDeliveryNYQM3"
                            name="foodDeliveryNYQM3"
                            defaultValue={values.foodDeliveryNYQM3}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodDeliveryTotal"
                            name="foodDeliveryTotal"
                            defaultValue={values.foodDeliveryTotal}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodDeliveryTotalP"
                            name="foodDeliveryTotalP"
                            defaultValue={values.foodDeliveryTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodDeliveryTotalNYQ"
                            name="foodDeliveryTotalNYQ"
                            defaultValue={values.foodDeliveryTotalNYQ}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodDeliveryNYQP"
                            name="foodDeliveryNYQP"
                            defaultValue={values.foodDeliveryNYQP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodDeliveryID"
                            name="foodDeliveryID"
                            defaultValue={values.foodDeliveryID}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="foodDeliveryP"
                            name="foodDeliveryP"
                            defaultValue={values.foodDeliveryP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> Rent </th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="rentM1_1"
                            name="rentM1_1"
                            defaultValue={values.rentM1_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="rentM1_2"
                            name="rentM1_2"
                            defaultValue={values.rentM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="rentM2_1"
                            name="rentM2_1"
                            defaultValue={
                              values.rentM2_1 ? values.rentM2_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="rentM2_2"
                            name="rentM2_2"
                            defaultValue={values.rentM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="rentM3_1"
                            name="rentM3_1"
                            defaultValue={
                              values.rentM3_1 ? values.rentM3_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="repairM3_2"
                            name="repairM3_2"
                            defaultValue={values.repairM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="rentTotal_1"
                            name="rentTotal_1"
                            defaultValue={values.rentTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="rentTotalP_1"
                            name="rentTotalP_1"
                            defaultValue={values.rentTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="rentTotal_2"
                            name="rentTotal_2"
                            defaultValue={values.rentTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="rentTotalP_2"
                            name="rentTotalP_2"
                            defaultValue={values.rentTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="rentID"
                            name="rentID"
                            defaultValue={values.rentID}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="rentP"
                            name="rentP"
                            defaultValue={values.rentP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> Utilities</th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="utilitiesM1_1"
                            name="utilitiesM1_1"
                            defaultValue={
                              values.utilitiesM1_1 ? values.utilitiesM1_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="utilitiesM1_2"
                            name="utilitiesM1_2"
                            defaultValue={values.utilitiesM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="utilitiesM2_1"
                            name="utilitiesM2_1"
                            defaultValue={
                              values.utilitiesM2_1 ? values.utilitiesM2_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="utilitiesM2_2"
                            name="utilitiesM2_2"
                            defaultValue={values.utilitiesM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="utilitiesM3_1"
                            name="utilitiesM3_1"
                            defaultValue={
                              values.utilitiesM3_1 ? values.utilitiesM3_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="utilitiesM3_2"
                            name="utilitiesM3_2"
                            defaultValue={values.utilitiesM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="utilitiesTotal_1"
                            name="utilitiesTotal_1"
                            defaultValue={values.utilitiesTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="utilitiesTotalP_1"
                            name="utilitiesTotalP_1"
                            defaultValue={values.utilitiesTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="utilitiesTotal_2"
                            name="utilitiesTotal_2"
                            defaultValue={values.utilitiesTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="utilitiesTotalP_2"
                            name="utilitiesTotalP_2"
                            defaultValue={values.utilitiesTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="utilitiesID"
                            name="utilitiesID"
                            defaultValue={values.utilitiesID}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="utilitiesP"
                            name="utilitiesP"
                            defaultValue={values.utilitiesP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> Depreciation </th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="depreciationM1"
                            name="depreciationM1"
                            defaultValue={
                              values.depreciationM1 ? values.depreciationM1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="depreciationNYQM1"
                            name="depreciationNYQM1"
                            defaultValue={values.depreciationNYQM1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="depreciationM2"
                            name="depreciationM2"
                            defaultValue={
                              values.depreciationM2 ? values.depreciationM2 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="depreciationNYQM2"
                            name="depreciationNYQM2"
                            defaultValue={values.depreciationNYQM2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="depreciationM3"
                            name="depreciationM3"
                            defaultValue={
                              values.depreciationM3 ? values.depreciationM3 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="depreciationNYQM3"
                            name="depreciationNYQM3"
                            defaultValue={values.depreciationNYQM3}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="depreciationTotal"
                            name="depreciationTotal"
                            defaultValue={values.depreciationTotal}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="depreciationTotalP"
                            name="depreciationTotalP"
                            defaultValue={values.depreciationTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="depreciationTotalNYQ"
                            name="depreciationTotalNYQ"
                            defaultValue={values.depreciationTotalNYQ}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="depreciationNYQP"
                            name="depreciationNYQP"
                            defaultValue={values.depreciationNYQP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="depreciationID"
                            name="depreciationID"
                            defaultValue={values.depreciationID}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="depreciationP"
                            name="depreciationP"
                            defaultValue={values.depreciationP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> Other Overheads </th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="overheadsM1_1"
                            name="overheadsM1_1"
                            defaultValue={
                              values.overheadsM1_1 ? values.overheadsM1_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="overheadsM1_2"
                            name="overheadsM1_2"
                            defaultValue={values.overheadsM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="overheadsM2_1"
                            name="overheadsM2_1"
                            defaultValue={
                              values.overheadsM2_1 ? values.overheadsM2_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="overheadsM2_2"
                            name="overheadsM2_2"
                            defaultValue={values.overheadsM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="overheadsM3_1"
                            name="overheadsM3_1"
                            defaultValue={
                              values.overheadsM3_1 ? values.overheadsM3_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="overheadsM3_2"
                            name="overheadsM3_2"
                            defaultValue={values.overheadsM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="overheadsTotal_1"
                            name="overheadsTotal_1"
                            defaultValue={values.overheadsTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="overheadsTotalP_1"
                            name="overheadsTotalP_1"
                            defaultValue={values.overheadsTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="overheadsTotal_2"
                            name="overheadsTotal_2"
                            defaultValue={values.overheadsTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="overheadsTotalP_2"
                            name="overheadsTotalP_2"
                            defaultValue={values.overheadsTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="overheadsID"
                            name="overheadsID"
                            defaultValue={values.overheadsID}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="overheadsP"
                            name="overheadsP"
                            defaultValue={values.overheadsP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> Total Expenses</th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalExpensesM1_1"
                            name="totalExpensesM1_1"
                            defaultValue={values.totalExpensesM1_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalExpensesM1_2"
                            name="totalExpensesM1_2"
                            defaultValue={values.totalExpensesM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalExpensesM2_1"
                            name="totalExpensesM2_1"
                            defaultValue={values.totalExpensesM2_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalExpensesM2_2"
                            name="totalExpensesM2_2"
                            defaultValue={values.totalExpensesM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalExpensesM3_1"
                            name="totalExpensesM3_1"
                            defaultValue={values.totalExpensesM3_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalExpensesM3_2"
                            name="totalExpensesM3_2"
                            defaultValue={values.totalExpensesM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalExpensesTotal_1"
                            name="totalExpensesTotal_1"
                            defaultValue={values.totalExpensesTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalExpensesTotalP_1"
                            name="totalExpensesTotalP_1"
                            defaultValue={values.totalExpensesTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalExpensesTotal_2"
                            name="totalExpensesTotal_2"
                            defaultValue={values.totalExpensesTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalExpensesTotalP_2"
                            name="totalExpensesTotalP_2"
                            defaultValue={values.totalExpensesTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalExpensesID"
                            name="totalExpensesID"
                            defaultValue={values.totalExpensesID}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalExpensesP"
                            name="totalExpensesP"
                            defaultValue={values.totalExpensesP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* Operating Income */}
                <div style={{ marginTop: "20px" }}>
                  <table className="table table-bordered">
                    <tbody>
                      <tr
                        style={{
                          background: "black",
                          color: "white",
                          paddingLeft: "2px",
                        }}
                        className="dark-background"
                      >
                        <th
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            textAlign: "center",
                          }}
                        >
                          Operating Income
                        </th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="incomeM1_1"
                            name="incomeM1_1"
                            defaultValue={values.incomeM1_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="incomeM1_2"
                            name="incomeM1_2"
                            defaultValue={values.incomeM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="incomeM2_1"
                            name="incomeM2_1"
                            defaultValue={values.incomeM2_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="incomeM2_2"
                            name="incomeM2_2"
                            defaultValue={values.incomeM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="incomeM3_1"
                            name="incomeM3_1"
                            defaultValue={values.incomeM3_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="incomeM3_2"
                            name="incomeM3_2"
                            defaultValue={values.incomeM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="incomeTotal_1"
                            name="incomeTotal_1"
                            defaultValue={values.incomeTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="incomeTotalP_1"
                            name="incomeTotalP_1"
                            defaultValue={values.incomeTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="incomeTotal_2"
                            name="incomeTotal_2"
                            defaultValue={values.incomeTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="incomeTotalP_2"
                            name="incomeTotalP_2"
                            defaultValue={values.incomeTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="incomeTotalId"
                            name="incomeTotalId"
                            defaultValue={values.incomeTotalId}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="incomeTotalP"
                            name="incomeTotalP"
                            defaultValue={values.incomeTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* Interest */}
                <div style={{ marginTop: "20px" }}>
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th scope="row"> Interest </th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="interestM1_1"
                            name="interestM1_1"
                            defaultValue={
                              values.interestM1_1 ? values.interestM1_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="interestM1_2"
                            name="interestM1_2"
                            defaultValue={values.interestM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="interestM2_1"
                            name="interestM2_1"
                            defaultValue={
                              values.interestM2_1 ? values.interestM2_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="interestM2_2"
                            name="interestM2_2"
                            defaultValue={values.interestM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="interestM3_1"
                            name="interestM3_1"
                            defaultValue={
                              values.interestM3_1 ? values.interestM3_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="interestM3_2"
                            name="interestM3_2"
                            defaultValue={values.interestM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="interestTotal_1"
                            name="interestTotal_1"
                            defaultValue={values.interestTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="interestTotalP_1"
                            name="interestTotalP_1"
                            defaultValue={values.interestTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="interestTotal_2"
                            name="interestTotal_2"
                            defaultValue={values.interestTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="interestTotalP_2"
                            name="interestTotalP_2"
                            defaultValue={values.interestTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="interestTotalId"
                            name="interestTotalId"
                            defaultValue={values.interestTotalId}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="interestTotalP"
                            name="interestTotalP"
                            defaultValue={values.interestTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> Admon Fee</th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="admonFeeM1_1"
                            name="admonFeeM1_1"
                            defaultValue={
                              values.admonFeeM1_1 ? values.admonFeeM1_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="admonFeeM1_2"
                            name="admonFeeM1_2"
                            defaultValue={values.admonFeeM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="admonFeeM2_1"
                            name="admonFeeM2_1"
                            defaultValue={
                              values.admonFeeM2_1 ? values.admonFeeM2_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="admonFeeM2_2"
                            name="admonFeeM2_2"
                            defaultValue={values.admonFeeM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="admonFeeM3_1"
                            name="admonFeeM3_1"
                            defaultValue={
                              values.admonFeeM3_1 ? values.admonFeeM3_1 : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="admonFeeM3_2"
                            name="admonFeeM3_2"
                            defaultValue={values.admonFeeM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="admonFeeTotal_1"
                            name="admonFeeTotal_1"
                            defaultValue={values.admonFeeTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="admonFeeTotalP_1"
                            name="admonFeeTotalP_1"
                            defaultValue={values.admonFeeTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="admonFeeTotal_2"
                            name="admonFeeTotal_2"
                            defaultValue={values.admonFeeTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="admonFeeTotalP_2"
                            name="admonFeeTotalP_2"
                            defaultValue={values.admonFeeTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="admonFeeTotalId"
                            name="admonFeeTotalId"
                            defaultValue={values.admonFeeTotalId}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="admonFeeP"
                            name="admonFeeP"
                            defaultValue={values.admonFeeP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* Sub Total */}
                <div style={{ marginTop: "20px" }}>
                  <table className="table table-bordered">
                    <tbody>
                      <tr
                        style={{
                          background: "black",
                          color: "white",
                          paddingLeft: "2px",
                        }}
                        className="dark-background"
                      >
                        <th
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            textAlign: "center",
                          }}
                        >
                          Sub Total
                        </th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="subTotalM1_1"
                            name="subTotalM1_1"
                            defaultValue={values.subTotalM1_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="subTotalM1_2"
                            name="subTotalM1_2"
                            defaultValue={values.subTotalM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="subTotalM2_1"
                            name="subTotalM2_1"
                            defaultValue={values.subTotalM2_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="subTotalM2_2"
                            name="subTotalM2_2"
                            defaultValue={values.subTotalM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="subTotalM3_1"
                            name="subTotalM3_1"
                            defaultValue={values.subTotalM3_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="subTotalM3_2"
                            name="subTotalM3_2"
                            defaultValue={values.subTotalM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="subTotalTotal_1"
                            name="subTotalTotal_1"
                            defaultValue={values.subTotalTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="subTotalTotalP_1"
                            name="subTotalTotalP_1"
                            defaultValue={values.subTotalTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="subTotalTotal_2"
                            name="subTotalTotal_2"
                            defaultValue={values.subTotalTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="subTotalTotalP_2"
                            name="subTotalTotalP_2"
                            defaultValue={values.subTotalTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="subTotalTotalId"
                            name="subTotalTotalId"
                            defaultValue={values.subTotalTotalId}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="subTotalTotalP"
                            name="subTotalTotalP"
                            defaultValue={values.subTotalTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* Other Income */}
                <div style={{ marginTop: "20px" }}>
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            textAlign: "center",
                          }}
                        >
                          Other Income
                        </th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="otherIncomeM1_1"
                            name="otherIncomeM1_1"
                            defaultValue={
                              values.otherIncomeM1_1
                                ? values.otherIncomeM1_1
                                : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="otherIncomeM1_2"
                            name="otherIncomeM1_2"
                            defaultValue={values.otherIncomeM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="otherIncomeM2_1"
                            name="otherIncomeM2_1"
                            defaultValue={
                              values.otherIncomeM2_1
                                ? values.otherIncomeM2_1
                                : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="otherIncomeM2_2"
                            name="otherIncomeM2_2"
                            defaultValue={values.otherIncomeM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="otherIncomeM3_1"
                            name="otherIncomeM3_1"
                            defaultValue={
                              values.otherIncomeM3_1
                                ? values.otherIncomeM3_1
                                : ""
                            }
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="otherIncomeM3_2"
                            name="otherIncomeM3_2"
                            defaultValue={values.otherIncomeM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="otherIncomeTotal_1"
                            name="otherIncomeTotal_1"
                            defaultValue={values.otherIncomeTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="otherIncomeTotalP_1"
                            name="otherIncomeTotalP_1"
                            defaultValue={values.otherIncomeTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="otherIncomeTotal_2"
                            name="otherIncomeTotal_2"
                            defaultValue={values.otherIncomeTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="otherIncomeTotalP_2"
                            name="otherIncomeTotalP_2"
                            defaultValue={values.otherIncomeTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="otherIncomeTotalId"
                            name="otherIncomeTotalId"
                            defaultValue={values.otherIncomeTotalId}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="otherIncomeTotalP"
                            name="otherIncomeTotalP"
                            defaultValue={values.otherIncomeTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* Net Profit */}
                <div style={{ marginTop: "20px" }}>
                  <table className="table table-bordered">
                    <tbody>
                      <tr
                        style={{
                          background: "black",
                          color: "white",
                          paddingLeft: "2px",
                        }}
                        className="dark-background"
                      >
                        <th
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            textAlign: "center",
                          }}
                        >
                          Net Profit
                        </th>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="netProfitM1_1"
                            name="netProfitM1_1"
                            defaultValue={values.netProfitM1_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="netProfitM1_2"
                            name="netProfitM1_2"
                            defaultValue={values.netProfitM1_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="netProfitM2_1"
                            name="netProfitM2_1"
                            defaultValue={values.netProfitM2_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="netProfitM2_2"
                            name="netProfitM2_2"
                            defaultValue={values.netProfitM2_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="netProfitM3_1"
                            name="netProfitM3_1"
                            defaultValue={values.netProfitM3_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="netProfitM3_2"
                            name="netProfitM3_2"
                            defaultValue={values.netProfitM3_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="netProfitTotal_1"
                            name="netProfitTotal_1"
                            defaultValue={values.netProfitTotal_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="netProfitTotalP_1"
                            name="netProfitTotalP_1"
                            defaultValue={values.netProfitTotalP_1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="netProfitTotal_2"
                            name="netProfitTotal_2"
                            defaultValue={values.netProfitTotal_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="netProfitTotalP_2"
                            name="netProfitTotalP_2"
                            defaultValue={values.netProfitTotalP_2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="netProfitId"
                            name="netProfitId"
                            defaultValue={values.netProfitId}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="netProfitP"
                            name="netProfitP"
                            defaultValue={values.netProfitP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ViewBulkUploadBreakdown;
