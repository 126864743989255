import PendingPayment from "../financials/PendingPayments";
import ViewPendingPaymentsStore from "../financials/StorePendingPayments";
import DeletedPayment from "../financials/DeletedPayments";
import ViewDeletedPaymentsStore from "../financials/StoreDeletedPayments";
import ScheduledPayment from "../financials/ScheduledPayments";
import ViewScheduledPaymentsStore from "../financials/StoreScheduledPayments";
import PaymentHistories from "../financials/PaymentHistory";
import ViewPaymentHistoriesStore from "../financials/StorePaymentHistories";
import ExpensesView from "../financials/ExpensesViewer";
import ViewExpensesStore from "../financials/StoreExpenses";
import ViewExpensesStoreDoc from "../financials/StoreExpensesDoc";
import ReportHistoriesView from "../financials/ReportsHistories";
import ViewReportStoreList from "../financials/StoreReportList";
import ViewStoreReportHistories from "../financials/StoreReportHistories";
import EmergencyCodeReport from "../financials/emergency/EmergencyCodeReport";
import InstantAudits from "../financials/InstantAudits";
import InstantTransactionHistory from "../financials/InstantTransaction";
import ViewAllInstantAuditsStore from "../financials/ViewAllInstantAuditsStore";
import AddNewInstantAudit from "../financials/AddNewInstantAuditForStore";
import SalesReportPDF from "../financials/ViewReportHistory";
import EmergencyReportPDF from "../financials/ViewEmergencyReport";

const financialsRoutes = [
  { path: "/financials/pending-payments", component: PendingPayment },
  { path: "/financials/deleted-payments", component: DeletedPayment },
  { path: "/financials/scheduled-payments", component: ScheduledPayment },
  { path: "/financials/expenses-viewers", component: ExpensesView },
  { path: "/financials/view-expenses-store/:store_id", component: ViewExpensesStore },
  { path: "/financials/view-store-report", component: ViewReportStoreList },
  { path: "/financials/view-report-store/:store_id/:id", component: ReportHistoriesView },
  {
    path: "/financials/view-report-histories-store/:store_id",
    component: ViewStoreReportHistories,
  },
  { path: "/financials/store/doc/:store_id/:expense_id", component: ViewExpensesStoreDoc },
  {
    path: "/financials/view-pending-payments-store/:store_id",
    component: ViewPendingPaymentsStore,
  },
  {
    path: "/financials/view-deleted-payments-store/:store_id",
    component: ViewDeletedPaymentsStore,
  },
  {
    path: "/financials/view-scheduled-payments-store/:store_id",
    component: ViewScheduledPaymentsStore,
  },
  {
    path: "/financials/view-payment-histories-store/:store_id",
    component: ViewPaymentHistoriesStore,
  },
  { path: "/financials/payment-history", component: PaymentHistories },
  { path: "/financials/view-closing-report", component: SalesReportPDF },
  { path: "/financials/view-emergency-report", component: EmergencyReportPDF },
  { path: "/financials/emergency-code-report", component: EmergencyCodeReport },
  { path: "/financials/instant-audits", component: InstantAudits },
  { path: "/financials/instant-transaction-history", component: InstantTransactionHistory },
  {
    path: "/financials/view-all-instant-audits-store/:store_id",
    component: ViewAllInstantAuditsStore,
  },
  { path: "/financials/add-new-instant-audits/:store_id", component: AddNewInstantAudit },
];

export default financialsRoutes;
