import moment from "moment";
import "moment-timezone";

export const ConvertDateFormat = (date) => {
  return date ? moment(date).format("MM-DD-YYYY") : "";
};

export const ConvertDateFormatForHuman = (date) => {
  return date ? moment(date).fromNow() : "";
};

export const ConvertDateTimeFormat = (dateTime) => {
  return moment(dateTime).format("MM-DD-YYYY HH:mm:ss");
};
