import cogoToast from "cogo-toast";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useHistory, useParams, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import {
  ConvertDateFormat,
  ConvertDateTimeFormat,
} from "../../components/ConvertDateFormat";
import { SystemConfigService } from "../../service/configService";
// import { ExpensesService } from "../../service/ExpenseService";
import { PaymentService } from "../../service/PaymentService";
import { UploadDepositSlip } from "./components/UploadDepositSlip";
import { Utility } from "../../Helpers/utils";
import moment from "moment";
const { SearchBar } = Search;

const StoreReportHistories = () => {
  let { store_id } = useParams();
  const [store, setStore] = useState(null);
  const [date, setDate] = useState(new Date());
  const [reportHistories, setReportHistories] = useState(null);
  const [showUploadDepositModal, setShowUploadDepositModal] = useState(false);
  const [user, setUser] = useState(null);
  const [depositAmount, setDepositAmount] = useState(0);

  let history = useHistory();

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  const handleViewReportRedirect = (row, e) => {
    // e.target.innerHTML = `<i class="fa fa-spin fa-spinner"></i>Loading...`;
    if (JSON.parse(localStorage.getItem("newCompletedSalesReport"))) {
      localStorage.removeItem("newCompletedSalesReport");
    }
    // PaymentService.getCompletedSalesReportForStore(store_id, row.report_date)
    //   .then((response) => {
    //     console.log(response.data.data, "New sales report");
    //     localStorage.setItem(
    //       "newCompletedSalesReport",
    //       JSON.stringify(response.data.data)
    //     );
    //   })
    //   .catch((error) => {
    //     console.log(error.reponse);
    //   });

    // setTimeout(() => {
    history.push("/financials/view-closing-report", {
      store_id,
      report_date: row.report_date,
    });
    // }, 5000);
  };

  const handleViewEmergencyReportRedirect = (row, e, id) => {
    // console.log(row.report_date, e, id);
    history.push("/financials/view-emergency-report", {
      store_id: id,
      report_date: row.report_date,
    });
  };

  useEffect(() => {
    PaymentService.getSalesReportForStore(store_id)
      .then((response) => {
        console.log(response.data.data);
        let reportResArray = [];
        response.data.data.map((res, index) => {
          return reportResArray.push({
            id: res.id,
            report_date: ConvertDateFormat(res.report_date),
            store_manager: res.store_manager,
            created_at: ConvertDateTimeFormat(res.created_at),
            comments: res.comments_available,
            deposit_calculated: res.deposit_calculated,
            owner: res.owner,
          });
        });
        setReportHistories(reportResArray);
      })
      .catch((error) => {
        console.log(error);
      });

    // const data = { store_id };

    SystemConfigService.getStore(store_id)
      .then((response) => {
        setStore(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        cogoToast.error(error, toastoptions);
      });

    const authUser = Utility.getUser();
    console.log(authUser, "Logged in user");
    setUser(authUser);
  }, []);

  const columns = [
    {
      dataField: "id",
      text: "Report Id",
      sort: true,
    },
    {
      dataField: "report_date",
      text: "Report Date",
      sort: true,
    },
    {
      dataField: "store_manager",
      text: "Store Manager",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Date Processed",
      sort: true,
    },
    {
      dataField: "comments",
      text: "",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div>
            {row.comments ? (
              <i
                style={{ fontSize: "20px" }}
                className="mdi mdi-message-text text-primary"
              ></i>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <div>
            <button
              className="btn btn-outline-primary"
              onClick={(e) => handleViewReportRedirect(row, e)}
            >
              <i className="mdi mdi-eye-outline text-primary"></i>View Report
            </button>

            <button
              className="btn btn-outline-primary"
              onClick={(e) => {
                // let reportDate = moment(new Date(row.report_date));
                let reportDate = new Date(row.report_date);
                setShowUploadDepositModal(true);
                setDepositAmount(row.deposit_calculated);
                setDate(reportDate);
              }}
            >
              <i className="mdi mdi-upload text-primary"></i> Upload Deposit
            </button>
            {/* <button
              className="btn btn-outline-primary"
              onClick={(e) =>
                handleViewEmergencyReportRedirect(row, e, store.location_id)
              }
            >
              <i className="mdi mdi-eye-outline text-primary"></i> Emergency
              Report
            </button> */}
            {store && store.payments_options === "pay_by_hour" ? (
              <button
                className="btn btn-outline-primary"
                onClick={(e) => handleViewReportRedirect(row, e)}
              >
                <i className="mdi mdi-eye-outline text-primary"></i> Bar
                Breakdown
              </button>
            ) : (
              ""
            )}
          </div>
        );
      },
      formatExtraData: {
        store,
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <div>
      <div>
        <p className="pull-right" style={{ textDecoration: "underline" }}>
          <a
            href="https://veltron.document360.io/v1/en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn How &nbsp;<span className="fa fa-question"></span>
          </a>
        </p>

        <div className="clearfix"></div>
      </div>

      <div className="page-header">
        <h3 className="page-title">
          <span style={{ textTransform: "capitalize" }}>
            {store ? store.name : ""}
          </span>
        </h3>

        <div>
          <Link to={"/financials/view-store-report"}>
            <button className="btn btn-primary"> View All Reports</button>
          </Link>
        </div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/financials/view-store-report"> La Parrilla </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <span style={{ textTransform: "capitalize" }}>
                {store ? store.name : ""}
              </span>
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            {reportHistories && (
              <div className="card-body">
                <h4 className="card-title"> Report History</h4>
                <div className="row">
                  <div className="col-12">
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={reportHistories}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div className="d-flex align-items-center">
                            <p className="mb-2 mr-2">Search in table:</p>
                            <SearchBar {...props.searchProps} />
                          </div>
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        {showUploadDepositModal && (
          <div>
            <Formik
              initialValues={{
                first_name: user ? user.first_name : "",
                last_name: user ? user.last_name : "",
                date: date,
                location: store_id ? store_id : "",
                deposit_amount: depositAmount ? depositAmount : "",
                attachments: [],
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                console.log(values);

                const data = new FormData();
                let reportDate = moment(new Date(values.date)).format(
                  "YYYY/MM/DD"
                );
                data.append("first_name", values.first_name);
                data.append("store_id", values.store_id);
                data.append("last_name", values.last_name);
                data.append("date", reportDate);
                data.append("location", values.location);
                data.append("deposit_amount", values.deposit_amount);

                if (values.attachments.length < 1) {
                  setSubmitting(false);
                  cogoToast.error(`Kindly upload a deposit slip`);
                } else {
                  Swal.fire({
                    title: "Are you sure?",
                    text: "Ensure that you have uploaded the right deposit slip!",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#000000",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Proceed",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      values.attachments.map((file) => {
                        return data.append("attachments[]", file, file.name);
                      });

                      PaymentService.uploadDepositSlip(data)
                        .then((response) => {
                          console.log(response.data.data);
                          setSubmitting(false);
                          Swal.fire(
                            "Successful!",
                            "Deposit slip has been uploaded.",
                            "success"
                          );
                          window.location.reload();
                        })
                        .catch((error) => {
                          console.log(error);
                          setSubmitting(false);
                        });
                    }
                  });
                }
              }}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <Modal
                  show={showUploadDepositModal}
                  onHide={() => setShowUploadDepositModal(false)}
                  aria-labelledby="example-modal-sizes-title-md"
                  style={{ top: "50px" }}
                  backdrop="static"
                >
                  <form name="form" onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                      <Modal.Title> Deposit Slip </Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ paddingBottom: "0px" }}>
                      <div className="row">
                        <div className="col-md-12">
                          <h4>
                            Name
                            <span className="text-danger"> * </span>
                          </h4>
                          <div className="row">
                            <div className="col-md-6">
                              <Form.Group>
                                <label htmlFor="first_name">
                                  First Name{" "}
                                  <span className="text-danger"> * </span>{" "}
                                </label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  id="manager_first_name"
                                  placeholder="First Name"
                                  name="first_name"
                                  value={values.first_name}
                                  onChange={handleChange}
                                  disabled={isSubmitting}
                                  autoComplete="off"
                                  autoCorrect="off"
                                  autoCapitalize="off"
                                  spellCheck="false"
                                />
                              </Form.Group>
                            </div>

                            <div className="col-md-6">
                              <Form.Group>
                                <label htmlFor="last_name">Last Name</label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  id="manager_last_name"
                                  placeholder="Last Name"
                                  name="last_name"
                                  value={values.last_name}
                                  onChange={handleChange}
                                  disabled={isSubmitting}
                                  autoComplete="off"
                                  autoCorrect="off"
                                  autoCapitalize="off"
                                  spellCheck="false"
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group>
                            <label htmlFor="location">Location</label>
                            <Form.Control
                              as="select"
                              type="text"
                              className="form-control"
                              id="location"
                              name="location"
                              value={
                                values.location ? values.location : "DEFAULT"
                              }
                              onChange={(e) => {
                                setFieldValue("location", e.target.value);
                              }}
                              disabled={isSubmitting}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                            >
                              <option value="DEFAULT" disabled>
                                Select One
                              </option>
                              <option value="1">
                                La Parrilla (Marietta Square)
                              </option>
                              <option value="2">
                                {" "}
                                La Parrilla (Dallas Hwy){" "}
                              </option>
                              <option value="3">
                                {" "}
                                La Parrilla (Flowery Branch){" "}
                              </option>
                              <option value="4">
                                {" "}
                                La Parrilla (Powder Spring){" "}
                              </option>
                              <option value="5">
                                {" "}
                                La Parrilla (Fayette Ville){" "}
                              </option>
                              <option value="6">
                                {" "}
                                La Parrilla (Alpharetta){" "}
                              </option>
                              <option value="7">
                                {" "}
                                La Parrilla (Woodstock){" "}
                              </option>
                              <option value="9"> La Parrilla (Acworth) </option>
                              <option value="10"> La Parrilla (Newman) </option>
                              <option value="11">
                                {" "}
                                La Parrilla (Mcdonough){" "}
                              </option>
                              <option value="12">
                                {" "}
                                La Parrilla (Howel Mill){" "}
                              </option>
                              <option value="13"> La Parrilla (Dothan) </option>
                              <option value="14">
                                {" "}
                                La Parrilla (Norcross){" "}
                              </option>
                              <option value="15"> La Parrilla (Canton) </option>
                              <option value="16"> La Parrilla (Macon) </option>
                              <option value="17">
                                {" "}
                                La Parrilla (Greenville){" "}
                              </option>
                              <option value="18">
                                {" "}
                                La Parrilla (Toco Hill){" "}
                              </option>
                              <option value="19">
                                {" "}
                                La Parrilla (Savannah){" "}
                              </option>
                              <option value="20">
                                {" "}
                                La Parrilla (Griffin){" "}
                              </option>
                              <option value="21"> La Parrilla #21 </option>
                              <option value="22"> La Parrilla #22 </option>
                              <option value="23"> La Parrilla #23 </option>
                              <option value="24"> La Parrilla #24 </option>
                              <option value="25"> La Parrilla #25 </option>
                              <option value="26"> La Parrilla #26 </option>
                              <option value="27"> La Parrilla #27 </option>
                              <option value="28"> La Parrilla #28 </option>
                              <option value="29"> La Parrilla #29 </option>
                              <option value="30"> La Parrilla #30 </option>
                              <option value="31"> Casi Ceilo (Rome) </option>
                              <option value="32"> HQ (Veltron Office) </option>
                            </Form.Control>
                          </Form.Group>
                        </div>

                        <div className="col-md-6">
                          <Form.Group>
                            <label htmlFor="deposit_amount">
                              {" "}
                              Total Deposit Amount
                            </label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="deposit_amount"
                              placeholder="Deposit Amount"
                              name="deposit_amount"
                              value={values.deposit_amount}
                              onChange={handleChange}
                              disabled={isSubmitting}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                            />
                          </Form.Group>
                        </div>
                      </div>

                      <Form.Group>
                        <label
                          htmlFor="date"
                          className="col-sm-3 col-form-label"
                        >
                          Date
                        </label>

                        <div className="row">
                          <div className="col-md-12">
                            <DatePicker
                              className="form-control w-100"
                              dateFormat="MM/dd/yyyy"
                              disabled={"disabled"}
                              selected={values.date}
                              isClearable
                              placeholderText="Select a new date"
                              onChange={(d1) => {
                                console.log(d1);
                                setDate(d1);
                                // handleChangeDate
                                setFieldValue(`date`, d1 ? d1 : "");
                              }}
                            />
                          </div>
                        </div>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label htmlFor="gender">
                          Upload Deposit Slip
                        </Form.Label>

                        <UploadDepositSlip
                          setFieldValue={setFieldValue}
                          isSubmitting={isSubmitting}
                        />
                      </Form.Group>
                    </Modal.Body>

                    <Modal.Footer
                      className="flex-wrap"
                      style={{ paddingTop: "0px" }}
                    >
                      {" "}
                      <Button
                        type="submit"
                        className="btn btn-block btn-primary font-weight-medium auth-form-btn"
                        disabled={isSubmitting}
                      >
                        Proceed &nbsp;&nbsp;
                        {isSubmitting ? (
                          // <Spinner animation="border" size="sm" />
                          <span className="fa fa-spin fa-spinner"></span>
                        ) : (
                          ""
                        )}
                      </Button>
                      <Button
                        variant="light m-2"
                        onClick={() => setShowUploadDepositModal(false)}
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </form>
                </Modal>
              )}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(StoreReportHistories);
