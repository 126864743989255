import React, { createContext, useReducer, useContext } from "react";

const PayBarContext = createContext();

const initialState = {
  laborPaymentDetails: [],
  barPaymentCreated: false,
  barEmergencyCodes: null,
  barEmergencyCodeDetails: null,
  storeDetails: null,
  tipSummary: [],
  amountLeft: 0,
  instantPayTotal: 0,
  barEmergencyAmount: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LABOR_PAYMENT_DETAILS":
      return { ...state, laborPaymentDetails: action.payload };
    case "SET_PAYMENT_CREATED":
      return { ...state, barPaymentCreated: action.payload };
    case "SET_STORE_DETAILS":
      return { ...state, storeDetails: action.payload };
    case "SET_TIP_SUMMARY":
      return { ...state, tipSummary: action.payload };
    case "SET_AMOUNT_LEFT":
      return { ...state, amountLeft: action.payload };
    case "SET_INSTANT_PAY_TOTAL":
      return { ...state, instantPayTotal: action.payload };
    case "SET_BAR_EMERGENCY_AMOUNT":
      return { ...state, barEmergencyAmount: action.payload };
    default:
      return state;
  }
};

export const PayBarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <PayBarContext.Provider value={{ state, dispatch }}>{children}</PayBarContext.Provider>;
};

export const usePayBarContext = () => {
  const context = useContext(PayBarContext);
  if (!context) {
    throw new Error("usePayBarContext must be used within a PayBarProvider");
  }
  return context;
};
