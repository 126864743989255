import Api from "./Api";

export class CateringService {
  static async createCateringPayment(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`catering/create`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async editCateringPayment(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`catering/edit`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getCateringPaymentForEdit(catering_id, store_id, catering_code) {
    return new Promise((resolve, reject) => {
      return Api.get(
        `catering/get-catering-edit/${catering_id}/${store_id}/${catering_code}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getCateringPayment(date, store_id) {
    return new Promise((resolve, reject) => {
      return Api.get(`catering/get-catering-details/${store_id}/${date}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getAllCateringPayment(store_id) {
    return new Promise((resolve, reject) => {
      return Api.get(`catering/get-all-catering-details/${store_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getFile(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`catering/getFile`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getScheduledCatering(store_id) {
    return new Promise((resolve, reject) => {
      return Api.get(`catering/get-scheduled-catering/${store_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getPendingCatering(store_id) {
    return new Promise((resolve, reject) => {
      return Api.get(`catering/get-pending-catering/${store_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async scheduleCatering(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`catering/schedule-catering-store`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async unScheduleCatering(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`catering/unschedule-catering-store`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async scheduleAllCatering(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`catering/schedule-all-catering`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async cancelCatering(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`catering/cancel-catering`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async getAllCateringPayments() {
    return new Promise((resolve, reject) => {
      return Api.get(`catering/get-all-catering`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async cateringRecords(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`catering/get-catering-records`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
