import React, { useState, useEffect } from "react";
import { withRouter, useHistory, useParams, Link } from "react-router-dom";
import cogoToast from "cogo-toast";
import { Formik } from "formik";
import { SystemConfigService } from "../../../service/configService";
import { Button, Form, Spinner } from "react-bootstrap";
import { UserService } from "../../../service/userService";
import InputMask from "react-input-mask";

const ViewStore = () => {
  const [editMode, setEditMode] = useState(false);
  const [store, setStore] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [corporations, setCorporations] = useState(null);

  const history = useHistory();
  let { id } = useParams();

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  useEffect(() => {
    SystemConfigService.getStore(id)
      .then((response) => {
        console.log(response.data);
        setStore(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, [id]);

  useEffect(() => {
    UserService.getEmployees()
      .then((response) => {
        console.log(response.data.data, "employees details");
        setEmployees(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });

    SystemConfigService.getCorporations()
      .then((response) => {
        setCorporations(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);

  return (
    <div>
      <div className="page-header">
        {store && (
          <h3 className="page-title config-header-title">
            {" "}
            Store <span>/ {store.name} </span>
          </h3>
        )}
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/system-configuration/all"}>Portal configuration</Link>
            </li>
            {!editMode && (
              <li className="breadcrumb-item active" aria-current="page">
                <Button
                  onClick={() => {
                    setEditMode(true);
                  }}
                >
                  {" "}
                  Edit{" "}
                </Button>
              </li>
            )}
          </ol>
        </nav>
      </div>

      {store && (
        <Formik
          initialValues={{
            id: store.location_id,
            area: store.area,
            location_id: store.location_id,
            name: store.name,
            address: store.address,
            city: store.city,
            state: store.state,
            manager_id: store.manager_id,
            telephone_number: store.telephone_number,
            email: store.email,
            corporation_id: store.corporation_id,
            pos_code: store.pos_code,
            crunch_time_code: store.crunch_time_code,
            payroll_code: store.payroll_code,
            payments_options: store.payments_options
              ? store.payments_options
              : "manual",
            // ato_code_payments_options: store.ato_code_payments_options
            //   ? store.ato_code_payments_options
            //   : "none",
            emergency_code_payments_options:
              store.emergency_code_payments_options
                ? store.emergency_code_payments_options
                : "no",
            catering_payments_options: store.catering_payments_options
              ? store.catering_payments_options
              : "no",
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            console.log(values, "The values");
            SystemConfigService.updateStore(values)
              .then((response) => {
                console.log(response.data.data);
                localStorage.setItem(
                  "storeDetails",
                  JSON.stringify(response.data.data)
                );
                setSubmitting(false);
                cogoToast.success("Store updated successfully", toastoptions);
                setEditMode(false);
                // history.push("/system-configuration/view-stores");
              })
              .catch((error) => {
                console.log(error.response);
                cogoToast.error(error.response.data.message, toastoptions);
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <div className="mt-3 mb-3">
              <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card" style={{ border: "none" }}>
                    <div className="card-body">
                      <form className="pageForms" onSubmit={handleSubmit}>
                        <div className="row border-bottom mb-5">
                          <div className="col-md-6">
                            <Form.Group>
                              <label htmlFor="area">Area</label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="area"
                                name="area"
                                defaultValue={values.area}
                                disabled={!editMode}
                                placeholder="area"
                                onChange={handleChange}
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="location_id">Location ID</label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="location_id"
                                name="location_id"
                                defaultValue={values.location_id}
                                disabled={!editMode}
                                placeholder="Location ID"
                                onChange={handleChange}
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="name">Name</label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="name"
                                name="name"
                                defaultValue={values.name}
                                onChange={handleChange}
                                disabled={!editMode}
                                style={{ textTransform: "capitalize" }}
                              />
                            </Form.Group>
                            <Form.Group>
                              <label htmlFor="address">Address</label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="address"
                                placeholder="Address"
                                name="address"
                                defaultValue={values.address}
                                onChange={handleChange}
                                disabled={!editMode}
                              />
                            </Form.Group>
                            <Form.Group>
                              <label htmlFor="city">City</label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="city"
                                placeholder="City"
                                name="city"
                                defaultValue={values.city}
                                onChange={handleChange}
                                disabled={!editMode}
                              />
                            </Form.Group>
                            <Form.Group>
                              <label htmlFor="state">State</label>
                              <Form.Control
                                type="text"
                                as="select"
                                className="form-control"
                                id="state"
                                placeholder="State"
                                name="state"
                                defaultValue={
                                  values.state ? values.state : "DEFAULT"
                                }
                                onChange={handleChange}
                                disabled={!editMode}
                              >
                                <option value="DEFAULT" disabled>
                                  {" "}
                                  Select One{" "}
                                </option>
                                <option value="AK"> Alaska - AK </option>
                                <option value="AZ"> Arizona - AZ </option>
                                <option value="AR"> Arkansas - AR </option>
                                <option value="CA"> California - CA </option>
                                <option value="CO"> Colorado - CO </option>
                                <option value="CT"> Connecticut - CT </option>
                                <option value="DE"> Delaware - DE </option>
                                <option value="FL"> Florida - FL </option>
                                <option value="GA"> Georgia - GA </option>
                                <option value="HI"> Hawaii - HI </option>
                                <option value="ID"> Idaho - ID </option>
                                <option value="IL"> Illinois - IL </option>
                                <option value="IN"> Indiana - IN </option>
                                <option value="IA"> Iowa - IA </option>
                                <option value="KS"> Kansas - KS </option>
                                <option value="KY"> Kentucky - KY </option>
                                <option value="LA"> Louisiana - LA </option>
                                <option value="ME"> Maine - ME </option>
                                <option value="MD"> Maryland - MD </option>
                                <option value="MA"> Massachusetts - MA </option>
                                <option value="MI"> Michigan - MI </option>
                                <option value="MN"> Minnesota - MN </option>
                                <option value="MS"> Mississippi - MS </option>
                                <option value="MO"> Missouri - MO </option>
                                <option value="MT"> Montana - MT </option>
                                <option value="NE"> Nebraska - NE </option>
                                <option value="NV"> Nevada - NV </option>
                                <option value="NH"> New Hampshire - NH </option>
                                <option value="NJ"> New Jersey - NJ </option>
                                <option value="NM"> New Mexico - NM </option>
                                <option value="NY"> New York - NY </option>
                                <option value="NC">
                                  {" "}
                                  North Carolina - NC{" "}
                                </option>
                                <option value="ND"> North Dakota - ND </option>
                                <option value="OH"> Ohio - OH </option>
                                <option value="OK"> Oklahoma - OK </option>
                                <option value="OR"> Oregon - OR </option>
                                <option value="PA"> Pennsylvania - PA </option>
                                <option value="RI"> Rhode Island - RI </option>
                                <option value="SC">
                                  {" "}
                                  South Carolina - SC{" "}
                                </option>
                                <option value="SD"> South Dakota - SD </option>
                                <option value="TN"> Tennessee - TN </option>
                                <option value="TX"> Texas - TX </option>
                                <option value="UT"> Utah - UT </option>
                                <option value="VT"> Vermont - VT </option>
                                <option value="VA"> Virginia - VA </option>
                                <option value="WA"> Washington - WA </option>
                                <option value="WV"> West Virginia - WV </option>
                                <option value="AZ"> Wisconsin - WI </option>
                                <option value="MY"> Wyoming - WY </option>
                              </Form.Control>
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="telephone_number">
                                Telephone Number
                              </label>
                              <InputMask
                                type="text"
                                mask="+1 (999) 999-9999"
                                className="form-control"
                                id="telephone_number"
                                placeholder="+ (999) 999-9999"
                                name="telephone_number"
                                disabled={!editMode}
                                defaultValue={values.telephone_number}
                                onChange={handleChange}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="email">Store Email</label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="email"
                                placeholder="Store email"
                                name="email"
                                disabled={!editMode}
                                defaultValue={values.email}
                                onChange={handleChange}
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="manager">
                                Manager {values.manager_id}
                              </label>
                              <Form.Control
                                as="select"
                                className="form-control"
                                name="manager_id"
                                value={
                                  values.manager_id
                                    ? values.manager_id
                                    : "DEFAULT"
                                }
                                id="manager_id"
                                disabled={!editMode}
                                onChange={(e) => {
                                  setFieldValue("manager_id", e.target.value);
                                }}
                              >
                                <option disabled value={"DEFAULT"}>
                                  Select Manager
                                </option>
                                {employees &&
                                  employees.activeEmployees.map((emp, index) => (
                                    <option value={emp.id} key={emp.id}>
                                      {emp.first_name} {emp.last_name}
                                    </option>
                                  ))}
                              </Form.Control>
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="corporation">Corporation</label>
                              <Form.Control
                                as="select"
                                className="form-control"
                                id="corporation_id"
                                name="corporation_id"
                                defaultValue={
                                  values.corporation_id
                                    ? values.corporation_id
                                    : "DEFAULT"
                                }
                                disabled={!editMode}
                                onChange={handleChange}
                              >
                                <option disabled value={"DEFAULT"}>
                                  Select Corporation
                                </option>
                                {corporations &&
                                  corporations.map((value, index) => (
                                    <option value={value.id} key={index}>
                                      {value.name}
                                    </option>
                                  ))}
                              </Form.Control>
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="pos_code">POS Code </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="name"
                                name="pos_code"
                                defaultValue={values.pos_code}
                                disabled={!editMode}
                                onChange={handleChange}
                                placeholder="POS Code "
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="crunch_time_code">
                                Crunch Time Code{" "}
                              </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="crunch_time_code"
                                name="crunch_time_code"
                                defaultValue={values.crunch_time_code}
                                disabled={!editMode}
                                onChange={handleChange}
                                placeholder="Crunch Time Code "
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="payroll_code">Payroll Code</label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="payroll_code"
                                name="payroll_code"
                                defaultValue={values.payroll_code}
                                disabled={!editMode}
                                onChange={handleChange}
                                placeholder="Payroll Code"
                              />
                            </Form.Group>

                            <Form.Group>
                              <Form.Label htmlFor="payments_options">
                                Bartender Tips Payment Options
                              </Form.Label>
                              <Form.Control
                                as="select"
                                type="text"
                                className="form-control"
                                name="payments_options"
                                id="payments_options"
                                defaultValue={values.payments_options}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                onChange={handleChange}
                              >
                                <option value="manual">Manual</option>
                                <option value="pay_by_hour">Pay by hour</option>
                                <option value="hour_help">
                                  View hour help
                                </option>
                              </Form.Control>
                            </Form.Group>

                            <Form.Group>
                              <Form.Label htmlFor="catering_payments_options">
                                Catering Payment Options
                              </Form.Label>
                              <Form.Control
                                as="select"
                                type="text"
                                className="form-control"
                                name="catering_payments_options"
                                id="catering_payments_options"
                                defaultValue={values.catering_payments_options}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                onChange={handleChange}
                              >
                                <option value="no"> No </option>
                                <option value="yes"> Yes </option>
                              </Form.Control>
                            </Form.Group>

                            <Form.Group>
                              <Form.Label htmlFor="emergency_code_payments_options">
                                Emergency Code Payment Options
                              </Form.Label>
                              <Form.Control
                                as="select"
                                type="text"
                                className="form-control"
                                name="emergency_code_payments_options"
                                id="emergency_code_payments_options"
                                defaultValue={
                                  values.emergency_code_payments_options
                                }
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                onChange={handleChange}
                              >
                                <option value="no"> No </option>
                                <option value="yes"> Yes </option>
                              </Form.Control>
                            </Form.Group>

                            {/* <Form.Group>
                              <Form.Label htmlFor="ato_code_payments_options">
                                ATO Payment Option
                              </Form.Label>
                              <Form.Control
                                as="select"
                                type="text"
                                className="form-control"
                                name="ato_code_payments_options"
                                id="ato_code_payments_options"
                                defaultValue={values.ato_code_payments_options}
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                onChange={handleChange}
                              >
                                <option value="none"> None </option>
                                <option value="bartender"> Bartender </option>
                                <option value="Togo"> ToGo </option>
                                <option value="bartender_togo">
                                  {" "}
                                  Bartender And To Go
                                </option>
                              </Form.Control>
                            </Form.Group> */}
                          </div>
                        </div>
                        {editMode && (
                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary btn-icon-text"
                            >
                              <i className="mdi mdi-file-check btn-icon-prepend"></i>{" "}
                              Update&nbsp;&nbsp;
                              {isSubmitting ? (
                                <Spinner animation="border" size="sm" />
                              ) : (
                                ""
                              )}
                            </button>

                            <button
                              className="btn btn-light"
                              onClick={() => {
                                setEditMode(false);
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
};

export default withRouter(ViewStore);
