import React, { useState, useEffect } from "react";
import { withRouter, useHistory, useParams, Link } from "react-router-dom";
import cogoToast from "cogo-toast";
import { Formik } from "formik";
import { SystemConfigService } from "../../../service/configService";
import { Button, Form, Spinner } from "react-bootstrap";
import { UserService } from "../../../service/userService";
import Select from "react-select";

const ViewDistrict = () => {
  const [editMode, setEditMode] = useState(false);
  const [district, setDistrict] = useState(null);
  const [districtManagers, setDistrictManagers] = useState(null);
  const [rManagersOptions, setRManagersOptions] = useState(null);
  const [storeOptions, setStoreOptions] = useState(null);
  const [defaultMan, setDefaultMan] = useState(null);
  const [defaultStore, setDefaultStore] = useState(null);

  const history = useHistory();
  let { id } = useParams();

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  useEffect(() => {
    let defaultOptionsArray = [];
    let defaultStoreOptions = [];
    SystemConfigService.getDistrict(id)
      .then((response) => {
        console.log(response.data.data, "This is the district");

        response.data.data.restaurant_manager.map((i, index) => {
          return defaultOptionsArray.push({
            value: i.value,
            label: i.name,
          });
        });

        response.data.data.store_selected.map((i, index) => {
          return defaultStoreOptions.push({
            value: i.value,
            label: i.name,
          });
        });
        setDefaultMan(defaultOptionsArray);
        setDefaultStore(defaultStoreOptions);
        setDistrict(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });

    UserService.getDistrictManagers()
      .then((response) => {
        setDistrictManagers(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    let storeOptionsArray = [];
    SystemConfigService.getStores()
      .then((response) => {
        let val = response.data.data;
        val.map((i, index) => {
          return storeOptionsArray.push({
            value: i.location_id,
            label: i.name.replace(/\w\S*/g, (w) =>
              w.replace(/^\w/, (c) => c.toUpperCase())
            ),
          });
        });
        setStoreOptions(storeOptionsArray);
      })
      .catch((error) => {
        console.log(error.response.data);
      });

    let rManagerOptionsArray = [];
    UserService.getRestaurantManagers()
      .then((response) => {
        let val = response.data.data;
        val.map((i, index) => {
          let name = `${i.first_name} ${i.last_name}`;
          return rManagerOptionsArray.push({
            value: i.slug,
            label: name.replace(/\w\S*/g, (w) =>
              w.replace(/^\w/, (c) => c.toUpperCase())
            ),
          });
        });
        setRManagersOptions(rManagerOptionsArray);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <div>
      <div className="page-header">
        {district && (
          <h3 className="page-title config-header-title">
            {" "}
            District <span>/ {district.name} </span>
          </h3>
        )}
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/system-configuration/all"}>Portal configuration</Link>
            </li>
            {!editMode && (
              <li className="breadcrumb-item active" aria-current="page">
                <Button
                  onClick={() => {
                    setEditMode(true);
                  }}
                >
                  {" "}
                  Edit{" "}
                </Button>
              </li>
            )}
          </ol>
        </nav>
      </div>

      {district && (
        <Formik
          initialValues={{
            id: district.id,
            name: district.name,
            manager: district.manager,
            restaurant_managers: [],
            store_selected: [],
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            SystemConfigService.updateDistrict(values)
              .then((response) => {
                console.log(response);
                setSubmitting(false);
                cogoToast.success(
                  "District updated successfully",
                  toastoptions
                );
                setEditMode(false);
                history.push("/system-configuration/view-districts");
              })
              .catch((error) => {
                console.log(error.response);
                cogoToast.error(error.response.data.message, toastoptions);
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <div className="mt-3 mb-3">
              <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card" style={{ border: "none" }}>
                    <div className="card-body">
                      <form className="pageForms" onSubmit={handleSubmit}>
                        <div className="row border-bottom mb-5">
                          <div className="col-md-6">
                            <Form.Group>
                              <label htmlFor="name"> Name </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                onChange={handleChange}
                                defaultValue={values.name}
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="state">District Manager</label>
                              <Form.Control
                                type="text"
                                as="select"
                                className="form-control"
                                id="manager"
                                name="manager"
                                value={
                                  values.manager ? values.manager : "DEFAULT"
                                }
                                onChange={handleChange}
                                disabled={!editMode}
                              >
                                <option value="DEFAULT" disabled>
                                  Select One
                                </option>

                                {districtManagers &&
                                  districtManagers.map((man, index) => (
                                    <option value={man.slug} key={index}>
                                      {" "}
                                      {man.first_name} {man.last_name}{" "}
                                    </option>
                                  ))}
                              </Form.Control>
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="state">Restaurant Managers</label>
                              <Select
                                isMulti={true}
                                onChange={(opt, e) => {
                                  console.log(opt);
                                  setFieldValue("restaurant_managers", opt);
                                }}
                                name="restaurant_managers"
                                options={
                                  rManagersOptions ? rManagersOptions : null
                                }
                                defaultValue={defaultMan}
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="store_selected">
                                {" "}
                                Select Stores{" "}
                              </label>

                              <Select
                                isMulti={true}
                                onChange={(opt, e) => {
                                  console.log(opt);
                                  setFieldValue("store_selected", opt);
                                }}
                                name="store_selected"
                                options={storeOptions}
                                defaultValue={defaultStore}
                              />
                            </Form.Group>
                          </div>
                        </div>
                        {editMode && (
                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary btn-icon-text"
                            >
                              <i className="mdi mdi-file-check btn-icon-prepend"></i>{" "}
                              Update&nbsp;&nbsp;
                              {isSubmitting ? (
                                <Spinner animation="border" size="sm" />
                              ) : (
                                ""
                              )}
                            </button>

                            <button
                              className="btn btn-light"
                              onClick={() => {
                                setEditMode(false);
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
};

export default withRouter(ViewDistrict);
