import React, { useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import SubTotal from "./SubTotal";
import AdmonFee from "./AdmonFee";
import BonusContext from "../../../contexts/bonus/BonusContext";

const OperatingIncome = ({ months }) => {
  const { dispatch, operatingIncome, admonFee, interest, subTotal, otherIncome, netProfit } =
    useContext(BonusContext);

  const initialValues = {
    incomeM1: operatingIncome ? operatingIncome.incomeM1 : "",
    incomeM1P: operatingIncome ? operatingIncome.incomeM1P : "",
    incomeM2: operatingIncome ? operatingIncome.incomeM2 : "",
    incomeM2P: operatingIncome ? operatingIncome.incomeM2P : "",
    incomeM3: operatingIncome ? operatingIncome.incomeM3 : "",
    incomeM3P: operatingIncome ? operatingIncome.incomeM3P : "",
    incomeTotal: operatingIncome ? operatingIncome.incomeTotal : "",
    incomeTotalP: operatingIncome ? operatingIncome.incomeTotalP : "",

    admonFeeM1: admonFee ? admonFee.admonFeeM1 : "",
    admonFeeM1P: admonFee ? admonFee.admonFeeM1P : "",
    admonFeeM2: admonFee ? admonFee.admonFeeM2 : "",
    admonFeeM2P: admonFee ? admonFee.admonFeeM2P : "",
    admonFeeM3: admonFee ? admonFee.admonFeeM3 : "",
    admonFeeM3P: admonFee ? admonFee.admonFeeM3P : "",
    admonFeeTotal: admonFee ? admonFee.admonFeeTotal : "",
    admonFeeTotalP: admonFee ? admonFee.admonFeeTotalP : "",

    interestM1: interest ? interest.interestM1 : "",
    interestM1P: interest ? interest.interestM1P : "",
    interestM2: interest ? interest.interestM2 : "",
    interestM2P: interest ? interest.interestM2P : "",
    interestM3: interest ? interest.interestM3 : "",
    interestM3P: interest ? interest.interestM3P : "",
    interestTotal: interest ? interest.interestTotal : "",
    interestTotalP: interest ? interest.interestTotalP : "",

    subTotalM1: subTotal ? subTotal.subTotalM1 : "",
    subTotalM1P: subTotal ? subTotal.subTotalM1P : "",
    subTotalM2: subTotal ? subTotal.subTotalM2 : "",
    subTotalM2P: subTotal ? subTotal.subTotalM2P : "",
    subTotalM3: subTotal ? subTotal.subTotalM3 : "",
    subTotalM3P: subTotal ? subTotal.subTotalM3P : "",
    subTotalTotal: subTotal ? subTotal.subTotalTotal : "",
    subTotalTotalP: subTotal ? subTotal.subTotalTotalP : "",

    otherIncomeM1: otherIncome ? otherIncome.otherIncomeM1 : "",
    otherIncomeM1P: otherIncome ? otherIncome.otherIncomeM1P : "",
    otherIncomeM2: otherIncome ? otherIncome.otherIncomeM2 : "",
    otherIncomeM2P: otherIncome ? otherIncome.otherIncomeM2P : "",
    otherIncomeM3: otherIncome ? otherIncome.otherIncomeM3 : "",
    otherIncomeM3P: otherIncome ? otherIncome.otherIncomeM3P : "",
    otherIncomeTotal: otherIncome ? otherIncome.otherIncomeTotal : "",
    otherIncomeTotalP: otherIncome ? otherIncome.otherIncomeTotalP : "",

    netProfitM1: netProfit ? netProfit.netProfitM1 : "",
    netProfitM1P: netProfit ? netProfit.netProfitM1P : "",
    netProfitM2: netProfit ? netProfit.netProfitM2 : "",
    netProfitM2P: netProfit ? netProfit.netProfitM2P : "",
    netProfitM3: netProfit ? netProfit.netProfitM3 : "",
    netProfitM3P: netProfit ? netProfit.netProfitM3P : "",
    netProfitTotal: netProfit ? netProfit.netProfitTotal : "",
    netProfitTotalP: netProfit ? netProfit.netProfitTotalP : "",
  };

  return (
    <div>
      <div className="col-lg-12 grid-margin stretch-card">
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);

            // Submit form here;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form name="sales-data" onSubmit={handleSubmit}>
              <div className="table-responsive">
                <div>
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th scope="row" width="10%"></th>
                        <td width="10%"></td>
                        <td width="10%"> {months.firstMonth}</td>
                        <td width="10%">%</td>
                        <td width="10%">{months.secondMonth}</td>
                        <td width="10%">%</td>
                        <td width="10%">{months.thirdMonth}</td>
                        <td width="10%">%</td>
                        <td width="10%">Total </td>
                        <td width="10%">%</td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{
                            padding: "0px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          Operating Income{" "}
                        </th>
                        <td
                          width="10%"
                          style={{
                            padding: "0px",
                            textAlign: "center",
                          }}
                        ></td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="incomeM1"
                            name="incomeM1"
                            defaultValue={values.incomeM1}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="incomeM1P"
                            name="incomeM1P"
                            defaultValue={values.incomeM1P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="incomeM2"
                            name="incomeM2"
                            defaultValue={values.incomeM2}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="incomeM2P"
                            name="incomeM2P"
                            defaultValue={values.incomeM2P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="incomeM3"
                            name="incomeM3"
                            defaultValue={values.incomeM3}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="incomeM3P"
                            name="incomeM3P"
                            defaultValue={values.incomeM3P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="incomeTotal"
                            name="incomeTotal"
                            defaultValue={values.incomeTotal}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="incomeTotalP"
                            name="incomeTotalP"
                            defaultValue={values.incomeTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <AdmonFee
                        isSubmitting={isSubmitting}
                        values={values}
                        handleChange={handleChange}
                      />

                      <SubTotal
                        isSubmitting={isSubmitting}
                        values={values}
                        handleChange={handleChange}
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default OperatingIncome;
