import ClosingReport from "../form-manager/ClosingReport";
import RestaurantManager from "../form-manager/closingReport/components/RestaurantManager";
import ClosingReportIssues from "../form-manager/ClosingReportIssues/ClosingReportIssues";

const formManagerRoutes = [
  { path: "/form-manager/closing-report", component: ClosingReport },
  { path: "/form-manager/restaurant-manager", component: RestaurantManager },
  { path: "/form-manager/closing-report-issues", component: ClosingReportIssues },
];

export default formManagerRoutes;
