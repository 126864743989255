import React, { useEffect, useState } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { PaymentService } from "../../service/PaymentService";
import { SystemConfigService } from "../../service/configService";
import { ConvertDateFormat } from "../../components/ConvertDateFormat";
import "moment-timezone";

const { SearchBar } = Search;

const StoreDeletedPayments = () => {
  let { store_id } = useParams();
  const [store, setStore] = useState(null);
  const [deletedPayments, setDeletedPayments] = useState(null);

  useEffect(() => {
    PaymentService.getDeletedPayment(store_id)
      .then((response) => {
        console.log(response.data.data);
        let DeletedResArray = [];
        response.data.data.map((res, index) => {
          return DeletedResArray.push({
            id: res.id,
            payrollEmployeeId: res.payrollEmployeeId,
            firstName: res.firstName,
            lastName: res.lastName,
            amount: res.amount,
            reportDate: ConvertDateFormat(res.reportDate),
            archivedBy: res.archived_by,
            archivedMessage: res.archived_message,
            deletedOn: ConvertDateFormat(res.deleted_at),
          });
        });
        setDeletedPayments(DeletedResArray);
      })
      .catch((error) => {
        console.log(error.response.data);
      });

    SystemConfigService.getStore(store_id)
      .then((response) => {
        console.log(response.data.data);
        setStore(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        // cogoToast.error(error.response.data.message, toastoptions);
      });
  }, []);

  const defaultSorted = [
    // {
    //   dataField: "payrollEmployeeId",
    //   order: "desc",
    // },
  ];

  const columns = [
    {
      dataField: "payrollEmployeeId",
      text: "Employee Id",
      sort: true,
    },
    {
      dataField: "firstName",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "lastName",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
    },
    {
      dataField: "reportDate",
      text: "Report Date",
      sort: true,
    },
    {
      dataField: "archivedBy",
      text: "Archived By",
      sort: true,
    },
    {
      dataField: "archivedMessage",
      text: "Reason",
      sort: true,
    },
    {
      dataField: "deletedOn",
      text: "Deleted On",
      sort: true,
    },
  ];

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">
          <span style={{ textTransform: "capitalize" }}>Deleted Payments</span>
        </h3>
        <div>
          <Link to={"/financials/deleted-payments"}>
            <button className="btn btn-primary">
              {" "}
              View All Deleted Payments{" "}
            </button>
          </Link>
        </div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/financials/deleted-payments">
                <span style={{ textTransform: "capitalize" }}>
                  {" "}
                  {store ? store.name : ""}
                </span>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <span style={{ textTransform: "capitalize" }}>
                Deleted Payments
              </span>
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-12">
          {deletedPayments && (
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"> Deleted Payment</h4>
                <div className="row">
                  <div className="col-12">
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={deletedPayments}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div className="d-flex align-items-center">
                            <div className="float-left">
                              <p className="mb-2 mr-2">Search in table:</p>
                              <SearchBar {...props.searchProps} />
                            </div>

                            <div className="clearfix"></div>
                          </div>
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            loading={true}
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                            id="deleted-table"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(StoreDeletedPayments);
