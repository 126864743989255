import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
// import DatePicker from "react-datepicker";
import { UserService } from "../../service/userService";
import cogoToast from "cogo-toast";
import { SystemConfigService } from "../../service/configService";

export class EditEmployee extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: "",
      last_name: "",
      middle_name: "",
      email: "",
      //   date_of_employment: new Date(),
      role: "",
      job_status: "",
      job_code_id: "",
      store_id: "",
      jobCode: null,
      employee: null,
    };

    this.handleInput = this.handleInputChange.bind(this);
  }

  toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  fetchJobCodes() {
    SystemConfigService.getJobCodes()
      .then((response) => {
        this.setState({ jobCode: response.data.data });
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }

  fetchStores() {
    SystemConfigService.getStores()
      .then((response) => {
        this.setState({ store: response.data.data });
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }

  fetchEmployeeUsingSlug() {
    const { slug } = this.props.match.params;
    UserService.getEmployeeUsingSlug(slug)
      .then((response) => {
        console.log(response.data.data, "Update");
        this.setState({ employee: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.fetchJobCodes();
    this.fetchStores();
    this.fetchEmployeeUsingSlug();
  }

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  handleDateOfEmploymentChange = (date) => {
    this.setState({ date_of_employment: date });
  };

  handleInputChange = (e) => {
    console.log(e.target.value);
    const { name, value } = e.target;
    console.log(name, value);
    this.setState({ [name]: value });
  };

  handleFormSubmission = (e) => {
    e.preventDefault();
    const {
      first_name,
      last_name,
      middle_name,
      email,
      //   date_of_employment,
      role,
      job_status,
      job_code_id,
      slug,
    } = this.state.employee;

    // if (email && first_name && last_name && job_status && job_code_id && role && store_id) {
    let data = {
      first_name,
      last_name,
      middle_name,
      email,
      //   date_of_employment,
      role,
      job_status,
      job_code_id,
      slug,
    };
    console.log(this.state.employee);
    console.log(data);
    UserService.editEmployee(data)
      .then((response) => {
        console.log(response.data);
        if (response.data.success) {
          cogoToast.success(
            "Employee uppdated successfully",
            this.toastoptions
          );
          // this.props.history.push('/employees/list');
        }
      })
      .catch((error) => {
        if (error.response.data.errors) {
          if (error.response.data.errors.email) {
            cogoToast.error(
              error.response.data.errors.email[0],
              this.toastoptions
            );
          }
          if (error.response.data.errors.first_name) {
            cogoToast.error(
              error.response.data.errors.first_name[0],
              this.toastoptions
            );
          }
          if (error.response.data.errors.last_name) {
            cogoToast.error(
              error.response.data.errors.last_name[0],
              this.toastoptions
            );
          }
          if (error.response.data.errors.job_status) {
            cogoToast.error(
              error.response.data.errors.job_status[0],
              this.toastoptions
            );
          }
          if (error.response.data.errors.job_code_id) {
            cogoToast.error(
              error.response.data.errors.job_code_id[0],
              this.toastoptions
            );
          }
          if (error.response.data.errors.role) {
            cogoToast.error(
              error.response.data.errors.role[0],
              this.toastoptions
            );
          }
          if (error.response.data.errors.date_of_employment) {
            cogoToast.error(
              error.response.data.errors.date_of_employment[0],
              this.toastoptions
            );
          }
          if (error.response.data.errors.store_id) {
            cogoToast.error(
              error.response.data.errors.store_id[0],
              this.toastoptions
            );
          }
        }
        cogoToast.error(error.response.data.message, this.toastoptions);
      });
    // } else {
    //     cogoToast.error('Please fill all the required fields', this.toastoptions);
    // }
  };

  render() {
    const { employee, jobCode } = this.state;
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Create Employee </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/employees/list"}> Employees</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Edit Employee
              </li>
            </ol>
          </nav>
        </div>

        <div className="row">
          {employee && (
            <div className="col-12">
              <div className="row">
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Employee Info </h4>
                      <form
                        className="form-sample"
                        onSubmit={this.handleFormSubmission}
                      >
                        {/* <p className="card-description"> Personal info </p> */}
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Group className="row">
                              <label className="col-sm-3 col-form-label">
                                First Name
                              </label>
                              <div className="col-sm-9">
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  id="first_name"
                                  placeholder="First Name"
                                  name="first_name"
                                  defaultValue={employee.first_name}
                                  onChange={(e) => {
                                    this.handleInputChange(e);
                                  }}
                                  //   onChange={this.handleInputChange}
                                />
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-md-6">
                            <Form.Group className="row">
                              <label className="col-sm-3 col-form-label">
                                Last Name
                              </label>
                              <div className="col-sm-9">
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  id="last_name"
                                  placeholder="Last Name"
                                  name="last_name"
                                  defaultValue={employee.last_name}
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </Form.Group>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <Form.Group className="row">
                              <label className="col-sm-3 col-form-label">
                                Middle Name
                              </label>
                              <div className="col-sm-9">
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  id="middle_name"
                                  placeholder="Middle Name"
                                  name="middle_name"
                                  value={employee.middle_name}
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-md-6">
                            <Form.Group className="row">
                              <label className="col-sm-3 col-form-label">
                                Email
                              </label>
                              <div className="col-sm-9">
                                <Form.Control
                                  type="email"
                                  disabled
                                  className="form-control"
                                  id="email"
                                  placeholder="Email"
                                  name="email"
                                  defaultValue={employee.email}
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                        <div className="row">
                          {/* <div className="col-md-6">
                            <Form.Group className="row">
                              <label className="col-sm-3 col-form-label">
                                Date of Employment
                                {Date(employee.date_of_employment)}
                              </label>
                              <div className="col-sm-9">
                                <DatePicker
                                  className="form-control w-100"
                                  name={"date_of_employment"}
                                  defaultValue={employee.date_of_employment}
                                  selected={this.state.date_of_employment}
                                  onChange={this.handleDateOfEmploymentChange}
                                />
                              </div>
                            </Form.Group>
                          </div> */}

                          <div className="col-md-6">
                            <Form.Group className="row">
                              <label className="col-sm-3 col-form-label">
                                Job Status
                              </label>
                              <div className="col-sm-9">
                                <select
                                  className="form-control"
                                  id="job_status"
                                  name="job_status"
                                  defaultValue={
                                    employee.job_status
                                      ? employee.job_status
                                      : ""
                                  }
                                  onChange={this.handleInputChange}
                                >
                                  <option value={""}>Select Job Status</option>
                                  <option value="part time">Part Time</option>
                                  <option value="full time">Full Time</option>
                                  <option value="hourly"> Hourly </option>
                                  <option value="salary exempt">
                                    Salary Exempt
                                  </option>
                                  <option value="salary non exempt">
                                    Manager
                                  </option>
                                </select>
                              </div>
                            </Form.Group>
                          </div>

                          <div className="col-md-6">
                            <Form.Group className="row">
                              <label className="col-sm-3 col-form-label">
                                Role
                              </label>
                              <div className="col-sm-9">
                                <select
                                  className="form-control"
                                  id="role"
                                  name="role"
                                  defaultValue={
                                    employee.role ? employee.role : ""
                                  }
                                  onChange={this.handleInputChange}
                                >
                                  <option value={""}>Select Role</option>
                                  <option value={"employee"}>Employee</option>
                                  <option value={"super admin"}>
                                    Super Admin
                                  </option>
                                  <option value={"store admin"}>
                                    Store Admin
                                  </option>
                                  <option value={"store manager"}>
                                    Store Manager
                                  </option>
                                  <option value={"manager"}>Manager</option>
                                  <option value={"accountant"}>
                                    Accountant
                                  </option>
                                </select>
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Group className="row">
                              <label className="col-sm-3 col-form-label">
                                Job Code
                              </label>
                              <div className="col-sm-9">
                                <select
                                  className="form-control"
                                  id="job_code_id"
                                  name="job_code_id"
                                  defaultValue={
                                    employee.job_code_id
                                      ? employee.job_code_id
                                      : ""
                                  }
                                  onChange={this.handleInputChange}
                                >
                                  <option
                                    value={
                                      employee.job_code_id
                                        ? employee.job_code_id
                                        : ""
                                    }
                                  >
                                    Select Job Code
                                  </option>
                                  {jobCode &&
                                    jobCode.map((value) => (
                                      <option value={value.id} key={value.id}>
                                        {value.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                        <button type="submit" className="btn btn-primary mr-2">
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default EditEmployee;
