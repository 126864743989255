import React, { useState, useEffect } from "react";
import { Tab } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import AppSpinner from "../../../components/Spinner";
import { SystemConfigService } from "../../../service/configService";

const ViewStores = () => {
  const [stores, setStores] = useState(null);

  useEffect(() => {
    SystemConfigService.getStoresForManagerAdmin()
      .then((response) => {
        console.log(response.data);
        setStores(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);

  return (
    <div>
      <div className="row">
        <div className="tab-custom-pills-horizontal col-md-12">
          <div className="float-left">
            <span className="card-description"> Stores </span>{" "}
            &nbsp;&nbsp;&nbsp;
            <small className="card-description">
              <i> ( Click on the box for details ) </i>
            </small>
          </div>
          <div className="float-right">
            <span>
              <Link to={"/system-configuration/all"}>Portal configuration</Link>
            </span>
            &nbsp;&nbsp;&nbsp;
            <Link to={"/system-configuration/store"}>
              <button type="button" className="btn btn-primary btn-rounded">
                Create Store
              </button>
            </Link>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>

      <div className="mt-3">
        <Tab.Pane eventKey="domains">
          <div className="row">
            {stores ? (
              stores.map((value, index) => (
                <div className="col-md-3 mb-5" key={index}>
                  <div className="domainBox">
                    <Link
                      to={`/system-configuration/view-store/${value.location_id}`}
                    >
                      <div className="react-aspect-ratio-placeholder">
                        <div className="sc-fzoiQi ozSmQ  ">
                          <main>
                            <h4 style={{ textTransform: "capitalize" }}>
                              {" "}
                              {value.name}
                            </h4>
                            <small>{value.address}</small>
                            <br />
                            <br />
                            <small>
                              <b>({value.location_id})</b>
                            </small>
                          </main>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              <AppSpinner />
            )}
          </div>
        </Tab.Pane>
      </div>
    </div>
  );
};

export default withRouter(ViewStores);
