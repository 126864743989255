import React, { useState, useEffect } from "react";
import { withRouter, useHistory, useParams, Link } from "react-router-dom";
import cogoToast from "cogo-toast";
import { Formik } from "formik";
import { SystemConfigService } from "../../../service/configService";
import { Button, Form, Spinner } from "react-bootstrap";

const ViewJobCode = () => {
  const [editMode, setEditMode] = useState(false);
  const [jobCode, setJobCode] = useState(null);

  const history = useHistory();
  let { id } = useParams();

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  useEffect(() => {
    SystemConfigService.getJobCode(id)
      .then((response) => {
        console.log(response.data);
        setJobCode(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, [id]);

  return (
    <div>
      <div className="page-header">
        {jobCode && (
          <h3 className="page-title config-header-title">
            {" "}
            Job Code <span>/ {jobCode.name} </span>
          </h3>
        )}
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/system-configuration/all"}>Portal configuration</Link>
            </li>
            {!editMode && (
              <li className="breadcrumb-item active" aria-current="page">
                <Button
                  onClick={() => {
                    setEditMode(true);
                  }}
                >
                  {" "}
                  Edit{" "}
                </Button>
              </li>
            )}
          </ol>
        </nav>
      </div>

      {jobCode && (
        <Formik
          initialValues={{
            id: jobCode.id,
            name: jobCode.name,
            pos_code: jobCode.pos_code,
            crunch_time_code: jobCode.crunch_time_code,
            payroll_code: jobCode.payroll_code,
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            SystemConfigService.updateJobCode(values)
              .then((response) => {
                console.log(response);
                setSubmitting(false);
                cogoToast.success(
                  "Job Code updated successfully",
                  toastoptions
                );
                setEditMode(false);
                history.push("/system-configuration/view-job-codes");
              })
              .catch((error) => {
                console.log(error.response);
                cogoToast.error(error.response.data.message, toastoptions);
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <div className="mt-3 mb-3">
              <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card" style={{ border: "none" }}>
                    <div className="card-body">
                      <form className="pageForms" onSubmit={handleSubmit}>
                        <div className="row border-bottom mb-5">
                          <div className="col-md-6">
                            <Form.Group>
                              <label htmlFor="name"> Name </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                placeholder="Name of the domain"
                                disabled={!editMode}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                onChange={handleChange}
                                defaultValue={values.name}
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="pos_code">POS Code </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="name"
                                name="pos_code"
                                defaultValue={values.pos_code}
                                disabled={!editMode}
                                onChange={handleChange}
                                placeholder="POS Code "
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="crunch_time_code">
                                Crunch Time Code{" "}
                              </label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="crunch_time_code"
                                name="crunch_time_code"
                                defaultValue={values.crunch_time_code}
                                disabled={!editMode}
                                onChange={handleChange}
                                placeholder="Crunch Time Code "
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="payroll_code">Payroll Code</label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="payroll_code"
                                name="payroll_code"
                                defaultValue={values.payroll_code}
                                disabled={!editMode}
                                onChange={handleChange}
                                placeholder="Payroll Code "
                              />
                            </Form.Group>
                          </div>
                        </div>
                        {editMode && (
                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary btn-icon-text"
                            >
                              <i className="mdi mdi-file-check btn-icon-prepend"></i>{" "}
                              Update&nbsp;&nbsp;
                              {isSubmitting ? (
                                <Spinner animation="border" size="sm" />
                              ) : (
                                ""
                              )}
                            </button>

                            <button
                              className="btn btn-light"
                              onClick={() => {
                                setEditMode(false);
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
};

export default withRouter(ViewJobCode);
