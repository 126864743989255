import React from "react";
import { FileUpload } from "./FileUpload";

export const LaborInfoReportUpload = ({ setFieldValue }) => {
  return (
    <FileUpload
      setFieldValue={setFieldValue}
      fieldName="labor_info_report_attachement"
      acceptedTypes=".csv, .xlsx"
      label="Uploaded Labor Info Report"
    />
  );
};
