import React from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import { SystemConfigService } from "../../../service/configService";
import cogoToast from "cogo-toast";
import { Formik } from "formik";

const JobCode = () => {
  const history = useHistory();

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Job Codes </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/system-configuration/all"}>Portal Configuration</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={"/system-configuration/view-job-codes"}>Job Codes</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              view all
            </li>
          </ol>
        </nav>
      </div>
      <Formik
        initialValues={{
          name: "",
          pos_code: "",
          crunch_time_code: "",
          payroll_code: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          if (values.name) {
            SystemConfigService.createJobCode(values)
              .then((response) => {
                console.log(response.data);
                if (response.data.success) {
                  setSubmitting(false);
                  cogoToast.success(
                    "Job Code created successfully",
                    toastoptions
                  );
                  history.push("/system-configuration/view-job-codes");
                }
              })
              .catch((error) => {
                console.log(error.response.data.message);
                if (error.response.data.errors) {
                  cogoToast.error(
                    error.response.data.errors.name[0],
                    toastoptions
                  );
                }
                cogoToast.error(error.response.data.message, toastoptions);
                setSubmitting(false);
              });
          } else {
            cogoToast.error("Please fill all the fields", toastoptions);
            setSubmitting(false);
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
          <div className="row">
            <div className="col-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">
                    Fill the details below to create a job code
                  </h4>
                  <form
                    className="forms-sample"
                    name="form"
                    onSubmit={handleSubmit}
                  >
                    <Form.Group>
                      <label htmlFor="name">Name</label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={values.name}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        placeholder="Job code "
                      />
                    </Form.Group>

                    <Form.Group>
                      <label htmlFor="pos_code">POS Code </label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="name"
                        name="pos_code"
                        value={values.pos_code}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        placeholder="POS Code"
                      />
                    </Form.Group>

                    <Form.Group>
                      <label htmlFor="crunch_time_code">
                        Crunch Time Code{" "}
                      </label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="crunch_time_code"
                        name="crunch_time_code"
                        value={values.crunch_time_code}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        placeholder="Crunch Time Code"
                      />
                    </Form.Group>

                    <Form.Group>
                      <label htmlFor="payroll_code">Payroll Code</label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="payroll_code"
                        name="payroll_code"
                        value={values.payroll_code}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        placeholder="Payroll Code"
                      />
                    </Form.Group>

                    <button type="submit" className="btn btn-primary mr-2">
                      Submit &nbsp;&nbsp;
                      {isSubmitting ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        ""
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default withRouter(JobCode);
