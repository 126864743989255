import cogoToast from "cogo-toast";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useParams, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { ConvertDateFormat } from "../../components/ConvertDateFormat";
import { SystemConfigService } from "../../service/configService";
import { SalesService } from "../../service/SalesService";
const { SearchBar } = Search;

const SalesReportList = () => {
  let { store_id } = useParams();
  const [store, setStore] = useState(null);
  const [reportData, setReportData] = useState(null);

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "report_date",
      text: "Report Date",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <div>
            {/* <button
              className="btn btn-outline-primary"
              onClick={() => setMdShow(true)}
            >
              <i className="mdi mdi-eye-outline text-primary"></i>View More
            </button> */}

            <Link to={`/percentage/view-details/${store_id}/${row.id}`}>
              <button className="btn btn-outline-primary">
                <i className="mdi mdi-eye-outline text-primary"></i>View Details
              </button>
            </Link>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  useEffect(() => {
    const data = { store_id };
    SalesService.getPercentageSalesForStore(data)
      .then((response) => {
        let dataResArray = [];
        response.data.data.map((res, index) => {
          return dataResArray.push({
            id: res.id,
            report_date: ConvertDateFormat(res.report_date),
          });
        });
        setReportData(dataResArray);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });

    SystemConfigService.getStore(store_id)
      .then((response) => {
        setStore(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        cogoToast.error(error.response.data.message, toastoptions);
      });
  }, []);

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">
          <span style={{ textTransform: "capitalize" }}>
            {store ? store.name : ""}
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span>
            <Link to={"/percentage/report"}>
              <button className="btn btn-primary">Back</button>
            </Link>
          </span>
        </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">La Parrilla</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <span style={{ textTransform: "capitalize" }}>
                {store ? store.name : ""}
              </span>
            </li>
          </ol>
        </nav>
      </div>

      <div className="row">
        {reportData && (
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"> Percentage Sales Report </h4>
                <div className="row">
                  <div className="col-12">
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={reportData}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div className="d-flex align-items-center">
                            <p className="mb-2 mr-2">Search in table:</p>
                            <SearchBar {...props.searchProps} />
                          </div>
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(SalesReportList);
