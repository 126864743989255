import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Form, Tabs, Tab } from "react-bootstrap";
// import { Link, useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import { PaymentService } from "../../service/PaymentService";
import DatePickerField from "../../components/Datepicker";

const ReportsHistories = () => {
  // let { store_id, id } = useParams();
  const [salesReport, setSalesReport] = useState(null);
  const [expenses, setExpenses] = useState(null);
  // const [totalExpenses, setTotalExpenses] = useState(null);
  const [pendingPayments, setPendingPayments] = useState(null);
  const [cashDepositData, setCashDepositData] = useState(null);
  const [serverPayment, setServerPayment] = useState(null);
  const [barTenderPayment, setBarTenderPayment] = useState(null);

  useEffect(() => {
    var btn = document.getElementsByTagName("button")[5];

    if (btn) {
      if (btn.disabled) {
        btn.disabled = false;
      }
    }

    if (JSON.parse(sessionStorage.getItem("newCompletedSalesReport"))) {
      const sales_report = JSON.parse(
        sessionStorage.getItem("newCompletedSalesReport")
      );

      console.log(sales_report, "The new sales report from the backend");

      setSalesReport(
        JSON.parse(sessionStorage.getItem("newCompletedSalesReport"))
      );

      PaymentService.getPaymentsStoreDateHistories(
        sales_report.owner,
        sales_report.report_date
      )
        .then((response) => {
          console.log(response.data.data);
          setServerPayment(response.data.data.servers);
          setBarTenderPayment(response.data.data.bartenders);
          setPendingPayments(response.data.data.pendingPayments);
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    }

    // if (JSON.parse(sessionStorage.getItem("tipIncomeReport"))) {
    //   console.log(JSON.parse(sessionStorage.getItem("tipIncomeReport")));
    //   setTipIncomeReport(JSON.parse(sessionStorage.getItem("tipIncomeReport")));
    // }

    // if (JSON.parse(sessionStorage.getItem("barTenderPayment"))) {
    //   console.log(JSON.parse(sessionStorage.getItem("barTenderPayment")));
    //   setBarTenderPayment(
    //     JSON.parse(sessionStorage.getItem("barTenderPayment"))
    //   );
    // }

    if (JSON.parse(sessionStorage.getItem("cashDepositData"))) {
      console.log(JSON.parse(sessionStorage.getItem("cashDepositData")));
      setCashDepositData(JSON.parse(sessionStorage.getItem("cashDepositData")));
    }

    if (JSON.parse(sessionStorage.getItem("expenses"))) {
      console.log(JSON.parse(sessionStorage.getItem("expenses")));
      const exp_tot = JSON.parse(sessionStorage.getItem("expenses"));
      console.log(exp_tot);
      // let exp_tot_val = 0;
      // exp_tot.forEach((element) => {
      //   console.log(element);
      // });
      // (val, index) => {
      //   console.log(val, index);
      // };

      setExpenses(JSON.parse(sessionStorage.getItem("expenses")));
    }
  }, [setSalesReport]);

  const roundNumberUp = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  return (
    <section className="content" id="previewStage">
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              {/* <h4 className="card-title">Basic Tab</h4> */}
              <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                <Tab eventKey="home" title="Final Report" className="test-tab">
                  <div>
                    <Formik
                      initialValues={{
                        store_id: salesReport
                          ? parseInt(salesReport.owner)
                          : "",
                        store: salesReport ? salesReport.location : "",
                        date: salesReport ? salesReport.report_date : "",
                        manager_name: salesReport ? salesReport.printed_by : "",
                        auto_truck: expenses ? expenses.auto_truck : "",
                        cleaning: expenses ? expenses.cleaning : "",
                        food_purchase: expenses ? expenses.food_purchases : "",
                        office_supplies: expenses
                          ? expenses.office_supplies
                          : "",
                        repair: expenses ? expenses.repair : "",
                        rest_supplies: expenses ? expenses.rest_supplies : "",
                        gift_promotion: expenses ? expenses.gift_promotion : "",
                        other: expenses ? expenses.other : "",
                        net_sales: salesReport ? salesReport.net_sales : "",
                        taxes: salesReport ? salesReport.taxes : "",
                        tips_credit_card: salesReport
                          ? salesReport.tips_credit_card
                          : "",
                        auto_gratuity: salesReport
                          ? salesReport.auto_gratuity
                          : "",
                        tip_share_collected: salesReport
                          ? salesReport.tip_share_collected
                          : "",
                        gift_card_sold: salesReport
                          ? salesReport.gift_card_sold
                          : "",
                        charities: salesReport ? salesReport.charities : "",
                        total_sales: salesReport ? salesReport.total : "",
                        total_credit_and_gift_cards: salesReport
                          ? salesReport.total_credit_and_gift_cards
                          : "",
                      }}
                      enableReinitialize
                      onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        console.log(values);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                      }) => (
                        <form
                          name="expenses_form"
                          id="expenses_form"
                          onSubmit={handleSubmit}
                        >
                          <div className="row">
                            <div className="col-md-6 grid-margin">
                              <h4 className="card-title">Closing Report</h4>

                              <Form.Group className="row">
                                <label
                                  htmlFor="store"
                                  className="col-sm-4 col-form-label"
                                >
                                  Parrilla #
                                </label>
                                <div className="col-sm-8">
                                  <Form.Control
                                    type="text"
                                    className="form-control"
                                    id="store"
                                    name="store"
                                    value={values.store}
                                    placeholder="Location"
                                    disabled={"disabled"}
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                    onChange={handleChange}
                                  />
                                </div>
                              </Form.Group>

                              <Form.Group className="row">
                                <label
                                  htmlFor="exampleInputMobile"
                                  className="col-sm-4 col-form-label"
                                >
                                  Date
                                </label>
                                <div className="col-sm-8">
                                  <DatePickerField
                                    name="date"
                                    autoComplete="off"
                                    dateFormat="MM/dd/yyyy"
                                    disabled={"disabled"}
                                    defaultValue={values.date}
                                  />
                                </div>
                              </Form.Group>

                              <Form.Group className="row">
                                <label
                                  htmlFor="store"
                                  className="col-sm-4 col-form-label"
                                >
                                  Manager Name
                                </label>
                                <div className="col-sm-8">
                                  <Form.Control
                                    type="text"
                                    className="form-control"
                                    id="manager_name"
                                    name="manager_name"
                                    value={values.manager_name}
                                    placeholder="Enter amount"
                                    disabled={"disabled"}
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                    onChange={handleChange}
                                  />
                                </div>
                              </Form.Group>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div
                                // className="table-responsive"
                                style={{ marginTop: "60px" }}
                              >
                                <table className="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th style={{ width: "40%" }}>
                                        {" "}
                                        Revenue{" "}
                                      </th>
                                      <th style={{ width: "60%" }}> Amount </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td> Net Sales </td>

                                      <td className="amountRow">
                                        <Form.Group>
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                $
                                              </span>
                                            </div>

                                            <Form.Control
                                              type="text"
                                              className="form-control"
                                              id="net_sales"
                                              name="net_sales"
                                              value={values.net_sales}
                                              placeholder="Enter amount"
                                              autoComplete="off"
                                              autoCorrect="off"
                                              autoCapitalize="off"
                                              spellCheck="false"
                                              disabled={"disabled"}
                                              onChange={handleChange}
                                            />
                                          </div>
                                        </Form.Group>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td> Taxes ( Sales & Liquor ) </td>

                                      <td className="amountRow">
                                        <Form.Group>
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                $
                                              </span>
                                            </div>

                                            <Form.Control
                                              type="text"
                                              className="form-control"
                                              id="taxes"
                                              name="taxes"
                                              defaultValue={values.taxes}
                                              placeholder="Enter amount"
                                              autoComplete="off"
                                              autoCorrect="off"
                                              autoCapitalize="off"
                                              spellCheck="false"
                                              disabled={"disabled"}
                                              onChange={handleChange}
                                            />
                                          </div>
                                        </Form.Group>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td> Tips - Credit Cards </td>

                                      <td className="amountRow">
                                        <Form.Group>
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                $
                                              </span>
                                            </div>

                                            <Form.Control
                                              type="text"
                                              className="form-control"
                                              id="tips_credit_card"
                                              name="tips_credit_card"
                                              defaultValue={
                                                values.tips_credit_card
                                              }
                                              placeholder="Enter amount"
                                              autoComplete="off"
                                              autoCorrect="off"
                                              autoCapitalize="off"
                                              spellCheck="false"
                                              disabled={"disabled"}
                                              onChange={handleChange}
                                            />
                                          </div>
                                        </Form.Group>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td> Autogratuity </td>

                                      <td className="amountRow">
                                        <Form.Group>
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                $
                                              </span>
                                            </div>

                                            <Form.Control
                                              type="text"
                                              className="form-control"
                                              id="auto_gratuity"
                                              name="auto_gratuity"
                                              defaultValue={
                                                values.auto_gratuity
                                              }
                                              placeholder="Enter amount"
                                              autoComplete="off"
                                              autoCorrect="off"
                                              autoCapitalize="off"
                                              spellCheck="false"
                                              disabled={"disabled"}
                                              onChange={handleChange}
                                            />
                                          </div>
                                        </Form.Group>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td> Tip Share Collected </td>

                                      <td className="amountRow">
                                        <Form.Group>
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                $
                                              </span>
                                            </div>

                                            <Form.Control
                                              type="text"
                                              className="form-control"
                                              id="tip_share_collected"
                                              name="tip_share_collected"
                                              defaultValue={
                                                values.tip_share_collected
                                              }
                                              placeholder="Enter amount"
                                              autoComplete="off"
                                              autoCorrect="off"
                                              autoCapitalize="off"
                                              spellCheck="false"
                                              disabled={"disabled"}
                                              onChange={handleChange}
                                            />
                                          </div>
                                        </Form.Group>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td> Gift Card Sold </td>

                                      <td className="amountRow">
                                        <Form.Group>
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                $
                                              </span>
                                            </div>

                                            <Form.Control
                                              type="text"
                                              className="form-control"
                                              id="gift_card_sold"
                                              name="gift_card_sold"
                                              defaultValue={
                                                values.gift_card_sold
                                              }
                                              placeholder="Enter amount"
                                              autoComplete="off"
                                              autoCorrect="off"
                                              autoCapitalize="off"
                                              spellCheck="false"
                                              disabled={"disabled"}
                                              onChange={handleChange}
                                            />
                                          </div>
                                        </Form.Group>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td> Charities </td>

                                      <td className="amountRow">
                                        <Form.Group>
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                $
                                              </span>
                                            </div>

                                            <Form.Control
                                              type="text"
                                              className="form-control"
                                              id="charities"
                                              name="charities"
                                              defaultValue={values.charities}
                                              placeholder="Enter amount"
                                              autoComplete="off"
                                              autoCorrect="off"
                                              autoCapitalize="off"
                                              spellCheck="false"
                                              disabled={"disabled"}
                                              onChange={handleChange}
                                            />
                                          </div>
                                        </Form.Group>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td> Total Revenues </td>

                                      <td className="amountRow">
                                        <Form.Group>
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                $
                                              </span>
                                            </div>

                                            <Form.Control
                                              type="text"
                                              className="form-control"
                                              id="total_sales"
                                              name="total_sales"
                                              defaultValue={values.total_sales}
                                              placeholder="Enter amount"
                                              autoComplete="off"
                                              autoCorrect="off"
                                              autoCapitalize="off"
                                              spellCheck="false"
                                              disabled={"disabled"}
                                              onChange={handleChange}
                                            />
                                          </div>
                                        </Form.Group>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <div
                              className="col-md-6"
                              style={{ marginTop: "60px" }}
                            >
                              <p> Tips paid to bartenders </p>

                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th style={{ width: "10%" }}> Number </th>
                                    <th style={{ width: "20%" }}>
                                      {" "}
                                      Bartender{" "}
                                    </th>
                                    <th style={{ width: "60%" }}> Tips </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {barTenderPayment &&
                                    barTenderPayment.map((tip, index) => (
                                      <tr key={tip.payrollEmployeeId}>
                                        <td>{tip.payrollEmployeeId}</td>
                                        <td>
                                          {tip.firstName} {tip.lastName}
                                        </td>
                                        <td>
                                          <Form.Group>
                                            <div className="input-group">
                                              <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                  $
                                                </span>
                                              </div>
                                              <Form.Control
                                                type="number"
                                                className="form-control"
                                                id="food_purchase"
                                                name="food_purchases"
                                                defaultValue={roundNumberUp(
                                                  tip.amount
                                                )}
                                                placeholder="Enter amount"
                                                autoComplete="off"
                                                autoCorrect="off"
                                                autoCapitalize="off"
                                                spellCheck="false"
                                                disabled={"disabled"}
                                                onChange={handleChange}
                                              />
                                            </div>
                                          </Form.Group>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div className="row" style={{ marginTop: "60px" }}>
                            <div className="col-md-6">
                              <div className="col-md-12">
                                <table className="table table-bordered">
                                  <tbody>
                                    <tr>
                                      <td> Total Credit and Gift Cards </td>

                                      <td className="amountRow">
                                        <Form.Group>
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                $
                                              </span>
                                            </div>

                                            <Form.Control
                                              type="text"
                                              className="form-control"
                                              id="total_credit_and_gift_cards"
                                              name="total_credit_and_gift_cards"
                                              defaultValue={
                                                values.total_credit_and_gift_cards
                                              }
                                              placeholder="Enter amount"
                                              autoComplete="off"
                                              autoCorrect="off"
                                              autoCapitalize="off"
                                              spellCheck="false"
                                              disabled={"disabled"}
                                              onChange={handleChange}
                                            />
                                          </div>
                                        </Form.Group>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                <table className="table table-bordered">
                                  <tbody>
                                    <tr>
                                      <td> Total Expenses </td>

                                      <td className="amountRow">
                                        <Form.Group>
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                $
                                              </span>
                                            </div>

                                            <Form.Control
                                              type="text"
                                              className="form-control"
                                              id="total_credit_and_gift_cards"
                                              name="total_credit_and_gift_cards"
                                              defaultValue={
                                                cashDepositData
                                                  ? cashDepositData.total_expenses
                                                  : ""
                                              }
                                              placeholder="Enter amount"
                                              autoComplete="off"
                                              autoCorrect="off"
                                              autoCapitalize="off"
                                              spellCheck="false"
                                              disabled={"disabled"}
                                              onChange={handleChange}
                                            />
                                          </div>
                                        </Form.Group>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div>
                                <p> Tips paid to servers </p>
                                <table className="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th style={{ width: "10%" }}> Number </th>
                                      <th style={{ width: "50%" }}> Server </th>
                                      <th style={{ width: "40%" }}> Tips </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {serverPayment &&
                                      serverPayment.map((serve, index) => (
                                        <tr key={serve.payrollEmployeeId}>
                                          <td> {serve.payrollEmployeeId} </td>
                                          <td>
                                            {" "}
                                            {serve.firstName} {serve.lastName}{" "}
                                          </td>

                                          <td className="amountRow">
                                            <Form.Group>
                                              <div className="input-group">
                                                <div className="input-group-prepend">
                                                  <span className="input-group-text">
                                                    $
                                                  </span>
                                                </div>

                                                <Form.Control
                                                  type="text"
                                                  className="form-control"
                                                  id="tip_owed"
                                                  name="tip_owed"
                                                  defaultValue={roundNumberUp(
                                                    serve.amount
                                                  )}
                                                  placeholder="Enter amount"
                                                  autoComplete="off"
                                                  autoCorrect="off"
                                                  autoCapitalize="off"
                                                  spellCheck="false"
                                                  disabled={"disabled"}
                                                  onChange={handleChange}
                                                />
                                              </div>
                                            </Form.Group>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                              <div>
                                <p> Failed Instant Pay Transactions </p>
                                <table className="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th style={{ width: "10%" }}> Number </th>
                                      <th style={{ width: "50%" }}> Server </th>
                                      <th style={{ width: "40%" }}> Tips </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {pendingPayments &&
                                      pendingPayments.map((pay, index) => (
                                        <tr key={pay.payrollEmployeeId}>
                                          <td> {pay.payrollEmployeeId} </td>
                                          <td>
                                            {" "}
                                            {pay.firstName} {pay.lastName}{" "}
                                          </td>

                                          <td className="amountRow">
                                            <NumberFormat
                                              defaultValue={roundNumberUp(
                                                pay.amount
                                              )}
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              prefix={"$"}
                                            />
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>

                          <div>
                            {cashDepositData && (
                              <div className="mt-5">
                                <Formik
                                  initialValues={{}}
                                  onSubmit={(values, { setSubmitting }) => {}}
                                >
                                  {({
                                    values,
                                    errors,
                                    touched,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                  }) => (
                                    <div>
                                      <div className="depositForm">
                                        <div>
                                          <div>
                                            <h3 className="mb-4">Deposit</h3>
                                          </div>

                                          <div>
                                            <div
                                              className="form-inline"
                                              id="form-inline"
                                            >
                                              <div className="col-md-3">
                                                <label htmlFor="aloha_deposit">
                                                  Aloha Deposit
                                                </label>
                                                <Form.Group>
                                                  <div className="input-group">
                                                    <div className="input-group-prepend">
                                                      <span className="input-group-text">
                                                        $
                                                      </span>
                                                    </div>
                                                    <Form.Control
                                                      type="number"
                                                      className="form-control"
                                                      id="aloha_deposit"
                                                      name="aloha_deposit"
                                                      value={
                                                        cashDepositData.aloha_deposit
                                                      }
                                                      placeholder="Enter amount"
                                                      autoComplete="off"
                                                      autoCorrect="off"
                                                      autoCapitalize="off"
                                                      spellCheck="false"
                                                      disabled={"disabled"}
                                                    />
                                                  </div>
                                                </Form.Group>
                                              </div>
                                              -
                                              <div className="col-md-3">
                                                <label
                                                  className=""
                                                  htmlFor="total_expenses"
                                                >
                                                  Total Expenses
                                                </label>
                                                <Form.Group>
                                                  <div className="input-group">
                                                    <div className="input-group-prepend">
                                                      <span className="input-group-text">
                                                        $
                                                      </span>
                                                    </div>
                                                    <Form.Control
                                                      type="number"
                                                      className="form-control"
                                                      id="total_expenses"
                                                      name="total_expenses"
                                                      value={
                                                        cashDepositData.total_expenses
                                                      }
                                                      placeholder="Enter amount"
                                                      autoComplete="off"
                                                      autoCorrect="off"
                                                      autoCapitalize="off"
                                                      spellCheck="false"
                                                      disabled={"disabled"}
                                                    />
                                                  </div>
                                                </Form.Group>
                                              </div>
                                              =
                                              <div className="col-md-4">
                                                <label
                                                  className=""
                                                  htmlFor="sales_deposit_calculated"
                                                >
                                                  Total Deposit (Calculated)
                                                </label>
                                                <Form.Group>
                                                  <div
                                                    className="input-group"
                                                    id="total_deposit_cal"
                                                  >
                                                    <div className="input-group-prepend">
                                                      <span className="input-group-text">
                                                        $
                                                      </span>
                                                    </div>
                                                    <Form.Control
                                                      type="number"
                                                      className="form-control"
                                                      id="sales_deposit_calculated"
                                                      name="sales_deposit_calculated"
                                                      value={
                                                        cashDepositData.sales_deposit_calculated
                                                      }
                                                      placeholder="Enter amount"
                                                      autoComplete="off"
                                                      autoCorrect="off"
                                                      autoCapitalize="off"
                                                      spellCheck="false"
                                                      disabled={"disabled"}
                                                    />
                                                  </div>
                                                </Form.Group>
                                              </div>
                                            </div>
                                          </div>

                                          <table className="table table-bordered mt-5">
                                            <tbody>
                                              <tr>
                                                <td style={{ width: "50%" }}>
                                                  <div>
                                                    <label htmlFor="cash_to_deposit">
                                                      Cash to be Deposit
                                                    </label>
                                                    <Form.Group>
                                                      <div className="input-group">
                                                        <div className="input-group-prepend">
                                                          <span className="input-group-text">
                                                            $
                                                          </span>
                                                        </div>
                                                        <Form.Control
                                                          type="number"
                                                          className="form-control"
                                                          id="cash_to_deposit"
                                                          name="cash_to_deposit"
                                                          value={
                                                            cashDepositData.cash_to_deposit
                                                          }
                                                          placeholder="Enter amount"
                                                          step="any"
                                                          autoComplete="off"
                                                          autoCorrect="off"
                                                          autoCapitalize="off"
                                                          spellCheck="false"
                                                          disabled={"disabled"}
                                                        />
                                                      </div>
                                                    </Form.Group>
                                                  </div>
                                                </td>

                                                <td style={{ width: "50%" }}>
                                                  {cashDepositData.over_or_short && (
                                                    <div>
                                                      <label htmlFor="over_or_short">
                                                        {cashDepositData.over_or_short &&
                                                        cashDepositData.over_or_short <
                                                          0
                                                          ? "Shortage"
                                                          : "Overage"}
                                                      </label>
                                                      <Form.Group>
                                                        <div className="input-group">
                                                          <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                              $
                                                            </span>
                                                          </div>
                                                          <Form.Control
                                                            type="number"
                                                            className="form-control"
                                                            id="over_or_short"
                                                            name="over_or_short"
                                                            value={roundNumberUp(
                                                              cashDepositData.over_or_short
                                                            )}
                                                            placeholder="Enter amount"
                                                            autoComplete="off"
                                                            autoCorrect="off"
                                                            autoCapitalize="off"
                                                            spellCheck="false"
                                                            disabled={
                                                              "disabled"
                                                            }
                                                          />
                                                        </div>
                                                      </Form.Group>
                                                    </div>
                                                  )}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>

                                        {cashDepositData.over_or_short && (
                                          <div className="mt-3">
                                            <p>
                                              Please explain the
                                              {cashDepositData.over_or_short &&
                                              cashDepositData.over_or_short < 0
                                                ? " shortage "
                                                : " overage "}
                                              of the deposit amount
                                            </p>
                                            <Form.Group>
                                              <Form.Control
                                                as="textarea"
                                                rows="3"
                                                type="text"
                                                className="form-control"
                                                id="reason_for_short_or_over"
                                                name="reason_for_short_or_over"
                                                value={
                                                  cashDepositData.reason_for_short_or_over
                                                }
                                                placeholder="Please Explain"
                                                autoComplete="off"
                                                autoCorrect="off"
                                                autoCapitalize="off"
                                                spellCheck="false"
                                                disabled={"disabled"}
                                              />
                                            </Form.Group>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </Formik>
                              </div>
                            )}
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                  {/* <div className="row">
                    <div className="col-md-12">
                      <div style={{ marginTop: "60px" }}>
                        <Link to={"/form-manager/preview-closing-report"}>
                          <button
                            type="button"
                            className="btn btn-primary mr-2"
                          >
                            Preview and Print PDF &nbsp;&nbsp;
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div> */}
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReportsHistories;
