import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";
import { Form, Spinner } from "react-bootstrap";
import cogoToast from "cogo-toast";
import { useAuth } from "../../contexts/AuthContext";
import InputField from "../../components/common/InputField";

const Login = () => {
  const history = useHistory();
  const { login, user } = useAuth();

  useEffect(() => {
    if (user) {
      history.push("/dashboard");
    }
  }, [user, history]);

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
          const userData = await login(values.email, values.password);

          if (userData?.first_login) {
            cogoToast.info("You need to change your password", {
              hideAfter: 5,
              position: "top-right",
              heading: "Attention",
            });
            history.push("/auth/first-login", { email: values.email });
          } else {
            cogoToast.success("Logged in successfully", {
              hideAfter: 5,
              position: "top-right",
              heading: "Success",
            });
            history.push("/dashboard");
          }
        } catch (error) {
          cogoToast.error(error.message);
          cogoToast.error(error.response?.data?.message || "An error occurred", {
            hideAfter: 5,
            position: "top-right",
            heading: "Error",
          });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <div className="d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="text-center">
                  <div className="brand-logo">
                    <Link to={"/home/login"}>
                      <img src={require("../../assets/images/la-parrila-logo.png")} alt="logo" />
                    </Link>
                  </div>
                  <h4>Hello! let's get started</h4>
                  <h6 className="font-weight-light">Sign in to continue.</h6>
                </div>
                <Form className="pt-3" name="form" onSubmit={handleSubmit}>
                  <InputField
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email Address"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email}
                    touched={touched.email}
                  />
                  <InputField
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.password}
                    touched={touched.password}
                  />
                  <div className="mt-3">
                    <button
                      type="submit"
                      className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      disabled={isSubmitting}
                    >
                      SIGN IN &nbsp;&nbsp;
                      {isSubmitting ? <Spinner animation="border" size="sm" /> : ""}
                    </button>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <div className="pull-left">
                      <Link to="/password/forgot-password" className="auth-link text-black">
                        Forgot password?
                      </Link>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default Login;
