import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link, useHistory } from "react-router-dom";
const { SearchBar } = Search;

const ViewNotHiredApplicants = ({ applicationEntries }) => {
  let history = useHistory();

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  const handleViewEmergencyReportRedirect = (row, e, id) => {
    // console.log(row.report_date, e, id);
    history.push("/financials/view-emergency-report", {
      store_id: id,
      report_date: row.report_date,
    });
  };

  const handleRedirect = (row) => {
    console.log(row, "Na the row be this");
    history.push(`/employees/details/${row.id}`, {
      entry: row,
    });
  };

  const handleRedirectToEdit = (row) => {
    console.log(row, "Na the row be this");
    history.push(`/employees/edit-details/${row.id}`, {
      entry: row,
    });
  };

  const columns = [
    {
      dataField: "id",
      text: "Report Id",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "position",
      text: "Position",
      sort: true,
    },
    {
      dataField: "approval_status",
      text: "Approval Status",
      sort: true,
    },
    {
      dataField: "date_created",
      text: "Date Created",
      sort: true,
    },
    {
      dataField: "state",
      text: "State",
      sort: true,
    },
    {
      dataField: "city",
      text: "City",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <div>
            {row.approval_status !== "PROCESSED" ? (
              <button
                className="btn btn-outline-primary"
                onClick={(e) => handleRedirect(row)}
              >
                <i className="mdi mdi-eye-outline text-primary"></i>View Details
              </button>
            ) : (
              <button
                className="btn btn-outline-primary"
                onClick={(e) => handleRedirectToEdit(row)}
              >
                <i className="mdi mdi-eye-outline text-primary"></i>View & Edit
                Details
              </button>
            )}
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];
  return (
    <div>
      <div>
        <div className="page-header">
          <nav aria-label="breadcrumb">
            {/* <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/financials/view-store-report"> La Parrilla </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page"></li>
            </ol> */}
          </nav>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"> Application Entries</h4>
                <div className="row">
                  <div className="col-12">
                    {applicationEntries && (
                      <ToolkitProvider
                        keyField="id"
                        bootstrap4
                        data={applicationEntries}
                        columns={columns}
                        search
                      >
                        {(props) => (
                          <div>
                            <div className="d-flex align-items-center">
                              <p className="mb-2 mr-2">Search in table:</p>
                              <SearchBar {...props.searchProps} />
                            </div>
                            <BootstrapTable
                              defaultSorted={defaultSorted}
                              pagination={paginationFactory()}
                              {...props.baseProps}
                              wrapperClasses="table-responsive"
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewNotHiredApplicants;
