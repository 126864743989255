import React from "react";
import { Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import PermanentAccess from "./PermanentAccess";
import TemporaryAccess from "./TemporaryAccess";

const StorePermissionModal = ({
  showStorePermissionModal,
  setShowStorePermissionModal,
  employee,
  handleStorePermissionClose,
  storeOptions,
  defaultPermanentStores,
  defaultTemporaryStores,
}) => {
  return (
    <>
      <div>
        <Modal
          show={showStorePermissionModal}
          onHide={() => setShowStorePermissionModal(false)}
          aria-labelledby="example-modal-sizes-title-md"
          style={{ top: "50px" }}
          backdrop="static"
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title> Store Permission </Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ paddingBottom: "0px", minHeight: "600px" }}>
            <div className="tab-pills-horizontal">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="permanent"
              >
                <Row>
                  <Col xs={12}>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="permanent"
                          className="d-flex align-items-center"
                        >
                          <i className="mdi mdi-home-outline text-primary pr-2"></i>
                          Permanent
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="temporary"
                          className="d-flex align-items-center"
                        >
                          <i className="mdi mdi-account-outline text-danger pr-2"></i>
                          Temporary
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col xs={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="permanent">
                        <div>
                          <PermanentAccess
                            employee={employee}
                            storeOptions={storeOptions}
                            defaultPermanentStores={defaultPermanentStores}
                            handleStorePermissionClose={
                              handleStorePermissionClose
                            }
                          />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="temporary">
                        <div>
                          <TemporaryAccess
                            employee={employee}
                            storeOptions={storeOptions}
                            defaultTemporaryStores={defaultTemporaryStores}
                            handleStorePermissionClose={
                              handleStorePermissionClose
                            }
                          />
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </Modal.Body>

          <Modal.Footer className="flex-wrap" style={{ paddingTop: "0px" }}>
            {" "}
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default StorePermissionModal;
