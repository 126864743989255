import React, { useEffect, useState } from "react"
import { UserService } from "../../../service/userService";
import PaginationPage from "../../dashboard/components/Pagination";
import EmployeeList from "./EmployeeList";


const ActiveEmployees = ({ data }) => {
    const [resultsReady, setResultsReady] = useState(false);
    const [searchedResults, setSearchedResults] = useState(null);

    const searchEmployeeUsingNames = (searchString) => {
        let propArray = data.filter((emp) => {
            return emp.first_name
                .toLowerCase()
                .concat(emp.email.toLowerCase())
                .concat(emp.last_name.toLowerCase())
                .includes(searchString.toLowerCase());
        });

        setSearchedResults(propArray);
    };



    useEffect(() => {
        UserService.getEmployees()
            .then((response) => {
                setSearchedResults(response.data.data.activeEmployees);
                setResultsReady(true);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    return (
        <div>
            <div>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search for an Employee using First Name, Last Name or Email Address"
                    onChange={(e) => {
                        searchEmployeeUsingNames(
                            e.target.value
                        );
                    }}
                />
            </div>
            <div className="card-body">

                {resultsReady && resultsReady ? (
                    <>
                        <div className="row">
                            {searchedResults &&
                                searchedResults.length > 0 ? (
                                <>
                                    <PaginationPage
                                        data={searchedResults}
                                        RenderComponent={EmployeeList}
                                        pageLimit={5}
                                        dataLimit={15}
                                    />

                                </>
                            ) : (
                                <div>
                                    <span className="fa fa-spin fa-spinner fa-4x"></span>
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <div>
                        <span className="fa fa-spin fa-spinner fa-4x"></span>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ActiveEmployees;