import React, { useEffect, useState } from "react";
import { UserService } from "../../service/userService";
import { Col, Form, Nav, Row, Tab } from "react-bootstrap";
import { Utility } from "../../Helpers/utils";
import { SystemConfigService } from "../../service/configService";
import PaginationPage from "../dashboard/components/Pagination";
import EmployeeList from "./components/EmployeeList";
import ActiveEmployees from "./components/ActiveEmployees";
import TerminatedEmployees from "./components/TerminatedEmployees";

const user = Utility.getUser();

const Employees = () => {
  const [activeEmployees, setActiveEmployees] = useState(null);
  const [terminatedEmployees, setTerminatedEmployees] = useState(null);
  const [searchedResults, setSearchedResults] = useState(null);
  const [stores, setStores] = useState(null);

  useEffect(() => {
    UserService.getEmployees()
      .then((response) => {
        console.log(response.data.data, "Here it is")
        setActiveEmployees(response.data.data.activeEmployees);
        setTerminatedEmployees(response.data.data.terminatedEmployees);
        // setSearchedResults(response.data.data);
        // setResultsReady(true);
      })
      .catch((error) => {
        console.log(error);
      });

    // UserService.getOtherEmployees()
    //   .then((response) => {
    //     setActiveEmployees(response.data.data.active);
    //     setTerminatedEmployees(response.data.data.terminated);
    //     setResultsReady(true);
    //   })
    //   .catch((error) => {
    //     console.log(error.response.data);
    //   });
  }, []);

  return (
    <div>
      {/* {employees && ( */}
      <div>
        <div>
          <p className="pull-right" style={{ textDecoration: "underline" }}>
            <a
              href="https://veltron.document360.io/v1/en"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn How &nbsp;<span className="fa fa-question"></span>
            </a>
          </p>

          <div className="clearfix"></div>
        </div>

        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title"> Employee List</h4>
              <div className="tab-pills-horizontal">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="activeEmployees"
                >
                  <Row>
                    <Col sm={12}>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="activeEmployees"
                            className="d-flex align-items-center"
                          >
                            Active Employees
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="terminatedEmployees"
                            className="d-flex align-items-center"
                          >
                            Terminated Employees
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col sm={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="activeEmployees">
                          <ActiveEmployees data={activeEmployees} setEmployees={setActiveEmployees} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="terminatedEmployees">
                          <TerminatedEmployees data={terminatedEmployees} />
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Employees;
