import React, { useEffect, useState } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import { BonusPayoutService } from "../../../service/BonusPayoutService";
import Swal from "sweetalert2";
import getStoreName from "../../../components/StoreNameUsingId";
import NumberFormat from "react-number-format";
import { RoundNumberUp } from "../../../components/Utils/RoundNumberUp";
import ShowCriteria from "../components/ShowCriteria";
import CriteriaMark from "../components/CriteriaMark";
import BonusPayout from "../components/breakdown/BonusPayout";
import BonusReviews from "../components/breakdown/BonusReviews";
import BonusEvaluation from "../components/breakdown/BonusEvaluation";

const ViewBonusBreakdown = () => {
  const history = useHistory();

  const [breakdownData, setBreakdownData] = useState(null);

  const year_1 = history.location.state.year_1;
  const year_2 = history.location.state.year_2;
  const quarter = history.location.state.quarter;
  const storeId = history.location.state.storeId;

  useEffect(() => {
    BonusPayoutService.getBreakdownForAQuarter({
      year_1,
      year_2,
      quarter,
      store: storeId,
    })
      .then((response) => {
        console.log(response.data.data);
        setBreakdownData(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "info",
          confirmButtonText: "Ok",
          allowEscapeKey: false,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.close();
          }
        });
      });
  }, []);

  return (
    <div>
      <div className="float-left">
        <div>
          <img
            src={require("../../../assets/images/la-parrila-logo.png")}
            alt="logo"
            className=""
          />
        </div>
      </div>

      <div className="row float-right">
        <div className="tab-custom-pills-horizontal col-md-12">
          <div className="">
            <Link to={"/bonus/view-select-bonus-payout"}>
              <button type="button" className="btn btn-primary btn-rounded">
                Back
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="clearfix"></div>

      <div className="page-header">
        <h3 className="page-title" style={{ color: "red", fontWeight: "bold" }}>
          BONUS PAYOUT - Q{quarter} {year_2}
        </h3>
      </div>

      <h4> Location: {getStoreName(storeId)} </h4>

      <div className="row">
        <div className="col-md-6">
          <BonusReviews quarter={quarter} />
        </div>

        <div className="col-md-8">
          <BonusEvaluation quarter={quarter} year={year_2} storeId={storeId} />
        </div>

        <div className="col-md-12">
          <BonusPayout breakdownData={breakdownData} quarter={quarter} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(ViewBonusBreakdown);
