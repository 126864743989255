import cogoToast from "cogo-toast";
import { Formik } from "formik";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import JobCodeName from "../../../../../../components/JobCodeName";
import StoreName from "../../../../../../components/StoreName";
import { UserService } from "../../../../../../service/userService";

const MoveModal = ({ employee, user, showMoveModal, setShowMoveModal }) => {
  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  return (
    <>
      <div>
        <Formik
          initialValues={{
            employee_id: employee ? employee.id : "",
            employee_slug: employee ? employee.slug : "",
            current_store_id: employee.company_details
              ? employee.company_details.store_id
              : "",
            new_store_id: "",
            creator_slug: user ? user.slug : "",
            job_code_id: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);

            if (values.new_store_id) {
              UserService.moveEmployee(values)
                .then((response) => {
                  console.log(response);
                  setShowMoveModal(false);
                  setSubmitting(false);
                  cogoToast.success(response.data.message, toastoptions);
                  localStorage.setItem("employeeActiveTab", "company");
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000);
                })
                .catch((error) => {
                  console.log(error);
                  cogoToast.error(error.response.data.message, toastoptions);
                  setSubmitting(false);
                });
            } else {
              cogoToast.error("Please enter select a store", toastoptions);
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <Modal
              show={showMoveModal}
              onHide={() => setShowMoveModal(false)}
              aria-labelledby="example-modal-sizes-title-md"
              style={{ top: "200px" }}
              backdrop="static"
            >
              <form name="form" onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Move Employee </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ paddingBottom: "0px" }}>
                  <Form.Group>
                    <label htmlFor="store">Current Active Store</label>
                    <select
                      className="form-control"
                      id="current_store_id"
                      name="current_store_id"
                      defaultValue={
                        values.current_store_id
                          ? values.current_store_id
                          : "DEFAULT"
                      }
                      onChange={handleChange}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      style={{ textTransform: "capitalize" }}
                    >
                      <option disabled value={"DEFAULT"}>
                        Select Store
                      </option>
                      {StoreName &&
                        StoreName.map((value) => (
                          <option value={value.id} key={value.id}>
                            {value.name}
                          </option>
                        ))}
                    </select>
                  </Form.Group>

                  <Form.Group>
                    <label htmlFor="store">
                      New Store <span className="text-danger"> * </span>
                    </label>
                    <select
                      className="form-control"
                      id="store_id"
                      name="new_store_id"
                      defaultValue={
                        values.new_store_id ? values.new_store_id : "DEFAULT"
                      }
                      onChange={handleChange}
                      disabled={isSubmitting}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      style={{ textTransform: "capitalize" }}
                    >
                      <option disabled value={"DEFAULT"}>
                        Select Store
                      </option>
                      {StoreName &&
                        StoreName.map((value) => (
                          <option value={value.id} key={value.id}>
                            {value.name}
                          </option>
                        ))}
                    </select>
                  </Form.Group>

                  <Form.Group>
                    <label htmlFor="job_code">Job Code</label>
                    <select
                      className="form-control"
                      id="job_code_id"
                      name="job_code_id"
                      value={values.job_code_id}
                      onChange={handleChange}
                      disabled={isSubmitting}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      style={{ textTransform: "capitalize" }}
                    >
                      <option disabled value={""}>
                        Select Job Code
                      </option>
                      {JobCodeName &&
                        JobCodeName.map((value) => (
                          <option value={value.id} key={value.id}>
                            {value.name}
                          </option>
                        ))}
                    </select>
                  </Form.Group>
                </Modal.Body>

                <Modal.Footer
                  className="fleex-wrap"
                  style={{ paddingTop: "0px" }}
                >
                  {" "}
                  <Button
                    type="submit"
                    className="btn btn-block btn-primary font-weight-medium auth-form-btn"
                  >
                    Proceed &nbsp;&nbsp;
                    {isSubmitting ? (
                      // <Spinner animation="border" size="sm" />
                      <span className="fa fa-spin fa-spinner"></span>
                    ) : (
                      ""
                    )}
                  </Button>
                  <Button
                    variant="light m-2"
                    onClick={() => setShowMoveModal(false)}
                  >
                    Cancel
                  </Button>
                </Modal.Footer>
              </form>
            </Modal>
          )}
        </Formik>
      </div>
    </>
  );
};

export default MoveModal;
