import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import { SystemConfigService } from "../../../service/configService";
import { BonusPayoutService } from "../../../service/BonusPayoutService";
import Swal from "sweetalert2";
import { Utility } from "../../../Helpers/utils";

const ViewBonusReports = () => {
  const history = useHistory();
  const user = Utility.getUser();

  const [storeOptions, setStoreOptions] = useState(null);

  useEffect(() => {
    SystemConfigService.getStores()
      .then((response) => {
        setStoreOptions(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> </h3>
        <nav aria-label="breadcrumb"></nav>
      </div>
      <Formik
        initialValues={{
          store: "",
          year: "",
          quarter: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          console.log(values);

          if (values.store && values.year && values.quarter) {
            BonusPayoutService.getBreakdownForAQuarter(values)
              .then((response) => {
                setSubmitting(false);
                console.log(response.data.data);
                history.push("/bonus/view-bonus-histories-store", {
                  breakdown_data: response.data.data,
                  store: values.store,
                  year: values.year,
                  quarter: values.quarter,
                });
              })
              .catch((error) => {
                console.log(error.response.data.message);
                setSubmitting(false);
                Swal.fire({
                  title: "Error",
                  text: error.response.data.message,
                  icon: "info",
                  confirmButtonText: "Ok",
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    Swal.close();
                  }
                });
              });
          } else {
            setSubmitting(false);
            alert("Please fill all the input fields");
          }

          //   console.log(values);
          // if (values) {
          //   console.log(values);
          // }
          // history.push("/bonus-payout-breakdown");
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <div className="row">
            <div className="col-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4
                    className="card-title"
                    style={{
                      marginBottom: "20px",
                    }}
                  >
                    Select a store to view Bonus Reports
                  </h4>
                  <form
                    className="forms-sample"
                    name="form"
                    onSubmit={handleSubmit}
                  >
                    <Form.Group>
                      <label htmlFor="store"> Store Name </label>
                      <Form.Control
                        type="text"
                        as="select"
                        className="form-control"
                        id="store"
                        name="store"
                        value={values.store ? values.store : "DEFAULT"}
                        onChange={handleChange}
                        disabled={isSubmitting}
                      >
                        <option value="DEFAULT" disabled>
                          Select One
                        </option>

                        {storeOptions &&
                          storeOptions.map((s, index) => (
                            <option value={s.location_id} key={index}>
                              {s.name.toUpperCase()}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <label htmlFor="year"> Year</label>
                      <Form.Control
                        type="text"
                        as="select"
                        className="form-control"
                        id="year"
                        name="year"
                        value={values.year ? values.year : "DEFAULT"}
                        onChange={handleChange}
                        disabled={isSubmitting}
                      >
                        <option value="DEFAULT" disabled>
                          Select One
                        </option>

                        <option value={"2021"}> 2021 </option>
                        <option value={"2022"}> 2022 </option>
                        <option value={"2023"}> 2023 </option>
                        <option value={"2024"}> 2024 </option>
                        <option value={"2025"}> 2025 </option>
                        <option value={"2026"}> 2026 </option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <label htmlFor="quarter">Quarter</label>
                      <Form.Control
                        type="text"
                        as="select"
                        className="form-control"
                        id="quarter"
                        name="quarter"
                        value={values.quarter ? values.quarter : "DEFAULT"}
                        onChange={handleChange}
                        disabled={isSubmitting}
                      >
                        <option value="DEFAULT" disabled>
                          Select One
                        </option>

                        <option value={"1"}>Q1</option>
                        <option value={"2"}>Q2</option>
                        <option value={"3"}>Q3</option>
                        <option value={"4"}>Q4</option>
                      </Form.Control>
                    </Form.Group>

                    {(user && user.role === "super admin") ||
                    user.role === "district manager" ? (
                      <button type="submit" className="btn btn-primary mr-2">
                        Submit &nbsp;&nbsp;
                        {isSubmitting ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          ""
                        )}
                      </button>
                    ) : (
                      ""
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default withRouter(ViewBonusReports);
