import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { Form } from "react-bootstrap";
import {
  RoundNumberUp,
  RoundNumberUpAccurately,
} from "../../Utils/RoundNumberUp";

const ShowHourBreakDown = ({
  laborPaymentDetails,
  totalAmMin,
  codeAmAmount,
  totalBreakMin,
  codeBreakAmount,
  totalPmMin,
  codePmAmount,
}) => {
  const [showBreakDetails, setShowBreakDetails] = useState(false);

  return (
    <div>
      <div>
        {!showBreakDetails && (
          <button
            className="btn-sm btn btn-primary"
            onClick={() => setShowBreakDetails(true)}
            style={{ float: "right", marginBottom: "5px" }}
          >
            {" "}
            <i className="fa fa-eye"></i> View Breakdown
          </button>
        )}
      </div>

      {showBreakDetails && (
        <div>
          <button
            type="button"
            onClick={() => setShowBreakDetails(false)}
            class="btn-sm btn btn-primary"
            style={{ float: "right", marginBottom: "5px" }}
          >
            Hide Breakdown
          </button>

          <div
            style={{
              margin: "20px 0",
              border: "1px dotted grey",
              padding: "20px",
            }}
          >
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{ width: "30%" }}>Employee Name</th>
                  <th style={{ width: "5%" }}> AM Mins</th>
                  <th style={{ width: "5%" }}> BREAK Mins </th>
                  <th style={{ width: "5%" }}> PM Mins</th>
                  <th style={{ width: "5%" }}> Minutes</th>
                  <th style={{ width: "30%" }}> Amount / Mins</th>
                  <th style={{ width: "5%" }}> AM ($)</th>
                  <th style={{ width: "5%" }}> BREAK ($)</th>
                  <th style={{ width: "5%" }}> PM ($)</th>
                </tr>
              </thead>
              <tbody>
                {" "}
                {laborPaymentDetails &&
                  laborPaymentDetails.map((pay, index) => (
                    <tr key={pay.employee_number}>
                      <td>
                        <Form.Group>
                          <Form.Control
                            name={`employee_name`}
                            className=""
                            placeholder="Employee Name"
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            disabled={"disabled"}
                            value={pay.name}
                            style={{
                              border: "none",
                              fontWeight: "bold",
                            }}
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <NumberFormat
                          defaultValue={pay.am}
                          displayType={"text"}
                          thousandSeparator={false}
                        />
                      </td>

                      <td>
                        <NumberFormat
                          defaultValue={pay.break}
                          displayType={"text"}
                          thousandSeparator={false}
                        />
                      </td>

                      <td>
                        <NumberFormat
                          defaultValue={pay.pm}
                          displayType={"text"}
                          thousandSeparator={false}
                        />
                      </td>

                      <td>
                        <NumberFormat
                          defaultValue={pay.total_minutes}
                          displayType={"text"}
                          thousandSeparator={false}
                        />
                      </td>

                      <td
                        style={{
                          paddingLeft: "5px",
                          paddingBottom: "0px",
                        }}
                      >
                        <Form.Group>
                          <Form.Control
                            name={`amount_per_minutes`}
                            className="form-control"
                            placeholder="Amount per Minutes"
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            disabled={"disabled"}
                            value={pay.amount_per_minutes}
                            style={{
                              border: "none",
                              padding: "0px",
                            }}
                          />
                        </Form.Group>
                      </td>

                      <td>
                        <NumberFormat
                          defaultValue={RoundNumberUp(pay.amAmount)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>

                      <td>
                        <NumberFormat
                          defaultValue={RoundNumberUp(pay.breakAmount)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>

                      <td>
                        <NumberFormat
                          defaultValue={RoundNumberUp(pay.pmAmount)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <div className="mt-5">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th style={{ width: "20%" }}>SHIFT</th>
                    <th style={{ width: "20%" }}> TOTAL MINUTES </th>
                    <th style={{ width: "20%" }}> TOTAL TIPS </th>
                    <th style={{ width: "20%" }}> TOTAL $ MINS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Group>
                        <Form.Control
                          name={`bar_am`}
                          className="form-control"
                          autoComplete="off"
                          autoCorrect="off"
                          autoCapitalize="off"
                          spellCheck="false"
                          disabled={"disabled"}
                          value={"BAR AM"}
                          style={{
                            border: "none",
                          }}
                        />
                      </Form.Group>
                    </td>

                    <td>
                      <NumberFormat
                        defaultValue={totalAmMin ? totalAmMin : 0}
                        displayType={"text"}
                        thousandSeparator={false}
                      />
                    </td>

                    <td>
                      <NumberFormat
                        defaultValue={codeAmAmount ? codeAmAmount : 0}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </td>

                    <td>
                      <NumberFormat
                        defaultValue={RoundNumberUpAccurately(
                          codeAmAmount / totalAmMin,
                          5
                        )}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Form.Group>
                        <Form.Control
                          name={`bar_am`}
                          className="form-control"
                          autoComplete="off"
                          autoCorrect="off"
                          autoCapitalize="off"
                          spellCheck="false"
                          disabled={"disabled"}
                          value={"BAR BREAK"}
                          style={{
                            border: "none",
                          }}
                        />
                      </Form.Group>
                    </td>

                    <td>
                      <NumberFormat
                        defaultValue={totalBreakMin ? totalBreakMin : 0}
                        displayType={"text"}
                        thousandSeparator={false}
                      />
                    </td>

                    <td>
                      <NumberFormat
                        defaultValue={codeBreakAmount ? codeBreakAmount : 0}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </td>

                    <td>
                      <NumberFormat
                        defaultValue={RoundNumberUpAccurately(
                          codeBreakAmount / totalBreakMin,
                          5
                        )}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Form.Group>
                        <Form.Control
                          name={`bar_am`}
                          className="form-control"
                          autoComplete="off"
                          autoCorrect="off"
                          autoCapitalize="off"
                          spellCheck="false"
                          disabled={"disabled"}
                          value={"BAR PM"}
                          style={{
                            border: "none",
                          }}
                        />
                      </Form.Group>
                    </td>

                    <td>
                      <NumberFormat
                        defaultValue={totalPmMin ? totalPmMin : 0}
                        displayType={"text"}
                        thousandSeparator={false}
                      />
                    </td>

                    <td>
                      <NumberFormat
                        defaultValue={codePmAmount ? codePmAmount : 0}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </td>

                    <td>
                      <NumberFormat
                        defaultValue={RoundNumberUpAccurately(
                          codePmAmount / totalPmMin,
                          5
                        )}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowHourBreakDown;
