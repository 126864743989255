import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import queryString from "query-string";
import cogoToast from "cogo-toast";
import { AuthService } from "../../service/authService";

export class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: queryString.parse(this.props.location.search).email,
      token: this.props.match.params.token,
      password: "",
      password_confirmation: "",
      submitted: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  componentDidMount() {
    console.log(this.props.match.params.token);
    console.log(queryString.parse(this.props.location.search).email);
  }

  toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  resetPassword(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { email, token, password, password_confirmation } = this.state;

    if (password && password_confirmation) {
      if (password !== password_confirmation) {
        cogoToast.error("Passwords do not match", this.toastoptions);
      } else {
        let data = { email, token, password, password_confirmation };

        AuthService.resetPassword(data)
          .then((response) => {
            console.log(response.data);
            cogoToast.success(
              "Password reset successfully. Please login",
              this.toastoptions
            );
            this.props.history.push("/home/login");
          })
          .catch((error) => {
            console.log(error.response.data);
            if (error.response.data.errors.password) {
              cogoToast.error(
                error.response.data.errors.password[0],
                this.toastoptions
              );
            }
            cogoToast.error(error.response.data.message, this.toastoptions);
          });
      }
    } else {
      cogoToast.error("Enter your passwords", this.toastoptions);
    }
  }

  render() {
    return (
      <div>
        <div className="d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="text-center">
                  <div className="brand-logo">
                    <Link to={"/home/login"}>
                      <img
                        src={require("../../assets/images/la-parrila-logo.png")}
                        alt="logo"
                      />
                    </Link>
                  </div>
                  {/* <h4>Forgot password?</h4> */}
                  <h6 className="font-weight-light">
                    Please set a new password
                  </h6>
                </div>
                <form
                  className="pt-3"
                  name="reset-password"
                  onSubmit={this.resetPassword}
                >
                  <div className="form-group">
                    <label htmlFor="password"> Password </label>
                    <input
                      type="password"
                      className="form-control form-control-lg"
                      id="password"
                      onChange={this.handleChange}
                      name="password"
                      placeholder=""
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password"> Confirm Password </label>
                    <input
                      type="password"
                      className="form-control form-control-lg"
                      id="password_confirmation"
                      onChange={this.handleChange}
                      name="password_confirmation"
                      placeholder=""
                    />
                  </div>

                  <div className="mt-3">
                    {/* <Link className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" to="/dashboard"> RESET PASSWORD</Link> */}
                    <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
                      {" "}
                      RESET PASSWORD{" "}
                    </button>
                  </div>
                  <div className="text-center mt-4 font-weight-light">
                    <Link to="/user-pages/login" className="text-primary">
                      Go to login
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ResetPassword);
