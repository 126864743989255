import React, { useEffect, useState, useContext } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import { SystemConfigService } from "../../service/configService";
import { Formik } from "formik";
import toastMessages from "../../Helpers/notifications";
import BonusContext from "../../contexts/bonus/BonusContext";

const BonusSelector = () => {
  const history = useHistory();

  const [storeOptions, setStoreOptions] = useState(null);

  const { dispatch } = useContext(BonusContext);

  // useEffect(() => {
  //   SystemConfigService.getStores()
  //     .then((response) => {
  //       setStoreOptions(response.data.data);
  //     })
  //     .catch((error) => {
  //       console.log(error.response.data);
  //     });
  // }, []);

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> </h3>
        <nav aria-label="breadcrumb"></nav>
      </div>
      <Formik
        initialValues={{
          store: "",
          year: "",
          quarter: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          if (values.store && values.year && values.quarter) {
            dispatch({
              type: "SET_INITIAL_DATA",
              payload: {
                storeId: values.store,
                year: values.year,
                quarter: values.quarter,
              },
            });
            // console.log(values);
            history.push("/bonus/create-bonus-payout");
          } else {
            toastMessages.warning("Please fill all the fields");
            setSubmitting(false);
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
          <div className="row">
            <div className="col-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="pull-left">
                    <h4 className="card-title">Select Details for Bonus Payout to Proceed</h4>
                  </div>

                  <div className="pull-right">
                    <Link to={"/bulk-upload"}>
                      <h4 className="card-title">
                        <button className="btn btn-primary">Bulk Upload </button>
                      </h4>
                    </Link>
                  </div>

                  <div className="clearfix"></div>
                  <form className="forms-sample" name="form" onSubmit={handleSubmit}>
                    <Form.Group>
                      <label htmlFor="store"> Store Name </label>
                      <Form.Control
                        type="text"
                        as="select"
                        className="form-control"
                        id="store"
                        name="store"
                        value={values.store ? values.store : "DEFAULT"}
                        onChange={handleChange}
                        disabled={isSubmitting}
                      >
                        <option value="DEFAULT" disabled>
                          Select One
                        </option>
                        <option value="1">LA PARRILLA (MARIETTA SQUARE)</option>
                        <option value="2">LA PARRILLA (DALLAS HWY)</option>
                        <option value="3">LA PARRILLA (FLOWERY BRANCH)</option>
                        <option value="4">LA PARRILLA (POWDER SPRINGS)</option>
                        <option value="5">LA PARRILLA (FAYETTEVILLE)</option>
                        <option value="6">LA PARRILLA (ALPHARETTA)</option>
                        <option value="7">LA PARRILLA (WOODSTOCK)</option>
                        <option value="9">LA PARRILLA (ACWORTH)</option>
                        <option value="10">LA PARRILLA (NEWNAN)</option>
                        <option value="11">LA PARRILLA (MCDONOUGH)</option>
                        <option value="12">LA PARRILLA (HOWELL MILL)</option>
                        <option value="13">LA PARRILLA (DOTHAN)</option>
                        <option value="15">LA PARRILLA (CANTON)</option>
                        <option value="16">LA PARRILLA (MACON)</option>
                        <option value="17">LA PARRILLA (GREENVILLE)</option>
                        <option value="18">LA PARRILLA (TOCO HILL)</option>
                        <option value="19">LA PARRILLA (SAVANNAH)</option>
                        <option value="20">LA PARRILLA (GRIFFIN)</option>
                        <option value="21">LA PARRILLA (ROME)</option>
                        <option value="14">LA PARRILLA (NORCROSS)</option>
                        <option value="23">LA PARRILLA (SANDY SPRINGS)</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <label htmlFor="year"> Year</label>
                      <Form.Control
                        type="text"
                        as="select"
                        className="form-control"
                        id="year"
                        name="year"
                        value={values.year ? values.year : "DEFAULT"}
                        onChange={handleChange}
                        disabled={isSubmitting}
                      >
                        <option value="DEFAULT" disabled>
                          Select One
                        </option>

                        <option value={"2021"}> 2021 </option>
                        <option value={"2022"}> 2022 </option>
                        <option value={"2023"}> 2023 </option>
                        <option value={"2024"}> 2024 </option>
                        <option value={"2025"}> 2025 </option>
                        <option value={"2026"}> 2026 </option>
                        <option value={"2027"}> 2027 </option>
                        <option value={"2028"}> 2028 </option>
                        <option value={"2029"}> 2029 </option>
                        <option value={"2030"}> 2030 </option>
                        <option value={"2031"}> 2031 </option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <label htmlFor="quarter">Quarter</label>
                      <Form.Control
                        type="text"
                        as="select"
                        className="form-control"
                        id="quarter"
                        name="quarter"
                        value={values.quarter ? values.quarter : "DEFAULT"}
                        onChange={handleChange}
                        disabled={isSubmitting}
                      >
                        <option value="DEFAULT" disabled>
                          Select One
                        </option>

                        <option value={"1"}>Q1</option>
                        <option value={"2"}>Q2</option>
                        <option value={"3"}>Q3</option>
                        <option value={"4"}>Q4</option>
                      </Form.Control>
                    </Form.Group>

                    <button type="submit" className="btn btn-primary mr-2">
                      Submit &nbsp;&nbsp;
                      {isSubmitting ? <span> Loading...</span> : ""}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default withRouter(BonusSelector);
