import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { ExpensesService } from "../../../service/ExpenseService";
import { ConvertDateFormat } from "../../../components/ConvertDateFormat";
const { SearchBar } = Search;

const EmergencyCodeReport = () => {
  let { store_id } = useParams();
  const [store, setStore] = useState(null);
  let history = useHistory();
  const [emergencyExpenses, setEmergencyExpenses] = useState(null);

  const handleViewEmergencyReportRedirect = (row, content) => {
    // console.log(row.storeId, row.rawDate);
    history.push("/financials/view-emergency-report", {
      store_id: row.storeId,
      report_date: row.rawDate,
    });
  };

  useEffect(() => {
    ExpensesService.getAllEmergencyExpenses()
      .then((response) => {
        console.log(response.data.data);
        let emergencyExpensesArray = [];
        response.data.data.map((res, index) => {
          return emergencyExpensesArray.push({
            id: res.id,
            storeId: res.store_id,
            rawDate: res.date,
            location: `#Laparrila ${res.store_id}`,
            employeeName: res.employee_name,
            emergencyCode: res.emergency_code,
            totalHours: res.total_hours,
            totalMinutes: res.total_minutes,
            date: ConvertDateFormat(res.date),
            tipsOwed: res.tips_owed,
            jobCode: res.job_code,
          });
        });
        setEmergencyExpenses(emergencyExpensesArray);
      })
      .catch((error) => {
        console.log(error.response.data);
      });

    // SystemConfigService.getStore(store_id)
    //   .then((response) => {
    //     console.log(response.data.data);
    //     setStore(response.data.data);
    //   })
    //   .catch((error) => {
    //     console.log(error.response.data.message);
    //   });
  }, []);

  const columns = [
    {
      dataField: "location",
      text: "Location",
      sort: true,
    },
    {
      dataField: "employeeName",
      text: "Employee Name",
      sort: true,
    },
    {
      dataField: "emergencyCode",
      text: "Emergency Code",
      sort: true,
    },
    {
      dataField: "totalHours",
      text: "Total Hours",
      sort: true,
    },
    {
      dataField: "totalMinutes",
      text: "Total Minutes",
      sort: true,
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
    },
    {
      dataField: "tipsOwed",
      text: "Tips",
      sort: true,
    },
    {
      dataField: "jobCode",
      text: "Job Code",
      sort: true,
    },
    {
      dataField: "Action",
      text: "Action",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <div>
            <button
              className="btn btn-outline-primary"
              onClick={(e) =>
                handleViewEmergencyReportRedirect(row, cellContent)
              }
            >
              <i className="mdi mdi-eye-outline text-primary"></i> Emergency
              Report
            </button>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">
          <span style={{ textTransform: "capitalize" }}>
            Emergency Codes Report
          </span>
        </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {/* <li className="breadcrumb-item">
              <Link to="/financials/payment-history">
                <span style={{ textTransform: "capitalize" }}>
                  {" "}
                  {store ? store.name : ""}
                </span>
              </Link>
            </li> */}
            <li className="breadcrumb-item active" aria-current="page">
              <span style={{ textTransform: "capitalize" }}>
                Emergency Codes Report
              </span>
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            {emergencyExpenses && (
              <div className="card-body">
                <h4 className="card-title"> Emergency Codes</h4>
                <div className="row">
                  <div className="col-12">
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={emergencyExpenses}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div className="d-flex align-items-center">
                            <p className="mb-2 mr-2">Search in table:</p>
                            <SearchBar {...props.searchProps} />
                          </div>
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(EmergencyCodeReport);
