import React, { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { ProfilePicturePond } from "../ProfilePitcurePond";
import { Formik } from "formik";
import { confirmAlert } from "react-confirm-alert";
import { UserService } from "../../../service/userService";
import toastMessages from "../../../Helpers/notifications";
import Swal from "sweetalert2";
import { useHistory } from "react-router";

const TerminateEmployee = ({ employee, slug }) => {
  const [editMode, setEditMode] = useState(false);

  let history = useHistory();

  const deleteEmployee = (slug) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete an employee completely. Please note that this process cannot be reversed. Click yes to proceed!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#000000",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        // Call the delete endpoint from here
        UserService.deleteEmployee(slug)
        .then((response) => {
          console.log(response.data.data)
          toastMessages.success("Employee has been deleted");
          history.push("/employees/list")
          // window.location.reload()
        })
        .catch((error) => {
          console.log(error)
        })
      }
    })
  };


  const reactivateEmployee = (slug) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to reactivate an employee. Click yes to proceed!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#000000",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        // Call the delete endpoint from here
        UserService.activateEmployee(slug)
        .then((response) => {
          console.log(response.data.data)
          toastMessages.success("Employee has been reactivated");
          window.location.reload()
        })
        .catch((error) => {
          console.log(error)
        })
      }
    })
  };

  return (
    <div className="col-md-12 col-lg-12">
      <div className="border-bottom text-center pb-4">
        <div style={{ marginTop: "20px" }}>
          <ProfilePicturePond slug={employee.slug} />
        </div>
        <div className="mb-3">
          <h3>{`${employee.first_name} ${employee.last_name}`}</h3>
          <div className="d-flex align-items-center justify-content-center">
            <h5
              className="mb-0 mr-2 text-muted"
              style={{ textTransform: "capitalize" }}
            >
              {employee.job_status}
            </h5>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          {employee.company_details &&
          employee.company_details.employment_status !== "terminated" ? (
            <Button
              disabled={editMode}
              className="btn btn-primary"
              onClick={() => {
                setEditMode(true);
              }}
            >
              Terminate
            </Button>
          ) : (
            <Button
              className="btn btn-primary"
              onClick={() => {
                reactivateEmployee(employee.slug);
              }}
            >
              Reactivate
            </Button>
          )}
          {/* <button
            className="btn btn-primary"
            onClick={() => {
              archiveEmployee(employee.slug);
            }}
          >
            Archive
          </button> */}

          <button
            className="btn btn-primary"
            onClick={() => {
              deleteEmployee(employee.slug);
            }}
          >
            Delete
          </button>
        </div>
      </div>

      {editMode && (
        <div className="">
          <Formik
            initialValues={{
              reason: "",
              lastWorkingDay: "",
              eligibleForRehire: "",
              explanationNoRehire: "",
              expectedReturnDate: "",
            }}
            onSubmit={async (values, { setSubmitting }) => {
              if (!values.reason) {
                toastMessages.error("Please select a reason");
              } else {
                try {
                  const response = await UserService.terminateEmployee(
                    slug,
                    values
                  );
                  if (response.data.success) {
                    toastMessages.success("Employee has been terminated");
                    setSubmitting(false);
                    window.location.reload();
                    return;
                  }
                } catch (error) {
                  console.log(error.response.data);
                  setSubmitting(false);
                }
              }
            }}
          >
            {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
              <div className="row">
                <div className="col-12 col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <Form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12">
                            <Form.Group>
                              <label htmlFor="reason">Reason</label>
                              <Form.Control
                                type="text"
                                as="select"
                                className="form-control"
                                id="reason"
                                name="reason"
                                required
                                value={
                                  values.reason ? values.reason : "DEFAULT"
                                }
                                onChange={handleChange}
                                disabled={isSubmitting}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                // defaultValue={"DEFAULT"}
                              >
                                <option value="DEFAULT" disabled>
                                  {" "}
                                  Select One
                                </option>
                                <option value="none">None</option>
                                <option value="voluntary resignation">
                                  Voluntary Resignation
                                </option>
                                <option value="violation of company policies">
                                  Violation Of Company Policies
                                </option>
                                <option value="introductory period">
                                  Introductory Period
                                </option>
                                <option value="relocation">Relocation</option>
                                <option value="retirement">Retirement</option>
                                <option value="workforce reduction">
                                  Workforce Reduction
                                </option>
                                <option value="deceased">Deceased</option>
                              </Form.Control>
                            </Form.Group>

                            <Form.Group>
                              <div> Last Working Day </div>

                              <Form.Control
                                type="date"
                                id="lastWorkingDay"
                                className="form-control w-100"
                                name="lastWorkingDay"
                                value={values.lastWorkingDay}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                required
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="eligibleForRehire">
                                Eligible For Rehire
                              </label>
                              <Form.Control
                                type="text"
                                as="select"
                                className="form-control"
                                id="eligibleForRehire"
                                name="eligibleForRehire"
                                value={
                                  values.eligibleForRehire
                                    ? values.eligibleForRehire
                                    : "DEFAULT"
                                }
                                onChange={handleChange}
                                disabled={isSubmitting}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                required
                              >
                                <option disabled value="DEFAULT">
                                  {" "}
                                  Select One{" "}
                                </option>
                                <option value="true"> Yes </option>
                                <option value="false"> No </option>
                              </Form.Control>
                            </Form.Group>

                            {values.eligibleForRehire === "false" ? (
                              <div>
                                <Form.Group>
                                  <label htmlFor="explanationNoRehire">
                                    Explanation
                                  </label>

                                  <Form.Control
                                    type="text"
                                    as="textarea"
                                    row="30"
                                    className="form-control"
                                    id="explanationNoRehire"
                                    name="explanationNoRehire"
                                    value={values.explanationNoRehire}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                    required
                                  ></Form.Control>
                                </Form.Group>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        {/* {editMode && ( */}
                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary btn-icon-text"
                          >
                            Terminate &nbsp;&nbsp;
                            {isSubmitting ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              ""
                            )}
                          </button>

                          <button
                            className="btn btn-light"
                            onClick={() => {
                              setEditMode(false);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                        {/* )} */}
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default TerminateEmployee;
