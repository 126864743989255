import React, { useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { LPSubmitButton } from "../../../styled/styles";
import BonusContext from "../../../contexts/bonus/BonusContext";

const AdministrativeExpenses = ({ months }) => {
  const { dispatch, administrativeExpenses, totalExpenses } = useContext(BonusContext);

  const initialValues = {
    payrollM1: administrativeExpenses ? administrativeExpenses.payrollM1 : "",
    payrollM1P: administrativeExpenses ? administrativeExpenses.payrollM1P : "",
    payrollM2: administrativeExpenses ? administrativeExpenses.payrollM2 : "",
    payrollM2P: administrativeExpenses ? administrativeExpenses.payrollM2P : "",
    payrollM3: administrativeExpenses ? administrativeExpenses.payrollM3 : "",
    payrollM3P: administrativeExpenses ? administrativeExpenses.payrollM3P : "",
    payrollTotal: administrativeExpenses ? administrativeExpenses.payrollTotal : "",
    payrollTotalP: administrativeExpenses ? administrativeExpenses.payrollTotalP : "",

    overheadsM1: administrativeExpenses ? administrativeExpenses.overheadsM1 : "",
    overheadsM1P: administrativeExpenses ? administrativeExpenses.overheadsM1P : "",
    overheadsM2: administrativeExpenses ? administrativeExpenses.overheadsM2 : "",
    overheadsM2P: administrativeExpenses ? administrativeExpenses.overheadsM2P : "",
    overheadsM3: administrativeExpenses ? administrativeExpenses.overheadsM3 : "",
    overheadsM3P: administrativeExpenses ? administrativeExpenses.overheadsM3P : "",
    overheadsTotal: administrativeExpenses ? administrativeExpenses.overheadsTotal : "",
    overheadsTotalP: administrativeExpenses ? administrativeExpenses.overheadsTotalP : "",

    totalAdminM1: administrativeExpenses ? administrativeExpenses.totalAdminM1 : "",
    totalAdminM1P: administrativeExpenses ? administrativeExpenses.totalAdminM1P : "",
    totalAdminM2: administrativeExpenses ? administrativeExpenses.totalAdminM2 : "",
    totalAdminM2P: administrativeExpenses ? administrativeExpenses.totalAdminM2P : "",
    totalAdminM3: administrativeExpenses ? administrativeExpenses.totalAdminM3 : "",
    totalAdminM3P: administrativeExpenses ? administrativeExpenses.totalAdminM3P : "",
    totalAdminTotal: administrativeExpenses ? administrativeExpenses.totalAdminTotal : "",
    totalAdminTotalP: administrativeExpenses ? administrativeExpenses.totalAdminTotalP : "",

    totalExpensesM1: totalExpenses ? totalExpenses.totalExpensesM1 : "",
    totalExpensesM1P: totalExpenses ? totalExpenses.totalExpensesM1P : "",
    totalExpensesM2: totalExpenses ? totalExpenses.totalExpensesM2 : "",
    totalExpensesM2P: totalExpenses ? totalExpenses.totalExpensesM2P : "",
    totalExpensesM3: totalExpenses ? totalExpenses.totalExpensesM3 : "",
    totalExpensesM3P: totalExpenses ? totalExpenses.totalExpensesM3P : "",
    totalExpensesTotal: totalExpenses ? totalExpenses.totalExpensesTotal : "",
    totalExpensesTotalP: totalExpenses ? totalExpenses.totalExpensesTotalP : "",
  };

  return (
    <div>
      <div className="col-lg-12 grid-margin stretch-card">
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);

            // Submit form here;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form name="sales-data" onSubmit={handleSubmit}>
              <div className="table-responsive">
                <div>
                  <table className="table table-bordered">
                    <tbody>
                      <tr
                        style={{
                          fontWeight: "bold",
                          // paddingLeft: "0px",
                          textAlign: "center",
                        }}
                      >
                        <th
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            padding: "0px",
                            textAlign: "center",
                          }}
                          width="10%"
                        >
                          Administrative Expenses
                        </th>
                        <td width="10%"></td>
                        <td width="10%"> {months.firstMonth}</td>
                        <td width="10%">%</td>
                        <td width="10%">{months.secondMonth}</td>
                        <td width="10%">%</td>
                        <td width="10%">{months.thirdMonth}</td>
                        <td width="10%">%</td>
                        <td width="10%">Total </td>
                        <td width="10%">%</td>
                      </tr>

                      <tr>
                        <th width="10%" scope="row">
                          {" "}
                        </th>
                        <td
                          width="10%"
                          style={{
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          Payroll Management
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollM1"
                            name="payrollM1"
                            defaultValue={values.payrollM1}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollM1P"
                            name="payrollM1P"
                            defaultValue={values.payrollM1P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td width="10%" className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollM2"
                            name="payrollM2"
                            defaultValue={values.payrollM2}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollM2P"
                            name="payrollM2P"
                            defaultValue={values.payrollM2P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollM3"
                            name="payrollM3"
                            defaultValue={values.payrollM3}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollM3P"
                            name="payrollM3P"
                            defaultValue={values.payrollM3P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollTotal"
                            name="payrollTotal"
                            defaultValue={values.payrollTotal}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="payrollTotalP"
                            name="payrollTotalP"
                            defaultValue={values.payrollTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row"> </th>
                        <td
                          width="10%"
                          style={{
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          Other Overheads
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="overheadsM1"
                            name="overheadsM1"
                            defaultValue={values.overheadsM1}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="overheadsM1P"
                            name="overheadsM1P"
                            defaultValue={values.overheadsM1P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="overheadsM2"
                            name="overheadsM2"
                            defaultValue={values.overheadsM2}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="overheadsM2P"
                            name="overheadsM2P"
                            defaultValue={values.overheadsM2P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="overheadsM3"
                            name="overheadsM3"
                            defaultValue={values.overheadsM3}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="overheadsM3P"
                            name="overheadsM3P"
                            defaultValue={values.overheadsM3P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="overheadsTotal"
                            name="overheadsTotal"
                            defaultValue={values.overheadsTotal}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="overheadsTotalP"
                            name="overheadsTotalP"
                            defaultValue={values.overheadsTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th scope="row"> </th>
                        <td
                          width="10%"
                          style={{
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          Total
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalAdminM1"
                            name="totalAdminM1"
                            defaultValue={values.totalAdminM1}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalAdminM1P"
                            name="totalAdminM1P"
                            defaultValue={values.totalAdminM1P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalAdminM2"
                            name="totalAdminM2"
                            defaultValue={values.totalAdminM2}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalAdminM2P"
                            name="totalAdminM2P"
                            defaultValue={values.totalAdminM2P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalAdminM3"
                            name="totalAdminM3"
                            defaultValue={values.totalAdminM3}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalAdminM3P"
                            name="totalAdminM3P"
                            defaultValue={values.totalAdminM3P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalAdminTotal"
                            name="totalAdminTotal"
                            defaultValue={values.totalAdminTotal}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalAdminTotalP"
                            name="totalAdminTotalP"
                            defaultValue={values.totalAdminTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th
                          scope="row"
                          style={{
                            padding: "0px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          Total Expenses{" "}
                        </th>
                        <td
                          width="10%"
                          style={{
                            padding: "0px",
                            textAlign: "center",
                          }}
                        ></td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalExpensesM1"
                            name="totalExpensesM1"
                            defaultValue={values.totalExpensesM1}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalExpensesM1P"
                            name="totalExpensesM1P"
                            defaultValue={values.totalExpensesM1P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>
                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalExpensesM2"
                            name="totalExpensesM2"
                            defaultValue={values.totalExpensesM2}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalExpensesM2P"
                            name="totalExpensesM2P"
                            defaultValue={values.totalExpensesM2P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalExpensesM3"
                            name="totalExpensesM3"
                            defaultValue={values.totalExpensesM3}
                            disabled={isSubmitting}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalExpensesM3P"
                            name="totalExpensesM3P"
                            defaultValue={values.totalExpensesM3P}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={(e) => {
                              console.log(e.target);
                            }}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalExpensesTotal"
                            name="totalExpensesTotal"
                            defaultValue={values.totalExpensesTotal}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>

                        <td className="bonus-input-wrapper">
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="totalExpensesTotalP"
                            name="totalExpensesTotalP"
                            defaultValue={values.totalExpensesTotalP}
                            disabled={"disabled"}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div>
                {/* <LPSubmitButton className="btn btn-primary" type="submit">
                  Update Administrative Expenses
                </LPSubmitButton> */}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AdministrativeExpenses;
