import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Circle } from "../../../components/AlertUtility";
import DatePicker from "react-datepicker";

const ListEmployees = ({ employees }) => {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <div className="row">
      <div className="col-xl-6 col-md-6 grid-margin grid-margin-lg-0 stretch-card">
        <div className="card card-invoice">
          <div className="card-body">
            <h4 className="card-title pb-3"> List of employees</h4>
            {employees &&
              employees.slice(0, 4).map((employee) => (
                <div className="list-card" key={employee.id}>
                  <div className="row align-items-center">
                    <div className="col-7 col-sm-8">
                      <div className="row align-items-center">
                        <div className="col-sm-4">
                          <div className="user-avatar mb-auto">
                            <Circle
                              style={{
                                backgroundImage: `url(${process.env.REACT_APP_AZURE_STORAGE}/pictures/${employee?.slug}),
                         url(/svg/portrait.svg)`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-8 pr-0 pl-sm-0">
                          {/* <span>06 Jan 2019</span> */}
                          <h6 className="mb-1 mb-sm-0">
                            {`${employee.first_name} ${employee.last_name}`}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-5 col-sm-4">
                      <div className="d-flex pt-1 align-items-center justify-content-end">
                        <div className="reload-outer bg-info">
                          <i className="mdi mdi-reload"></i>
                        </div>
                        <div className="dropdown pl-2">
                          <div
                            id="dropdownMenuButton2"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <Dropdown alignRight>
                              <Dropdown.Toggle className="nav-link count-indicator bg-transparent text-muted p-0 border-0 toggle-arrow-hide">
                                <i className="mdi mdi-dots-vertical"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="preview-list navbar-dropdown">
                                <Link to={`/employees/view/${employee.slug}`}>
                                  View Employee
                                </Link>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            <Link
              className="text-black mt-3 mb-0 d-block h6"
              to="/employees/list"
            >
              View all <i className="mdi mdi-chevron-right"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-md-6 grid-margin grid-margin-lg-0 stretch-card">
        <div className="card">
          <div className="card-body">
            <DatePicker
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div className="datepicker-custom-header d-flex justify-content-between align-items-center">
                  <h5 className="month-name">
                    {date.toLocaleString("default", { month: "long" }) +
                      " " +
                      date.toLocaleString("default", { year: "numeric" })}
                  </h5>
                  <div>
                    <button
                      className="month-nav-btn btn btn-inverse-primary btn-rounded btn-icon prev-month"
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      <i className="mdi mdi-chevron-left"></i>
                    </button>
                    <button
                      className="month-nav-btn btn btn-inverse-primary btn-rounded btn-icon next-month"
                      onClick={increaseMonth}
                    >
                      <i
                        className="mdi mdi-chevron-right"
                        disabled={nextMonthButtonDisabled}
                      ></i>
                    </button>
                  </div>
                </div>
              )}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              inline
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListEmployees;
