import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import DatePickerField from "../../../../../components/Datepicker";

const CreateExpensesModal = ({
  initialValues,
  handleChange,
  handleSubmit,
  isSubmitting,
  createExpensesModal,
  setCreateExpensesModal,
  enableExpensesDetailsField,
  addExpensesDetails,
}) => (
  <Modal
    size="md"
    show={createExpensesModal}
    onHide={() => setCreateExpensesModal(false)}
    aria-labelledby="example-modal-sizes-title-md"
  >
    <div className="">
      <form name="form" className="" onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Create Expenses </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Expenses</h4>

                  <Form.Group className="row">
                    <label htmlFor="manager_name" className="col-sm-3 col-form-label">
                      Store
                    </label>
                    <div className="col-sm-9">
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="manager_name"
                        name="manager_name"
                        defaultValue={initialValues.store || ""}
                        placeholder="Store "
                        disabled={"disabled"}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="row">
                    <label htmlFor="exampleInputMobile" className="col-sm-3 col-form-label">
                      Date
                    </label>
                    <div className="col-sm-9">
                      <DatePickerField
                        name="date"
                        autoComplete="off"
                        dateFormat="MM/dd/yyyy"
                        defaultValue={initialValues.date || ""}
                        disabled={"disabled"}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="row">
                    <label htmlFor="manager_name" className="col-sm-3 col-form-label">
                      Manager Name
                    </label>
                    <div className="col-sm-9">
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="manager_name"
                        name="manager_name"
                        defaultValue={initialValues.manager_name || ""}
                        placeholder="Manager Name"
                        disabled={"disabled"}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Group>

                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th> Expenses </th>
                          <th> Amount </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td> Auto and Truck </td>

                          <td>
                            <Form.Group>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">$</span>
                                </div>

                                <Form.Control
                                  type="number"
                                  step="any"
                                  maxLength="4"
                                  className="form-control noscroll"
                                  id="auto_truck"
                                  name="auto_truck"
                                  defaultValue={initialValues.auto_truck || ""}
                                  placeholder="Enter amount"
                                  disabled={isSubmitting}
                                  autoComplete="off"
                                  autoCorrect="off"
                                  autoCapitalize="off"
                                  spellCheck="false"
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                          </td>
                        </tr>
                        <tr>
                          <td> Cleaning & Laundry </td>

                          <td>
                            <Form.Group>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">$</span>
                                </div>

                                <Form.Control
                                  type="number"
                                  className="form-control noscroll"
                                  step="any"
                                  maxLength="4"
                                  id="cleaning"
                                  name="cleaning"
                                  defaultValue={initialValues.cleaning || ""}
                                  placeholder="Enter amount"
                                  disabled={isSubmitting}
                                  autoComplete="off"
                                  autoCorrect="off"
                                  autoCapitalize="off"
                                  spellCheck="false"
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                          </td>
                        </tr>
                        <tr>
                          <td> Food Purchase </td>

                          <td>
                            <Form.Group>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">$</span>
                                </div>

                                <Form.Control
                                  type="number"
                                  className="form-control noscroll"
                                  id="food_purchase"
                                  step="any"
                                  maxLength="4"
                                  name="food_purchases"
                                  defaultValue={initialValues.food_purchases || ""}
                                  placeholder="Enter amount"
                                  disabled={isSubmitting}
                                  autoComplete="off"
                                  autoCorrect="off"
                                  autoCapitalize="off"
                                  spellCheck="false"
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                          </td>
                        </tr>

                        <tr>
                          <td> Office Supplies </td>

                          <td>
                            <Form.Group>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">$</span>
                                </div>

                                <Form.Control
                                  type="number"
                                  className="form-control noscroll"
                                  id="office_supplies"
                                  step="any"
                                  maxLength="4"
                                  name="office_supplies"
                                  defaultValue={initialValues.office_supplies || ""}
                                  placeholder="Enter amount"
                                  disabled={isSubmitting}
                                  autoComplete="off"
                                  autoCorrect="off"
                                  autoCapitalize="off"
                                  spellCheck="false"
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                          </td>
                        </tr>

                        <tr>
                          <td> Repair & Maintenance </td>

                          <td>
                            <Form.Group>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">$</span>
                                </div>

                                <Form.Control
                                  type="number"
                                  className="form-control noscroll"
                                  id="repair"
                                  name="repair"
                                  step="any"
                                  maxLength="4"
                                  defaultValue={initialValues.repair || ""}
                                  placeholder="Enter amount"
                                  disabled={isSubmitting}
                                  autoComplete="off"
                                  autoCorrect="off"
                                  autoCapitalize="off"
                                  spellCheck="false"
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                          </td>
                        </tr>

                        <tr>
                          <td> Rest. Supplies </td>

                          <td>
                            <Form.Group>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">$</span>
                                </div>

                                <Form.Control
                                  type="number"
                                  className="form-control noscroll"
                                  id="rest_supplies"
                                  step="any"
                                  maxLength="4"
                                  name="rest_supplies"
                                  defaultValue={initialValues.rest_supplies || ""}
                                  placeholder="Enter amount"
                                  disabled={isSubmitting}
                                  autoComplete="off"
                                  autoCorrect="off"
                                  autoCapitalize="off"
                                  spellCheck="false"
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                          </td>
                        </tr>

                        <tr>
                          <td> Gift Promotion </td>

                          <td>
                            <Form.Group>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">$</span>
                                </div>

                                <Form.Control
                                  type="number"
                                  className="form-control noscroll"
                                  id="gift_promotion"
                                  name="gift_promotion"
                                  step="any"
                                  maxLength="4"
                                  defaultValue={initialValues.gift_promotion || ""}
                                  placeholder="Enter amount"
                                  disabled={isSubmitting}
                                  autoComplete="off"
                                  autoCorrect="off"
                                  autoCapitalize="off"
                                  spellCheck="false"
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                          </td>
                        </tr>

                        <tr>
                          <td> Other </td>
                          <td>
                            <Form.Group>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">$</span>
                                </div>

                                <Form.Control
                                  type="number"
                                  className="form-control noscroll"
                                  id="other"
                                  name="other"
                                  step="any"
                                  maxLength="4"
                                  defaultValue={initialValues.other || ""}
                                  placeholder="Enter amount"
                                  disabled={isSubmitting}
                                  onFocus={enableExpensesDetailsField}
                                  autoComplete="off"
                                  autoCorrect="off"
                                  autoCapitalize="off"
                                  spellCheck="false"
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                          </td>
                        </tr>
                        {addExpensesDetails && initialValues.other !== "" && (
                          <tr>
                            <td> Expenses Details </td>

                            <td>
                              <Form.Group>
                                <label htmlFor="expenses_details" className="col-form-label">
                                  Please enter the details
                                </label>
                                <Form.Control
                                  as="textarea"
                                  rows="6"
                                  columns="10"
                                  className="form-control"
                                  id="expenses_details"
                                  name="expenses_details"
                                  onChange={handleChange}
                                  value={initialValues.expenses_details ?? ""}
                                />
                              </Form.Group>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="flexx-wrap" style={{ paddingTop: "0px" }}>
          {" "}
          <Button
            type="submit"
            className="btn btn-block btn-primary font-weight-medium auth-form-btn"
          >
            Proceed &nbsp;&nbsp;
            {isSubmitting ? (
              // <Spinner animation="border" size="sm" />
              <span className="fa fa-spin fa-spinner"></span>
            ) : (
              ""
            )}
          </Button>
          <Button variant="light m-2" onClick={() => setCreateExpensesModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </form>
    </div>
  </Modal>
);

export default CreateExpensesModal;
