export const getRoleBasedItems = (user) => {
  if (!user) return [];

  const isAdmin = user.role === "super admin";
  const isHR = user.role === "hr";
  const isRestaurantManager = user.role === "restaurant manager";
  const isServiceManager = user.role === "service manager";
  const isOperations = user.role === "operations";
  const isCatering = user.role === "catering";
  const isAccounting = user.role === "accounting";
  const isDistrictManager = user.role === "district manager";
  const isEmployee = user.role === "employee";
  const isNonEmployee = !isEmployee;

  return [
    {
      path: "/dashboard",
      icon: "mdi-home",
      title: "Dashboard",
    },
    ...(isAdmin || isHR || isRestaurantManager || isServiceManager || isOperations
      ? [
          {
            path: "/employees",
            icon: "mdi-account-multiple",
            title: "Employees",
            state: "employeesMenuOpen",
            subItems: [
              ...(isEmployee
                ? [{ path: "/employees/rate-your-leaders", title: "Rate Your Leaders" }]
                : []),
              ...(isAdmin || isHR
                ? [
                    { path: "/employees/list", title: "View Employees" },
                    { path: "/employees/add", title: "Add Employee" },
                  ]
                : []),
              {
                path: `/employee/view/my-profile/${user.slug}`,
                title: "View My Profile",
              },
              ...(isAdmin || isHR || isRestaurantManager || isServiceManager || isOperations
                ? [{ path: "/employees/application", title: "Application Entries" }]
                : []),
            ],
          },
        ]
      : []),
    ...(isAdmin || isHR || isRestaurantManager || isServiceManager || isOperations || isCatering
      ? [
          {
            path: "/form-manager",
            icon: "mdi-microsoft",
            title: "Form Manager",
            state: "formElementsMenuOpen",
            subItems: [
              ...(isAdmin ||
              isHR ||
              isRestaurantManager ||
              isServiceManager ||
              isOperations ||
              isCatering
                ? [
                    {
                      path: "/form-manager/restaurant-manager",
                      title: "Restaurant Manager",
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(isAdmin ||
    isAccounting ||
    isRestaurantManager ||
    isServiceManager ||
    isDistrictManager ||
    isOperations ||
    isCatering
      ? [
          {
            path: "/financials",
            icon: "mdi-currency-usd",
            title: "Financials",
            state: "financialsMenuOpen",
            subItems: [
              { path: "/financials/view-store-report", title: "Final Reports" },
              { path: "/financials/payment-history", title: "Payment History" },
              ...(isAdmin || isAccounting || isRestaurantManager || isServiceManager || isOperations
                ? [
                    { path: "/financials/scheduled-payments", title: "Scheduled Payments" },
                    { path: "/financials/pending-payments", title: "Pending Payments" },
                    { path: "/financials/deleted-payments", title: "Deleted Payments" },
                  ]
                : []),
              { path: "/financials/expenses-viewers", title: "Expenses Viewer" },
              { path: "/financials/instant-audits", title: "Instant Pay Audit" },
              { path: "/financials/instant-transaction-history", title: "Instant History" },
              { path: "/financials/emergency-code-report", title: "Emergency Code Report" },
            ],
          },
        ]
      : []),
    ...(isAdmin || isCatering
      ? [
          {
            path: "/catering",
            icon: "mdi-food",
            title: "Catering",
            state: "cateringMenuOpen",
            subItems: [
              { path: "/catering/catering-payment", title: "Catering Event" },
              { path: "/catering/scheduled-events", title: "Scheduled Events" },
            ],
          },
        ]
      : []),
    ...(isAdmin || isAccounting || isHR
      ? [
          {
            path: "/accounting",
            icon: "mdi-account-card-details",
            title: "Accounting",
            state: "accountingMenuOpen",
            subItems: [
              { path: "/accounting/report", title: "Closing Report" },
              { path: "/percentage/report", title: "% Sales Report" },
            ],
          },
        ]
      : []),
    ...(isAdmin || isAccounting || isHR || isOperations
      ? [
          {
            path: "/bonus",
            icon: "mdi-account-card-details",
            title: "Bonus",
            state: "bonusMenuOpen",
            subItems: [
              { path: "/bonus-payout", title: "Create Data" },
              { path: "/bonus/bulk-upload", title: "Bulk Upload" },
              { path: "/bonus/view-select-bonus-payout", title: "View Data" },
              { path: "/bonus/view-reviews-bonus-payout", title: "Reviews" },
              { path: "/bonus/survey-for-leaders", title: "Survey" },
              { path: "/bonus/view-evaluation-bonus-payout", title: "Evaluation" },
            ],
          },
        ]
      : []),
    ...(isHR || isAdmin
      ? [
          {
            path: "/hr",
            icon: "mdi-human-handsup",
            title: "HR Links",
            state: "hrMenuOpen",
            subItems: [
              { path: "/hr/employees", title: "HR Employees" },
              { path: "/hr/management", title: "HR Management" },
            ],
          },
        ]
      : []),
    ...(isNonEmployee
      ? [
          {
            path: "/report",
            icon: "mdi-message-reply-text",
            title: "Reports",
            state: "reportPagesMenuOpen",
            subItems: [
              { path: "/report/accounting-report", title: "Accounting Report" },
              { path: "/report/list-stores", title: "MESReports" },
            ],
          },
        ]
      : []),
    {
      path: "/feedback",
      icon: "mdi-comment-question-outline",
      title: "Feedback",
    },
    ...(isAdmin || isAccounting || isOperations
      ? [
          {
            path: "/system-configuration",
            icon: "mdi-settings",
            title: "Portal Configuration",
            state: "systemConfigurationMenuOpen",
            subItems: [
              { path: "/system-configuration/all", title: "Portal Settings" },
              { path: "/system-configuration/employee", title: "Employee Settings" },
              { path: "/system-configuration/bonus/default-page", title: "Bonus Payout" },
              { path: "/system-configuration/kpis/default", title: "Bonus KPIs" },
            ],
          },
        ]
      : []),
  ];
};
