import React, { useEffect, useState } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import { SystemConfigService } from "../../../service/configService";
import { UserService } from "../../../service/userService";
import cogoToast from "cogo-toast";
import { Formik } from "formik";
import InputMask from "react-input-mask";

const Store = () => {
  const [employees, setEmployees] = useState(null);
  const [corporations, setCorporations] = useState(null);

  const history = useHistory();

  useEffect(() => {
    UserService.getEmployees()
      .then((response) => {
        console.log(response, "GETTING EMPLOYEES");
        setEmployees(response.data.data.activeEmployees);
      })
      .catch((error) => {
        console.log(error.response);
      });

    SystemConfigService.getCorporations()
      .then((response) => {
        setCorporations(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Store </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/system-configuration/all"}>Portal Configuration</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={"/system-configuration/view-stores"}>Stores</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              view all
            </li>
          </ol>
        </nav>
      </div>
      <Formik
        initialValues={{
          area: "",
          location_id: "",
          name: "",
          address: "",
          city: "",
          state: "",
          manager_id: "",
          states_code: "",
          telephone_number: "",
          email: "",
          corporation_id: "",
          submitted: false,
          pos_code: "",
          crunch_time_code: "",
          payroll_code: "",
          payments_options: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          if (
            values.name &&
            values.address &&
            values.city &&
            values.state &&
            values.email &&
            values.location_id
          ) {
            SystemConfigService.createStore(values)
              .then((response) => {
                console.log(response);
                if (response.data.success) {
                  setSubmitting(false);
                  cogoToast.success("Store created successfully", toastoptions);
                  localStorage.setItem("storeDetails", JSON.stringify(response.data.data));
                  history.push("/system-configuration/view-stores");
                }
              })
              .catch((error) => {
                if (error.response.data.errors) {
                  if (error.response.data.errors.email) {
                    cogoToast.error(error.response.data.errors.email[0], toastoptions);
                  }
                  if (error.response.data.errors.area) {
                    cogoToast.error(error.response.data.errors.area[0], toastoptions);
                  }
                  if (error.response.data.errors.name) {
                    cogoToast.error(error.response.data.errors.name[0], toastoptions);
                  }
                  if (error.response.data.errors.address) {
                    cogoToast.error(error.response.data.errors.address[0], toastoptions);
                  }
                  if (error.response.data.errors.city) {
                    cogoToast.error(error.response.data.errors.city[0], toastoptions);
                  }
                  if (error.response.data.errors.state) {
                    cogoToast.error(error.response.data.errors.state[0], toastoptions);
                  }

                  if (error.response.data.errors.location_id) {
                    cogoToast.error(error.response.data.errors.location_id[0], toastoptions);
                  }
                  setSubmitting(false);
                }
                cogoToast.error(error.response.data.message, toastoptions);
                setSubmitting(false);
              });
          } else {
            cogoToast.error("Please fill all the required fields", toastoptions);
            setSubmitting(false);
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
          <div className="row">
            <div className="col-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Fill the details below to create a store</h4>
                  {/* <p className="card-description"> Basic form elements </p> */}
                  <form className="forms-sample" onSubmit={handleSubmit}>
                    <Form.Group>
                      <label htmlFor="area">
                        Area <span className="text-danger">*</span>
                      </label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="area"
                        name="area"
                        value={values.area}
                        disabled={isSubmitting}
                        placeholder="area"
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group>
                      <label htmlFor="location_id">
                        Location Id <span className="text-danger">*</span>
                      </label>
                      <Form.Control
                        type="number"
                        className="form-control"
                        id="location_id"
                        name="location_id"
                        value={values.location_id}
                        disabled={isSubmitting}
                        placeholder="Location ID"
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group>
                      <label htmlFor="name">
                        Name <span className="text-danger">*</span>
                      </label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="name"
                        name="name"
                        disabled={isSubmitting}
                        value={values.name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <label htmlFor="address">
                        Address <span className="text-danger">*</span>
                      </label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="address"
                        placeholder="Address"
                        name="address"
                        disabled={isSubmitting}
                        value={values.address}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <label htmlFor="city">
                        City <span className="text-danger">*</span>
                      </label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="city"
                        placeholder="City"
                        name="city"
                        disabled={isSubmitting}
                        value={values.city}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <label htmlFor="state">
                        State <span className="text-danger">*</span>
                      </label>
                      <Form.Control
                        type="text"
                        as="select"
                        className="form-control"
                        id="state"
                        placeholder="State"
                        name="state"
                        value={values.state ? values.state : "DEFAULT"}
                        onChange={handleChange}
                        disabled={isSubmitting}
                      >
                        <option value="DEFAULT" disabled>
                          {" "}
                          Select One{" "}
                        </option>
                        <option value="AK"> Alaska - AK </option>
                        <option value="AZ"> Arizona - AZ </option>
                        <option value="AR"> Arkansas - AR </option>
                        <option value="CA"> California - CA </option>
                        <option value="CO"> Colorado - CO </option>
                        <option value="CT"> Connecticut - CT </option>
                        <option value="DE"> Delaware - DE </option>
                        <option value="FL"> Florida - FL </option>
                        <option value="GA"> Georgia - GA </option>
                        <option value="HI"> Hawaii - HI </option>
                        <option value="ID"> Idaho - ID </option>
                        <option value="IL"> Illinois - IL </option>
                        <option value="IN"> Indiana - IN </option>
                        <option value="IA"> Iowa - IA </option>
                        <option value="KS"> Kansas - KS </option>
                        <option value="KY"> Kentucky - KY </option>
                        <option value="LA"> Louisiana - LA </option>
                        <option value="ME"> Maine - ME </option>
                        <option value="MD"> Maryland - MD </option>
                        <option value="MA"> Massachusetts - MA </option>
                        <option value="MI"> Michigan - MI </option>
                        <option value="MN"> Minnesota - MN </option>
                        <option value="MS"> Mississippi - MS </option>
                        <option value="MO"> Missouri - MO </option>
                        <option value="MT"> Montana - MT </option>
                        <option value="NE"> Nebraska - NE </option>
                        <option value="NV"> Nevada - NV </option>
                        <option value="NH"> New Hampshire - NH </option>
                        <option value="NJ"> New Jersey - NJ </option>
                        <option value="NM"> New Mexico - NM </option>
                        <option value="NY"> New York - NY </option>
                        <option value="NC"> North Carolina - NC </option>
                        <option value="ND"> North Dakota - ND </option>
                        <option value="OH"> Ohio - OH </option>
                        <option value="OK"> Oklahoma - OK </option>
                        <option value="OR"> Oregon - OR </option>
                        <option value="PA"> Pennsylvania - PA </option>
                        <option value="RI"> Rhode Island - RI </option>
                        <option value="SC"> South Carolina - SC </option>
                        <option value="SD"> South Dakota - SD </option>
                        <option value="TN"> Tennessee - TN </option>
                        <option value="TX"> Texas - TX </option>
                        <option value="UT"> Utah - UT </option>
                        <option value="VT"> Vermont - VT </option>
                        <option value="VA"> Virginia - VA </option>
                        <option value="WA"> Washington - WA </option>
                        <option value="WV"> West Virginia - WV </option>
                        <option value="AZ"> Wisconsin - WI </option>
                        <option value="MY"> Wyoming - WY </option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <label htmlFor="telephone_number">Telephone Number</label>
                      <InputMask
                        type="text"
                        mask="+1 (999) 999-9999"
                        className="form-control"
                        id="telephone_number"
                        placeholder="+ (999) 999-9999"
                        name="telephone_number"
                        disabled={isSubmitting}
                        value={values.telephone_number}
                        onChange={handleChange}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                      />
                    </Form.Group>

                    <Form.Group>
                      <label htmlFor="email">
                        Store Email <span className="text-danger">*</span>{" "}
                      </label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="Store email"
                        name="email"
                        disabled={isSubmitting}
                        value={values.email}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group>
                      <label htmlFor="manager">Manager</label>
                      <Form.Control
                        as="select"
                        className="form-control"
                        name="manager_id"
                        value={values.manager_id ? values.manager_id : "DEFAULT"}
                        id="manager_id"
                        disabled={isSubmitting}
                        onChange={handleChange}
                      >
                        <option disabled value={"DEFAULT"}>
                          Select Manager
                        </option>
                        {employees &&
                          employees.map((value, index) => (
                            <option value={value.id} key={index}>
                              {value.first_name} {value.last_name}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <label htmlFor="corporation">Corporation</label>
                      <Form.Control
                        as="select"
                        className="form-control"
                        id="corporation_id"
                        name="corporation_id"
                        value={values.corporation_id ? values.corporation_id : "DEFAULT"}
                        disabled={isSubmitting}
                        onChange={handleChange}
                      >
                        <option disabled value={"DEFAULT"}>
                          Select Corporation
                        </option>
                        {corporations &&
                          corporations.map((value, index) => (
                            <option value={value.id} key={index}>
                              {value.name}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <label htmlFor="pos_code">POS Code </label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="name"
                        name="pos_code"
                        value={values.pos_code}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        placeholder="POS Code"
                      />
                    </Form.Group>

                    <Form.Group>
                      <label htmlFor="crunch_time_code">Crunch Time Code </label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="crunch_time_code"
                        name="crunch_time_code"
                        value={values.crunch_time_code}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        placeholder="Crunch Time Code"
                      />
                    </Form.Group>

                    <Form.Group>
                      <label htmlFor="payroll_code">Payroll Code</label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="payroll_code"
                        name="payroll_code"
                        value={values.payroll_code}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        placeholder="Payroll Code"
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label htmlFor="payments_options">
                        Bartender Tips Payment Options
                      </Form.Label>
                      <Form.Control
                        as="select"
                        type="text"
                        className="form-control"
                        name="payments_options"
                        id="payments_options"
                        value={values.payments_options ? values.payments_options : "DEFAULT"}
                        disabled={isSubmitting}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        onChange={handleChange}
                      >
                        <option value="DEFAULT" disabled>
                          Select One
                        </option>
                        <option value="manual">Manual</option>
                        <option value="pay_by_hour">Pay by hour </option>
                        <option value="hour_help"> View hour help </option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label htmlFor="catering_payments_options">
                        Catering Payment Options
                      </Form.Label>
                      <Form.Control
                        as="select"
                        type="text"
                        className="form-control"
                        name="catering_payments_options"
                        id="catering_payments_options"
                        defaultValue={values.catering_payments_options}
                        disabled={isSubmitting}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        onChange={handleChange}
                      >
                        <option value="no"> No </option>
                        <option value="yes"> Yes </option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label htmlFor="emergency_code_payments_options">
                        Emergency Code Payment Options
                      </Form.Label>
                      <Form.Control
                        as="select"
                        type="text"
                        className="form-control"
                        name="emergency_code_payments_options"
                        id="emergency_code_payments_options"
                        defaultValue={values.emergency_code_payments_options}
                        disabled={isSubmitting}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        onChange={handleChange}
                      >
                        <option value="no"> No </option>
                        <option value="yes"> Yes </option>
                      </Form.Control>
                    </Form.Group>

                    <button type="submit" className="btn btn-primary mr-2">
                      Submit &nbsp;&nbsp;
                      {isSubmitting ? <Spinner animation="border" size="sm" /> : ""}
                    </button>
                    {/* <button className="btn btn-light">Cancel</button> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default withRouter(Store);
