import { Formik } from "formik";
import React from "react";
import { Form } from "react-bootstrap";

const roundNumberUp = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2);
};

const InputField = ({ label, name, value, onChange, disabled }) => (
  <div className="col-md-3">
    <label htmlFor={name}>{label}</label>
    <Form.Group>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">$</span>
        </div>
        <Form.Control
          type="number"
          className="form-control"
          id={name}
          name={name}
          value={value || ""}
          placeholder="Enter amount"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </Form.Group>
  </div>
);

export const DepositForm = ({
  initialValues,
  onSubmit,
  alohaDeposit,
  totalExpenses,
  depositCalculated,
  editDepositMode,
  enterOverageOrShort,
  overOrShort,
  setEnterOverageOrShort,
  setOverOrShort,
  setEditDepositMode,
}) => (
  <>
    <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={onSubmit}>
      {({ values, handleSubmit, isSubmitting, handleChange }) => (
        <>
          <form onSubmit={handleSubmit} className="depositForm">
            <h3 className="mb-4">Deposit</h3>
            <div className="form-inline" id="form-inline">
              <InputField
                label="Aloha Deposit"
                name="aloha_deposit"
                value={alohaDeposit}
                disabled
              />
              -
              <InputField
                label="Total Expenses"
                name="total_expenses"
                value={totalExpenses}
                disabled
              />
              =
              <InputField
                label="Total Deposit (Calculated)"
                name="sales_deposit_calculated"
                value={depositCalculated}
                disabled
              />
            </div>
            <table className="table table-bordered mt-5">
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>
                    <div>
                      <label htmlFor="cash_to_deposit">Cash to be Deposited</label>
                      <Form.Group>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                          </div>
                          <Form.Control
                            type="number"
                            className="form-control"
                            id="cash_to_deposit"
                            name="cash_to_deposit"
                            value={values.cash_to_deposit ? parseFloat(values.cash_to_deposit) : ""}
                            placeholder="Enter amount"
                            step="any"
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            disabled={editDepositMode}
                            onChange={(e) => {
                              handleChange(e);
                              if (parseFloat(e.target.value)) {
                                if (
                                  roundNumberUp(parseFloat(e.target.value)) !==
                                  roundNumberUp(parseFloat(depositCalculated))
                                ) {
                                  setOverOrShort(
                                    roundNumberUp(
                                      parseFloat(e.target.value) - parseFloat(depositCalculated)
                                    )
                                  );
                                  setEnterOverageOrShort(true);
                                } else {
                                  setEnterOverageOrShort(false);
                                }
                              } else {
                                setEnterOverageOrShort(false);
                              }
                            }}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </td>
                  <td style={{ width: "50%" }}>
                    {enterOverageOrShort && (
                      <div>
                        <label htmlFor="over_or_short">
                          {overOrShort && overOrShort < 0 ? "Shortage" : "Overage"}
                        </label>
                        <Form.Group>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">$</span>
                            </div>
                            <Form.Control
                              type="number"
                              className="form-control"
                              id="over_or_short"
                              name="over_or_short"
                              value={roundNumberUp(overOrShort)}
                              placeholder="Enter amount"
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              disabled={"disabled"}
                            />
                          </div>
                        </Form.Group>
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            {enterOverageOrShort && (
              <div className="mt-3">
                <p>
                  Please explain the
                  {overOrShort && overOrShort < 0 ? " shortage " : " overage "}
                  of the deposit amount
                </p>
                <Form.Group>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    type="text"
                    className="form-control"
                    id="reason_for_short_or_over"
                    name="reason_for_short_or_over"
                    value={values.reason_for_short_or_over}
                    placeholder="Please Explain"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    disabled={editDepositMode}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
            )}
            {!editDepositMode && (
              <button type="submit" className="btn btn-primary btn-icon-text">
                <i className="mdi mdi-file-check btn-icon-prepend"></i> Submit Deposit
              </button>
            )}
          </form>
          {editDepositMode && (
            <div className="mt-3">
              <button
                type="submit"
                className="btn btn-light"
                onClick={() => setEditDepositMode(false)}
              >
                <i className="mdi mdi-file-check btn-icon-prepend"></i> Edit Deposit{" "}
              </button>
            </div>
          )}
        </>
      )}
    </Formik>
  </>
);
