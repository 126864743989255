import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
// import DatePicker from "react-datepicker";
import { UserService } from "../../service/userService";
import cogoToast from "cogo-toast";
import { SystemConfigService } from "../../service/configService";
import { Formik } from "formik";
import InputMask from "react-input-mask";

const CreateEmployeeForm = () => {
  const [jobCode, setJobCode] = useState(null);
  const [store, setStore] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [rmSelected, setRmSelected] = useState(false);

  const history = useHistory();

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  useEffect(() => {
    SystemConfigService.getJobCodes()
      .then((response) => {
        setJobCode(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });

    SystemConfigService.getStores()
      .then((response) => {
        setStore(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);

  return (
    <div>
      <div>
        <p className="pull-right" style={{ textDecoration: "underline" }}>
          <a href="https://veltron.document360.io/v1/en" target="_blank" rel="noopener noreferrer">
            Learn How &nbsp;<span className="fa fa-question"></span>
          </a>
        </p>

        <div className="clearfix"></div>
      </div>
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          middle_name: "",
          pay_type: "",
          pay_schedule: "",
          phone_number: "",
          email: "",
          date_of_employment: "",
          employment_status: "active",
          role: "",
          job_status: "",
          job_code_id: "",
          store_id: "",
          manager_type: "",
          salary: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = "Invalid email address";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            console.log(values);
            const response = await UserService.createEmployee(values);
            if (response.data.success) {
              cogoToast.success("Employee created successfully", toastoptions);
              setSubmitting(false);
              setSubmitted(false);
              history.push("/employees/list");
              return;
            }
          } catch (e) {
            const errorObject = e.response.data.errors;

            if (e.response.data.errors) {
              if (e.response.data.errors.email) {
                cogoToast.error(errorObject.email[0], toastoptions);
              }
              if (errorObject.first_name) {
                cogoToast.error(errorObject.first_name[0], toastoptions);
              }
              if (errorObject.last_name) {
                cogoToast.error(errorObject.last_name[0], toastoptions);
              }
              if (errorObject.job_status) {
                cogoToast.error(errorObject.job_status[0], toastoptions);
              }
              if (errorObject.job_code_id) {
                cogoToast.error(errorObject.job_code_id[0], toastoptions);
              }
              if (errorObject.role) {
                cogoToast.error(errorObject.role[0], toastoptions);
              }
              if (errorObject.date_of_employment) {
                cogoToast.error(errorObject.date_of_employment[0], toastoptions);
              }
              if (errorObject.store_id) {
                cogoToast.error(errorObject.store_id[0], toastoptions);
              }
            }

            cogoToast.error(e.response.data.message, toastoptions);
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
          <div>
            <div className="page-header">
              <div>
                <h3 className="page-title"> Create New Employee </h3>
              </div>

              <div>
                <Link to={"/employees/bulk/upload"}>
                  <button className="btn btn-primary"> Bulk Upload </button>
                </Link>
              </div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/employees/list"}> Employees</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Create Employee
                  </li>
                </ol>
              </nav>
            </div>

            <div className="row">
              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <Form className="form-sample" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="col-md-12 border-bottom mb-3">
                            <h5> Personal </h5>
                          </div>

                          <Form.Group>
                            <label htmlFor="first_name">
                              First Name <span className="text-danger"> * </span>{" "}
                            </label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="first_name"
                              placeholder="First Name"
                              name="first_name"
                              value={values.first_name}
                              onChange={handleChange}
                              disabled={isSubmitting}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                            />
                          </Form.Group>

                          <Form.Group>
                            <label htmlFor="middle_name">Middle Name</label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="middle_name"
                              placeholder="Middle Name"
                              name="middle_name"
                              value={values.middle_name}
                              onChange={handleChange}
                              disabled={isSubmitting}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                            />
                          </Form.Group>

                          <Form.Group>
                            <label htmlFor="last_name">
                              Last Name <span className="text-danger"> * </span>
                            </label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="last_name"
                              placeholder="Last Name"
                              name="last_name"
                              value={values.last_name}
                              onChange={handleChange}
                              disabled={isSubmitting}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                            />
                          </Form.Group>
                        </div>

                        <div className="col-md-6">
                          <div className="col-md-12 border-bottom mb-3">
                            <h5> Contact </h5>
                          </div>

                          <div className="col-md-12">
                            <Form.Group>
                              <label htmlFor="phone_number">Phone Number</label>
                              <InputMask
                                type="text"
                                mask="+1 (999) 999-9999"
                                className="form-control"
                                id="phone_number"
                                placeholder="+ (999) 999-9999"
                                name="phone_number"
                                value={values.phone_number}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>

                            <Form.Group>
                              <label htmlFor="email">
                                Email <span className="text-danger"> * </span>
                              </label>
                              <Form.Control
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="col-md-12 border-bottom mb-3">
                            <h5> Job </h5>
                          </div>

                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-12">
                                <Form.Group>
                                  <div>
                                    Date of Employment <span className="text-danger"> * </span>
                                  </div>
                                  <Form.Control
                                    type="date"
                                    id="dateOfEmployment"
                                    className="form-control w-100"
                                    name="date_of_employment"
                                    value={values.date_of_employment}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                    // maxDate={new Date()}
                                  />
                                  {/* <DatePicker
                                id="dateOfEmployment"
                                className="form-control w-100"
                                name="date_of_employment"
                                value={values.date_of_employment}
                                selected={values.date_of_employment}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              /> */}
                                </Form.Group>

                                <Form.Group>
                                  <label htmlFor="job_status">
                                    Job Status <span className="text-danger"> * </span>
                                  </label>
                                  <select
                                    className="form-control"
                                    id="job_status"
                                    name="job_status"
                                    value={values.job_status}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                  >
                                    <option disabled value="">
                                      Select Job Status
                                    </option>
                                    <option value="part time">Part Time</option>
                                    <option value="full time">Full Time</option>
                                    <option value="hourly"> Hourly </option>
                                    <option value="salary exempt">Salary Exempt</option>
                                    <option value="salary non exempt">Salary Non Exempt</option>
                                  </select>
                                </Form.Group>

                                <Form.Group>
                                  <label htmlFor="job_code">
                                    Job Code <span className="text-danger"> * </span>
                                  </label>
                                  <select
                                    className="form-control"
                                    id="job_code_id"
                                    name="job_code_id"
                                    value={values.job_code_id}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                  >
                                    <option disabled value={""}>
                                      Select Job Code
                                    </option>
                                    {jobCode &&
                                      jobCode.map((value) => (
                                        <option value={value.job_code_id} key={value.job_code_id}>
                                          {value.name}
                                        </option>
                                      ))}
                                  </select>
                                </Form.Group>

                                <Form.Group>
                                  <label htmlFor="store">
                                    Store <span className="text-danger"> * </span>
                                  </label>
                                  <select
                                    className="form-control"
                                    id="store_id"
                                    name="store_id"
                                    value={values.store_id}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                  >
                                    <option disabled value={""}>
                                      Select Store
                                    </option>
                                    {store &&
                                      store.map((value) => (
                                        <option value={value.location_id} key={value.location_id}>
                                          {value.name}
                                        </option>
                                      ))}
                                  </select>
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="col-md-12 border-bottom mb-3">
                            <h5> Access Level </h5>
                          </div>

                          <div className="col-md-12">
                            <Form.Group>
                              <label htmlFor="role">
                                Role <span className="text-danger"> * </span>
                              </label>
                              <select
                                className="form-control"
                                id="role"
                                name="role"
                                value={values.role}
                                onChange={(e) => {
                                  setFieldValue("role", e.target.value);
                                  if (e.target.value === "restaurant manager") {
                                    setRmSelected(true);
                                  } else {
                                    setRmSelected(false);
                                  }
                                }}
                                disabled={isSubmitting}
                              >
                                <option disabled value={""}>
                                  Select Role
                                </option>
                                <option value={"super admin"}>Super Admin</option>
                                <option value={"accounting"}>Accounting</option>
                                <option value={"operations"}>Operations</option>
                                <option value={"restaurant manager"}>Restaurant Manager</option>
                                <option value={"district manager"}>District Manager</option>
                                <option value={"service manager"}>Service Manager</option>
                                <option value={"hr"}>HR</option>
                                <option value={"employee"}>Employee</option>
                                <option value={"catering"}>Catering</option>
                              </select>
                            </Form.Group>
                          </div>

                          {rmSelected && (
                            <div>
                              <div className="col-md-12">
                                <Form.Group>
                                  <label htmlFor="manager_type">
                                    Manager Type <span className="text-danger"> * </span>
                                  </label>
                                  <select
                                    className="form-control"
                                    id="manager_type"
                                    name="manager_type"
                                    value={values.manager_type}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                  >
                                    <option disabled value={""}>
                                      Select Manager Type
                                    </option>
                                    <option value={"general"}>General</option>
                                    <option value={"beverage"}>Beverage</option>
                                    <option value={"foh"}>FOH</option>
                                    <option value={"kitchen"}>Kitchen</option>
                                  </select>
                                </Form.Group>
                              </div>

                              <div className="col-md-6">
                                <Form.Group>
                                  <label htmlFor="email">Salary</label>
                                  <Form.Control
                                    type="number"
                                    className="form-control"
                                    id="salary"
                                    placeholder="Salary"
                                    name="salary"
                                    value={values.salary}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                  />
                                </Form.Group>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="col-md-6">
                          <div className="col-md-12 border-bottom mb-3">
                            <h5> Employment Status </h5>
                          </div>

                          <div className="col-md-6">
                            <Form.Group>
                              <label htmlFor="employment_status">Employee Status</label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                id="employment_status"
                                placeholder="Employment Status"
                                name="employment_status"
                                value={values.employment_status}
                                onChange={handleChange}
                                disabled={"disabled"}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>

                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-primary mr-2"
                      >
                        Submit &nbsp;&nbsp;
                        {isSubmitting ? <Spinner animation="border" size="sm" /> : ""}
                      </button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default CreateEmployeeForm;
