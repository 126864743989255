import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
// import { AspectRatio } from "react-aspect-ratio";
import { FilePond, registerPlugin } from "react-filepond";
import React, { useEffect, useState } from "react";
import cogoToast from "cogo-toast";
import { Utility } from "../../Helpers/utils";
import { BiggerCircle } from "../../components/AlertUtility";

const acceptedMimeTypes = ["image/jpeg", "image/png"];

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export const ProfilePicturePond = ({ slug }) => {
  const [background, setBackground] = useState(``);

  const toastoptions = {
    hideAfter: 5,
    position: "top-right",
    heading: "Attention",
  };

  useEffect(() => {
    setBackground(
      `${process.env.REACT_APP_AZURE_STORAGE}/pictures/${slug}?${Date.now()}`
    );
  }, [slug]);

  //   <div className="user-avatar mb-auto">
  //     <BigCircle
  //       style={{
  //         backgroundImage: `url(${process.env.REACT_APP_STORAGE}/profile/${user?.slug}),
  //                          url(/svg/portrait.svg)`,
  //         backgroundSize: "cover",
  //         backgroundRepeat: "no-repeat",
  //         backgroundPosition: "center",
  //       }}
  //     />

  //     <span className="edit-avatar-icon">
  //       <i className="mdi mdi-camera"></i>
  //     </span>
  //   </div>;

  return (
    <div className="user-avatar mb-auto">
      <BiggerCircle
        //   ratio="1/1"
        style={{
          backgroundImage: `url(${background}), url(/svg/portrait.svg)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          display: "block",
          margin: "0 auto",
        }}
      >
        <FilePond
          allowMultiple={false}
          name="profile_picture"
          instantUpload={true}
          allowRevert={false}
          labelIdle=""
          onerror={(error, file) => {
            cogoToast.error(error.main, toastoptions);
            file.abortLoad();
          }}
          onaddfile={(error, file) => {
            if (
              !acceptedMimeTypes.find((mimeType) => mimeType === file.fileType)
            ) {
              cogoToast.error(
                "You can only upload JPEG and PNG formats",
                toastoptions
              );
              file.abortLoad();
              return;
            }

            if (file.fileSize > 3 * 1000 * 1024) {
              cogoToast.error("File is too large", toastoptions);
              file.abortLoad();
            }
          }}
          onprocessfile={(error, file) => {
            file.abortLoad();
          }}
          server={{
            process: async (
              _,
              file,
              metadata,
              load,
              error,
              progress,
              abort
            ) => {
              const data = new FormData();
              data.append("profile_picture", file);
              const request = new XMLHttpRequest();

              request.onload = async (e) => {
                if (request.status >= 200 && request.status < 300) {
                  load(request.responseText);
                  cogoToast.success("Image updated successfully", toastoptions);

                  setBackground(
                    `${
                      process.env.REACT_APP_AZURE_STORAGE
                    }/pictures/${slug}?${Date.now()}`
                  );
                  console.log(background);
                } else {
                  console.error(request);
                }
              };

              request.upload.onprogress = (e) => {
                progress(e.lengthComputable, e.loaded, e.total);
              };

              request.open(
                "POST",
                `${process.env.REACT_APP_BASE_URL}/employee/account/update-profile-pic/${slug}`
              );

              request.setRequestHeader(
                "authorization",
                Utility.getBearerToken()
              );

              request.send(data);

              return {
                abort: () => {
                  if (!request.DONE) {
                    request.abort();
                  }

                  abort();
                },
              };
            },
          }}
        ></FilePond>
      </BiggerCircle>
    </div>
  );
};
