import React from "react";

const roundNumberUp = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2);
};

const ExpensesTableRow = ({ name, label, value, handleChange, editMode }) => (
  <tr>
    <td>{label}</td>
    <td>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">$</span>
        </div>
        <input
          type="number"
          className="form-control"
          id={name}
          name={name}
          defaultValue={roundNumberUp(value)}
          placeholder="Enter amount"
          step="any"
          maxLength="4"
          onChange={handleChange}
          disabled={!editMode}
        />
      </div>
    </td>
  </tr>
);

export default ExpensesTableRow;
