import React from "react";

const UploadRequiredFiles = () => {
  return (
    <div>
      <small className="form-text text-muted">
        Go back to the previous step, click Cancel and upload all files.
      </small>
    </div>
  );
};

export default UploadRequiredFiles;
