import cogoToast from "cogo-toast";
import React, { useEffect, useState } from "react";
import { useHistory, useParams, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Col, Row, Nav, Tab } from "react-bootstrap";
import { SystemConfigService } from "../../../service/configService";
import { UserService } from "../../../service/userService";
import AppSpinner from "../../../components/Spinner";
import moment from "moment";
import ViewAllStoresApplication from "./components/ViewAllStoresApplication";
import ViewHiredApplicants from "./components/ViewHiredApplicants";
import ViewNotHiredApplicants from "./components/ViewNotHiredApplicants";
import { Utility } from "../../../Helpers/utils";

const ShowApplicationEntries = () => {
  const [applicationEntries, setApplicationEntries] = useState(null);
  const [hiredApplicants, setHiredApplicants] = useState(null);
  const [notHiredApplicants, setNotHiredApplicants] = useState(null);
  const [stores, setStores] = useState(null);

  let history = useHistory();
  const user = Utility.getUser();

  useEffect(() => {
    UserService.getApplicants()
      .then((response) => {
        console.log(response.data.data, "Applicants");

        let applicantsArr = [];
        let hiredApplicantArr = [];
        let notHiredApplicantArr = [];

        response.data.data.map((res, index) => {
          let resultData = {
            id: res.id,
            approval_status: res.approval_status,
            date_created: moment(res.date_created).fromNow(),
            state: res.state,
            city: res.city,
            address: res.address,
            copy_resume: res.copy_resume,
            date: res.date,
            email: res.email,
            entry_number: res.entry_number,
            form_number: res.form_number,
            how_heard: res.how_heard,
            ip_address: res.ip_address,
            name: res.name,
            phone_number: res.phone_number,
            position: res.position,
            signature: res.signature,
            when_start: res.when_start,
            willing: res.willing,
            isAcknowledged: res.isAcknowledged,
            hired: res.hired,
            are_you_us_citizen: res.are_you_us_citizen,
            authorised_to_work_us: res.authorised_to_work_us,
            worked_for_this_company: res.worked_for_this_company,
            when_did_you_worked_here: res.when_did_you_worked_here,
            rate: res.rate,
            start_date: res.start_date,
            employee_classification: res.employee_classification,
            employee_type: res.employee_type,
            rehired_from: res.rehired_from,
            transferred_from: res.transferred_from,
            manager_interviewed: res.manager_interviewed,
            ready_training: res.ready_training,
            position_hired: res.position_hired,
          };
          if (res.approval_status == "PROCESSED" && res.hired == "Yes") {
            hiredApplicantArr.push(resultData);
          } else if (res.approval_status == "PROCESSED" && res.hired === "No") {
            notHiredApplicantArr.push(resultData);
          }
          applicantsArr.push(resultData);
        });

        setNotHiredApplicants(notHiredApplicantArr);
        setHiredApplicants(hiredApplicantArr);
        setApplicationEntries(applicantsArr);
      })
      .catch((error) => {
        console.log(error);
      });

    SystemConfigService.getStoresForManagerAdmin()
      .then((response) => {
        console.log(response.data.data);
        setStores(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);

  return (
    <div>
      <div>
        <p className="pull-right" style={{ textDecoration: "underline" }}>
          <a
            href="https://veltron.document360.io/v1/en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn How &nbsp;<span className="fa fa-question"></span>
          </a>
        </p>

        <div className="clearfix"></div>
      </div>

      <div className="tab-custom-pills-horizontal">
        <Tab.Container id="left-tabs-example" defaultActiveKey="stores">
          <Row>
            <Col xs={12}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link
                    eventKey="stores"
                    className="d-flex align-items-center"
                  >
                    Stores
                  </Nav.Link>
                </Nav.Item>

                {user.role !== "restaurant manager" && (
                  <>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="all"
                        className="d-flex align-items-center"
                      >
                        All Entries
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        eventKey="hired"
                        className="d-flex align-items-center"
                      >
                        Hired
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        eventKey="notHired"
                        className="d-flex align-items-center"
                      >
                        Not Hired
                      </Nav.Link>
                    </Nav.Item>
                  </>
                )}
              </Nav>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="stores">
                  <div className="mt-3">
                    <div className="row">
                      {stores ? (
                        stores.map((value, index) => (
                          <div className="col-md-3 mb-5" key={index}>
                            <div className="domainBox">
                              <Link
                                to={`/employees/entries/${value.location_id}`}
                              >
                                <div className="react-aspect-ratio-placeholder">
                                  <div className="sc-fzoiQi ozSmQ  ">
                                    <main>
                                      <h4
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {value.name}
                                      </h4>
                                      <small>{value.address}</small>
                                      <br />
                                      <br />
                                      <small>
                                        <b>({value.location_id})</b>
                                      </small>
                                    </main>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        ))
                      ) : (
                        <AppSpinner />
                      )}
                    </div>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="all">
                  <ViewAllStoresApplication
                    applicationEntries={applicationEntries}
                  />
                </Tab.Pane>

                <Tab.Pane eventKey="hired">
                  <ViewHiredApplicants applicationEntries={hiredApplicants} />
                </Tab.Pane>

                <Tab.Pane eventKey="notHired">
                  <ViewNotHiredApplicants
                    applicationEntries={notHiredApplicants}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};

export default withRouter(ShowApplicationEntries);
