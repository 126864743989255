import React, { useEffect, useState } from "react";
import { Spinner, Form } from "react-bootstrap";
import { UserService } from "../../../../../service/userService";
import { Formik } from "formik";
import { useHistory, withRouter } from "react-router";
import moment from "moment";
import { BonusPayoutService } from "../../../../../service/BonusPayoutService";
import Swal from "sweetalert2";
import { SystemConfigService } from "../../../../../service/configService";
import { Link } from "react-router-dom";

const RateYourLeadersSpanish = () => {
  const [managers, setManagers] = useState(null);
  const [managersAvailable, setManagersAvailable] = useState(false);
  const [selectedManager, setSelectedManager] = useState(null);
  const [selectedManagerName, setSelectedManagerName] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [employeeNumber, setEmployeeNumber] = useState(null);
  const [storeOptions, setStoreOptions] = useState(null);
  const [managerLoading, setManagerLoading] = useState(false)
  const history = useHistory();

  //   const user = Utility.getUser();

  useEffect(() => {
    // console.log(user);s
    // UserService.getAllManagers()
    //   .then((response) => {
    //     console.log(response.data.data);
    //     setManagers(response.data.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    if (JSON.parse(localStorage.getItem("rateMyBossDetails"))) {
      setSelectedManager(
        JSON.parse(localStorage.getItem("rateMyBossDetails")).manager_id
      );

      setSelectedManagerName(
        JSON.parse(localStorage.getItem("rateMyBossDetails")).manager_name
      );

      setSelectedStore(
        JSON.parse(localStorage.getItem("rateMyBossDetails")).store_id
      );

      setEmployeeNumber(
        JSON.parse(localStorage.getItem("rateMyBossDetails")).employeeNumber
      );

      UserService.getRestaurantManagersUsingStoreId(
        JSON.parse(localStorage.getItem("rateMyBossDetails")).store_id
      )
        .then((response) => {
          console.log(response.data.data);
          setManagers(response.data.data);
          setManagersAvailable(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    localStorage.setItem("surveyLang", JSON.stringify("spanish"));

    // get stores
    SystemConfigService.getStores()
      .then((response) => {
        setStoreOptions(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);

  return (
    <div className="col-md-6 mx-auto" style={{ marginTop: "50px" }}>
      <div>
        <div style={{ marginBottom: "20px" }} className="pull-left">
          <Link to={"/employees/rate-your-leaders/begin/english"}>
            Translate to English
          </Link>
        </div>

        <div style={{ marginBottom: "20px" }} className="pull-right">
          <Link to={"/employees/rate-your-leaders"}>
            <button className="btn btn-primary"> Back </button>
          </Link>
        </div>
      </div>

      <div className="clearfix"></div>

      <div className="card">
        <div className="card-body">
          <div>
            <p>
              Cada pregunta exige que indique su experiencia en cuanto a la
              frecuencia con la que su gerente demuestra el comportamiento
              descrito:
            </p>
            <ul>
              <li> Nunca </li>
              <li> A veces </li>
              <li> A menudo </li>
              <li> Siempre </li>
            </ul>
          </div>

          <div>
            <p>
              Tenga en cuenta que se necesita toda su atención durante toda la
              encuesta
            </p>
          </div>

          <div>
            Elige tu ubicación, selecciona el administrador y clickea en el
            <b> Empezar Encuesta </b> botón para comenzar.
          </div>

          <div
            style={{
              marginTop: "20px",
            }}
          >
            <Formik
              initialValues={{
                manager: selectedManager ? selectedManager : "",
                store: selectedStore ? selectedStore : "",
                manager_name: selectedManagerName ? selectedManagerName : "",
                employeeNumber: employeeNumber ? employeeNumber : ""
              }}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);

                if (values.manager && values.store && values.employeeNumber) {
                  let rateMyBossObj = {};

                  let managerDetails = {
                    store_id: values.store,
                    employeeNumber: values.employeeNumber,
                    manager_id: values.manager,
                    manager_name: values.manager_name,
                    quarter: moment().quarter(),
                    year: moment().year(),
                  };

                  // Create an API to check if the selected manager has
                  // been reviewed the same employee for the current quarter.
                  BonusPayoutService.checkManagerSurvey(managerDetails)
                    .then((response) => {
                      console.log(response);
                      if (response.data.success) {
                        Object.assign(rateMyBossObj, managerDetails);

                        localStorage.setItem(
                          "rateMyBossDetails",
                          JSON.stringify(rateMyBossObj)
                        );
                        setSubmitting(false);

                        history.push("/employees/rate-your-leaders/create");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      Swal.fire({
                        title: "Error",
                        text: error.response.data.message,
                        icon: "error",
                        confirmButtonText: "Ok",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                      }).then((result) => {
                        setSubmitting(false);
                        if (result.isConfirmed) {
                          Swal.close();
                        }
                      });
                    });
                } else {
                  setSubmitting(false);
                  alert("Please check all fields");
                }
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <div>
                  <form className="rate_my_boss_form" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group>
                          <Form.Group>
                            <label htmlFor="employeeEmail">
                              <b>número de empleado</b>
                            </label>

                            <Form.Control
                              type="text"
                              className="form-control"
                              id="number"
                              placeholder="Employee Number"
                              name="employeeNumber"
                              value={values.employeeNumber}
                              onChange={handleChange}
                              disabled={isSubmitting}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                            />
                          </Form.Group>
                        </Form.Group>

                        <Form.Group>
                          <label htmlFor="store">
                            <b>Tienda</b>
                          </label>
                          <Form.Control
                            type="text"
                            as="select"
                            className="form-control"
                            id="store"
                            name="store"
                            value={values.store ? values.store : "DEFAULT"}
                            onChange={(e) => {
                              setFieldValue("store", e.target.value);
                              setManagerLoading(true)
                              UserService.getRestaurantManagersUsingStoreId(
                                e.target.value
                              )
                                .then((response) => {
                                  console.log(response.data.data);
                                  setManagers(response.data.data);
                                  setManagersAvailable(true);
                                  setManagerLoading(false)
                                })
                                .catch((error) => {
                                  console.log(error);
                                });
                            }}
                            disabled={isSubmitting}
                          >
                            <option value="DEFAULT" disabled>
                              Select One
                            </option>

                            {/* Listing because of delay */}

                            <option value={"1"}>
                              {" "}
                              LA PARRILLA (MARIETTA SQUARE){" "}
                            </option>

                            <option value="2">LA PARRILLA (DALLAS HWY)</option>
                            <option value="3">
                              LA PARRILLA (FLOWERY BRANCH)
                            </option>
                            <option value="4">
                              LA PARRILLA (POWDER SPRINGS)
                            </option>
                            <option value="5">
                              LA PARRILLA (FAYETTEVILLE)
                            </option>
                            <option value="6">LA PARRILLA (ALPHARETTA)</option>
                            <option value="7">LA PARRILLA (WOODSTOCK)</option>
                            <option value="9">LA PARRILLA (ACWORTH)</option>
                            <option value="10">LA PARRILLA (NEWNAN)</option>
                            <option value="11">LA PARRILLA (MCDONOUGH)</option>
                            <option value="12">
                              LA PARRILLA (HOWELL MILL)
                            </option>
                            <option value="13">LA PARRILLA (DOTHAN)</option>
                            <option value="15">LA PARRILLA (CANTON)</option>
                            <option value="16">LA PARRILLA (MACON)</option>
                            <option value="17">LA PARRILLA (GREENVILLE)</option>
                            <option value="18">LA PARRILLA (TOCO HILL)</option>
                            <option value="19">LA PARRILLA (SAVANNAH)</option>
                            <option value="20">LA PARRILLA (GRIFFIN)</option>
                            <option value="21">LA PARRILLA (ROME)</option>
                            <option value="14">LA PARRILLA (NORCROSS)</option>
                            <option value="23">
                              LA PARRILLA (SANDY SPRINGS)
                            </option>
                          </Form.Control>
                        </Form.Group>

                        {managerLoading && (
                          <span> Loading.... Please wait..</span>
                        )}


                        {managersAvailable && (
                          <Form.Group style={{ marginTop: "20px" }}>
                            <label htmlFor="manager">Select Manager</label>

                            <Form.Control
                              as="select"
                              type="text"
                              className="form-control"
                              id="manager"
                              name="manager"
                              value={
                                values.manager ? values.manager : "DEFAULT"
                              }
                              onChange={(e) => {
                                setFieldValue("manager", e.target.value);
                                setFieldValue(
                                  "manager_name",
                                  e.target.options[e.target.selectedIndex].text
                                );
                              }}
                              disabled={isSubmitting}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                            >
                              <option value="DEFAULT" disabled>
                                Select Manager
                              </option>
                              {managers &&
                                managers.map((man, index) => (
                                  <option value={man.id} key={index}>
                                    {man.first_name} {man.last_name}
                                  </option>
                                ))}
                            </Form.Control>
                          </Form.Group>
                        )}

                        <input
                          type="text"
                          name="manager_name"
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>

                    <div>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {" "}
                        Start Survey{" "}
                        {isSubmitting ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(RateYourLeadersSpanish);
