import React, { useEffect, useState } from "react";
import { UserService } from "../../../../../service/userService";

const Locations = ({ employee, user }) => {
  const [histories, setHistories] = useState(null);

  useEffect(() => {
    async function getHistories(slug) {
      try {
        const response = await UserService.getEmployeeLocationHistories(slug);
        setHistories(response.data.data);
      } catch (e) {
        console.log(e.response);
      }
    }

    getHistories(user.slug);
  }, []);

  console.log(histories, "This is the history");
  return (
    <div>
      <div className="mt-3">
        <div className="row">
          {histories ? (
            histories.map((value, index) => (
              <div className="col-md-3 mb-5" key={index}>
                <div className="domainBox">
                  <div className="react-aspect-ratio-placeholder">
                    <div className="sc-fzoiQi ozSmQ  ">
                      <main>
                        <h4 style={{ textTransform: "capitalize" }}>
                          Current Store: {value.active_store_id}
                        </h4>

                        <span> Date Hired: {value.date_hired}</span>
                        <br />
                        <span>Start Date: {value.start_date}</span>
                        <br />
                        <span>End Date: {value.end_date}</span>
                        <br />
                      </main>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p> No history found </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Locations;
